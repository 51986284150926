<template>
  <DevtoolsSection>
    <template #title>
      Ивенты
    </template>
    <Log
      v-for="(event, index) in events"
      :key="index"
      :date="event.date"
      :status="event.statusCode"
      :content="event.responseBody.state.substr(-6, 6)"
      :more-info="event.responseBody.events.length > 0"
      @more-info="visibleLog = event"
    />
  </DevtoolsSection>

  <LogDetails
    v-if="visibleLog"
    class="fixed top-0 left-0 w-screen h-screen z-10"
    :log="visibleLog"
    @close="visibleLog = undefined"
  />
</template>

<script setup lang="ts">
import { EventLog } from '@/devtools/sections/events/eventLog';
import LogDetails from '@/devtools/sections/log-details.vue';
import Log from '@/devtools/sections/log.vue';
import DevtoolsSection from '@/devtools/sections/section/devtools-section.vue';
import { useDevtoolsStore } from '@/devtools/store/devtoolsModule';
import { ref } from 'vue';

const { events } = useDevtoolsStore();
const visibleLog = ref<EventLog>();
</script>
