<template>
  <ActionWrapper>
    <div class="flex flex-col">
      <div class="mb-2 flex items-center justify-start">
        <IconArrowUpWeight class="-mt-2 -ml-2" />
      </div>
      <Title4
        class="font-bold mb-4 text-left"
        color="primary"
      >
        {{ $gettext('По данной паллете есть несколько поставок') }}
      </Title4>
      <div class="text-primary mb-4 whitespace-pre-line text-left">
        {{ $gettext('Выберите дату поставки, для которой проводится КТК') }}
      </div>
      <RadioGroup
        v-model="date"
        :items="reasons"
      />
      <div class="flex mt-6">
        <UiButton
          class="mr-2"
          background-color="secondary"
          @click="reject"
        >
          {{ $gettext('Отмена') }}
        </UiButton>
        <UiButton
          background-color="primary"
          :is-disabled="!date"
          data-test="choose-date-modal continue-btn"
          @click="submit"
        >
          {{ $gettext('Подтвердить') }}
        </UiButton>
      </div>
    </div>
  </ActionWrapper>
</template>

<script lang="ts">
import { getDefaultLocalFormat } from '@/temp/constants/dateFormat';
import IconArrowUpWeight from '@/temp/icons/icon-arrow-up-weight.vue';
import RadioGroup from '@/temp/ui/inputs/radio-group/radio-group.vue';
import ActionWrapper from '@/ui/common/notifications/actionWrapper.vue';
import Title4 from '@/ui/common/typo/title-4.vue';
import UiButton from '@/ui/common/ui-button.vue';
import dayjs from 'dayjs';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'ControlCheckChooseDateModal',
  components: {
    UiButton,
    Title4,
    RadioGroup,
    IconArrowUpWeight,
    ActionWrapper,
  },
  props: {
    dates: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  emits: ['btn-click'],
  data() {
    return {
      date: '',
    };
  },
  computed: {
    reasons() {
      return this.dates.reduce((acc, cur) => {
        acc[cur] = dayjs(cur).format(getDefaultLocalFormat());
        return acc;
      }, {});
    },
  },
  methods: {
    reject() {
      this.$emit('btn-click', 'reject');
    },
    submit() {
      this.$emit('btn-click', this.date);
    },
  },
});
</script>
