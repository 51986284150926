import { api } from '@/fsd/data/api/api.service';
import { getBarcodeWithoutWeight } from '@/fsd/shared/tools/barcode';
import { unpackTrueMark } from '@/fsd/shared/tools/unpackTrueMark';
import Product from '@/models/Product';
import ProductByBarcode from '@/models/ProductByBarcode';
import productQueue from '@/services/queue/product-queue';
import { ProductsResponse } from '@/services/response';
import { createCacheValidator } from '@/store/utils/common';
import { logger } from '@/temp/plugins/logs';
import { AvailableProduct } from '@/types/product';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { defineStore } from 'pinia';

const cv = createCacheValidator();

interface Availables {
  has_stocks: boolean;
  found: AvailableProduct[];
}

export const useProducts = defineStore('products', {
  state() {
    return {
      products: new Map<Product['product_id'], Product>(),
      availableProducts: new Map<Product['product_id'], Availables>(),
    };
  },
  getters: {
    productById:
      state =>
        (id?: string): Product | undefined => {
          if (!id) return undefined;
          useProducts().checkNeedReload(id);
          return state.products.get(id) as Product;
        },
    productByBarcode:
      state =>
        (barcode: string): Product | undefined => {
          for (const [id, p] of state.products) {
            if (p.barcode.includes(barcode)) {
              useProducts().checkNeedReload(id);
              return p as Product;
            }
          }
        },
    availableByProductId: state => (product_id?: Product['product_id']) => {
      if (!product_id) return undefined;
      return state.availableProducts.get(product_id)?.found;
    },
    hasStocks: state => (product_id: Product['product_id']) => {
      return state.availableProducts.get(product_id)?.has_stocks;
    },
  },
  actions: {
    /**
     * Ф-я для поиска продукта в локальном кеше, пробует распаковывать марку и чистить вес из ШК
     *
     * @param {string} barcode
     * @returns {Promise<Product[] | null>}
     */
    getProductsByBarcodeLocal(barcode: string): Product[] {
      const finder = (barcode: string): Product[] => {
        const result: Product[] = [];
        for (const p of this.products.values()) {
          if (p.barcode.includes(barcode)) {
            result.push(p as Product);
          }
        }
        result.forEach(p => this.checkNeedReload(p.product_id));
        return result;
      };
      //   Обработка простых ШК
      let productFromCache = finder(barcode);
      // Нашли сразу по ШК без обработки
      if (productFromCache.length) return productFromCache;
      // Пробуем почистить ШК от веса и поискать  еще раз
      const barcodeWithoutWeight = getBarcodeWithoutWeight(barcode);
      if (barcodeWithoutWeight) {
        productFromCache = finder(barcodeWithoutWeight);
        if (productFromCache.length) return productFromCache;
      }

      const unpackedMark = unpackTrueMark(barcode);
      if (!unpackedMark) return [];

      return this.getProductsByGtin(unpackedMark.gtin);
    },
    /**
     * Возвращает продукты по переданному баркоду, сначала ищем в кэше, после в апи
     *
     * @async
     * @param {string} barcode
     * @returns {Promise<Product[] | null>}
     */
    async getProductsByBarcodeAsync(barcode: string): Promise<Product[]> {
      // Сперва идем в кеш
      const productsFromCache = this.getProductsByBarcodeLocal(barcode);
      if (productsFromCache.length) return productsFromCache;
      //   Если в кеше нет, идем в апи
      try {
        const { data } = await api.barcode({ barcode });
        const product_ids = data.found.filter(ProductByBarcode.isProductByBarcode).map(p => p.product_id);
        if (product_ids.length > 0) {
          const products = await this.getProductsByIds(product_ids);
          return products || [];
        }
        return [];
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    /**
     * Ф-я для поиска продуктов по GTIN
     *
     * @param {string} gtin
     * @returns {(Product[] | null)}
     */
    getProductsByGtin(gtin: string): Product[] {
      return (Array.from(this.products.values()) as Product[]).filter((p) => {
        if (p.gtin === gtin || p.barcode.includes(gtin)) {
          this.checkNeedReload(p.product_id);
          return true;
        }
        return false;
      });
    },
    set(products: Product[]) {
      products.forEach((product) => {
        this.products.set(product.product_id, product);
        cv.set(product.product_id);
      });
    },
    clear() {
      this.products.clear();
      this.availableProducts.clear();
      cv.clear();
    },
    async fetchAvailable(product_id: string): Promise<AvailableProduct[] | undefined> {
      if (!product_id) return;
      try {
        const { data } = await api.stock.available({ product_id });
        this.availableProducts.set(product_id, {
          found: data.found,
          has_stocks: data.has_stocks,
        });
        return data.found;
      } catch (e) {
        logger.error(e, {
          method: 'available',
          type: 'api',
          source: 'products.ts',
        });
      }
    },
    async getProductsByIds(ids: string[]) {
      ids = ids.filter(id => !!id);
      ids = [...new Set(ids)];
      if (ids.length === 0) return;
      const findProducts: Product[] = [];
      const productsToFetchIds: string[] = [];
      ids.forEach((id) => {
        const product = this.productById(id);
        if (product) findProducts.push(product);
        else productsToFetchIds.push(id);
      });
      if (productsToFetchIds.length > 0) {
        try {
          const products = (await productQueue.loadMany(productsToFetchIds)).filter(Product.isProduct);
          if (products) {
            findProducts.push(...products);
          }
        } catch (e) {
          logger.error(e, {
            method: 'addToQueue',
            type: 'api',
            source: 'products.ts',
          });
        }
      }
      return findProducts;
    },
    async getProductById(id: string): Promise<Product | undefined> {
      const product = this.productById(id);
      if (product) return product;
      try {
        return await productQueue.load(id);
      } catch (e) {
        logger.error(e, {
          method: 'addToQueue',
          type: 'api',
          source: 'products.ts',
        });
        return undefined;
      }
    },
    async getProductByBarcode(barcode: string): Promise<Product> {
      let product: Product | undefined = this.productByBarcode(barcode);
      if (!product) {
        const { data } = await api.barcode({ barcode });
        const found = data.found[0];
        if (!found || !ProductByBarcode.isProductByBarcode(found)) throw new Error('wrong barcode');
        if (!product) {
          try {
            await productQueue.load(found.product_id);
          } catch (e) {
            logger.error(e, {
              method: 'addToQueue',
              type: 'api',
              source: 'products.ts',
            });
          }
        }
        product = this.productById(found.product_id);
      }
      if (!product) {
        throw Error('Продукт не был загружен');
      }
      return product;
    },
    async loadProducts({
      ids,
      config,
    }: {
      ids: string[];
      config?: AxiosRequestConfig;
    }): Promise<AxiosResponse<ProductsResponse>> {
      const response = await api.products({ ids }, config);
      this.set(response.data.products);
      return response;
    },
    checkNeedReload(id: string) {
      if (cv.check(id) === false) {
        productQueue.load(id);
      }
    },
    checkValidCache(id: string) {
      return cv.check(id);
    },
  },
});
