<template>
  <div
    class="keyboard"
    :data-test="`keyboard ${isVisible ? 'open' : 'close'}`"
    :class="{ openKeyboard: isVisible, closeKeyboard: !isVisible, animation: isVisible !== undefined }"
  >
    <div class="keyboard-row">
      <div
        class="keyboard-cell"
        data-test="keyboard key 1"
        @click="onKeyClick('1')"
      >
        1
      </div>
      <div
        class="keyboard-cell"
        data-test="keyboard key 2"
        @click="onKeyClick('2')"
      >
        2
      </div>
      <div
        class="keyboard-cell"
        data-test="keyboard key 3"
        @click="onKeyClick('3')"
      >
        3
      </div>
    </div>
    <div class="keyboard-row">
      <div
        class="keyboard-cell"
        data-test="keyboard key 4"
        @click="onKeyClick('4')"
      >
        4
      </div>
      <div
        class="keyboard-cell"
        data-test="keyboard key 5"
        @click="onKeyClick('5')"
      >
        5
      </div>
      <div
        class="keyboard-cell"
        data-test="keyboard key 6"
        @click="onKeyClick('6')"
      >
        6
      </div>
    </div>
    <div class="keyboard-row">
      <div
        class="keyboard-cell"
        data-test="keyboard key 7"
        @click="onKeyClick('7')"
      >
        7
      </div>
      <div
        class="keyboard-cell"
        data-test="keyboard key 8"
        @click="onKeyClick('8')"
      >
        8
      </div>
      <div
        class="keyboard-cell"
        data-test="keyboard key 9"
        @click="onKeyClick('9')"
      >
        9
      </div>
    </div>
    <div class="keyboard-row">
      <div
        v-if="type === 'temperature'"
        class="keyboard-cell"
        data-test="keyboard key -"
        @click="onKeyClick('-')"
      >
        -
      </div>
      <div
        v-else
        class="keyboard-cell"
        data-test="keyboard key ."
        @click="onKeyClick('.')"
      >
        .
      </div>
      <div
        class="keyboard-cell"
        data-test="keyboard key 0"
        @click="onKeyClick('0')"
      >
        0
      </div>
      <div
        class="keyboard-cell"
        data-test="keyboard key D"
        @click="onKeyClick('D')"
      >
        <img
          src="./img/delete.svg"
          alt=""
        >
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    isVisible: {
      type: Boolean,
      default: undefined,
    },
    type: {
      type: String as PropType<'pin' | 'number' | 'temperature' | 'date'>,
      default: 'number',
    },
  },
  emits: ['key-click'],
  methods: {
    onKeyClick(key) {
      this.$emit('key-click', key);
    },
  },
});
</script>

<style scoped lang="scss">
.keyboard {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  background-color: var(--card-bg);
}

.keyboard-row {
  display: flex;
}

.keyboard-cell {
  flex: 1 1 0;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: var(--text-primary-color);
}

@keyframes open {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 250px;
  }
}

@keyframes close {
  0% {
    max-height: 250px;
  }
  100% {
    max-height: 0;
  }
}

.openKeyboard {
  max-height: 250px;

  &.animation {
    animation: open 0.3s linear;
  }
}

.closeKeyboard {
  max-height: 0;

  &.animation {
    animation: close 0.3s linear;
  }
}
</style>
