<template>
  <Bar
    :icons="icons"
    @close-click="emits('close')"
  />
</template>
<script setup lang="ts">
import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import Suggest from '@/models/Suggest';
import Bar from '@/ui/common/bar/bar.vue';

interface RetailHeaderDetails {
  order: OrderOrderRetail;
  suggest?: Suggest;
}

const props = defineProps<RetailHeaderDetails>();

const icons = () => {
  if (props.suggest) {
    return {};
  } else {
    return { left: 'back' };
  }
};

const emits = defineEmits<{
  (e: 'close'): void;
}>();
</script>
