<template>
  <label
    class="inline-block relative cursor-pointer w-full box-border align-middle"
    :class="{
      'pointer-events-none': disabled,
      'opacity-60': disabled,
    }"
    tabindex="0"
    role="checkbox"
    data-test="toggle-button"
    @keydown.space.prevent="keyToggle"
  >
    <input
      type="checkbox"
      class="absolute w-px h-px opacity-0"
      :checked="value"
      :disabled="disabled"
      tabindex="-1"
      @change.stop="toggle"
    >

    <div
      class="relative rounded-2xl bg-day-controlMinor border-2 border-day-controlMinor h-12 transition-all delay-300"
    >
      <div
        class="flex items-center absolute overflow-hidden bg-white text-base h-full w-6/12 justify-center rounded-2xl top-0 left-0 text-warmGray-600 z-1"
        :style="buttonStyle"
      >
        {{ toggled ? labels.unchecked : labels.checked }}
      </div>
    </div>
    <div
      class="absolute w-6/12 h-full top-0 flex justify-center items-center"
      :class="{ 'left-0': toggled, 'right-0': !toggled }"
    >
      <span class="text-day-textMinor font-base">{{ toggled ? labels.checked : labels.unchecked }}</span>
    </div>
  </label>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

interface Labels {
  checked: string;
  unchecked: string;
}

interface Data {
  toggled: boolean;
}

export default defineComponent({
  name: 'ToggleButton',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    sync: {
      type: Boolean,
      default: false,
    },
    labels: {
      type: Object as PropType<Labels>,
      required: true,
    },
  },
  emits: ['input', 'change'],
  data(): Data {
    return {
      toggled: this.value,
    };
  },
  computed: {
    buttonStyle(): { [key: string]: string } {
      const transform = this.toggled ? 'translate(100%, 0)' : 'translate(0, 0)';
      return {
        transition: 'transform 300ms',
        transform,
      };
    },
  },
  watch: {
    value(value) {
      if (this.sync) {
        this.toggled = !!value;
      }
    },
  },
  methods: {
    keyToggle(event): void {
      if (!this.disabled) {
        this.toggle(event);
      }
    },

    toggle(event): void {
      const toggled = !this.toggled;
      if (!this.sync) {
        this.toggled = toggled;
      }
      this.$emit('input', toggled);
      this.$emit('change', {
        value: toggled,
        srcEvent: event,
      });
    },
  },
});
</script>
