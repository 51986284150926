import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import Suggest from '@/models/Suggest';

/**
 * Возвращает true, если для товара разрешен ручной ввод количества собранных товаров
 * @param suggest
 */
export const checkManualInputAllowed = (suggest: Suggest | TrueMarkSuggestWrapper) => {
  const allowManualWeightInput
    = useUser().experimentByName(experiments.exp_manual_weight_input)
      && suggest.product?.isTrueWeight;

  const allowManualCollectedInput
    = useUser().experimentByName(experiments.exp_manual_collected_input)
      && !suggest.product?.isTrueWeight
      && !suggest.conditions.need_true_mark;

  return (
    !TrueMarkSuggestWrapper.isTrueMarkSuggestWrapper(suggest)
    && (allowManualWeightInput || allowManualCollectedInput)
  );
};
