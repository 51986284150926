import {
  getBlockedSuggests,
  getBox2ShelfSuggests,
  getDoneSuggests,
  getEmptyDoneSuggests,
  getFullDoneSuggests,
  getPartDoneSuggests,
  getRequestSuggests,
} from '@/fsd/entities/filters/suggestsFilters';
import Suggest from '@/models/Suggest';
import BaseOrder, { OrderTargetEnum, OrderTypeEnum } from '@/models/orders/BaseOrder';
import { useOrders } from '@/store/modules/orders';
import { $gettext } from '@/temp/plugins/gettext';
import { getDefaultProgressConfig } from '@/ui/common/bar/getDefaultProgressConfig';
import { FilterMenuItemConfig } from '@/ui/common/filter-menu/types';
import { computed, ref } from 'vue';
import { mergeTrueMarkSuggests } from './mergeTrueMarkSuggests';

export enum FiltersEnum {
  all = 'all',
  done = 'done',
  request = 'request',
  part = 'part',
  noProduct = 'noProduct',
}

export const useFilter = <F extends string = never>(order_id: BaseOrder['order_id'], mergeSuggest = false) => {
  type FilterType = FiltersEnum | F;

  const filter = ref<FilterType>(FiltersEnum.all);

  const setFilter = (value: FilterType) => {
    filter.value = value;
  };

  const order = computed(() => useOrders().orderById(order_id));

  const suggests = computed<Suggest[]>(() => {
    if (!order.value || !order.value.suggests) {
      return [];
    }
    return mergeSuggest ? mergeTrueMarkSuggests(order.value.suggests) : order.value.suggests;
  });

  const completedSuggests = computed<Suggest[]>(() => getDoneSuggests(suggests.value));

  const fullCompletedSuggests = computed<Suggest[]>(() => getFullDoneSuggests(suggests.value));

  const partiallyCompletedSuggests = computed<Suggest[]>(() => getPartDoneSuggests(suggests.value));

  const noProductSuggests = computed<Suggest[]>(() => getEmptyDoneSuggests(suggests.value));

  const requestSuggests = computed<Suggest[]>(() => getRequestSuggests(suggests.value));

  const blockedSuggests = computed<Suggest[]>(() => getBlockedSuggests(suggests.value));

  const filteredSuggests = computed<Suggest[]>(() => {
    switch (filter.value) {
      case FiltersEnum.all:
        return suggests.value;
      case FiltersEnum.done:
        return fullCompletedSuggests.value;
      case FiltersEnum.request:
        return requestSuggests.value;
      case FiltersEnum.part:
        return partiallyCompletedSuggests.value;
      case FiltersEnum.noProduct:
        return noProductSuggests.value;
    }
    return suggests.value;
  });

  const filterMenuConfig = computed<FilterMenuItemConfig[]>(() => {
    return [
      {
        buttonText: $gettext('Не отсканированные'),
        color: 'secondary',
        count: requestSuggests.value.length,
        onClick: () => setFilter(FiltersEnum.request),
      },
      {
        buttonText: $gettext('Нет'),
        color: 'red',
        count: noProductSuggests.value.length,
        onClick: () => setFilter(FiltersEnum.noProduct),
      },
      {
        buttonText: $gettext('Почти'),
        color: 'orange',
        count: partiallyCompletedSuggests.value.length,
        onClick: () => setFilter(FiltersEnum.part),
      },
      {
        buttonText: $gettext('Готово'),
        color: 'green',
        count: fullCompletedSuggests.value.length,
        onClick: () => setFilter(FiltersEnum.done),
      },
    ];
  });

  const progressConfig = computed(() => {
    if (!order.value) return;
    if (order.value.type === OrderTypeEnum.order) {
      if (order.value.target === OrderTargetEnum.canceled) {
        return [
          {
            count: getBox2ShelfSuggests(fullCompletedSuggests.value).length,
            color: 'green',
          },
        ];
      }
    }
    return getDefaultProgressConfig(suggests.value);
  });

  return {
    filter,
    setFilter,
    suggests,
    completedSuggests,
    fullCompletedSuggests,
    partiallyCompletedSuggests,
    noProductSuggests,
    requestSuggests,
    blockedSuggests,
    filteredSuggests,
    filterMenuConfig,
    progressConfig,
  };
};
