import {
  getBox2ShelfSuggests,
  getRequestSuggests,
  getShelf2BoxSuggests,
  getSuggestsByStatus,
  getSuggestsByStatusAndType,
} from '@/fsd/entities/filters/suggestsFilters';
import { SuggestStatusEnum, SuggestTypeEnum } from '@/models/Suggest';
import BaseOrder, {
  OrderEstatusEnum,
  OrderTargetEnum,
  OrderTypeEnum,
  OrderWorkStatusEnum,
} from '@/models/orders/BaseOrder';
import { isClientOrder } from '@/models/orders/ClientOrder';
import { isOrderOrder } from '@/models/orders/OrderOrder';
import { useOrders } from '@/store/modules/orders';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { $gettext, $ngettext } from '@/temp/plugins/gettext';
import { isProductInRequired } from '@/types/product';
import { uniq } from 'lodash';
import { computed } from 'vue';
import { mergeTrueMarkSuggests } from '@/fsd/entities/suggest/tools/mergeTrueMarkSuggests';
import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';

export const getActionsCount = (order_id: BaseOrder['order_id']) => {
  const expTrueMarkMerged = useUser().experimentByName(experiments.exp_true_mark_merged);

  const order = computed(() => {
    return useOrders().orderById(order_id);
  });

  const needShowActionsCountRow = computed(() => {
    if (!order.value) return false;
    // Если не ордер, то показываем кол-во всегда
    if (!isOrderOrder(order.value)) return true;
    // Если документ в работе, то также показываем
    if (order.value.status === OrderWorkStatusEnum.processing) return true;
    // Если включена очередь, то показываем для первого документа
    if (!useUser().experimentByName(experiments.exp_black_jack)) return false;

    const firstRequestOrder = useOrders().orderOrders.find(o => o.status === OrderWorkStatusEnum.request);
    if (!firstRequestOrder) return false;
    return firstRequestOrder.order_id === order_id;
  });

  const request = computed(() => {
    if (!order.value) return 0;

    if (order.value.status === OrderWorkStatusEnum.request) {
      switch (order.value.type) {
        case OrderTypeEnum.check_more:
          return order.value.shelves.length;
        case OrderTypeEnum.check_valid_regular:
        case OrderTypeEnum.check_valid_short:
        case OrderTypeEnum.writeoff_prepare_day:
          return 0;
        case OrderTypeEnum.hand_move:
          return uniq(order.value.required.filter(isProductInRequired).map(r => r.product_id)).length;
        default:
          return order.value.required.length;
      }
    }

    if (order.value.target === OrderTargetEnum.canceled) {
      switch (order.value.type) {
        case OrderTypeEnum.check_valid_regular:
        case OrderTypeEnum.check_valid_short:
        case OrderTypeEnum.writeoff_prepare_day:
        case OrderTypeEnum.order:
        case OrderTypeEnum.order_retail:
        case OrderTypeEnum.shipment:
        case OrderTypeEnum.visual_control:
          return getSuggestsByStatusAndType(order.value.suggests, SuggestStatusEnum.request, SuggestTypeEnum.box2shelf)
            .length;
        case OrderTypeEnum.refund:
        case OrderTypeEnum.sale_stowage:
        case OrderTypeEnum.stowage_market:
        case OrderTypeEnum.weight_stowage:
          return getSuggestsByStatusAndType(order.value.suggests, SuggestStatusEnum.request, SuggestTypeEnum.shelf2box)
            .length;
        case OrderTypeEnum.hand_move:
          return uniq(getRequestSuggests(order.value.suggests).map(s => s.product_id)).length;
        default:
          return getSuggestsByStatus(order.value.suggests, [SuggestStatusEnum.request, SuggestStatusEnum.blocked])
            .length;
      }
    }

    if (order.value.status === OrderWorkStatusEnum.processing) {
      switch (order.value.type) {
        case OrderTypeEnum.order:
        case OrderTypeEnum.order_retail:
          return order.value.suggests.filter(s => !s.isPackaging).filter(s => s.status !== SuggestStatusEnum.done)
            .length;
        case OrderTypeEnum.hand_move:
          return uniq(getRequestSuggests(order.value.suggests).map(s => s.product_id)).length;
        default:
          return getSuggestsByStatus(order.value.suggests, [SuggestStatusEnum.request, SuggestStatusEnum.blocked])
            .length;
      }
    }
    return 0;
  });

  const all = computed<number>(() => {
    if (!order.value) return 0;
    if (order.value.status === OrderWorkStatusEnum.request) {
      switch (order.value.type) {
        case OrderTypeEnum.check_more:
          return order.value.shelves.length;
        case OrderTypeEnum.check_valid_regular:
        case OrderTypeEnum.check_valid_short:
        case OrderTypeEnum.writeoff_prepare_day:
          return 0;
        case OrderTypeEnum.hand_move:
          return uniq(order.value.required.filter(isProductInRequired).map(p => p.product_id)).length;
        default:
          return order.value.required.length;
      }
    }

    if (order.value.target === OrderTargetEnum.canceled) {
      switch (order.value.type) {
        case OrderTypeEnum.check_more:
          return order.value.suggests.filter(s => !s.product_id).length;
        case OrderTypeEnum.check_valid_regular:
        case OrderTypeEnum.check_valid_short:
        case OrderTypeEnum.writeoff_prepare_day:
        case OrderTypeEnum.order:
        case OrderTypeEnum.shipment:
        case OrderTypeEnum.visual_control:
        case OrderTypeEnum.order_retail:
          return getBox2ShelfSuggests(order.value.suggests).length;
        case OrderTypeEnum.hand_move:
          return uniq(order.value.suggests.map(s => s.product_id)).length;
        case OrderTypeEnum.sale_stowage:
        case OrderTypeEnum.stowage_market:
        case OrderTypeEnum.weight_stowage:
        case OrderTypeEnum.refund:
          return getShelf2BoxSuggests(order.value.suggests).length;
        default:
          return order.value.suggests.length;
      }
    }

    if (order.value.status === OrderWorkStatusEnum.processing) {
      switch (order.value.type) {
        case OrderTypeEnum.check_more:
          return order.value.suggests.filter(s => !s.product_id).length;
        case OrderTypeEnum.order:
        case OrderTypeEnum.order_retail:
          return order.value.suggests.filter(s => !s.isPackaging).length;
        case OrderTypeEnum.hand_move:
        case OrderTypeEnum.sale_stowage:
        case OrderTypeEnum.stowage_market:
        case OrderTypeEnum.weight_stowage:
          return uniq(order.value.suggests.map(s => s.product_id)).length;
        default:
          return order.value.suggests.length;
      }
    }

    return 0;
  });

  const requestActionsCount = computed(() => {
    if (!order.value) return '';

    switch (order.value.type) {
      case OrderTypeEnum.check_more:
      case OrderTypeEnum.inventory_check_more:
        return $ngettext('%{n} полка', '%{n} полок', request.value, {
          n: String(request.value),
        });

      case OrderTypeEnum.check_valid_regular:
      case OrderTypeEnum.check_valid_short:
      case OrderTypeEnum.writeoff_prepare_day:
      case OrderTypeEnum.order:
      case OrderTypeEnum.order_retail:
      case OrderTypeEnum.repacking:
      case OrderTypeEnum.writeoff:
        return '';
      case OrderTypeEnum.hand_move:
      case OrderTypeEnum.kitchen_provision:
      case OrderTypeEnum.robot_provision:
        return $ngettext('%{n} позиция от %{date}', '%{n} позиций от %{date}', all.value, {
          n: String(all.value),
          date: order.value.date,
        });
      default:
        return $ngettext('%{n} товар', '%{n} товаров', request.value, {
          n: String(request.value),
        });
    }
  });

  const getCountParams = () => {
    let requestCount = request.value;
    let allCount = all.value;

    // При экспе в процессинге нужно показывать объединенные саджесты
    if (expTrueMarkMerged && order.value?.suggests.length) {
      // * все саджесты, кроме пакетов
      const suggests = order.value.suggests.filter(s => !s.isPackaging);
      const mergedSuggests = mergeTrueMarkSuggests(suggests);

      // * саджесты в статусе request
      const requestSuggests = mergedSuggests.filter((suggest) => {
        // * Для враппера товар считается в request, если хотя бы один его саджест не закрыты
        if (TrueMarkSuggestWrapper.isTrueMarkSuggestWrapper(suggest)) {
          return suggest.suggests.every(s => s.status !== SuggestStatusEnum.done);
        }
        return suggest.status !== SuggestStatusEnum.done;
      });

      requestCount = requestSuggests.length;
      allCount = mergedSuggests.length;
    }

    return {
      requestCount,
      allCount,
    };
  };

  const getDefaultTextProcessing = () => {
    if (request.value === 0) {
      return $gettext('Все собрано');
    }

    const { requestCount, allCount } = getCountParams();

    const params = {
      request: String(requestCount),
      all: String(allCount),
    };

    return $ngettext(
      'Осталось %{request} из %{all} товар', 'Осталось %{request} из %{all} товаров',
      allCount,
      params,
    );
  };

  const processingActionsCount = computed(() => {
    if (!order.value) return '';
    if (order.value.estatus === OrderEstatusEnum.waiting_signal) {
      return $gettext('Ожидается завершение в диспетчерской');
    }

    switch (order.value.type) {
      case OrderTypeEnum.check_more:
        return $ngettext('Осталось %{request} из %{all} полка', 'Осталось %{request} из %{all} полок', all.value, {
          request: String(request.value),
          all: String(all.value),
        });
      case OrderTypeEnum.inventory_check_more:
        return $ngettext('Осталось %{request} полка', 'Осталось %{request} полок', request.value, {
          request: String(request.value),
        });
      case OrderTypeEnum.order:
        if (isOrderOrder(order.value)) {
          if (order.value.canCollectRobozone && !order.value.areRoboRacksArrived) {
            return $gettext('Роботы везут товары');
          }
          if (order.value.hasOnlyBlockedSuggests && order.value.hasBlockedKitchenSuggests) {
            return $gettext('Блюда с кухни ещё готовятся');
          }
          if (order.value.isOrderPaused) {
            return $gettext('Ожидайте решения поддержки');
          }
        }
        return getDefaultTextProcessing();
      case OrderTypeEnum.order_retail: {
        if (isClientOrder(order.value)) {
          const isConfirming = order.value.vars.confirming_assembled_products;
          const isConfirmAssembled = order.value.vars.fulfilled_conditions?.confirm_assembled_products;

          if (isConfirming && !isConfirmAssembled) {
            return $gettext('Идёт согласование');
          }

          if (isConfirmAssembled) {
            return $gettext('Согласование завершено');
          }
        }

        if (request.value === 0) {
          return $gettext('Все собрано');
        }

        const countText = $ngettext('Осталось %{request} из %{all} товар', 'Осталось %{request} из %{all} товаров', all.value, {
          request: String(request.value),
          all: String(all.value),
        });

        const problemsText = order.value.problems?.length
          ? $ngettext('(%{n} проблема)', '(%{n} проблем)', order.value.problems.length, {
              n: String(order.value.problems.length),
            })
          : '';

        return countText + problemsText;
      }
      case OrderTypeEnum.repacking:
        return '';
      default:
        if (request.value === 0) {
          return $gettext('Все собрано');
        }
        return $ngettext('Осталось %{request} из %{all} товар', 'Осталось %{request} из %{all} товаров', all.value, {
          request: String(request.value),
          all: String(all.value),
        });
    }
  });

  const canceledActionsCount = computed(() => {
    if (!order.value) return '';
    switch (order.value.type) {
      case OrderTypeEnum.repacking:
        return '';
      default: {
        const { requestCount, allCount } = getCountParams();

        const params = {
          request: String(requestCount),
          all: String(allCount),
        };

        return $ngettext(
          'Осталось вернуть %{request} из %{all} товар',
          'Осталось вернуть %{request} из %{all} товаров',
          allCount,
          params,
        );
      }
    }
  });

  const actionsCount = computed(() => {
    if (!needShowActionsCountRow.value) return '';
    if (!order.value) return '';

    switch (true) {
      case order.value.status === OrderWorkStatusEnum.request:
        return requestActionsCount.value;
      case order.value.target === OrderTargetEnum.canceled:
        return canceledActionsCount.value;
      case order.value.status === OrderWorkStatusEnum.processing:
        return processingActionsCount.value;

      default:
        return '';
    }
  });

  return { actionsCount };
};
