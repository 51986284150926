/**
 * Массив, ограниченный по своей длине на величину size
 * Нужен для того, чтобы ТСД не запоминала ВСЕ отсканированные баркоды
 */
export class SizedArray<T> extends Array<T> {
  constructor(public size: number) {
    super();
  }

  unshift(item: T) {
    if (this.length >= this.size) {
      this.pop();
      return super.unshift(item);
    }
    return super.unshift(item);
  }
}
