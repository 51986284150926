import { ScheduleTypeEnum } from '@/models/Product';
import { ShelfTypeEnum } from '@/models/Shelf';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import OrderEvent from '@/models/events/OrderEvent';
import BaseOrder, { OrderTypeEnum } from '@/models/orders/BaseOrder';
import ClientOrder from '@/models/orders/ClientOrder';
import { useOrders } from '@/store/modules/orders';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import dayjs from 'dayjs';

interface Conditions {
  fragile: boolean;
  // флаг, что показывает, что нужно отправить сигнал на согласование
  confirm_assembled_products: boolean;
}

export default class OrderOrder extends ClientOrder {
  // специфично для клиентского заказа, если null - паузы нет. если строка - время до которого выставлена пауза(по времени бека)
  public paused_until: string | null = null;
  public isOrderPaused: boolean = false;
  public type: OrderTypeEnum.order = OrderTypeEnum.order;
  public conditions: Conditions = {
    fragile: false,
    confirm_assembled_products: false,
  };

  constructor(data: any) {
    super(data);
    this.paused_until = data.paused_until;
    this.conditions = data.conditions;
    if (data.paused_until) {
      this.isOrderPaused = this.isPaused();
      if (this.isOrderPaused) {
        this.startPauseTimeout();
      }
    }
  }

  updateFromEvent(event: OrderEvent) {
    this.self.paused_until = event.paused_until;
    this.self.isOrderPaused = this.isPaused();
    if (this.self.isOrderPaused) {
      this.startPauseTimeout();
    }
    return super.updateFromEvent(event);
  }

  private startPauseTimeout() {
    setTimeout(() => {
      this.updateOrderPause();
    }, this.getPausedTime() + 1000);
  }

  private updateOrderPause() {
    const order = useOrders().orderById(this.order_id) as any as OrderOrder;
    if (order) {
      order.isOrderPaused = this.isPaused();
    }
  }

  isPaused(): boolean {
    return Boolean(this.paused_until && dayjs().add(useUser().timeOffset) < dayjs(this.paused_until));
  }

  getPausedTime(): number {
    if (this.paused_until) {
      // проверяем наличие паузы, если она стоит, то блокируем кнопку. так как текущее время не реактивно
      // запускаем пересчет этого свойства после истечения времени
      if (dayjs().add(useUser().timeOffset) < dayjs(this.paused_until)) {
        return dayjs(this.paused_until).diff(dayjs().add(useUser().timeOffset));
      }
    }
    return 0;
  }

  get hasFragile() {
    return this.conditions.fragile;
  }

  // ts сходит с ума. хз пока как поправить
  get self() {
    if (this._self) {
      return this._self;
    }
    this._self = useOrders().orderById(this.order_id) as OrderOrder;
    return this._self;
  }

  get isRover() {
    return this.courier?.type === 'rover';
  }

  get hasBlockedSuggests() {
    return this.suggests.some(s => s.status === SuggestStatusEnum.blocked);
  }

  get hasOnlyBlockedSuggests() {
    // ищем заблокированный саджест с блюдом и проверяем, что никаких других доступных для сборки саджестов у нас нет.
    return (
      this.suggests.some(s => s.status === SuggestStatusEnum.blocked)
      && this.suggests.every(s => s.status === SuggestStatusEnum.blocked || s.status === SuggestStatusEnum.done || s.isPackaging)
    );
  }

  get hasKitchenSuggests() {
    // Кофе не всегда готовится на кухне, на некоторых складах оно готовится прямо на складе кладовщиком. в таком случае мы не хотим воспринимать кофе как ГЕ
    return this.suggests.some(
      s =>
        s.shelf?.type === ShelfTypeEnum.kitchen_on_demand
        && s.status === SuggestStatusEnum.request
        && (s.product?.schedule_type !== ScheduleTypeEnum.coffee
          || useUser().experimentByName(experiments.coffee_machine_in_the_kitchen)),
    );
  }

  /**
   * True, если в заказе есть заблокированные саджесты из кухни
   */
  get hasBlockedKitchenSuggests() {
    return this.suggests.some(
      s => s.shelf?.type === ShelfTypeEnum.kitchen_on_demand && s.status === SuggestStatusEnum.blocked,
    );
  }

  /**
   * True, если в заказе есть саджесты из робозоны
   */
  get hasRobozoneSuggests() {
    return this.suggests.some(s => s.isRobozone);
  }

  /**
   * True, если в заказе есть заблокированные саджесты из робозоны
   */
  get hasBlockedRobozoneSuggests() {
    return this.suggests.some(s => s.isRobozone && s.status === SuggestStatusEnum.blocked);
  }

  /**
   * True, если роботы привезли стеллажи
   */
  get areRoboRacksArrived() {
    return this.suggests.some(suggest => suggest.isRobozone && !suggest.isBlocked);
  }

  /**
   * True, если пользователь может начать собирать саджесты из робозоны
   */
  get canCollectRobozone() {
    if (!this.hasRobozoneSuggests) {
      return false;
    }

    const nonRobozoneSuggests = this.suggests.filter(suggest => !suggest.isRobozone && !suggest.isSample && !suggest.isPackaging);
    const allNonRobozoneSuggestsHandled = nonRobozoneSuggests.every(
      suggest => suggest.status !== SuggestStatusEnum.request,
    );
    return allNonRobozoneSuggestsHandled;
  }

  get hasCoffee() {
    // Имеется в виду есть ли в заказе кофе, что должен готовить сборщик.
    if (useUser().experimentByName(experiments.coffee_machine_in_the_kitchen)) return false;
    return this.suggests.some(
      s => s.shelf?.type === ShelfTypeEnum.kitchen_on_demand && s.product?.schedule_type === ScheduleTypeEnum.coffee,
    );
  }

  protected sorter(suggests: Suggest[]): Suggest[] {
    // саджесты на выдачу кухни всегда в самом конце.сортируем сперва по типу полки, потом по ордеру.
    const shelfTypeSorter = (a: Suggest, b: Suggest): -1 | 0 | 1 => {
      if (!a.shelf || !b.shelf) return 0;
      switch (true) {
        case a.shelf.type === ShelfTypeEnum.kitchen_on_demand
          && b.shelf.type === ShelfTypeEnum.kitchen_on_demand:
          return 0;
        case a.shelf.type === ShelfTypeEnum.kitchen_on_demand:
          return 1;
        case b.shelf.type === ShelfTypeEnum.kitchen_on_demand:
          return -1;
        default:
          return 0;
      }
    };

    const shelfOrderSorter = (a: Suggest, b: Suggest): number => {
      if (!a.shelf || !b.shelf) return 0;
      return a.shelf.order - b.shelf.order;
    };

    const samplingSuggestSorter = (a: Suggest, b: Suggest): number => {
      // сортируем саджесты с семлпами в самый конец
      return Number(a.isSample) - Number(b.isSample);
    };

    const coffeeKitchenSorter = (a: Suggest, b: Suggest): number => {
      // кофе в начале, заказы с кухней в конце
      return (
        Number(a.product?.schedule_type === ScheduleTypeEnum.kitchens)
        - Number(b.product?.schedule_type === ScheduleTypeEnum.kitchens)
      );
    };

    return suggests.sort(
      (a, b) =>
        samplingSuggestSorter(a, b) || coffeeKitchenSorter(a, b) || shelfTypeSorter(a, b) || shelfOrderSorter(a, b),
    );
  }

  get isAvailableForJunior() {
    const maxWeight = useUser().getMaxWeightForOrder(this.type);
    if (!maxWeight) return false;
    if (this.totalWeight > maxWeight) {
      return false;
    }
    return super.isAvailableForJunior;
  }
}

export const isOrderOrder = (o: BaseOrder): o is OrderOrder => {
  return o.type === OrderTypeEnum.order;
};
