<template>
  <Overlay @backdrop-click="$emit('backdrop-click')">
    <div
      class="defects-container"
      data-test="defect-image-card"
    >
      <Typography
        class="total-defects"
        type="h2"
      >
        {{ title }}
      </Typography>
      <div class="product">
        <div class="img-container-defect">
          <ImageWrapper :img-src="defect" />
        </div>
      </div>
      <Typography
        class="total-defects"
        type="h2"
      >
        {{ description }}
      </Typography>
      <div class="footer">
        <UiButton
          background-color="secondary"
          data-test="result-defects yes-btn"
          @click="$emit('backdrop-click')"
        >
          {{ $gettext('Закрыть') }}
        </UiButton>
      </div>
    </div>
  </Overlay>
</template>

<script lang="ts">
import ImageWrapper from '@/ui/common/image-wrapper.vue';
import Overlay from '@/ui/common/overlay/overlay.vue';
import Typography from '@/ui/common/typography.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Typography,
    Overlay,
    UiButton,
    ImageWrapper,
  },
  props: {
    defect: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
  },
  emits: ['backdrop-click'],
});
</script>

<style lang="scss" scoped>
.product {
  padding: 8px 24px;
  display: flex;
  flex: 1 1 0;
  justify-content: center;
}

.defects-container {
  display: flex;
  flex-direction: column;
  background: var(--main-bg);
  border-radius: 16px 16px 0 0;
  bottom: 0;
  transition: transform 200ms ease 0ms;
  transform: translateY(0);
  pointer-events: auto;
  margin: auto 0 0;
  height: 85%;
  overflow: auto;
}

.total-defects {
  margin: 20px 16px 4px 16px;
  text-align: center;
}
.defects-text {
  padding: 8px;
  text-align: center;
}

.footer {
  display: flex;
  padding: 10px 16px 12px 16px;
  & button:first-child {
    margin-right: 8px;
  }
}

.filler {
  flex: 1 1 0;
}
</style>
<style lang="scss">
.img-container-defect {
  height: 100%;
  width: 100%;
  .icon {
    object-fit: contain !important;
  }
}
</style>
