<template>
  <teleport to="#teleport-waiting">
    <Overlay>
      <div
        class="waiting-window-container"
        data-test="waiting-window main-container"
        :class="{ center: position === 'center', bottom: position === 'bottom' }"
      >
        <InlineLoader />
        <Typography
          align="center"
          margin="32px 0 0 0"
        >
          {{ time }}
        </Typography>
        <Typography
          type="h4"
          align="center"
        >
          {{ title }}
        </Typography>
        <Typography
          v-if="text"
          type="h2"
          align="center"
          margin="20px 0 0 0"
        >
          {{ text }}
        </Typography>
      </div>
    </Overlay>
  </teleport>
</template>

<script lang="ts">
import InlineLoader from '@/ui/common/inline-loader.vue';
import Overlay from '@/ui/common/overlay/overlay.vue';
import Typography from '@/ui/common/typography.vue';
import { defineComponent, PropType } from 'vue';

interface Data {
  uiState: {
    timer: number;
  };
  timerId?: number;
}

export default defineComponent({
  components: {
    Typography,
    Overlay,
    InlineLoader,
  },
  props: {
    timer: {
      type: Number,
      default: 60,
    },
    position: {
      type: String as PropType<'bottom' | 'center'>,
      default: 'bottom',
    },
  },
  data(): Data {
    return {
      uiState: {
        timer: 0,
      },
      timerId: undefined,
    };
  },
  computed: {
    title(): string {
      if (this.uiState.timer === 0) {
        return this.$gettext('Упс! Нам нужно чуть больше времени');
      }
      return this.$gettext('Пожалуйста, подождите');
    },
    text(): string {
      if (this.uiState.timer === 0) {
        return this.$gettext('Все в порядке! Пожалуйста, подождите еще немного.');
      }
      return this.$gettext('Создание задания обычно занимает 10-15 секунд');
    },
    time(): string {
      const minutes = Math.floor(this.uiState.timer / 60)
        .toString()
        .padStart(2, '0');
      const seconds = (this.uiState.timer % 60).toString().padStart(2, '0');
      return `${minutes}:${seconds}`;
    },
  },
  mounted() {
    this.uiState.timer = this.timer;
    this.startTimer();
  },
  unmounted() {
    if (this.timerId) {
      clearInterval(this.timerId);
      this.timerId = undefined;
    }
  },
  methods: {
    startTimer(): void {
      this.timerId = setInterval(() => {
        this.uiState.timer = this.uiState.timer - 1;
        if (this.uiState.timer === 0) {
          clearInterval(this.timerId);
          this.timerId = undefined;
        }
      }, 1000);
    },
  },
});
</script>

<style lang="scss" scoped>
.waiting-window-container {
  background: var(--main-bg);
  border-radius: 16px 16px 0 0;
  bottom: 0;
  padding: 32px 16px;
  transition: transform 200ms ease 0ms;
  transform: translateY(0);
  pointer-events: auto;
  &.center {
    border-radius: 16px;
    margin: auto 16px;
  }
  &.bottom {
    align-self: flex-end;
  }
}
</style>
