/**
 * Cостояние фильтра списка саджестов
 */
export const enum SuggestsFilterStateEnum {
  all = 'all',
  done = 'done',
  request = 'request',
  part = 'part',
  noProduct = 'noProduct',
}
