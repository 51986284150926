<template>
  <div class="flex flex-col gap-3">
    <div class="h-44 w-44 self-center">
      <ImageWrapper :img-src="product?.images[0]" />
    </div>
    <Body1
      data-test="product title"
      class="text-center font-medium"
    >
      {{ product?.title }}
    </Body1>
    <Caption1
      color="semantic-text-minor"
      class="text-center italic"
    >
      · {{ productStorageTypeTitle }} ·
    </Caption1>
  </div>
</template>

<script lang="ts" setup>
import Product from '@/models/Product';
import { useProducts } from '@/store/modules/products';
import { storageTypesInfo } from '@/temp/constants/translations';
import ImageWrapper from '@/ui/common/image-wrapper.vue';
import Body1 from '@/ui/common/typo/body-1.vue';
import Caption1 from '@/ui/common/typo/caption-1.vue';
import { computed, toRefs } from 'vue';

const props = defineProps<{ product_id: Product['product_id'] }>();
const { product_id } = toRefs(props);
const product = computed(() => {
  return useProducts().productById(product_id.value);
});

const productStorageTypeTitle = computed(() => {
  const tag = product.value?.tags[0] || 'empty';
  return storageTypesInfo[tag];
});
</script>
