<template>
  <input
    :id="bagsCountId"
    v-model="model"
    class="hidden"
    type="radio"
    :name="name"
    :value="value"
  >
  <label
    :data-test="props.dataTest"
    class="flex justify-center items-center w-full h-10 rounded-[13px]"
    :class="{
      'bg-control-minor ': model !== value,
      'bg-background-accent': model === value,
      'opacity-60': (maxBagsCount && Number(maxBagsCount) < Number(value)) || (maxBagsCount && maxBagsCount < 5 && value === '+'),
    }"
    :for="bagsCountId"
    @click="
      () => {
        if (maxBagsCount && maxBagsCount < 5 && value === '+') return;
        emit('click');
      }
    "
  >
    {{ value }}
  </label>
</template>

<script lang="ts" setup>
import {
  computed, defineEmits, defineProps,
} from 'vue';

const props = defineProps<{
  name: string;
  modelValue: string;
  value: string;
  dataTest?: string;
  maxBagsCount?: number;
}>();
const emit = defineEmits(['update:modelValue', 'click']);

const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    // Если мы нажем на '+', то нам прилетит Number('+') -> NaN
    if (Number.isNaN(Number(value)) || (props.maxBagsCount !== undefined && props.maxBagsCount < +value)) return;
    emit('update:modelValue', value);
  },
});

const bagsCountId = computed(() => `${props.value}-${props.name}`);
</script>
