import { CountryCodeEnum } from '@/models/Constants';
import { useUser } from '@/store/modules/user';

// В Разных странах маркировка имеет разное наименование. Сейчас для РФ это 'Честный знак', для Узбекистана - 'ASL Belgisi'
export const getTrueMarkType = () => {
  const countryCode = useUser().countryCode;
  if (countryCode === CountryCodeEnum.UZB) {
    return 'ASL Belgisi';
  }
  return 'Честный знак';
};
