import { useUser } from '@/store/modules/user';

export const checkPermits = (...permits: (string | [string, string])[]) => {
  return permits.every((permit) => {
    if (Array.isArray(permit)) {
      return checkPermit(...permit);
    } else {
      return checkPermit(permit);
    }
  });
};

export const checkPermit = (permit_name: string, permit_list_name?: string): boolean => {
  const permit = useUser().permitByName(permit_name);
  if (Array.isArray(permit) && permit_list_name) {
    return permit.includes(permit_list_name);
  } else {
    return Boolean(permit);
  }
};
