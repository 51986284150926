<template>
  <div class="empty">
    <Typography
      type="h3"
      align="center"
      margin="16px 16px 20px 16px"
    >
      {{ $gettext('Все задания выполнены. Можно немного отдохнуть') }}
    </Typography>
    <div class="image">
      <div class="image-container">
        <img
          src="./img/empty.svg"
          class="empty-image"
        >
        <img
          src="./img/zzz.svg"
          alt=""
          class="empty-image-z"
        >
      </div>
    </div>
  </div>
</template>

<script>
import Typography from '@/ui/common/typography.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { Typography },
});
</script>

<style scoped>
.empty {
  margin: 0 0 1rem;
}
.image {
  display: flex;
  justify-content: center;
}

.empty-image {
  margin: 0 auto;
  opacity: 0.72;
}

.image-container {
  position: relative;
}

.empty-image-z {
  position: absolute;
  right: -4rem;
  top: 0;
  opacity: 0.72;
}
</style>
