import IconRobotRounded from '@/fsd/shared/icons/robot/icon-robot-rounded.vue';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import { useComponent } from '@/hooks/useComponent';
import Shelf from '@/models/Shelf';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import { OrderTargetEnum } from '@/models/orders/BaseOrder';
import OrderOrder from '@/models/orders/OrderOrder';
import { $gettext } from '@/temp/plugins/gettext';
import { showRackArriveFailed } from '@/fsd/features/order/remainders/useCheckReadyRobozone';
import {
  ComputedRef, Ref, computed, markRaw, ref, watch,
} from 'vue';
import { useRouter } from 'vue-router';

interface UseRobozoneCollectProps {
  order: ComputedRef<OrderOrder | undefined>;
  suggestsForCollect: ComputedRef<Suggest[]>;
  activeSuggest: ComputedRef<Suggest | undefined>;
  activeSuggestIndex: Ref<number, number>;
  hideScanBarcodeCard: Ref<boolean>;
}

/**
 * Composable для обработки заказов с позициями из робозоны
 * @param params
 */
export const useRobozoneCollect = ({
  order,
  suggestsForCollect,
  activeSuggest,
  activeSuggestIndex,
  hideScanBarcodeCard,
}: UseRobozoneCollectProps) => {
  const router = useRouter();

  /**
   * True, если заказ отменен
   */
  const isOrderCancelled = computed((): boolean => {
    return order.value?.target === OrderTargetEnum.canceled || order.value?.target === OrderTargetEnum.failed;
  });

  /**
   * Компонент валидации стеллажа
   */
  const rackValidation = useComponent<Suggest[], Shelf>();

  /**
   * Словарь результатов валидации робостеллажей
   */
  const rackValidationResults = ref<Record<string, boolean>>({});

  /**
   * True, если нужно показать action валидации стеллажа
   */
  const needShowRackValidation = computed((): boolean => {
    const rack = activeSuggest.value?.shelf?.rack;

    return !!(
      order.value?.canCollectRobozone
      && activeSuggest.value?.isRobozone
      && rack != null
      && !rackValidationResults.value[rack]
      && order.value?.areRoboRacksArrived
      && activeSuggest.value.status === SuggestStatusEnum.request
    );
  });

  /**
   * True, если требуется показать уведомление о том, что стеллажи еще не приехали
   */
  const needShowRackNotArrivedMessage = computed((): boolean => {
    return !!(order.value?.canCollectRobozone && activeSuggest.value?.isRobozone && !order.value?.areRoboRacksArrived);
  });

  /**
   * True, если заказ должен быть отменен по причине того, что хотя бы один стеллаж не доехал
   */
  const orderShouldBeCancelled = computed<boolean>(() => {
    const hasNotArrivedRack = suggestsForCollect.value.some(suggest => suggest.vars?.rack_move_failed
      || (suggest.isRobozone && suggest.status === SuggestStatusEnum.error));

    return hasNotArrivedRack && order.value?.target !== OrderTargetEnum.canceled;
  });

  /**
   * Показывает модальное окно о том, что стеллажи еще не приехали на станцию
   */
  const showRackOnTheWayModal = async () => {
    await Modal.show({
      title: $gettext('Роботы ещё везут товары из заказа %{orderNum}', {
        orderNum: order.value?.orderNumberForView ?? '',
      }),
      text:
        $gettext(
          'Заказ на паузе, он не влияет на вашу скорость сборки. Упакуйте то, что уже собрано и подпишите пакеты.',
        )
        + '\n '
        + $gettext('Сообщим, когда стеллажи будут на месте'),
      btnPosition: ButtonPositionsEnum.horizontal,
      confirmBtnTitle: $gettext('На главную'),
      closeBtnTitle: $gettext('Отмена'),
      onConfirm: () => {
        router.push({ name: 'home' });
      },
      component: markRaw(IconRobotRounded),
    });
  };

  watch(needShowRackNotArrivedMessage, () => {
    if (needShowRackNotArrivedMessage.value) {
      showRackOnTheWayModal();
    }
  });

  watch(
    needShowRackValidation,
    async (val) => {
      if (!val || rackValidation.visible.value) return;

      try {
        hideScanBarcodeCard.value = true;
        const shelf = await rackValidation.asyncShow(suggestsForCollect.value);

        if (shelf?.rack != null) {
          rackValidationResults.value[shelf.rack] = true;
          const suggestIndexWithRack = suggestsForCollect.value.findIndex(
            suggest => suggest.shelf?.rack === shelf.rack,
          );
          activeSuggestIndex.value = suggestIndexWithRack;
        }
      } catch (e) {
        console.error(e);
      } finally {
        hideScanBarcodeCard.value = false;
      }
    },
    { immediate: true },
  );

  watch(isOrderCancelled, (newVal) => {
    if (newVal && rackValidation.visible.value) {
      rackValidation.hide();
    }
  });

  watch(
    orderShouldBeCancelled,
    async (val) => {
      const suggest = suggestsForCollect.value.find(suggest =>
        suggest.isRobozone
        && (suggest.vars.rack_move_failed || suggest.status === SuggestStatusEnum.error));

      if (!val || !suggest || !order.value) return;

      showRackArriveFailed(order.value, suggest, false);
    },
    { immediate: true },
  );

  return { rackValidation };
};
