<template>
  <ConfigIcon />
</template>
<script setup lang="ts">
import IconMenu from '@/fsd/shared/icons/IconMenu.vue';
import IconCancel from '@/fsd/shared/icons/icon-cancel.vue';
import IconHistory from '@/fsd/shared/icons/icon-history.vue';
import IconInfo from '@/fsd/shared/icons/icon-info.vue';
import IconTrueMark from '@/fsd/shared/icons/icon-true-mark.vue';
import PencilToLine from '@/fsd/shared/icons/pencil-to-line.vue';
import TrueMarkCancel from '@/fsd/shared/icons/true-mark-cancel.vue';
import TrueMarkCheck from '@/fsd/shared/icons/true-mark-check.vue';
import { Component, computed } from 'vue';

const iconPaths: Record<string, Component> = {
  pencilToLine: PencilToLine,
  cancel: IconCancel,
  info: IconInfo,
  trueMark: IconTrueMark,
  trueMarkCancel: TrueMarkCancel,
  trueMarkCheck: TrueMarkCheck,
  history: IconHistory,
  menu: IconMenu,
};

interface ConfigIconProps {
  iconKey?: keyof typeof iconPaths;
}

const props = defineProps<ConfigIconProps>();

const ConfigIcon = computed<Component | undefined>(() => {
  return props.iconKey ? iconPaths[props.iconKey] : undefined;
});
</script>
