<template>
  <Row
    v-if="needShow"
    :label="label"
    :value="value"
    data-test="suggest-details valid row"
  />
</template>

<script lang="ts">
import BaseOrder, { OrderStageEnum, OrderTypeEnum } from '@/models/orders/BaseOrder';
import ControlOrder from '@/models/orders/ControlOrder';
import Product from '@/models/Product';
import Shelf from '@/models/Shelf';
import Suggest, { SuggestTypeEnum, SuggestVarsModeEnum } from '@/models/Suggest';
import { useShelves } from '@/store/modules/shelves';
import { useUser } from '@/store/modules/user';
import { getValidTitle } from '@/temp/constants/translations/validTitles';
import { calculateControlDate } from '@/ui/common/suggest-details/fields/utils';
import Row from '@/ui/common/suggest-details/row.vue';
import { Model } from '@/ui/common/suggest-details/types';
import { getFormatDate } from '@/utils';
import { ValidTypeEnum } from '@/views/ProductCheck/types';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    Row,
  },
  props: {
    model: {
      type: Object as PropType<Model>,
      required: true,
    },
    order: {
      type: Object as PropType<BaseOrder>,
      required: true,
    },
    suggest: {
      type: Object as PropType<Suggest>,
      required: true,
    },
    product: {
      type: Object as PropType<Product>,
      default: undefined,
    },
    step: {
      type: String,
      required: true,
    },
    mode: {
      type: String as PropType<ValidTypeEnum>,
      default: ValidTypeEnum.expirationDate,
    },
  },
  setup() {
    const shelvesStore = useShelves();
    const userStore = useUser();

    return {
      shelvesStore,
      userStore,
    };
  },
  computed: {
    shelf(): Shelf {
      return this.shelvesStore.shelfById(this.suggest.shelf_id)!;
    },
    needShow(): boolean {
      // для посылок не нужно
      if (this.suggest.vars?.mode === SuggestVarsModeEnum.item) return false;
      if (this.userStore.experimentByName('exp_short_need_valid') && this.product?.valid! <= 14) return false;
      if (this.isControl) {
        return (this.step === 'count' && this.suggest.type === SuggestTypeEnum.shelf2box);
      }
      return !!(
        this.suggest.conditions.valid
        || [OrderTypeEnum.acceptance].includes(this.order.type)
        || ([OrderTypeEnum.sale_stowage].includes(this.order.type)
          && this.order.vars.stage === OrderStageEnum.stowage)
      );
    },
    label(): string {
      return getValidTitle({
        docType: this.order.type,
        mode: this.mode as any,
        suggest: this.suggest,
      });
    },
    isControl(): boolean {
      return [
        OrderTypeEnum.writeoff_prepare_day,
        OrderTypeEnum.check_valid_regular,
        OrderTypeEnum.check_valid_short,
      ].includes(this.order.type);
    },
    value(): string {
      if (this.isControl) {
        return calculateControlDate({
          mode: this.mode,
          order: this.order as ControlOrder,
          product: this.product!,
          shelf: this.shelf,
        });
      }
      let date: string;
      if ([OrderTypeEnum.acceptance, OrderTypeEnum.sale_stowage].includes(this.order.type)) {
        date = this.model.date || this.suggest.result_valid || this.suggest.valid!;
      } else {
        date = this.suggest.conditions.valid!;
      }
      return getFormatDate(date, {
        mode: this.mode as any,
        product: this.product,
      });
    },
  },
});
</script>
