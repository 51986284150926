<template>
  <div class="input">
    <div
      class="input-wrapper"
      :class="{ 'input-wrapper_text': type !== 'date' }"
    >
      <div
        class="click-info"
        @click="onClick"
      >
        <div v-if="type === 'date'">
          <Typography
            type="text4"
            color="secondary-text"
          >
            {{ label }}
          </Typography>
        </div>
        <input
          v-bind="attrs"
          ref="input"
          v-model="uiState.model"
          :min="min"
          :max="max"
          :class="{ 'controls-input_text': type !== 'date' }"
          :placeholder="type === 'date' ? undefined : $gettext('Введите количество...')"
          :type="type"
          class="controls-input disabled:bg-day-controlMinor"
          :value="uiState.model"
        >
        <div v-if="type === 'date'">
          <Typography
            type="label1"
            color="primary-text"
          >
            {{ formattedModel }}
          </Typography>
        </div>
      </div>
    </div>
    <UiButton
      is-icon
      :is-disabled="!uiState.model || !!$attrs.disabled || invalid"
      @click="onButtonClick"
    >
      <img
        v-if="!isNext && (!uiState.model || invalid)"
        class="icon-check"
        src="./img/check-disabled.svg"
        alt=""
      >
      <img
        v-if="!isNext && uiState.model && !invalid"
        class="icon-check"
        src="./img/check.svg"
        alt=""
      >
      <img
        v-if="isNext && (!uiState.model || invalid)"
        class="icon-check"
        src="./img/next-disabled.svg"
        alt=""
      >
      <img
        v-if="isNext && uiState.model && !invalid"
        class="icon-check"
        src="./img/next.svg"
        alt=""
      >
    </UiButton>
  </div>
</template>

<script lang="ts">
import Typography from '@/ui/common/typography.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { getFormatDate } from '@/utils';
import { defineComponent } from 'vue';

interface Data {
  isCalendar: boolean;
  uiState: {
    model?: string;
  };
}

export default defineComponent({
  components: {
    Typography,
    UiButton,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: null,
    },
    type: {
      type: String,
      default: 'number',
    },
    isNext: {
      type: Boolean,
      default: false,
    },
    max: {
      type: [String, Number],
      default: undefined,
    },
    min: {
      type: [String, Number],
      default: 0,
    },
  },
  emits: ['confirm'],
  data(): Data {
    return {
      isCalendar: true,
      uiState: {
        model: undefined,
      },
    };
  },
  computed: {
    attrs(): object {
      return {
        ...this.$attrs,
        type: this.type,
      };
    },
    invalid(): boolean {
      if (!this.uiState.model) return false;
      const isMax = this.max ? +this.uiState.model > +this.max : false;
      const isMin = this.min ? +this.uiState.model < +this.min : false;
      return isMax || isMin;
    },
    formattedModel(): string {
      if (this.uiState.model && this.type === 'date') {
        return getFormatDate(this.uiState.model);
      }
      if (this.uiState.model) {
        return this.uiState.model;
      }
      return this.type === 'date' ? this.$gettext('Не указана') : this.$gettext('Не указано');
    },
  },
  mounted() {
    this.uiState.model = this.value ? this.value.toString() : undefined;
  },
  methods: {
    onButtonClick(): void {
      this.$emit('confirm', { value: this.uiState.model });
    },
    onClick(): void {
      if (this.$refs.input) {
        (this.$refs.input as any).click();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.input {
  display: flex;
  max-width: 100%;
  width: 100%;
}

.input-wrapper {
  background: var(--secondary-btn-bg);
  border-radius: 8px;
  display: flex;
  width: calc(100% - 4rem);
  margin-right: 0.5rem;

  &_text {
    background: transparent;
    width: calc(100% - 3.5rem);
  }
}

.click-info {
  align-items: center;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
}

.controls-label {
  color: theme('colors.day.textMinor');
  font-weight: 500;
}

.controls-input {
  height: 0;
  width: 0;
  opacity: 0;
  border: 0;
  border-bottom: 2.5px solid theme('colors.day.control');
  box-sizing: border-box;
  color: theme('colors.warmGray.600');
  font-size: 20px;
  font-weight: 500;
  outline: none;

  &_text {
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.5;
    width: 100%;
    height: 3.5rem;
    opacity: 1;
  }
}

.button-submit {
  flex-basis: 80px;
  width: 80px;
  height: 56px;
}

.icon-check {
  width: 22px;
  height: 17px;

  &.disabled {
  }
}

.buttons {
  padding-top: 300px;
}
</style>
