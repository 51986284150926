<template>
  <svg
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="m18.3569,6.21108c-0.1461,-1.28056 -1.1624,-2.29687 -2.443,-2.44301l-1.199,1.19904c1.0745,0.50003 1.943,1.36848 2.443,2.44302l1.199,-1.19905zm-11.2462,6.36022l5.80321,-5.80324c1.28059,0.14615 2.29689,1.16246 2.44299,2.44302l-5.80318,5.80322c-0.04145,0.0414 -0.08439,0.0811 -0.1287,0.119c-0.31018,0.2653 -0.68764,0.4419 -1.09239,0.5094l-2.22039,0.3701l0.37007,-2.2204c0.07709,-0.4626 0.29679,-0.8895 0.62839,-1.2211zm7.4684,-10.65039l-3.00001,3l-6.05938,6.05939c-0.66319,0.6632 -1.1026,1.517 -1.25679,2.4422l-0.54268,3.2561c-0.16921,1.0152 0.71095,1.8954 1.72619,1.7262l3.2561,-0.5427c0.92514,-0.1542 1.77898,-0.5936 2.44218,-1.2568l6.05939,-6.05939l3,-3c0.2695,-0.26951 0.4209,-0.63503 0.4209,-1.01617c0,-2.77785 -2.2519,-5.02974 -5.0298,-5.02974c-0.3811,0 -0.7466,0.15141 -1.0161,0.42091zm-10.4541,18.32909c-0.62132,0 -1.125,0.5037 -1.125,1.125c0,0.6213 0.50368,1.125 1.125,1.125l15.75,0c0.6213,0 1.125,-0.5037 1.125,-1.125c0,-0.6213 -0.5037,-1.125 -1.125,-1.125l-15.75,0z"
      fill="#1C1D20"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
