import { DescriptionByCodeDefault, GetTitleAndText } from '@/fsd/entities/modals/TrueMarkErrorsTexts/types';
import { getTrueMarkType } from '@/fsd/entities/true-mark/utils';
import type { InfoItem } from '@/fsd/shared/universalModal';
import { ERR, ERR_STATUS_BAD_TRUE_MARK } from '@/temp/constants/errors';
import { $gettext } from '@/temp/plugins/gettext';
import { getTrueMarkErrorTitle } from '@/fsd/entities/modals/TrueMarkErrorsTexts/utils';

/**
 * Заголовок и текст ошибок по-умолчанию
 */
export const getTitleAndTextByCodeDefault: GetTitleAndText = (code: ERR) => {
  let title = $gettext('Отсканируйте «%{markType}» ещё раз', {
    markType: getTrueMarkType(),
  });
  let text = '';

  switch (code) {
    case ERR.ER_INVALID_TRUE_MARK:
    case ERR.ER_SUGGEST_INVALID_TRUE_MARK:
    case ERR.ER_SUGGEST_TRUE_MARK_REQUIRED:
      title = $gettext('Отсканируйте «%{markType}»', {
        markType: getTrueMarkType(),
      });
      text = $gettext('Похоже, вы отсканировали штрихкод вместо «%{markType}». Вам нужен небольшой квадратный код', {
        markType: getTrueMarkType(),
      });
      break;
    case ERR.ER_TRUE_API_BROKEN:
    case ERR.ER_MARK_IS_SELLING_YET:
    case ERR.ER_RETIRED_TRUE_MARK_WAITING_FOR_SALE_CONFIRMATION:
    case ERR.ER_NO_PRODUCT_FOR_MARK_IN_COMPANY:
    case ERR.ER_TRUE_MARK_OWNER_CHANGING:
      title = $gettext('Отсканируйте другой товар, а этот пока верните на полку');
      break;
    case ERR.ER_EXTERNAL_SERVICE:
    case ERR.ER_SUGGEST_TRUE_MARK_ALREADY_TAKEN:
      title = $gettext('Отсканируйте «%{markType}» ещё раз', {
        markType: getTrueMarkType(),
      });
      text = $gettext('Не удалось загрузить информацию, нужно попробовать ещё раз');
      break;
    case ERR.ER_RETIRED_TRUE_MARK_ON_REVIEW:
    case ERR.ER_RETIRED_TRUE_MARK_ON_STORE:
    case ERR.ER_TRUE_MARK_HAS_EXPIRED:
    case ERR.ER_RETIRED_TRUE_MARK_OTHER_STORE:
    case ERR.ER_RETIRED_TRUE_MARK_OUTSIDE:
    case ERR.ER_TRUE_MARK_OWNER_TIN_IS_WRONG:
    case ERR.ER_BAD_TRUE_MARK:
      title = $gettext('Отнесите этот товар на полку «Разбор»');
      text = $gettext('Затем возьмите другой товар и отсканируйте его «%{markType}»', {
        markType: getTrueMarkType(),
      });
      break;
    case ERR.ER_SUGGEST_TRUE_MARK_DUPLICATED:
      title = $gettext('Вы уже отсканировали этот товар, положите его в корзину и возьмите следующий');
      break;
    case ERR.ER_SUGGEST_TRUE_MARK_CONSUMED:
    case ERR.ER_SUGGEST_TRUE_MARK_IN_ANOTHER_ORDER:
      title = $gettext('Этот товар собрали в другом заказе — верните его на полку и возьмите другой');
      break;
    case ERR.ER_SUGGEST_WRONG_PRODUCT_TRUE_MARK:
      title = $gettext('Вы отсканировали не тот товар, проверьте состав заказа');
      break;
    case ERR.ER_SUGGEST_WRONG_TRUE_MARK:
    case ERR.ER_SUGGEST_TRUE_MARK_NOT_IN_CURRENT_ORDER:
      title = $gettext('Вы отсканировали не ту марку «%{markType}»', {
        markType: getTrueMarkType(),
      });
      text = $gettext('Нужно отсканировать ту же марку товара, что и при сборке заказа');
  }

  return {
    title,
    text,
  };
};

/**
 * Информационный блок описания кода ошибки по-умолчанию
 */
export const getInfoBlockDescriptionByCodeDefault = (code: ERR): InfoItem | null => {
  const { TITLE_DESCRIPTION_ERROR } = getTrueMarkErrorTitle();

  const ERROR_DESCRIPTION: Record<DescriptionByCodeDefault, string> = {
    [ERR.ER_MARK_IS_SELLING_YET]: $gettext(
      'Марка товара не вернулась в оборот, её можно будет отсканировать позже',
    ),
    [ERR.ER_RETIRED_TRUE_MARK_WAITING_FOR_SALE_CONFIRMATION]: $gettext(
      'Марка товара не вернулась в оборот, её можно будет отсканировать позже',
    ),
    [ERR.ER_RETIRED_TRUE_MARK_ON_REVIEW]: $gettext(
      'Товар нужно списать, это сделает директор даркстора',
    ),
    [ERR.ER_RETIRED_TRUE_MARK_ON_STORE]: $gettext(
      'Марка товара недействительна — его нужно списать, это сделает директор даркстора',
    ),
    [ERR.ER_TRUE_MARK_HAS_EXPIRED]: $gettext(
      'Товар просрочен, его нужно списать — это сделает директор даркстора',
    ),
    [ERR.ER_SUGGEST_INVALID_TRUE_MARK]: $gettext(
      'Марка товара недействительна — его нужно списать, это сделает директор даркстора',
    ),
    [ERR.ER_RETIRED_TRUE_MARK_OTHER_STORE]: $gettext(
      'Марка товара недействительна — его нужно списать, это сделает директор даркстора',
    ),
    [ERR.ER_RETIRED_TRUE_MARK_OUTSIDE]: $gettext(
      'Марка товара недействительна — его нужно списать, это сделает директор даркстора',
    ),
    [ERR.ER_SUGGEST_TRUE_MARK_DUPLICATED]: $gettext(
      'Сканировать «%{markType}» для каждого товара нужно отдельно',
      { markType: getTrueMarkType() },
    ),
    [ERR.ER_SUGGEST_TRUE_MARK_CONSUMED]: $gettext(
      'Сканировать «%{markType}» для каждого товара нужно отдельно',
      { markType: getTrueMarkType() },
    ),
    [ERR.ER_SUGGEST_TRUE_MARK_IN_ANOTHER_ORDER]: $gettext(
      'Сканировать «%{markType}» для каждого товара нужно отдельно',
      { markType: getTrueMarkType() },
    ),
    [ERR.ER_TRUE_MARK_OWNER_TIN_IS_WRONG]: $gettext('Марка товара закреплена за другим юридическим лицом'),
    [ERR.ER_TRUE_MARK_OWNER_CHANGING]: $gettext(
      'Товар недавно прошёл приёмку, статус марки «%{markType}» скоро изменится',
      { markType: getTrueMarkType() },
    ),
  };

  if (ERROR_DESCRIPTION[code]) {
    return {
      title: ERROR_DESCRIPTION[code],
      subtitle: TITLE_DESCRIPTION_ERROR,
    };
  }

  return null;
};

/**
 * Информационный блок описания кода ошибки по-умолчанию
 */
export const getInfoBlockDescriptionByStatusDefault = (status: ERR_STATUS_BAD_TRUE_MARK): InfoItem | null => {
  const { TITLE_DESCRIPTION_ERROR } = getTrueMarkErrorTitle();

  const ERROR_DESCRIPTION_BY_STATUS = {
    [ERR_STATUS_BAD_TRUE_MARK.NOT_APPLIED]: $gettext(
      'Марка товара не введена в оборот поставщиком',
    ),
    [ERR_STATUS_BAD_TRUE_MARK.NOT_VERIFIED]: $gettext(
      'Марка товара не проверена поставщиком',
    ),
    [ERR_STATUS_BAD_TRUE_MARK.RETIRED]: $gettext(
      'Марка товара выведена из оборота',
    ),
    [ERR_STATUS_BAD_TRUE_MARK.BLOCKED]: $gettext(
      'Марка товара заблокирована',
    ),
    [ERR_STATUS_BAD_TRUE_MARK.NOT_REALIZABLE]: $gettext(
      'Марка товара не введена в оборот поставщиком',
    ),
  };

  if (ERROR_DESCRIPTION_BY_STATUS[status]) {
    return {
      title: ERROR_DESCRIPTION_BY_STATUS[status],
      subtitle: TITLE_DESCRIPTION_ERROR,
    };
  }

  return null;
};
