<template>
  <StaticVersionUpdater
    v-if="webViewUpdater.visible.value"
    :actual-version="actualVersion"
    @close="checkForUpdateLater"
  />
</template>

<script setup lang="ts">
import StaticVersionUpdater from '@/components/updater/StaticVersionUpdater.vue';
import { api } from '@/fsd/data/api/api.service';
import { useComponent } from '@/hooks/useComponent';
import { useUser } from '@/store/modules/user';
import { logger } from '@/temp/plugins/logs';
import {
  onBeforeUnmount, ref, watchEffect,
} from 'vue';
import { useRoute } from 'vue-router';

const userStore = useUser();
const webViewUpdater = useComponent();
const route = useRoute();

let timer: number | undefined = undefined;

const actualVersion = ref<string>('');

// Интервал проверки на наличие новой версии статики
const INTERVAL_TIMEOUT = 30_000;
// Через сколько еще раз запросить обновление, если пользователь отказался обновляться сейчас
const UPDATE_LATER_INTERVAL = 120_000;

const emits = defineEmits<{ (e: 'backdrop-click'): void }>();

const checkVersion = async () => {
  try {
    const { data } = await api.version();

    if (data.version !== userStore.version) {
      actualVersion.value = data.version;
      webViewUpdater.show();
    } else {
      timer = setTimeout(() => {
        checkVersion();
      }, INTERVAL_TIMEOUT);
    }
  } catch {
    logger('Не удалось проверить версию.');
  }
};

const checkForUpdateLater = () => {
  timer = setTimeout(() => {
    checkVersion();
  }, UPDATE_LATER_INTERVAL);

  webViewUpdater.hide();
  emits('backdrop-click');
};

watchEffect(() => {
  if (route.name === 'login' || route.name === 'home') {
    checkVersion();
  } else {
    clearTimeout(timer);
  }
});

onBeforeUnmount(() => {
  clearTimeout(timer);
});
</script>
