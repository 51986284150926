<template>
  <div class="border-2 border-[var(--invert-bg)] rounded-2xl m-2 h-14 flex flex-row">
    <div class="flex-[2.5] border-r-2 border-[var(--invert-bg)] h-full flex items-center justify-center">
      <Body1
        v-for="(symbol, index) in vehicle_numberString"
        :key="symbol + index"
        class="border-[var(--invert-bg)]"
        :class="{
          'mr-1': index === 0 || index === 3,
          'border-l-2 pl-0.5': !vehicle_number && index === 0,
          'border-r-2 pr-0.5': vehicle_number.length - 1 === index && vehicle_number.length !== 6,
        }"
        :color="vehicle_numberColor"
      >
        {{ symbol }}
      </Body1>
    </div>
    <div class="flex-1 h-full flex items-center justify-center">
      <Body1
        v-for="(symbol, index) in vehicle_regionString"
        :key="symbol + index"
        class="border-[var(--invert-bg)]"
        :class="{
          'border-l-2 pl-0.5': vehicle_region.length === 0 && index === 0 && vehicle_number.length === 6,
          'border-r-2 pr-0.5': vehicle_region.length - 1 === index && vehicle_region.length !== 3,
        }"
        :color="vehicle_regionColor"
      >
        {{ symbol }}
      </Body1>
    </div>
  </div>
</template>
<script lang="ts">
import Body1 from '@/ui/common/typo/body-1.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NumberInput',
  components: {
    Body1,
  },
  props: {
    vehicle_number: {
      type: String,
      required: true,
    },
    vehicle_region: {
      type: String,
      required: true,
    },
  },
  computed: {
    vehicle_numberString(): string[] {
      return this.vehicle_number.length ? this.vehicle_number.split('') : ['o', '0', '0', '0', 'o', 'o']; // в массиве по сути placeHolder
    },
    vehicle_regionString(): string[] {
      return this.vehicle_region.length ? this.vehicle_region.split('') : ['0', '0', '0']; // в массиве по сути placeHolder
    },
    vehicle_numberColor(): string {
      return this.vehicle_number ? 'semantic-text-main' : 'semantic-text-minor';
    },
    vehicle_regionColor(): string {
      return this.vehicle_region ? 'semantic-text-main' : 'semantic-text-minor';
    },
  },
});
</script>
