<template>
  <Bar
    :order="order"
    :caption="`${$gettext('Заказ')} ${props.order.orderNumberForView}`"
    :text="$gettext('Согласование изменений')"
    :custom-menu="true"
    :icons="{ left: 'close', right: 'info' }"
    @close-click="toHomePage"
    @menu-click="orderMenu"
  />
</template>

<script setup lang="ts">
import { OrderModal } from '@/fsd/features/order';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { callAction } from '@/fsd/shared/ui/action';
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import type OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import { useOrders } from '@/store/modules/orders';
import { $gettext } from '@/temp/plugins/gettext';
import { logger } from '@/temp/plugins/logs';
import Bar from '@/ui/common/bar/bar.vue';
import { useRouter } from 'vue-router';

interface HeaderRetailProps {
  order: OrderOrderRetail;
}

const router = useRouter();

const props = defineProps<HeaderRetailProps>();
defineEmits<{ (e: 'finish'): void }>();

const orderMenu = async () => {
  const isCanCancel = await callAction(OrderModal, { order: props.order });

  if (isCanCancel) {
    await cancelOrder();
  }
};

const toHomePage = () => {
  return router.push({ name: 'home' });
};

const cancelOrder = async () => {
  const canceled = await Modal.show({
    title: $gettext('Отменить сборку заказа?'),
    btnPosition: ButtonPositionsEnum.horizontal,
    closeBtnTitle: $gettext('Отмена'),
    confirmBtnTitle: $gettext('Отменить'),
  });
  if (canceled) {
    try {
      await useOrders().cancelOrder(props.order.order_id);
      await toHomePage();
    } catch (error) {
      logger.error(error, {
        method: 'cancelOrder',
        type: 'order',
      });
    }
  }
};
</script>
