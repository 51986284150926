<template>
  <Blur>
    <Layout>
      <template #header>
        <slot
          v-if="!loading"
          name="header"
        />
      </template>
      <template #default>
        <slot
          v-if="!loading"
          name="default"
        />
        <div
          v-else
          class="h-full flex flex-col items-center justify-center"
        >
          <LoaderIndicator class="mb-4" />
          <body1 class="mb-2">
            {{ $gettext('Загрузка') }}
          </body1>
          <caption1 class="mb-2">
            {{ $gettext('Пожалуйста, подождите') }}
          </caption1>
        </div>
      </template>
      <template #footer>
        <slot
          v-if="!loading"
          name="footer"
        />
      </template>
    </Layout>
  </Blur>
</template>
<script lang="ts" setup>
import { useCheckSuggestResources } from '@/fsd/data/utils/checkResourse';
import { rumSpaManager } from '@/fsd/shared/tools/RumSpaManager';
import BaseOrder from '@/models/orders/BaseOrder';
import Suggest from '@/models/Suggest';
import Blur from '@/temp/ui/blur/blur.vue';
import Layout from '@/ui/common/layout.vue';
import LoaderIndicator from '@/ui/common/loader-indicator.vue';
import { ref, watch } from 'vue';

interface SuggestDetailsLayoutProps {
  order_id: BaseOrder['order_id'];
  suggest_id: Suggest['suggest_id'];
  mergedSuggest?: boolean;
}

const props = defineProps<SuggestDetailsLayoutProps>();
// * Если саджест смержен, то у него virtual id
// * для такого саджеста не получится получить ресурсы
// * поэтому считаем, что они уже загружены
// * это НЕ будет работать, если вынести компонент на отдельную страницу,
// * минуя загрузку всех саджестов
const { loading } = !props.mergedSuggest
  ? useCheckSuggestResources(props.order_id, props.suggest_id)
  : { loading: ref(false) };
// #region RUM
const pageName = 'suggest-details';
rumSpaManager.makeSpaSubPage(pageName, true);
rumSpaManager.startDataLoading(pageName);
if (!loading.value) {
  // Если данные есть, то сразу ставим индикатор, что мы их отрисовываем
  rumSpaManager.finishDataLoading(pageName, true);
  rumSpaManager.startDataRendering(pageName);
} else {
  // Если данных не хватает, то ждем их загрузку
  const unWatch = watch(loading, (val) => {
    if (!val) {
      unWatch();
      rumSpaManager.finishDataLoading(pageName);
      rumSpaManager.startDataRendering(pageName);
    }
  });
  // #endregion
}
</script>
