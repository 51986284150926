<template>
  <SuggestDetailsLayout
    v-if="suggest && product && order"
    data-test="suggest-details true-mark container"
    :suggest_id="suggest.suggest_id"
    :order_id="suggest.order_id"
    merged-suggest
  >
    <template #header>
      <Bar
        :order="order"
        :menu-config="suggestMenuConfig"
        :menu-title="product.title"
        :icons="{ left: 'back', right: 'menu' }"
        data-test="suggest true-mark bar"
        is-fixed
        @close-click="$emit('cancel')"
      />
    </template>
    <template #default>
      <div class="p-4 relative border-t-2 border-element">
        <ProductPicture :img-src="suggest.imageSrc" />
        <div class="flex flex-col items-center gap-4">
          <Body1
            class="font-medium"
            data-test="suggest-details true-mark title"
          >
            {{ product.title }}
          </Body1>

          <div class="flex flex-wrap">
            <FragileBadge v-if="product.fragile" />

            <template v-if="product?.true_mark">
              <ASLBelgisiBadge v-if="userStore.countryCode === 'UZB'" />
              <TrueMarkBadge v-else />
            </template>
          </div>

          <Caption1 color="semantic-text-minor">
            {{ productStorageTypeTitle }}
          </Caption1>

          <div
            data-test="suggest-details rows container"
            class="flex flex-col gap-4 w-full"
          >
            <CountRow
              :order="order"
              :product="product"
              :suggest="suggest"
              :model="models"
              step="count"
            />
            <ResultCountRow
              :order="order"
              :product="product"
              :suggest="suggest"
              :model="models"
              step="count"
            />
            <ValidRow
              :order="order"
              :product="product"
              :suggest="suggest"
              :model="models"
              step="count"
              :mode="ValidTypeEnum.productionDate"
            />
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <LayoutFooter>
        <CountFieldView
          :hint-text="hintText"
          :count="suggest.result_count"
          :quant-unit="QuantUnitEnum.unit"
          @cancel="emit('cancel')"
        />
      </LayoutFooter>
    </template>
  </SuggestDetailsLayout>
</template>

<script setup lang="ts">
import { api } from '@/fsd/data/api/api.service';
import { ASLBelgisiBadge, FragileBadge, TrueMarkBadge } from '@/fsd/entities/badges';
import { mergeTrueMarkSuggests } from '@/fsd/entities/suggest/tools/mergeTrueMarkSuggests';
import { useCheck } from '@/fsd/entities/suggest/tools/useCheck';
import { getTrueMarkType } from '@/fsd/entities/true-mark/utils';
import { callAction } from '@/fsd/shared/ui/action';
import { ActionReportProps } from '@/fsd/shared/ui/action/types';
import SuggestDetailsLayout from '@/fsd/widgets/suggest-details/SuggestDetailsLayout.vue';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import { QuantUnitEnum } from '@/models/Product';
import ProductByBarcode from '@/models/ProductByBarcode';
import { SuggestStatusEnum } from '@/models/Suggest';
import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { storageTypesInfo } from '@/temp/constants/translations';
import { $gettext } from '@/temp/plugins/gettext';
import { TrueMark } from '@/temp/services/true-mark';
import Bar from '@/ui/common/bar/bar.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import Report from '@/ui/common/notifications/report.vue';
import ProductPicture from '@/ui/common/suggest-details/components/product-picture.vue';
import CountRow from '@/ui/common/suggest-details/data-rows/count-row.vue';
import ResultCountRow from '@/ui/common/suggest-details/data-rows/result-count-row.vue';
import ValidRow from '@/ui/common/suggest-details/data-rows/valid-row.vue';
import CountFieldView from '@/ui/common/suggest-details/fields/CountFieldView.vue';
import Body1 from '@/ui/common/typo/body-1.vue';
import Caption1 from '@/ui/common/typo/caption-1.vue';
import { computed, watch, watchEffect } from 'vue';
import LayoutFooter from '../layout/layout-footer.vue';
import { ValidTypeEnum } from '@/views/ProductCheck/types';

interface Props {
  suggestCount?: number;
  barcode: string;
  suggestId: string;
  orderId: string;
}

const props = defineProps<Props>();
const emit = defineEmits(['cancel']);
const productsStore = useProducts();
const ordersStore = useOrders();
const userStore = useUser();
const { showLoader } = useLoader();

const hintText = $gettext('Нужно отсканировать «%{markType}»', {
  markType: getTrueMarkType(),
});
const order = computed(() => ordersStore.orderById(props.orderId));

const suggests = computed(() => {
  const orderSuggest = order.value?.suggests ?? [];
  return mergeTrueMarkSuggests(orderSuggest);
});
const suggest = computed(
  () => suggests.value.find(suggest => suggest.suggest_id === props.suggestId) as TrueMarkSuggestWrapper,
);

const suggestClosed: Record<string, string> = {};
watchEffect(() => {
  suggest.value?.suggests.forEach(({ suggest_id, vars }) => {
    if (vars.true_mark) {
      suggestClosed[suggest_id] = vars.true_mark;
    }
  });
});

const product = computed(() => productsStore.productById(suggest.value?.product_id));

const productStorageTypeTitle = computed(() => {
  const tag = product.value?.tags[0] || 'empty';
  return storageTypesInfo[tag];
});

const models = computed(() => ({
  count: suggest.value?.count,
  weights: [],
}));

const suggestMenuConfig = computed<MenuItemConfig[]>(() => {
  const menuConfig: MenuItemConfig[] = [];

  const errorInCardBtn: MenuItemConfig = {
    buttonText: $gettext('Ошибка в карточке товара'),
    buttonSecondText: $gettext('Изображение, описание, другое'),
    dataTest: 'suggest-details error-card',
    onClick: () => {
      callAction<ActionReportProps, void>(Report, {
        title: 'Ошибка в карточке товара',
        productId: product.value?.product_id ?? '',
      });
    },
    iconKey: 'pencilToLine',
    condition: () => userStore.experimentByName(experiments.exp_product_bug_ticket),
  };
  menuConfig.push(errorInCardBtn);

  return menuConfig;
});

const getSuggest = async (barcode: string) => {
  const { closeLoader } = showLoader();
  try {
    await TrueMark.check({
      true_mark: barcode,
      order_id: suggest.value.order_id,
    }, order.value?.type);

    const { data } = await api.barcode({
      barcode,
      order_id: order.value?.order_id,
    });
    const [product] = data.found.filter(ProductByBarcode.isProductByBarcode);

    return suggest.value.suggests.find(({ status, count, suggest_id }) => {
      // для КИЗ не приходят count, поэтому предполагаем, что это 1
      return status === SuggestStatusEnum.request
        && count === (product.count ?? 1)
        && !suggestClosed[suggest_id];
    });
  } finally {
    closeLoader();
  }
};

const handlerBarcode = async (barcode: string) => {
  try {
    const currentSuggest = await getSuggest(barcode);
    if (currentSuggest) {
      await useCheck(order.value!.order_id, {
        suggest_id: currentSuggest.suggest_id,
        count: currentSuggest.count ?? 1,
        true_mark: barcode,
      });
      suggestClosed[currentSuggest.suggest_id] = barcode;
    }
  } catch {
    return true;
  }
  return !(suggest.value.count === suggest.value.result_count);
};

useRequestBarcode(handlerBarcode);

handlerBarcode(props.barcode);

watch(
  () => suggest.value,
  (newValue) => {
    if (!newValue) emit('cancel');
  },
);
</script>
