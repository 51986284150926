<template>
  <div class="flex h-[calc(100vh-20px)] mt-5 bg-background-main pointer-events-auto">
    <Layout class="flex flex-col">
      <template #header>
        <Bar
          :caption="caption"
          :text="product.title"
          :icons="{ left: 'back' }"
          :menu-config="[]"
          @close-click="backStep"
        />
      </template>

      <template #default>
        <template v-if="uiState.step === 'choice'">
          <div
            class="flex items-center justify-between h-14 mx-4 border-b"
            data-test="wrong_photo"
            @click="nextStep('wrong_photo')"
          >
            {{ $gettext('Неправильная фотография товара') }}
            <Chevron />
          </div>
          <div
            class="flex items-center justify-between h-14 mx-4 border-b"
            data-test="without_packaging"
            @click="nextStep('without_packaging')"
          >
            {{ $gettext('Товар на фотографии без упаковки') }}
            <Chevron />
          </div>
          <div
            class="flex items-center justify-between h-14 mx-4 border-b"
            data-test="wrong_description"
            @click="nextStep('wrong_description')"
          >
            {{ $gettext('Не совпадают описания') }}
            <Chevron />
          </div>
          <div
            class="flex items-center justify-between h-14 mx-4 border-b"
            data-test="wrong_product_valid"
            @click="nextStep('wrong_product_valid')"
          >
            {{ $gettext('Не совпадают сроки годности') }}
            <Chevron />
          </div>
          <div
            class="flex items-center justify-between h-14 mx-4"
            data-test="not_displayed"
            @click="nextStep('not_displayed')"
          >
            {{ $gettext('Товар не отображается на фронте') }}
            <Chevron />
          </div>
        </template>
        <form
          v-if="uiState.step === 'comment'"
          ref="submitFormRef"
          data-test="submit form bug-report"
          @submit.prevent="submitForm"
        >
          <Hint class="m-4">
            {{ $gettext('Сфотографируйте или загрузите фотографию товара и добавьте комментарий, если нужно') }}
          </Hint>
          <!--            <ui-button
              class="cursor-pointer"
              type="button"
              background-color="secondary"
              :is-disabled="!apkVersion || images.length >= 5"
              @click="openCamera"
            >
              <template #icon>
                <camera class="mr-1" />
                {{ $gettext('Сделать фото') }}
              </template>
            </ui-button>-->
          <div class="mx-4 h-14">
            <input
              id="upload-image"
              ref="uploadImageRef"
              type="file"
              accept="image/*"
              hidden
              :disabled="images.length >= 5"
              @change="uploadImageFromGallery"
            >
            <label
              for="upload-image"
              class="h-full flex justify-evenly items-center rounded-2xl cursor-pointer bg-semantic-bg-control-minor"
              data-test="submit upload-file btn"
            >
              {{ $gettext('Прикрепить фото из галереи') }}
              <Clip />
            </label>
          </div>
          <ul
            v-if="images.length > 0"
            class="overflow-y-auto mx-4 py-2 divide-y divide-day-line"
          >
            <li
              v-for="image in images"
              :key="image.attachment.id"
              class="flex justify-between p-3 pr-6"
            >
              <img
                v-if="image.attachment.url"
                class="w-6 h-6 inline-block rounded-lg"
                :src="image.attachment.url"
                alt="photo"
              >
              <span class="mx-3 whitespace-nowrap overflow-hidden overflow-ellipsis">{{ image.attachment.name }}</span>
              <button
                type="button"
                data-test="submit preview-image delete btn"
                @click="deleteImage(image.attachment.id)"
              >
                <Bucket />
              </button>
            </li>
          </ul>
          <div class="m-4">
            <textarea
              id="comment"
              v-model="uiState.comment"
              name="comment"
              data-test="submit comment field"
              class="py-5 w-full border-b border-solid border-day-line resize-none text-justify"
              :placeholder="$gettext('Введите комментарий...')"
              maxlength="500"
            />
          </div>
        </form>
      </template>
      <template #footer>
        <LayoutFooter>
          <UiButton
            v-if="uiState.step === 'choice'"
            data-test="menu close-btn"
            background-color="secondary"
            @click="$emit('close')"
          >
            {{ $gettext('Закрыть') }}
          </UiButton>
          <UiButton
            v-if="uiState.step === 'comment'"
            :is-disabled="uiState.comment === '' || !images.length"
            data-test="report comment-btn"
            background-color="primary"
            @click="requestSubmit"
          >
            {{ $gettext('Создать обращение') }}
          </UiButton>
        </LayoutFooter>
      </template>
    </Layout>
  </div>
</template>

<script lang="ts">
import { api } from '@/fsd/data/api/api.service';
import { PlatformService } from '@/fsd/data/services/platform.service';
import Product from '@/models/Product';
import { ProductBugRequest } from '@/services/requests';
import { useProducts } from '@/store/modules/products';
import { useUser } from '@/store/modules/user';
import Bucket from '@/temp/icons/bucket.vue';
import Chevron from '@/temp/icons/chevron.vue';
import Clip from '@/temp/icons/clip.vue';
import { logger } from '@/temp/plugins/logs';
import { AttachmentImage, AttachmentImageData } from '@/types/attachment';
import Bar from '@/ui/common/bar/bar.vue';
import Hint from '@/ui/common/hint/hint.vue';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import UiButton from '@/ui/common/ui-button.vue';
import {
  Camera, CameraResultType, CameraSource,
} from '@capacitor/camera';
import axios, { AxiosError } from 'axios';
import { v1 as uuidv1 } from 'uuid';
import { defineComponent } from 'vue';

type BugType = 'wrong_photo' | 'without_packaging' | 'wrong_description' | 'wrong_product_valid' | 'not_displayed' | '';
type Images = {
  s3Key: string;
  attachment: AttachmentImage;
};

interface Data {
  uiState: {
    step: 'choice' | 'comment';
    comment: string;
    type: BugType;
  };
  images: Images[];
}

export default defineComponent({
  name: 'ErrorInCard',
  components: {
    LayoutFooter,
    Bucket,
    Clip,
    Hint,
    Chevron,
    UiButton,
    Bar,
    Layout,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    productId: {
      type: String,
      required: true,
    },
  },
  emits: ['close'],
  setup() {
    const { showLoader } = useLoader();
    const productsStore = useProducts();
    const userStore = useUser();

    return {
      showLoader,
      productsStore,
      userStore,
    };
  },
  data(): Data {
    return {
      uiState: {
        step: 'choice',
        comment: '',
        type: '',
      },
      images: [],
    };
  },
  computed: {
    product(): Product {
      return this.productsStore.productById(this.productId)!;
    },
    caption(): string {
      switch (this.uiState.type) {
        case 'wrong_photo':
          return this.$gettext('Неправильная фотография товара');
        case 'without_packaging':
          return this.$gettext('Товар на фотографии без упаковки');
        case 'wrong_description':
          return this.$gettext('Не совпадают описания');
        case 'wrong_product_valid':
          return this.$gettext('Не совпадают сроки годности');
        case 'not_displayed':
          return this.$gettext('Товар не отображается на фронте');
        default:
          return this.title;
      }
    },
    apkVersion(): string {
      return this.userStore.apkVersion;
    },
  },
  methods: {
    async uploadImage(fileData: AttachmentImageData): Promise<void> {
      const {
        external_id, filename, storage, b64_data,
      } = fileData;
      try {
        const { data } = await api.files.upload({
          external_id,
          file_group_name: 'common',
          storage,
          data: {
            filename,
            file: b64_data as File,
          },
        });
        this.images.push({
          s3Key: data.file_meta_id,
          attachment: {
            id: external_id,
            name: filename,
            url: fileData.url,
          },
        });
        this.$alert.success(this.$gettext(`Изображение %{filename} прикреплено`, { filename }));
      } catch (error: any) {
        console.error(error);
        this.$alert.error(this.$gettext('Не удалось получить ключ для картинки из S3'));
      }
    },
    async openCamera(): Promise<void> {
      if (!PlatformService.isCapacitor()) {
        this.$alert.error('wrong platform');
        return;
      }
      const imageName = `camera_${Date.now()}.jpg`;
      const externalId = uuidv1();
      const { closeLoader } = this.showLoader();
      try {
        const { dataUrl } = await Camera.getPhoto({
          quality: 50,
          allowEditing: false,
          source: CameraSource.Camera,
          resultType: CameraResultType.DataUrl,
        });

        const blob = await fetch(dataUrl!).then(res => res.blob());

        await this.uploadImage({
          external_id: externalId,
          filename: imageName,
          storage: 's3',
          b64_data: blob as File,
          url: dataUrl!,
        });
      } catch (error) {
        console.error(error);
        this.$alert.error(this.$gettext('Не удалось загрузить картинку'));
      } finally {
        closeLoader();
      }
    },
    async loadFile({ file }: { file: File }): Promise<void> {
      const externalId = uuidv1();
      try {
        await this.uploadImage({
          external_id: externalId,
          filename: file.name,
          storage: 's3',
          b64_data: file,
          url: URL.createObjectURL(file),
        });
      } catch {
        this.$alert.error(this.$gettext('Не удалось загрузить картинку'));
      }
    },
    async uploadImageFromGallery(): Promise<void> {
      const { closeLoader } = this.showLoader();
      const files = Array.from((this.$refs.uploadImageRef as HTMLInputElement).files as FileList);
      const promises = files.map(file => this.loadFile({ file: file as File }));
      await Promise.allSettled(promises);
      closeLoader();
    },
    deleteImage(id: string): void {
      this.images = this.images.filter(image => image.attachment.id !== id);
    },
    async submitForm(): Promise<void> {
      let options: ProductBugRequest = {
        product_id: this.productId,
        bug_type: this.uiState.type,
      };

      if (this.uiState.comment.length) {
        options.comment = this.uiState.comment;
      }
      if (this.images.length) {
        options.s3_attachments = this.images.map(image => ({ file_meta_id: image.s3Key }));
      }

      const { closeLoader } = this.showLoader();
      try {
        const response = await api.support.product_bug(options);
        this.$emit('close');
        closeLoader();
        this.$modal.show({
          title: this.$gettext('Обращение создано. \nСпасибо, что так внимательны!'),
          text: this.$gettext('Номер обращения:') + `\n${response.data.issue_key}`,
        });
      } catch (e: any) {
        closeLoader();
        const error = e as AxiosError;
        if (axios.isAxiosError(error) && !error.response) {
          await this.$modal.show({
            title: this.$gettext('Кажется, что у вас нет интернета'),
          });
        }
        this.$emit('close');
        switch (error.response?.status) {
          case 400:
            this.$modal.show({
              title: this.$gettext('Какие-то из передаваемых данных некорректные'),
            });
            break;
          case 401:
            this.$modal.show({
              title: this.$gettext('Требуется авторизация'),
            });
            break;
          case 403:
            this.$modal.show({
              title: this.$gettext('Нет доступа к данному сервису'),
            });
            break;
          case 404:
            this.$modal.show({
              title: this.$gettext('Сервис не найден'),
            });
            break;
          case 409:
            this.$modal.show({
              title: this.$gettext(
                `Такое обращение уже существует и обрабатывается контентом %{ticket_number}. \nСпасибо за внимательность!`,
                { ticket_number: (error.response.data as any).issue_key ? `${(error.response.data as any).issue_key}` : '' },
              ),
            });
            break;
          case 429:
            this.$modal.show({
              title: this.$gettext('Процедура создания тикета уже идет. Повторите позже.'),
            });
            break;
          default:
            this.$modal.show({
              title: this.$gettext('Что-то пошло не так'),
              text: this.$gettext(`Ошибка %{error}`, { error: String(error) }),
            });
        }
        logger.error(error, {
          type: 'API',
          block: 'bug report',
        });
      }
    },
    async backStep(): Promise<void> {
      if (this.uiState.step === 'choice') {
        this.$emit('close');
        return;
      }
      const confirmed = await this.$notification.confirmBottom({
        title: this.$gettext('Покинуть экран?'),
        text: this.$gettext('Все данные будут потеряны.'),
      });
      if (confirmed) {
        this.uiState.step = 'choice';
        this.uiState.comment = '';
        this.uiState.type = '';
        this.images = [];
      }
    },
    nextStep(type: BugType): void {
      this.uiState.step = 'comment';
      this.uiState.type = type;
    },
    requestSubmit(): void {
      (this.$refs.submitFormRef as HTMLFormElement).requestSubmit();
    },
  },
});
</script>

<style lang="scss" scoped></style>
