<template>
  <div class="flex flex-row p-2 gap-2">
    <UiButton
      v-if="needChange"
      type="small"
      data-test="suggest-card change"
      :data-index="suggest.product_id"
      background-color="secondary"
      @click="$emit('change')"
    >
      <template #icon>
        <IconPlus />
      </template>
      {{ $gettext('Замена') }}
    </UiButton>
    <UiButton
      v-if="needConfirm"
      type="small"
      data-test="suggest-card confirm"
      :background-color="confirmed ? 'success' : 'secondary'"
      @click="$emit('confirm')"
    >
      <template #icon>
        <Completed v-if="confirmed" />
        <Check v-else />
      </template>
      {{ confirmed ? $gettext('Согласовано') : $gettext('Согласовать') }}
    </UiButton>
    <UiButton
      v-if="canSuggestDone"
      type="small"
      data-test="suggest-card ready"
      background-color="secondary"
      @click="$emit('ready')"
    >
      <template #icon>
        <Check />
      </template>
      {{ $gettext('Собрано') }}
    </UiButton>
    <UiButton
      v-if="isManualInputButtonVisible"
      type="small"
      data-test="manual-input-btn"
      background-color="secondary"
      @click="$emit('openManualInput')"
    >
      <template #icon>
        <Edit v-if="suggest.product?.isTrueWeight" />
        <IconExit v-else />
      </template>
      {{ suggest.product?.isTrueWeight ? $gettext('Ввести вес') : $gettext('Указать кол-во') }}
    </UiButton>
  </div>
</template>
<script setup lang="ts">
import IconExit from '@/fsd/shared/icons/IconExit.vue';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';
import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import { checkManualInputAllowed } from '@/views/OrderRetail/tools/checkManualInputAllowed';
import { useOrders } from '@/store/modules/orders';
import Check from '@/temp/icons/check.vue';
import Completed from '@/temp/icons/completed.vue';
import Edit from '@/temp/icons/edit.vue';
import IconPlus from '@/temp/icons/icon-plus.vue';
import { $gettext } from '@/temp/plugins/gettext';
import UiButton from '@/ui/common/ui-button.vue';
import { computed, toRefs } from 'vue';

interface CardButtonsProps {
  suggest: Suggest | TrueMarkSuggestWrapper;
  collected: number;
  needConfirm?: boolean;
  confirmed?: boolean;
}

const props = defineProps<CardButtonsProps>();
const { suggest } = toRefs(props);
const order = computed<OrderOrderRetail>(() => {
  return useOrders().orderById(suggest.value.order_id) as OrderOrderRetail;
});

defineEmits<{
  (e: 'change'): void;
  (e: 'ready'): void;
  (e: 'confirm'): void;
  (e: 'openManualInput'): void;
}>();

const needChange = computed(() => {
  return !suggest.value.isFull && !suggest.value.vars.more_product && order.value.isReplaceItemsMethod;
});

const canSuggestDone = computed(() => {
  if (TrueMarkSuggestWrapper.isTrueMarkSuggestWrapper(suggest.value)) {
    return suggest.value.isRequest && suggest.value.isPartial;
  }
  return suggest.value.isRequest && props.collected;
});

/**
 * True, если отображается кнопка ручного ввода количества
 */
const isManualInputButtonVisible = computed(() => {
  return (
    checkManualInputAllowed(suggest.value)
    && (suggest.value.status !== SuggestStatusEnum.done || !suggest.value.result_count)
    && !props.collected
  );
});
</script>
