<template>
  <button
    v-if="isCanOpenMenu"
    data-test="suggest-card menu"
    class="flex pr-2"
    @click.stop="emits('menu')"
  >
    <IconMenuRound />
  </button>
  <button
    v-else-if="isCanBeRemoved"
    data-test="suggest-card remove"
    class="flex pr-2"
    @click.stop="emits('remove')"
  >
    <IconBasketRound />
  </button>
</template>
<script setup lang="ts">
import IconBasketRound from '@/fsd/shared/icons/icon-basket-round.vue';
import IconMenuRound from '@/fsd/shared/icons/icon-menu-round.vue';
import { checkSuggestNeedMenu } from '@/views/OrderRetail/tools/checkSuggestNeedMenu';
import { checkSuggestCanBeRemoved } from '@/views/OrderRetail/tools/checkSuggestCanBeRemoved';
import Suggest from '@/models/Suggest';
import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';
import { computed } from 'vue';

interface SuggestCardProps {
  suggest: Suggest | TrueMarkSuggestWrapper;
  collected: string[] | number;
}

const props = defineProps<SuggestCardProps>();

const emits = defineEmits<{
  (e: 'remove'): void;
  (e: 'menu'): void;
}>();

const isCanOpenMenu = computed(() => {
  const collected = Array.isArray(props.collected) ? props.collected.length : props.collected;
  return checkSuggestNeedMenu(props.suggest, collected);
});

const isCanBeRemoved = computed(() => {
  return checkSuggestCanBeRemoved(props.suggest);
});
</script>
