import { ScannerService } from '@/services/scanner/scanner.service';
import { v4 as uuid } from 'uuid';
import { onBeforeUnmount, ref, watch } from 'vue';

interface Options {
  immediate: boolean;
}

/**
 * Тип для ф-и позволяющей дозапросить ШК
 *
 * @export
 * @typedef {BarcodeRequester}
 */
export type BarcodeRequester = () => Promise<string>;

/**
 * Тип для обработчика ШК
 *
 * @export
 * @typedef {BarcodeHandler}
 */
export type BarcodeHandler = (barcode: string, requester: BarcodeRequester) => Promise<boolean | void> | boolean | void;

/**
 * Хук для получения ШК от пользователя, вызовет свой кб при получении нового ШК, дополнительно передает в кб ф-ю для дозапроса ШК
 *
 * @param {BarcodeHandler} onBarcodeScan
 * @param {Options} [options={ immediate: true }]
 * @returns {{ needBarcodeRequest: any; autoRequestBarcode: () => any; }}
 */
export const useRequestBarcode = (
  onBarcodeScan: BarcodeHandler,
  options: Options = { immediate: true },
) => {
  const handlerId = uuid();
  const needBarcodeRequest = ref<boolean>(options.immediate);
  const hasRequest = ref<boolean>(false);

  /**
   * True, если компонент использующий сканирование уничтожен
   */
  const isDestroyed = ref<boolean>(false);

  const autoRequestBarcode = async () => {
    if (!needBarcodeRequest.value) return;

    try {
      hasRequest.value = true;
      const requester: BarcodeRequester = () => ScannerService.requestCode(handlerId);
      const barcode = await requester();
      const scanResult = (await onBarcodeScan(barcode, requester)) ?? true;
      needBarcodeRequest.value = isDestroyed.value ? false : scanResult;
      hasRequest.value = false;
      autoRequestBarcode();
    } catch (e) {
      console.error(e);
      hasRequest.value = false;
      needBarcodeRequest.value = false;
      return;
    }
  };
  if (options.immediate) {
    autoRequestBarcode();
  }

  onBeforeUnmount(() => {
    isDestroyed.value = true;
    ScannerService.clearPromise(handlerId);
  });

  // Если запрос стал не нужен - чистим его
  watch(needBarcodeRequest, (val) => {
    if (!val) ScannerService.clearPromise(handlerId);
    else if (!hasRequest.value) {
      autoRequestBarcode();
    }
  });

  return {
    needBarcodeRequest,
    autoRequestBarcode,
  };
};
