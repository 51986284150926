<template>
  <div
    ref="cont"
    class="drag_verify w-full h-14 relative flex justify-center items-center rounded-2xl bg-day-backgroundInvert"
    :class="{
      'bg-day-backgroundInvert': !disabled,
      'bg-day-controlMinor': disabled,
      disabled,
    }"
    @mousemove="dragMoving"
    @mouseup="dragFinish"
    @touchmove="dragMoving"
    @touchend="dragFinish"
  >
    <div
      ref="progressBar"
      class="absolute left-0 rounded-2xl h-full"
      :class="{ 'bg-yellow-dark': !isPassing, 'bg-green-toxicDark': isPassing }"
      style="transition: width 0.1s, background 0.5s; left: 0"
    />
    <body-2
      class="z-10"
      :color="disabled ? 'day-textMinor' : 'day-textInvert'"
    >
      <slot v-if="!isPassing" />
      <template v-else>
        {{ $gettext('Ожидание') }}
      </template>
    </body-2>
    <div
      ref="handler"
      class="rounded-2xl flex justify-center items-center absolute top-0 cursor-move h-14 w-14 z-20"
      :class="{ 'bg-day-control': !isPassing && !disabled, 'bg-day-line': disabled, 'bg-green-toxic': isPassing }"
      style="transition: left 0.1s, background 0.5s; left: 0"
      data-test="slider-button-handler"
      @mousedown="dragStart"
      @touchstart="dragStart"
    >
      <ArrowRight
        v-if="!isPassing"
        :color="disabled ? 'day-textMinor' : ''"
      />
      <Spinner
        v-else
        class="rotate"
      />
    </div>
    <div
      v-if="!disabled"
      class="absolute right-0 h-14 w-14 flex justify-center items-center"
    >
      <Check color="day-textInvert" />
    </div>
  </div>
</template>
<script lang="ts">
import ArrowRight from '@/temp/icons/arrow-right.vue';
import Check from '@/temp/icons/check.vue';
import Spinner from '@/temp/icons/spinner.vue';
import { defineComponent } from 'vue';

interface Data {
  isMoving: boolean;
  x: number;
  isPassing: boolean;
  resetTimeout?: number;
}

export default defineComponent({
  name: 'SliderButton',
  components: {
    ArrowRight,
    Check,
    Spinner,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['slide-complete'],
  data(): Data {
    return {
      isMoving: false,
      x: 0,
      isPassing: false,
      resetTimeout: undefined,
    };
  },
  beforeUnmount() {
    if (this.resetTimeout) {
      clearTimeout(this.resetTimeout);
    }
  },
  methods: {
    dragStart(e): void {
      if (!this.isPassing && !this.disabled) {
        this.isMoving = true;
        const handler = this.$refs.handler as HTMLDivElement;
        this.x = (e.pageX || e.touches[0].pageX) - parseInt(handler.style.left.replace('px', ''), 10);
      }
    },
    dragMoving(e): void {
      if (!this.$refs.cont) return;

      const cont = this.$refs.cont as HTMLDivElement;
      const handler = this.$refs.handler as HTMLDivElement;
      const progressBar = this.$refs.progressBar as HTMLDivElement;
      const width = cont.clientWidth;
      const height = cont.clientHeight;
      if (this.isMoving && !this.isPassing) {
        const _x = (e.pageX || e.touches[0].pageX) - this.x;
        if (_x > 0 && _x <= width - height) {
          handler.style.left = _x + 'px';
          progressBar.style.width = _x + height / 2 + 'px';
        } else if (_x > width - height) {
          handler.style.left = width - height + 'px';
          progressBar.style.width = width - height / 2 + 'px';
          this.passVerify();
        }
      }
    },
    dragFinish(e): void {
      if (!this.$refs.cont) return;
      const cont = this.$refs.cont as HTMLDivElement;
      const handler = this.$refs.handler as HTMLDivElement;
      const progressBar = this.$refs.progressBar as HTMLDivElement;
      const width = cont.clientWidth;
      const height = cont.clientHeight;
      if (this.isMoving && !this.isPassing) {
        const _x = (e.pageX || e.changedTouches[0].pageX) - this.x;
        if (_x < width - height) {
          handler.style.left = '0';
          progressBar.style.width = '0';
        }
        this.isMoving = false;
      }
    },
    passVerify(): void {
      this.isPassing = true;
      this.isMoving = false;
      this.$emit('slide-complete');
      this.resetTimeout = setTimeout(() => {
        this?.reset?.();
      }, 2000);
    },
    reset(): void {
      this.resetTimeout = undefined;
      this.isPassing = false;
      const handler = this.$refs.handler as HTMLDivElement;
      const progressBar = this.$refs.progressBar as HTMLDivElement;
      handler.style.left = '0';
      progressBar.style.width = '0';
    },
  },
});
</script>

<style>
.rotate {
  animation: 1s linear 0s normal none infinite running rotate;
  -webkit-animation: 1s linear 0s normal none infinite running rotate;
  width: 100px;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
