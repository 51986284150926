import { api } from '@/fsd/data/api/api.service';
import { errorTrueMarkModal } from '@/fsd/entities/modals/errorTrueMarkModal';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { unpackTrueMark } from '@/fsd/shared/tools/unpackTrueMark';
import { TypeAccountingEnum } from '@/models/Product';
import ProductByBarcode from '@/models/ProductByBarcode';
import Suggest from '@/models/Suggest';
import { AudioService } from '@/services/audio.service';
import productQueue from '@/services/queue/product-queue';
import { useProducts } from '@/store/modules/products';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { ERR } from '@/temp/constants/errors';
import { $gettext } from '@/temp/plugins/gettext';

// Блок в котором пробуем на фронте сделать предварительные проверки валидности марки
// true - марка валидна, идем дальше, false - марка не валидна, прекращаем выполнение.
const checkTrueMarkLocal = async (suggest: Suggest, true_mark: string): Promise<boolean> => {
  const product = suggest?.product || (await productQueue.load(suggest.product_id));

  const unpackedMark = unpackTrueMark(true_mark);
  if (!unpackedMark) {
    // это не марка!
    await errorTrueMarkModal({ code: ERR.ER_SUGGEST_TRUE_MARK_REQUIRED });
    return false;
  }

  // пробуем собрать все, что может быть gtin-ом
  const expectedGtins: string[] = [];
  // если мы собираем дочерний продукт, то gtin может быть не заполнен, нужно взять его из родителя.
  if (product.gtin) {
    expectedGtins.push(product.gtin);
  } else if (product.parent_id) {
    // должны заранее положить продукт в кеш, но на всякий случай делаем лоад.
    const parent = await productQueue.load(product.parent_id);
    expectedGtins.push(parent.gtin);
  }

  // сейчас часть продуктов не имеют заполненного gtin, поэтому пробуем достать их из массива баркодов
  if (!product.parent_id) {
    expectedGtins.push(...product.barcode);
  } else {
    const parent = await productQueue.load(product.parent_id);
    expectedGtins.push(...parent.barcode);
  }

  // если нет совпадений ни в gtin, ни в баркодах, то это не тот продукт
  if (!expectedGtins.includes(unpackedMark.gtin)) {
    await errorTrueMarkModal({ code: ERR.ER_SUGGEST_WRONG_PRODUCT_TRUE_MARK });
    return false;
  }

  return true;
};

export const checkBarcodeBySuggest = async (barcode: string, activeSuggest: Suggest): Promise<boolean> => {
  if (useUser().experimentByName(experiments.exp_check_true_mark_on_tsd) && activeSuggest.conditions.need_true_mark) {
    // Флоу проверки марок ЧЗ
    // Сначала проверяем локально
    // После проверяем в апи ЧЗ
    // И только после отправляем запрос на закрытие саджеста
    return await checkTrueMarkLocal(activeSuggest, barcode);
  }

  const productFromCache = useProducts().productByBarcode(barcode);
  if (productFromCache) {
    if (activeSuggest.product_id === productFromCache.product_id) {
      return true;
    } else {
      Alerts.error($gettext('Отсканирован неверный штрихкод %{barcode}', { barcode }));
      return false;
    }
  }
  try {
    //  если ничего не нашли локально, то делаем запрос по ручке баркод
    const { data } = await api.barcode({ barcode });
    const product_ids = data.found.filter(ProductByBarcode.isProductByBarcode).map(p => p.product_id);
    if (product_ids.length === 0) {
      Alerts.error($gettext('Не найден штрихкод %{barcode}', { barcode }));
      return false;
    }
    const products = await useProducts().getProductsByIds(product_ids);
    if (!products) {
      // ошибка загрузки продктов
      Alerts.error($gettext('Не удалось проверить баркод. Попробуйте ещё раз'));
      return false;
    }
    const finded = products.find(p => p.product_id === activeSuggest.product_id);
    if (finded) return true;

    // получили что-то не то.
    const hasCommonParent
      = activeSuggest.product!.type_accounting === TypeAccountingEnum.weight
        && products.some(p => p.type_accounting === TypeAccountingEnum.weight && p.parent_id === activeSuggest.product!.parent_id);
    if (hasCommonParent) {
      Alerts.error($gettext('Отсканирован не тот вес'));
    } else {
      Alerts.error($gettext('Отсканирован не тот товар'));
    }
    return false;
  } catch (error) {
    Alerts.error($gettext('Не удалось проверить баркод. Попробуйте ещё раз'));
    console.error(error);
    AudioService.playError();
    return false;
  }
};
