<template>
  <div class="flex flex-col">
    <NumberInput
      :vehicle_region="vehicle_region"
      :vehicle_number="vehicle_number"
    />
    <div class="flex flex-col px-4 bg-semantic-bg-minor">
      <div class="flex justify-between w-full p-2">
        <KeyboardKey
          v-for="number in keys.numbers"
          :key="number"
          class="w-[30px]"
          :class="digitsBackGround"
          :value="number.toString()"
          @click="onSymbolInput(number)"
        />
      </div>

      <div class="flex justify-between w-full p-2">
        <KeyboardKey
          v-for="digit in keys.digitsFirstLine"
          :key="digit"
          class="w-11"
          :class="lettersBackGround"
          :value="digit"
          @click="onSymbolInput(digit)"
        />
      </div>

      <div class="flex justify-between w-full p-2">
        <div class="h-12 mr-1 w-11 bg-semantic-bg-minor flex justify-center items-center rounded-lg" />
        <KeyboardKey
          v-for="digit in keys.digitsSecondLine"
          :key="digit"
          class="w-11"
          :class="lettersBackGround"
          :value="digit"
          @click="onSymbolInput(digit)"
        />
        <div
          class="h-12 mx-0.5 w-11 bg-semantic-bg-minor flex justify-center items-center rounded-lg"
          data-test="keyboard key D"
          @click="onDeleteSymbol"
        >
          <IconDelete />
        </div>
      </div>
    </div>
    <div class="flex flex-row p-2">
      <UiButton
        background-color="secondary"
        class="mr-1"
        @click="$emit('back')"
      >
        {{ $gettext('Назад') }}
      </UiButton>
      <UiButton
        background-color="primary"
        :is-disabled="isDisabled"
        data-test="save truck-number"
        @click="saveTruckNumber"
      >
        {{ $gettext('Сохранить') }}
      </UiButton>
    </div>
  </div>
</template>
<script lang="ts">
import IconDelete from '@/fsd/shared/icons/icon-delete.vue';
import KeyboardKey from '@/fsd/widgets/acceptance/CheckTransportConditons/condition-steps/components/KeyboardKey.vue';
import NumberInput from '@/fsd/widgets/acceptance/CheckTransportConditons/condition-steps/components/NumberInput.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { getItem, setItem } from '@/utils/sessionStorageHelper';
import { defineComponent } from 'vue';

interface Data {
  vehicle_number: string;
  vehicle_region: string;
  keys: {
    numbers: number[];
    digitsFirstLine: string[];
    digitsSecondLine: string[];
  };
}

export default defineComponent({
  name: 'CarNumberKeyboard',
  components: {
    NumberInput,
    KeyboardKey,
    IconDelete,
    UiButton,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    truckNumber: {
      type: String,
      default: '',
    },
  },
  emits: ['done', 'back'],
  data(): Data {
    return {
      vehicle_number: '',
      vehicle_region: '',
      keys: {
        numbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
        digitsFirstLine: ['А', 'В', 'Е', 'К', 'М', 'Н', 'О'],
        digitsSecondLine: ['Р', 'С', 'Т', 'У', 'Х'],
      },
    };
  },
  computed: {
    isDisabled(): boolean {
      // запрещаем ввод формата ( 0, 00, 000, 0xx, 00x )
      return !this.vehicle_region || !this.vehicle_region.match(/^[1-9]|^(0[1-9])$/);
    },
    isMustBeLetter(): boolean {
      return (this.vehicle_number.length >= 4 && this.vehicle_number.length < 6) || !this.vehicle_number;
    },
    isMustBeDigit(): boolean {
      return (this.vehicle_number.length >= 1 && this.vehicle_number.length < 4) || this.vehicle_number.length >= 6;
    },
    digitsBackGround(): string {
      return this.isMustBeDigit ? 'bg-semantic-bg-custom' : 'bg-semantic-bg-control-minor';
    },
    lettersBackGround(): string {
      return this.isMustBeLetter ? 'bg-semantic-bg-custom' : 'bg-semantic-bg-control-minor';
    },
  },
  mounted() {
    const savedNumber = getItem('truck_number', this.name);
    const truck_number = savedNumber || this.truckNumber;
    this.vehicle_number = truck_number.slice(0, 6);
    this.vehicle_region = truck_number.slice(6, 9);
  },
  methods: {
    onSymbolInput(symbol: string | number): void {
      if (this.isMustBeLetter && Number.isInteger(symbol)) return;
      if (this.isMustBeDigit && !Number.isInteger(symbol)) return;
      if (this.vehicle_region.length === 3) return;
      if (this.vehicle_number.length < 6) this.vehicle_number += symbol.toString();
      else {
        this.vehicle_region += symbol;
      }
      this.saveInStorage();
    },
    onDeleteSymbol(): void {
      if (this.vehicle_region.length) {
        this.vehicle_region = this.vehicle_region.slice(0, -1);
      } else {
        this.vehicle_number = this.vehicle_number.slice(0, -1);
      }
      this.saveInStorage();
    },
    saveTruckNumber(): void {
      this.$emit('done', this.fullNumber());
    },
    saveInStorage(): void {
      setItem('truck_number', this.name, this.fullNumber());
    },
    fullNumber(): string {
      return this.vehicle_number + this.vehicle_region;
    },
  },
});
</script>
