<template>
  <Layout>
    <div
      ref="container"
      class="flex flex-col h-full w-full"
    >
      <Hint class="m-4">
        {{ $gettext('Покажите QR-код на кассе') }}
      </Hint>
      <QrcodeVue
        :value="'515&' + order.external_id"
        :size="width"
        :margin="8"
      />
    </div>
    <template #footer>
      <LayoutFooter>
        <SliderButton
          data-test="order-finish qrcode next-stage-btn"
          @slide-complete="$emit('done')"
        >
          {{ $gettext('Завершить сборку') }}
        </SliderButton>
      </LayoutFooter>
    </template>
  </Layout>
</template>

<script setup lang="ts">
import BaseOrder from '@/models/orders/BaseOrder';
import Hint from '@/ui/common/hint/hint.vue';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import SliderButton from '@/ui/common/slider-button/slider-button.vue';
import QrcodeVue from 'qrcode.vue';
import {
  onMounted, ref, useTemplateRef,
} from 'vue';
import {
  OrderFinishingDeltaNamesEnum,
  OrderFinishingMarkNamesEnum,
  useRUMOrderFinishingStep,
} from '../tools/useMeasureOrderFinish';

interface Props {
  order: BaseOrder;
}

const props = defineProps<Props>();
defineEmits(['done']);

const container = useTemplateRef<HTMLDivElement>('container');
const width = ref(200);

useRUMOrderFinishingStep({
  orderId: props.order.order_id,
  markName: OrderFinishingMarkNamesEnum.QR_CODE,
  deltaName: OrderFinishingDeltaNamesEnum.QR_CODE,
});

onMounted(() => {
  if (container.value) {
    width.value = container.value.clientWidth;
  }
});
</script>
