import { callAction } from '@/fsd/shared/ui/action';
import Shelf from '@/models/Shelf';
import type { BarcodeRequester } from '@/hooks/useRequestBarcode';
import RequestShelfModal from './RequestShelfModal.vue';
import { RequestShelfModalOptions } from './types';

export const requestShelfFromUser = async (requester: BarcodeRequester, options: RequestShelfModalOptions = {}) => {
  return callAction<{
    requester: BarcodeRequester;
    options: RequestShelfModalOptions;
  }, Shelf | undefined>(RequestShelfModal, {
    requester,
    options,
  });
};
