import { Found } from '@/services/response';
import { BaseModel } from 'sjs-base-model';

export default class ProductByBarcode extends BaseModel {
  public type: string = 'product';
  public product_id: string = '';
  public external_id: string = '';
  public title: string = '';
  public long_title: string = '';
  public valid: string = '';
  public write_off_before: string = '';
  public true_mark: boolean = false;
  public count: number | undefined = undefined; // для ЧЗ, если это КИГ (упаковка) вернется число

  constructor(data: any) {
    super({ expand: true });
    this.update(data);
  }

  static isProductByBarcode(found: Found): found is ProductByBarcode {
    return found.type === 'product';
  }
}
