import { ILogger } from '@/temp/plugins/logs/types';
import {
  errorHandler, eventHandler, logHandler,
} from './handlers';

const logger: ILogger = Object.assign(logHandler, {
  error: errorHandler,
  event: eventHandler,
});

export { logger };
