<template>
  <PageLayout :order_id="order_id">
    <template #header>
      <Bar
        :order="order"
        :text="controlBarText"
        :caption="controlBarCaption"
        @close-click="toHomePage"
      />
    </template>

    <template #default>
      <div>
        <div class="my-2 mx-1">
          <Hint>{{ hintText }}</Hint>
        </div>

        <SuggestCard
          v-for="(suggest, index) in requestSuggests"
          :key="suggest.suggest_id"
          :suggest="suggest"
          :order="order"
          :suggest-menu-config="suggestMenuConfig(suggest)"
          :doc-type="OrderTypeEnum.shipment_rollback"
          need-menu
          :data-index="`request ${index}`"
          @open-suggest-menu="() => (uiStateNeedBarcodeRequest = false)"
          @close-suggest-menu="() => (uiStateNeedBarcodeRequest = true)"
        />

        <SuggestCard
          v-for="(suggest, index) in doneSuggests"
          :key="suggest.suggest_id"
          :suggest="suggest"
          :order="order"
          :doc-type="OrderTypeEnum.shipment_rollback"
          :data-index="`done ${index}`"
        />

        <SuggestDetails
          v-if="suggestDetails.visible.value && suggestDetails.props.value"
          :suggest-id="suggestDetails.props.value.suggest_id"
          :order-id="order.order_id"
          @cancel="suggestDetails.hide"
          @finish="props => finishActiveSuggest(props, suggestDetails.props.value)"
        />
      </div>
    </template>

    <template #footer>
      <LayoutFooter>
        <UiButton
          v-if="order.vars.stage === OrderStageEnum.store"
          data-test="trash-stage shipment_rollback btn"
          @click="toTrashStage"
        >
          {{ $gettext('Перейти к списанию') }}
        </UiButton>
        <UiButton
          v-if="order.vars.stage === OrderStageEnum.trash"
          data-test="finish shipment_rollback btn"
          @click="finishOrder"
        >
          {{ $gettext('Завершить') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </PageLayout>
</template>

<script lang="ts">
import { useSubscribeOnOrder } from '@/fsd/data/utils/subscribeOnOrder';
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import { getDoneSuggests, getRequestSuggests } from '@/fsd/entities/filters/suggestsFilters';
import { useBox2Shelf } from '@/fsd/entities/suggest/tools/useBox2Shelf';
import { useShelf2Box } from '@/fsd/entities/suggest/tools/useShelf2Box';
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import { useComponent } from '@/hooks/useComponent';
import { useHandleOrderStatus } from '@/hooks/useHandleOrderStatus';
import requestBarcode from '@/mixins/requestBarcode';
import requestProductCode from '@/mixins/requestProductCode';
import Suggest, { SuggestStatusEnum, SuggestTypeEnum, SuggestVarsStageEnum } from '@/models/Suggest';
import BaseOrder, { OrderEstatusEnum, OrderStageEnum } from '@/models/orders/BaseOrder';
import ShipmentRollbackOrder from '@/models/orders/ShipmentRollbackOrder';
import { Box2ShelfTrashReasonEnum, SignalEnum } from '@/services/requests';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { logger } from '@/temp/plugins/logs';
import Bar from '@/ui/common/bar/bar.vue';
import Hint from '@/ui/common/hint/hint.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import SuggestCard from '@/ui/common/suggest-card/suggest-card.vue';
import SuggestDetails from '@/ui/common/suggest-details/suggest-details.vue';
import { Model } from '@/ui/common/suggest-details/types';
import UiButton from '@/ui/common/ui-button.vue';
import { checkConditions, OperationEnum } from '@/utils/checkConditions';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { OrderTypeEnum } from '@/models/orders/BaseOrder';
import { ScannerService } from '@/services/scanner/scanner.service';
import { requestShelfFromUser } from '@/fsd/entities/actions/RequestShelfFromUser';

interface Data {
  uiStateNeedBarcodeRequest: boolean;
}

export default defineComponent({
  name: 'ShipmentRollback',
  components: {
    PageLayout,
    LayoutFooter,
    Bar,
    Hint,
    SuggestDetails,
    UiButton,
    SuggestCard,
  },
  mixins: [requestBarcode, requestProductCode],
  props: {
    order_id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { showLoader } = useLoader();
    const ordersStore = useOrders();
    const productsStore = useProducts();
    const shareOrder = useComponent();
    const suggestDetails = useComponent<Suggest>();
    const router = useRouter();

    useHandleOrderStatus(props.order_id);

    return {
      showLoader,
      ordersStore,
      productsStore,
      shareOrder,
      suggestDetails,
      router,
      OrderTypeEnum,
      OrderStageEnum,
    };
  },
  data(): Data {
    return {
      uiStateNeedBarcodeRequest: true,
    };
  },
  computed: {
    order(): ShipmentRollbackOrder {
      return this.ordersStore.orderById(this.order_id) as ShipmentRollbackOrder;
    },
    stage(): string {
      return this.order?.vars?.stage || 'store';
    },
    suggests(): Suggest[] {
      if (!this.order) return [];
      if (this.stage === 'canceling') {
        return this.order.suggests.filter(suggest => suggest.vars.stage === SuggestVarsStageEnum.canceling);
      }
      if (this.stage === 'trash') {
        return this.order.suggests.filter(suggest => suggest.vars.stage === SuggestVarsStageEnum.trash);
      }
      // нет поля vars.stage
      return this.order.suggests.filter(suggest => suggest.vars.stage === SuggestVarsStageEnum.store);
    },
    doneSuggests(): Suggest[] {
      return getDoneSuggests(this.suggests);
    },
    requestSuggests(): Suggest[] {
      return getRequestSuggests(this.suggests);
    },
    stageSuggestsByProductId(): (product_id: string) => Suggest[] {
      return (product_id) => {
        return this.suggests.filter(suggest => suggest.product_id === product_id);
      };
    },
    controlBarCaption(): string {
      if (this.stage === 'store') {
        return this.$gettext('Возврат отгрузки № %{doc_number}', {
          doc_number: (this.order && this.order.attr.doc_number) || '',
        });
      }
      if (this.stage === 'canceling') {
        return this.$gettext('Отмена возврата отгрузки');
      }
      return this.$gettext('Списание');
    },
    controlBarText(): string {
      return this.$gettext('Осталось %{request} из %{all} товаров', {
        request: String(this.requestSuggests.length),
        all: String(this.suggests.length),
      });
    },
    suggestMenuConfig(): (suggest: Suggest) => MenuItemConfig[] {
      return (suggest) => {
        const menuConfig: MenuItemConfig[] = [];
        if (!suggest) return menuConfig;
        const noProductBtn: MenuItemConfig = {
          buttonText: this.$gettext('Отметить как отсутствующий'),
          onClick: () => {
            useBox2Shelf(this.order_id, {
              suggest_id: suggest.suggest_id,
              count: 0,
            });
          },
          condition: () => checkConditions(suggest, OperationEnum.all, true),
        };
        menuConfig.push(noProductBtn);

        return menuConfig;
      };
    },
    confirmFinishTitle(): string {
      if (this.stage === 'trash' && this.suggests.length === 0) {
        return this.$gettext('Вы уверены, что хотите завершить задание?');
      }
      return this.$gettext('Вы уверены, что разместили товары из корзины на полке «Списание»?');
    },
    hintText(): string {
      if (this.stage === 'trash' && this.suggests.length === 0) {
        return this.$gettext('Отсутствуют товары для списания, можно завершать задание');
      }
      return this.$gettext('Отсканируйте товар');
    },
  },
  methods: {
    async requestBarcode(): Promise<boolean> {
      const { product, item } = await this.requestProductCode({ checkSuggests: true });
      if (!product && !item) {
        return true;
      }
      return await this.selectProduct(product || item);
    },
    toHomePage(): void {
      this.router.push({ name: 'home' });
    },
    async finishActiveSuggest({ count = 0, reason }: Pick<Model, 'count' | 'reason'>, suggest?: Suggest): Promise<any> {
      if (!suggest) return;

      if (suggest.type === SuggestTypeEnum.box2shelf) {
        const result = await useBox2Shelf(this.order_id, {
          suggest_id: suggest.suggest_id,
          count,
          reason: reason
            ? {
                code: reason as Box2ShelfTrashReasonEnum | 'LIKE_SHELF' | 'TTL',
                count,
              }
            : undefined,
        });
        if (!result) return;
      } else {
        const result = await useShelf2Box(this.order_id, {
          suggest_id: suggest.suggest_id,
          count,
        });
        if (!result) return;
      }
      this.suggestDetails.hide();
    },
    async finishOrder(): Promise<void> {
      const confirm = await this.$notification.confirmBottom({
        title: this.confirmFinishTitle,
      });
      if (!confirm) return;
      this.uiStateNeedBarcodeRequest = false;
      const isLastStage = await this.toFinalStage();
      if (!isLastStage) {
        this.uiStateNeedBarcodeRequest = true;
        return;
      }
      const result = await useEndOrder(this.order_id);
      if (result) {
        this.toHomePage();
      } else {
        this.uiStateNeedBarcodeRequest = true;
      }
    },
    async selectSuggest(suggest): Promise<void> {
      if (suggest.status === SuggestStatusEnum.done && !suggest.isEditable) {
        this.$alert.error(this.$gettext('Данное задание уже выполнено!'));
        throw 'suggest already complete and not editable';
      }
      await this.suggestDetails.asyncShow(suggest);
    },
    async selectProduct(product): Promise<boolean> {
      const stageSuggests = this.stageSuggestsByProductId(product.product_id || product.item_id);
      const requestStageSuggests = getRequestSuggests(stageSuggests);

      if (stageSuggests.length === 0) {
        this.$alert.error(this.$gettext('%{barcode} нет в задании', { barcode: product.barcode[0] }));
        return true;
      }
      if (requestStageSuggests.length === 0) {
        this.$alert.error(this.$gettext('Товар уже размещен'));
        return true;
      }
      if (requestStageSuggests.length === 1) {
        await this.selectSuggest(requestStageSuggests[0]);
        return true;
      }
      const requester = () => ScannerService.requestCode(this.$options.name + this._uuid);
      const shelf = await requestShelfFromUser(requester);
      if (!shelf) return true;
      const suggest = requestStageSuggests.find(item => item.shelf_id === shelf.shelf_id);
      if (!suggest) {
        this.$alert.error(this.$gettext('Отсканирована неверная полка'));
        return this.selectProduct(product);
      }
      await this.selectSuggest(suggest);
      return true;
    },
    async toTrashStage(): Promise<void> {
      const confirm = await this.$notification.confirmBottom({
        title: this.$gettext('Вы уверены, что разместили все товары?'),
      });
      if (!confirm) return;
      if (this.order!.vars.stage === OrderStageEnum.trash) return;
      const { closeLoader } = this.showLoader(this.$gettext('Ожидаем переход на стадию списания'), this.order_id);
      try {
        await this.ordersStore.signal({
          order_id: this.order!.order_id,
          signal: SignalEnum.next_stage,
          data: { stage: 'trash' },
        });
        if ((this.order as BaseOrder)!.vars.stage === OrderStageEnum.trash) {
          closeLoader();
          return;
        }
        this.uiStateNeedBarcodeRequest = false;
        await useSubscribeOnOrder(this.order_id)((o) => {
          if (!o) return true;
          if (o.vars.stage === OrderStageEnum.trash && o.estatus === OrderEstatusEnum.waiting) {
            return true;
          }
          return false;
        });
        closeLoader();
        this.uiStateNeedBarcodeRequest = true;
      } catch (error) {
        logger.error(error, {
          method: 'toTrashStage',
          type: 'shipment_rollback',
        });
        closeLoader();
      }
    },
    async toFinalStage(): Promise<boolean> {
      if (!this.order) return true;
      if (this.order.vars.stage === OrderStageEnum.all_done) {
        return true;
      }
      const { closeLoader } = this.showLoader(this.$gettext('Ожидаем переход на стадию завершения'), this.order_id);
      try {
        await this.ordersStore.signal({
          order_id: this.order.order_id,
          signal: SignalEnum.next_stage,
          data: { stage: 'all_done' },
        });
        if ((this.order as BaseOrder).vars.stage === OrderStageEnum.all_done) {
          closeLoader();
          return true;
        }
        await useSubscribeOnOrder(this.order_id)((o) => {
          if (!o) return true;
          if (o.vars.stage === OrderStageEnum.all_done && o.estatus === OrderEstatusEnum.waiting) {
            return true;
          }
          return false;
        });
        closeLoader();
        return true;
      } catch {
        closeLoader();
        return false;
      }
    },
  },
});
</script>
