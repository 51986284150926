<template>
  <div class="card-container">
    <div>
      <div class="content">
        <!-- Слайдер, внутри которого сначала идут слайды с requestedOrders, потом слайды с failedOrders
          failedOrders - ордера с проблемами.
          Например, заказ на 5 пирожков, а на складе только 4 -->
        <Swiper
          :space-between="0"
          :modules="modules"
          virtual
          @progress="setProgress"
        >
          <SwiperSlide
            v-for="(requestedOrder, requestedOrderIndex) in requestedOrders"
            :key="requestedOrder.order_id"
            :virtual-index="requestedOrderIndex"
            :data-index="requestedOrderIndex"
          >
            <Order
              :order-id="requestedOrder.order_id"
              class="my-2 mx-4"
            />
          </SwiperSlide>
          <SwiperSlide
            v-for="(failedOrder, failedOrderIndex) in failedOrders"
            :key="failedOrder.order_id + failedOrderIndex"
            :virtual-index="requestedOrders.length - 1 + failedOrderIndex"
            :data-index="failedOrderIndex"
          >
            <Order
              :order-id="failedOrder.order_id"
              class="my-2 mx-4"
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
    <div
      v-if="requestedOrders.length + failedOrders.length > 1"
      class="progress"
    >
      <div class="line">
        <div
          class="indicator"
          :style="{ left: progressPercent }"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Order } from '@/fsd/entities/order/ui/order-card/';
import OrderType from '@/models/orders/BaseOrder';
import { Virtual } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { PropType, defineComponent } from 'vue';

interface Data {
  progress: number;
}

export default defineComponent({
  components: {
    Order,
    Swiper,
    SwiperSlide,
  },
  props: {
    requestedOrders: {
      type: Array as PropType<OrderType[]>,
      default: () => [],
    },
    failedOrders: {
      type: Array as PropType<OrderType[]>,
      default: () => [],
    },
  },
  data(): Data {
    return {
      progress: 0,
    };
  },
  computed: {
    progressPercent() {
      return `calc(${this.progress * 100}% - 0.25rem)`;
    },
    modules() {
      return [Virtual];
    },
  },
  methods: {
    setProgress(swiper, progress) {
      this.progress = progress;
    },
  },
});
</script>

<style scoped>
.card-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  box-sizing: border-box;
  padding: 0;
  z-index: 2;
  position: relative;
}

.content::-webkit-scrollbar {
  display: none;
}

.progress {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
  bottom: 0.875rem;
  padding: 1rem 0 0;
  z-index: 1;
}

.line {
  border-radius: 0.5rem;
  height: 0.25rem;
  position: relative;
  width: 4rem;
}

.line::before {
  background: #c4c2be;
  border-radius: 0.5rem;
  bottom: 0;
  content: '';
  display: block;
  left: -0.25rem;
  opacity: 0.2;
  position: absolute;
  right: -0.25rem;
  top: 0;
}

.indicator {
  border: 0.125rem solid var(--main-bg);
  background: var(--text-primary-color);
  border-radius: 0.5rem;
  height: 0.75rem;
  left: 0;
  position: absolute;
  top: -0.25rem;
  width: 0.75rem;
  transition: left 150ms ease 0ms;
}
</style>
