<template>
  <PageLayout :order_id="order_id">
    <template #header>
      <Bar
        :menu-config="menuConfig"
        :order="order"
        :menu-title="activeSuggest?.product?.title"
        @close-click="close"
      />
    </template>
    <template #default>
      <ProductCheckSuggestViewer
        v-if="activeSuggest"
        :key="activeSuggest?.suggest_id"
        :order_id="order_id"
        :suggest_id="activeSuggest?.suggest_id"
        :valid-type="validType"
      />
    </template>
  </PageLayout>
</template>

<script lang="ts" setup>
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import BaseOrder, { OrderTypeEnum } from '@/models/orders/BaseOrder';
import CheckProductOnShelfOrder from '@/models/orders/CheckProductOnShelfOrder';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import { useOrders } from '@/store/modules/orders';
import { useSuggests } from '@/store/modules/suggests';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { $gettext } from '@/temp/plugins/gettext';
import Bar from '@/ui/common/bar/bar.vue';
import { MenuItemConfig } from '@/ui/common/menu/types';
import ProductCheckSuggestViewer from '@/views/ProductCheck/ProductCheckSuggestViewer.vue';
import { ValidTypeEnum } from '@/views/ProductCheck/types';
import {
  computed, ref, watch,
} from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps<{ order_id: BaseOrder['order_id'] }>();

const router = useRouter();
const order = computed(() => {
  return useOrders().orderById(props.order_id);
});
const suggests = computed(() => {
  return useSuggests().suggestsByOrderId(props.order_id);
});

const activeSuggest = computed<Suggest | undefined>(() => {
  if (!suggests.value) return undefined;
  for (const s of suggests.value.values()) {
    if (s.status === SuggestStatusEnum.request) return s;
  }
  return undefined;
});

const validType = ref<ValidTypeEnum>(
  useUser().experimentByName(experiments.exp_manhattan)
    ? ValidTypeEnum.productionDate
    : ValidTypeEnum.expirationDate,
);
const menuConfig = computed(() => {
  const menuConfig: MenuItemConfig[] = [];

  const changeValidTypeBtn: MenuItemConfig = {
    buttonText:
      validType.value === ValidTypeEnum.expirationDate
        ? $gettext('Ввод даты изготовления')
        : $gettext('Ввод срока годности'),
    buttonSecondText: $gettext('Изменить на'),
    onClick: () => {
      Alerts.success($gettext('Тип вводимой даты успешно изменен'));
      if (validType.value === ValidTypeEnum.productionDate) {
        validType.value = ValidTypeEnum.expirationDate;
      } else {
        validType.value = ValidTypeEnum.productionDate;
      }
    },
    condition: () => useUser().experimentByName(experiments.exp_manhattan),
  };
  menuConfig.push(changeValidTypeBtn);

  return menuConfig;
});
const toHomePage = () => {
  router.push({ name: 'home' });
};
const close = async () => {
  if (
    order.value?.type === OrderTypeEnum.check_product_on_shelf
    && (order.value as CheckProductOnShelfOrder).vars.reserve
    && order.value.source === 'tsd'
  ) {
    try {
      await useOrders().cancelOrder(props.order_id);
    } catch (e) {
      console.error(e);
    }
  }
  toHomePage();
};
const finishOrder = async () => {
  const parent = order.value?.parent[0];
  const result = await useEndOrder(props.order_id);
  if (result) {
    if (parent) useOrders().clearBorder(parent);
    toHomePage();
  }
};

// проверяем есть ли у нас информация о границах, если  это пересчет по распоряжению
const checkBorderForParent = async () => {
  if (!order.value) return;
  // пересчеты по распоряжению имеют source === tsd и не пустого родителя
  if (
    order.value.parent.length === 0
    || order.value.source !== 'tsd'
    || order.value.type === OrderTypeEnum.inventory_check_product_on_shelf
  )
    return;
  const borders = useOrders().recountBorders.get(order.value.parent[0]);
  // мы потеряли инфо о границах, нужно выйти из сценария.
  if (!borders) {
    await useOrders().cancelOrder(props.order_id);
    Alerts.error($gettext('Произошла ошибка при получении остатков. Попробуйте позже'));
    toHomePage();
  }
};
const checkActiveSuggest = () => {
  // еще не загрузились
  if (!suggests.value) return;
  if (!activeSuggest.value) {
    finishOrder();
  }
};

checkBorderForParent();
watch(order, checkBorderForParent);
checkActiveSuggest();
watch(activeSuggest, checkActiveSuggest);
</script>
