import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { UserLanguageEnum } from '@/models/User';
import { languages } from '@/temp/constants';
import { logger } from '@/temp/plugins/logs';
import 'dayjs/locale/ar';
import 'dayjs/locale/az';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/he';
import 'dayjs/locale/ru';
import 'dayjs/locale/tr';
import 'dayjs/locale/uz';
import { createGettext } from 'vue3-gettext';

const translations = {};

export const gettext = createGettext({
  availableLanguages: {
    ...languages,
    base: 'Русский базовый',
  },
  defaultLanguage: UserLanguageEnum.ru_RU,
  translations,
  silent: true,
});

export const $gettext = gettext.$gettext;
export const $ngettext = gettext.$ngettext;

export async function setLanguage(value: UserLanguageEnum) {
  if (gettext.translations[value]) {
    gettext.current = value;
    return;
  }
  try {
    const mapLangToJson: Record<UserLanguageEnum, string> = {
      ru_RU: 'ru',
      en_EN: 'en',
      he_HE: 'he',
      fr_FR: 'fr',
      ar_SA: 'ar',
      uz_UZ: 'uz',
      tr_TR: 'tr',
      az_AZ: 'az',
      kk_KZ: 'kk',
      es_419: 'es-la',
    };
    if (!mapLangToJson[value]) {
      logger.error('wrong user language', {
        additional: {
          lang: value,
        },
      });
      return;
    }
    const data = await import(`../../translations/i18n/${mapLangToJson[value]}.json`);
    gettext.translations = {
      ...gettext.translations,
      [value]: data.default,
    };
    gettext.current = value;
  } catch {
    Alerts.error($gettext('Произошла ошибка при загрузке переводов'));
  }
}

type ObjectOfStringsOrString = { [key: string]: ObjectOfStringsOrString | string };

/**
 * Обертка для объектов с переводами, которая
 * Превращает поля в геттеры, что повзволяет их динамически переводить
 *
 * заголовки используются в 2 разных сценариях: карточка саджеста(просмотр/выполнение) и список саджестов (request/done)
 * в большинстве случаев они одинаковые, но могут быть и различия.
 * такие различия(если нужно) разруливаем по месту.
 */

export function gettextWrapper<T extends ObjectOfStringsOrString>(object: T): T {
  const result = {} as any;
  for (const [key, value] of Object.entries(object)) {
    Object.defineProperty(result, key, {
      get() {
        if (typeof value === 'string') {
          // Эта копия нужна, чтобы геттекс не пытался перевод у value, которого нет
          const gettextCopy = gettext.$gettext;
          return gettextCopy(value);
        }
        return gettextWrapper(value);
      },
      enumerable: true,
    });
  }
  return result;
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $gettext: typeof gettext.$gettext;
    $ngettext: typeof gettext.$ngettext;
  }
}
