<template>
  <Overlay class="!z-[999]">
    <Layout>
      <template #header>
        <DeleteReasonWindowHeader
          :order="order"
          @back="$emit('close')"
        />
      </template>
      <template #default>
        <div class="h-full bg-element flex flex-col gap-2">
          <div class="bg-main rounded-b-3xl p-4">
            <Title2 class="font-bold px-1">
              {{ $gettext('Укажите причину несборки товара') }}
            </Title2>
            <SuggestCard
              v-if="suggest"
              class="mt-2"
              :suggest="suggest"
              :collected="suggest.result_count || collected || 0"
              :need-buttons="false"
              :need-right-buttons="false"
            />
          </div>
          <div
            class="bg-main rounded-t-3xl p-4 h-full"
          >
            <Body2 class="font-medium">
              {{ $gettext('Причины несборки') }}
            </Body2>
            <RadioGroup
              v-model="selectedDeleteReason"
              :items="deleteReasons"
            />
          </div>
        </div>
      </template>
      <template #footer>
        <DeleteReasonWindowFooter
          :suggest="suggest"
          :saved-delete-reason="savedDeleteReason"
          :selected-delete-reason="selectedDeleteReason"
          @done="submitDeleteReason"
          @close="$emit('close')"
        />
      </template>
    </Layout>
  </Overlay>
</template>

<script setup lang="ts">
import Suggest from '@/models/Suggest';
import { useUser } from '@/store/modules/user';
import Layout from '@/ui/common/layout.vue';
import Title2 from '@/ui/common/typo/title-2.vue';
import Overlay from '@/ui/common/overlay/overlay.vue';
import DeleteReasonWindowFooter from './DeleteReasonWindowFooter.vue';
import DeleteReasonWindowHeader from './DeleteReasonWindowHeader.vue';
import RadioGroup from '@/temp/ui/inputs/radio-group/radio-group.vue';
import { SuggestReasonGroup, SuggestReason } from '@/models/Constants';
import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import SuggestCard from '../SuggestCard/SuggestCard.vue';
import { computed, ref } from 'vue';

interface DeleteReasonProps {
  suggest_id: Suggest['suggest_id'];
  order: OrderOrderRetail;
  collected: number;
}

const props = defineProps<DeleteReasonProps>();

const emits = defineEmits<{
  (e: 'close', confirmed?: SuggestReason['value']): void;
}>();

const userStore = useUser();

/**
 * Выбранная причина несборки товара
 */
const selectedDeleteReason = ref<SuggestReason['value'] | undefined>();

/**
 * Саджест, для которого указываем причину несборки
 */
const suggest = computed(
  () => props.order.suggests.find(s => s.suggest_id === props.suggest_id),
);

/**
 * Список причин несборки
 */
const deleteReasons = computed<Record<string, string>>(() => {
  const reasons = userStore.suggestsReasonsByGroup[SuggestReasonGroup.cancel_reasons] ?? [];

  return reasons.reduce((acc, cur) => {
    acc[cur.value] = cur.title;
    return acc;
  }, {});
});

/**
 * Сохраненная причина удаления
 */
const savedDeleteReason = computed(() => suggest.value?.reason?.code);

const submitDeleteReason = () => {
  if (!selectedDeleteReason.value) return;

  emits('close', selectedDeleteReason.value);
};

</script>
