<template>
  <ActionWrapper>
    <div class="flex flex-col gap-2 pt-1 px-1">
      <Title4 class="font-bold">
        {{ $gettext('Остаток на полках') }}
      </Title4>
      <Body2
        v-if="product"
        color="semantic-text-minor"
      >
        {{ product.title }}
      </Body2>
      <Hint bg-color="bg-blue-info">
        <template #image>
          <IconInfoFill />
        </template>
        <template #text>
          {{ $gettext('Остаток может меняться') }}
        </template>
        <template #sub-text>
          {{ $gettext('Информация актуальна на %{time}', { time: requestTime }) }}
        </template>
      </Hint>
      <Body2 class="font-medium">
        {{ $gettext('Полка') }}
      </Body2>
      <div
        v-for="p in products"
        :key="p.product_id"
        class="flex flex-row justify-between border-b border-gray-150 py-2"
      >
        <Body2 color="semantic-text-minor">
          {{ shelfById(p.shelf_id).title }}
        </Body2>
        <Body2 class="font-medium">
          {{ $gettext('%{count} шт.', { count: p.count.toString() }) }}
        </Body2>
      </div>
      <UiButton
        class="mt-2"
        background-color="secondary"
        data-test="available-product btn-close"
        @click="$emit('btn-click')"
      >
        {{ $gettext('Закрыть') }}
      </UiButton>
    </div>
  </ActionWrapper>
</template>
<script setup lang="ts">
import IconInfoFill from '@/fsd/shared/icons/IconInfoFill.vue';
import Hint from '@/fsd/shared/ui/hint/hint.vue';
import Shelf from '@/models/Shelf';
import { useProducts } from '@/store/modules/products';
import { $gettext } from '@/temp/plugins/gettext';
import { AvailableProduct } from '@/types/product';
import ActionWrapper from '@/ui/common/notifications/actionWrapper.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import UiButton from '@/ui/common/ui-button.vue';
import dayjs from 'dayjs';
import { computed } from 'vue';

interface AvailableProductActionProps {
  product_id: string;
  products: AvailableProduct[];
  shelves: Shelf[];
}

const {
  product_id, products, shelves,
} = defineProps<AvailableProductActionProps>();

defineEmits<{ (e: 'btn-click'): void }>();

const product = computed(() => useProducts().productById(product_id));

const shelfById = (id: Shelf['shelf_id']) => shelves.find(s => s.shelf_id === id) as Shelf;

const requestTime = computed<string>(() => dayjs().format('HH:mm:ss'));
</script>
