import { checkNeedTrueMarkModal, errorTrueMarkModal } from '@/fsd/entities/modals/errorTrueMarkModal';
import retryBox2ShelfModal from '@/fsd/entities/modals/retryBox2ShelfModal';
import retryShelf2BoxModal from '@/fsd/entities/modals/retryShelf2BoxModal';
import { useBox2Shelf } from '@/fsd/entities/suggest/tools/useBox2Shelf';
import { prepareBarcodes, useShelf2Box } from '@/fsd/entities/suggest/tools/useShelf2Box';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { checkBarcodeBySuggest } from '@/fsd/widgets/order/RequiredCard/useCollectOrder';
import {
  finishAssembling,
  finishCheckTrueMark,
  finishDoneRequest,
  finishEventWaiting,
  finishRequestBarcode,
  sendRequestBarcodeTime,
  startAssembling,
  startRequestBarcode,
} from '@/fsd/widgets/order/rum/useMeasureAssemblingPosition';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Product from '@/models/Product';
import Suggest, { SuggestStatusEnum, SuggestTypeEnum } from '@/models/Suggest';
import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';
import { OrderTargetEnum } from '@/models/orders/BaseOrder';
import OrderOrder from '@/models/orders/OrderOrder';
import { AudioService } from '@/services/audio.service';
import itemQueue from '@/services/queue/item-queue';
import productQueue from '@/services/queue/product-queue';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { modalNotifyId } from '@/temp/constants/common';
import { $gettext } from '@/temp/plugins/gettext';
import { TrueMark } from '@/temp/services/true-mark';
import { useLoader } from '@/ui/common/loader/useLoader';
import { needLessWeightModal } from '@/utils/modals';
import { Collected } from '@/views/Order/types';
import { notify } from '@kyvg/vue3-notification';
import { computed, Ref, ref, watch } from 'vue';

interface UseCollectOrderProps {
  order_id: Ref<string>;
  order: Ref<OrderOrder | undefined>;
  suggestsForCollect: Ref<Suggest[]>;
  activeSuggest: Ref<Suggest | undefined>;
  activeSuggestIndex: Ref<number>;
  collected: Ref<Record<Suggest['suggest_id'], Collected>>;
  updateActiveSuggestIndex: (newActiveIndex: number) => void;
  updateCollected: (suggest_id: Suggest['suggest_id'], newVal: Collected) => void;
}

export const useCollectOrder = ({
  order_id,
  order,
  suggestsForCollect,
  activeSuggest,
  activeSuggestIndex,
  collected,
  updateActiveSuggestIndex,
  updateCollected,
}: UseCollectOrderProps) => {
  const { showLoader } = useLoader();
  const expTrueMarkMerged = useUser().experimentByName(experiments.exp_true_mark_merged);

  const trueMarkQueue = ref<Record<string, boolean>>({});

  const hasProblemOnActiveSuggest = computed(() => {
    if (!activeSuggest.value || !order.value || order.value.target === OrderTargetEnum.canceled) return false;
    return order.value.problems.filter(p => p.product_id).find(p => p.product_id === activeSuggest.value?.product_id);
  });

  const hasSuggestsToCollect = computed(() => {
    return suggestsForCollect.value.some(s => s.status === SuggestStatusEnum.request);
  });

  const setTrueMarkQueue = (suggest: Suggest) => {
    if (suggest.conditions.need_true_mark && expTrueMarkMerged) {
      trueMarkQueue.value[suggest.suggest_id] = true;
    }
  };

  const clearTrueMarkQueue = (suggest: Suggest) => {
    if (suggest.conditions.need_true_mark && expTrueMarkMerged && trueMarkQueue.value[suggest.suggest_id]) {
      delete trueMarkQueue.value[suggest.suggest_id];
    }
  };

  const getSuggestById = (
    suggest_id: Suggest['suggest_id'] | TrueMarkSuggestWrapper['suggest_id'],
  ): Suggest | TrueMarkSuggestWrapper | undefined => {
    const suggest = suggestsForCollect.value.find(s => s.suggest_id === suggest_id);
    if (
      expTrueMarkMerged
      && suggest
      && TrueMarkSuggestWrapper.isTrueMarkSuggestWrapper(suggest)
      && suggest.suggests.length
    ) {
      return suggest.suggests.find(s => s.status === SuggestStatusEnum.request && !trueMarkQueue.value[s.suggest_id]);
    }
    return suggest;
  };

  const clearCollected = (suggest?: Suggest) => {
    if (!suggest) return;
    updateCollected(suggest.suggest_id, { count: 0 });
  };

  const decreaseCollectedCount = (suggest: Suggest) => {
    const activeCollectedCount = (collected.value[suggest.suggest_id].count ?? 0);

    updateCollected(suggest.suggest_id, { count: activeCollectedCount ? activeCollectedCount - 1 : 0 });
  };

  const selectNext = () => {
    // найти не выполненный саджест и перейти на него.
    // сначала ищем ближайший следующий. потом первый невыполненный
    let nextIndex = suggestsForCollect.value
      .slice(activeSuggestIndex.value + 1)
      .findIndex(s => s.status === SuggestStatusEnum.request || s.status === SuggestStatusEnum.blocked);
    if (nextIndex !== -1) {
      nextIndex += activeSuggestIndex.value + 1;
    } else {
      nextIndex = suggestsForCollect.value.findIndex(s => s.status === SuggestStatusEnum.request);
    }
    if (nextIndex === -1) return;

    updateActiveSuggestIndex(nextIndex);
  };

  const setCollected = (suggest: Suggest, barcode: string, next?: boolean, weight?: number) => {
    if (!suggest) return;
    const activeCollected = { ...(collected.value[suggest.suggest_id] ?? {}) };

    // Обновил кол-во
    if (!activeCollected.count) {
      activeCollected.count = 0;
    }
    activeCollected.count += 1;

    // Обновил баркоды
    if (!activeCollected.barcodes) {
      activeCollected.barcodes = [];
    }
    activeCollected.barcodes!.push(barcode);

    // Обновил вес
    if (weight) {
      if (!activeCollected.weight) {
        activeCollected.weight = 0;
      }
      activeCollected.weight += weight;
    }

    if (suggest.conditions.need_true_mark) {
      activeCollected.true_mark = barcode;
    }

    updateCollected(suggest.suggest_id, activeCollected);

    if (suggest.count === activeCollected.count) {
      return completeSuggest(suggest, next);
    }
    return true;
  };

  const setCollectedTrueWeight = (suggest: Suggest, barcode: string) => {
    const weight = Product.weightFromBarcode(barcode);
    const activeCollected = { ...(collected.value[suggest.suggest_id] ?? {}) };

    // Обновил кол-во
    if (!activeCollected.count) {
      activeCollected.count = 0;
    }
    activeCollected.count += weight;

    // Обновил баркоды
    if (!activeCollected.barcodes) {
      activeCollected.barcodes = [];
    }
    activeCollected.barcodes.push(barcode);
    updateCollected(suggest.suggest_id, activeCollected);
  };

  const completeSuggest = async (suggest?: Suggest, next = true): Promise<void> => {
    if (!suggest || !order.value) return;
    sendRequestBarcodeTime(suggest.suggest_id);
    const {
      weight, count, true_mark, barcodes,
    } = collected.value[suggest.suggest_id];

    if (suggest.type === SuggestTypeEnum.box2shelf) {
      const result = await useBox2Shelf(
        order_id.value,
        {
          suggest_id: suggest.suggest_id,
          weight,
          count,
          true_mark,
          barcodes: suggest.conditions.need_barcodes && barcodes ? prepareBarcodes(barcodes) : undefined,
        },
        {
          onRequestError: async (e, retry) => {
            const response = e.response;

            if (checkNeedTrueMarkModal(response?.data?.code)) {
              errorTrueMarkModal({
                code: response?.data?.code,
                status: response?.data?.true_mark_status,
              });
              clearCollected(suggest);
              return false;
            }

            const confirmed = await retryBox2ShelfModal(e);
            if (!confirmed) return false;
            return retry();
          },
          measureRequest: finishDoneRequest,
          measureEvent: finishEventWaiting,
        },
      );
      clearTrueMarkQueue(suggest);
      if (!result) {
        decreaseCollectedCount(suggest);
        return;
      }
    } else {
      const result = await useShelf2Box(
        order_id.value,
        {
          suggest_id: suggest.suggest_id,
          weight,
          count,
          true_mark,
          barcodes: suggest.conditions.need_barcodes && barcodes ? prepareBarcodes(barcodes) : undefined,
        },
        {
          onRequestError: async (e, retry) => {
            AudioService.playError();
            const response = e.response;
            if (checkNeedTrueMarkModal(response?.data?.code)) {
              errorTrueMarkModal({
                code: response?.data?.code,
                status: response?.data?.true_mark_status,
              });
              clearCollected(suggest);
              return false;
            }

            const confirmed = await retryShelf2BoxModal(e);
            if (!confirmed) return false;
            return retry();
          },
          measureRequest: finishDoneRequest,
          measureEvent: finishEventWaiting,
        },
      );
      clearTrueMarkQueue(suggest);
      if (!result) {
        decreaseCollectedCount(suggest);
        return;
      }
    }
    // все успешно. можем закрывать измерение по саджесту
    finishAssembling();
    clearTrueMarkQueue(suggest);
    if (next) selectNext();
  };

  const handleItem = async (suggest: Suggest, barcode: string): Promise<void> => {
    const item = suggest?.item || (await itemQueue.load(suggest.product_id));
    if (!item || !suggest) {
      // ОШИБКА!!!
      return;
    }
    if (item.checkBarcode(barcode)) {
      updateCollected(suggest.suggest_id, {
        ...(collected.value[suggest.suggest_id] ?? {}),
        count: 1,
        barcodes: [barcode],
      });
      await completeSuggest(suggest);
      //  Проверить собранное и добавить или закрыть саджест и отменить запрос баркода
    } else {
      Alerts.error($gettext('Отсканирован неверный штрихкод %{barcode}', { barcode }));
    }
    return;
  };
  // Блок в котором валидируем марку в апи
  // true - марка валидна, идем дальше, false - марка не валидна, прекращаем выполнение.
  const checkTrueMarkAtAPI = async (suggest: Suggest, true_mark: string): Promise<boolean> => {
    // проверяем только для саджестов с типом возьми, для обратных проверять не нужно - мы их уже проверили раньше при сборке
    if (suggest.type !== SuggestTypeEnum.shelf2box) return true;
    const { closeLoader } = showLoader();
    try {
      const response = await TrueMark.check({
        true_mark,
        order_id: suggest.order_id,
      });
      closeLoader();
      finishCheckTrueMark(response?.duration || 0);
      return true;
    } catch {
      closeLoader();
      return false;
    }
  };

  const handleProduct = async (suggest: Suggest, barcode: string, next?: boolean): Promise<void> => {
    const product = suggest?.product || (await productQueue.load(suggest.product_id));
    if (!product) {
      // ОШИБКА!!!
      return;
    }

    // ЧЗ
    if (suggest.conditions.need_true_mark) {
      const valid = await checkTrueMarkAtAPI(suggest, barcode);
      if (!valid) {
        clearTrueMarkQueue(suggest);
        return;
      }
      await setCollected(suggest, barcode, next);
      return;
    }

    // Настоящий весовой продукт
    if (product.isTrueWeight) {
      const weight = Product.weightFromBarcode(barcode);
      if (!weight) {
        Alerts.error($gettext('Отсканирован неверный штрихкод %{barcode}', { barcode }));
        return;
      }
      if (!suggest.max_count) {
        if (weight !== suggest.count) {
          Alerts.error($gettext('Отсканирован неверный штрихкод %{barcode}', { barcode }));
          return;
        }
        setCollectedTrueWeight(suggest, barcode);
        await completeSuggest(suggest, next);
        return;
      }
      const curCollectedCount = collected.value[suggest.suggest_id]?.count || 0;
      // Слишком много
      if (curCollectedCount + weight > suggest.max_count) {
        const delta = curCollectedCount + weight - suggest.max_count;
        await needLessWeightModal(delta.toString());
        return;
      }

      setCollectedTrueWeight(suggest, barcode);
      // Слишкм мало
      if (curCollectedCount + weight < suggest.min_count!) {
        return;
      }
      // в самый раз
      await completeSuggest(suggest, next);
      return;
    }

    // весовой продукт
    if (product.type_accounting === 'weight') {
      const weight = Product.weightFromBarcode(barcode);
      setCollected(suggest, barcode, next, weight);
      return;
    }

    // это простой штучный продукт
    await setCollected(suggest, barcode, next);
    return;
  };
  const barcodeHandler = async (barcode: string) => {
    if (!order.value) return false;
    if (!activeSuggest.value) return false;
    if (hasProblemOnActiveSuggest.value) return true;
    if (!needBarcodeRequest.value) return true;
    if (activeSuggest.value.status === SuggestStatusEnum.blocked) return true;
    if (order.value.isOrderPaused) {
      await Modal.show({
        title: $gettext('Обрабатывается оператором'),
        text: $gettext('Данный заказ обрабатывается оператором, пожалуйста подождите'),
      });
      return true;
    }
    notify.close(modalNotifyId);
    const suggest = getSuggestById(activeSuggest.value.suggest_id);
    if (!suggest) return;
    // посылка
    if (activeSuggest.value.vars?.mode === 'item') {
      await handleItem(suggest, barcode);
      return true;
    }
    // продукт
    startRequestBarcode(suggest.suggest_id);
    const valid = await checkBarcodeBySuggest(barcode, activeSuggest.value);
    finishRequestBarcode(suggest.suggest_id);
    if (!valid) return true;

    const needNext = TrueMarkSuggestWrapper.isTrueMarkSuggestWrapper(activeSuggest.value)
      ? activeSuggest.value.isLastRequestSuggest
      : true;

    setTrueMarkQueue(suggest);

    await handleProduct(suggest, barcode, needNext);
    return true;
  };

  const { needBarcodeRequest } = useRequestBarcode(barcodeHandler, { immediate: false });

  const stopBarcodeRequest = () => {
    needBarcodeRequest.value = false;
  };
  const startBarcodeRequest = () => {
    if (needBarcodeRequest.value === true) return;
    needBarcodeRequest.value = true;
  };

  // если текущий саджест пропал, то идем к ближайшему в статусе реквест
  watch(
    activeSuggest,
    (val) => {
      if (val?.status === SuggestStatusEnum.request) {
        startAssembling();
      }
    },
    { immediate: true },
  );

  watch(
    hasSuggestsToCollect,
    (val) => {
      if (val) {
        startBarcodeRequest();
      } else {
        stopBarcodeRequest();
      }
    },
    { immediate: true },
  );

  watch(activeSuggestIndex, () => {
    if (hasSuggestsToCollect.value) {
      startBarcodeRequest();
    }
  });

  return {
    selectNext,
    stopBarcodeRequest,
    startBarcodeRequest,
    clearActiveCollected: () => clearCollected(activeSuggest.value),
    completeActiveSuggest: () => completeSuggest(activeSuggest.value),
  };
};
