<template>
  <Layout data-test="order-finish find-rover">
    <div class="bg-day-cardDivider h-full flex flex-col gap-1.5">
      <Sector class="rounded-t-none">
        <Title2 class="font-bold block my-2.5">
          {{ $gettext('Найдите ровер на улице по номеру') }}
        </Title2>
        <Body2 class="block">
          {{ $gettext('Номер можно найти на передней части корпуса.') }}
        </Body2>
        <RoverSector
          :name="order?.courier?.name ?? ''"
          class="mt-4"
        />
      </Sector>
    </div>

    <template #footer>
      <LayoutFooter>
        <UiButton
          data-test="next-stage-btn"
          @click="nextStage"
        >
          {{ $gettext('Открыть Rover') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </Layout>
</template>

<script setup lang="ts">
import RoverSector from '@/fsd/entities/rover/RoverSector.vue';
import { useOpenRoverHatch } from '@/fsd/features/openRoverHatch/useOpenRoverHatch';
import Sector from '@/fsd/shared/ui/sector/sector.vue';
import ClientOrder from '@/models/orders/ClientOrder';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import Title2 from '@/ui/common/typo/title-2.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { defineEmits, defineProps } from 'vue';
import {
  OrderFinishingDeltaNamesEnum,
  OrderFinishingMarkNamesEnum,
  useRUMOrderFinishingStep,
} from '../tools/useMeasureOrderFinish';

const props = defineProps<{ order: ClientOrder }>();
const emit = defineEmits(['done']);

const { openRoverHatch } = useOpenRoverHatch();

const nextStage = async () => {
  await openRoverHatch(props.order);
  emit('done');
};

useRUMOrderFinishingStep({
  orderId: props.order.order_id,
  markName: OrderFinishingMarkNamesEnum.ROVER_FIND,
  deltaName: OrderFinishingDeltaNamesEnum.ROVER_FIND,
});
</script>
