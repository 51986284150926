<template>
  <div
    class="flex flex-col gap-4"
    data-test="product card item"
  >
    <InfoRow
      :title="$gettext('Полка')"
      :value="shelf?.title ?? ''"
      data-test="product card shelf row"
    />
    <InfoRow
      :title="$gettext('Резерв')"
      :value="reservedCount"
      data-test="product card reserved row"
    />
    <InfoRow
      v-if="valid"
      :title="$gettext('Годен до')"
      :value="valid"
      data-test="product card valid row"
    />
    <InfoRow
      v-if="valid"
      :title="$gettext('Дата производства')"
      :value="productionDate"
      data-test="product card production-date row"
    />
    <InfoRow
      :title="$gettext('Остаток')"
      :value="availableCount"
      bold
      data-test="product card count row"
    />
  </div>
</template>

<script lang="ts" setup>
import { getValueForView } from '@/fsd/entities/product';
import InfoRow from '@/fsd/widgets/productCard/InfoRow.vue';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { AvailableProduct } from '@/types/product';
import { getFormatDate } from '@/utils';
import { ValidTypeEnum } from '@/views/ProductCheck/types';
import { computed } from 'vue';

const props = defineProps<{ available: AvailableProduct }>();

const shelf = computed(() => {
  return useShelves().shelfById(props.available.shelf_id);
});
const product = computed(() => {
  return useProducts().productById(props.available.product_id);
});

const availableCount = computed(() => {
  if (!product.value || !shelf.value) return '-';
  return getValueForView({
    product: product.value,
    shelf: shelf.value,
    count: props.available.count,
  });
});

const reservedCount = computed(() => {
  if (!product.value || !shelf.value) return '-';
  return getValueForView({
    product: product.value,
    shelf: shelf.value,
    count: props.available.reserved,
  });
});
const valid = computed(() => {
  if (props.available.valid) {
    return getFormatDate(props.available.valid);
  }
  return '';
});
const productionDate = computed(() => {
  return getFormatDate(props.available.valid, {
    mode: ValidTypeEnum.productionDate,
    product: product.value,
  });
});
</script>
