import { getTrueMarkType } from '@/fsd/entities/true-mark/utils';
import { $gettext } from '@/temp/plugins/gettext';

export const checkTrueMarkErrorsCode = () => ({
  ER_INVALID_TRUE_MARK: $gettext('Для этого продукта необходимо отсканировать марку'),
  ER_TRUE_API_BROKEN: $gettext(
    'Невозможно получить статус марки из «%{markType}». Попробуйте отсканировать другую марку',
    { markType: getTrueMarkType() },
  ),
  ER_EXTERNAL_SERVICE: $gettext('Попробуйте отсканировать марку снова'),
  ER_BAD_TRUE_MARK: $gettext('Попробуйте отсканировать другую марку'),
  ER_RETIRED_TRUE_MARK_ON_REVIEW: $gettext(
    'Марка уже погашена и перемещена на полку “Разбор“. Попробуйте отсканировать другую марку',
  ),
  ER_RETIRED_TRUE_MARK_ON_STORE: $gettext('Марка уже погашена на вашем складе. Попробуйте отсканировать другую марку'),
  ER_RETIRED_TRUE_MARK_OTHER_STORE: $gettext(
    'Марка уже погашена на другом складе. Попробуйте отсканировать другую марку',
  ),
  ER_RETIRED_TRUE_MARK_OUTSIDE: $gettext('Марка уже погашена другим ЮР лицом. Попробуйте отсканировать другую марку'),
  ER_MARK_IS_SELLING_YET: $gettext(
    'Данная марка ещё не вернулась в оборот. Попробуйте отсканировать другую марку или отсканировать эту позднее',
  ),
  ER_TRUE_MARK_HAS_EXPIRED: $gettext('Товар просрочен, необходимо списать данный товар.'),
  ER_SUGGEST_INVALID_TRUE_MARK: $gettext('Невалидная марка «%{markType}»', { markType: getTrueMarkType() }),
  ER_RETIRED_TRUE_MARK_WAITING_FOR_SALE_CONFIRMATION: $gettext(
    'Этот товар недоступен для сборки заказа, его марка ещё не вернулась в оборот. Верните товар на полку и возьмите другой',
  ),
});
