import { mergeTrueMarkSuggests, unMergeTrueMarkSuggests } from '@/fsd/entities/suggest/tools/mergeTrueMarkSuggests';
import Suggest from '@/models/Suggest';
import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';
import { useOrders } from '@/store/modules/orders';

type ReturnSuggests = (Suggest | TrueMarkSuggestWrapper)[];

/**
 * Находит все дочерние саджесты
 * @param parent
 */
export const getChildSuggests = (parent: Suggest | TrueMarkSuggestWrapper): ReturnSuggests => {
  const result: Suggest[] = [];

  const order = useOrders().orderById(parent.order_id);

  if (!order) return result;

  const parentSuggestIds = TrueMarkSuggestWrapper.isTrueMarkSuggestWrapper(parent)
    ? parent.suggestsIds
    : [parent.suggest_id];

  order.signals.forEach((signal) => {
    if (parentSuggestIds.includes(signal.data.parent_suggest_id)) {
      unMergeTrueMarkSuggests(order.suggests).forEach((suggest) => {
        if (signal.sigid === suggest.vars.sigid) {
          result.push(suggest);
        }
      });
    }
  });

  return mergeTrueMarkSuggests(result);
};

/**
 * Находит все дочерние саджесты c result_count отличным от нуля
 * @param parent
 */
export const getFullChildSuggests = (parent: Suggest | TrueMarkSuggestWrapper): ReturnSuggests => {
  return getChildSuggests(parent).filter(s => !!s.result_count);
};

/**
 * Находит все дочерние саджесты в статусе request
 * @param parent
 */
export const getNonClosedChildSuggests = (parent: Suggest | TrueMarkSuggestWrapper): ReturnSuggests => {
  return getChildSuggests(parent).filter(s => !s.isDone);
};
