<template>
  <div class="relative w-screen h-screen">
    <div class="opacity-60 absolute w-full h-full bg-background-main" />
    <div class="absolute bg-background-main bottom-0 w-full rounded-t-2xl">
      <div class="absolute w-full justify-center flex -mt-6 round">
        <img
          class="w-10 h-10 p-1 bg-background-main rounded-xl"
          src="@/assets/img/warning.svg"
          alt="Error Icon"
        >
      </div>
      <div class="p-2">
        <div class="text-xl mt-2 font-bold text-primary rtl">
          {{ title }}
        </div>
        <div class="max-h-64 overflow-auto">
          <ErrorRow
            v-for="(detail, index) in details"
            :key="index"
            class="py-1"
            :title="detail.title"
            :label="detail.label"
          />
        </div>
        <div class="flex mt-2">
          <UiButton
            background-color="secondary"
            data-test="major-error close-btn"
            @click="$emit('close')"
          >
            {{ $gettext('Назад') }}
          </UiButton>
          <UiButton
            :timeout="5"
            class="ml-2"
            data-test="major-error repeat-btn"
            @click="$emit('repeat')"
          >
            {{ $gettext('Повторить') }}
          </UiButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ErrorRow from '@/temp/ui/notifications/major-error/error-row/error-row.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { defineComponent, PropType } from 'vue';
import IErrorRow from './error-row/error-row.vue';

export default defineComponent({
  name: 'MajorError',
  components: {
    UiButton,
    ErrorRow,
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    details: {
      required: true,
      type: Array as PropType<typeof IErrorRow[]>,
    },
  },
  emits: ['close', 'repeat'],
  watch: {
    $route(to) {
      if (to.name === 'login') {
        this.$emit('close');
      }
    },
  },
});
</script>
