<template>
  <LayoutFooter class="flex-col">
    <UiButton
      v-if="step === FinishStepEnum.empty"
      class="mt-2"
      data-test="order_retail finish-support"
      background-color="secondary"
      @click="openSupport"
    >
      {{ $gettext('Обратиться в поддержку') }}
    </UiButton>
    <UiButton
      v-if="step === FinishStepEnum.finish"
      class="mt-2"
      data-test="order_retail finish-order"
      @click="$emit('finishOrder')"
    >
      {{ $gettext('Завершить заказ') }}
    </UiButton>
    <UiButton
      v-if="step === FinishStepEnum.qrcode"
      class="mt-2"
      data-test="order_retail to-finish-order"
      @click="$emit('next')"
    >
      {{ $gettext('Далее') }}
    </UiButton>
  </LayoutFooter>
</template>

<script setup lang="ts">
import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import { useUser } from '@/store/modules/user';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { FinishStepEnum } from '@/views/OrderRetail/types';
import { useRouter } from 'vue-router';

interface FooterFinishProps {
  order: OrderOrderRetail;
  step: FinishStepEnum;
}

const userStore = useUser();
const router = useRouter();

const props = defineProps<FooterFinishProps>();

defineEmits<{
  (e: 'finishOrder');
  (e: 'next');
}>();

const openSupport = () => {
  userStore.setChatMetaData({
    order_id: props.order.order_id,
    doc_number: props.order.external_id,
  });
  router.push({ name: 'support' });
};
</script>
