import { useDevtoolsStore } from '@/devtools/store/devtoolsModule';
import { PlatformService } from '@/fsd/data/services/platform.service';
import { GS } from '@/fsd/shared/tools/unpackTrueMark';
import { ModeService } from '@/services/mode.service';
import { CameraScanner } from '@/services/scanner/handlers/CameraScanner';
import { DialogScanner } from '@/services/scanner/handlers/DialogScanner';
import { HardwareScanner } from '@/services/scanner/handlers/HardwareScanner';
import { SoftScanner } from '@/services/scanner/handlers/SoftScanner';
import { NativeScanner } from '@/services/scanner/native-scanner';
import { useUser } from '@/store/modules/user';
import { v1 as uuidv1 } from 'uuid';
import { CameraProScanner } from './handlers/CameraProScanner';

class ScannerServiceClass {
  mode = '';
  requestFrom: string | undefined = undefined;
  reject?: (arg: any) => any = undefined;
  scanner: any;

  constructor(mode) {
    // почему то здесь нет логгера
    console.log('Запуск сканеров', mode);
    this.init(mode);
  }

  init(mode) {
    // На сиайке не включается кордова, поэтому мы и не слушаем ивент
    const uri = window.location.search.substring(1);
    const params = new URLSearchParams(uri);

    if (params.get('pro') === 'true') {
      setTimeout(async () => {
        useUser().setDevice(uuidv1());
        await this.setMode('pro');
      }, 0);
      return;
    } else if (params.get('mode') === 'dialog') {
      setTimeout(async () => {
        useUser().setDevice(uuidv1());
        await this.setMode('dialog');
      }, 2000);
      return;
    }

    if (mode === 'dialog') {
      this.setMode('dialog');
    } else {
      document.addEventListener('deviceready', async () => {
        // при запуске в дев режиме ивент не будет вызван никогда
        // Из-за ивента, мы не можем перенести это в логин
        console.log('Устройство готово');
        // в браузере device_id никогда не будет получен, промис от  getDeviceId не будет завершен.
        // даем кордове на ответ 2 секунды, чтобы с запасом, проставляем режим дмалог и генерируем device_id
        let timer: number | undefined = setTimeout(async () => {
          localStorage.setItem('isWeb', 'true');
          await useUser().setDevice(uuidv1());
          await this.setMode('dialog');
          timer = undefined;
        }, 2000);
        const { device_id } = await NativeScanner.getDeviceId();
        // соломка на случай если промис все таки зарезолвится, но мы проставили режим, как диалог
        if (!timer) return;
        // если ответ был получен, то значит, что таймаут нам больше не нужен и приложение запущено на тсд
        clearTimeout(timer);
        await useUser().setDevice(device_id);
        await this.setMode(mode);
      });
    }
  }

  clearPromise(requestFrom?: string) {
    if (requestFrom && requestFrom !== this.requestFrom) return;
    if (this.reject) {
      this.reject('Scanner rejected');
      // чистим поля, чтобы не вызывать лищний раз очистку
      this.requestFrom = undefined;
      this.reject = undefined;
    }
  }

  requestCode(requestFrom?: string): Promise<string> {
    console.log('Отдали промис с баркодом -', requestFrom);
    this.clearPromise();
    this.requestFrom = requestFrom;
    return new Promise((resolve, reject) => {
      this.reject = reject;
      const purifyBarcode = (data) => {
        // От кордовы прилетает объект, от других методов строка
        const barcodeRaw = typeof data === 'string' ? data : data.barcode;

        // Некоторые сканнеры не умеют сканнировать GS (Group Separator, 29-й символ ASCII)
        // Поэтому эти сканнеры перепрошиваются, чтобы вместо GS они печатали решетку
        // Потому что решетка точно НЕ используется в баркодах
        // https://st.yandex-team.ru/LAVKADEV-8705
        // * GS символ так же следует удалять, если он стоит вначале строки
        // * некоторые камеры могут подставлять его вначале ШК
        // * https://st.yandex-team.ru/LAVKADEV-14985
        const barcodeWithGS = barcodeRaw.replace(/#/g, GS);

        // * Если GS стоит вначале, то его нужно обрезать
        if (barcodeWithGS.startsWith(GS)) {
          return barcodeWithGS.replace(GS, '');
        }

        return barcodeWithGS;
      };

      // Ждём, пока у нас будет обработчик
      const tryScan = () => {
        if (this.scanner) {
          this.scanner
            .scan()
            .then((data) => {
              const barcode = purifyBarcode(data);
              console.log(`Отсканирован ${barcode}`, requestFrom);
              useDevtoolsStore().pushBarcode(barcode);
              resolve(barcode);
            })
            .catch((e) => {
              // иногда мы можем попробовать вызвать сканер когда он уже не доступен
              // (например мы пробуем запуститься со встроенным сканером ШК, но его нет и мы хотим переключиться на камеру)
              // в этом случае мы получим ошибку "Not started yet" и умрем. это способ реанимировать сканер
              console.error(e);
              setTimeout(tryScan, 100);
            });
          clearInterval(interval);
        }
      };
      const interval = setInterval(tryScan, 100);
      tryScan();
    });
  }

  /**
   * Установка режима сканера
   * @param {'camera'|'hard'|'soft'|'dialog'|'pro'} mode
   * @return {Promise<void>}
   */
  async setMode(mode) {
    console.log(mode);
    if (this.scanner) {
      await this.scanner.destroy();
    }
    switch (mode) {
      case 'hard':
        this.scanner = new HardwareScanner();
        break;
      case 'camera':
        this.scanner = new CameraScanner();
        break;
      case 'soft':
        this.scanner = new SoftScanner();
        break;
      case 'dialog':
        this.scanner = new DialogScanner();
        break;
      case 'pro':
        this.scanner = new CameraProScanner();
        break;
    }
    this.mode = mode;
    this.scanner.init().catch((err) => {
      switch (err.message) {
        case 'Нет встроенного сканера':
          if (PlatformService.isCapacitor()) {
            // Если это капаситор, то пробуем взаимодействовать с камерой
            this.setMode('camera');
          } else {
            this.setMode('soft');
          }
          break;
        // На будущее, когда будут приколы с камерой и прочим
      }
    });
  }
}

// @ts-expect-error
window.scaner = NativeScanner;

const ScannerService = new ScannerServiceClass(ModeService.isDevelopment() ? 'dialog' : 'hard');
// @ts-expect-error
window.scannerService = ScannerService;
export { ScannerService };
