<template>
  <Layout>
    <template #header>
      <Bar
        :caption="$gettext('Отсканируйте QR склада')"
        :menu-config="[]"
        @close-click="backToLogin"
      />
    </template>

    <div class="px-4 leading-tight">
      {{
        $gettext(
          'Попросите директора показать вам QR-код склада. Его можно найти в разделе “Мои сотрудники“ -> “…“ -> “QR для привязки сборщика к складу“',
        )
      }}
    </div>

    <IconQr class="w-4/5 h-4/5 mx-auto" />
  </Layout>
</template>

<script lang="ts" setup>
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import { useUser } from '@/store/modules/user';
import IconQr from '@/temp/icons/icon-qr.vue';
import { $gettext } from '@/temp/plugins/gettext';
import { QrActions } from '@/temp/services/qr-actions';
import Bar from '@/ui/common/bar/bar.vue';
import Layout from '@/ui/common/layout.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import { useRouter } from 'vue-router';

const { showLoader } = useLoader();
const userStore = useUser();
const router = useRouter();

useRequestBarcode(async (barcode) => {
  const { closeLoader } = showLoader();

  if (!QrActions.isAction(barcode)) {
    Alerts.error($gettext('Нужно было отсканировать QR склада'));
    closeLoader();
    return;
  }

  try {
    const { store_id } = await QrActions.storeCheckin(barcode);
    userStore.setStore(store_id);
    await userStore.loadOptions();
    await router.push({ name: 'home' });
  } catch {
    Alerts.error($gettext('Произошла ошибка при привязке к складу'));
  } finally {
    closeLoader();
  }
});

function backToLogin() {
  userStore.logout();
}
</script>
