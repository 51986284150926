import BaseOrder, { OrderBaseVars } from '@/models/orders/BaseOrder';
import { useOrders } from '@/store/modules/orders';
import { useUser } from '@/store/modules/user';
import { AxiosResponse } from 'axios';

/**
 * Моделька, объединяющая в себя все клиентские заказы
 * Необходима для вынесения в себя общих полей в моделях OrderOrder и OrderRetail
 * Задача модели - уменьшить копипасту
 */

export interface ClientOrderVars extends OrderBaseVars {
  fulfilled_conditions?: {
    confirm_assembled_products?: boolean;
  };
  confirming_assembled_products?: boolean;
  total_order_weight?: number;
}

export const isClientOrder = (o: BaseOrder): o is ClientOrder => {
  return ['order', 'order_retail'].includes(o.type);
};
export default class ClientOrder extends BaseOrder {
  public vars: ClientOrderVars = {};

  constructor(data: any) {
    super(data);
    this.vars = data.vars;
  }

  get packingSuggests() {
    return this.suggests.filter(s => s.isPackaging);
  }

  closePackingSuggests(collected) {
    const requestSuggests = this.packingSuggests.filter(suggest => suggest.status !== 'done');
    if (requestSuggests.length === 0) {
      return;
    }

    const suggests = requestSuggests.map((s) => {
      return {
        ...s,
        count: collected?.[s.suggest_id] || 0,
      };
    });

    const promises: Promise<AxiosResponse>[] = [];

    for (const s of suggests) {
      const payload = {
        suggest_id: s.suggest_id,
        count: s.count,
      };
      const promise = useOrders().shelf2box(payload);
      promises.push(promise);
    }

    return Promise.allSettled<Promise<AxiosResponse>[]>(promises);
  }

  get isHeavyOrder() {
    if (!this.vars?.total_order_weight) {
      return false;
    }
    return this.vars.total_order_weight > useUser().weightLimit;
  }
}
