enum PlatformEnum {
  web = 'web',
  cordova = 'cordova',
  capacitor = 'capacitor',
  pro = 'pro',
}

export const PlatformService = {
  getMode() {
    if (localStorage.getItem('isPro')) {
      return PlatformEnum.pro;
    }
    if (localStorage.getItem('isCapacitor')) {
      return PlatformEnum.capacitor;
    }
    if (localStorage.getItem('isWeb')) {
      return PlatformEnum.web;
    }

    return PlatformEnum.cordova;
  },
  isWeb() {
    return this.getMode() === PlatformEnum.web;
  },
  isCordova() {
    return this.getMode() === PlatformEnum.cordova;
  },
  isCapacitor() {
    return this.getMode() === PlatformEnum.capacitor;
  },
  isPro() {
    return this.getMode() === PlatformEnum.pro;
  },
};
