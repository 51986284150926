<template>
  <div>
    <div class="title-container">
      <Typography
        type="text3"
        color="secondary-text"
        is-bold
      >
        {{ $gettext('Диагностика') }}
      </Typography>
    </div>
    <PingChart
      :chart-data="uiState.pickerPing"
      name="Picker"
    />
    <PingChart
      :chart-data="uiState.apiPing"
      name="API"
    />
    <PingChart
      :chart-data="uiState.eventPing"
      name="Event"
    />
  </div>
</template>

<script lang="ts">
import { api } from '@/fsd/data/api/api.service';
import { ModeService } from '@/services/mode.service';
import Typography from '@/ui/common/typography.vue';
import PingChart from '@/ui/home/settings/options/ping-chart.vue';
import { defineComponent } from 'vue';

interface ChartDataItem {
  duration: number;
  success: boolean;
  hasResponse?: boolean;
}
interface Data {
  uiState: {
    apiPing: ChartDataItem[];
    eventPing: ChartDataItem[];
    pickerPing: ChartDataItem[];
  };
  interval?: number;
}

export default defineComponent({
  components: {
    Typography,
    PingChart,
  },
  props: {},
  data(): Data {
    return {
      uiState: {
        apiPing: [],
        eventPing: [],
        pickerPing: [],
      },
      interval: undefined,
    };
  },
  mounted() {
    this.interval = setInterval(this.startPing, 1000);
    this.startPing();
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    async startPing() {
      await Promise.allSettled([this.pingApi(), this.pingEvent(), this.pingPicker()]);
    },
    async pingApi() {
      try {
        const resp = await api.ping.api();
        this.uiState.apiPing.push({
          duration: (resp as any).duration,
          success: true,
        });
      } catch (e: any) {
        this.uiState.apiPing.push({
          duration: e.duration!,
          success: false,
          hasResponse: !!e.response,
        });
      }
    },
    async pingEvent() {
      try {
        const resp = await api.ping.event();
        this.uiState.eventPing.push({
          duration: (resp as any).duration,
          success: true,
        });
      } catch (e: any) {
        this.uiState.eventPing.push({
          duration: e.duration!,
          success: false,
          hasResponse: !!e.response,
        });
      }
    },
    async pingPicker() {
      try {
        if (ModeService.isProduction()) {
          const resp = await api.ping.polka();
          this.uiState.pickerPing.push({
            duration: (resp as any).duration,
            success: true,
          });
        } else {
          // локально нет доступа к этой ручке. глушу ошибки в консоли
          this.uiState.pickerPing.push({
            duration: 42,
            success: true,
          });
        }
      } catch (e: any) {
        this.uiState.pickerPing.push({
          duration: e.duration!,
          success: false,
          hasResponse: !!e.response,
        });
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.title-container {
  background: var(--element-bg);
  padding: 4px 16px;
}
.settings-row {
  display: flex;
  justify-content: space-between;
  padding: 20px 16px;
}
.value-container {
  display: flex;
}
.value {
  font-size: 16px;
  color: #9e9b98;
  margin-right: 10px;
}
</style>
