<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.25 4.5H8.25C8.66421 4.5 9 4.83579 9 5.25V8.25C9 8.66421 8.66421 9 8.25 9H5.25C4.83579 9 4.5 8.66421 4.5 8.25V5.25C4.5 4.83579 4.83579 4.5 5.25 4.5ZM2.25 5.25C2.25 3.59315 3.59315 2.25 5.25 2.25H8.25C9.90685 2.25 11.25 3.59315 11.25 5.25V8.25C11.25 9.90685 9.90685 11.25 8.25 11.25H5.25C3.59315 11.25 2.25 9.90685 2.25 8.25V5.25ZM15.75 4.5H18.75C19.1642 4.5 19.5 4.83579 19.5 5.25V8.25C19.5 8.66421 19.1642 9 18.75 9H15.75C15.3358 9 15 8.66421 15 8.25V5.25C15 4.83579 15.3358 4.5 15.75 4.5ZM12.75 5.25C12.75 3.59315 14.0931 2.25 15.75 2.25H18.75C20.4069 2.25 21.75 3.59315 21.75 5.25V8.25C21.75 9.90685 20.4069 11.25 18.75 11.25H15.75C14.0931 11.25 12.75 9.90685 12.75 8.25V5.25ZM8.25 15H5.25C4.83579 15 4.5 15.3358 4.5 15.75V18.75C4.5 19.1642 4.83579 19.5 5.25 19.5H8.25C8.66421 19.5 9 19.1642 9 18.75V15.75C9 15.3358 8.66421 15 8.25 15ZM5.25 12.75C3.59315 12.75 2.25 14.0931 2.25 15.75V18.75C2.25 20.4069 3.59315 21.75 5.25 21.75H8.25C9.90685 21.75 11.25 20.4069 11.25 18.75V15.75C11.25 14.0931 9.90685 12.75 8.25 12.75H5.25ZM15.75 15H18.75C19.1642 15 19.5 15.3358 19.5 15.75V18.75C19.5 19.1642 19.1642 19.5 18.75 19.5H15.75C15.3358 19.5 15 19.1642 15 18.75V15.75C15 15.3358 15.3358 15 15.75 15ZM12.75 15.75C12.75 14.0931 14.0931 12.75 15.75 12.75H18.75C20.4069 12.75 21.75 14.0931 21.75 15.75V18.75C21.75 20.4069 20.4069 21.75 18.75 21.75H15.75C14.0931 21.75 12.75 20.4069 12.75 18.75V15.75Z"
      fill="#1C1D20"
    />
  </svg>
</template>
