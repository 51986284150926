<template>
  <Layout>
    <template #header>
      <ChangeProductHeader
        :order="order"
        @back="closeChange"
      />
    </template>
    <template #default>
      <div class="h-full bg-element flex flex-col gap-2">
        <div class="bg-background-main rounded-b-3xl p-4">
          <Title2 class="font-bold px-1">
            {{ $gettext('Добавление замены') }}
          </Title2>
          <SuggestCard
            class="mt-2"
            :collected="suggest.result_count || collected || 0"
            :suggest="suggest"
            :need-buttons="false"
            @menu="$emit('openSuggestMenu', suggest)"
            @remove="$emit('removeSuggest', suggest)"
          />
        </div>
        <div
          v-if="childrenSuggest.length"
          class="bg-background-main rounded-t-3xl p-4 h-full"
        >
          <Title4 class="font-bold px-1 mt-2">
            {{ $gettext('Заменить на') }}
          </Title4>
          <SuggestCardLite
            v-for="child in childrenSuggest"
            :key="child.suggest_id"
            class="my-3"
            :collected="child.result_count || 0"
            :suggest="child"
            @remove="() => removeChild(child)"
          />
        </div>
        <EmptyScreen
          v-else
          class="bg-background-main rounded-t-3xl"
          :text="$gettext('Отсканируйте товары на замену')"
        />
      </div>
    </template>
    <template #footer>
      <ChangeProductFooter
        :suggest="suggest"
        :children-suggest="childrenSuggest"
        :order="order"
        :collected="collected"
        @done="$emit('changeDone', suggest)"
      />
    </template>
  </Layout>
</template>

<script setup lang="ts">
import { checkNeedTrueMarkModal, errorTrueMarkModal } from '@/fsd/entities/modals/errorTrueMarkModal';
import { unMergeTrueMarkSuggests } from '@/fsd/entities/suggest/tools/mergeTrueMarkSuggests';
import { prepareBarcodes } from '@/fsd/entities/suggest/tools/useShelf2Box';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { callAction } from '@/fsd/shared/ui/action';
import { EmptyScreen } from '@/fsd/shared/ui/emptyScreen';
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Product from '@/models/Product';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';
import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import { SignalEnum } from '@/services/requests';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { $gettext } from '@/temp/plugins/gettext';
import Layout from '@/ui/common/layout.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import Title2 from '@/ui/common/typo/title-2.vue';
import { checkTrueMark } from '@/views/OrderRetail/tools/checkTrueMark';
import { isChildSuggest } from '@/views/OrderRetail/tools/getParentSuggest';
import { Shelf2BoxRetail } from '@/views/OrderRetail/types';
import { computed, onMounted, ref, watch } from 'vue';
import RescanSuggestModal from '../../modals/RescanSuggestModal.vue';
import { getChildSuggests, getFullChildSuggests, getNonClosedChildSuggests } from '../../tools/getChildSuggests';
import { resetSuggests } from '../../tools/resetSuggest';
import SuggestCard from '../SuggestCard/SuggestCard.vue';
import SuggestCardLite from '../SuggestCard/SuggestCardLite.vue';
import ChangeProductFooter from './ChangeProductFooter.vue';
import ChangeProductHeader from './ChangeProductHeader.vue';
import ManufactureDateWindow from '../ManufactureDateWindow/ManufactureDateWindow.vue';

const productsStore = useProducts();
const ordersStore = useOrders();
const { showLoader } = useLoader();

interface ChangeProductProps {
  suggest_id: Suggest['suggest_id'];
  order: OrderOrderRetail;
  collected: number;
}

const props = defineProps<ChangeProductProps>();

const emits = defineEmits<{
  (e: 'close'): void;
  (e: 'openSuggestMenu', suggest: Suggest): void;
  (e: 'removeSuggest', suggest: Suggest): void;
  (e: 'removeSuggestsList', suggests: Suggest[]): void;
  (e: 'suggestDone', data: Shelf2BoxRetail): void;
  (e: 'changeDone', suggest: Suggest | TrueMarkSuggestWrapper);
}>();

const childrenCollected = ref<Record<string, string[]>>({});

const expAlbertHofmann = computed(() => useUser().experimentByName(experiments.exp_albert_hofmann));

/**
 * Саджест на замену
 */
const suggest = computed(
  () => props.order.suggests.find(s => s.suggest_id === props.suggest_id) as Suggest | TrueMarkSuggestWrapper,
);

/**
 * Список всех дочерних саджестов
 */
const allChildrenSuggest = computed(() => {
  return getChildSuggests(suggest.value);
});

/**
 * Список дочерних саджестов, у которых result_count > 0
 */
const childrenSuggest = computed(() => {
  return getFullChildSuggests(suggest.value);
});

/**
 * Список незакрытых дочерних саджестов
 */
const nonClosedChildSuggest = computed(() => {
  return getNonClosedChildSuggests(suggest.value);
});

/**
 * Cписок дочерних саджестов на замену, присутсвующих до открытия окна.
 */
const savedChildrenSuggests = ref<Record<Suggest['suggest_id'], Suggest>>(
  childrenSuggest.value.reduce((acc, cur) => {
    acc[cur.suggest_id] = cur;
    return acc;
  }, {}),
);

/**
 * True, если есть новые дочерние саджесты на замену
 */
const hasNewChildren = computed(() => {
  return childrenSuggest.value.some((suggest) => {
    const savedSuggest = savedChildrenSuggests.value[suggest.suggest_id];
    return !savedSuggest || savedSuggest.result_count !== suggest.result_count;
  });
});

const finishChildSuggest = async (product_id: Product['product_id']): Promise<void> => {
  const childSuggest = allChildrenSuggest.value.find((child) => {
    if (child.product_id === product_id) return child;
  });

  if (childSuggest && childrenCollected.value[product_id]) {
    emits('suggestDone', {
      suggest: childSuggest,
      barcodes: childrenCollected.value[product_id],
    });
  }
};

/**
 * Добавляет баркод продукта в список отсканированных
 * @param product_id
 * @param barcode
 */
const updateChildrenCollected = (product_id: Product['product_id'], barcode: string) => {
  if (childrenCollected.value[product_id]) {
    childrenCollected.value[product_id].push(barcode);
  } else {
    childrenCollected.value[product_id] = [barcode];
  }
};

onMounted(() => {
  childrenSuggest.value.forEach((suggest) => {
    suggest.barcodes.forEach((barcode) => {
      updateChildrenCollected(suggest.product_id, barcode);
    });
  });

  if (nonClosedChildSuggest.value.length) {
    reScanNonClosedChild();
  }
});

/**
 * Возвращает true, если в заказе уже есть переданный товар
 * @param product_id
 */
const hasProductInOrder = async (product_id: Product['product_id']): Promise<boolean> => {
  const suggest = props.order.suggests.find(s => s.product_id === product_id && !s.vars.more_product);

  if (suggest && !isChildSuggest(suggest)) {
    await Modal.show({
      title: $gettext('Данный продукт уже существует в заказе'),
      text: $gettext('Чтобы его добавить, откройте карточку этого товара в списке и просканируйте товар'),
    });
    return true;
  }

  return false;
};

/**
 * Возвращает true, если существует дочерний саджест с переданным продуктом
 * @param product_id
 */
const hasProductInChildren = (product_id: Product['product_id']): boolean => {
  return allChildrenSuggest.value.some(s => s.product_id === product_id);
};

const hasProductInEmptyMarkChildren = (product_id: Product['product_id']): Suggest | undefined => {
  return unMergeTrueMarkSuggests(allChildrenSuggest.value).find(s => s.product_id === product_id && !s.result_count);
};

const checkNeedTrueMark = (product: Product): boolean => product.true_mark && expAlbertHofmann.value;

const addProduct = async (product: Product, mark?: string): Promise<void> => {
  let valid;

  if (product.mercury) {
    valid = await callAction(ManufactureDateWindow, {
      product: product,
    });

    if (!valid) return;
  }

  const { closeLoader } = showLoader();

  const parentSuggestId = TrueMarkSuggestWrapper.isTrueMarkSuggestWrapper(suggest.value)
    ? suggest.value.suggests[0].suggest_id
    : suggest.value.suggest_id;

  try {
    const barcodes = childrenCollected.value[product.product_id] || [];
    await ordersStore.signal({
      order_id: props.order.order_id,
      signal: SignalEnum.more_product,
      data: {
        product_id: product.product_id,
        count: product.isTrueWeight ? Product.weightFromBarcode(barcodes) : 1,
        parent_suggest_id: parentSuggestId,
        barcodes: !checkNeedTrueMark(product) ? prepareBarcodes(barcodes) : undefined,
        true_mark: checkNeedTrueMark(product) ? mark : undefined,
        status: SuggestStatusEnum.done,
        valid,
      },
    });
    await new Promise<void>((resolve) => {
      const unWatch = watch(allChildrenSuggest, (suggests) => {
        for (const s of suggests) {
          if (s.status === SuggestStatusEnum.done && s.product_id === product.product_id) {
            resolve();
            unWatch();
            return;
          }
        }
      });
    });
  } catch (e: any) {
    const response = e.response;

    if (checkNeedTrueMarkModal(response?.data?.code)) {
      errorTrueMarkModal({
        code: response?.data?.code,
        status: response?.data?.true_mark_status,
      });
    }
  } finally {
    closeLoader();
  }
};

useRequestBarcode(async (barcode) => {
  const { closeLoader } = showLoader();
  try {
    const product = await productsStore.getProductByBarcode(barcode);
    if (checkNeedTrueMark(product)) {
      const isSuccess = checkTrueMark(barcode, props.order.order_id);
      if (!isSuccess) return true;
    }

    if (hasProductInChildren(product.product_id) && !checkNeedTrueMark(product)) {
      updateChildrenCollected(product.product_id, barcode);
      await finishChildSuggest(product.product_id);
      return true;
    }

    const findSuggest = hasProductInEmptyMarkChildren(product.product_id);

    if (findSuggest && findSuggest.conditions.need_true_mark) {
      emits('suggestDone', {
        suggest: findSuggest,
        true_mark: barcode,
      });
      return true;
    }

    closeLoader();

    const hasProduct = await hasProductInOrder(product.product_id);

    if (!hasProduct) {
      updateChildrenCollected(product.product_id, barcode);
      await addProduct(product, barcode);
    }

    return true;
  } catch {
    Alerts.error($gettext('Отсканирован неверный баркод'));
    return true;
  } finally {
    closeLoader();
  }
});

/**
 * Закрывает окно подбора замен
 */
const closeChange = async () => {
  if (hasNewChildren.value) {
    const confirm = await Modal.show({
      title: $gettext('Отмена замен'),
      text: $gettext('Все текущие замены будут удалены'),
      btnPosition: ButtonPositionsEnum.horizontal,
      confirmBtnTitle: $gettext('Ок'),
      closeBtnTitle: $gettext('Отменить'),
    });

    if (confirm) {
      emits('removeSuggestsList', unMergeTrueMarkSuggests(childrenSuggest.value));
      emits('close');
    }
  } else {
    emits('close');
  }
};

/**
 * Удаляет дочерний саджест из списка замен
 * @param suggest
 */
const removeChild = (suggest: Suggest) => {
  childrenCollected.value[suggest.product_id] = [];
  emits('removeSuggest', suggest);
};

const reScanNonClosedChild = async (): Promise<void> => {
  await callAction(RescanSuggestModal, { suggest: suggest.value });

  if (nonClosedChildSuggest.value.length) {
    await resetSuggests(props.order, nonClosedChildSuggest.value);
    const { closeLoader } = showLoader();
    await new Promise<void>((resolve) => {
      const unWatch = watch(nonClosedChildSuggest, (suggests) => {
        if (!suggests.length) {
          resolve();
          unWatch();
          return;
        }
      });
    });
    closeLoader();
  }
};
</script>
