<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.8569 5.46108C18.7108 4.18052 17.6945 3.16421 16.4139 3.01807L15.2149 4.21711C16.2894 4.71714 17.1579 5.58559 17.6579 6.66013L18.8569 5.46108ZM7.6107 11.8213L13.4139 6.01806C14.6945 6.16421 15.7108 7.18052 15.8569 8.46108L10.0537 14.2643C10.0123 14.3057 9.96933 14.3454 9.92502 14.3833C9.61484 14.6486 9.23738 14.8252 8.83263 14.8927L6.61224 15.2628L6.98231 13.0424C7.0594 12.5798 7.2791 12.1529 7.6107 11.8213ZM15.0791 1.17091L12.0791 4.17091L6.01971 10.2303C5.35652 10.8935 4.91711 11.7473 4.76292 12.6725L4.22024 15.9286C4.05103 16.9438 4.93119 17.824 5.94643 17.6548L9.20253 17.1121C10.1277 16.9579 10.9815 16.5185 11.6447 15.8553L17.7041 9.79591L20.7041 6.79591C20.9736 6.5264 21.125 6.16088 21.125 5.77974C21.125 3.00189 18.8731 0.75 16.0952 0.75C15.7141 0.75 15.3486 0.901405 15.0791 1.17091ZM4.625 19.5C4.00368 19.5 3.5 20.0037 3.5 20.625C3.5 21.2463 4.00368 21.75 4.625 21.75H20.375C20.9963 21.75 21.5 21.2463 21.5 20.625C21.5 20.0037 20.9963 19.5 20.375 19.5H4.625Z"
      :fill="colorValue"
    />
  </svg>
</template>
<script setup lang="ts">
import { useColor } from '@/fsd/shared/hooks';

const { color } = defineProps<{ color?: string }>();
const { colorValue } = useColor({ color: () => color });
</script>
