<template>
  <Row
    v-if="needShow"
    :label="label"
    :value="value"
    data-test="suggest card available row"
  />
</template>

<script lang="ts">
// поле для отображения количества остатков для продукта на полке.
import { getValueForView } from '@/fsd/entities/product';
import { AvailableItem } from '@/models/Item';
import Product from '@/models/Product';
import Shelf from '@/models/Shelf';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { getCountTitle } from '@/temp/constants/translations/countTitles';
import { DocTypes } from '@/temp/constants/translations/types';
import { AvailableProduct } from '@/types/product';
import Row from '@/ui/common/suggest-card/row.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    Row,
  },
  props: {
    docType: {
      type: String as PropType<DocTypes>,
      required: true,
    },
    availableProduct: {
      type: Object as PropType<AvailableProduct | AvailableItem>,
      default: undefined,
    },
  },
  setup() {
    const shelvesStore = useShelves();
    const productsStore = useProducts();

    return {
      shelvesStore,
      productsStore,
    };
  },
  computed: {
    shelf(): Shelf {
      return this.shelvesStore.shelfById(this.availableProduct?.shelf_id)!;
    },
    product(): Product {
      return this.productsStore.productById(this.availableProduct?.product_id)!;
    },
    componentCount(): number | string {
      if (this.availableProduct) {
        if (this.availableProduct.is_components) {
          return (this.availableProduct.count / this.availableProduct.quants).toFixed(2);
        }
        return this.availableProduct.count;
      }
      return 0;
    },
    needShow(): boolean {
      // Если это продукт - то показываем
      return Boolean(this.availableProduct?.count && this.product);
    },
    label(): string {
      return getCountTitle({ docType: this.docType });
    },
    value(): string {
      let count = this.availableProduct?.count || 0;

      return getValueForView({
        product: this.product,
        shelf: this.shelf,
        count,
      });
    },
  },
});
</script>
