<template>
  <LayoutFooter class="flex-col">
    <OrderTotalPrice
      :total-price="order.totalPrice + collectedPrice"
      :max-total-price="Number(order.max_total_price)"
    />
    <template v-if="order.closable">
      <UiButton
        v-if="!confirmingAssembled"
        :background-color="order.needClientConfirm ? 'secondary' : 'primary'"
        class="mt-2"
        :data-test="order.needClientConfirm ? 'order_retail to-confirm-btn' : 'order_retail check-payment'"
        @click="order.needClientConfirm ? $emit('toConfirm') : $emit('finish')"
      >
        {{ order.needClientConfirm ? $gettext('К согласованию') : $gettext('Проверить возможность оплаты') }}
      </UiButton>
      <UiButton
        v-else
        class="mt-2"
        :is-disabled="true"
      >
        {{ $gettext('Проверка возможности оплаты') }}
      </UiButton>
    </template>
  </LayoutFooter>
</template>

<script setup lang="ts">
import { OrderTotalPrice } from '@/fsd/features/order';
import Product from '@/models/Product';
import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { computed } from 'vue';

interface OrderRetailFooterProps {
  order: OrderOrderRetail;
  collected: Record<string, string[]>;
}

const props = defineProps<OrderRetailFooterProps>();

defineEmits<{
  (e: 'finish'): void;
  (e: 'toConfirm'): void;
}>();

const collectedPrice = computed(() => {
  return props.order.suggests.reduce((acc, suggest) => {
    const curCollected = props.collected[suggest.suggest_id] || [];

    if (suggest.product?.isTrueWeight) {
      const count = Product.weightFromBarcode(curCollected);
      acc += count * +suggest.price;
    } else {
      acc += curCollected.length * +suggest.price;
    }

    return acc;
  }, 0);
});

const confirmingAssembled = computed<boolean>(
  () =>
    !!props.order.vars.confirming_assembled_products
    && typeof props.order.vars.fulfilled_conditions?.confirm_assembled_products !== 'boolean',
);
</script>
