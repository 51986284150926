<template>
  <PageLayout :order_id="order_id">
    <SuggestDetailsTrueMarkAcceptance
      v-if="suggestDetailsTrueMark.visible.value && suggestDetailsTrueMark.props.value && orderTrueMarksIncluded"
      v-bind="suggestDetailsTrueMark.props.value"
      :order-id="order_id"
      @cancel="suggestDetailsTrueMark.hide"
    />
    <SuggestDetails
      v-else-if="suggestDetails.visible.value && suggestDetails.props.value"
      :suggest-id="suggestDetails.props.value.suggest_id"
      :suggest-prop="suggestDetails.props.value.virtual ? suggestDetails.props.value : undefined"
      :order-id="order_id"
      :barcode="scanBarcode"
      @cancel="suggestDetails.hide"
      @finish="val => finishActiveSuggest(val, suggestDetails.props.value)"
    />
    <ShareOrder
      v-else-if="order && shareOrder.visible.value"
      :order="order"
      @close="shareOrder.hide"
    />
    <SuggestsList
      v-else
      :menu-title="$gettext('Приёмка')"
      :merge-suggest="orderTrueMarksIncluded"
      :order_id="order_id"
      :suggest-menu-config="suggestMenuConfig"
      @show-share-order="showShareOrder"
      @close="toHomePage"
      @finish-order="finishOrder"
      @finish-order-true-mark="finishOrderTrueMark"
      @open-details="openDetails"
    />
  </PageLayout>
</template>

<script setup lang="ts">
import { useSubscribeOnOrderStatus } from '@/fsd/data/utils/subscribeOnOrder';
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import { useHandleProductCode } from '@/fsd/entities/scanner';
import { getDoneSuggests, getRequestSuggests } from '@/fsd/entities/filters/suggestsFilters';
import { useCheck } from '@/fsd/entities/suggest/tools/useCheck';
import { checkContractor } from '@/fsd/features/order/utils/checkContractor';
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import { SuggestsList } from '@/fsd/widgets/acceptance';
import { useComponent } from '@/hooks/useComponent';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Product, { TypeAccountingEnum } from '@/models/Product';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';
import AcceptanceOrder from '@/models/orders/AcceptanceOrder';
import {
  AcceptanceModeAttrEnum,
  OrderStatus,
  OrderTargetEnum,
  RequestTypeAttrEnum,
} from '@/models/orders/BaseOrder';
import { AudioService } from '@/services/audio.service';
import { ModeService } from '@/services/mode.service';
import { SignalEnum } from '@/services/requests';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useUser } from '@/store/modules/user';
import { experiments, orderTypeUrls } from '@/temp/constants';
import { getQuantUnit } from '@/temp/constants/translations/quantUnits';
import { $gettext, $ngettext } from '@/temp/plugins/gettext';
import { logger } from '@/temp/plugins/logs';
import { Notifications } from '@/temp/plugins/notification';
import { QrActions } from '@/temp/services/qr-actions';
import { useLoader } from '@/ui/common/loader/useLoader';
import { MenuItemConfig } from '@/ui/common/menu/types';
import ShareOrder from '@/ui/common/share-order/share-order.vue';
import { getCountToView } from '@/ui/common/suggest-card/formatters/count-formatter';
import SuggestDetailsTrueMarkAcceptance from '@/ui/common/suggest-details/SuggestDetailsTrueMarkAcceptance.vue';
import SuggestDetails from '@/ui/common/suggest-details/suggest-details.vue';
import { Model } from '@/ui/common/suggest-details/types';
import { checkConditions, OperationEnum } from '@/utils/checkConditions';
import { isAxiosError } from 'axios';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { mergeTrueMarkSuggests } from '@/fsd/entities/suggest/tools/mergeTrueMarkSuggests';
import { useHandleOrderStatus } from '@/hooks/useHandleOrderStatus';

const scanBarcode = ref<string | undefined>();

const props = defineProps<{ order_id: string }>();

const { showLoader } = useLoader();
const productsStore = useProducts();
const ordersStore = useOrders();
const userStore = useUser();
const shareOrder = useComponent();
const suggestDetails = useComponent<Suggest>();
const suggestDetailsTrueMark = useComponent<{
  count?: number;
  suggestId: string;
  barcode: string;
}>();
const router = useRouter();

const order = computed<AcceptanceOrder | undefined>(() => ordersStore.orderById(props.order_id) as AcceptanceOrder);
const orderTrueMarksIncluded = computed(() => order.value?.attr.true_marks_included);
const suggests = computed(() => {
  const orderSuggest = order.value?.suggests ?? [];
  return orderTrueMarksIncluded.value ? mergeTrueMarkSuggests(orderSuggest) : orderSuggest;
});

const requestSuggests = computed(() => getRequestSuggests(suggests.value));
const completedSuggests = computed(() => getDoneSuggests(suggests.value));
const isOrderWeight = computed(() => suggests.value.some(s => s.conditions.need_weight));
const isOrderMoveType = computed(() => order.value?.attr.request_type === RequestTypeAttrEnum.move_order);
const isOrderModePreCheck = computed(() => order.value?.attr.acceptance_mode === AcceptanceModeAttrEnum.pre_check);
const isMayBeTrust = computed(() => {
  const isAllSuggestsOpen = requestSuggests.value.length === suggests.value.length;

  return isOrderMoveType.value && isAllSuggestsOpen && !isOrderWeight.value;
});
const isMustBeTrust = computed(() => {
  const isLightmanExp = !!userStore.experimentByName(experiments.exp_lightman);
  const isOrderTrustCode = !!order.value?.attr.trust_code;

  return isLightmanExp && isOrderTrustCode;
});

useHandleOrderStatus(props.order_id);
const { getProductsByBarcode } = useHandleProductCode(props.order_id);

const checkTrustAcceptance = async (barcode: string) => {
  if (QrActions.isAction(barcode)) {
    logger('Получен баркод с подписью');
    barcode = await QrActions.trustAcceptance(barcode);
  }
  if (order.value && [order.value.attr.request_id, order.value.attr.trust_code].includes(barcode)) {
    if (isOrderMoveType.value && completedSuggests.value.length) {
      Alerts.error($gettext('Невозможно завершить приемку доверительно, если уже есть принятые товары !'));
      return false;
    }
    const confirmedTrustAcceptance = await confirmTrustAcceptance();

    if (confirmedTrustAcceptance) {
      await runTrustAcceptance();
    }
    return false;
  }
  if (!ModeService.isProduction() && barcode === order.value?.attr.doc_number) {
    const confirmedTrustAcceptance = await confirmTrustAcceptance();

    if (confirmedTrustAcceptance) {
      await runTrustAcceptance();
    }
    return false;
  }
  if (isMustBeTrust.value) {
    await Modal.show({ title: $gettext('Приемка разрешена только по QR коду') });
    return false;
  }
  return true;
};

const getProductsMap = (products: Product[]): Record<string, Product> => {
  return products.reduce((acc, product) => {
    const key = product.parent_id ?? product.product_id;
    acc[key] = product;
    return acc;
  }, {});
};

const createVirtualSuggest = (product: Product): Suggest => {
  return new Suggest({
    order_id: props.order_id,
    product_id: product.product_id,
    shelf_id: order.value?.shelves[0],
    count: 0,
    conditions: {
      all: true,
      max_count: false,
      need_valid: true,
    },
    virtual: true,
  });
};

const getSuggestFromProduct = (suggestsFromProducts: Suggest[]) => {
  if (suggestsFromProducts.length > 1) {
    const requestSuggest = suggestsFromProducts.find(s => s.status === SuggestStatusEnum.request);
    if (requestSuggest) {
      return requestSuggest;
    }
  }

  return suggestsFromProducts[0];
};

const handlerBarcode = async (barcode: string) => {
  try {
    // если приемка по ЧЗ, то она не может быть доверительной
    let isNotTrustAcceptance = orderTrueMarksIncluded.value;
    if (!isNotTrustAcceptance) {
      isNotTrustAcceptance = await checkTrustAcceptance(barcode);
    }

    if (isNotTrustAcceptance) {
      const { products, counts } = await getProductsByBarcode(barcode, props.order_id);

      const productsProductType = products.filter(Product.isProduct);
      if (!productsProductType.length) {
        Alerts.error($gettext('Продукта нет в задании'));
        return true;
      }

      const productsMap = getProductsMap(products);
      const suggestsFromProducts = suggests.value.filter(({ product_id }) => productsMap[product_id]);

      if (!suggestsFromProducts.length) {
        if (!isOrderModePreCheck.value) {
          Alerts.error($gettext('Продукта нет в задании'));
          return true;
        }

        const [product] = productsProductType;
        const canSendMoreProduct = await checkContractor(props.order_id, product.product_id);
        if (!canSendMoreProduct) {
          AudioService.playError();
          return true;
        }

        const virtualSuggest = createVirtualSuggest(product);
        await suggestDetails.asyncShow(virtualSuggest);
        return true;
      }

      const currentSuggest = getSuggestFromProduct(suggestsFromProducts);
      const product = productsMap[currentSuggest.product_id];

      scanBarcode.value = barcode;

      if (isMayBeTrust.value && !orderTrueMarksIncluded.value) {
        const confirmed = await Modal.show({
          title: $gettext('Эта приемка должна быть выполнена доверительно'),
          text: $gettext(
            'Вы хотите принять все товары не доверительно – это действие необратимо. Вы уверены, что хотите продолжить?',
          ),
          btnPosition: ButtonPositionsEnum.horizontal,
          confirmBtnTitle: $gettext('Продолжить'),
          closeBtnTitle: $gettext('Назад'),
        });
        if (confirmed) await suggestDetails.asyncShow(currentSuggest);
        return true;
      }

      const isProductUnit = product.type_accounting === TypeAccountingEnum.unit;

      if (isProductUnit && product.parent_id && currentSuggest.conditions.need_weight) {
        await Modal.show({ title: $gettext('Сканируйте ШК с коробки') });
        return true;
      }
      // ветвление по типу саджеста
      if (TrueMarkSuggestWrapper.isTrueMarkSuggestWrapper(currentSuggest)) {
        const count: number | undefined = counts[product.product_id];
        const props = {
          barcode,
          suggestId: currentSuggest.suggest_id,
          count,
        };
        await suggestDetailsTrueMark.asyncShow(props);
      } else {
        await suggestDetails.asyncShow(currentSuggest);
      }
    }
    return true;
  } catch {
    return true;
  }
};

const { needBarcodeRequest } = useRequestBarcode(handlerBarcode);

const confirmTrustAcceptance = async () => {
  return Modal.show({
    title: $gettext('Вы хотите принять весь товар без проверки?'),
    text: $gettext(
      'Если вы подтвердите это действие, то весь товар будет принят автоматически и проверять его не потребуется',
    ),
    btnPosition: ButtonPositionsEnum.horizontal,
  });
};

const toHomePage = () => {
  router.push({ name: 'home' });
};

const getTitle = (activeProduct: Product | undefined, count: number, weight?: number) => {
  if (activeProduct?.quants && activeProduct?.quants > 1 && activeProduct.quant_unit) {
    return $ngettext(
      'Вы уверены, что хотите принять %{count} упаковок товара (%{unit_count} %{unit})?',
      'Вы уверены, что хотите принять %{count} упаковок товара (%{unit_count} %{unit})?',
      count,
      {
        count: String(count),
        unit_count: String(activeProduct?.quants * count),
        unit: getQuantUnit(activeProduct?.quant_unit),
      },
    );
  }

  return $gettext('Вы уверены, что хотите принять %{weightPart} %{product}?', {
    weightPart: String(getCountToView({
      count,
      type_accounting: activeProduct?.type_accounting,
      weight,
    })),
    product: String(activeProduct?.title),
  });
};

const finishActiveSuggest = async (
  { count = 0, weight, date }: Pick<Model, 'count' | 'weight' | 'date'>,
  suggest: Suggest | undefined,
): Promise<void> => {
  if (!suggest) {
    Alerts.error($gettext('Произошла ошибка при выполнении задания'));
    logger.error('empty active suggest', {
      type: 'code',
      page: 'accceptance',
      method: 'finishActiveSuggest',
      message: 'empty active suggest',
    });
    return;
  }
  const activeProduct = suggest.product;

  if (count !== 0) {
    const confirmed = await Notifications.confirmBottom({
      title: getTitle(activeProduct, count, weight),
    });
    if (!confirmed) return;
  } else {
    const confirmed = await Notifications.confirmBottom({
      title: $gettext('Вы уверены, что товар %{product} отсутствует?', {
        count: String(count),
        product: String(activeProduct?.title),
      }),
    });
    if (!confirmed) return;
  }

  if (count === suggest.result_count) {
    suggestDetails.hide();
    needBarcodeRequest.value = true;
    return;
  }
  if (suggest.virtual) {
    // особый случай для б2б и документов с pre_check
    await ordersStore.signal({
      order_id: props.order_id,
      signal: SignalEnum.add_product,
      data: {
        product_id: suggest.product_id,
        valid: date,
        count,
      },
    });
  } else {
    const result = await useCheck(props.order_id, {
      suggest_id: suggest.suggest_id,
      count,
      weight,
      valid: date,
    });
    if (!result) return;
  }

  suggestDetails.hide();
  needBarcodeRequest.value = true;
};

const finishOrder = async (): Promise<void> => {
  const isFinishedConfirm = await Notifications.confirmBottom({
    title: $gettext('Вы уверены, что приняли все товары?'),
  });
  if (!isFinishedConfirm) return;
  needBarcodeRequest.value = false;
  const result = await useEndOrder(props.order_id);
  if (result) {
    toHomePage();
  } else {
    needBarcodeRequest.value = true;
  }
};

const finishOrderTrueMark = async () => {
  const { closeLoader } = showLoader($gettext('Отправляем запрос на завершение задания'), props.order_id);
  try {
    await ordersStore.signal({
      order_id: props.order_id,
      signal: SignalEnum.try_complete_acceptance,
    });
  } catch (e) {
    closeLoader();
    logger.error(e, {
      method: 'signal',
      type: 'api',
      source: 'acceptance.vue',
    });
  }
};

watch(() => order.value?.target, (target) => {
  if (target && target === OrderTargetEnum.failed) {
    router.push({
      name: orderTypeUrls.acceptance_rollback,
      params: { order_id: props.order_id },
    });
  }
});

const runTrustAcceptance = async () => {
  const { closeLoader } = showLoader($gettext('Отправляем запрос на доверительную приемку'), props.order_id);
  try {
    await ordersStore.signal({
      order_id: props.order_id,
      signal: SignalEnum.acceptance_agree,
    });

    const subscribeHandler = (status?: OrderStatus) => {
      if (!status || status === OrderTargetEnum.complete) {
        Alerts.success($gettext('Доверительная приемка проведена'));
        toHomePage();
        return true;
      }
      return status === OrderTargetEnum.failed;
    };

    const subscribeOrderStatus = useSubscribeOnOrderStatus(props.order_id);
    await subscribeOrderStatus(subscribeHandler);
  } catch (e: unknown) {
    if (isAxiosError(e)) {
      if (e.response?.data.code === 'ER_SUGGEST_WEIGHT_REQUIRED') {
        Modal.show({
          title: $gettext('Невозможно принять доверительно'),
          text: $gettext('В приемке присутствуют весовые товары'),
        });
      }
    }
  } finally {
    closeLoader();
  }
};

const showShareOrder = async (): Promise<void> => {
  needBarcodeRequest.value = false;
  await shareOrder.asyncShow();
  needBarcodeRequest.value = true;
};

const openDetails = async (suggest: Suggest) => {
  needBarcodeRequest.value = false;
  await suggestDetails.asyncShow(suggest);
  needBarcodeRequest.value = true;
};

const onNoProductClick = (suggest: Suggest) => {
  if (isMustBeTrust.value) {
    Modal.show({ title: $gettext('Приемка разрешена только по QR коду') });
    return;
  }
  if (suggest.conditions.need_weight) {
    finishActiveSuggest({
      count: 0,
      weight: 0,
    }, suggest);
  } else {
    finishActiveSuggest({ count: 0 }, suggest);
  }
};

const suggestMenuConfig = (suggest: Suggest): MenuItemConfig[] => {
  return [
    {
      buttonText: $gettext('Отметить как отсутствующий'),
      onClick: () => {
        onNoProductClick(suggest);
      },
      dataTest: 'no-product btn',
      condition: () => {
        const isValidCondition = checkConditions(suggest, OperationEnum.all, true);
        const isSuggestRequestOrResultCount = (suggest.status === SuggestStatusEnum.request || suggest.result_count! > 0);
        const isNeedTrueMark = suggest.conditions.need_true_mark;

        return isValidCondition && isSuggestRequestOrResultCount && !isNeedTrueMark;
      },
    },
    {
      buttonText: $gettext('Принять товар'),
      onClick: async () => {
        if (isMustBeTrust.value) {
          Modal.show({ title: $gettext('Приемка разрешена только по QR коду') });
          return;
        }
        await openDetails(suggest);
      },
      dataTest: 'open-details btn',
      condition: () => {
        return (
          productsStore.productById(suggest.product_id)?.type_accounting === TypeAccountingEnum.true_weight
          && userStore.experimentByName(experiments.exp_weighty_touch)
        );
      },
    },
  ];
};
</script>
