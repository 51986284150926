<template>
  <svg
    viewBox="0 0 320 524"
    fill="none"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <rect
        x="239"
        y="163"
        width="156"
        height="156"
        rx="20"
        transform="rotate(-21.8056 239 163)"
        fill="#F5F4F2"
      />
      <rect
        x="76"
        y="-0.0827787"
        width="102.374"
        height="102.374"
        rx="20"
        transform="rotate(117.909 76 -0.0827787)"
        fill="#F5F4F2"
      />
      <rect
        x="32.7867"
        y="401"
        width="77.1833"
        height="77.1833"
        rx="20"
        transform="rotate(68.4474 32.7867 401)"
        fill="#F5F4F2"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="320"
          height="524"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
