import IconCourierRover from '@/fsd/shared/icons/courier/icon-courier-rover.vue';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { $gettext } from '@/temp/plugins/gettext';
import { markRaw } from 'vue';

export const orderByRoverModal = () => {
  return Modal.show({
    title: $gettext('Этот заказ будет доставлен ровером'),
    text: $gettext(
      'После сборки заказа вам нужно будет положить товары в ровер, который будет ждать вас на улице рядом с лавкой',
    ),
    component: markRaw(IconCourierRover),
    confirmBtnTitle: $gettext('Все понятно'),
  });
};
