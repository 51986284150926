export enum ERR {
  ER_SUGGEST_TRUE_MARK_DUPLICATED = 'ER_SUGGEST_TRUE_MARK_DUPLICATED',
  ER_SUGGEST_WRONG_TRUE_MARK = 'ER_SUGGEST_WRONG_TRUE_MARK',
  ER_INVALID_TRUE_MARK = 'ER_INVALID_TRUE_MARK',
  ER_BAD_TRUE_MARK = 'ER_BAD_TRUE_MARK',
  ER_TRUE_API_BROKEN = 'ER_TRUE_API_BROKEN',
  ER_EXTERNAL_SERVICE = 'ER_EXTERNAL_SERVICE',
  ER_RETIRED_TRUE_MARK_ON_REVIEW = 'ER_RETIRED_TRUE_MARK_ON_REVIEW',
  ER_RETIRED_TRUE_MARK_ON_STORE = 'ER_RETIRED_TRUE_MARK_ON_STORE',
  ER_RETIRED_TRUE_MARK_OTHER_STORE = 'ER_RETIRED_TRUE_MARK_OTHER_STORE',
  ER_RETIRED_TRUE_MARK_OUTSIDE = 'ER_RETIRED_TRUE_MARK_OUTSIDE',

  ER_SUGGEST_TRUE_MARK_REQUIRED = 'ER_SUGGEST_TRUE_MARK_REQUIRED',
  ER_SUGGEST_INVALID_TRUE_MARK = 'ER_SUGGEST_INVALID_TRUE_MARK',
  ER_SUGGEST_WRONG_PRODUCT_TRUE_MARK = 'ER_SUGGEST_WRONG_PRODUCT_TRUE_MARK',
  ER_SUGGEST_TRUE_MARK_CONSUMED = 'ER_SUGGEST_TRUE_MARK_CONSUMED',
  ER_SUGGEST_TRUE_MARK_IN_ANOTHER_ORDER = 'ER_SUGGEST_TRUE_MARK_IN_ANOTHER_ORDER',
  ER_SUGGEST_TRUE_MARK_NOT_IN_CURRENT_ORDER = 'ER_SUGGEST_TRUE_MARK_NOT_IN_CURRENT_ORDER',
  ER_SUGGEST_TRUE_MARK_ALREADY_TAKEN = 'ER_SUGGEST_TRUE_MARK_ALREADY_TAKEN',
  ER_MARK_IS_SELLING_YET = 'ER_MARK_IS_SELLING_YET',
  ER_TRUE_MARK_HAS_EXPIRED = 'ER_TRUE_MARK_HAS_EXPIRED',
  ER_RETIRED_TRUE_MARK_WAITING_FOR_SALE_CONFIRMATION = 'ER_RETIRED_TRUE_MARK_WAITING_FOR_SALE_CONFIRMATION',
  ER_NO_PRODUCT_FOR_MARK_IN_COMPANY = 'ER_NO_PRODUCT_FOR_MARK_IN_COMPANY',
  ER_TRUE_MARK_OWNER_TIN_IS_WRONG = 'ER_TRUE_MARK_OWNER_TIN_IS_WRONG',
  ER_TRUE_MARK_OWNER_CHANGING = 'ER_TRUE_MARK_OWNER_CHANGING',

  ER_CONFIG_NOT_FOUND = 'ER_CONFIG_NOT_FOUND',
  ER_UNKNOWN_GROUP_MARK = 'ER_UNKNOWN_GROUP_MARK',
  ER_PRODUCT_NOT_IN_ACCEPTANCE = 'ER_PRODUCT_NOT_IN_ACCEPTANCE',
  ER_UNIT_MARK_FROM_GROUP = 'ER_UNIT_MARK_FROM_GROUP',
  ER_SERVICE_UNAVAILABLE = 'ER_SERVICE_UNAVAILABLE',
  ER_TRUE_MARK_IN_ANOTHER_ORDER = 'ER_TRUE_MARK_IN_ANOTHER_ORDER',
  ER_TRUE_MARK_ALREADY_BEEN_SCANNED = 'ER_TRUE_MARK_ALREADY_BEEN_SCANNED',
  ER_MARK_NOT_INTRODUCED = 'ER_MARK_NOT_INTRODUCED',
  ER_SUGGEST_TRUE_MARK_UNEXPECTED_LIMIT_EXCEEDED = 'ER_SUGGEST_TRUE_MARK_UNEXPECTED_LIMIT_EXCEEDED',
}

export const enum ERR_STATUS_BAD_TRUE_MARK {
  NOT_APPLIED = 'NOT_APPLIED',
  NOT_VERIFIED = 'NOT_VERIFIED',
  RETIRED = 'RETIRED',
  INTRODUCED = 'INTRODUCED',
  CIS_NOT_FOUND = 'CIS_NOT_FOUND',
  BLOCKED = 'BLOCKED',
  NOT_REALIZABLE = 'NOT_REALIZABLE',
}
