import { onUnmounted, ref } from 'vue';

export function useComponent<ComponentProps = void, ResolveData = void>(
  isVisible: boolean = false,
) {
  const visible = ref(isVisible);
  const props = ref<ComponentProps>();

  const toggle = () => {
    visible.value = !visible.value;
  };

  const show = () => {
    visible.value = true;
  };

  let resolve: any = () => {};
  let reject: any = () => {};

  const asyncShow = (p: ComponentProps) => {
    props.value = p;
    show();
    return new Promise<ResolveData | undefined>((res, rej) => {
      resolve = res;
      reject = rej;
    });
  };

  const hide = (data?: ResolveData) => {
    visible.value = false;
    props.value = undefined;
    resolve(data);
  };

  const executeAndHide = async (cb: (...args: any[]) => any, ...args: any[]) => {
    await cb(...args);
    hide();
  };

  onUnmounted(() => reject('useComponent:unmounted'));

  return {
    visible,
    toggle,
    show,
    hide,
    executeAndHide,
    asyncShow,
    props,
  };
}
