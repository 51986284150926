<template>
  <svg
    class="primary-color-icon"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.8 7L9.04645 1.75355C9.24171 1.55829 9.24171 1.24171 9.04645 1.04645L8.35355 0.353553C8.15829 0.158291 7.84171 0.158291 7.64645 0.353553L0 8L7.64645 15.6464C7.84171 15.8417 8.15829 15.8417 8.35355 15.6464L9.04645 14.9536C9.24171 14.7583 9.24171 14.4417 9.04645 14.2464L3.8 9H15.5C15.7761 9 16 8.77614 16 8.5V7.5C16 7.22386 15.7761 7 15.5 7H3.8Z"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
