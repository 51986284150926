<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="40"
      height="40"
      rx="20"
      :fill="bgColorValue"
      class=""
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.2944 12.8046C18.4976 10.7233 21.502 10.7233 22.7052 12.8046L28.8514 23.4359C30.0559 25.5192 28.5525 28.1249 26.146 28.1249L13.8536 28.1249C11.4472 28.1249 9.94376 25.5192 11.1482 23.4359L17.2944 12.8046ZM20 16.2499C20.5177 16.2499 20.9375 16.6697 20.9375 17.1874V19.6874C20.9375 20.2052 20.5177 20.6249 20 20.6249C19.4822 20.6249 19.0625 20.2052 19.0625 19.6874V17.1874C19.0625 16.6697 19.4822 16.2499 20 16.2499ZM21.25 23.4374C21.25 24.1278 20.6903 24.6874 20 24.6874C19.3096 24.6874 18.75 24.1278 18.75 23.4374C18.75 22.7471 19.3096 22.1874 20 22.1874C20.6903 22.1874 21.25 22.7471 21.25 23.4374Z"
      :fill="colorValue"
    />
  </svg>
</template>

<script setup lang="ts">
import { useColor } from '@/fsd/shared/hooks';

interface Props {
  bgColor?: string;
  color?: string;
}

const { bgColor = '#FFA60D', color = 'white' } = defineProps<Props>();
const params = {
  color: () => color,
  bgColor: () => bgColor,
};
const { colorValue, bgColorValue } = useColor(params);
</script>
