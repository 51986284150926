import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';

let resolve: any;
let reject: any;
const createNewPromise = <T>(): Promise<T> => {
  if (reject) reject('scan intercept');
  return new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });
};

const returnBarcodeToListener = (barcode: string) => {
  resolve(barcode);
  resolve = undefined;
  reject = undefined;
};

export const startScan = async (): Promise<string> => {
  const listener = await BarcodeScanner.addListener('barcodeScanned', async (result) => {
    await listener.remove();
    await BarcodeScanner.stopScan();
    returnBarcodeToListener(result.barcode.displayValue);
  });
  await BarcodeScanner.startScan();
  return createNewPromise<string>();
};

export const stopScan = async () => {
  if (reject) {
    reject('scan aborted');
  }
  await BarcodeScanner.stopScan();
};
