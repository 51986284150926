<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 0H32C36.4183 0 40 3.58172 40 8V32C40 36.4183 36.4183 40 32 40H8C3.58172 40 0 36.4183 0 32V8C0 3.58172 3.58172 0 8 0Z"
      fill="#ED7E67"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.5989 4.08493C19.4957 3.71127 20.5045 3.71127 21.4013 4.08493L32.0083 8.50451C33.3659 9.07017 34.2502 10.3967 34.2502 11.8674V28.135C34.2502 29.6058 33.3659 30.9322 32.0083 31.4979L21.4013 35.9175C20.5045 36.2911 19.4957 36.2911 18.5989 35.9175L7.99192 31.4979C6.63432 30.9322 5.75 29.6058 5.75 28.135V11.8674C5.75 10.3967 6.63432 9.07017 7.99192 8.50451L18.5989 4.08493ZM11.1504 10.4387L18.5 7.37637V13.5011L11.1504 10.4387ZM28.8498 10.4387L21.5002 13.5011V7.37637L28.8498 10.4387ZM8.75021 28.135V12.6889L18.0732 16.5735L12.7816 30.2434L9.14584 28.7285C8.90627 28.6287 8.75021 28.3946 8.75021 28.135ZM18.5004 32.6262L18.5003 23.7811L15.552 31.3977L18.5004 32.6262ZM21.5006 32.6259L21.5005 16.7512L31.25 12.6889V28.135C31.25 28.3946 31.0939 28.6287 30.8544 28.7285L21.5006 32.6259Z"
      :fill="colorValue"
    />
  </svg>
</template>
<script setup lang="ts">
import { useColor } from '@/fsd/shared/hooks';

interface Props {
  bgColor?: string;
  color?: string;
}

const { color = 'white' } = defineProps<Props>();
const params = {
  color: () => color,
};
const { colorValue } = useColor(params);
</script>
