<template>
  <BadgeBase
    :size="size"
    :uppercase="uppercase"
    :container-class="badgeConfig[type].containerClass"
  >
    <template
      v-if="badgeConfig[type].icon"
      #iconLeft
    >
      <component
        :is="badgeConfig[type].icon"
        height="100%"
        width="100%"
      />
    </template>
    <slot />
  </BadgeBase>
</template>

<script setup lang="ts">
import IconCircleCheck from '@/fsd/shared/icons/icon-circle-check.vue';
import { BadgeBase, BadgeSizeEnum } from '@/fsd/shared/ui/BadgeBase';
import type { Component } from 'vue';
import { StatusTypeEnum } from './types';

interface BadgeConfig {
  containerClass: string;
  icon?: Component;
}

interface Props {
  size?: BadgeSizeEnum;
  type: StatusTypeEnum;
  uppercase?: boolean;
}

const { size = BadgeSizeEnum.large, uppercase = true } = defineProps<Props>();

const badgeConfig: Record<StatusTypeEnum, BadgeConfig> = {
  [StatusTypeEnum.complete]: {
    containerClass: 'bg-lime-toxic text-white',
    icon: IconCircleCheck,
  },
  [StatusTypeEnum.waiting]: {
    containerClass: 'bg-orange-normal text-white',
  },
  [StatusTypeEnum.error]: {
    containerClass: 'bg-red-normal text-white',
  },
  [StatusTypeEnum.custom]: {
    containerClass: 'bg-gray-350 text-white',
  },
  [StatusTypeEnum.processing]: {
    containerClass: 'bg-blue-normal text-white',
  },
};
</script>
