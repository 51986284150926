import { api } from '@/fsd/data/api/api.service';
import AvailableProductAction from '@/fsd/features/CheckAvailableProduct/ui/AvailableProductAction.vue';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { callAction } from '@/fsd/shared/ui/action';
import Product from '@/models/Product';
import { useShelves } from '@/store/modules/shelves';
import { $gettext } from '@/temp/plugins/gettext';
import { logger } from '@/temp/plugins/logs';
import { useLoader } from '@/ui/common/loader/useLoader';

export const checkAvailableProduct = async (product_id: Product['product_id']) => {
  const { showLoader } = useLoader();
  const { closeLoader } = showLoader();
  try {
    const { data } = await api.stock.available({ product_id });
    const shelves = await useShelves().getShelvesByIds(data.found.map(f => f.shelf_id));
    closeLoader();
    logger.error('check-available-product', {
      additional: {
        product_id,
      },
    });
    await callAction(AvailableProductAction, {
      product_id,
      products: data.found,
      shelves,
    });
  } catch (e) {
    closeLoader();
    logger.error(e, {
      method: 'checkAvailableProduct',
      type: 'api',
      source: 'checkAvailableProduct.ts',
    });
    Alerts.error($gettext('Попробуйте позже'));
  }
};
