import { RUMOrderAssemblingDeltasEnum, rumSpaManager } from '@/fsd/shared/tools/RumSpaManager';
import {
  getDuration, markEnd, markStart,
} from '@/fsd/shared/utils/timeMarkHelper';
import { logger } from '@/temp/plugins/logs';

const pageName = 'order.assembling';

export const startAssembling = () => {
  rumSpaManager.makeSpaSubPage(pageName, true);
};
export const finishAssembling = () => {
  rumSpaManager.sendDelta(pageName, RUMOrderAssemblingDeltasEnum.finishAssembling, 0);
  rumSpaManager.finalizeSpaData(pageName);
};

export const finishDoneRequest = (duration) => {
  if (duration) rumSpaManager.sendDelta(pageName, RUMOrderAssemblingDeltasEnum.finishDoneRequest, duration);
};

export const startSherlock = () => {
  markStart('sherlock');
};

export const finishSherlock = () => {
  markEnd('sherlock');
  const duration = getDuration('sherlock');
  if (duration) rumSpaManager.sendDelta(pageName, RUMOrderAssemblingDeltasEnum.finishSherlock, duration);
};
export const finishEventWaiting = (duration) => {
  if (duration) rumSpaManager.sendDelta(pageName, RUMOrderAssemblingDeltasEnum.finishDoneEventWaiting, duration);
};

export const finishCheckTrueMark = (duration: number) => {
  try {
    if (duration) rumSpaManager.sendDelta(pageName, RUMOrderAssemblingDeltasEnum.finishCheckTrueMark, duration);
  } catch (e) {
    logger.error(e);
  }
};

export const startRequestBarcode = (suggest_id: string) => {
  try {
    markStart('order-assembly-request-barcode' + suggest_id);
  } catch (e) {
    logger.error(e);
  }
};

const times = {};
export const finishRequestBarcode = (suggest_id: string) => {
  try {
    markEnd('order-assembly-request-barcode' + suggest_id);
    const duration = getDuration('order-assembly-request-barcode' + suggest_id);
    if (!duration) return;
    if (!times[suggest_id]) times[suggest_id] = 0;
    times[suggest_id] += duration;
  } catch (e) {
    logger.error(e);
  }
};

export const sendRequestBarcodeTime = (suggest_id: string) => {
  try {
    markEnd('order-assembly-request-barcode');
    const duration = times[suggest_id] || 0;
    delete times[suggest_id];
    rumSpaManager.sendDelta(pageName, RUMOrderAssemblingDeltasEnum.finishRequestBarcode, duration);
  } catch (e) {
    logger.error(e);
  }
};
