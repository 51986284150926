<template>
  <Overlay>
    <div class="count-window-container">
      <Typography
        type="h2"
        class="count-window-title"
      >
        {{ title }}
      </Typography>
      <Typography class="count-window-text">
        {{ text }}
      </Typography>
      <Counter
        :min="0"
        :max="max"
        :btn-timeout="timeoutForBtn"
        :btn-text="$gettext('Готово')"
        @confirm="value => onInputCountConfirm({ value })"
      />
    </div>
  </Overlay>
</template>

<script lang="ts">
import Counter from '@/fsd/entities/counter';
import Overlay from '@/ui/common/overlay/overlay.vue';
import Typography from '@/ui/common/typography.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Counter,
    Typography,
    Overlay,
  },
  props: {
    max: {
      type: Number,
      default: undefined,
    },
    customTitle: {
      type: String,
      default: '',
    },
    customText: {
      type: String,
      default: '',
    },
    timeoutForBtn: {
      type: Number,
      default: 0,
    },
  },

  emits: ['finish'],
  computed: {
    title(): string {
      if (this.customTitle) return this.customTitle;
      return this.$gettext('Какое количество товара, которого вам не хватило, лежит на полке?');
    },
    text(): string {
      if (this.customText) return this.customText;
      return this.$gettext('Пожалуйста, введите количество этого товара, которое вы видете на полке');
    },
  },
  methods: {
    onInputCountConfirm({ value }: { value: number }): void {
      this.$emit('finish', value);
    },
  },
});
</script>

<style lang="scss">
.count-window-container {
  background: var(--main-bg);
  padding: 16px 8px 8px;
  transition: transform 200ms ease 0ms;
  transform: translateY(0);
  border-radius: 16px;
  margin: auto 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: auto;
}

.count-window-title {
  margin: 0 8px 12px 8px !important;
}

.count-window-text {
  margin: 0 8px 20px 8px !important;
}
</style>
