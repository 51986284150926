<template>
  <div :data-test="dataTest">
    <div
      v-if="iconTop"
      class="mb-2 px-0.5 flex items-center"
      :class="iconPosition(iconTop.position)"
    >
      <component
        :is="iconTop.icon"
        v-bind="iconTop.props"
      />
    </div>
    <div
      data-test="confirm title"
      class="text-primary text-2xl leading-7 font-bold mb-3 px-2"
      :class="titleAlign"
    >
      {{ title }}
    </div>
    <div
      v-if="iconCenter"
      class="my-4 flex items-center"
      :class="iconPosition(iconCenter.position)"
    >
      <component
        :is="iconCenter.icon"
        v-bind="iconCenter.props"
      />
    </div>
    <div
      v-if="text"
      data-test="confirm text"
      class="text-primary mb-4 whitespace-pre-line px-2"
      :class="align"
    >
      {{ text }}
    </div>
    <div
      v-if="iconBottom"
      class="my-4 flex items-center"
      :class="iconPosition(iconBottom.position)"
    >
      <component
        :is="iconBottom.icon"
        v-bind="iconBottom.props"
      />
    </div>
    <div
      class="flex"
      :class="buttonsClass"
    >
      <UiButton
        v-for="(button, index) in buttons"
        :key="index"
        :class="{
          'ml-2': buttons.length === 2 && index === 1 && !isWrap,
          'mt-2': (buttons.length > 2 || isWrap) && index >= 1,
        }"
        :timeout="button.timeout"
        :background-color="button.color"
        :btn-text-color="button.btnTextColor"
        :data-test="button.dataTest"
        @click="button.dontCloseOnClick ? button.onClick() : $emit('btn-click', button.onClick)"
      >
        {{ button.title }}
      </UiButton>
    </div>
  </div>
</template>

<script lang="ts">
import { ButtonConfig, IconConfig } from '@/temp/plugins/types';
import UiButton from '@/ui/common/ui-button.vue';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  name: 'ModalContent',
  components: { UiButton },
  props: {
    title: {
      type: String,
      required: true,
    },
    titleAlign: {
      type: String,
      default: 'text-left',
    },
    text: {
      type: String,
      default: '',
    },
    textAlign: {
      type: String,
      default: '',
    },
    iconTop: {
      type: Object as PropType<IconConfig>,
      default: undefined,
    },
    iconCenter: {
      type: Object as PropType<IconConfig>,
      default: undefined,
    },
    iconBottom: {
      type: Object as PropType<IconConfig>,
      default: undefined,
    },
    buttons: {
      type: Object as PropType<ButtonConfig[]>,
      default: undefined,
    },
    isWrap: {
      type: Boolean,
      default: false,
    },
    dataTest: {
      type: String,
      default: '',
    },
  },
  emits: ['btn-click'],
  computed: {
    align(): string {
      return this.textAlign || this.titleAlign;
    },
    buttonsClass(): any {
      if (this.buttons?.length! > 2 || this.isWrap) {
        return {
          'flex-col': true,
          'justify-between': true,
          'items-center': true,
        };
      }
      if (this.buttons?.length === 2) {
        return {
          'flex-row': true,
          'justify-between': true,
          'items-center': true,
        };
      }
      return {
        'items-center': true,
      };
    },
  },
  methods: {
    iconPosition(position): string {
      switch (position) {
        case 'left':
          return 'justify-start';
        case 'right':
          return 'justify-end';
        case 'center':
        default:
          return 'justify-center';
      }
    },
  },
});
</script>
