<template>
  <ActionWrapper>
    <div
      ref="container"
      class="flex flex-col pt-1 px-1"
    >
      <Title4
        class="font-bold mb-4 text-left"
        color="primary"
      >
        {{ suggest.product?.title }}
      </Title4>
      <caption1
        color="day-textMinor"
        class="flex pb-3 border-b border-day-line"
      >
        {{
          $gettext('Количество %{n} из %{all} шт.', {
            n: String(collected),
            all: String(suggest.count),
          })
        }}
      </caption1>
      <div
        v-if="isManualInputAllowed"
        class="flex mt-3 pb-3 border-b border-day-line"
      >
        <ButtonToModal
          data-test="suggest-modal change-products-count"
          @click="emits('btnClick', SuggestMenuModalActions.openManualInput)"
        >
          <template #icon>
            <IconReturn />
          </template>
          {{ $gettext('Изменить количество товара') }}
        </ButtonToModal>
      </div>
      <div
        v-else-if="!suggest.product?.isTrueWeight"
        class="flex mt-3 pb-3 border-b border-day-line"
      >
        <ButtonToModal
          data-test="suggest-modal remove-products"
          @click="emits('btnClick', SuggestMenuModalActions.removeProducts)"
        >
          <template #icon>
            <IconReturn />
          </template>
          {{ $gettext('Уменьшить количество товара') }}
        </ButtonToModal>
      </div>
      <div
        v-if="suggest.product?.isTrueWeight && !suggest.result_count"
        class="flex mt-3 pb-3 border-b border-day-line"
      >
        <ButtonToModal
          data-test="suggest-modal cancel"
          @click="emits('btnClick', SuggestMenuModalActions.cancelScans)"
        >
          <template #icon>
            <IconReturn />
          </template>
          {{ $gettext('Отменить все сканирования') }}
        </ButtonToModal>
      </div>
      <div
        v-if="canChangeDeleteReason"
        class="flex mt-3 pb-3"
        :class="{'border-b border-day-line' : canBeRemoved}"
      >
        <ButtonToModal
          data-test="suggest-modal change-delete-reason"
          @click="emits('btnClick', SuggestMenuModalActions.changeDeleteReason)"
        >
          <template #icon>
            <IconShoppingBasketError />
          </template>
          {{ $gettext('Изменить причину несборки') }}
        </ButtonToModal>
      </div>
      <div
        v-if="canChangeManufactureDate"
        class="flex mt-3 pb-3 border-b border-day-line"
      >
        <ButtonToModal
          data-test="suggest-modal change-manufacture-date"
          inner-wrap-classes="w-full"
          text-classes="flex grow justify-between"
          @click="emits('btnClick', SuggestMenuModalActions.changeManufactureDate)"
        >
          <template #icon>
            <IconCalendar />
          </template>
          <span>
            {{ $gettext('Дата изготовления') }}
          </span>
          <span
            class="text-gray-400"
            data-test="suggest-manufacture-date"
          >
            {{ manufactureDate }}
          </span>
        </ButtonToModal>
      </div>
      <div
        v-if="canBeRemoved"
        class="flex mt-3 pb-3"
      >
        <ButtonToModal
          data-test="suggest-modal remove"
          text-color="red-dark"
          @click="emits('btnClick', SuggestMenuModalActions.removeSuggest)"
        >
          <template #icon>
            <IconBasketRemove />
          </template>
          {{ $gettext('Удалить') }}
        </ButtonToModal>
      </div>
      <div class="flex mt-6">
        <UiButton
          data-test="suggest-modal close"
          background-color="secondary"
          @click="emits('btnClick')"
        >
          {{ $gettext('Закрыть') }}
        </UiButton>
      </div>
    </div>
  </ActionWrapper>
</template>

<script setup lang="ts">
import { useUser } from '@/store/modules/user';
import IconBasketRemove from '@/fsd/shared/icons/icon-basket-remove.vue';
import IconShoppingBasketError from '@/fsd/shared/icons/IconShoppingBasketError.vue';
import IconReturn from '@/fsd/shared/icons/icon-return.vue';
import { ButtonToModal } from '@/fsd/shared/ui/buttonToModal';
import Suggest from '@/models/Suggest';
import { $gettext } from '@/temp/plugins/gettext';
import ActionWrapper from '@/ui/common/notifications/actionWrapper.vue';
import Title4 from '@/ui/common/typo/title-4.vue';
import { checkManualInputAllowed } from '@/views/OrderRetail/tools/checkManualInputAllowed';
import { checkSuggestCanBeRemoved } from '@/views/OrderRetail/tools/checkSuggestCanBeRemoved';
import UiButton from '@/ui/common/ui-button.vue';
import { experiments } from '@/temp/constants';
import { SuggestMenuModalActions } from './types';
import IconCalendar from '@/temp/icons/calendar.vue';
import { getFormatDate } from '@/utils';
import { computed } from 'vue';

interface MenuModalProps {
  suggest: Suggest;
  collected: number;
}

const props = defineProps<MenuModalProps>();

const emits = defineEmits<{
  (e: 'btnClick', needDo?: SuggestMenuModalActions): void;
}>();

/**
 * True, если для товара доступен ручной ввод количества
 */
const isManualInputAllowed = computed(() => checkManualInputAllowed(props.suggest));

/**
 * True, если продукт может быть удален из заказа
 */
const canBeRemoved = computed(() => {
  return checkSuggestCanBeRemoved(props.suggest);
});

/**
 * True, если можно изменить установленную причину несборки
 */
const canChangeDeleteReason = computed(() => {
  return useUser().experimentByName(experiments.exp_delete_reason_required)
    && props.suggest.reason?.code;
});

/**
 * True, если можно изменить установленную дату изготовления
 */
const canChangeManufactureDate = computed(() => {
  return props.suggest.conditions.need_manufacture_date
    && props.suggest.result_valid;
});

/**
 * Дата изготовления товара
 */
const manufactureDate = computed(() => getFormatDate(props.suggest.result_valid));
</script>
