<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="40"
      height="40"
      rx="20"
      fill="#5C5A57"
      fill-opacity="0.1"
    />
    <path
      d="M23.7834 15.9018C23.6354 15.5207 23.4054 15.1789 23.1104 14.9018C22.7715 14.5975 22.3721 14.3724 21.94 14.2418C21.3022 14.0779 20.6468 13.9972 19.9893 14.0018C19.3318 13.9956 18.6762 14.0763 18.0387 14.2418C17.6212 14.3746 17.2357 14.5961 16.9073 14.8918C16.6094 15.1662 16.3788 15.5089 16.2344 15.8918C16.0826 16.3178 16.0066 17.1107 16.01 17.5642H18.2045C18.2003 17.3809 18.2267 16.856 18.2825 16.6818C18.3336 16.5063 18.4278 16.3454 18.55 16.2118C18.9611 15.8878 19.3493 15.75 20.0028 15.75C20.6562 15.75 21.0288 15.8871 21.45 16.2218C21.5722 16.3554 21.6842 16.5163 21.7352 16.6918C21.7863 16.8671 21.8125 17.0489 21.8132 17.2318C21.827 17.5467 21.7525 17.859 21.5986 18.1318C21.4587 18.3589 21.2842 18.5615 21.0817 18.7318C20.869 18.9143 20.6441 19.0814 20.4087 19.2318C20.1582 19.383 19.9286 19.568 19.726 19.7818C19.5146 20.0047 19.34 20.2614 19.2091 20.5418C19.0656 20.8997 18.985 21.2852 19 21.6718V22.0018H21V21.6618C20.9977 21.5493 21.0234 21.4375 21.0636 21.3328C21.1038 21.228 21.1639 21.1326 21.2403 21.0518C21.4073 20.864 21.5559 20.6993 21.7644 20.5618L22.4862 20.0518C22.7544 19.8658 22.9999 19.6476 23.2177 19.4018C23.483 19.1118 23.6895 18.7707 23.8252 18.3981C23.9608 18.0256 24.0228 17.6292 24.0077 17.2318C24.0111 16.7783 23.9351 16.3278 23.7834 15.9018Z"
      fill="#21201F"
    />
    <path
      d="M21.5 24.5C21.5 25.3284 20.8284 26 20 26C19.1716 26 18.5 25.3284 18.5 24.5C18.5 23.6716 19.1716 23 20 23C20.8284 23 21.5 23.6716 21.5 24.5Z"
      fill="#21201F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.9289 12.9289C14.8043 11.0536 17.3478 10 20 10C22.6522 10 25.1957 11.0536 27.0711 12.9289C28.9464 14.8043 30 17.3478 30 20C30 22.6522 28.9464 25.1957 27.0711 27.0711C25.1957 28.9464 22.6522 30 20 30C17.3478 30 14.8043 28.9464 12.9289 27.0711C11.0536 25.1957 10 22.6522 10 20C10 17.3478 11.0536 14.8043 12.9289 12.9289ZM20 12C17.8783 12 15.8434 12.8429 14.3431 14.3431C12.8429 15.8434 12 17.8783 12 20C12 22.1217 12.8429 24.1566 14.3431 25.6569C15.8434 27.1571 17.8783 28 20 28C22.1217 28 24.1566 27.1571 25.6569 25.6569C27.1571 24.1566 28 22.1217 28 20C28 17.8783 27.1571 15.8434 25.6569 14.3431C24.1566 12.8429 22.1217 12 20 12Z"
      fill="#21201F"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
