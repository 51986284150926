import { api } from '@/fsd/data/api/api.service';
import Item from '@/models/Item';
import ItemByBarcode from '@/models/ItemByBarcode';
import Product from '@/models/Product';
import ProductByBarcode from '@/models/ProductByBarcode';
import { useItems } from '@/store/modules/items';
import { useProducts } from '@/store/modules/products';
import { $gettext } from '@/temp/plugins/gettext';
import { useLoader } from '@/ui/common/loader/useLoader';

/**
 * Возвращает сущность/сущности по переданному ШК.
 * В качестве результата будет массив продуктов(у продуктов случаются коллизии ШК)/ посылка или null, если ничего не найдено.
 * Изначально пробуем получить данные из кеша и только если не удалось идем в апи.
 * @param {string} barcode
 * @returns {Promise<Product[] | Item | null>}
 */
export const getModelByBarcode = async (barcode: string): Promise<Product[] | Item | null> => {
  // Сперва идем в кеш
  const productFromCache = useProducts().getProductsByBarcodeLocal(barcode);
  if (productFromCache.length) return productFromCache;
  const itemFromCache = useItems().itemByBarcode(barcode);
  if (itemFromCache) return itemFromCache;
  // После пробуем сходить в апи
  return loadModelsFromApi(barcode);
};

/**
 * Ф-я для загрузки моделей(продуктов и/или посылок) из API по ШК
 *
 * @async
 * @param {string} barcode
 * @returns {Promise<Product[] | Item | null>}
 */
const loadModelsFromApi = async (barcode: string): Promise<Product[] | Item | null> => {
  const { showLoader } = useLoader();
  const { closeLoader } = showLoader($gettext('Запрашиваем продукт по штрихкоду'));
  try {
    const { data } = await api.barcode({ barcode });
    const founds = data.found.filter(item => item.type === 'product' || item.type === 'item');
    if (founds.length === 0) return null;
    const product_ids = founds.filter(ProductByBarcode.isProductByBarcode).map(p => p.product_id);
    if (product_ids.length > 0) {
      const products = await useProducts().getProductsByIds(product_ids);
      return products || null;
    }
    const item_ids = founds.filter(ItemByBarcode.isItemByBarcode).map(i => i.item_id);
    if (item_ids.length > 0) {
      const items = await useItems().getItemsByIds(item_ids);
      if (items) return items[0];
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  } finally {
    closeLoader();
  }
};

export const getProductsByBarcode = async (barcode: string): Promise<Product[]> => {
  //   Если в кеше нет, идем в апи
  const { showLoader } = useLoader();
  const { getProductsByBarcodeAsync } = useProducts();
  const { closeLoader } = showLoader($gettext('Запрашиваем продукт по штрихкоду'));
  try {
    return await getProductsByBarcodeAsync(barcode);
  } catch (e) {
    console.error(e);
    return [];
  } finally {
    closeLoader();
  }
};
