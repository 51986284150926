import { Modal } from '@/fsd/shared/tools/modalNotification';
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import { $gettext } from '@/temp/plugins/gettext';
import { AxiosError } from 'axios';

export default (e: AxiosError<any>) => {
  const { response } = e;

  let reason = '';
  let title = $gettext('Не удалось завершить задание');
  switch (true) {
    case !response:
      reason = $gettext('Что-то с сетью, попробуйте ещё раз');
      break;
    case response!.status === 423:
      title = $gettext('Закрываем документ — пожалуйста, подождите');
      reason = $gettext('Это может занять ещё немного времени, затем задание завершится');
      break;
    default:
      reason = `code: ${response!.status} \nmessage: ${response!.data?.message || ''}`;
      break;
  }
  return Modal.show({
    title,
    text: reason,
    btnPosition: ButtonPositionsEnum.horizontal,
    confirmBtnTitle: $gettext('Повторить'),
    closeBtnTitle: $gettext('Отмена'),
    confirmTimeout: 5,
    dataTest: 'error-retry-confirm',
  });
};
