import BaseOrder from '@/models/orders/BaseOrder';
import { BaseModel } from 'sjs-base-model';

export default class OrdersQueueEvent extends BaseModel {
  public type = 'order_queue_updated' as const;

  public queue: BaseOrder['order_id'][] = [];

  constructor(data: any) {
    super({ expand: true });
    this.update(data);
  }

  static isOrdersQueueEvent(event: { type: string }): event is OrdersQueueEvent {
    return event.type === 'order_queue_updated';
  }
}
