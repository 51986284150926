<template>
  <div
    class="flex justify-between py-4 mx-4 border-b border-slate-400"
    @click="$emit('click')"
  >
    <div class="flex flex-col">
      <Typography>
        {{ label }}
      </Typography>
      <Typography
        v-if="secondLabel"
        color="secondary-text"
      >
        {{ secondLabel }}
      </Typography>
    </div>

    <div class="flex items-center">
      <div class="flex flex-col items-end mr-4">
        <Typography>
          {{ value }}
        </Typography>
        <Typography
          v-if="secondValue"
          color="secondary-text"
        >
          {{ secondValue }}
        </Typography>
      </div>
      <Chevron
        v-if="$attrs.click"
        class="cursor-pointer"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Chevron from '@/temp/icons/icon-chevron-full.vue';
import Typography from '@/ui/common/typography.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Chevron,
    Typography,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    secondLabel: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      required: true,
    },
    secondValue: {
      type: String,
      default: '',
    },
  },
  emits: ['click'],
});
</script>
