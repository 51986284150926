<template>
  <div class="flex flex-col gap-3">
    <Tag :type="TagTypesEnum.disable">
      {{ $gettext('Упаковка посылки') }}
    </Tag>
    <RadioGroup
      v-model="typeRef"
      :items="TYPE_PACKAGE"
    />
    <Tag
      v-if="typeRef !== TypePackageTypeEnum.without_package"
      :type="TagTypesEnum.disable"
    >
      {{ $gettext('Размер упаковки') }}
    </Tag>
    <RadioGroup
      v-if="typeRef !== TypePackageTypeEnum.without_package"
      v-model="sizeRef"
      :items="SIZE_PACKAGE"
    />
  </div>
</template>

<script setup lang="ts">
import {
  TypePackageClassEnum,
  TypePackageSizeEnum,
  TypePackageTypeEnum,
} from '@/models/Item';
import RadioGroup from '@/temp/ui/inputs/radio-group/radio-group.vue';
import Tag, { TagTypesEnum } from '@/ui/common/tag/tag.vue';
import {
  getTypeAndSizeFromPackageClass, SIZE_PACKAGE, TYPE_PACKAGE,
} from '@/utils/packageClassItem';
import {
  defineEmits, defineProps, ref, watch,
} from 'vue';

const typeRef = ref<TypePackageTypeEnum>();
const sizeRef = ref<TypePackageSizeEnum>();
const packageClassRef = ref<TypePackageClassEnum>();

watch([typeRef, sizeRef], ([newType, newSize]) => {
  if (newType === TypePackageTypeEnum.without_package) {
    packageClassRef.value = TypePackageClassEnum.without_package;
    sizeRef.value = undefined;
  } else if (newType && newSize) {
    packageClassRef.value = `${newType}_${newSize}` as TypePackageClassEnum;
  } else {
    packageClassRef.value = undefined;
  }
});

watch(packageClassRef, (packageClass) => {
  emits('change', packageClass);
});

const props = defineProps<{ package_class?: TypePackageClassEnum }>();

const emits = defineEmits<{ change: [packageClass: TypePackageClassEnum | undefined] }>();

if (props.package_class) {
  const { type, size } = getTypeAndSizeFromPackageClass(props.package_class);

  typeRef.value = type;
  sizeRef.value = size;
}
</script>
