import ControlOrder, { ControlModeEnum } from '@/models/orders/ControlOrder';
import Product from '@/models/Product';
import Shelf from '@/models/Shelf';
import { getDefaultLocalFormat } from '@/temp/constants/dateFormat';
import { ValidTypeEnum } from '@/views/ProductCheck/types';
import dayjs from 'dayjs';

interface CalculateControlDateProps {
  mode: string;
  order: ControlOrder;
  product: Product;
  shelf: Shelf;
}

export const calculateControlDate = (option: CalculateControlDateProps) => {
  // предполагается, что это сегодняшняя дата ???? upd Это дата создания КСГ, Женя подтвердил, что берем именно ее, а не сегодня.
  let controlDate = dayjs.unix(option.order.created).tz();

  // для списания в треш с полки markdown используем завтрашнюю дату
  if (
    (option.order.vars.mode === ControlModeEnum.store2trash && option.shelf.type === 'markdown')
    || option.order.vars.mode === ControlModeEnum.markdown2trash
  ) {
    controlDate = controlDate.add(1, 'day');
  } else if (option.order.vars.mode === ControlModeEnum.eatToday2markdown && option.product.expired_count_sale) {
    // для КСГ Съешь сегодня используем специальное поле expired_count_sale
    controlDate = controlDate.add(option.product.expired_count_sale + 1, 'day');
  } else {
    // для всех остальных случаев это write_off_before + 1 день(КСГ проводится вечером!)
    controlDate = controlDate.add(option.product.write_off_before + 1, 'day');
  }

  // адаптируем для даты производства. вычитая срок хранения продукта.
  if (option.mode === ValidTypeEnum.productionDate) {
    controlDate = controlDate.subtract(option.product.valid || 0, 'day');
  }

  return controlDate.format(getDefaultLocalFormat());
};

// ОСХ- остаточный срок хранения. через сколько товар перестанет быть годен. (дней до истечения срока годности)
// КСГ проводится вечером, поэтому везде +1 день
//
// для режима store2trash - списываем когда ОСХ меньше или равен write_off_before продукта + 1 день
// исключение - полка markdown, с нее списываем только когда ОСХ равен одному дня.
//
// для режима store2markdown перемещаем те товары, что имеют ОСХ меньше чем write_off_before + 1 день  на полку markdown
//
// для режима store2review перемещаем те товары, что имеют ОСХ меньше чем write_off_before + 1 день  на полка review
//
// для режима eatToday2markdown перемещаем те товары, что имеют ОСХ меньше чем write_off_before + 1 день  на полку markdown
