import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import { DocTypes } from '@/temp/constants/translations/types';
import { $gettext, gettextWrapper } from '@/temp/plugins/gettext';
import { ValidTypeEnum } from '@/views/ProductCheck/types';

export const inputValidTitles = gettextWrapper({
  default: $gettext('Срок годности'),
  acceptance: {
    productionDate: $gettext('Дата производства'),
    expirationDate: $gettext('Срок годности'),
  },
  sale_stowage: {
    productionDate: $gettext('Дата производства'),
    expirationDate: $gettext('Срок годности'),
  },
  check_valid_short: $gettext('Дата для проверки'),
  check_valid_regular: $gettext('Дата для проверки'),
  writeoff_prepare_day: $gettext('Дата для проверки'),
});

export const inputValidTitlesDone = gettextWrapper({
  default: $gettext('Срок годности'),
  acceptance: {
    productionDate: $gettext('Дата производства'),
    expirationDate: $gettext('Срок годности'),
  },
  sale_stowage: {
    productionDate: $gettext('Дата производства'),
    expirationDate: $gettext('Срок годности'),
  },
  check_valid_short: $gettext('Новая дата производства'),
  check_valid_regular: $gettext('Новая дата производства'),
  writeoff_prepare_day: $gettext('Новая дата производства'),
});

interface Option {
  mode?: ValidTypeEnum;
  docType: DocTypes;
  suggest?: Suggest;
}

export const getValidTitle = ({
  docType, mode, suggest,
}: Option) => {
  const result
    = suggest?.status === SuggestStatusEnum.done
      ? inputValidTitlesDone[docType]
      : inputValidTitles[docType];
  if (typeof result === 'object') {
    return result[mode!] || inputValidTitles.default;
  }
  return result || inputValidTitles.default;
};
