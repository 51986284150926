import { Component, Raw } from 'vue';

export enum ButtonPositionsEnum {
  one_button = 'one_button',
  horizontal = 'horizontal',
  vertical = 'vertical',
}

export interface InfoItem {
  title: string;
  subtitle: string;
}
export interface UniversalModalProps {
  title: string;
  subtitle?: string;
  text?: string;
  infoBlock?: InfoItem[];
  infoBlockTitle?: string;
  btnPosition?: ButtonPositionsEnum;
  confirmBtnTitle?: string;
  closeBtnTitle?: string;
  confirmTimeout?: number;
  dataTest?: string;
}

export type NotificationsUniversalModalProps = UniversalModalProps & {
  onClose?: () => boolean | void;
  onConfirm?: () => boolean | void;
  component?: Raw<Component>;
};
