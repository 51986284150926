import { Found } from '@/services/response';
import { BaseModel } from 'sjs-base-model';

export default class UserByBarcode extends BaseModel {
  public type: string = 'user';
  public user_id: string = '';
  public title: string = '';

  constructor(data: any) {
    super({ expand: true });
    this.update(data);
  }

  static isUserByBarcode(found: Found): found is UserByBarcode {
    return found.type === 'user';
  }
}
