// Набор утилит для распаковки баркода

const isEven = num => num % 2 === 0;

/**
 * Ф-я возвращает очищенный ШК без веса
 *
 * @param {string} barcode
 * @returns {(string | undefined)}
 */
export const getBarcodeWithoutWeight = (barcode: string): string | undefined => {
  const canBeValid = isCanBeValidProductBarcode(barcode);
  if (!canBeValid) return undefined;
  const barcodeWithoutControlNumber = barcode.slice(0, -1);
  const barcodeWithClearWeight = barcode.slice(0, -5) + '00000';
  return barcodeWithClearWeight + getControlNumber(barcodeWithoutControlNumber);
};

/**
 * Ф-я проверяет может ли ШК быть продуктом
 *
 * @param {string} barcode
 * @returns {boolean}
 */
export const isCanBeValidProductBarcode = (barcode: string): boolean => {
  const validLength = barcode.length === 13 || barcode.length === 18;
  const validNumber = !isNaN(+barcode);
  const validControlNumber = getControlNumber(barcode.slice(0, -1)) === +barcode[barcode.length - 1];
  return validLength && validNumber && validControlNumber;
};

/**
 * Ф-я для получения контрольной цифры ШК
 *
 * @param {string} barcode
 * @returns {(number | false)}
 */
export const getControlNumber = (barcode: string): number | false => {
  // Цифры набора нумеруются справа налево.
  // Подсчитываются суммы цифр, стоящих на четных и нечетных местах.
  // Сумма цифр, стоящих на четных местах, суммируется с утроенной суммой цифр, стоящих на нечетных местах.
  // Если цифра единиц полученного результата равна нулю, то контрольная цифра — 0.
  // Если последняя цифра результата не нуль, то контрольная цифра равна дополнению этой цифры до 10.

  const sum = barcode.split('').reduce((acc, cur, index) => acc + (isEven(index) ? +cur : +cur * 3), 0);
  const last = sum % 10;
  return (10 - last) % 10;
};

/**
 * Ф-я извлекает вес из продукта
 *
 * @param {string} barcode
 * @returns {(number | false)}
 */
export const getWeightFromBarcode = (barcode: string): number | false => {
  if (barcode.length !== 13 && barcode.length !== 18) {
    return false;
  }
  // Если полка в обычной стране, то баркод 13-символьный, 8-12 вес, 13 контрольный
  // Если полка в Израиле, то баркод 18-символьный, 13-17 вес, 18 контрольный
  return +barcode.slice(-6, -1);
};
