<template>
  <div v-if="activeStep === 'date'">
    <Hint class="my-2 mx-4">
      {{ hintText }}
    </Hint>
    <Keyboard
      v-if="needShowKeyBoard"
      type="date"
      :initial-value="defaultValue"
      :min="minDate"
      :max="maxDate"
      :placeholder="$gettext('ДД.ММ.ГГ')"
      :is-open="false"
      @input="value => onInputDateConfirm({ value })"
    />
    <UiInput
      v-else
      class="date-container"
      type="date"
      :label="label"
      :value="defaultValue"
      :min="minDate"
      :max="maxDate"
      @confirm="onInputDateConfirm"
    />
  </div>
</template>
<script lang="ts">
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import BaseOrder, { OrderTypeEnum } from '@/models/orders/BaseOrder';
import Product from '@/models/Product';
import Suggest from '@/models/Suggest';
import { ModeService } from '@/services/mode.service';
import { ScannerService } from '@/services/scanner/scanner.service';
import { useProducts } from '@/store/modules/products';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { defaultSourceFormat } from '@/temp/constants/dateFormat';
import { logger } from '@/temp/plugins/logs';
import Hint from '@/ui/common/hint/hint.vue';
import UiInput from '@/ui/common/input/input.vue';
import Keyboard from '@/ui/common/keyboard/keyboard.vue';
import { getFormatDate } from '@/utils';
import { ValidTypeEnum } from '@/views/ProductCheck/types';
import dayjs from 'dayjs';
import { defineComponent, PropType } from 'vue';

interface Data {
  model: {
    invalidDate: string;
  };
}

export default defineComponent({
  components: {
    Hint,
    Keyboard,
    UiInput,
  },
  props: {
    activeStep: {
      type: String,
      required: true,
    },
    order: {
      type: Object as PropType<BaseOrder>,
      required: true,
    },
    suggest: {
      type: Object as PropType<Suggest>,
      required: true,
    },
    productId: {
      type: String,
      default: undefined,
    },
    mode: {
      type: String as PropType<ValidTypeEnum>,
      default: ValidTypeEnum.expirationDate,
    },
    initialValue: {
      type: String,
      default: undefined,
    },
  },
  emits: ['confirmDate'],
  setup() {
    const productsStore = useProducts();
    const userStore = useUser();

    return {
      productsStore,
      userStore,
    };
  },
  data(): Data {
    return {
      model: {
        invalidDate: '',
      },
    };
  },
  computed: {
    needShowKeyBoard(): boolean {
      return (
        ScannerService.mode === 'dialog'
        || ModeService.isDevelopment()
        || this.userStore.experimentByName(experiments['exp_augusta-ada'])
      );
    },
    confirmTitle(): string {
      return this.$gettext('Вы подтверждаете введенную дату - %{newDate}?', {
        newDate: getFormatDate(this.model.invalidDate, {
          product: this.product,
          mode: this.mode as any,
        }),
      });
    },
    confirmText(): string {
      return this.$gettext('Максимально возможная дата - %{calcDate}', {
        calcDate: getFormatDate(this.maxValidDate, {
          product: this.product,
          mode: this.mode as any,
        }),
      });
    },
    label(): string {
      if (this.mode === ValidTypeEnum.expirationDate) {
        return this.$gettext('Новый срок годности');
      }
      if (this.mode === ValidTypeEnum.productionDate) {
        return this.$gettext('Новая дата изготовления');
      }
      return '';
    },
    product(): Product | undefined {
      if (this.suggest.product_id) {
        return this.productsStore.productById(this.suggest.product_id);
      }
      if (this.productId) {
        return this.productsStore.productById(this.productId);
      }
      return undefined;
    },
    isControl(): boolean {
      return [
        OrderTypeEnum.writeoff_prepare_day,
        OrderTypeEnum.check_valid_regular,
        OrderTypeEnum.check_valid_short,
      ].includes(this.order.type);
    },
    minDate(): string | undefined {
      switch (this.order.type) {
        case OrderTypeEnum.check_valid_regular:
        case OrderTypeEnum.check_valid_short:
        case OrderTypeEnum.writeoff_prepare_day:
        case OrderTypeEnum.check_more:
        case OrderTypeEnum.inventory_check_more:
        case OrderTypeEnum.check_product_on_shelf:
        case OrderTypeEnum.sale_stowage:
          if (this.mode === ValidTypeEnum.expirationDate) {
            return dayjs().format(defaultSourceFormat);
          }
          return undefined;
        default:
          return undefined;
      }
    },
    maxDate(): string | undefined {
      if (this.mode === ValidTypeEnum.productionDate) {
        return dayjs().format(defaultSourceFormat);
      }
      return undefined;
    },
    hintText(): string {
      const hintTexts = {
        default: {
          productionDate: this.$gettext('Какая дата изготовления у этого товара?'),
          expirationDate: this.$gettext('Какой самый ранний срок годности у этого товара?'),
        },
        control: {
          productionDate: this.$gettext('Какая дата изготовления у этого товара?'),
          expirationDate: this.$gettext('Какой минимальный срок годности у товара на полке?'),
        },
        check_product_on_shelf: {
          productionDate: this.$gettext('Какая дата изготовления у этого товара?'),
          expirationDate: this.$gettext('Какой самый ранний срок годности у этого товара?'),
        },
        check_more: this.$gettext('Какой самый ранний срок годности у этого товара?'),
      };
      if (
        [
          OrderTypeEnum.writeoff_prepare_day,
          OrderTypeEnum.check_valid_regular,
          OrderTypeEnum.check_valid_short,
        ].includes(this.order.type)
      ) {
        return hintTexts['control'][this.mode];
      }
      if ([OrderTypeEnum.check_more, OrderTypeEnum.inventory_check_more].includes(this.order.type)) {
        return hintTexts['check_more'];
      }
      if (hintTexts[this.order.type] && hintTexts[this.order.type][this.mode]) {
        return hintTexts[this.order.type][this.mode];
      }
      return hintTexts[this.order.type] || hintTexts['default'][this.mode];
    },
    maxValidDate(): string {
      const maxValidDate = dayjs();
      if (this.product) {
        return getFormatDate(maxValidDate.format(defaultSourceFormat), {
          add: {
            value: this.product.valid ?? 0,
            unit: 'day',
          },
          targetFormat: defaultSourceFormat,
        });
      }
      return maxValidDate.format(defaultSourceFormat);
    },
    defaultValue(): string | undefined {
      if (!this.initialValue) return;

      return getFormatDate(this.initialValue, {
        mode: this.mode as any,
        product: this.product,
        targetFormat: defaultSourceFormat,
      });
    },
  },
  methods: {
    async onInputDateConfirm({ value }): Promise<void> {
      const date = this.calculateDate(value);
      if (this.validateDate(date)) {
        this.$emit('confirmDate', date);
      } else {
        this.model.invalidDate = date;
        const confirmed = await this.$modal.show({
          title: this.confirmTitle,
          text: this.confirmText,
          btnPosition: ButtonPositionsEnum.horizontal,
        });
        if (confirmed) {
          this.sendMessage();
          this.$emit('confirmDate', this.model.invalidDate);
        }
      }
    },
    calculateDate(value: string): string {
      if (this.mode === ValidTypeEnum.productionDate && this.product) {
        return getFormatDate(value, {
          add: {
            value: this.product.valid ?? 0,
            unit: 'day',
          },
          targetFormat: defaultSourceFormat,
        });
      }
      return value;
    },
    validateDate(date): boolean {
      const newDate = dayjs(date, defaultSourceFormat);
      const maxValidDate = dayjs(this.maxValidDate, defaultSourceFormat);
      return newDate.isBefore(maxValidDate) || newDate.isSame(maxValidDate);
    },
    sendMessage(): void {
      logger.event('WRONG_DATE', 'true', {
        additional: {
          external_id: this.product!.external_id,
          valid: this.model.invalidDate,
          calcValid: this.maxValidDate,
        },
      });
    },
  },
});
</script>
<style scoped>
.date-container {
  margin: 16px 16px 8px;
  width: auto;
}
</style>
