<template>
  <div
    class="flex mt-2"
    :class="{ 'is-rtl': isRTL }"
    :data-test="dataTest"
  >
    <Body3
      data-test="suggest row label"
      class="flex-1"
      color="warmGray-600"
    >
      {{ label }}
    </Body3>
    <div class="ml-2 flex-1 flex flex-col">
      <Body3
        data-test="suggest row value"
        color="warmGray-600"
      >
        {{ value[0] }}
      </Body3>
      <Body3
        class="py-2"
        color="day-textMinor"
      >
        {{ $gettext('из') }}
      </Body3>
      <Body3
        data-test="suggest row value"
        color="warmGray-600"
      >
        {{ value[1] }}
      </Body3>
    </div>
  </div>
</template>

<script lang="ts">
import { useUser } from '@/store/modules/user';
import Body3 from '@/ui/common/typo/body-3.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: { Body3 },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    dataTest: {
      type: String,
      default: '',
    },
  },
  setup() {
    const userStore = useUser();

    return { userStore };
  },
  computed: {
    isRTL(): boolean {
      return this.userStore.isRTL;
    },
  },
});
</script>
