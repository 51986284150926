import {
  TypePackageClassEnum,
  TypePackageSizeEnum,
  TypePackageTypeEnum,
} from '@/models/Item';
import { $gettext } from '@/temp/plugins/gettext';

export const PACKAGE_CLASSES: Record<TypePackageClassEnum, string> = {
  [TypePackageClassEnum.box_l]: $gettext('Коробка большая'),
  [TypePackageClassEnum.box_m]: $gettext('Коробка средняя'),
  [TypePackageClassEnum.box_s]: $gettext('Коробка маленькая'),
  [TypePackageClassEnum.packet_l]: $gettext('Пакет большой'),
  [TypePackageClassEnum.packet_m]: $gettext('Пакет средний'),
  [TypePackageClassEnum.packet_s]: $gettext('Пакет маленький'),
  [TypePackageClassEnum.without_package]: $gettext('Без упаковки'),
};

export const TYPE_PACKAGE: Record<TypePackageTypeEnum, string> = {
  [TypePackageTypeEnum.box]: $gettext('Коробка'),
  [TypePackageTypeEnum.packet]: $gettext('Пакет'),
  [TypePackageTypeEnum.without_package]: $gettext('Без упаковки'),
};

export const SIZE_PACKAGE: Record<TypePackageSizeEnum, string> = {
  [TypePackageSizeEnum.l]: $gettext('Большой'),
  [TypePackageSizeEnum.m]: $gettext('Средний'),
  [TypePackageSizeEnum.s]: $gettext('Маленький'),
};

interface TypeAndSizeFromPackageClass {
  type: TypePackageTypeEnum;
  size?: TypePackageSizeEnum;
}

export const getTypeAndSizeFromPackageClass = (
  value: TypePackageClassEnum,
): TypeAndSizeFromPackageClass => {
  if (value === TypePackageClassEnum.without_package) {
    return {
      type: TypePackageTypeEnum.without_package,
      size: undefined,
    };
  }
  const [type, size] = value.split('_');

  return {
    type,
    size,
  } as TypeAndSizeFromPackageClass;
};
