import { api } from '@/fsd/data/api/api.service';
import { SherlockStatusEnum } from '@/fsd/widgets/order/RequiredCard/sherlock/types';
import Suggest, {
  SuggestStatusEnum,
  SuggestTagEnum,
  SuggestTypeEnum,
} from '@/models/Suggest';
import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';
import OrderOrder from '@/models/orders/OrderOrder';
import { SignalEnum } from '@/services/requests';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useUser } from '@/store/modules/user';
import { availableForSherlock, experiments } from '@/temp/constants';
import { logger } from '@/temp/plugins/logs';
import { useLoader } from '@/ui/common/loader/useLoader';
import { isEqual } from 'lodash';
import { computed, ref } from 'vue';

export const useSherlock = (order_id: OrderOrder['order_id'], suggest: Suggest | TrueMarkSuggestWrapper) => {
  const { showLoader } = useLoader();
  const sherlockStatus = ref<SherlockStatusEnum>(SherlockStatusEnum.maybe);

  const order = computed(() => {
    return useOrders().orderById(order_id);
  });

  const checkSherlockMustSkip = (): boolean => {
    if (!suggest || !order.value) return true;
    // эксп отключен, шерлока не будет
    if (!useUser().experimentByName(experiments.exp_sherlock)) return true;
    // это не саджест на взять с полки
    if (suggest.type !== SuggestTypeEnum.shelf2box) return true;
    // саджест уже выполнен, нет смысла проверять
    if (suggest.status !== SuggestStatusEnum.request) return true;
    // это посылка, не проверяем
    if (suggest?.isItemSuggest) return true;
    // саджест на кухню, шерлок не нужен
    if (suggest.conditions.tags?.includes(SuggestTagEnum.kitchen)) return true;
    // уже есть сигнал по этому саджесту
    if (
      order.value.signals?.find(s =>
        TrueMarkSuggestWrapper.isTrueMarkSuggestWrapper(suggest)
          ? isEqual(s.data?.suggest_id, suggest.suggestsIds)
          : s.data?.suggest_id === suggest.suggest_id,
      )
    ) {
      return true;
    }

    return sherlockStatus.value === SherlockStatusEnum.skip;
  };

  const checkNeedSherlock = async (): Promise<boolean> => {
    if (checkSherlockMustSkip()) return false;
    if (!suggest) return false;
    try {
      // спросить о количестве остатков, если available<count
      const available = await useProducts().fetchAvailable(suggest.product_id);
      const availableOnShelf = available?.find(item => item.shelf_id === suggest!.shelf_id);
      const sherlockCount = useUser().sherlockBorder || availableForSherlock;
      // спрашиваем остатки только если остатки есть, остатков больше чем минимальное кол-во для запроса и после выполнения саджеста остатков станет меньше
      if (
        availableOnShelf
        && availableOnShelf.count > sherlockCount
        && availableOnShelf.count - suggest.count! <= sherlockCount
      ) {
        sherlockStatus.value = SherlockStatusEnum.need;
        return true;
      } else {
        sherlockStatus.value = SherlockStatusEnum.skip;
        return false;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const sendSherlockSignal = async (): Promise<void> => {
    const { closeLoader } = showLoader();
    try {
      const ids = TrueMarkSuggestWrapper.isTrueMarkSuggestWrapper(suggest) ? suggest.suggestsIds : suggest.suggest_id;

      await api.order.signal({
        order_id,
        signal: SignalEnum.shortfall,
        data: {
          suggest_id: ids,
          source: 'sherlock',
        },
      });
      closeLoader();
    } catch (e) {
      closeLoader();
      logger.error(e, {
        method: 'signal',
        type: 'api',
        source: 'product-card.vue',
      });
    }
  };

  sherlockStatus.value = checkSherlockMustSkip()
    ? SherlockStatusEnum.skip
    : SherlockStatusEnum.maybe;

  return {
    checkSherlockMustSkip,
    checkNeedSherlock,
    sherlockStatus,
    sendSherlockSignal,
  };
};
