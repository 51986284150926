<template>
  <ActionWrapper
    position="center"
    @blur-click="emits('btn-click')"
  >
    <div
      class="p-4 flex flex-col"
      data-test="request-shelf modal"
    >
      <div class="pb-4 flex items-center border-b-[0.5px] border-day-line">
        <Body1 class="font-medium">
          {{ options?.text||$gettext('Сканируй полку на которой лежит товар') }}
        </Body1>
      </div>

      <div class="pt-4 text-center mx-auto">
        <IconBarcode />
      </div>
    </div>
  </ActionWrapper>
</template>

<script setup lang="ts">
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { BarcodeRequester } from '@/hooks/useRequestBarcode';
import Shelf from '@/models/Shelf';
import { useShelves } from '@/store/modules/shelves';
import IconBarcode from '@/temp/icons/icon-barcode.vue';
import { $gettext } from '@/temp/plugins/gettext';
import { useLoader } from '@/ui/common/loader/useLoader';
import ActionWrapper from '@/ui/common/notifications/actionWrapper.vue';
import Body1 from '@/ui/common/typo/body-1.vue';
import { defineEmits, defineProps } from 'vue';
import { RequestShelfModalOptions } from './types';

const { requester, options } = defineProps<{
  requester?: BarcodeRequester;
  options: RequestShelfModalOptions;
}>();

const emits = defineEmits<{ (e: 'btn-click', shelf?: Shelf): void }>();

const { showLoader } = useLoader();

const requestShelf = async () => {
  if (!requester) return;
  const barcode = await requester();
  const { closeLoader } = showLoader($gettext('Ищем полку по ШК'));
  try {
    const shelf = await useShelves().getShelfByBarcode(barcode);
    emits('btn-click', shelf);
  } catch (error) {
    console.error(error);
    Alerts.error($gettext('Не найден штрихкод'));
    requestShelf();
  } finally {
    closeLoader();
  }
};

if (requester) {
  requestShelf();
}

</script>
