export interface AvailableProduct {
  shelf_id: string;
  product_id: string;
  shelf_type: string;
  count: number;
  reserved: number;
  quants: number;
  valid: string;
  is_components: boolean;
}

export interface ProductInRequired {
  product_id: string;
  // Идентификатор продукта
  count?: number;
  // Количество
  result_count?: number;
  // Итоговое количество
  shelf_id?: string;
  // Идентификатор полки
  update_valids?: boolean;
  // флаг, который показывает, что изменение СГ будет применено ко всем остаткам
}

export const isProductInRequired = (product): product is ProductInRequired => product.product_id;
