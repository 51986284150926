<template>
  <ClientOrderSuggestCardContainer :data-test="`product card ${index}`">
    <div
      class="rounded-3xl h-full w-full flex flex-col overflow-hidden gap-2"
      :class="{
        'border-2 border-red-normal':
          suggest?.type === SuggestTypeEnum.box2shelf &&
          suggest?.status === SuggestStatusEnum.request,
      }"
    >
      <!--Шапка-->
      <div class="flex px-6 pt-6">
        <OrderProductCardShelf :suggest="suggest" />
        <OrderProductCardCount
          :index="index"
          :collected="collected.count ?? 0"
          :suggest="suggest"
          :problem="problem"
        />
      </div>
      <!--Картинка-->
      <div class="flex-1 shrink-1" />
      <div class="relative justify-center h-full px-6">
        <div class="w-full h-full">
          <ImageWrapper
            :img-src="suggest.imageSrc"
            :grayscale="isBox2Shelf"
          >
            <div class="top-1/2 -translate-y-2/4 z-10 w-full absolute justify-center items-center flex flex-col">
              <StatusBadge
                v-if="isCompleted"
                class="-rotate-12"
                :type="StatusTypeEnum.complete"
                data-test="order suggest-completed badge"
              >
                {{ order.isCanceled ? $gettext('Готово') : $gettext('Собран') }}
              </StatusBadge>

              <StatusBadge
                v-if="order.isOrderPaused || (isProblem && !order.isOrderPaused)"
                :type="StatusTypeEnum.waiting"
                class="-rotate-12"
                data-test="order product-card pause-badge"
              >
                {{ $gettext('Ждём решение') }}
              </StatusBadge>

              <StatusBadge
                v-if="isBox2Shelf && suggest?.status === SuggestStatusEnum.request && suggest.shelf?.type !== ShelfTypeEnum.trash"
                :type="StatusTypeEnum.error"
                class="-rotate-12"
                data-test="order product-card pause-badge"
              >
                {{ $gettext('Вернуть на полку') }}
              </StatusBadge>

              <StatusBadge
                v-if="suggest.isBlocked && suggest.isKitchen"
                :type="StatusTypeEnum.waiting"
                class="-rotate-12"
                data-test="product block badge"
              >
                {{ $gettext('Ещё готовится') }}
              </StatusBadge>

              <StatusBadge
                v-if="suggest.isCanceled"
                :type="StatusTypeEnum.error"
                class="-rotate-12"
                data-test="product canceled badge"
              >
                {{ $gettext('Отменён') }}
              </StatusBadge>

              <StatusBadge
                v-if="isRobozone && !order.canCollectRobozone"
                :type="StatusTypeEnum.processing"
                class="w-[310px] h-auto text-center"
                data-test="product robozone-block badge"
              >
                {{ $gettext('Сначала соберите товары с обычных стеллажей') }}
              </StatusBadge>
              <StatusBadge
                v-if="isRobozone && order.canCollectRobozone && suggest.isBlocked"
                :type="StatusTypeEnum.processing"
                class="w-[310px] h-auto text-center"
                data-test="product robozone-block badge"
              >
                {{ $gettext('Роботы везут этот товар') }}
              </StatusBadge>
            </div>
          </ImageWrapper>
        </div>
      </div>
      <div class="flex-1 shrink-1" />
      <!--Тэги-->
      <div class="flex flex-wrap shrink-0 gap-1 px-6 py-3">
        <PackageClassBadge
          v-if="item?.packageClass"
          :package_class="item.packageClass"
        />
        <UltimaBadge v-if="item?.isUltimaParcel" />
        <FragileBadge v-if="product?.fragile || item?.isUltimaParcel" />
        <SpecialPackageBadge v-if="product?.special_package" />
        <WeightBadge
          v-if="product?.isTrueWeight"
          :plu_code="product.plu_code"
        />
        <template v-if="isTrueMark">
          <ASLBelgisiBadge v-if="countryCode === 'UZB'" />
          <TrueMarkBadge v-else />
        </template>
        <OverweightBadge v-if="isBlockedOverweight" />
        <RobozoneBadge v-if="isRobozone" />
      </div>
      <!--Название-->
      <Body1
        color="text-primary"
        class="font-bold px-6 pb-6"
        data-test="product title"
      >
        {{ title }}
      </Body1>
    </div>

    <template #footer>
      <LayoutFooter v-if="needCollectButton">
        <UiButton
          :is-disabled="isDisabled"
          background-color="secondary"
          data-test="collect low_weight"
          @click="collect"
        >
          <template #icon>
            <Check />
          </template>
          {{ $gettext('Собрано') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </ClientOrderSuggestCardContainer>
</template>

<script lang="ts">
import {
  ASLBelgisiBadge,
  FragileBadge,
  OverweightBadge,
  PackageClassBadge,
  RobozoneBadge,
  SpecialPackageBadge,
  StatusBadge,
  StatusTypeEnum,
  TrueMarkBadge,
  UltimaBadge,
  WeightBadge,
} from '@/fsd/entities/badges';
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import ClientOrderSuggestCardContainer from '@/fsd/widgets/order/RequiredCard/ClientOrderSuggestCardContainer.vue';
import OrderProductCardShelf from '@/fsd/widgets/order/RequiredCard/OrderProductCardShelf.vue';
import OrderProductCardCount from '@/fsd/widgets/order/RequiredCard/order-product-card-count.vue';
import Item from '@/models/Item';
import Product from '@/models/Product';
import { ShelfTypeEnum } from '@/models/Shelf';
import Suggest, { SuggestStatusEnum, SuggestTypeEnum } from '@/models/Suggest';
import { Problem } from '@/models/orders/BaseOrder';
import OrderOrder from '@/models/orders/OrderOrder';
import { useItems } from '@/store/modules/items';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useUser } from '@/store/modules/user';
import Check from '@/temp/icons/check.vue';
import ImageWrapper from '@/ui/common/image-wrapper.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import Body1 from '@/ui/common/typo/body-1.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { Collected } from '@/views/Order/types';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  name: 'ClientOrderSuggestCardClassic',
  components: {
    OverweightBadge,
    ClientOrderSuggestCardContainer,
    OrderProductCardCount,
    OrderProductCardShelf,
    UltimaBadge,
    SpecialPackageBadge,
    WeightBadge,
    TrueMarkBadge,
    ASLBelgisiBadge,
    Body1,
    FragileBadge,
    PackageClassBadge,
    ImageWrapper,
    LayoutFooter,
    UiButton,
    Check,
    StatusBadge,
    RobozoneBadge,
  },
  props: {
    order_id: {
      type: String,
      required: true,
    },
    problem: {
      type: Object as PropType<Problem>,
      default: undefined,
    },
    suggest: {
      type: Object as PropType<Suggest>,
      default: () => {
        return {} as Suggest;
      },
    },
    collected: {
      type: Object as PropType<Collected>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  emits: ['finish'],
  setup() {
    const { showLoader } = useLoader();
    const itemsStore = useItems();
    const productsStore = useProducts();
    const ordersStore = useOrders();
    const userStore = useUser();

    return {
      showLoader,
      itemsStore,
      productsStore,
      userStore,
      ordersStore,
      SuggestStatusEnum,
      SuggestTypeEnum,
      ShelfTypeEnum,
      StatusTypeEnum,
    };
  },
  computed: {
    order(): OrderOrder {
      return this.ordersStore.orderById(this.order_id) as OrderOrder;
    },
    title(): string {
      if (!this.suggest) {
        return this.product?.title || '';
      }
      if (this.suggest.vars?.mode === 'item') {
        return this.item?.title || '';
      } else {
        return this.product?.title || '';
      }
    },
    isCompleted(): boolean {
      return (
        !this.isProblem
        && (this.suggest.status === SuggestStatusEnum.done
          || this.suggest.count === this.collected.count)
      );
    },
    isBox2Shelf(): boolean {
      return this.suggest.type === SuggestTypeEnum.box2shelf;
    },
    product(): Product | undefined {
      return this.productsStore.productById(this.suggest.product_id);
    },
    item(): Item | undefined {
      return this.itemsStore.itemById(this.suggest.product_id);
    },
    isProblem(): boolean {
      return !!this.problem;
    },
    isTrueMark(): boolean {
      return this.suggest?.conditions?.need_true_mark;
    },
    isBlockedOverweight(): boolean {
      return this.suggest?.isBlockedOverweight;
    },
    isDisabled(): boolean {
      return !this.collected.count;
    },
    needCollectButton(): boolean | undefined {
      return (
        this.suggest.product
        && this.suggest.product.isTrueWeight
        && this.suggest.status !== SuggestStatusEnum.done
        && this.suggest.type === SuggestTypeEnum.shelf2box
      );
    },
    /**
     * True, если suggest на сборку из робозоны
     */
    isRobozone(): boolean {
      return this.suggest?.isRobozone;
    },
    countryCode() {
      return useUser().countryCode;
    },
  },
  mounted() {
    if (!this.title && this.suggest) {
      this.suggest.loadProduct();
    }
  },
  methods: {
    finishSuggest() {
      this.$emit('finish');
    },
    async collect(): Promise<void> {
      const confirm = await this.$modal.show({
        title: this.$gettext('Весь товар собран?'),
        text: this.$gettext('Убедитесь, что этого товара больше нет.'),
        btnPosition: ButtonPositionsEnum.horizontal,
        confirmBtnTitle: this.$gettext('Да, весь'),
        closeBtnTitle: this.$gettext('Отмена'),
      });
      if (!confirm) return;
      this.finishSuggest();
    },
  },
});
</script>
