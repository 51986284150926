<template>
  <div
    v-if="activeStep === 'weight'"
    class="p-4"
  >
    <Hint class="mb-2 mt-4">
      {{ hintText }}
    </Hint>
    <UiButton
      :is-disabled="weights.length === 0"
      data-test="suggest-details weight-field finish-btn"
      @click="sendScannedWeight"
    >
      <template v-if="!hasNextStep">
        {{ $gettext('Завершить') }}
      </template>
      <template v-else>
        {{ $gettext('Далее') }}
      </template>
    </UiButton>
  </div>
</template>
<script setup lang="ts">
import { api } from '@/fsd/data/api/api.service';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import BaseOrder from '@/models/orders/BaseOrder';
import Product, { TypeAccountingEnum } from '@/models/Product';
import WeightStowageOrder from '@/models/orders/WeightStowageOrder';
import ProductByBarcode from '@/models/ProductByBarcode';
import Suggest from '@/models/Suggest';
import { AudioService } from '@/services/audio.service';
import productQueue from '@/services/queue/product-queue';
import { useProducts } from '@/store/modules/products';
import { $gettext } from '@/temp/plugins/gettext';
import Hint from '@/ui/common/hint/hint.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import UiButton from '@/ui/common/ui-button.vue';
import {
  computed, defineEmits, defineProps, ref, withDefaults,
} from 'vue';

const { showLoader } = useLoader();
const productsStore = useProducts();

interface WeightFieldProps {
  activeStep: string;
  order: BaseOrder;
  suggest: Suggest;
  weights?: number[];
  productId?: string;
  hasNextStep?: boolean;
}

const props = withDefaults(defineProps<WeightFieldProps>(), {
  weights: () => [],
  productId: undefined,
});

const emits = defineEmits<{
  (e: 'scanWeightProduct', weight: number): void;
  (e: 'confirmInput', data: {
    weight: number;
    count: number;
  }): void;
}>();

const weightsRef = ref<number[]>(props.weights);

const product = computed<Product | undefined>(() => productsStore.productById(props.productId));

const isBanana = computed<boolean>(() => Boolean((props.order as WeightStowageOrder).vars?.scan_unit_child_product));

const hintText = computed<string>(() => {
  if (props.suggest.conditions.weight_aggregate && product.value && product.value.lower_weight_limit) {
    return $gettext('Сканируйте товары с весом от %{lower} г до %{upper} г', {
      lower: String(product.value.lower_weight_limit),
      upper: String(product.value.upper_weight_limit),
    });
  }
  return $gettext('Сканируйте принимаемый товар');
});

const { needBarcodeRequest } = useRequestBarcode(async (barcode) => {
  function productsFind(products, product_id, link_to_id) {
    return products.find((product) => {
      return product_id === product[link_to_id];
    });
  }

  const { closeLoader } = showLoader();
  try {
    const { data } = await api.barcode({ barcode });
    const weight = Product.weightFromBarcode(barcode);

    const found_ids = data.found.filter(ProductByBarcode.isProductByBarcode).map(p => p.product_id);
    const founds = await productsStore.getProductsByIds(found_ids);

    // @ts-expect-error pinia. founds был any
    const products = founds.filter((p) => {
      return props.suggest.product_id === p.parent_id || props.suggest.product_id === p.product_id;
    });

    if (!products) {
      Alerts.error($gettext('Отсканирован неверный штрихкод %{barcode}', { barcode }));
      AudioService.playError();
      closeLoader();
      return true;
    }

    let needProduct;
    let hasUnitChild;

    if (props.suggest.product?.children_id?.length) {
      const children = (await productQueue.loadMany(props.suggest.product.children_id)).filter(Product.isProduct);
      hasUnitChild = children.some(child => child.type_accounting === TypeAccountingEnum.unit);
    }

    closeLoader();

    switch (true) {
      case isBanana.value:
      case hasUnitChild: {
        // Проверяем, что просканировали тот же товар, что пришел в suggest'e
        needProduct = productsFind(products, props.suggest.product_id, 'product_id');
        if (!needProduct) {
          Alerts.error($gettext('Сканируйте ШК с коробки'));
        }
        break;
      }

      case props.suggest.conditions.weight_aggregate: {
        // Ищем товар(product_id) с необходимым весовым диапазоном, сравнивая с первым просканированным товаром(this.product.product_id)
        needProduct = productsFind(products, product.value?.product_id, 'product_id');
        if (!needProduct) {
          needProduct = productsFind(products, props.suggest.product_id, 'parent_id');
          if (!needProduct) {
            Alerts.error($gettext('Отсканирован неверный штрих-код'));
          } else {
            Alerts.error($gettext('Неподходящий весовой диапазон'));
          }
        }
        break;
      }

      case !props.suggest.product?.children_id: {
        // Если в suggest'e находится продукт у которого нет дочернего, значит он сам является дочерним, который нам нужен
        needProduct = productsFind(products, props.suggest.product_id, 'product_id');
        if (!needProduct) {
          Alerts.error($gettext('Сканируйте ШК с упаковки продукта'));
        }
        break;
      }

      default: {
        // Проверяем, что в suggest'e находится родительский товар для отсканированного
        needProduct = productsFind(products, props.suggest.product_id, 'parent_id');
        if (!needProduct) {
          Alerts.error($gettext('Отсканирован неверный штрих-код'));
        }
      }
    }

    if (needProduct && weight) {
      emits('scanWeightProduct', weight);
      AudioService.playAlert();
    } else {
      AudioService.playError();
    }
    return true;
    // TODO Место для моделек
  } catch {
    closeLoader();
    Alerts.error($gettext('Не найден штрихкод'));
    return true;
  }
});

const sendScannedWeight = (): void => {
  needBarcodeRequest.value = false;
  const weight = weightsRef.value.reduce((acc, value) => acc + value, 0);
  const count = weightsRef.value.length;
  emits('confirmInput', {
    weight,
    count,
  });
};
</script>
