<template>
  <div
    :data-test="`micro alert ${type}`"
    class="notification"
    :class="type"
    @click="$emit('close')"
  >
    <Caption1
      class="text-center font-medium"
      color="white"
    >
      {{ title }}
    </Caption1>
  </div>
</template>
<script lang="ts">
import Caption1 from '@/ui/common/typo/caption-1.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MicroAlert',
  components: { Caption1 },
  props: {
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  emits: ['close'],
});
</script>

<style lang="scss" scoped>
.notification {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 15px 0 3px 0;

  &.warn {
    background: #ffb648;
  }

  &.error {
    background: #ff3d2b;
  }

  &.success {
    background: #00a637;
  }
}
</style>
