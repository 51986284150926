<template>
  <Calc
    :is-true-weight="suggest.product?.isTrueWeight"
    :max="suggest.max_count || suggest.count || 0"
    @close="manualInput"
  />
</template>

<script setup lang="ts">
import Product from '@/models/Product';
import Suggest from '@/models/Suggest';
import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import Calc from '@/ui/common/keyboard/calc.vue';
import { useSuggests } from '@/store/modules/suggests';
import { computed } from 'vue';

interface ManialInputWindowProps {
  order_id: OrderOrderRetail['order_id'];
  suggest_id: string;
  collected: string[];
}

const props = defineProps<ManialInputWindowProps>();
const suggest = computed(
  () => useSuggests().getSuggest(props.order_id, props.suggest_id) as Suggest,
);

const emits = defineEmits<{
  (e: 'close'): void;
  (e: 'setCollected', suggest: Suggest, barcodes: string[]): void;
}>();

/**
 * Баркод текущего продукта
 */
const productBarcode = computed(() => {
  return suggest.value.product?.barcode[0];
});

/**
 * Обрабатывает ручной ввод веса товара
 */
async function manualWeightInput(weight?: string) {
  if (!weight || !productBarcode.value) {
    emits('close');
    return;
  }

  const numWeight = Number(weight) * 1000;
  const barcodes = Product.barcodesFromWeight(numWeight, productBarcode.value);

  emits('setCollected', suggest.value, barcodes);
  emits('close');
}

/**
 * Обрабатывает ручной ввод количества товара
 */
async function manualCountInput(count?: string) {
  if (!count) {
    emits('close');
    return;
  }

  const barcodes = Array(Number(count)).fill(productBarcode.value);

  emits('setCollected', suggest.value, barcodes);
  emits('close');
}

/**
 * Обрабатывает ручной ввод собранных товаров
 */
const manualInput = (count?: string) => {
  if (suggest.value.product?.isTrueWeight) {
    manualWeightInput(count);
  } else {
    manualCountInput(count);
  }
};
</script>
