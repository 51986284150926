<template>
  <Layout>
    <template #header>
      <MoreProductHeader
        :order="order"
        :product="product"
        @back="$emit('close')"
      />
    </template>
    <template #default>
      <SuggestDetails
        :collected="barcodes"
        :product="product"
      />
    </template>
    <template #footer>
      <MoreProductFooter
        :is-need-true-mark="isNeedTrueMark"
        @add-more="moreProduct"
      />
    </template>
  </Layout>
</template>

<script setup lang="ts">
import { checkNeedTrueMarkModal, errorTrueMarkModal } from '@/fsd/entities/modals/errorTrueMarkModal';
import { SuggestDetails } from '@/fsd/entities/suggest';
import { unMergeTrueMarkSuggests } from '@/fsd/entities/suggest/tools/mergeTrueMarkSuggests';
import { prepareBarcodes } from '@/fsd/entities/suggest/tools/useShelf2Box';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Product from '@/models/Product';
import { SuggestStatusEnum } from '@/models/Suggest';
import { default as OrderOrderRetail } from '@/models/orders/OrderOrderRetail';
import { SignalEnum } from '@/services/requests';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { $gettext } from '@/temp/plugins/gettext';
import Layout from '@/ui/common/layout.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import { checkTrueMark } from '@/views/OrderRetail/tools/checkTrueMark';
import { computed, onMounted, ref, watch } from 'vue';
import { MoreProductSuggestProps } from '../../types';
import MoreProductFooter from './MoreProductFooter.vue';
import MoreProductHeader from './MoreProductHeader.vue';

const productsStore = useProducts();
const ordersStore = useOrders();
const { showLoader } = useLoader();

const barcodes = ref<string[]>([]);
const trueMark = ref<string | undefined>(undefined);

interface RetailMoreProduct {
  data: MoreProductSuggestProps;
  order: OrderOrderRetail;
}

const props = defineProps<RetailMoreProduct>();

const emits = defineEmits<{ (e: 'close'): void }>();

const product = computed<Product>(() => {
  return productsStore.productById(props.data.product_id)!;
});

const checkSuggests = computed(() => unMergeTrueMarkSuggests(props.order.suggests));

const count = computed<number>(() => {
  return product.value.isTrueWeight ? Product.weightFromBarcode(barcodes.value) : barcodes.value.length;
});

const expAlbertHofmann = computed(() => useUser().experimentByName(experiments.exp_albert_hofmann));

const isNeedTrueMark = computed<boolean>(() => product.value.true_mark && !trueMark.value && expAlbertHofmann.value);

watch(
  product,
  async (p) => {
    if (p.true_mark && expAlbertHofmann.value) {
      const isSuccess = await checkTrueMark(props.data.barcode, props.order.order_id);
      if (!isSuccess) return;
      trueMark.value = props.data.barcode;
      needBarcodeRequest.value = false;
    }
  },
  { immediate: true },
);

onMounted(() => {
  barcodes.value.push(props.data.barcode);
});

const moreProduct = async () => {
  const confirm = await Modal.show({
    title: $gettext('Этого товара нет в заказе'),
    text: $gettext('Вы уверены, что хотите добавить этот товар в заказ?'),
    btnPosition: ButtonPositionsEnum.horizontal,
    confirmBtnTitle: $gettext('Добавить'),
  });
  if (!confirm) {
    return;
  }
  const { closeLoader } = showLoader();

  try {
    await ordersStore.signal({
      order_id: props.order.order_id,
      signal: SignalEnum.more_product,
      data: {
        product_id: props.data.product_id,
        count: trueMark.value ? 1 : count.value,
        barcodes: !trueMark.value ? prepareBarcodes(barcodes.value) : undefined,
        true_mark: trueMark.value,
        status: SuggestStatusEnum.done,
      },
    });
    await new Promise<void>((resolve) => {
      const unWatch = watch(checkSuggests, (suggests) => {
        for (const s of suggests) {
          if (
            s.conditions.need_true_mark
            && s.isDone
            && s.vars.true_mark === trueMark.value
            && s.product_id === product.value.product_id
          ) {
            unWatch();
            resolve();
          } else if (!s.conditions.need_true_mark && s.isDone && s.product_id === product.value.product_id) {
            unWatch();
            resolve();
          }
        }
        return;
      });
    });
    emits('close');
  } catch (e: any) {
    const response = e?.response;

    if (checkNeedTrueMarkModal(response?.data?.code)) {
      errorTrueMarkModal({
        code: response?.data?.code,
        status: response?.data?.true_mark_status,
      });
    }
  } finally {
    closeLoader();
  }
};

const { needBarcodeRequest } = useRequestBarcode(async (barcode) => {
  const { closeLoader } = showLoader();
  try {
    const product = await productsStore.getProductByBarcode(barcode);
    if (props.data.product_id !== product.product_id) {
      Alerts.error($gettext('Отсканирован другой товар'));
      return true;
    }
    if (isNeedTrueMark.value) {
      const isSuccess = await checkTrueMark(barcode, props.order.order_id);
      if (!isSuccess) return true;
      trueMark.value = barcode;
      return false;
    }
    barcodes.value.push(barcode);
    return true;
  } catch {
    Alerts.error($gettext('Отсканирован неверный баркод'));
    return true;
  } finally {
    closeLoader();
  }
});
</script>
