<template>
  <Layout :key="language">
    <template #header>
      <AppBar @show-profile="showProfile" />
    </template>

    <template #default>
      <div class="flex flex-col min-h-full">
        <OrderCarousel />
        <div class="flex flex-1 flex-wrap mx-4 mb-1">
          <GroupItem
            v-if="orderTypes.includes(OrderTypeEnum.order) || orderTypes.includes(OrderTypeEnum.order_retail)"
            :count="orderOrders.length + failedOrders.length"
            data-test="main list order btn"
            image="order"
            :url="URLS.order"
          >
            {{ $gettext('Сборка заказа') }}
          </GroupItem>
          <GroupItem
            v-if="
              orderTypes.includes(OrderTypeEnum.check_valid_regular) ||
                orderTypes.includes(OrderTypeEnum.visual_control) ||
                orderTypes.includes(OrderTypeEnum.repacking)
            "
            :count="controlOrders.length"
            data-test="main list control btn"
            image="control"
            :url="URLS.control"
          >
            {{ $gettext('Контроль товаров') }}
          </GroupItem>
          <GroupItem
            v-if="orderTypes.includes(OrderTypeEnum.acceptance) || orderTypes.includes(OrderTypeEnum.acceptance_market)"
            :count="acceptanceOrders(false).length"
            data-test="main list acceptance btn"
            image="acceptance"
            :url="URLS.acceptance"
          >
            {{ $gettext('Приемка') }}
          </GroupItem>
          <GroupItem
            v-if="
              orderTypes.includes(OrderTypeEnum.sale_stowage) ||
                orderTypes.includes(OrderTypeEnum.weight_stowage) ||
                orderTypes.includes(OrderTypeEnum.stowage_market)
            "
            :count="stowageOrders.length"
            data-test="main list stowage btn"
            image="stowage"
            :url="URLS.stowage"
          >
            {{ $gettext('Размещение') }}
          </GroupItem>
          <GroupItem
            v-if="orderTypes.includes(OrderTypeEnum.check_product_on_shelf)"
            :count="checkProductOnShelfOrders.length"
            data-test="main list check_product_on_shelf btn"
            image="check_product_on_shelf"
            :url="URLS.check_product_on_shelf"
          >
            {{ $gettext('Пересчеты') }}
          </GroupItem>
          <GroupItem
            v-if="orderTypes.includes(OrderTypeEnum.writeoff)"
            :count="writeoffOrders.length"
            data-test="main list writeoff btn"
            image="writeoff"
            :url="URLS.writeoff"
          >
            {{ $gettext('Списание') }}
          </GroupItem>
          <GroupItem
            v-if="orderTypes.includes(OrderTypeEnum.check_more) || orderTypes.includes(OrderTypeEnum.inventory_check_more)"
            :count="blindCheckOrders.length"
            data-test="main list check_more btn"
            image="inventarization"
            :url="URLS.check_more"
          >
            {{ $gettext('Слепая инвентаризация') }}
          </GroupItem>
          <GroupItem
            v-if="
              orderTypes.includes(OrderTypeEnum.check) ||
                orderTypes.includes(OrderTypeEnum.inventory_check_product_on_shelf) ||
                orderTypes.includes(OrderTypeEnum.check_final)
            "
            :count="checkOrders.length"
            data-test="main list check btn"
            image="inventarization"
            :url="URLS.check"
          >
            {{ $gettext('Контроль проходa') }}
          </GroupItem>
          <GroupItem
            v-if="orderTypes.includes(OrderTypeEnum.refund) || orderTypes.includes(OrderTypeEnum.part_refund)"
            :count="refundOrders.length"
            data-test="main list refund btn"
            image="refund"
            :url="URLS.refund"
          >
            {{ $gettext('Возвраты заказов') }}
          </GroupItem>
          <GroupItem
            v-if="orderTypes.includes(OrderTypeEnum.shipment) || orderTypes.includes(OrderTypeEnum.shipment_rollback)"
            :count="shipmentOrders.length"
            data-test="main list shipment btn"
            image="shipment"
            :url="URLS.shipment"
          >
            {{ $gettext('Отгрузки') }}
          </GroupItem>
          <GroupItem
            v-if="
              orderTypes.includes(OrderTypeEnum.hand_move) ||
                orderTypes.includes(OrderTypeEnum.kitchen_provision) ||
                orderTypes.includes(OrderTypeEnum.robot_provision)
            "
            :count="handMoveOrders.length"
            data-test="main list hand_move btn"
            image="hand_move"
            :url="URLS.hand_move"
          >
            {{ $gettext('Перемещения') }}
          </GroupItem>

          <GroupItem
            v-if="orderTypes.includes('control_check')"
            :count="controlCheckOrders.length"
            data-test="main list control_check btn"
            :url="URLS.control_check"
            is-mono-order
          >
            {{ $gettext('Контроль точности комплектации') }}
          </GroupItem>
        </div>
        <div class="mt-1">
          <Version />
          <div
            v-if="expAlbertHofmann"
            class="px-4 mt-4"
          >
            <UiButton
              data-test="check true mark"
              @click="checkTrueMark"
            >
              {{ $gettext('Проверка марок') }}
            </UiButton>
          </div>
          <SupportChatActivator />
          <RecountByOrder
            v-if="recountByOrder.visible.value"
            @cancel="() => (uiState.checkParentId = undefined)"
          />
        </div>
      </div>
    </template>
  </Layout>
</template>

<script lang="ts">
import { api } from '@/fsd/data/api/api.service';
import { PlatformService } from '@/fsd/data/services/platform.service';
import { loadingFinish } from '@/fsd/data/services/pro.service';
import { checkContractor } from '@/fsd/features/order/utils/checkContractor';
import { OrangeIconWarning } from '@/fsd/shared/icons/warning/OrangeIconWarning';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { rumSpaManager } from '@/fsd/shared/tools/RumSpaManager';
import OrderCarousel from '@/fsd/widgets/home/carousel/order-carousel.vue';
import { useComponent } from '@/hooks/useComponent';
import { useLogout } from '@/hooks/useLogout';
import requestBarcode from '@/mixins/requestBarcode';
import ItemByBarcode from '@/models/ItemByBarcode';
import { OrderTypeEnum } from '@/models/orders/BaseOrder';
import ProductByBarcode from '@/models/ProductByBarcode';
import ShelfByBarcode from '@/models/ShelfByBarcode';
import { UserLanguageEnum } from '@/models/User';
import { AudioService } from '@/services/audio.service';
import { ScannerService } from '@/services/scanner/scanner.service';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useUser } from '@/store/modules/user';
import {
  experiments, orderTypeUrls, permits,
} from '@/temp/constants';
import { modalNotifyId } from '@/temp/constants/common';
import { $gettext } from '@/temp/plugins/gettext';
import { logger } from '@/temp/plugins/logs';
import { QrActions } from '@/temp/services/qr-actions';
import { AvailableProduct } from '@/types/product';
import Layout from '@/ui/common/layout.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import UiButton from '@/ui/common/ui-button.vue';
import AppBar from '@/ui/home/app-bar/app-bar.vue';
import GroupItem from '@/ui/home/group-item/group-item.vue';
import RecountByOrder from '@/ui/home/recount-by-order/recount-by-order.vue';
import SupportChatActivator from '@/ui/home/support-chat/support-chat-activator.vue';
import Version from '@/ui/home/version/version.vue';
import { checkPermits } from '@/utils/checkPermit';
import { needUpgradeRoleModal } from '@/utils/modals';
import { notify } from '@kyvg/vue3-notification';
import dayjs from 'dayjs';
import { mapState, storeToRefs } from 'pinia';
import {
  defineComponent, markRaw, watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

interface Data {
  uiStateNeedBarcodeRequest: boolean;
  uiState: {
    shareOrderId?: string;
    checkParentId?: string;
  };
  alertOnNewOrderData: {
    timerId?: number;
    timeout: number;
  };
}

export default defineComponent({
  name: 'Home',
  components: {
    OrderCarousel,
    UiButton,
    SupportChatActivator,
    Layout,
    AppBar,
    GroupItem,
    Version,
    RecountByOrder,
  },
  mixins: [requestBarcode],
  setup() {
    useLogout();
    const { showLoader, closeLoader } = useLoader();
    const productsStore = useProducts();
    const userStore = useUser();
    const ordersStore = useOrders();
    const recountByOrder = useComponent();
    const router = useRouter();

    const route = useRoute();
    const pageName = route.name as string;
    rumSpaManager.startDataLoading(pageName);
    const { isInitialized } = storeToRefs(ordersStore);
    if (isInitialized.value) {
      // Сообщить PRO, что мы на главной
      if (PlatformService.isPro()) {
        loadingFinish();
      }
      rumSpaManager.finishDataLoading(pageName, true);
      rumSpaManager.startDataRendering(pageName);
    } else {
      rumSpaManager.startStubRendering(pageName);
      const unWatch = watch(isInitialized, (val) => {
        if (val) {
          unWatch();
          if (PlatformService.isPro()) {
            loadingFinish();
          }
          rumSpaManager.finishDataLoading(pageName);
          rumSpaManager.startDataRendering(pageName);
        }
      });
    }

    watch(() => userStore.storeId, async (value) => {
      if (!value) {
        await Modal.show({
          title: $gettext('Чтобы начать работу, обратитесь к директору'),
          text: $gettext('Ему нужно прикрепить вас к складу. После этого попробуйте отсканировать код ещё раз'),
          confirmTimeout: 5,
          component: markRaw(OrangeIconWarning),
          confirmBtnTitle: $gettext('Хорошо'),
        });
        await userStore.logout();
      }
    }, { immediate: true });

    return {
      showLoader,
      closeLoader,
      productsStore,
      userStore,
      ordersStore,
      recountByOrder,
      router,
      OrderTypeEnum,
    };
  },
  data(): Data {
    return {
      uiStateNeedBarcodeRequest: true,
      uiState: {
        shareOrderId: undefined,
        checkParentId: undefined,
      },
      alertOnNewOrderData: {
        timerId: undefined,
        timeout: 15000,
      },
    };
  },
  computed: {
    ...mapState(useOrders, [
      'failedOrders',
      'controlCheckOrders',
      'handMoveOrders',
      'refundOrders',
      'shipmentOrders',
      'writeoffOrders',
      'stowageOrders',
      'controlOrders',
      'blindCheckOrders',
      'checkProductOnShelfOrders',
      'checkOrders',
      'orderOrders',
      'acceptanceOrders',
      'hasRequestOrderOrder',
    ]),
    name(): string {
      return this.userStore.name;
    },
    orderTypes(): string[] {
      return (this.userStore.permitByName('tsd_order_types') as string[]) || [];
    },
    language(): UserLanguageEnum {
      return this.userStore.language;
    },
    URLS(): any {
      return orderTypeUrls;
    },
    expAlbertHofmann(): boolean {
      return this.userStore.experimentByName(experiments['exp_albert_hofmann']);
    },
  },
  watch: {
    hasRequestOrderOrder: {
      handler(value) {
        if (value && !this.alertOnNewOrderData.timerId) {
          this.alertOnNewOrder();
        }
      },
      immediate: true,
    },
  },
  beforeUnmount() {
    notify.close(modalNotifyId);

    if (this.alertOnNewOrderData.timerId) {
      clearTimeout(this.alertOnNewOrderData.timerId);
    }
  },
  methods: {
    async requestBarcode(): Promise<boolean> {
      const barcode = await ScannerService.requestCode(this.$options.name + this._uuid);
      if (!this.uiStateNeedBarcodeRequest) return false;
      if (barcode.length === 0) return true;
      if (QrActions.isAction(barcode)) {
        return this.handleOrderCheck(await QrActions.orderCheck(barcode));
      }
      const parsedBarcode = this.parseBarcode(barcode);
      if (parsedBarcode && parsedBarcode?.type === 'assets') {
        //   открыть окно создания списания основных средств
        //  прокинуть туда параметры

        this.router.push({
          name: 'create-writeoff-by-order',
          params: {
            type: parsedBarcode.type,
            taskId: parsedBarcode.task_id,
            system: parsedBarcode.system,
          },
        });
        return false;
      }
      const { closeLoader } = this.showLoader();
      try {
        const { data } = await api.barcode({ barcode });
        closeLoader();
        if (!data || !data.found.length) {
          this.$alert.error(this.$gettext('Не найден штрихкод %{barcode}', { barcode }));
          return true;
        }
        const found = data.found[0];
        if (ProductByBarcode.isProductByBarcode(found)) {
          const availableProduct = await this.filterMultiProduct(data.found, barcode);
          if (!availableProduct) return true;
          const product = await this.productsStore.getProductById(availableProduct.product_id);
          // если есть родительская приемка, то проверяем поставщика
          if (this.uiState.checkParentId) {
            // @ts-expect-error pinia product был any
            const canSendMoreProduct = await checkContractor(this.uiState.checkParentId, product?.product_id);
            if (!canSendMoreProduct) {
              AudioService.playError();
              return true;
            }
          }
          if (this.userStore.experimentByName(experiments.exp_purple_hearts)) {
            if (product?.children_id) {
              this.$modal.show({
                title: this.$gettext('Отсканируйте штрих-код дочернего товара'),
                text: this.$gettext(
                  'Вы отсканировали родительский товар, для продолжения работы необходимо отсканировать код дочернего товара.',
                ),
                component: markRaw(OrangeIconWarning),
                confirmBtnTitle: this.$gettext('Закрыть'),
              });

              return true;
            }
          }
          this.recountByOrder.hide();
          // LAVKADEV-13182
          if (this.uiState.checkParentId) {
            this.router.push({
              name: 'product-check-create',
              params: {
                product_id: availableProduct.product_id,
                parent_id: this.uiState.checkParentId,
              },
            });
          } else {
            this.router.push({
              name: 'product-card',
              params: { product_id: availableProduct.product_id },
            });
          }
          return true;
        } else {
          if (this.uiState.checkParentId) {
            this.$alert.error(this.$gettext('Отсканирован неверный баркод'));
            return true;
          }
        }

        if (ItemByBarcode.isItemByBarcode(found)) {
          await this.router.push({
            name: 'item-card',
            params: { item_id: found.item_id },
          });
          return false;
        }

        if (ShelfByBarcode.isShelfByBarcode(found)) {
          await this.router.push({
            name: 'shelf-card',
            params: { shelf_id: found.shelf_id },
          });
          return false;
        }
        AudioService.playError();
        return true;
      } catch {
        closeLoader();
        this.$alert.error(this.$gettext('Не найден штрихкод'));
        return true;
      }
    },
    parseBarcode(barcode): any {
      try {
        return JSON.parse(barcode);
      } catch {
        return undefined;
      }
    },
    async handleOrderCheck(parsedBarcode): Promise<boolean> {
      logger('handleOrderCheck', parsedBarcode);
      if (parsedBarcode.expire_time && dayjs(parsedBarcode.expire_time).isBefore(dayjs())) {
        this.$alert.error(this.$gettext('Время действия QR кода истекло'));
        return true;
      }

      const hasPermits = checkPermits(permits.tsd_create_check, permits.tsd_check_contractor);
      if (!hasPermits) {
        const result = await needUpgradeRoleModal({ text: this.$gettext('Поднимите роль, чтобы создать пересчет') });
        if (result) {
          this.router.push({
            name: 'settings-upgrade-role',
            params: { upgrade: 'true' },
          });
          return false;
        }
        return true;
      }
      this.uiState.checkParentId = parsedBarcode.parent_id;
      this.recountByOrder.show();

      return true;
    },
    async filterMultiProduct(found, barcode): Promise<AvailableProduct | undefined> {
      try {
        if (found.length === 1) {
          return found[0];
        }
        logger.event('MULTI_ITEM_ON_BARCODE', barcode);
        for (const f of found) {
          const available = await this.productsStore.fetchAvailable(f.product_id);
          // @ts-expect-error pinia
          if (available.length > 0) {
            return f;
          }
        }
        this.$alert.error('По этому штрихкоду найдено несколько товаров, сообщите в поддержку');
        return found[0];
      } catch (error) {
        logger.error(error, {
          method: 'filterMultiProduct',
          type: 'home',
        });
      }
    },
    showProfile(): void {
      // профиля пока нет, поэтому сразу открываем настройки
      this.router.push({ name: 'settings' });
    },
    checkTrueMark() {
      this.router.push({ name: 'checkTrueMark' });
    },
    alertOnNewOrder(): void {
      if (!this.hasRequestOrderOrder) {
        this.alertOnNewOrderData.timerId = undefined;
        return;
      }
      AudioService.playNewOrderAlert();

      this.alertOnNewOrderData.timerId = setTimeout(() => {
        this.alertOnNewOrder();
      }, this.alertOnNewOrderData.timeout);
    },
  },
});
</script>
