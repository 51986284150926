export const experiments = {
  // Необходимо ли при размещении посылок указывать типоразмер
  'exp_package_class': 'exp_package_class',
  // возможность добавить исполнителя в приемку и КСГ и отписаться от ордера
  'exp_tsd_companion': 'exp_tsd_companion',
  // Необходимость указывать причину удаления/замены товара для сборки с полок
  'exp_delete_reason_required': 'exp_delete_reason_required',
  // возможность ввода в КСГ даты производства вместо срока годности
  'exp_manhattan': 'exp_manhattan',
  // Запрашиваем ввод температуры при приемке
  'exp_marie-curie': 'exp_marie-curie',
  // Используем клавиатуру для ввода даты
  'exp_augusta-ada': 'exp_augusta-ada',
  // Выводим предупреждение о том, что курьер далеко для кладовщиков
  'exp_el_pausino': 'exp_el_pausino',
  // При выполнении ордера, если остатки продукта малы, спрашиваем у кладовщика сколько осталось продуктов на полке
  'exp_sherlock': 'exp_sherlock',
  // Показываем чатик с поддержкой для кладовщиков
  'exp_socrates': 'exp_socrates',
  // Разрешаем только доверительную приемку
  'exp_lightman': 'exp_lightman',
  // Проверка ассортимента поставщика перед добавлением товара в размещение или пересчете по распоряжению
  'exp_schrodinger': 'exp_schrodinger',
  // Проверка на наличие стока перед созданием пересчета
  'exp_condition_zero': 'exp_condition_zero',
  // Разрешаем стадию списания в весовой раскладке
  'exp_chicken_run': 'exp_chicken_run', // ВЫПИЛЕН
  // Эксперимент запрета перемещения обычных товаров на office и наоборот
  'exp_illidan': 'exp_illidan',
  // Оповещение в полке о кратном превышении количества при размещении/пересчете товара
  'exp_limit_exceeded': 'exp_limit_exceeded',
  // Эксперимент по запрету размещения родительских товаров
  'exp_purple_hearts': 'exp_purple_hearts',
  // Эксперимент по приемке посылок маркета через трансфер акт
  'exp_waste': 'exp_waste',
  // Эксперимент для отображения собранного и следующего товаров для сборки
  // Эксперимент, который вместо свайпов для движения между продуктами в клиентском заказе, добавляет кнопки для переключения между полками
  'exp_untouchable': 'exp_untouchable',
  // Эксперимент, который вместо свайпов для движения между продуктами в клиентском заказе, добавляет кнопки для переключения продукта на следующий/предыдущий
  'exp_susanin': 'exp_susanin',
  // Эксперимент, позволяющий создать обращение в контент
  'exp_product_bug_ticket': 'exp_product_bug_ticket',
  // проваливаемся в карточку весового продукта по лонг тапу
  'exp_weighty_touch': 'exp_weighty_touch',
  // Честный знак. У каждого товара при сборке уникальный баркод
  'exp_albert_hofmann': 'exp_albert_hofmann',
  // мягкий диспатч. блокируем взятие НЕ первого заказа в работу, если эксп включен
  'exp_black_jack': 'exp_black_jack',
  // эксп по которому оповещаем пользователя о готовности блюд с кухни
  'exp_free_cash_register': 'exp_free_cash_register',
  // Если включено, то кофе готовится на кухне.
  'coffee_machine_in_the_kitchen': 'coffee_machine_in_the_kitchen',
  // Эксперимент для ограничения кол-ва найденных продуктов по общей стоимости приемки
  'exp_not_more_than_total_price': 'exp_not_more_than_total_price',
  // необходимость qrкода для заказа
  'exp_check_qr': 'exp_check_qr',
  // Для доверительной приёмки просим пользователя указать дату и время прибытия ТС, а также дату и время убытия ТС
  'exp_checkin_vehicle_upon_acceptance': 'exp_checkin_vehicle_upon_acceptance',
  // Вывод внешнего номера клиентского заказа в Полке
  'exp_show_partner_order_id': 'exp_show_partner_order_id',
  // объединение саджестов товаров с ЧЗ под один TrueMarkSuggestWrapper
  'exp_true_mark_merged': 'exp_true_mark_merged',
  // использование только динамических qr-кодов для логина в ТСД
  'exp_dynamic_qr_only': 'exp_dynamic_qr_only',
  // Эксперимент для использования sse ручек для получения евентов на полке
  'exp_use_server_sent_events_for_picker': 'exp_use_server_sent_events_for_picker',
  // Раздельный выбор языка для данных и для интерфейса
  'exp_separate_data_lang': 'exp_separate_data_lang',
  // необходимость проверки и обновления версии хрома/вебвью
  'exp_tsd_update_webview': 'exp_tsd_update_webview',
  // проверяем марку в заказе на стороне тсд
  'exp_check_true_mark_on_tsd': 'exp_check_true_mark_on_tsd',
  // разрешает ручной ввод веса в сборке с полок
  'exp_manual_weight_input': 'exp_manual_weight_input',
  // показывает sku_id (external_id=sku_id) в качестве артикула товара
  'exp_external_id_as_article': 'exp_external_id_as_article',
  // включает возможность размещать ингредиенты блюд только на кухонные полки
  'exp_components_for_kitchen': 'exp_components_for_kitchen',
  // убирает шаг сканирования qr кода для сборки с полок
  'exp_shelf_picking_skip_qr_code': 'exp_shelf_picking_skip_qr_code',
  'exp_manual_collected_input': 'exp_manual_collected_input',
  // убирает для заказов на сборку иконку "Хрупкий", когда заказ не взят в работу
  'exp_antifragile': 'exp_antifragile',
  // скрывает текущее количество товара на полке при размещении
  'exp_hidden_count': 'exp_hidden_count',
  // необходимость проверки и обновления самой апки
  'exp_update_cap_picker_app': 'exp_update_cap_picker_app',
};
