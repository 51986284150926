import { OrderTypeEnum } from '@/models/orders/BaseOrder';

export enum AdditionalDocTypesEnum {
  'pack' = 'pack',
  'view' = 'view',
  'shelf' = 'shelf',
  'onlyTitle' = 'onlyTitle',
  'create-acceptance-item' = 'create-acceptance-item',
  'create-writeoff-by-order-item' = 'create-writeoff-by-order-item',
}

export type DocTypes = OrderTypeEnum | AdditionalDocTypesEnum;
