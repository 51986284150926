import { DescriptionByCodeAcceptance, GetTitleAndText } from '@/fsd/entities/modals/TrueMarkErrorsTexts/types';
import { getTrueMarkType } from '@/fsd/entities/true-mark/utils';
import type { InfoItem } from '@/fsd/shared/universalModal';
import { ERR } from '@/temp/constants/errors';
import { $gettext } from '@/temp/plugins/gettext';
import { getTrueMarkErrorTitle } from '@/fsd/entities/modals/TrueMarkErrorsTexts/utils';

/**
 * Заголовок и текст ошибок для приёмки
 */
export const getTitleAndTextByCodeAcceptance: GetTitleAndText<null> = (code: ERR) => {
  let title = '';
  let text = '';

  switch (code) {
    case ERR.ER_CONFIG_NOT_FOUND:
      title = $gettext('Отложите этот товар и обратитесь к директору');
      break;
    case ERR.ER_UNKNOWN_GROUP_MARK:
      title = $gettext('Отсканируйте «%{markType}» товара', { markType: getTrueMarkType() });
      text = $gettext('Если на упаковке нет общей марки «%{markType}» (КИГ), нужно отдельно отсканировать каждый товар',
        { markType: getTrueMarkType() },
      );
      break;
    case ERR.ER_PRODUCT_NOT_IN_ACCEPTANCE:
      title = $gettext('Отсканируйте другой товар, а этот пока отложите');
      text = $gettext('Нужно обратиться к директору: товара нет в документе о приёмке');
      break;
    case ERR.ER_UNIT_MARK_FROM_GROUP:
      title = $gettext('Отсканируйте общий «%{markType}» на упаковке (КИГ)', { markType: getTrueMarkType() });
      text = $gettext('Сейчас вы отсканировали марку одного из товаров, нужна марка всей упаковки');
      break;
    case ERR.ER_TRUE_MARK_HAS_EXPIRED:
      title = $gettext('Отложите этот товар, он относится к другой приёмке');
      text = $gettext('Вам нужно отсканировать марку другого товара, который относится к этой приёмке');
      break;
    case ERR.ER_SERVICE_UNAVAILABLE:
      title = $gettext('Отсканируйте «%{markType}» ещё раз', { markType: getTrueMarkType() });
      text = $gettext('Если не получится, пока отложите этот товар');
      break;
    case ERR.ER_TRUE_MARK_IN_ANOTHER_ORDER:
      title = $gettext('Отсканируйте другой товар, а этот пока отложите');
      break;
    case ERR.ER_SUGGEST_TRUE_MARK_ALREADY_TAKEN:
    case ERR.ER_TRUE_MARK_ALREADY_BEEN_SCANNED:
      title = $gettext('Вы уже отсканировали этот товар');
      text = $gettext('Приёмка оформлена, с этим товаром больше ничего делать не надо');
      break;
    case ERR.ER_MARK_NOT_INTRODUCED:
      title = $gettext('Оформить приёмку этого товара нельзя, обратитесь к директору');
      text = $gettext('Марка товара недействительна, всю поставку надо будет вернуть');
      break;
    case ERR.ER_SUGGEST_TRUE_MARK_UNEXPECTED_LIMIT_EXCEEDED:
      title = $gettext('Оформить приёмку этого товара нельзя, обратитесь к директору');
      text = $gettext('Марка товара недействительна, всю поставку надо будет вернуть');
      break;
    default:
      return null;
  }

  return {
    title,
    text,
  };
};

/**
 * Информационный блок описания кода ошибки для приёмки
 */
export const getInfoBlockDescriptionByCodeAcceptance = (code: ERR): InfoItem | null => {
  const { TITLE_DESCRIPTION_ERROR } = getTrueMarkErrorTitle();

  const ERROR_DESCRIPTION: Record<DescriptionByCodeAcceptance, string> = {
    [ERR.ER_CONFIG_NOT_FOUND]: $gettext('В конфигурациях WMS выставлена неправильная версия API'),
    [ERR.ER_TRUE_MARK_IN_ANOTHER_ORDER]: $gettext('Этот товар входит в другую приёмку'),
  };

  if (ERROR_DESCRIPTION[code]) {
    return {
      title: ERROR_DESCRIPTION[code],
      subtitle: TITLE_DESCRIPTION_ERROR,
    };
  }

  return null;
};
