import Item, { ItemInRequired } from '@/models/Item';
import Product, { TagsEnum, TypeAccountingEnum } from '@/models/Product';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import OrderEvent from '@/models/events/OrderEvent';
import { isString } from '@/models/typeGuards';
import itemQueue from '@/services/queue/item-queue';
import productQueue from '@/services/queue/product-queue';
import shelfQueue from '@/services/queue/shelf-queue';
import { useItems } from '@/store/modules/items';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { useSuggests } from '@/store/modules/suggests';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { defaultSourceFormatLong } from '@/temp/constants/dateFormat';
import { $gettext } from '@/temp/plugins/gettext';
import { ProductInRequired, isProductInRequired } from '@/types/product';
import { formatOrderNumber, getFormatDate } from '@/utils';
import dayjs from 'dayjs';
import { BaseModel } from 'sjs-base-model';
import { computed, unref } from 'vue';

export enum OrderWorkStatusEnum {
  request = 'request',
  processing = 'processing',
}

export type OrderStatus = OrderTargetEnum | OrderWorkStatusEnum;

export enum OrderTargetEnum {
  complete = 'complete',
  failed = 'failed',
  canceled = 'canceled',
}

export enum OrderEstatusEnum {
  waiting = 'waiting',
  done = 'done',
  // специфично для контролек, означает, что ордер ждет завершения подсчета из админки
  waiting_signal = 'waiting_signal',
  // статус говорящий, что сигнал на проверку трансфер акта отправлен
  check_transfer_act = 'check_transfer_act',
  // специфично для отмены клиенского заказа
  waiting_subs_cancel = 'waiting_subs_cancel',
  // Ожидание подвоза стеллажей в робозоне. Специфично для снабжения робозоны
  waiting_racks = 'waiting_racks',
  // Ожидание ответа от сервиса роботов. Специфично для заказов из робозоны
  call_racks_replenishment = 'call_racks_replenishment',
  // приемка ЧЗ, попытаться закрыть, если не все товары найдены
  try_completion = 'try_completion',
  // приемка ЧЗ, вернуться к приемке со страницы отмены
  rollback_completion = 'rollback_completion',
}

export enum OrderTypeEnum {
  // # Рабочий процесс
  order = 'order', // заказ клиента
  order_retail = 'order_retail', // заказ клиента в магазине
  acceptance = 'acceptance', // приемка товара
  acceptance_market = 'acceptance_market', // приемка посылок
  shipment = 'shipment', // отгрузка товара
  shipment_rollback = 'shipment_rollback', // отмена отгрузки товара
  stowage = 'stowage', // раскладка
  stowage_market = 'stowage_market', // раскладка посылок
  sale_stowage = 'sale_stowage', // раскладка начинающая продажи
  weight_stowage = 'weight_stowage', // раскладка весового товара
  move = 'move', // перемещение товаров
  writeoff = 'writeoff', // списание товара
  check = 'check', // инвентаризация
  check_final = 'check_final', // инвентаризация | Контрольная проверка (ЛИ)
  check_product_on_shelf = 'check_product_on_shelf', // инвентаризация продукта на полке
  check_more = 'check_more', // слепая инвентаризация
  // # Контроль сроков годности
  writeoff_prepare_day = 'writeoff_prepare_day', //  списание однодневных товаров
  check_valid_short = 'check_valid_short', // списание краткосрочных товаров
  check_valid_regular = 'check_valid_regular', // списание долгосрочных товаров
  visual_control = 'visual_control', // списание товара по внешнему виду
  refund = 'refund', // возврат товаров order
  part_refund = 'part_refund', // частичный возврат товаров
  // # Инвентаризация
  inventory = 'inventory', // базовый ордер
  inventory_check_more = 'inventory_check_more', // слепая проверка
  inventory_check_product_on_shelf = 'inventory_check_product_on_shelf', // проверка изменившихся товаров | Контрольная проверка (ПИ)
  // # Прочее
  collect = 'collect', // накопление списка товаров
  hand_move = 'hand_move',
  repacking = 'repacking', // перефасовка
  control_check = 'control_check', // контроль точности комплектации

  kitchen_provision = 'kitchen_provision', // снабжение. то же самое, что и hand_move, но больше приоритет в карусели
  robot_provision = 'robot_provision', // снабжение робозоны
}

enum OrderSourceEnum {
  'dispatcher' = 'dispatcher', // из диспетчерской
  'tsd' = 'tsd', // создан полкой
  'eda' = 'eda', // из яндекседы
  '1c' = '1c', // из 1С
  'integration' = 'integration', // интеграция
  'external' = 'external', // внешний
  'woody' = 'woody', // интеграция с woody
  'tristero' = 'tristero', // интеграция посылок
  'internal' = 'internal', // внутренние процессы
  'zoho' = 'zoho', // интеграция с zoho
}

export enum OrderStageEnum {
  trash = 'trash',
  stowage = 'stowage',
  all_done = 'all_done',
  store = 'store',
  complete = 'complete',
  trash2box = 'trash2box',
  trash2shelf = 'trash2shelf',
  repacking2box = 'repacking2box',
  repacking2shelf = 'repacking2shelf',

  /**
   * Этап раскладки на целевые полки для ордеров размещения (в том числе kitchen_provision, robot_provision)
   */
  put = 'put',

  /**
   * Этап взятия товаров с полок для ордеров размещения (в том числе kitchen_provision, robot_provision)
   */
  take = 'take',
}

export enum RequestTypeAttrEnum {
  move_order = 'move_order',
  purchase_order = 'purchase_order', // приёмка ЧЗ СТРОГАЯ
}

export enum AcceptanceModeAttrEnum {
  pre_check = 'pre-check',
}

// * https://a.yandex-team.ru/arcadia/taxi/lavka/platform/wms-backend/doc/api/models/order.yaml?rev=r15230839#L276
export const enum ProblemTypeEnum {
  low = 'low', // * недостаточно товара
  shelf_not_found = 'shelf_not_found', // * полка не найдена
  product_not_found = 'product_not_found', // * продукт не найден
  empty_products = 'empty_products', // * пустая секция products (при создании ордера)
  empty_shelves = 'empty_shelves', // * пустая секция shelves (при создании ордера)
  api_call_failure = 'api_call_failure', // * обращение во внешний сервис сломалось
  stock_found_for_item = 'stock_found_for_item', // * посылка уже есть на остатке
}
export interface Problem {
  type: ProblemTypeEnum;
  product_id?: string; // * Идентификатор продукта для `low`, `product_not_found`
  shelf_id?: string; // * Идентификатор полки для `low`, `shelf_not_found`
  count?: number; // * Количество продукта для `low`
  reserve?: number;
  stock_id?: string;
  item_id?: string;
  shelf_type?: string;
}

export const enum SignalTypeEnum {
  stat = 'stat',
  confirm_assembled_products = 'confirm_assembled_products',
  complete_final_stage = 'complete_final_stage',
}

export interface Signal {
  sent: string;
  sigid: string;
  type: SignalTypeEnum;
  data: any;
  done: any;
}

export enum ShelfPickingMethodEnum {
  delete_items = 'delete_items',
  replace_items = 'replace_items',
}

export interface Attr {
  doc_date: string;
  contractor?: string;
  doc_number: string;
  partner_order_id?: string;
  request_id?: string;
  request_date?: string;
  request_number?: string;
  request_type?: RequestTypeAttrEnum;
  maybe_rover?: boolean;
  trust_code?: string;
  acceptance_mode?: AcceptanceModeAttrEnum.pre_check;
  is_pickup?: boolean;
  returnable_supplier?: boolean;
  shelf_picking_method?: ShelfPickingMethodEnum;
  true_marks_included?: boolean;
}

export interface Courier {
  external_id: string; //  Идентификатор курьера во внешней системе
  name?: string; // Имя курьера
  vin?: string; // Имя ровера 2
  taxi_driver_uuid?: string; // Имя ровера 1
  type: 'human' | 'rover';
  phone?: string; //   Телефон курьера
  arrival_time: string; // Когда прибудет за заказом
  related_orders: string[]; //  Идентификаторы заказов связанных по id курьера
}

export interface OrderBaseVars {
  tag?: TagsEnum | 'mixed' | 'parcel';
  stage?: OrderStageEnum;

  /**
   * True, если в заказе есть позиции из робозоны
   */
  with_robozone?: boolean;
}

export default class BaseOrder extends BaseModel {
  // Идентификатор ордера
  public order_id: string = '';
  // Идентификатор склада
  public store_id: string = '';
  // Внешний идентификатор ордера
  public external_id: string = '';
  // Родительские ордера
  public parent: string[] = [];
  // Статус ордера
  public status: OrderStatus = OrderWorkStatusEnum.request;
  // Целевой статус
  public target: OrderTargetEnum = OrderTargetEnum.complete;
  // Подстатус
  public estatus: OrderEstatusEnum = OrderEstatusEnum.waiting;
  // Тип ордера
  public type: OrderTypeEnum = OrderTypeEnum.order;
  // Исполнители на ордере
  public users: string[] = [];
  // Список товаров требующихся клиенту
  public required: (ProductInRequired | ItemInRequired)[] = [];
  // Список проблем с товарами
  public problems: Problem[] = [];
  // Список полок заказа
  public shelves: string[] = [];
  // Список продуктов в документе
  public products: string[] = [];
  public version: number = 0;
  public revision: number = 0;
  public created: number = 0;
  public updated: string = '';
  public serial: number = 0;
  public attr: Attr = {
    doc_date: '',
    doc_number: '',
  };

  // Общая стоимость всех товаров в ордере
  public total_price?: string;
  // Кто создал
  public user_id: string = '';

  public signals: Signal[] = [];
  public courier?: Courier = undefined;
  public vars: OrderBaseVars = {};
  // эни нужен пока не распилим варсы по разным типам документов
  public data: any = {};
  // источник документа
  public source: OrderSourceEnum = OrderSourceEnum.tsd;

  // при типизации этого свойства у нас разлетаются типы и не получается пользоваться полиморфизмом
  protected _self: any = undefined;

  constructor(data: any) {
    super();
    this.update(data);
  }

  update(data) {
    if (data.created) {
      if (typeof data.created === 'string') {
        data.created = dayjs(data.created, defaultSourceFormatLong).unix();
      }
    }
    return super.update(data);
  }

  updateFromEvent(event: OrderEvent) {
    // не самое удобное приседание. но без него никак.
    // вся реактивность во вью 3 работает через прокси, соответственно объект может себя мутировать ТОЛЬКО через
    // свой же экземпляр обернутый прокси.
    // на подумать: а может сразу после записи с стор взять и проставить в каждую модель ссылку на свое прокси ??
    this.self.status = event.status || this.self.status;
    this.self.estatus = event.estatus || this.self.estatus;
    this.self.version = event.version || this.self.version;
    this.self.revision = event.revision || this.self.revision;
    this.self.users = event.users || this.self.users;

    // в ивентах нет таргета, поэтому обновим его вручную
    if (event.status === 'canceled' && this.target === OrderTargetEnum.complete) {
      this.self.target = OrderTargetEnum.canceled;
    }

    return this.self;
  }

  async loadProducts() {
    try {
      const promises: Promise<any>[] = [];
      if (this.required.length) {
        promises.push(productQueue.loadMany(this.orderProductsId));
        promises.push(itemQueue.loadMany(this.orderItemsId));
      }
      if (this.problems.length) {
        const product_ids = this.problems.map(s => s.product_id).filter(isString);
        promises.push(productQueue.loadMany(product_ids));
      }
      await Promise.allSettled(promises);
    } catch (e) {
      console.error('order-model loadProducts', e);
    }
  }

  get orderShelvesId() {
    if (this.status === OrderWorkStatusEnum.processing && this.suggests.length) {
      return this.suggests.map(s => s.shelf_id).filter(isString);
    }
    return [...new Set([...this.required.map(r => r.shelf_id), ...(this.shelves || [])])].filter(isString);
  }

  get orderProductsId() {
    if (this.status === OrderWorkStatusEnum.processing && this.suggests.length) {
      return this.suggests.filter(s => s.isProductSuggest).map(s => s.product_id);
    }
    return this.required
      .filter(isProductInRequired)
      .map(s => s.product_id)
      .filter(Boolean);
  }

  get orderItemsId() {
    if (this.status === OrderWorkStatusEnum.processing && this.suggests.length) {
      return this.suggests.filter(s => s.isItemSuggest).map(s => s.product_id);
    }
    return this.required
      .filter(Item.isItemInRequired)
      .map(s => s.item_id)
      .filter(Boolean);
  }

  loadShelves = async () => {
    try {
      const promises: Promise<any>[] = [];
      const shelves_id = this.orderShelvesId;
      if (shelves_id.length) {
        promises.push(shelfQueue.loadMany(shelves_id));
      }
      await Promise.allSettled(promises);
    } catch (e) {
      console.error('order-model loadProducts', e);
    }
  };

  get self(): this {
    // геттер, для изменения данных изнутри модельки, позволяет оповещать внешнее прокси об изменении данных
    if (this._self) {
      return this._self;
    }
    this._self = useOrders().orderById(this.order_id)!;
    return this._self;
  }

  get isControl() {
    return [
      OrderTypeEnum.check_valid_regular,
      OrderTypeEnum.writeoff_prepare_day,
      OrderTypeEnum.check_valid_short,
    ].includes(this.type);
  }

  protected _loadedProducts = computed(() => {
    let product_ids: string[] = [];
    if (this.status === OrderWorkStatusEnum.processing && this.suggests.length) {
      product_ids = this.suggests.map(s => s.product_id);
    } else if (this.required?.length) {
      product_ids = this.required.filter(isProductInRequired).map(s => s.product_id);
    }
    return product_ids
      .map(id => useProducts().products.get(id))
      .filter(Product.isProduct)
      .filter(Boolean);
  });

  get loadedProducts() {
    return unref<Product[]>(this._loadedProducts);
  }

  get hasFragile() {
    return this.loadedProducts.some(p => p.fragile);
  }

  get hasUltimaPackageItems() {
    return this.orderItemsId.some(item_id => useItems().itemById(item_id)?.isUltimaParcel);
  }

  suggestById(suggest_id: string): Suggest | undefined {
    return useSuggests().getSuggest(this.order_id, suggest_id);
  }

  // дата документа с форматированием
  get date() {
    const date = this.attr.request_date || this.attr.doc_date;
    if (date) {
      return getFormatDate(date);
    }
    return $gettext('без даты');
  }

  // общая логика сортировки саджестов. для изменения переопредели метод в наследнике.
  protected sorter(suggests: Suggest[]): Suggest[] {
    const statusSorter = (a: Suggest, b: Suggest): -1 | 0 | 1 => {
      switch (true) {
        case a.status === b.status:
          return 0;
        case a.status === SuggestStatusEnum.request:
          return -1;
        case b.status === SuggestStatusEnum.request:
          return 1;
        default:
          return 0;
      }
    };
    const shelfOrderSorter = (a: Suggest, b: Suggest): number => {
      if (!a.shelf || !b.shelf) return 0;
      return a.shelf.order - b.shelf.order;
    };
    const updateTimeSorter = (a: Suggest, b: Suggest): number => {
      return b.updated - a.updated;
    };
    return suggests.sort((a, b) => {
      return statusSorter(a, b) || shelfOrderSorter(a, b) || updateTimeSorter(a, b);
    });
  }

  protected _suggest = computed(() =>
    this.sorter(Array.from(useSuggests().suggestsByOrderId(this.order_id)?.values() || [])),
  );

  get suggests() {
    return unref<Suggest[]>(this._suggest);
  }

  getSuggestsByProductId(product_id: string) {
    return this.suggests.filter(s => s.product_id === product_id);
  }

  get contractor() {
    return this.attr.contractor || '-';
  }

  get rack() {
    const shelfId = this.shelves[0] || this.required[0].shelf_id;
    if (shelfId) {
      const shelf = useShelves().shelfById(shelfId);
      return shelf?.rack;
    }
  }

  get isCanceled() {
    return this.target === OrderTargetEnum.canceled;
  }

  get isRover() {
    return this.courier?.type === 'rover';
  }

  get hasAdultProducts() {
    if (!this.loadedProducts.length) return true;
    return this.loadedProducts.some(p => p.underage_restricted);
  }

  get hasSpecialPackageProducts() {
    return this.loadedProducts.some(p => p.special_package);
  }

  get hasUltima() {
    return this.suggests.some(s => s.isItemSuggest && s.item?.isUltimaParcel);
  }

  get isAvailableOrderType() {
    return useOrders().isAvailableOrderType(this.type);
  }

  get totalWeight() {
    return this.required.reduce((acc, i) => {
      if (isProductInRequired(i)) {
        const product = useProducts().productById(i.product_id);
        return acc + (product?.weight ?? 0) * (i.count ?? 0);
      }
      const item = useItems().itemById(i.item_id);
      return acc + (item?.weight ?? 0);
    }, 0);
  }

  get maxWeightInOrder() {
    return this.required.reduce((acc, i) => {
      if (isProductInRequired(i)) {
        const product = useProducts().productById(i.product_id);
        if (product?.type_accounting === TypeAccountingEnum.true_weight) {
          return acc > 1 ? acc : 1;
        }
        const productWeight = product?.weight ?? 0;
        return acc > productWeight ? acc : productWeight;
      }
      const item = useItems().itemById(i.item_id);
      const itemWeight = item?.weight || 0;
      return acc > itemWeight ? acc : itemWeight;
    }, 0);
  }

  get isAvailableForJunior() {
    return this.isAvailableOrderType && !this.hasAdultProducts;
  }

  get isExternalOrder() {
    //   флажок, что показывает внешний ли это заказ
    return Boolean(
      (this.type === OrderTypeEnum.order || this.type === OrderTypeEnum.order_retail)
      && useUser().experimentByName(experiments.exp_show_partner_order_id)
      && this.attr.partner_order_id,
    );
  }

  get orderNumberForView() {
    let rawNumber = this.attr.doc_number || this.external_id || this.order_id;
    // Для uzum нужно писать на пакете внутренний номер
    if (this.isExternalOrder) {
      rawNumber = this.attr.partner_order_id!;
    }
    const formattedNumber = formatOrderNumber(rawNumber);
    if (this.isExternalOrder) return 'X-' + formattedNumber;
    return formattedNumber;
  }
}
