<template>
  <!--Во время загрузки остатков показываем заглушку-->
  <ClientOrderSuggestCardSherlockInfo
    v-if="sherlock === 'maybe'"
    :suggest="suggest"
    img-size="big"
    :data-test="`product card ${index}`"
  />
  <!--Если нужен шерлок - показываем карточку-->
  <ClientOrderSuggestCardSherlock
    v-if="sherlock === 'need'"
    :suggest="suggest"
    :order_id="order_id"
    :is-visible="isVisible"
    @input-sherlock-count="onSherlock"
  />
  <ClientOrderSuggestCardClassic
    v-if="sherlock === 'skip'"
    :order_id="order_id"
    :index="index"
    :suggest="suggest"
    :problem="problem"
    :collected="collected"
    @finish="arg => emits('finish', arg)"
  />
</template>
<script setup lang="ts">
import ClientOrderSuggestCardClassic from '@/fsd/widgets/order/RequiredCard/ClassicCard/ClientOrderSuggestCardClassic.vue';
import ClientOrderSuggestCardSherlock from '@/fsd/widgets/order/RequiredCard/sherlock/ClientOrderSuggestCardSherlock.vue';
import ClientOrderSuggestCardSherlockInfo from '@/fsd/widgets/order/RequiredCard/sherlock/ClientOrderSuggestCardSherlockInfo.vue';
import { SherlockStatusEnum } from '@/fsd/widgets/order/RequiredCard/sherlock/types';
import Suggest from '@/models/Suggest';
import { Problem } from '@/models/orders/BaseOrder';
import OrderOrder from '@/models/orders/OrderOrder';
import { Collected } from '@/views/Order/types';

withDefaults(
  defineProps<{
    isVisible: boolean;
    order_id: OrderOrder['order_id'];
    index: number;
    suggest: Suggest;
    problem?: Problem;
    collected: Collected;
    sherlock?: SherlockStatusEnum;
  }>(),
  {
    collected: () => ({ count: 0 }),
    sherlock: SherlockStatusEnum.skip,
    problem: undefined,
  },
);

const emits = defineEmits(['finish', 'input-sherlock-count']);

const onSherlock = (count) => {
  emits('input-sherlock-count', count);
};
</script>
