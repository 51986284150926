<template>
  <PageLayout :order_id="order_id">
    <template #header>
      <OrderHeader
        ref="header"
        :order_id="order_id"
        :suggest="activeSuggest"
        :collected="activeSuggest?.suggest_id ? collected[activeSuggest.suggest_id] : { count: 0 }"
        @start-barcode-request="hideScanBarcodeCard = false"
        @stop-barcode-request="hideScanBarcodeCard = true"
      />
      <ShelvesChain
        v-if="exp_untouchable && !isOrderDone"
        :suggests="suggestsForCollect"
        :index="activeSuggestIndex"
        @choose-suggest="index => (activeSuggestIndex = index)"
      />
    </template>
    <template #default>
      <!-- Сборка позиций  -->
      <template v-if="!isOrderDone && !hideScanBarcodeCard">
        <RequiredCardWrapper
          v-model:active-suggest-index="activeSuggestIndex"
          :collected="collected"
          :suggests="suggestsForCollect"
          :active-suggest="activeSuggest"
          :order_id="order_id"
          @input-sherlock-count="checkNeedReportAfterSherlock"
          @update:collected="(suggest_id, newVal) => (collected[suggest_id] = newVal)"
        />
      </template>
      <FinishOrderCard
        v-if="order && isOrderDone"
        :order_id="order_id"
        :suggests="suggestsForCollect"
        :packing="order.packingSuggests"
        @done="finishOrder"
      />
      <RackValidation
        v-if="rackValidation.visible.value && !isOrderDone"
        :suggests="suggestsForCollect"
        :order="order"
        @validated="rackValidation.hide"
        @close-click="toHomePage"
      />
    </template>
  </PageLayout>
</template>

<script setup lang="ts">
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import { mergeTrueMarkSuggests } from '@/fsd/entities/suggest/tools/mergeTrueMarkSuggests';
import { useCheckBlockedSuggests } from '@/fsd/features/order/remainders/useCheckReadyMeals';
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import RequiredCardWrapper from '@/fsd/widgets/order/RequiredCard/RequiredCardWrapper.vue';
import FinishOrderCard from '@/fsd/widgets/order/finish-order/finish-order-card.vue';
import { useRUMOrderFinishing } from '@/fsd/widgets/order/finish-order/tools/useMeasureOrderFinish';
import ShelvesChain from '@/fsd/widgets/order/order-with-shelves-chain/shelves-chain.vue';
import { useHandleOrderStatus } from '@/hooks/useHandleOrderStatus';
import Suggest, { SuggestStatusEnum, SuggestTypeEnum } from '@/models/Suggest';
import { OrderTargetEnum } from '@/models/orders/BaseOrder';
import OrderOrder from '@/models/orders/OrderOrder';
import { useOrders } from '@/store/modules/orders';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { $gettext } from '@/temp/plugins/gettext';
import { logger } from '@/temp/plugins/logs';
import RackValidation from '@/ui/robots/RackValidation.vue';
import { deleteItem } from '@/utils/localStorageHelper';
import OrderHeader from '@/views/Order/OrderHeader.vue';
import { Collected } from '@/views/Order/types';
import {
  computed, onUnmounted, reactive, ref, watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useRobozoneCollect } from './useRobozoneCollect';

interface Props {
  order_id: string;
}

const props = defineProps<Props>();
const router = useRouter();
const route = useRoute();
const expTrueMarkMerged = useUser().experimentByName(experiments.exp_true_mark_merged);

const header = ref<InstanceType<typeof OrderHeader>>();

const { startOrderFinishing, finishOrderFinishing } = useRUMOrderFinishing();

const order = computed<OrderOrder | undefined>(() => {
  return useOrders().orderById(props.order_id) as OrderOrder;
});

const suggests = computed(() => {
  if (!order.value) return [];
  return order.value.suggests;
});

const suggestsForCollect = computed(() => {
  const mergedSuggests = expTrueMarkMerged ? mergeTrueMarkSuggests(suggests.value) : suggests.value;
  if (order.value?.target === OrderTargetEnum.canceled) {
    return mergedSuggests
      .filter(suggest => !suggest.isPackaging)
      .filter(s => s.status === SuggestStatusEnum.request || s.type === SuggestTypeEnum.box2shelf);
  }
  return mergedSuggests.filter(suggest => !suggest.isPackaging);
});

const firstRequestSuggestIndex = computed(() => {
  const idx = suggestsForCollect.value.findIndex((s) => {
    return s.status === SuggestStatusEnum.request;
  });
  if (idx !== -1) return idx;
  return 0;
});

/**
 * Возвращает индекс саджеста, который нужно показать первым
 */
const getStartSuggestIndex = () => {
  const startWithKitchen = route.query.kitchenReady != null;

  if (startWithKitchen) {
    const firstKitchenIndex = suggestsForCollect.value.findIndex(
      suggest => suggest.isKitchen && suggest.status === SuggestStatusEnum.request,
    );

    return firstKitchenIndex !== -1 ? firstKitchenIndex : firstRequestSuggestIndex.value;
  }

  return firstRequestSuggestIndex.value;
};

const activeSuggestIndex = ref(getStartSuggestIndex());

const activeSuggest = computed<Suggest | undefined>(() => {
  return suggestsForCollect.value[activeSuggestIndex.value];
});

/**
 * True, если нужно скрыть карточки с логикой сканирования
 */
const hideScanBarcodeCard = ref(false);

const collected = reactive<Record<Suggest['suggest_id'], Collected>>(
  suggestsForCollect.value.reduce((acc, s) => {
    acc[s.suggest_id] = {
      count: 0,
    };
    return acc;
  }, {}),
);

const { rackValidation } = useRobozoneCollect({
  order,
  suggestsForCollect,
  activeSuggest,
  activeSuggestIndex,
  hideScanBarcodeCard,
});

useCheckBlockedSuggests(props.order_id);

useHandleOrderStatus(props.order_id, {
  cancel: () => {
    activeSuggestIndex.value = 0;
  },
});

const exp_untouchable = computed(() => {
  return useUser().experimentByName('exp_untouchable');
});

const isOrderDone = computed<boolean>(() => {
  if (order.value?.target !== OrderTargetEnum.complete) return false;
  return suggestsForCollect.value.every(s => s.isCompleted);
});

const toHomePage = (): Promise<any> => {
  return router.push({ name: 'home' });
};

const finishOrder = async (): Promise<void> => {
  if (!order.value) {
    return toHomePage();
  }
  if (order.value.isOrderPaused) {
    Alerts.error($gettext('Данный заказ обрабатывается оператором, пожалуйста подождите'));
    return;
  }
  try {
    const orderId = props.order_id;
    const result = await useEndOrder(props.order_id);
    if (!result) {
      return;
    }
    deleteItem('roverScan', orderId);
    await toHomePage();
  } catch (error) {
    logger.error(error, {
      method: 'finishOrder',
      type: 'order',
    });
  }
};

const checkNeedReportAfterSherlock = (count): void => {
  // Если на полке НЕ учетное кол-во и товара может не хватать для сборки
  if (count < activeSuggest.value?.count!) {
    header.value?.showReportMenu();
  }
};

watch(isOrderDone, (val) => {
  if (val) {
    startOrderFinishing();
  }
});

// Если текущий саджест пропал, то идем к ближайшему в статусе реквест
watch(
  activeSuggest,
  (val) => {
    if (val) return;
    activeSuggestIndex.value = firstRequestSuggestIndex.value;
  },
  { immediate: true },
);

// После размонтирования компонента останавливаем метрики
onUnmounted(() => {
  if (isOrderDone.value) {
    finishOrderFinishing();
  }
});
</script>
