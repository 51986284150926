import { notify } from '@kyvg/vue3-notification';
import { Component, markRaw } from 'vue';

interface Options {
  id?: number;
}

export const callAction = <P = any, R = any>(component: Component, props?: P, options: Options = {}): Promise<R> => {
  return new Promise((resolve) => {
    notify({
      group: 'action',
      duration: -1,
      id: options.id,
      data: {
        resolve,
        component: markRaw(component),
        props,
      },
    });
  });
};
