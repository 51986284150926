<template>
  <Bar
    :order="order"
    :caption="`${$gettext('Заказ')} ${props.order.orderNumberForView}`"
    :text="$gettext('Оплата заказа')"
    :icons="{ left: 'close' }"
    @close-click="toHomePage"
  />
</template>

<script setup lang="ts">
import type OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import { $gettext } from '@/temp/plugins/gettext';
import Bar from '@/ui/common/bar/bar.vue';
import { useRouter } from 'vue-router';

interface HeaderFinishProps {
  order: OrderOrderRetail;
}

const router = useRouter();

const props = defineProps<HeaderFinishProps>();

defineEmits<{ (e: 'back'): void }>();

const toHomePage = (): Promise<any> => {
  return router.push({ name: 'home' });
};
</script>
