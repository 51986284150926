<template>
  <Layout>
    <template #header>
      <Bar
        :order="order"
        :caption="caption"
        :text="text"
        need-progress
        :progress-config="progressConfig"
        :total="suggests.length"
        :menu-config="menuConfig"
        :menu-title="menuTitle"
        @close-click="$emit('close')"
      />
    </template>
    <template #default>
      <div class="flex flex-col h-full">
        <Hint
          v-if="filteredSuggests.length !== 0"
          class="my-2 mx-4"
        >
          {{
            $gettext('При сканировании товаров ориентируйтесь на пометки: штрихкод или «%{markType}»', {
              markType: getTrueMarkType(),
            })
          }}
        </Hint>
        <SuggestCardContainer
          :doc-type="OrderTypeEnum.acceptance"
          :order="order"
          :suggest-menu-config="suggestMenuConfig"
          :suggests="filteredSuggests"
          :need-menu-for-suggests="
            filteredSuggests.map(s => {
              return Boolean(s.status === SuggestStatusEnum.request || (s.result_count && s.result_count > 0));
            })
          "
        />
      </div>
    </template>
    <template #footer>
      <LayoutFooter>
        <FilterMenu
          v-if="filter === FiltersEnum.all"
          :menu-config="filterMenuConfig"
        />
        <UiButton
          v-if="filter !== FiltersEnum.all"
          @click="setFilter(FiltersEnum.all)"
        >
          {{ $gettext('Назад') }}
        </UiButton>
        <SliderButton
          v-else
          data-test="acceptance finish btn"
          :disabled="!!requestSuggests.length"
          @slide-complete="$emit('finishOrder')"
        >
          {{ $gettext('Завершить') }}
        </SliderButton>
      </LayoutFooter>
    </template>
  </Layout>
</template>
<script setup lang="ts">
import { useFilter } from '@/fsd/entities/suggest';
import { FiltersEnum } from '@/fsd/entities/suggest/tools/useFilter';
import { getTrueMarkType } from '@/fsd/entities/true-mark/utils';
import { useDetachFromOrder } from '@/fsd/features/order/utils/useDetachFromOrder';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import AcceptanceOrder from '@/models/orders/AcceptanceOrder';
import { OrderTypeEnum } from '@/models/orders/BaseOrder';
import { useOrders } from '@/store/modules/orders';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { $gettext, $ngettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import Bar from '@/ui/common/bar/bar.vue';
import FilterMenu from '@/ui/common/filter-menu/filter-menu.vue';
import Hint from '@/ui/common/hint/hint.vue';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { MenuItemConfig } from '@/ui/common/menu/types';
import SliderButton from '@/ui/common/slider-button/slider-button.vue';
import UiButton from '@/ui/common/ui-button.vue';
import SuggestCardContainer from '@/views/common/suggest-card-container.vue';
import { computed } from 'vue';

interface SuggestsListProps {
  order_id: string;
  mergeSuggest?: boolean;
  suggestMenuConfig: (suggest: Suggest) => MenuItemConfig[];
  menuTitle?: string;
}

const props = defineProps<SuggestsListProps>();
const emits = defineEmits<{
  (e: 'showShareOrder'): void;
  (e: 'close'): void;
  (e: 'finishOrder'): void;
  (e: 'finishOrderTrueMark'): void;
  (e: 'openDetails', suggest: Suggest): void;
}>();

const userStore = useUser();
const ordersStore = useOrders();

const { detachBtn } = useDetachFromOrder(props.order_id);
const {
  filter, setFilter, filterMenuConfig, progressConfig, suggests, requestSuggests, filteredSuggests,
} = useFilter(
  props.order_id,
  props.mergeSuggest,
);

const order = computed(() => ordersStore.orderById(props.order_id) as AcceptanceOrder);

const contractorName = computed<string>(() => order.value?.attr.contractor || '');

const caption = computed<string>(() => {
  switch (filter.value) {
    case FiltersEnum.all:
      return $gettext('Приемка');
    case FiltersEnum.done:
      return $gettext('Товары со статусом “Готово”');
    case FiltersEnum.request:
      return $gettext('Не отсканированные товары');
    case FiltersEnum.part:
      return $gettext('Товары со статусом “Почти”');
    case FiltersEnum.noProduct:
      return $gettext('Товары со статусом “Нет”');
    default:
      return $gettext('Приемка');
  }
});

const text = computed<string>(() => {
  switch (filter.value) {
    case FiltersEnum.all:
      return $gettext('Осталось %{request} из %{all} товаров', {
        request: String(requestSuggests.value.length),
        all: String(suggests.value.length),
      });
    case FiltersEnum.done:
    case FiltersEnum.request:
    case FiltersEnum.part:
    case FiltersEnum.noProduct:
      return $ngettext(
        '%{filtered} товар с таким статусом',
        '%{filtered} товаров с таким статусом',
        filteredSuggests.value.length,
        { filtered: String(filteredSuggests.value.length) },
      );
    default:
      return contractorName.value;
  }
});

const menuConfig = computed<MenuItemConfig[]>(() => {
  return [
    detachBtn.value,
    {
      buttonText: $gettext('Товаров больше не осталось'),
      buttonSecondText: $gettext('Поставку нужно будет вернуть поставщику'),
      iconKey: 'cancel',
      buttonTextColor: 'semantic-error-main',
      dataTest: 'acceptance try_complete',
      onClick: async () => {
        const confirm = await Notifications.universalModal({
          title: $gettext('Товаров точно больше нет?'),
          text: $gettext(
            'Сейчас всё проверим — если ошибок нет, завершим приёмку. Если что-то будет не так, все товары нужно вернуть поставщику',
          ),
          titleAlign: 'left',
          textAlign: 'left',
          position: 'bottom',
          buttons: [
            {
              title: $gettext('Нет'),
              color: 'secondary',
              dataTest: 'confirm decline-btn',
              onClick: () => false,
            },
            {
              title: $gettext('Да'),
              color: 'error',
              btnTextColor: 'white',
              dataTest: 'confirm ok-btn',
              onClick: () => true,
            },
          ],
        });

        if (confirm) {
          emits('finishOrderTrueMark');
        }
      },
      condition: () => {
        return !!order.value?.attr.true_marks_included && !!requestSuggests.value.length;
      },
    },
    {
      buttonText: $gettext('Разделить задание'),
      onClick: () => emits('showShareOrder'),
      condition: () => userStore.experimentByName(experiments.exp_tsd_companion),
    },
  ];
});
</script>
