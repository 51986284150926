<template>
  <notifications
    class="z-[1001]"
    classes="notifications"
    width="100%"
    group="new-universal-modal"
  >
    <template #body="props">
      <NewUniversalModal
        :title="props.item.data.title"
        :subtitle="props.item.data.subtitle"
        :text="props.item.data.text"
        :info-block="props.item.data.infoBlock"
        :info-block-title="props.item.data.infoBlockTitle"
        :btn-position="props.item.data.btnPosition"
        :confirm-btn-title="props.item.data.confirmBtnTitle"
        :close-btn-title="props.item.data.closeBtnTitle"
        :data-test="props.item.data.dataTest"
        @close="props.item.data.onClose?.() || executeAndClose(() => props.item.data.resolve(false), props.close)"
        @confirm="props.item.data.onConfirm?.() || executeAndClose(() => props.item.data.resolve(true), props.close)"
      >
        <template #icon>
          <component :is="props.item.data.component" />
        </template>
      </NewUniversalModal>
    </template>
  </notifications>
</template>
<script lang="ts">
import { NewUniversalModal } from '@/fsd/shared/universalModal';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ModalGroup',
  components: {
    NewUniversalModal,
  },
  methods: {
    executeAndClose(cb, close): void {
      cb();
      close();
    },
  },
});
</script>
