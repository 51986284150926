<template>
  <ProductCard
    :product-id="suggest.product_id"
    class="remove-paddings"
    :need-menu="false"
  >
    <template #info>
      <Row
        data-test="suggest card count row"
        class="border-none remove-paddings remove-margins"
        :label="$gettext('Количество')"
        :value="count"
      />
    </template>
  </ProductCard>
</template>

<script lang="ts">
import Suggest from '@/models/Suggest';
import ProductCard from '@/ui/common/product-card/product-card.vue';
import Row from '@/ui/common/row/row.vue';
import { getCountToView } from '@/ui/common/suggest-card/formatters/count-formatter';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'ProductGroupItem',
  components: {
    Row,
    ProductCard,
  },
  props: {
    suggest: {
      type: Object as PropType<Suggest>,
      required: true,
    },
  },
  computed: {
    count(): string {
      if (this.suggest.product) {
        return getCountToView({
          count: this.suggest.result_count!,
          type_accounting: this.suggest.product.type_accounting,
        });
      }
      return this.$gettext('%{count} шт.', { count: String(this.suggest.result_count) });
    },
  },
});
</script>

<style scoped>
.remove-paddings {
  @apply p-0;
}
.remove-margins {
  @apply m-0;
}
</style>
