<template>
  <PageLayout :order_id="order_id">
    <FinishOrder
      v-if="finish.visible.value"
      :order="order"
    />
    <ChangeProduct
      v-else-if="change_product.visible.value && change_product.props.value"
      :suggest_id="change_product.props.value"
      :order="order"
      :collected="collected[change_product.props.value]?.length || 0"
      @close="change_product.hide"
      @remove-suggest="removeSuggest"
      @remove-suggests-list="removeSuggestsList"
      @open-suggest-menu="openSuggestMenu"
      @suggest-done="finishSuggest"
      @change-done="confirmChangeSuggest"
    />
    <SuggestDetails
      v-else-if="details.visible.value && details.props.value"
      :suggest_id="details.props.value"
      :order="order"
      :collected="collected[details.props.value] || []"
      @close="details.hide"
      @remove-suggest="removeSuggest"
      @open-suggest-menu="openSuggestMenu"
      @remove-products="removeProducts"
      @suggest-done="confirmPartSuggest"
      @change-products="change_product.asyncShow"
      @update-collected="updateCollected"
      @open-manual-input="manualInput.asyncShow"
    />
    <MoreProduct
      v-else-if="more_product.visible.value && more_product.props.value"
      :data="more_product.props.value"
      :order="order"
      @close="more_product.hide"
    />
    <ConfirmList
      v-else-if="confirm_list.visible.value"
      :order="order"
      @open-details="details.asyncShow"
      @finish-suggest="confirmPartSuggest"
      @remove-suggest="removeSuggest"
      @open-suggest-menu="openSuggestMenu"
      @open-more-product="more_product.asyncShow"
      @change-product="change_product.asyncShow"
      @to-finish="finish.show"
    />
    <ResetSuggests
      v-else-if="reset_suggests.visible.value"
      :order="order"
      :suggest_id="reset_suggests.props.value"
      @back="reset_suggests.hide"
      @to-finish="finish.show"
    />
    <ClientDetails
      v-else-if="client_details.visible.value"
      :order="order"
      @back="client_details.hide"
      @to-package="toPackageFromClient"
      @to-confirm="confirm_list.show"
    />
    <SuggestsList
      v-else
      :collected="collected"
      :order="order"
      @open-details="details.asyncShow"
      @finish-suggest="confirmPartSuggest"
      @remove-suggest="removeSuggest"
      @open-suggest-menu="openSuggestMenu"
      @update-collected="updateCollected"
      @change-product="change_product.asyncShow"
      @to-confirm="client_details.show"
      @to-finish="finish.show"
      @open-manual-input="manualInput.asyncShow"
    />
    <ManualInputWindow
      v-if="manualInput.visible.value && manualInput.props.value"
      :suggest_id="manualInput.props.value"
      :order_id="order_id"
      :collected="collected[manualInput.props.value] || []"
      @close="manualInput.hide"
      @set-collected="(suggest, barcodes) => updateCollected(suggest, barcodes, true)"
    />
    <DeleteReasonWindow
      v-if="deleteReasonsWindow.visible.value && deleteReasonsWindow.props.value"
      :suggest_id="deleteReasonsWindow.props.value"
      :order="order"
      :collected="collected[deleteReasonsWindow.props.value]?.length || 0"
      @close="deleteReasonsWindow.hide"
    />
  </PageLayout>
</template>

<script setup lang="ts">
import { useConfirmAssembledProducts } from '@/fsd/data/order/useConfirmAssembledProducts';
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { callAction } from '@/fsd/shared/ui/action';
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import { useComponent } from '@/hooks/useComponent';
import { useHandleOrderStatus } from '@/hooks/useHandleOrderStatus';
import Suggest from '@/models/Suggest';
import Product from '@/models/Product';
import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';
import type { SuggestReason } from '@/models/Constants';
import { Shelf2BoxReason, ReasonsEnum } from '@/services/requests';
import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import { useOrders } from '@/store/modules/orders';
import { useUser } from '@/store/modules/user';
import { $gettext } from '@/temp/plugins/gettext';
import {
  computed, onMounted, ref, watch,
} from 'vue';
import RemoveProductsModal from '../modals/RemoveProductsModal.vue';
import SuggestMenuModal from '../modals/SuggestMenuModal.vue';
import { experiments } from '@/temp/constants';
import { checkWeight } from '../tools/checkWeight';
import { getFullChildSuggests } from '../tools/getChildSuggests';
import { getParentSuggest, isChildSuggest } from '../tools/getParentSuggest';
import { removeSuggestsList } from '../tools/removeSuggestsList';
import { shelf2BoxRetail } from '../tools/shelf2boxRetail';
import { MoreProductSuggestProps, RemoveData, ReturnWeightEnum, Shelf2BoxRetail } from '../types';
import ChangeProduct from './ChangeProduct/ChangeProduct.vue';
import ClientDetails from './ClientDetails/ClientDetails.vue';
import ConfirmList from './ConfirmList/ConfirmList.vue';
import FinishOrder from './FinishOrder/FinishOrder.vue';
import ManualInputWindow from './ManualInput/ManualInputWindow.vue';
import MoreProduct from './MoreProduct/MoreProduct.vue';
import ResetSuggests from './ResetSuggests/ResetSuggests.vue';
import SuggestDetails from './SuggestDetails/SuggestDetails.vue';
import SuggestsList from './SuggestsList/SuggestsList.vue';
import DeleteReasonWindow from './DeleteReasonWindow/DeleteReasonWindow.vue';
import ManufactureDateWindow from './ManufactureDateWindow/ManufactureDateWindow.vue';
import { SuggestMenuModalActions } from '../modals/types';

interface OrderRetailProps {
  order_id: string;
}

const details = useComponent<Suggest['suggest_id']>();
const client_details = useComponent();
const reset_suggests = useComponent<Suggest['suggest_id'] | undefined>();
const more_product = useComponent<MoreProductSuggestProps>();
const change_product = useComponent<Suggest['suggest_id']>();
const manualInput = useComponent<Suggest['suggest_id']>();
const deleteReasonsWindow = useComponent<Suggest['suggest_id'], SuggestReason['value']>();
const confirm_list = useComponent();
const finish = useComponent();
const ordersStore = useOrders();
const { confirmAssembledProducts } = useConfirmAssembledProducts();

const collected = ref<Record<string, string[]>>({});

const props = defineProps<OrderRetailProps>();

useHandleOrderStatus(props.order_id);

const order = computed<OrderOrderRetail>(() => ordersStore.orderById(props.order_id) as OrderOrderRetail);

const packedSuggests = computed(() => order.value?.packedSuggests);

const isOrderCanPayment = computed<boolean>(
  () => !!order.value?.vars?.fulfilled_conditions?.confirm_assembled_products,
);

/**
 * Список дат изготовления по саджестам
 */
const manufactureDates = ref<Record<Suggest['suggest_id'], string>>({});

/**
 * True, если требуется указывать причину несборки товара
 */
const needDeleteReason = computed<boolean>(() => useUser().experimentByName(experiments.exp_delete_reason_required));

watch(
  isOrderCanPayment,
  (val) => {
    if (val) {
      finish.show();
    }
  },
  { immediate: true },
);

watch(
  packedSuggests,
  (suggests) => {
    suggests.forEach((suggest) => {
      if (suggest.isFull && getFullChildSuggests(suggest).length && reset_suggests.props.value !== suggest.suggest_id) {
        reset_suggests.asyncShow(suggest.suggest_id);
      }
    });
  },
  { immediate: true },
);

onMounted(() => {
  const suggest = order.value.nonClosedChildSuggests[0] && getParentSuggest(order.value.nonClosedChildSuggests[0]);
  if (order.value.nonClosedChildSuggests.length && suggest?.suggest_id) {
    change_product.asyncShow(suggest.suggest_id);
  }
});

const getBarcodes = (s: Suggest): string[] => {
  return collected.value[s.suggest_id] && collected.value[s.suggest_id].length
    ? collected.value[s.suggest_id]
    : s.barcodes;
};

const clearCollected = (suggest_id: Suggest['suggest_id']) => {
  collected.value[suggest_id] = [];
};

const finishSuggest = async (payload: Shelf2BoxRetail): Promise<boolean> => {
  const isSuccess = await shelf2BoxRetail(payload);
  clearCollected(payload.suggest.suggest_id);
  return isSuccess;
};

/**
 * Запрашивает у пользователя причину несборки товара
 */
const askDeleteReason = async (suggest: Suggest | TrueMarkSuggestWrapper, barcodes?: string[]): Promise<Shelf2BoxReason | undefined> => {
  const reasonCode = await deleteReasonsWindow.asyncShow(suggest.suggest_id);

  if (!reasonCode) return;

  return {
    code: reasonCode,
    count: barcodes && !TrueMarkSuggestWrapper.isTrueMarkSuggestWrapper(suggest)
      ? getCountFromBarcodes(suggest, barcodes)
      : 0,
  };
};

/**
 * Запрашивает у пользователя причину несборки товара
 */
const askManufactureDate = async (suggest: Suggest | TrueMarkSuggestWrapper): Promise<string | undefined> => {
  const valid = await callAction(ManufactureDateWindow, {
    product: suggest.product,
  });

  if (!valid) return;

  manufactureDates.value[suggest.suggest_id] = valid;
  return valid;
};

/**
 * Обновляет список собранных баркодов
 * @param suggest - саджест, для которого обновляем список собранных
 * @param barcodes - список баркодов, которые нужно добавить в список собранных
 * @param reset - True, если нужно сбросить отсканированные ранее баркоды
 */
const updateCollected = async (suggest: Suggest | TrueMarkSuggestWrapper, barcodes: string[], reset = false) => {
  if (suggest.conditions.need_manufacture_date && !manufactureDates.value[suggest.suggest_id]) {
    const valid = await askManufactureDate(suggest);
    if (!valid) return;
  }

  if (TrueMarkSuggestWrapper.isTrueMarkSuggestWrapper(suggest)) {
    if (!suggest.firstEmptySuggest) return;
    await finishSuggest({
      suggest: suggest.firstEmptySuggest,
      true_mark: barcodes[0],
      valid: manufactureDates.value[suggest.suggest_id],
    });
    return;
  }

  if (!reset) {
    barcodes = suggest.isDone
      ? [...suggest.barcodes, ...barcodes]
      : [...(collected.value[suggest.suggest_id] ?? []), ...barcodes];
  }

  if (suggest.isDone) {
    let reason;

    if (needDeleteReason.value && !suggest.reason?.code) {
      reason = await askDeleteReason(suggest, barcodes);
      if (!reason) return;
    }

    await finishSuggest({
      suggest,
      barcodes,
      reason,
    });
    return;
  }

  if (suggest.product?.isTrueWeight) {
    const typeWeight = await checkWeight(suggest, barcodes);
    if (typeWeight === ReturnWeightEnum.MORE) {
      collected.value[suggest.suggest_id] = barcodes;
    }
    if (typeWeight === ReturnWeightEnum.FULL) {
      collected.value[suggest.suggest_id] = barcodes;
      await finishSuggest({
        suggest,
        barcodes,
        valid: manufactureDates.value[suggest.suggest_id],
      });
    }
    return;
  }

  collected.value[suggest.suggest_id] = barcodes;

  if (suggest.count && collected.value[suggest.suggest_id].length >= suggest.count) {
    await finishSuggest({
      suggest,
      barcodes,
      valid: manufactureDates.value[suggest.suggest_id],
    });
  }
};

/**
 * Возвращает количество собранных товаров по собранным баркодам
 */
const getCountFromBarcodes = (suggest, barcodes): number => suggest.product?.isTrueWeight ? Product.weightFromBarcode(barcodes) : barcodes.length;

/**
 * Удаляет товар из заказа
 * @param suggest
 */
const removeSuggest = async (suggest: Suggest | TrueMarkSuggestWrapper): Promise<any> => {
  let confirm;
  let reason: Shelf2BoxReason | undefined;

  if (!needDeleteReason.value || isChildSuggest(suggest)) {
    confirm = await Modal.show({
      title: $gettext('Удалить товар из заказа?'),
      text: isChildSuggest(suggest)
        ? $gettext('Вы уверены, что хотите удалить товар на замену?')
        : $gettext('Вы уверены, что не можете собрать его в заказ?'),
      btnPosition: ButtonPositionsEnum.horizontal,
      closeBtnTitle: $gettext('Отмена'),
      confirmBtnTitle: $gettext('Удалить'),
    });
  } else {
    reason = await askDeleteReason(suggest);
    confirm = !!reason;
  }

  if (!confirm) return;

  if (TrueMarkSuggestWrapper.isTrueMarkSuggestWrapper(suggest)) {
    removeSuggestsList(suggest.suggests, reason);
  } else {
    await finishSuggest({
      suggest,
      reason,
    });
  }
};

const removeProducts = async (suggest: Suggest | TrueMarkSuggestWrapper) => {
  const maxNum: number = collected.value[suggest.suggest_id]?.length || suggest.result_count || 0;
  const removeData: RemoveData = await callAction(RemoveProductsModal, {
    suggest,
    maxNum,
  });
  let reason;

  if (needDeleteReason.value) {
    reason = await askDeleteReason(suggest);

    if (!reason) return;
  }

  if (removeData && removeData.suggest && suggest.conditions.need_true_mark) {
    await finishSuggest({
      suggest: removeData.suggest,
      reason,
    });
  }

  if (removeData && removeData.removeNum && removeData.barcode) {
    const { removeNum, barcode } = removeData;
    collected.value[suggest.suggest_id] = new Array(maxNum - removeNum).fill(barcode);

    if (reason) {
      reason.count = getCountFromBarcodes(suggest, collected.value[suggest.suggest_id]);
    }

    if (suggest.isDone) await finishSuggest({
      suggest,
      barcodes: collected.value[suggest.suggest_id],
      reason,
    });
  }
};

/**
 * Завершает процесс добавления замен для товара
 * @param suggest
 */
const confirmChangeSuggest = async (suggest: Suggest | TrueMarkSuggestWrapper) => {
  let reason: Shelf2BoxReason | undefined;
  const barcodes = getBarcodes(suggest);

  if (needDeleteReason.value && !suggest.reason?.code) {
    reason = await askDeleteReason(suggest, barcodes);
    if (!reason) return;
  }

  change_product.executeAndHide(async () => {
    if (TrueMarkSuggestWrapper.isTrueMarkSuggestWrapper(suggest)) {
      removeSuggestsList(suggest.requestSuggests, reason);
    } else {
      await finishSuggest({
        suggest,
        barcodes,
        reason,
      });
    }
  });
};

/**
 * Обрабатывает сборку товара в неполном объеме
 */
const confirmPartSuggest = async (suggest: Suggest | TrueMarkSuggestWrapper): Promise<void> => {
  let reason: Shelf2BoxReason | undefined;
  let confirm;

  if (!needDeleteReason.value) {
    confirm = await Modal.show({
      title: $gettext('Весь товар собран?'),
      text: order.value.isDeleteItemsMethod
        ? $gettext('Убедитесь, что этого товара больше нет.')
        : $gettext('Убедитесь, что этого товара больше нет, а также нет подходящих для него замен.'),
      btnPosition: ButtonPositionsEnum.horizontal,
      closeBtnTitle: $gettext('Отмена'),
      confirmBtnTitle: $gettext('Собран'),
    });
  } else {
    reason = await askDeleteReason(suggest, collected.value[suggest.suggest_id]);
    confirm = !!reason;
  }

  if (confirm) {
    if (TrueMarkSuggestWrapper.isTrueMarkSuggestWrapper(suggest)) {
      removeSuggestsList(suggest.requestSuggests, reason);
    } else {
      await finishSuggest({
        suggest,
        barcodes: collected.value[suggest.suggest_id],
        reason,
        valid: manufactureDates.value[suggest.suggest_id],
      });
    }
  }
};

/**
 * Изменение причин несборки товара
 */
const changeDeleteReason = async (suggest: Suggest | TrueMarkSuggestWrapper) => {
  const reason = await askDeleteReason(suggest);

  if (!reason) return;

  if (TrueMarkSuggestWrapper.isTrueMarkSuggestWrapper(suggest)) {
    removeSuggestsList(suggest.requestSuggests, reason);
  } else {
    await finishSuggest({
      suggest,
      reason,
    });
  }
};

/**
 * Изменение даты изготовления товара
 */
const changeManufactureDate = async (suggest: Suggest | TrueMarkSuggestWrapper) => {
  const valid = await askManufactureDate(suggest);

  if (!valid) return;

  const changeValid = suggest => finishSuggest({
    suggest,
    reason: {
      count: suggest.result_count ?? 0,
      code: ReasonsEnum.CHANGE_VALID,
    },
    valid,
  });

  if (TrueMarkSuggestWrapper.isTrueMarkSuggestWrapper(suggest)) {
    suggest.suggests.forEach(changeValid);
  } else {
    changeValid(suggest);
  }
};

/**
 * Открывает модальное окно списка доступных действий для продукта,
 * и обрабатывает выбранное в нем действие
 */
const openSuggestMenu = async (suggest: Suggest | TrueMarkSuggestWrapper) => {
  const needDo = await callAction(SuggestMenuModal, {
    suggest,
    collected: collected.value[suggest.suggest_id]?.length || suggest.result_count,
  });

  if (needDo === SuggestMenuModalActions.removeSuggest) await removeSuggest(suggest);
  if (needDo === SuggestMenuModalActions.removeProducts) await removeProducts(suggest);
  if (needDo === SuggestMenuModalActions.cancelScans) clearCollected(suggest.suggest_id);
  if (needDo === SuggestMenuModalActions.openManualInput) manualInput.asyncShow(suggest.suggest_id);
  if (needDo === SuggestMenuModalActions.changeDeleteReason) changeDeleteReason(suggest);
  if (needDo === SuggestMenuModalActions.changeManufactureDate) changeManufactureDate(suggest);
};

const toPackageFromClient = async () => {
  if (order.value.needResetSuggests.length) {
    await reset_suggests.asyncShow(undefined);
  } else if (order.value.forFinishPacking.length) {
    await confirmAssembledProducts(order.value);
  } else {
    finish.show();
  }
};
</script>
