import { api } from '@/fsd/data/api/api.service';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import BaseOrder from '@/models/orders/BaseOrder';
import { useOrders } from '@/store/modules/orders';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { $gettext } from '@/temp/plugins/gettext';
import { useLoader } from '@/ui/common/loader/useLoader';
import { MenuItemConfig } from '@/ui/common/menu/types';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

export const useDetachFromOrder = (order_id: BaseOrder['order_id']) => {
  const order = computed(() => {
    return useOrders().orderById(order_id);
  });
  const { showLoader } = useLoader();
  const router = useRouter();
  const detach = async () => {
    const { closeLoader } = showLoader();
    try {
      await api.order.executer({
        order_id,
        unlink: [useUser().userId],
      });
      closeLoader();
      Alerts.success('Вы откреплены от задания');
      await router.push({ name: 'home' });
    } catch (error) {
      closeLoader();
      console.error(error);
    }
  };

  const detachBtn = computed<MenuItemConfig>(() => {
    return {
      buttonText: $gettext('Открепиться от задания'),
      onClick: detach,
      dataTest: 'detach order btn',
      condition: () =>
        Boolean(
          order.value
          && order.value.users
          && order.value.users.length >= 2
          && useUser().experimentByName(experiments.exp_tsd_companion),
        ),
    };
  });
  return {
    detach,
    detachBtn,
  };
};
