<template>
  <Layout data-test="order-finish open-rover">
    <div class="flex flex-col items-justify">
      <div class="m-2">
        <img
          v-if="!isScanRoverBarcode"
          class="w-full"
          src="./img/photo.png"
        >
        <img
          v-else
          class="w-full"
          src="./img/close.png"
        >
      </div>
      <title4 class="font-bold m-4">
        <template v-if="!isScanRoverBarcode">
          {{ $gettext('Отсканируйте QR-код ровера') }}
        </template>
        <!-- eslint-disable-next-line no-irregular-whitespace -->
        <template v-else>
          {{ $gettext('Положите заказ в ровер и закройте крышку') }}
        </template>
      </title4>
      <div
        v-if="!isScanRoverBarcode"
        class="rover-container bg-blue-light flex-1 flex flex-row justify-between"
      >
        <IconRoverMini class="w-12" />
        <div class="flex flex-col mr-12 justify-center">
          <body2 class="text-center font-medium">
            {{ roverName }}
          </body2>
          <caption1 class="text-center font-medium">
            {{ $gettext('Найдите его на улице') }}
          </caption1>
        </div>
      </div>
      <body2 class="m-4">
        <template v-if="!isScanRoverBarcode">
          <!-- eslint-disable-next-line no-irregular-whitespace -->
          {{ $gettext('Он находится под крышкой ровера, как указано на фотографии.') }}
        </template>
        <template v-else>
          {{ $gettext('Чтобы закрыть крышку нужно нажать на кнопку close, или закрыть крышку вручную.') }}
        </template>
      </body2>
    </div>
    <template
      v-if="isScanRoverBarcode"
      #footer
    >
      <LayoutFooter>
        <UiButton
          data-test="next-stage-btn"
          :is-disabled="order.isOrderPaused"
          @click="emits('ready-click')"
        >
          <span
            v-if="order.isOrderPaused"
            class="font-medium"
          >{{ $gettext('Обрабатывается оператором') }}</span>
          <span
            v-else
            class="font-medium"
          >{{ $gettext('Далее') }}</span>
        </UiButton>
      </LayoutFooter>
    </template>
  </Layout>
</template>

<script setup lang="ts">
import IconRoverMini from '@/fsd/shared/icons/rover/icon-rover-mini.vue';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import OrderOrder from '@/models/orders/OrderOrder';
import { $gettext } from '@/temp/plugins/gettext';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { getItem, setItem } from '@/utils/localStorageHelper';
import {
  computed, defineEmits, defineProps, ref, withDefaults,
} from 'vue';
import {
  OrderFinishingDeltaNamesEnum,
  OrderFinishingMarkNamesEnum,
  useRUMOrderFinishingStep,
} from '../tools/useMeasureOrderFinish';

interface RoverCardProps {
  order: OrderOrder;
  id?: string;
}

const props = withDefaults(defineProps<RoverCardProps>(), {
  id: 'order-rover',
});

useRUMOrderFinishingStep({
  orderId: props.order.order_id,
  markName: OrderFinishingMarkNamesEnum.ROVER,
  deltaName: OrderFinishingDeltaNamesEnum.ROVER,
});

const emits = defineEmits<{ (e: 'ready-click'): void }>();

const isScanRoverBarcode = ref<boolean>(!!getItem('roverScan', props.order.order_id));

const roverName = computed<string>(() => $gettext('Ровер %{name}', { name: props.order?.courier?.name || '' }));

useRequestBarcode(async (barcode) => {
  if (
    barcode === props.order.courier!.taxi_driver_uuid
    || barcode === props.order.courier!.vin
    || barcode === props.order.courier!.name
  ) {
    isScanRoverBarcode.value = true;
    setItem('roverScan', props.order.order_id, true);
    return false;
  } else {
    Alerts.error($gettext('Отсканирован неверный штрихкод %{barcode}', { barcode }));
    return true;
  }
});
</script>

<style lang="scss" scoped>
.status-heading {
  margin: 0 16px 4px 16px;
  text-align: center;
}

.status-text {
  margin: 4px 16px 0 16px;
  text-align: center;
}

.rover-card {
  background: var(--main-bg);
  pointer-events: auto;
  height: 100vh;
  display: flex;
}

.rover-container {
  border-radius: 20px;
  margin: 8px 16px;
  padding: 13px 17px;
}
</style>
