<template>
  <ActionWrapper>
    <div
      ref="container"
      class="flex flex-col pt-1 px-1"
    >
      <Title4
        class="font-bold mb-2 text-left"
        color="primary"
      >
        {{
          $gettext('Отсканируйте %{type} товара, чтобы убрать его из заказа', {
            type: typeScan,
          })
        }}
      </Title4>
      <Body2
        class="mb-2"
        color="semantic-text-minor"
      >
        {{ suggest.product?.title }}
      </Body2>
      <div
        data-test="order-retail remove-products-num"
        class="flex flex-row justify-between mb-4 px-4 py-4 bg-element rounded-2xl"
      >
        <Body2>{{ isTrueMarkSuggest ? $gettext('Осталось') : $gettext('Убрать из сборки') }}</Body2>
        <Body2 class="font-bold">
          {{
            isTrueMarkSuggest
              ? $gettext('%{count} шт.', { count: String(suggest.result_count) })
              : $gettext('%{count} из %{all} шт.', { count: String(removeNum), all: String(maxNum) })
          }}
        </Body2>
      </div>
      <div class="flex justify-center">
        <ScanTrueMarkIcon v-if="isTrueMarkSuggest" />
        <IconBarcode v-else />
      </div>
      <div class="flex mt-6 gap-2">
        <UiButton
          data-test="order-retail remove-products-btn-cancel"
          background-color="secondary"
          @click="$emit('btnClick')"
        >
          {{ $gettext('Назад') }}
        </UiButton>
        <UiButton
          v-if="!isTrueMarkSuggest"
          data-test="order-retail remove-products-btn"
          :is-disabled="!removeNum"
          @click="$emit('btnClick', { suggest, removeNum, barcode: productBarcode })"
        >
          {{ $gettext('Подтвердить') }}
        </UiButton>
      </div>
    </div>
  </ActionWrapper>
</template>

<script setup lang="ts">
import { getTrueMarkType } from '@/fsd/entities/true-mark/utils';
import ScanTrueMarkIcon from '@/fsd/shared/icons/scan-true-mark-icon.vue';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Suggest from '@/models/Suggest';
import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';
import { useProducts } from '@/store/modules/products';
import IconBarcode from '@/temp/icons/icon-barcode.vue';
import { $gettext } from '@/temp/plugins/gettext';
import ActionWrapper from '@/ui/common/notifications/actionWrapper.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import Title4 from '@/ui/common/typo/title-4.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { computed, ref } from 'vue';
import { RemoveData } from '../types';

const productsStore = useProducts();

const removeNum = ref<number>(0);
const productBarcode = ref<string>('');

interface RemoveProductsProps {
  suggest: Suggest | TrueMarkSuggestWrapper;
  maxNum: number;
}

const props = defineProps<RemoveProductsProps>();

const emits = defineEmits<{ (e: 'btnClick', data?: RemoveData): void }>();

const isTrueMarkSuggest = computed<boolean>(() => TrueMarkSuggestWrapper.isTrueMarkSuggestWrapper(props.suggest));

const typeScan = computed<string>(() => {
  if (isTrueMarkSuggest.value) {
    return getTrueMarkType();
  }
  return $gettext('Штрихкод');
});

useRequestBarcode(async (barcode) => {
  try {
    const product = await productsStore.getProductByBarcode(barcode);
    if (props.suggest.product_id === product.product_id) {
      if (TrueMarkSuggestWrapper.isTrueMarkSuggestWrapper(props.suggest)) {
        const needSuggest = props.suggest.suggests.find(s => s.vars.true_mark === barcode);

        if (needSuggest) {
          emits('btnClick', { suggest: needSuggest });
          return true;
        }

        Alerts.error($gettext('Отсканирован другой товар'));
      } else if (props.maxNum > removeNum.value) {
        removeNum.value++;
        productBarcode.value = barcode;
      }
    } else {
      Alerts.error($gettext('Отсканирован другой товар'));
    }
    return true;
  } catch {
    Alerts.error($gettext('Отсканирован неверный баркод'));
    return true;
  }
});
</script>
