<template>
  <div
    v-for="group in suggestsByGroup"
    :key="group.group_id"
    class="flex flex-col pb-2"
  >
    <Body2 class="font-bold mb-2">
      {{
        group.group_id === SUGGEST_GROUP_ID.OTHERS ? $gettext('Другое') : group.name
      }}
    </Body2>
    <div class="flex flex-col gap-2">
      <template
        v-for="suggest in group.suggests"
        :key="suggest.suggest_id"
      >
        <SuggestCardWithChildren
          v-if="getFullChildSuggests(suggest).length"
          :child-suggests="getFullChildSuggests(suggest)"
          :collected="collected ? collected[suggest.suggest_id] : suggest.result_count || 0"
          :suggest="suggest"
          :need-confirm="needConfirm"
          :confirmed="confirmSuggests ? confirmSuggests[suggest.suggest_id] : undefined"
          @click="$emit('openDetails', suggest)"
          @remove="$emit('removeSuggest', suggest)"
          @menu="$emit('openSuggestMenu', suggest)"
          @change="$emit('changeProduct', suggest)"
          @remove-child="sug => $emit('removeSuggest', sug)"
          @confirm="$emit('confirmSuggest', suggest)"
          @open-manual-input="$emit('openManualInput', suggest)"
        />
        <SuggestCard
          v-else
          :collected="(collected ? collected[suggest.suggest_id] : suggest.result_count) || 0"
          :suggest="suggest"
          :need-confirm="needConfirm"
          :confirmed="confirmSuggests ? confirmSuggests[suggest.suggest_id] : undefined"
          @click="$emit('openDetails', suggest)"
          @remove="$emit('removeSuggest', suggest)"
          @ready="$emit('finishSuggest', suggest)"
          @menu="$emit('openSuggestMenu', suggest)"
          @change="$emit('changeProduct', suggest)"
          @confirm="$emit('confirmSuggest', suggest)"
          @open-manual-input="$emit('openManualInput', suggest)"
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import Suggest from '@/models/Suggest';
import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';
import { $gettext } from '@/temp/plugins/gettext';
import Body2 from '@/ui/common/typo/body-2.vue';
import { getFullChildSuggests } from '@/views/OrderRetail/tools/getChildSuggests';
import { computed } from 'vue';
import { SUGGEST_GROUP_ID, getSuggestsByGroup } from '../../tools/getSuggestsByGroup';
import { SuggestsByGroup } from '../../types';
import SuggestCard from './SuggestCard.vue';
import SuggestCardWithChildren from './SuggestCardWithChildren.vue';

interface SuggestGroupProps {
  suggests: (Suggest | TrueMarkSuggestWrapper)[];
  collected?: Record<string, string[]>;
  confirmSuggests?: Record<Suggest['suggest_id'], boolean>;
  needConfirm?: boolean;
}

const props = defineProps<SuggestGroupProps>();

defineEmits<{
  (e: 'finishSuggest', suggest: Suggest | TrueMarkSuggestWrapper): void;
  (e: 'removeSuggest', suggest: Suggest | TrueMarkSuggestWrapper): void;
  (e: 'changeSuggest', suggest: Suggest | TrueMarkSuggestWrapper): void;
  (e: 'openSuggestMenu', suggest: Suggest | TrueMarkSuggestWrapper): void;
  (e: 'changeProduct', suggest: Suggest | TrueMarkSuggestWrapper): void;
  (e: 'openDetails', suggest: Suggest | TrueMarkSuggestWrapper): void;
  (e: 'confirmSuggest', suggest: Suggest | TrueMarkSuggestWrapper): void;
  (e: 'openManualInput', suggest: Suggest | TrueMarkSuggestWrapper): void;
}>();

const suggestsByGroup = computed<SuggestsByGroup[]>(() => {
  return getSuggestsByGroup(props.suggests);
});
</script>
