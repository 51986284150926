import { ModeService } from '@/services/mode.service';

export enum RUMAckDeltasEnum {
  finishAckRequest = 'data.ack.response',
  finishAckEventWaiting = 'data.ack.event',
  finishAckLoadData = 'data.ack.load',
}

export enum RUMOrderAssemblingDeltasEnum {
  finishSherlock = 'data.assembling.sherlock',
  finishRequestBarcode = 'data.assembling.request_barcode',
  finishCheckTrueMark = 'data.assembling.check_true_mark',
  finishDoneRequest = 'data.assembling.response',
  finishDoneEventWaiting = 'data.assembling.event',
  finishAssembling = 'data.assembling.finish',
}

class RumSpaManager {
  private mode = ModeService.getMode();

  private logDebug = (methodName: string, pageName: string) => {
    if (!this.mode || this.mode !== 'production') {
      console.log(`RumSpaManager.${methodName}: ${pageName}`);
    }
  };

  makeSpaSubPage = (pageName: string, isBlock: boolean = false) => {
    const subPage = window.Ya.Rum.spa.makeSpaSubPage(pageName, undefined, isBlock);
    if (!isBlock) {
      window.Ya.Rum.setVars({
        '-page': pageName,
      });
    }
    this.logDebug('makeSpaSubPage', pageName);
    return subPage;
  };

  startDataLoading = (pageName: string) => {
    window.Ya.Rum.spa.startDataLoading(pageName);
    this.logDebug('startDataLoading', pageName);
  };

  finishDataLoading = (pageName: string, isCachedData?: boolean) => {
    window.Ya.Rum.spa.finishDataLoading(pageName, isCachedData);
    this.logDebug('finishDataLoading', pageName);
  };

  startStubRendering = (pageName: string) => {
    window.Ya.Rum.spa.startStubRendering(pageName);
    this.logDebug('startStubRendering', pageName);
  };

  finishStubRendering = (pageName: string) => {
    window.Ya.Rum.spa.finishStubRendering(pageName);
    this.logDebug('finishStubRendering', pageName);
  };

  startDataRendering = (pageName: string, shouldCallFinishDataRendering?: boolean) => {
    window.Ya.Rum.spa.startDataRendering(pageName, undefined, shouldCallFinishDataRendering);
    this.logDebug('startDataRendering', pageName);
  };

  finishDataRendering = (pageName: string) => {
    window.Ya.Rum.spa.finishDataRendering(pageName);
    this.logDebug('finishDataRendering', pageName);
  };

  finalizeSpaData = (pageName: string) => {
    window.Ya.Rum.sendTrafficData();
    window.Ya.Rum.finalizeLayoutShiftScore();
    window.Ya.Rum.finalizeLargestContentfulPaint();
    this.logDebug('finalizeSpaData', pageName);
  };

  sendDelta = (pageName: string, deltaName: string, deltaValue?: number) => {
    const subpage = window.Ya.Rum.spa.getLastSpaSubPage(pageName);
    if (!subpage) return;
    this.logDebug(`sendDelta - ${deltaName} : ${deltaValue}`, pageName);
    window.Ya.Rum.sendDelta(deltaName, deltaValue, subpage);
  };

  sendTimeMark = (pageName: string, timeMarkName: string, timeMarkValue: number) => {
    const subpage = window.Ya.Rum.spa.getLastSpaSubPage(pageName);
    if (!subpage) return;
    this.logDebug(`sendTimeMark - ${timeMarkName} : ${timeMarkValue}`, pageName);
    window.Ya.Rum.sendTimeMark(timeMarkName, timeMarkValue, true, subpage);
  };
}

export const rumSpaManager = new RumSpaManager();
