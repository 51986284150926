<template>
  <ActionWrapper
    padding-class="p-0"
    @blur-click="close"
  >
    <div
      class="bg-white flex flex-col rounded-2xl mt-auto pointer-events-auto"
      data-test="manufacture date window"
    >
      <Title4 class="px-4 pt-3 mb-2 font-bold">
        {{ $gettext('Укажите дату изготовления товара') }}
      </Title4>
      <Body2 class="px-4 mb-2 text-gray-400">
        {{ product?.title }}
      </Body2>
      <Keyboard
        type="date"
        :placeholder="$gettext('ДД.ММ.ГГ')"
        is-open
        @input="emitDate"
      />
    </div>
  </ActionWrapper>
</template>

<script setup lang="ts">
import Title4 from '@/ui/common/typo/title-4.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import ActionWrapper from '@/ui/common/notifications/actionWrapper.vue';
import Keyboard from '@/ui/common/keyboard/keyboard.vue';
import Product from '@/models/Product';

interface ManialInputWindowProps {
  product: Product;
}

defineProps<ManialInputWindowProps>();

const emits = defineEmits<{
  (e: 'btnClick', date?: string): void;
}>();

const close = () => {
  emits('btnClick');
};

const emitDate = (value: string | number | null) => {
  emits('btnClick', value?.toString());
};
</script>
