import Product from '@/models/Product';
import Suggest from '@/models/Suggest';
import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';
import type { Shelf2BoxReason } from '@/services/requests';

export enum FinishStepEnum {
  empty = 'empty',
  sort = 'sort',
  count = 'count',
  qrcode = 'qrcode',
  finish = 'finish',
}

export enum ReturnWeightEnum {
  OVER = 'over',
  MORE = 'more',
  FULL = 'full',
  ERROR = 'error',
}

export interface MoreProductSuggestProps {
  product_id: Product['product_id'];
  barcode: string;
}

export interface SuggestsByGroup {
  group_id: string;
  name: string;
  suggests: (Suggest | TrueMarkSuggestWrapper)[];
}

export interface RemoveData {
  suggest: Suggest;
  removeNum?: number;
  barcode?: string;
}

export interface Shelf2BoxRetail {
  suggest: Suggest;
  barcodes?: string[];
  true_mark?: string;
  removeTrueMark?: boolean;
  reason?: Shelf2BoxReason;
  valid?: string;
}
