<template>
  <div class="filter-menu-container">
    <UiButton
      class="filter-icon"
      background-color="secondary"
      :is-icon="true"
      @click="toggleMenu"
    >
      <FilterIcon />
    </UiButton>
    <transition
      v-if="menuVisible"
      name="fade"
      appear
    >
      <div
        class="overlay-backdrop"
        @click="closeMenu"
      />
    </transition>
    <transition
      v-if="menuVisible"
      name="translate"
      appear
    >
      <div class="overlay-container">
        <div
          v-for="btn in menuConfig"
          :key="btn.buttonText"
          class="menu-row"
          @click="
            () => {
              btn.onClick();
              toggleMenu();
            }
          "
        >
          <div
            class="bubble"
            :class="{ [btn.color]: true }"
          />
          <Typography class="title">
            {{ btn.buttonText }}
          </Typography>
          <Typography>
            {{ btn.count }}
          </Typography>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import FilterIcon from '@/icons/filter-icon.vue';
import { FilterMenuItemConfig } from '@/ui/common/filter-menu/types';
import Typography from '@/ui/common/typography.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    Typography,
    UiButton,
    FilterIcon,
  },
  props: {
    menuConfig: {
      type: Array as PropType<FilterMenuItemConfig[]>,
      required: true,
    },
  },
  data() {
    return {
      menuVisible: false,
    };
  },
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
      this.$emitter.emit('setActive', this.menuVisible);
    },
    closeMenu() {
      this.menuVisible = false;
      // По умолчанию в лейауте хедер имеет больший з-индекс. это необходимо для корректного отображения меню находящегося в хедере.
      // для корректного отображения меню из футера нужно выставить ему больший чем у хедера з-индекс.
      // обработчик находится в компоненте ui/common/layout.vue
      this.$emitter.emit('setActive', false);
    },
  },
});
</script>

<style lang="scss" scoped>
.overlay-backdrop {
  width: 100%;
  height: 100%;
  transition: opacity 150ms ease 0ms;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(33, 32, 31, 0.6);
  backdrop-filter: blur(12px);
  z-index: -1;
}

.overlay-container {
  background: var(--element-bg);
  position: absolute;
  padding: 4px 0;
  border-radius: 16px;
  bottom: 100%;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.filter-icon {
  margin-right: 16px;
  z-index: 20000;
  position: relative;
}

.menu-row {
  display: flex;
  width: 280px;
  align-items: center;
  height: 56px;
  padding: 0 16px;
  cursor: pointer;
}

.title {
  flex-grow: 1;
}

.bubble {
  background: var(--text-secondary-color);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-right: 16px;

  &.red {
    background: #f5523a;
  }

  &.green {
    background: #48c600;
  }

  &.orange {
    background: #fc9000;
  }

  &.gray {
    background: #c4c2be;
  }

  &.blue {
    background: #5c5cda;
  }
}

.filter-menu-container {
  z-index: 1;
}
</style>
