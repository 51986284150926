<template>
  <div class="flex flex-col items-center p-0 px-5">
    <typography
      color="secondary-title"
      type="text3"
      is-bold
      margin="4px 0"
      @click="activateDevtools"
    >
      {{ $gettext('Версия приложения') }}
    </typography>
    <VersionText />
  </div>
</template>

<script setup>
import { useDevtoolsStore } from '@/devtools/store/devtoolsModule';
import { ref } from 'vue';
import VersionText from './version-text.vue';

const devtools = useDevtoolsStore();
const clickCount = ref(0);

const activateDevtools = () => {
  if (clickCount.value++ >= 5) devtools.activateDevtools();
};
</script>
