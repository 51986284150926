import { Modal } from '@/fsd/shared/tools/modalNotification';
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import { $gettext } from '@/temp/plugins/gettext';
import { AxiosError } from 'axios';

export default ({ response }: AxiosError<any>) => {
  let reason = '';
  switch (true) {
    case !response:
      reason = $gettext('Что-то с сетью, попробуйте ещё раз');
      break;
    case response!.status === 423:
      reason = $gettext('Документ недоступен, попробуйте ещё раз');
      break;
    default:
      reason = `code: ${response!.status} \nmessage: ${response!.data?.message || ''}`;
      break;
  }

  return Modal.show({
    title: $gettext('Не удалось переместить товар в корзину'),
    text: reason,
    btnPosition: ButtonPositionsEnum.horizontal,
    confirmBtnTitle: $gettext('Повторить'),
    closeBtnTitle: $gettext('Отмена'),
    confirmTimeout: 5,
    dataTest: 'error-retry-confirm',
  });
};
