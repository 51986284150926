import Item from '@/models/Item';
import Product from '@/models/Product';
import Shelf from '@/models/Shelf';
import Suggest from '@/models/Suggest';
import BaseOrder, { OrderWorkStatusEnum } from '@/models/orders/BaseOrder';
import orderQueue from '@/services/queue/order-queue';
import productQueue from '@/services/queue/product-queue';
import shelfQueue from '@/services/queue/shelf-queue';
import { useItems } from '@/store/modules/items';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { useSuggests } from '@/store/modules/suggests';
import { ref } from 'vue';

// Предполагаю использовать ф-и следующим образом: проверяем наличие ресурсов при маунте вьюх и если ресурсов нет, то дозагружаем их.
export const useCheckOrderResources = (order_id: BaseOrder['order_id']) => {
  const loading = ref<boolean>(false);
  const hasResources = ref<boolean>(checkOrderResources(order_id));
  if (!hasResources.value) {
    loading.value = true;
    loadOrderResources(order_id).then((result) => {
      loading.value = false;
      hasResources.value = result;
    });
  }

  return {
    hasResources,
    loading,
  };
};

const checkOrderResources = (order_id: BaseOrder['order_id']): boolean => {
  const order = useOrders().orderById(order_id);
  if (!order) return false;
  if (order.status === OrderWorkStatusEnum.processing) {
    const suggests = useSuggests().suggestsByOrderId(order_id);
    if (!suggests) return false;
    for (const [, s] of suggests) {
      const loaded = s.isLoaded;
      if (!loaded) return false;
    }
    return true;
  }
  // request
  const shelvesStore = useShelves();
  const productsStore = useProducts();
  const itemsStore = useItems();

  return (
    order.orderShelvesId.every(id => shelvesStore.shelfById(id))
    && order.orderProductsId.every(id => productsStore.productById(id))
    && order.orderItemsId.every(id => itemsStore.itemById(id))
  );
};

const loadOrderResources = async (order_id: BaseOrder['order_id']): Promise<boolean> => {
  const order = useOrders().orderById(order_id) || (await orderQueue.load(order_id));
  if (!order) return false;

  if (order.status === OrderWorkStatusEnum.processing) {
    const suggestsStore = useSuggests();
    let suggests: Suggest[] = [];
    const suggestsMap = suggestsStore.suggestsByOrderId(order_id);
    if (suggestsMap) {
      suggests = Array.from(suggestsMap.values() as any as Suggest[]);
    } else {
      const loadedSuggests = await suggestsStore.loadSuggests(order);
      if (!loadedSuggests) return false;
      suggests = loadedSuggests;
    }
    const promises: Promise<any>[] = [];
    for (const s of suggests) {
      const loaded = s.isLoaded;
      if (!loaded) promises.push(s.loadResources());
    }
    const results = await Promise.allSettled(promises);
    return results.every(i => i.status === 'fulfilled');
  }

  const results = await Promise.allSettled([order.loadProducts(), order.loadShelves()]);
  return results.every(i => i.status === 'fulfilled');
};

export const useCheckSuggestResources = (order_id: BaseOrder['order_id'], suggest_id: Suggest['suggest_id']) => {
  const loading = ref<boolean>(false);
  const hasResources = ref<boolean>(false);
  if (!order_id || !suggest_id) {
    return {
      hasResources,
      loading,
    };
  }
  hasResources.value = checkSuggestResources(order_id, suggest_id);
  if (!hasResources.value) {
    loading.value = true;
    loadSuggestResources(order_id, suggest_id).then((result) => {
      loading.value = false;
      hasResources.value = result;
    });
  }

  return {
    hasResources,
    loading,
  };
};

const checkSuggestResources = (order_id: BaseOrder['order_id'], suggest_id: Suggest['suggest_id']): boolean => {
  const order = useOrders().orderById(order_id);
  if (!order) return false;
  const suggest = useSuggests().getSuggest(order_id, suggest_id);
  if (!suggest) return false;
  return suggest.isLoaded;
};

const loadSuggestResources = async (
  order_id: BaseOrder['order_id'],
  suggest_id: Suggest['suggest_id'],
): Promise<boolean> => {
  const order = useOrders().orderById(order_id) || (await orderQueue.load(order_id));
  if (!order) return false;
  const suggest
    = useSuggests().getSuggest(order_id, suggest_id) || (await useSuggests().loadSuggest(order_id, suggest_id));
  if (!suggest) return false;
  if (!suggest.isLoaded) {
    return await suggest.loadResources();
  }
  return true;
};

export const useCheckProductResources = (product_id: Product['product_id'], reload_available: boolean = false) => {
  const loading = ref<boolean>(false);
  const hasResources = ref<boolean>(checkProductResources(product_id, reload_available));
  if (!hasResources.value) {
    loading.value = true;
    loadProductResources(product_id, reload_available).then((result) => {
      loading.value = false;
      hasResources.value = result;
    });
  }

  return {
    hasResources,
    loading,
  };
};

const checkProductResources = (product_id: Product['product_id'], reload_available: boolean = false): boolean => {
  const product = useProducts().productById(product_id);
  if (!product) return false;
  const available = !reload_available && useProducts().availableByProductId(product_id);
  if (!available) return false;

  const shelvesStore = useShelves();
  for (const a of available) {
    const shelf = shelvesStore.shelfById(a.shelf_id);
    if (!shelf) return false;
  }

  return true;
};

const loadProductResources = async (
  product_id: Product['product_id'],
  reload_available: boolean = false,
): Promise<boolean> => {
  const productStore = useProducts();
  const product = productStore.productById(product_id) || (await productQueue.load(product_id));
  if (!product) return false;
  const available
    = (!reload_available && productStore.availableByProductId(product_id))
      || (await productStore.fetchAvailable(product_id));
  if (!available) return false;
  await shelfQueue.loadMany(available.map(a => a.shelf_id));
  return true;
};

export const useCheckShelfResources = (shelf_id: Shelf['shelf_id']) => {
  const loading = ref<boolean>(false);
  loading.value = true;
  loadShelfResources(shelf_id).then(() => {
    loading.value = false;
  });

  const reload = (): void => {
    loading.value = true;
    loadShelfResources(shelf_id).then(() => {
      loading.value = false;
    });
  };

  return {
    loading,
    reload,
  };
};
export const loadShelfResources = async (shelf_id: Shelf['shelf_id']): Promise<boolean> => {
  try {
    await useShelves().getShelfById(shelf_id);
    await useShelves().fetchAvailable(shelf_id);
    const available = useShelves().availableById(shelf_id);
    const productIds = available.map(p => p.product_id);
    // мы не можем знать, что именно лежит на полке: продукты или посылки?
    await Promise.all([useItems().getItemsByIds(productIds), useProducts().getProductsByIds(productIds)]);

    return true;
  } catch {
    return false;
  }
};

export const useCheckItemResources = (item_id: Item['item_id']) => {
  const loading = ref<boolean>(false);
  const hasResources = Boolean(useItems().itemById(item_id) && useItems().availableById(item_id));
  if (!hasResources) {
    loading.value = true;
    loadItemResources(item_id).then(() => {
      loading.value = false;
    });
  }

  return { loading };
};
export const loadItemResources = async (item_id: Item['item_id']): Promise<boolean> => {
  try {
    await Promise.all([useItems().getItemById(item_id), useItems().fetchAvailable(item_id)]);
    const available = useItems().availableById(item_id);
    if (available.length) {
      await useShelves().getShelfById(available[0].shelf_id);
    }

    return true;
  } catch {
    return false;
  }
};
