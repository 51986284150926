<template>
  <typo-base
    style="font-size: 20px; line-height: 23px"
    :class="colorClass"
  >
    <slot />
  </typo-base>
</template>

<script setup lang="ts">
import { useColor } from '@/fsd/shared/hooks';

const { color } = defineProps<{ color?: string }>();
const { colorClass } = useColor({ color: () => color });
</script>
