export const enum ValidTypeEnum {
  productionDate = 'productionDate',
  expirationDate = 'expirationDate',
}

export interface RecountOptions {
  count: number;
  tail: number;
  valid?: string;
}
