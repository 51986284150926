import { Alerts } from '@/fsd/shared/tools/alertNotification';
import Suggest from '@/models/Suggest';
import { $gettext } from '@/temp/plugins/gettext';

export const enum OperationEnum {
  cancelable = 'cancelable',
  editable = 'editable',
  all = 'all',
  need_valid = 'need_valid',
}

export const checkConditions = (
  suggest: Suggest,
  operation: OperationEnum,
  silent = false,
): boolean => {
  if (!suggest.conditions) {
    return true;
  }
  if (suggest.conditions[operation] === true) {
    return true;
  }
  if (suggest.conditions[operation] === false) {
    if (!silent) {
      const errors = {
        cancelable: $gettext('Невозможно отменить данное задание'),
        editable: $gettext('Невозможно отредактировать данное задание'),
        all: $gettext('Невозможно указать другое кол-во товара'),
        need_valid: $gettext('Невозможно указать другой срок годности'),
      };
      Alerts.error(errors[operation]);
    }
    return false;
  }
  return true;
};
