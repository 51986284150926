<template>
  <div>
    <div
      v-for="(label, name, index) in items"
      :key="label"
      :class="{'border-b': index !== Object.keys(items).length - 1}"
    >
      <input
        :id="name.toString()"
        type="radio"
        class="float-right hidden"
        :value="name"
        :checked="name === modelValue"
        :name="name.toString()"
        @change="updateValue(name)"
      >
      <label
        :for="name.toString()"
        :data-test="`radio-option-${name}`"
        class="block relative pr-10 py-4"
      >
        <typography class="inline">
          {{ label }}
        </typography>
      </label>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      default: undefined,
    },
    items: {
      type: Object as PropType<Record<string, string>>,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  methods: {
    updateValue(value) {
      this.$emit('update:modelValue', value);
    },
  },
});
</script>

<style lang="scss" scoped>
label:after,
label:before {
  content: '';
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  top: 50%;
  background-color: #f1f0ed;
  border-radius: 50%;
  transform: translateY(-50%)
}

input[type='radio']:checked + label:before {
  background-color: #fce45e;
}

input[type='radio']:checked + label:after {
  background-color: #21201f;
  transform: translateY(-50%) scale(0.4);
}
</style>
