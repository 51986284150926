import { mergeTrueMarkSuggests } from '@/fsd/entities/suggest/tools/mergeTrueMarkSuggests';
import { getRequestSuggests } from '@/fsd/entities/filters/suggestsFilters';
import Suggest from '@/models/Suggest';
import BaseOrder, { OrderTypeEnum, ShelfPickingMethodEnum } from '@/models/orders/BaseOrder';
import ClientOrder from '@/models/orders/ClientOrder';
import { isChildSuggest } from '@/views/OrderRetail/tools/getParentSuggest';
import { unref } from 'vue';

interface Conditions {
  fragile: boolean;
  // флаг, что показывает, что нужно отправить сигнал на согласование
  confirm_assembled_products: boolean;
}

export default class OrderOrderRetail extends ClientOrder {
  public conditions: Conditions = {
    fragile: false,
    confirm_assembled_products: false,
  };

  public max_total_price: string = '';

  constructor(data: any) {
    super(data);
    this.conditions = data.conditions;
    this.max_total_price = data.max_total_price || '0';
  }

  get totalPrice() {
    return this.suggests.reduce((acc, cur) => {
      if (cur.isPackaging || cur.isSample) {
        return acc;
      }
      if (cur.isDone && cur.conditions.tags) {
        acc += cur.result_count! * +cur.price;
        return acc;
      }
      return acc;
    }, 0);
  }

  get closable() {
    return getRequestSuggests(this.nonPackingSuggests).length === 0;
  }

  get suggests() {
    return mergeTrueMarkSuggests(unref<Suggest[]>(this._suggest));
  }

  get nonPackingSuggests() {
    return this.suggests.filter(s => !s.isPackaging);
  }

  get nonPackedSuggest() {
    return this.nonPackingSuggests.filter(s => !s.isDone).filter(s => !isChildSuggest(s));
  }

  get packedSuggests() {
    return this.suggests
      .filter(s => s.isDone)
      .filter(s => s.isFull || this.isDeleteItemsMethod)
      .filter(s => !isChildSuggest(s))
      .filter(s => !s.vars.more_product);
  }

  get needConfirmSuggests() {
    // не показываем семплы, их не нужно согласовывать
    // показываем только закрытые саджесты
    // это должен быть добавленный продукт собранный не в 0 или продукт из задания
    // это не весовой продукт и собран с другим количеством ИЛИ
    // это весовой продукт и собран за пределами диапазона ИЛИ
    // это добавленный продукт

    return this.nonPackingSuggests
      .filter(s => !s.isSample)
      .filter(s => s.isDone)
      .filter(s => (s.vars.more_product && s.result_count) || !s.vars.more_product)
      .filter((s) => {
        if (s.vars.more_product) {
          return true;
        }
        return !s.isFull;
      })
      .filter(s => !isChildSuggest(s));
  }

  get needResetSuggests() {
    return this.nonPackingSuggests
      .filter(s => !s.isSample)
      .filter(s => s.isDone && s.result_count)
      .filter((s) => {
        if (s.vars.more_product) {
          return true;
        }
        return s.isPartial || s.isOver;
      });
  }

  get needClientConfirm(): boolean {
    // ищем саджест, что закрыт НЕ так как требуется по условиям,
    // то есть обычный продукт закрыт НЕ в count
    // весовой продукт НЕ в диапазоне
    return this.needConfirmSuggests.length !== 0 && this.isReplaceItemsMethod;
  }

  get forFinishPacking() {
    return this.nonPackingSuggests.filter(s => s.isDone).filter(s => s.result_count);
  }

  get nonClosedChildSuggests() {
    return this.nonPackingSuggests.filter(s => isChildSuggest(s) && !s.isDone);
  }

  protected sorter(suggests: Suggest[]): Suggest[] {
    return suggests;
  }

  get shelfPickingMethod(): ShelfPickingMethodEnum {
    return this.attr.shelf_picking_method || ShelfPickingMethodEnum.replace_items;
  }

  get isDeleteItemsMethod() {
    return this.shelfPickingMethod === 'delete_items';
  }

  get isReplaceItemsMethod() {
    return this.shelfPickingMethod === 'replace_items';
  }
}

export const isOrderOrderRetail = (o: BaseOrder): o is OrderOrderRetail => {
  return o.type === OrderTypeEnum.order_retail;
};
