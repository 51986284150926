import Suggest, { SuggestStatusEnum } from '@/models/Suggest';

interface UpdateOptions extends Partial<Suggest> {
  suggests: Suggest[];
}

export default class TrueMarkSuggestWrapper extends Suggest {
  public suggests: Suggest[] = [];
  public virtual = true as const;
  public count: number = 0;
  public result_count: number = 0;
  public status: SuggestStatusEnum = SuggestStatusEnum.request;

  constructor(data: UpdateOptions) {
    super(data);
    this.addSuggest = data.suggests[0];
  }

  set addSuggest(suggest: Suggest) {
    this.suggests.push(suggest);
    this.count = this.suggests.reduce((acc, s) => acc + (!s.vars.more_product && s?.count ? s.count : 0), 0);
    this.result_count = this.suggests.reduce((acc, s) => acc + (s?.result_count || 0), 0);
    this.status = this.suggests.every(suggest => suggest.isDone) ? SuggestStatusEnum.done : SuggestStatusEnum.request;
  }

  get isRequest() {
    return this.suggests.some(suggest => suggest.isRequest);
  }

  get isDone() {
    return this.suggests.every(suggest => suggest.isDone);
  }

  get isBlocked() {
    return this.suggests.every(suggest => suggest.isBlocked);
  }

  get isCanceled() {
    return this.suggests.every(suggest => suggest.isCanceled);
  }

  get isCompleted() {
    return this.isDone || this.isCanceled;
  }

  get price(): string {
    return this.suggests[0].price;
  }

  static isTrueMarkSuggestWrapper(suggest: Suggest | TrueMarkSuggestWrapper): suggest is TrueMarkSuggestWrapper {
    return suggest.virtual && suggest.conditions.need_true_mark;
  }

  get suggestsIds(): Suggest['suggest_id'][] {
    return this.suggests.map(s => s.suggest_id);
  }

  get isLastRequestSuggest(): boolean {
    const requestSuggests = this.suggests.filter(suggest => suggest.status === SuggestStatusEnum.request);
    return requestSuggests.length === 1;
  }

  get requestSuggests(): Suggest[] {
    return this.suggests.filter(suggest => suggest.status === SuggestStatusEnum.request);
  }

  get firstRequestSuggest(): Suggest | undefined {
    return this.requestSuggests[0];
  }

  get firstEmptySuggest(): Suggest | undefined {
    return this.firstRequestSuggest || this.suggests.find(s => s.isDone && s.result_count === 0);
  }

  get isFull(): boolean {
    return this.result_count === this.count;
  }

  get isPartial(): boolean {
    return this.result_count < this.count && this.result_count > 0;
  }

  get isOver(): boolean {
    return this.result_count > this.count;
  }
}
