import { api } from '@/fsd/data/api/api.service';
import DownPriceRounded from '@/fsd/shared/icons/down-price/down-price-rounded.vue';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import ClientOrder from '@/models/orders/ClientOrder';
import orderQueue from '@/services/queue/order-queue';
import { SignalEnum } from '@/services/requests';
import { getCurrency } from '@/temp/constants/currencies';
import { $gettext } from '@/temp/plugins/gettext';
import { BaseError } from '@/types/baseErrorResponse';
import { useLoader } from '@/ui/common/loader/useLoader';
import { AxiosError } from 'axios';
import { markRaw } from 'vue';

interface MaxTotalPriceExceedError extends BaseError {
  code: 'ER_MAX_TOTAL_PRICE_EXCEEDED';
  details: {
    exceeded_by: string;
  };
}

export function useConfirmAssembledProducts() {
  const { showLoader } = useLoader();

  const confirmAssembledProducts = async (order: ClientOrder) => {
    const { closeLoader } = showLoader();
    try {
      await api.order.signal({
        order_id: order.order_id,
        signal: SignalEnum.confirm_assembled_products,
      });
    } catch (e: any) {
      const response = (e as AxiosError<BaseError | MaxTotalPriceExceedError>)['response'];

      if (response?.data.code === 'ER_MAX_TOTAL_PRICE_EXCEEDED') {
        closeLoader();
        await showMaxPriceExceedModal((response.data as MaxTotalPriceExceedError)?.details.exceeded_by);
        return false;
      }

      if (response?.data.code !== 'ER_CONFLICT' || response?.data.message !== 'Already confirmed') {
        Alerts.error($gettext('Произошла ошибка при отправке сигнала'));
        closeLoader();
        return false;
      }
    }
    order.vars.confirming_assembled_products = true;
    await orderQueue.load(order.order_id);
    closeLoader();
    return true;
  };

  return { confirmAssembledProducts };
}

/**
 * Показывает модальное окно о превышении максимальной суммы заказа
 * @param exceededBy - на сколько превысили максимальную сумму
 */
function showMaxPriceExceedModal(exceededBy: string) {
  return Modal.show({
    title: $gettext('Сумма заказа превышена на %{exceededBy} %{currency}', {
      exceededBy,
      currency: getCurrency(),
    }),
    text: $gettext(
      'Итоговая сумма стала слишком большая. Согласуйте с клиентом товары, которые хотите убрать из заказа и повторите попытку.',
    ),
    confirmBtnTitle: $gettext('Вернуться к заказу'),
    component: markRaw(DownPriceRounded),
  });
}
