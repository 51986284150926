<template>
  <div>
    <div
      class="settings-row"
      data-test="language row"
      @click="settingContainer.show"
    >
      <Typography>
        {{ $gettext('Язык интерфейса') }}
      </Typography>
      <div class="flex items-center">
        <Typography
          margin="0 10px 0 0"
          color="secondary-text"
        >
          {{ currentLanguage }}
        </Typography>
        <div>
          <IconChevron color="text-primary" />
        </div>
      </div>
    </div>
    <SettingContainer
      v-if="settingContainer.visible.value"
      :caption="$gettext('Язык интерфейса')"
      @close="settingContainer.hide"
    >
      <CustomSelect
        :options="selectOptions"
        :value="language"
        @change="onChange"
      />
    </SettingContainer>
  </div>
</template>

<script lang="ts">
import { useComponent } from '@/hooks/useComponent';
import { useUser } from '@/store/modules/user';
import { languages } from '@/temp/constants';
import IconChevron from '@/temp/icons/icon-chevron.vue';
import CustomSelect from '@/ui/common/fields/select.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import Typography from '@/ui/common/typography.vue';
import SettingContainer from '@/ui/home/settings/options/setting-container.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    IconChevron,
    Typography,
    SettingContainer,
    CustomSelect,
  },
  props: {},
  setup() {
    const { showLoader } = useLoader();
    const userStore = useUser();
    const settingContainer = useComponent();

    return {
      showLoader,
      userStore,
      settingContainer,
    };
  },
  computed: {
    selectOptions(): {
      name: string;
      value: string;
    }[] {
      return Object.keys(languages).reduce((acc, value) => {
        acc.push({
          name: languages[value],
          value: value,
        });
        return acc;
      }, [] as {
        name: string;
        value: string;
      }[]);
    },
    currentLanguage(): string {
      return languages[this.language];
    },
    language() {
      return this.userStore.language;
    },
  },
  methods: {
    async onChange(value): Promise<void> {
      const { closeLoader } = this.showLoader(this.$gettext('Сохраняем выбранный язык'));
      try {
        await this.userStore.setLanguage(value);
        this.$alert.success(this.$gettext('Язык сохранен'));
      } catch {
        this.$alert.error(this.$gettext('Произошла ошибка сохранения языка'));
      } finally {
        closeLoader();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.settings-row {
  display: flex;
  justify-content: space-between;
  padding: 20px 16px;
}
</style>
