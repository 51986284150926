<template>
  <ActionWrapper position="center">
    <div
      data-test="confirm title"
      class="text-primary text-2xl leading-7 font-bold mb-3 px-2"
    >
      {{ $gettext('В приёмке есть многоместные посылки') }}
    </div>
    <div
      data-test="confirm text"
      class="text-primary mb-4 whitespace-pre-line px-2"
    >
      {{
        $gettext(
          'Их удобнее отложить в сторону — если в приёмке будет не хватать посылок, курьеру надо будет вернуть часть из них. Когда вы сканируете многоместную посылку будет другой звук.',
        )
      }}
    </div>
    <div class="mt-2 flex flex-row justify-between items-center gap-2">
      <UiButton
        background-color="secondary"
        @click="play"
      >
        {{ $gettext('Воспроизвести звук') }}
      </UiButton>
      <UiButton
        background-color="primary"
        data-test="confirm ok-btn"
        @click="emits('close')"
      >
        {{ $gettext('Понятно') }}
      </UiButton>
    </div>
  </ActionWrapper>
</template>
<script setup lang="ts">
import { AudioService } from '@/services/audio.service';
import UiButton from '@/ui/common/ui-button.vue';
import ActionWrapper from './actionWrapper.vue';

const emits = defineEmits<{ (e: 'close'): void }>();
const play = () => {
  AudioService.playMultiItem();
};
</script>
