import Event from '@/models/events/Event';
import BaseOrder, { OrderEstatusEnum, OrderWorkStatusEnum } from '@/models/orders/BaseOrder';
import OrderOrder from '@/models/orders/OrderOrder';
import { BaseModel } from 'sjs-base-model';

export default class OrderEvent extends BaseModel {
  public type = 'order' as const;
  public order_id: BaseOrder['order_id'] = '';
  public status: BaseOrder['status'] = OrderWorkStatusEnum.request;
  public estatus: BaseOrder['estatus'] = OrderEstatusEnum.waiting;
  public version: BaseOrder['version'] = -1;
  public revision: BaseOrder['revision'] = -1;
  public users: BaseOrder['users'] = [];
  public paused_until: OrderOrder['paused_until'] = null;

  constructor(data: any) {
    super({ expand: true });
    this.update(data);
  }

  static isOrderEvent(event: Event['data']): event is OrderEvent {
    return event.type === 'order';
  }
}
