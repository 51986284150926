<template>
  <UiButton
    class="flex-0 mr-2"
    data-test="order basket-btn"
    background-color="secondary"
    is-icon
    @click="productList.show()"
  >
    <Basket />
  </UiButton>
  <Teleport to="#root">
    <template v-if="productList.visible.value">
      <ProductList
        :suggests="suggests"
        @close="productList.hide()"
      />
    </template>
  </Teleport>
</template>

<script lang="ts">
import Basket from '@/fsd/shared/icons/basket/basket.vue';
import ProductList from '@/fsd/widgets/product-list/ProductList.vue';
import { useComponent } from '@/hooks/useComponent';
import Suggest from '@/models/Suggest';
import UiButton from '@/ui/common/ui-button.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    ProductList,
    Basket,
    UiButton,
  },
  props: {
    suggests: {
      type: Array as PropType<Suggest[]>,
      required: true,
    },
  },
  emits: ['close'],

  setup() {
    const productList = useComponent<void>();
    return { productList };
  },
});
</script>
