<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM13.5 8.25C13.5 9.07843 12.8284 9.75 12 9.75C11.1716 9.75 10.5 9.07843 10.5 8.25C10.5 7.42157 11.1716 6.75 12 6.75C12.8284 6.75 13.5 7.42157 13.5 8.25ZM12 11.625C12.6213 11.625 13.125 12.1287 13.125 12.75V16.5C13.125 17.1213 12.6213 17.625 12 17.625C11.3787 17.625 10.875 17.1213 10.875 16.5V12.75C10.875 12.1287 11.3787 11.625 12 11.625Z"
      fill="#265CFF"
    />
  </svg>
</template>
