<template>
  <div
    class="flex justify-between items-center"
    :class="{ 'is-rtl': isRTL, 'font-bold': bold }"
    :data-test="dataTest"
  >
    <Body2 :class="!bold && 'text-gray-400'">
      {{ title }}
    </Body2>
    <Body2 data-test="info-row value">
      {{ value }}
    </Body2>
  </div>
</template>
<script lang="ts" setup>
import { useUser } from '@/store/modules/user';
import Body2 from '@/ui/common/typo/body-2.vue';
import { storeToRefs } from 'pinia';

interface Props {
  title: string;
  value: string;
  dataTest?: string;
  bold?: boolean;
}

defineProps<Props>();

const { isRTL } = storeToRefs(useUser());
</script>
