<template>
  <RoundedCard24 class="flex flex-col">
    <IconDownload class="mx-2 mt-2" />
    <Title4 class="font-medium mx-4 my-3">
      {{ $gettext('Загрузка обновления') }}
    </Title4>
    <Body2 class="mx-4 mb-3">
      {{ $gettext('Может занять до 20 минут. В это время лучше быть поближе к точке доступа Wi-Fi') }}
    </Body2>
    <div class="flex justify-between mx-4">
      <Body2 color="semantic-text-minor">
        {{ $gettext('Загрузка') }}{{ '.'.repeat(pulse) }}
      </Body2>
      <Body2 color="semantic-text-minor">
        {{ progress }} %
      </Body2>
    </div>
    <ProgressBarUpdateWindow
      class="mx-4 mt-2 mb-6"
      :progress="progress"
    />
  </RoundedCard24>
</template>
<script setup lang="ts">
import ProgressBarUpdateWindow from '@/fsd/features/tools/useUpdate/common/ProgressBarUpdateWindow.vue';
import IconDownload from '@/fsd/shared/icons/download/icon-download.vue';
import RoundedCard24 from '@/fsd/shared/ui/cards/RoundedCard24.vue';
import {
  onMounted, onUnmounted, ref,
} from 'vue';

const pulse = ref(1);
const intervalId = ref();

defineProps<{ progress: number }>();

onMounted(() => {
  intervalId.value = setInterval(() => {
    if (pulse.value < 4) {
      pulse.value = pulse.value + 1;
    } else {
      pulse.value = 1;
    }
  }, 500);
});
onUnmounted(() => {
  clearInterval(intervalId.value);
});
</script>
