<template>
  <div class="flex flex-col absolute rounded-t-3xl bottom-0 w-full bg-day-cardDivider">
    <div
      class="bg-background-main w-full px-2 pt-3 rounded-t-3xl pointer-events-auto"
      :class="{ 'rounded-b-3xl': aboutOrderModalMenuItem }"
    >
      <div class="flex flex-col gap-1 pb-3 px-2">
        <Title4 class="font-bold">
          {{ title }}
        </Title4>
        <Body2 color="text-secondary">
          {{ subtitle }}
        </Body2>
      </div>

      <div
        v-for="config in commonMenuItems"
        :key="config.buttonText"
        class="flex flex-wrap w-full px-2"
      >
        <Divider />
        <ButtonToModal
          :data-test="config.dataTest"
          class="m-1 min-h-14"
          background-color="secondary"
          :text-color="config.buttonTextColor"
          @click="() => click(config)"
        >
          <template #icon>
            <ConfigIcon
              v-if="config.iconKey"
              :icon-key="config.iconKey"
              :fill-color="config.buttonTextColor"
            />
          </template>
          <div class="flex flex-col items-start text-left">
            <Body2 :color="config.buttonTextColor">
              {{ config.buttonText }}
            </Body2>
            <Caption1
              v-if="config.buttonSecondText"
              class="mb-0.5"
              color="text-secondary"
            >
              {{ config.buttonSecondText }}
            </Caption1>
          </div>
        </ButtonToModal>
      </div>
      <UiButton
        v-if="!aboutOrderModalMenuItem"
        class="h-14 mb-2"
        data-test="menu close-btn"
        background-color="secondary"
        @click="close"
      >
        {{ $gettext('Закрыть') }}
      </UiButton>
    </div>

    <div
      v-if="aboutOrderModalMenuItem"
      class="flex flex-wrap bg-background-main w-full p-2 mt-2 rounded-t-3xl pointer-events-auto"
    >
      <ButtonToModal
        :data-test="aboutOrderModalMenuItem.dataTest"
        class="px-3 h-14"
        background-color="secondary"
        :text-color="aboutOrderModalMenuItem.buttonTextColor"
        @click="() => click(aboutOrderModalMenuItem)"
      >
        <template #icon>
          <ConfigIcon
            v-if="aboutOrderModalMenuItem.iconKey"
            :icon-key="aboutOrderModalMenuItem.iconKey"
            :fill-color="aboutOrderModalMenuItem.buttonTextColor"
          />
        </template>
        <div class="flex">
          <Body2 :color="aboutOrderModalMenuItem.buttonTextColor">
            {{ aboutOrderModalMenuItem.buttonText }}
          </Body2>
          <Caption1
            v-if="aboutOrderModalMenuItem.buttonSecondText"
            class="mb-0.5"
            color="text-secondary"
          >
            {{ aboutOrderModalMenuItem.buttonSecondText }}
          </Caption1>
        </div>
      </ButtonToModal>
      <UiButton
        class="h-14"
        data-test="menu close-btn"
        background-color="secondary"
        @click="close"
      >
        {{ $gettext('Закрыть') }}
      </UiButton>
    </div>
  </div>
</template>

<script lang="ts">
import { ButtonToModal } from '@/fsd/shared/ui/buttonToModal';
import Divider from '@/fsd/shared/ui/divider/divider.vue';
import { $gettext } from '@/temp/plugins/gettext';
import ConfigIcon from '@/ui/common/menu/config-icon.vue';
import { MenuItemConfig } from '@/ui/common/menu/types';
import Body2 from '@/ui/common/typo/body-2.vue';
import Caption1 from '@/ui/common/typo/caption-1.vue';
import Title4 from '@/ui/common/typo/title-4.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  components: {
    Divider,
    UiButton,
    ButtonToModal,
    ConfigIcon,
    Title4,
    Body2,
    Caption1,
  },
  props: {
    menuConfig: {
      type: Array as PropType<MenuItemConfig[]>,
      required: true,
    },
    title: {
      type: String,
      default: $gettext('Выберите пункт'),
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  computed: {
    menuConfigFiltered(): MenuItemConfig[] {
      return this.menuConfig.filter((menuItem) => {
        if (!menuItem.condition) {
          return true;
        }
        return menuItem.condition();
      });
    },
    commonMenuItems(): MenuItemConfig[] {
      // Кнопка 'О заказе должна находится отдельно'
      return this.menuConfigFiltered.filter(menuItem => !menuItem.isAboutOrder);
    },
    aboutOrderModalMenuItem(): MenuItemConfig | undefined {
      return this.menuConfigFiltered.find(menuItem => menuItem.isAboutOrder);
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    click(config) {
      config.onClick();
      this.close();
    },
  },
});
</script>
