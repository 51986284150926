<template>
  <Layout>
    <template #header>
      <SuggestsListHeader :order="order" />
    </template>
    <template #default>
      <Tabs
        :active-key="activeTabKey"
        @change-tab="changeTab"
      >
        <Tab
          :tab_key="ActiveTabKeys.NO_PACKED"
          :title="`${$gettext('Не собрано')} · ${nonPackedSuggests.length}`"
        >
          <EmptyScreen
            v-if="!nonPackedSuggests.length"
            :height="96"
          />
          <SuggestsByGroup
            :suggests="nonPackedSuggests"
            :collected="collected"
            @open-details="openDetails"
            @remove-suggest="sug => $emit('removeSuggest', sug)"
            @finish-suggest="sug => $emit('finishSuggest', sug)"
            @open-suggest-menu="sug => $emit('openSuggestMenu', sug)"
            @change-product="sug => $emit('changeProduct', sug.suggest_id)"
            @open-manual-input="sug => $emit('openManualInput', sug.suggest_id)"
          />
        </Tab>
        <Tab
          v-if="order.isReplaceItemsMethod"
          :tab_key="ActiveTabKeys.CONFIRM"
          :title="`${$gettext('Согласовать')} · ${needConfirmSuggests.length}`"
        >
          <EmptyScreen
            v-if="!needConfirmSuggests.length"
            :height="96"
          />
          <SuggestsByGroup
            :suggests="needConfirmSuggests"
            :collected="collected"
            @open-details="openDetails"
            @remove-suggest="sug => $emit('removeSuggest', sug)"
            @finish-suggest="sug => $emit('finishSuggest', sug)"
            @open-suggest-menu="sug => $emit('openSuggestMenu', sug)"
            @change-product="sug => $emit('changeProduct', sug.suggest_id)"
            @open-manual-input="sug => $emit('openManualInput', sug.suggest_id)"
          />
        </Tab>
        <Tab
          :tab_key="ActiveTabKeys.PACKED"
          :title="`${$gettext('Собрано')} · ${packedSuggests.length}`"
        >
          <EmptyScreen
            v-if="!packedSuggests.length"
            :height="96"
          />
          <SuggestsByGroup
            :suggests="packedSuggests"
            :collected="collected"
            @open-details="openDetails"
            @remove-suggest="sug => $emit('removeSuggest', sug)"
            @finish-suggest="sug => $emit('finishSuggest', sug)"
            @open-suggest-menu="sug => $emit('openSuggestMenu', sug)"
            @change-product="sug => $emit('changeProduct', sug.suggest_id)"
            @open-manual-input="sug => $emit('openManualInput', sug.suggest_id)"
          />
        </Tab>
      </Tabs>
    </template>
    <template #footer>
      <SuggestsListFooter
        :order="order"
        :collected="collected"
        @finish="checkPayment"
        @to-confirm="$emit('toConfirm')"
      />
    </template>
  </Layout>
</template>

<script setup lang="ts">
import { useConfirmAssembledProducts } from '@/fsd/data/order/useConfirmAssembledProducts';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { EmptyScreen } from '@/fsd/shared/ui/emptyScreen';
import { Tab, Tabs } from '@/fsd/shared/ui/tabs';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Suggest from '@/models/Suggest';
import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';
import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import { useProducts } from '@/store/modules/products';
import { $gettext } from '@/temp/plugins/gettext';
import Layout from '@/ui/common/layout.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import {
  computed, ref, toRefs, watch,
} from 'vue';
import SuggestsByGroup from '../SuggestCard/SuggestsByGroup.vue';
import SuggestsListFooter from './SuggestsListFooter.vue';
import SuggestsListHeader from './SuggestsListHeader.vue';

const productsStore = useProducts();
const { showLoader } = useLoader();
const { confirmAssembledProducts } = useConfirmAssembledProducts();

interface SuggestsListProps {
  order: OrderOrderRetail;
  collected: Record<string, string[]>;
}

enum ActiveTabKeys {
  NO_PACKED = 'no',
  PACKED = 'yes',
  CONFIRM = 'q',
}

const props = defineProps<SuggestsListProps>();
const { order } = toRefs(props);

const emits = defineEmits<{
  (e: 'openDetails', suggest_id: Suggest['suggest_id']): void;
  (e: 'finishSuggest', suggest: Suggest | TrueMarkSuggestWrapper): void;
  (e: 'removeSuggest', suggest: Suggest | TrueMarkSuggestWrapper): void;
  (e: 'openSuggestMenu', suggest: Suggest | TrueMarkSuggestWrapper): void;
  (e: 'updateCollected', suggest: Suggest | TrueMarkSuggestWrapper, barcodes: string[]): void;
  (e: 'changeProduct', suggest_id: Suggest['suggest_id']): void;
  (e: 'openManualInput', suggest_id: Suggest['suggest_id']): void;
  (e: 'toConfirm'): void;
  (e: 'toFinish'): void;
}>();

const activeTabKey = ref<ActiveTabKeys>(ActiveTabKeys.NO_PACKED);

const suggests = computed(() => props.order.suggests);

const nonPackedSuggests = computed(() => props.order.nonPackedSuggest);

const needConfirmSuggests = computed(() => props.order.needConfirmSuggests);

const packedSuggests = computed(() => props.order.packedSuggests);

const { needBarcodeRequest } = useRequestBarcode(async (barcode) => {
  const { closeLoader } = showLoader();
  try {
    const product = await productsStore.getProductByBarcode(barcode);
    const suggest = suggests.value.find(s => s.product_id === product.product_id);
    if (!suggest) {
      Alerts.error($gettext('Отсканирован неверный баркод'));
      return false;
    }
    if (suggest.isDone && suggest.isFull) {
      Alerts.error($gettext('Данный продукт уже собран'));
      return true;
    }
    emits('updateCollected', suggest, [barcode]);
    return true;
  } catch {
    Alerts.error($gettext('Отсканирован неверный баркод'));
    return true;
  } finally {
    closeLoader();
  }
});

const openDetails = (suggest: Suggest | TrueMarkSuggestWrapper) => {
  needBarcodeRequest.value = false;
  emits('openDetails', suggest.suggest_id);
};

watch(
  nonPackedSuggests,
  (suggests) => {
    if (suggests.length) {
      activeTabKey.value = ActiveTabKeys.NO_PACKED;
    } else if (order.value.isReplaceItemsMethod && needConfirmSuggests.value.length) {
      activeTabKey.value = ActiveTabKeys.CONFIRM;
    } else {
      activeTabKey.value = ActiveTabKeys.PACKED;
    }
  },
  { immediate: true },
);

const changeTab = (key: ActiveTabKeys) => {
  activeTabKey.value = key;
};

const checkPayment = () => {
  if (props.order.forFinishPacking.length) {
    confirmAssembledProducts(props.order);
  } else {
    emits('toFinish');
  }
};
</script>
