<template>
  <Layout v-if="!loading">
    <template #header>
      <Bar
        :menu-config="barMenuConfig"
        @close-click="backClick"
      />
    </template>
    <ItemCardInfo
      v-if="item"
      data-test="item-info"
      :item="item"
    />

    <template #footer>
      <LayoutFooter v-if="available && available.length > 0">
        <UiButton
          v-if="needCreateMoveBtn"
          data-test="item card move btn"
          class="mb-2"
          background-color="secondary"
          @click="onMoveButtonClick"
        >
          {{ $gettext('Переместить') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </Layout>
  <InlineLoader v-else />
  <RelatedOrdersPage
    v-if="relatedOrders.visible.value"
    :item-id="item_id"
    @close="relatedOrders.hide"
  />
</template>

<script lang="ts" setup>
import { api } from '@/fsd/data/api/api.service';
import { useCheckItemResources } from '@/fsd/data/utils/checkResourse';
import { useRumPage } from '@/fsd/shared/metrics';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { useComponent } from '@/hooks/useComponent';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Item, { AvailableItem } from '@/models/Item';
import ItemByBarcode from '@/models/ItemByBarcode';
import ProductByBarcode from '@/models/ProductByBarcode';
import ShelfByBarcode from '@/models/ShelfByBarcode';
import { OrderTypeEnum } from '@/models/orders/BaseOrder';
import { AudioService } from '@/services/audio.service';
import { useItems } from '@/store/modules/items';
import { useProducts } from '@/store/modules/products';
import { useUser } from '@/store/modules/user';
import { permits } from '@/temp/constants';
import { $gettext } from '@/temp/plugins/gettext';
import { logger } from '@/temp/plugins/logs';
import { AvailableProduct } from '@/types/product';
import Bar from '@/ui/common/bar/bar.vue';
import InlineLoader from '@/ui/common/inline-loader.vue';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import { MenuItemConfig } from '@/ui/common/menu/types';
import UiButton from '@/ui/common/ui-button.vue';
import RelatedOrdersPage from '@/ui/home/product-card/related-orders-page.vue';
import ItemCardInfo from '@/views/ItemCard/ItemCardInfo.vue';
import {
  computed, toRefs, watch,
} from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps<{
  item_id: Item['item_id'];
}>();

const { item_id } = toRefs(props);

const router = useRouter();
const relatedOrders = useComponent();
const { showLoader } = useLoader();

let { loading } = useCheckItemResources(item_id.value);
watch(item_id, () => {
  const result = useCheckItemResources(item_id.value);
  loading = result.loading;
});

useRumPage(loading);

const item = computed(() => {
  return useItems().itemById(item_id.value);
});

const available = computed((): AvailableItem[] => {
  return useItems().availableById(item_id.value);
});

const needCreateMoveBtn = computed((): boolean => {
  if (useUser().isJunior) {
    const maxWeight = useUser().getMaxWeightForOrder(OrderTypeEnum.move);
    if (item.value && item.value.weight > maxWeight) return false;
  }
  return Boolean(useUser().permitByName(permits.tsd_move));
});

const barMenuConfig = computed((): MenuItemConfig[] => {
  const menuConfig: MenuItemConfig[] = [];

  const showRelatedOrdersBtn: MenuItemConfig = {
    buttonText: $gettext('Показать связанные документы'),
    onClick: () => {
      relatedOrders.show();
    },
  };
  menuConfig.push(showRelatedOrdersBtn);

  return menuConfig;
});

useRequestBarcode(async (barcode) => {
  const { closeLoader } = showLoader(undefined);
  try {
    const { data } = await api.barcode({ barcode });
    closeLoader();
    if (!data || !data.found.length) {
      Alerts.error($gettext('Не найден штрихкод %{barcode}', { barcode }));
      return true;
    }
    const found = data.found[0];
    if (ProductByBarcode.isProductByBarcode(found)) {
      const availableProduct = await filterMultiProduct(data.found, barcode);
      if (!availableProduct) return true;
      await router.push({
        name: 'product-card',
        params: { product_id: availableProduct.product_id },
      });
      return false;
    }

    if (ItemByBarcode.isItemByBarcode(found)) {
      await router.push({
        name: 'item-card',
        params: { item_id: found.item_id },
      });
      return true;
    }

    if (ShelfByBarcode.isShelfByBarcode(found)) {
      await router.push({
        name: 'shelf-card',
        params: { shelf_id: found.shelf_id },
      });
      return false;
    }
    AudioService.playError();
    return true;
  } catch {
    closeLoader();
    Alerts.error($gettext('Не найден штрихкод'));
    return true;
  }
});

const filterMultiProduct = async (found, barcode): Promise<AvailableProduct | undefined> => {
  try {
    if (found.length === 1) {
      return found[0];
    }
    logger.event('MULTI_ITEM_ON_BARCODE', barcode);
    for (const f of found) {
      const available = await useProducts().fetchAvailable(f.product_id);
      // @ts-expect-error pinia
      if (available.length > 0) {
        return f;
      }
    }
    Alerts.error('По этому штрихкоду найдено несколько товаров, сообщите в поддержку');
    return found[0];
  } catch (error) {
    logger.error(error, {
      method: 'filterMultiProduct',
      type: 'home',
    });
  }
};

const onMoveButtonClick = async (): Promise<void> => {
  await router.push({
    name: 'item-card-move',
    params: { item_id: item_id.value },
  });
};

const backClick = () => {
  if ((router.options.history.state.back as string).includes('/shelf')) {
    router.back();
  } else {
    router.push({ name: 'home' });
  }
};
</script>
