import { debugFormat } from '@/temp/constants/dateFormat';
import dayjs from 'dayjs';
import {
  onMounted, onUnmounted, ref,
} from 'vue';

export function useReactiveDate() {
  const date = ref(dayjs().format(debugFormat));
  let interval;

  onMounted(() => {
    interval = setInterval(() => {
      date.value = dayjs().format(debugFormat);
    }, 1000);
  });

  onUnmounted(() => {
    clearInterval(interval);
  });

  return date;
}
