import { BrandingEnum } from '@/fsd/features/tools/useBranding';
import { CurrenciesEnum } from '@/temp/constants/currencies';
import { BaseModel } from 'sjs-base-model';

interface Store {
  estatus: 'processing';
  currency: CurrenciesEnum;
  // experiments
  options: { [key: string]: boolean };
  title: string;
  cluster_title: string;
  options_setup: {
    exp_mc_hammer_timetable: [];
    sherlock_border: number;
  };
}

interface DomainConfigs {
  account?: 'yandex' | string;
  branding?: BrandingEnum;
  domain?: string;
  support_chat_url?: string;
}

export enum CountryCodeEnum {
  UZB = 'UZB',
  OTHER = 'other',
}

/**
 * Название групп, в которых доступна причина закрытия саджеста
 */
export enum SuggestReasonGroup {
  /**
   * Причины несборки
   */
  cancel_reasons = 'cancel_reasons',

  /**
   * Причины списания
   */
  writeoff_reasons = 'writeoff_reasons',

  /**
   * Причины списания при перемещении продукта на полку trash
   */
  product_move_trash = 'product_move_trash',

  /**
   * Причины списания в размещении на этапе review
   */
  sale_stowage_review = 'sale_stowage_review',

  /**
   * Причины списания в размещении на этапе trash
   */
  sale_stowage_trash = 'sale_stowage_trash',

  /**
   * Причины списания в размещении на этапе stowage
   */
  sale_stowage_stowage = 'sale_stowage_stowage',

  /**
   * Причины списания при возврате заказа
   */
  refund_trash = 'refund_trash',

  /**
   * Списание при частичном возврате заказа
   */
  part_refund_trash = 'part_refund_trash',

  /**
   * Причины списания при отмене отгрузки
   */
  shipment_rollback_trash = 'shipment_rollback_trash',
}

export interface SuggestReason {
  /**
   * Название причины, например "Нетоварный внешний вид".
   * Переводится на бекенде, в соответствии с текущим языком пользователя.
   */
  title: string;

  /**
   * Слаг причины. Например, `UNMARKETABLE_APPEARANCE`
   */
  value: string;

  /**
   * Список групп, в которых доступна причина
   */
  groups: SuggestReasonGroup[];
}

interface Company {
  languages: string[];
  data_languages: string[];
  country_code: CountryCodeEnum;

  /**
   * Список причин закрытия саджестов
   */
  suggests_reasons?: SuggestReason[];
}

export default class Constants extends BaseModel {
  public backend_version: string = '';
  public company: Company = {
    languages: [],
    data_languages: [],
    country_code: CountryCodeEnum.OTHER,
    suggests_reasons: [],
  };

  public store: Store = {
    estatus: 'processing',
    title: '',
    cluster_title: '',
    currency: CurrenciesEnum.RUB,
    options: {},
    options_setup: {
      exp_mc_hammer_timetable: [],
      sherlock_border: 2,
    },
  };

  public domain_configs: DomainConfigs = {};

  constructor(data: any) {
    super({ expand: true });
    this.update(data);
  }
}
