<template>
  <div>
    <IconRobot
      v-if="icon === 'robot'"
      :color="iconColor"
    />
  </div>
</template>

<script setup lang="ts">
import IconRobot from '@/fsd/shared/icons/robot/icon-robot.vue';
import { AppendIconNameEnum } from '@/fsd/shared/ui/routeBar/types';
import { computed, defineProps } from 'vue';

const props = defineProps<{
  icon: AppendIconNameEnum;
  chipActive: boolean;
}>();

const iconColor = computed(() => {
  return props.chipActive ? 'blue-normal' : 'black';
});
</script>
