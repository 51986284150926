import Suggest, { SuggestTypeEnum } from '@/models/Suggest';
import { useShelves } from '@/store/modules/shelves';
import { getQuantUnit } from '@/temp/constants/translations/quantUnits';
import { $ngettext } from '@/temp/plugins/gettext';

/**
 * Возвращает заголовок модального окна подтверждения закрытия саджеста
 * @param suggest
 * @param count
 * @param shelf_id
 */
export function getSuggestFinishConfirmTitle(suggest: Suggest, count: number, shelf_id: string) {
  const shelvesStore = useShelves();

  const activeProduct = suggest.product;
  if (activeProduct?.quants && activeProduct?.quants > 1 && activeProduct.quant_unit) {
    if (suggest.type === SuggestTypeEnum.box2shelf) {
      return $ngettext(
        'Вы уверены, что разместили %{count} упаковок этого товара(%{unit_count} %{unit}) на полке %{shelf}?',
        'Вы уверены, что разместили %{count} упаковок этого товара(%{unit_count} %{unit}) на полке %{shelf}?',
        count,
        {
          count: String(count),
          shelf: String(shelvesStore.shelfById(shelf_id)?.title),
          unit_count: String(activeProduct?.quants * count),
          unit: getQuantUnit(activeProduct?.quant_unit),
        },
      );
    } else {
      return $ngettext(
        'Вы уверены, что забрали %{count} упаковок этого товара(%{unit_count} %{unit}) с полки %{shelf}?',
        'Вы уверены, что забрали %{count} упаковок этого товара(%{unit_count} %{unit}) с полки %{shelf}?',
        count,
        {
          count: String(count),
          shelf: String(suggest.shelf?.title),
          unit_count: String(activeProduct?.quants * count),
          unit: getQuantUnit(activeProduct?.quant_unit),
        },
      );
    }
  }
  if (suggest.type === SuggestTypeEnum.box2shelf) {
    return $ngettext(
      'Вы уверены, что разместили %{count} товар на полке %{shelf}?',
      'Вы уверены, что разместили %{count} товаров на полке %{shelf}?',
      count,

      {
        count: String(count),
        shelf: String(shelvesStore.shelfById(shelf_id)?.title),
      },
    );
  } else {
    return $ngettext(
      'Вы уверены, что забрали %{count} товар с полки %{shelf}?',
      'Вы уверены, что забрали %{count} товаров с полки %{shelf}?',
      count,
      {
        count: String(count),
        shelf: String(suggest.shelf?.title),
      },
    );
  }
}
