<template>
  <LayoutFooter class="flex-col gap-2">
    <div class="flex gap-1">
      <UiButton
        v-if="needCreateCheckBtn"
        data-test="product card check btn"
        background-color="secondary"
        @click="emits('check')"
      >
        {{ $gettext('Пересчитать') }}
      </UiButton>
      <!-- Операция изменения СГ не имеет смысла если у товара не заполнено поле valid -->
      <UiButton
        v-if="needCreateChangeValidBtn"
        :is-disabled="product?.valid === null"
        data-test="product card change valid btn"
        background-color="secondary"
        @click="emits('change_valid')"
      >
        {{ $gettext('Изменить СГ') }}
      </UiButton>
    </div>
    <div
      v-if="needCreateMoveBtn || needCreateWriteoffBtn"
      class="flex gap-1"
    >
      <UiButton
        v-if="needCreateMoveBtn"
        data-test="product card move btn"
        background-color="secondary"
        @click="emits('move')"
      >
        {{ $gettext('Переместить') }}
      </UiButton>
      <UiButton
        v-if="needCreateWriteoffBtn"
        data-test="product card writeoff btn"
        background-color="secondary"
        @click="emits('writeoff')"
      >
        {{ $gettext('Списать') }}
      </UiButton>
    </div>
  </LayoutFooter>
</template>

<script lang="ts" setup>
import { OrderTypeEnum } from '@/models/orders/BaseOrder';
import Product, { TypeAccountingEnum } from '@/models/Product';
import { useProducts } from '@/store/modules/products';
import { useUser } from '@/store/modules/user';
import { experiments, permits } from '@/temp/constants';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { computed, toRefs } from 'vue';

const emits = defineEmits(['check', 'change_valid', 'move', 'writeoff']);
const props = defineProps<{ product_id: Product['product_id'] }>();
const { product_id } = toRefs(props);

const productsStore = useProducts();
const userStore = useUser();

const product = computed(() => {
  return productsStore.productById(product_id.value);
});

const available = computed(() => {
  return useProducts().availableByProductId(product_id.value);
});

const needCreateCheckBtn = computed(() => {
  if (!product.value) return false;
  const hasPermit = Boolean(userStore.permitByName(permits.tsd_create_check));

  const isWeightParent = product.value.type_accounting === TypeAccountingEnum.weight && !product.value.parent_id;

  const hasStocks
    = productsStore.hasStocks(product_id.value) || userStore.experimentByName(experiments.exp_condition_zero);
  return hasPermit && !isWeightParent && hasStocks;
});

const needCreateChangeValidBtn = computed(() => {
  if (!product.value) return false;
  const available = productsStore.availableByProductId(product_id?.value);
  return Boolean(userStore.permitByName(permits.tsd_set_valid) && available && available.length > 0);
});

const needCreateWriteoffBtn = computed(() => {
  if (!product.value) return false;
  if (!available.value?.length) return false;
  if (userStore.isJunior) {
    const maxWeight = userStore.getMaxWeightForOrder(OrderTypeEnum.move);
    if (product.value.weight > maxWeight) return false;
  }
  return Boolean(userStore.permitByName(permits.tsd_writeoff));
});

const needCreateMoveBtn = computed(() => {
  if (!product.value) return false;
  if (!available.value?.length) return false;
  if (userStore.isJunior) {
    const maxWeight = userStore.getMaxWeightForOrder(OrderTypeEnum.move);
    if (product.value.weight > maxWeight) return false;
  }
  return Boolean(userStore.permitByName(permits.tsd_move));
});
</script>
