import { api } from '@/fsd/data/api/api.service';
import Item from '@/models/Item';
import ItemByBarcode from '@/models/ItemByBarcode';
import BaseOrder, { OrderTypeEnum } from '@/models/orders/BaseOrder';
import Product, { TypeAccountingEnum } from '@/models/Product';
import ProductByBarcode from '@/models/ProductByBarcode';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import { isString } from '@/models/typeGuards';
import { AudioService } from '@/services/audio.service';
import { ScannerService } from '@/services/scanner/scanner.service';
import { useItems } from '@/store/modules/items';
import { useProducts } from '@/store/modules/products';
import { isProductInRequired } from '@/types/product';
import { useLoader } from '@/ui/common/loader/useLoader';
import { defineComponent } from 'vue';

interface RequestProductCode {
  onScanBarcode?: (barcode: string) => Promise<boolean>;
  checkSuggests?: boolean;
  suggest?: Suggest;
}

interface RequestProductCodeResult {
  product?: Product;
  findSuggest?: Suggest;
  item?: Item;
  barcode: string;
  parentProduct?: Product;
  childProduct?: Product;
  allResults?: (Product | Item)[];
}

interface FindedProduct {
  product?: Product;
  findSuggest?: Suggest;
  item?: Item;
  parentProduct?: Product;
  childProduct?: Product;
}

export default defineComponent({
  setup() {
    const { showLoader } = useLoader();
    return { showLoader };
  },
  computed: {
    // Это, фактически, проверка на весовой продукт. Поэтому было бы идеально выполнять эту провервку в модельке
    // весового продукта, которой пока нет
    isSimpleBarcode(): (barcode: string) => boolean {
      return (barcode) => {
        return (barcode.length !== 13 && barcode.length !== 18) || (barcode[0] !== '2' && barcode[0] !== '6');
      };
    },
    order(): BaseOrder | undefined {
      return undefined;
    },
  },
  methods: {
    productBySuggest(products: (Product | Item)[], suggestWithProduct): Product | Item {
      return products.find((p) => {
        if (isProductInRequired(p)) {
          return p.product_id === suggestWithProduct.product_id;
        }
        if (Item.isItemInRequired(p)) {
          return p.item_id === suggestWithProduct.product_id;
        }
      })!;
    },
    async requestProductCode({
      onScanBarcode,
      checkSuggests = false,
      suggest,
    }: RequestProductCode): Promise<RequestProductCodeResult> {
      const result: RequestProductCodeResult = {
        product: undefined,
        findSuggest: undefined,
        item: undefined,
        barcode: '', // +
        parentProduct: undefined,
        childProduct: undefined,
        allResults: undefined, // +
      };
      const barcode = await ScannerService.requestCode(this.$options.name + this._uuid);
      result.barcode = barcode;
      if (onScanBarcode) {
        const next = await onScanBarcode(barcode);
        if (!next) return result;
      }

      const { closeLoader } = this.showLoader(this.$gettext('Запрашиваем продукт по штрихкоду'));
      try {
        const results = await this.checkBarcode(barcode);
        result.allResults = results;

        if (checkSuggests) {
          const {
            product, childProduct, parentProduct, findSuggest, item,
          } = await this.findNeededProduct(
            results,
            barcode,
          );
          result.product = product;
          result.childProduct = childProduct;
          result.parentProduct = parentProduct || (await this.loadParent(product));
          result.findSuggest = findSuggest;
          result.item = item;
          if (!childProduct) {
            if (product?.parent_id) {
              result.childProduct = product;
            }
          }
        } else {
          let finded: Product | Item | undefined = results[0];
          if (!finded) {
            this.$alert.error(this.$gettext('Отсканирован родительский ШК'));
            throw new Error('Parent product');
          }
          if (suggest) {
            finded = results.find((p) => {
              if (Product.isProduct(p)) return p.product_id === suggest.product_id;
              if (Item.isItem(p)) return p.item_id === suggest.product_id;
            });
            if (!finded) {
              // если в саджесте продукт, то нужно проверить есть ли общий родитель у отсканированного и того, что в саджесте
              if (suggest.product) {
                const hasCommonParent
                  = suggest.product.type_accounting === TypeAccountingEnum.weight
                    && results.some(
                      p =>
                        Product.isProduct(p)
                        && p.type_accounting === TypeAccountingEnum.weight
                        // @ts-expect-error pinia
                        && p.parent_id === suggest.product.parent_id,
                    );
                if (hasCommonParent) {
                  this.$alert.error(this.$gettext('Отсканирован не тот вес'));
                  throw new Error('wrong weight');
                } else {
                  this.$alert.error(this.$gettext('Отсканирован не тот товар'));
                  throw new Error('requestProductCode not found');
                }
              }
              // если в саджесте посылка, то оставляем все как есть.
              this.$alert.error(this.$gettext('Отсканирован неверный штрихкод %{barcode}', { barcode }));
              throw new Error('requestProductCode not found');
            }
          }
          if (Product.isProduct(finded)) result.product = finded;
          if (Item.isItem(finded)) result.item = finded;
        }

        return result;
      } catch (error) {
        console.error(error);
        throw new Error('requestProductCode');
      } finally {
        closeLoader();
      }
    },
    async checkBarcode(barcode): Promise<(Product | Item)[]> {
      try {
        if (this.isSimpleBarcode(barcode)) {
          //  Простой случай, можно попробовать найти по баркоду в сторе
          const products = useProducts().getProductsByBarcodeLocal(barcode);
          if (products.length) return products;
          const item = useItems().itemByBarcode(barcode);
          if (item) return [item];
        }
        //  если ничего не нашли локально, то делаем запрос по ручке баркод
        const { data } = await api.barcode({ barcode });
        const founds = data.found.filter(item => item.type === 'product' || item.type === 'item');
        if (founds.length === 0) {
          this.$alert.error(this.$gettext('Не найден штрихкод %{barcode}', { barcode }));
          throw new Error('check barcode');
        }
        const product_ids = founds.filter(ProductByBarcode.isProductByBarcode).map(p => p.product_id);
        const item_ids = founds.filter(ItemByBarcode.isItemByBarcode).map(i => i.item_id);
        const results: (Product | Item)[] = [];
        if (product_ids.length > 0) {
          // Используем хук не в setup, так как из-за миксина возможна коллизия имён
          const products = await useProducts().getProductsByIds(product_ids);
          if (products) {
            // TODO место для модельки
            // фильтруем весовых родителей. проводить с ними операции нельзя, поэтому не даем их сканировать
            for (const product of products) {
              if (product.type_accounting !== TypeAccountingEnum.weight || product.parent_id) {
                results.push(product);
              } else {
                // нужно проверить детей. если они НЕ весовые то разрешаем идти дальше
                if (product.children_id?.length) {
                  // Если есть дети
                  // Используем хук не в setup, так как из-за миксина возможна коллизия имён
                  const child = await useProducts().getProductById(product.children_id[0]);
                  // И они не весовые
                  if (child?.type_accounting === TypeAccountingEnum.unit) {
                    results.push(product);
                  }
                }
              }
            }
          }
        }
        if (item_ids.length > 0) {
          const items = await useItems().getItemsByIds(item_ids);
          if (items) {
            results.push(...items);
          }
        }
        return results;
      } catch (error) {
        console.error(error);
        AudioService.playError();
        throw error;
      }
    },

    async findNeededProduct(products: (Product | Item)[], barcode: string): Promise<FindedProduct> {
      const result: FindedProduct = {
        product: undefined,
        findSuggest: undefined,
        item: undefined,
        parentProduct: undefined,
        childProduct: undefined,
      };
      const suggests = this.order?.suggests!;
      const suggestsWithProduct = this.getSuggestsWithProducts(suggests, products);
      if (suggestsWithProduct.length === 0) {
        if (!this.isSimpleBarcode(barcode)) {
          const {
            parent, child, suggest,
          } = await this.checkParents(products);
          if (suggest) {
            result.product = parent;
            result.parentProduct = parent;
            result.childProduct = child;
            result.findSuggest = suggest;
            return result;
          }
        }
        if (this.order?.type === OrderTypeEnum.acceptance && this.order?.attr.acceptance_mode === 'pre-check') {
          result.product = products.find(Product.isProduct);
          return result;
        }
        this.$alert.error(this.$gettext('Продукта нет в задании'));
        throw new Error('this product is not in order');
      }
      if (suggestsWithProduct.length === 1) {
        result.findSuggest = suggestsWithProduct[0];
        const product = this.productBySuggest(products, suggestsWithProduct[0]);
        if (Product.isProduct(product)) result.product = product;
        if (Item.isItem(product)) result.item = product;
        return result;
      }
      if (suggestsWithProduct.length > 1) {
        const requestSuggest = suggestsWithProduct.find(s => s.status === SuggestStatusEnum.request);
        if (requestSuggest) {
          result.findSuggest = requestSuggest;
          const product = this.productBySuggest(products, requestSuggest);
          if (Product.isProduct(product)) result.product = product;
          if (Item.isItem(product)) result.item = product;
          return result;
        } else {
          result.findSuggest = suggestsWithProduct[0];
          const product = this.productBySuggest(products, suggestsWithProduct[0]);
          if (Product.isProduct(product)) result.product = product;
          if (Item.isItem(product)) result.item = product;
          return result;
        }
      }
      return result;
    },

    getSuggestsWithProducts(suggests: Suggest[], products: (Product | Item)[]): Suggest[] {
      return suggests.filter((s) => {
        return products.find((p) => {
          if (Product.isProduct(p)) {
            return p.product_id === s.product_id;
          }
          if (Item.isItem(p)) {
            return p.item_id === s.product_id;
          }
        });
      });
    },

    async checkParents(
      required: (Product | Item)[],
    ): Promise<{
        suggest?: Suggest;
        parent?: Product;
        child?: Product;
      }> {
      const result: any = {
        suggest: undefined,
        parent: undefined,
        child: undefined,
      };
      const products: Product[] = required.filter(Product.isProduct);
      if (products.length === 0) {
        // если нет продуктов в найденном, то нет смысла проверять родителей
        return result;
      }
      const parentIds = products.map(p => p.parent_id).filter(isString);
      if (parentIds.length === 0) {
        return result;
      }
      // Используем хук не в setup, так как из-за миксина возможна коллизия имён
      const parents = await useProducts().getProductsByIds(parentIds);
      if (!parents || parents.length === 0) {
        return result;
      }
      const suggests = (this as any).order && (this as any).order.suggests;
      const suggestsWithProduct = suggests.filter((s) => {
        return parents.find((p) => {
          return p.product_id === s.product_id;
        });
      });
      if (suggestsWithProduct.length === 0) {
        return result;
      }
      if (suggestsWithProduct.length === 1) {
        result.suggest = suggestsWithProduct[0];
        const product = this.productBySuggest(parents, suggestsWithProduct[0]) as Product;
        result.parent = product;
        result.child = required.find(r => Product.isProduct(r) && r.parent_id === product.product_id);
        return result;
      }
      if (suggestsWithProduct.length > 1) {
        const requestSuggest = suggestsWithProduct.find(s => s.status === SuggestStatusEnum.request);
        if (requestSuggest) {
          result.suggest = requestSuggest;
          const product = this.productBySuggest(parents, requestSuggest) as Product;
          result.parent = product;
          result.child = required.find(r => Product.isProduct(r) && r.parent_id === product.product_id);
          return result;
        } else {
          result.suggest = suggestsWithProduct[0];
          const product = this.productBySuggest(products, suggestsWithProduct[0]) as Product;
          result.parent = product;
          result.child = required.find(r => Product.isProduct(r) && r.parent_id === product.parent_id);
          return result;
        }
      }
      return result;
    },
    async loadParent(product): Promise<Product | undefined> {
      if (product?.parent_id) {
        // Используем хук не в setup, так как из-за миксина возможна коллизия имён
        return await useProducts().getProductById(product.parent_id);
      }
    },
  },
});
