<template>
  <Layout>
    <template #header>
      <FinishOrderHeader :order="order" />
    </template>
    <template #default>
      <div
        v-if="currentStep === FinishStepEnum.empty"
        class="flex flex-col gap-4 p-4"
      >
        <Title2>{{ $gettext('В заказе не был собран ни один товар') }}</Title2>

        <Body2>{{ $gettext('Обратитесь в поддержку для отмены заказа') }}</Body2>
      </div>
      <SortingStageWrapper
        v-if="currentStep === FinishStepEnum.sort"
        :suggests="order.forFinishPacking"
        @done="nextStep"
      />
      <SelectBagsCountStage
        v-if="currentStep === FinishStepEnum.count"
        :order_id="order?.order_id"
        :packing="order.packingSuggests"
        :suggests="order.forFinishPacking"
        :has-next-step="true"
        @done="nextStep"
      />
      <div
        v-if="currentStep === FinishStepEnum.qrcode"
        ref="wrapperRef"
        class="flex flex-col gap-4 p-4"
      >
        <Title2>{{ $gettext('Оплатите заказ на кассе') }}</Title2>
        <Body2>
          {{ $gettext('Покажите QR-код кассир или отсканируйте его самостоятельно на кассе самообслуживания.') }}
        </Body2>
        <div class="flex flex-row items-center justify-between bg-gray-200 px-3 py-5 rounded-2xl">
          <Body2>{{ $gettext('Сумма заказа') }}</Body2>
          <Body2 class="font-bold">
            {{ `${order.totalPrice.toLocaleString()} ${getCurrency()}` }}
          </Body2>
        </div>
        <QrcodeVue
          :value="'515&' + order.external_id"
          :size="width"
          :margin="8"
        />
      </div>
      <div
        v-if="currentStep === FinishStepEnum.finish"
        class="flex flex-col items-center justify-center h-full gap-4 px-8"
      >
        <img
          class="icon"
          src="../../../../fsd/shared/icons/icon-full-completed.png"
        >
        <Title2 class="font-bold">
          {{ $gettext('Заказ оплачен') }}
        </Title2>
        <Body2 class="text-center">
          {{ $gettext('Поместите пакеты с заказом в стеллаж для выдачи заказов') }}
        </Body2>
      </div>
    </template>
    <template
      v-if="
        currentStep === FinishStepEnum.finish ||
          currentStep === FinishStepEnum.qrcode ||
          currentStep === FinishStepEnum.empty
      "
      #footer
    >
      <FinishOrderFooter
        :order="order"
        :step="currentStep"
        @finish-order="finishOrder"
        @next="nextStep"
      />
    </template>
  </Layout>
</template>

<script setup lang="ts">
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import SelectBagsCountStage from '@/fsd/widgets/order/finish-order/SelectBags/SelectBagsCountStage.vue';
import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { getCurrency } from '@/temp/constants/currencies';
import { $gettext } from '@/temp/plugins/gettext';
import Layout from '@/ui/common/layout.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import Title2 from '@/ui/common/typo/title-2.vue';
import { FinishStepEnum } from '@/views/OrderRetail/types';
import FinishOrderFooter from '@/views/OrderRetail/ui/FinishOrder/FinishOrderFooter.vue';
import FinishOrderHeader from '@/views/OrderRetail/ui/FinishOrder/FinishOrderHeader.vue';
import SortingStageWrapper from '@/views/OrderRetail/ui/FinishOrder/sort/SortingStageWrapper.vue';
import QrcodeVue from 'qrcode.vue';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

interface OrderRetailFinishProps {
  order: OrderOrderRetail;
}

const router = useRouter();

const props = defineProps<OrderRetailFinishProps>();

const wrapperRef = ref<HTMLDivElement>();

const stepIndex = ref<number>(0);

const width = computed<number>(() => {
  const wrapperWidth = wrapperRef.value?.clientWidth;
  return wrapperWidth ? wrapperWidth - 32 : 200;
});

const steps = computed<FinishStepEnum[]>(() => {
  if (!props.order.forFinishPacking.length) {
    return [FinishStepEnum.empty];
  }
  let steps = [FinishStepEnum.sort, FinishStepEnum.count, FinishStepEnum.qrcode, FinishStepEnum.finish];

  if (useUser().experimentByName(experiments.exp_shelf_picking_skip_qr_code)) {
    steps = steps.filter(step => step !== FinishStepEnum.qrcode);
  }

  return steps;
});

const currentStep = computed<FinishStepEnum>(() => {
  return steps.value[stepIndex.value];
});

const nextStep = () => {
  stepIndex.value++;
};

const finishOrder = async () => {
  const result = await useEndOrder(props.order.order_id);
  if (result) router.push({ name: 'home' });
};
</script>
