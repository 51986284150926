<template>
  <LoginBg
    v-if="theme === ThemeEnum.main"
    class="absolute h-full w-full pointer-events-none"
  />
  <LoginBgDark
    v-if="theme === ThemeEnum.dark"
    class="absolute h-full w-full pointer-events-none"
  />
  <LoginBgDeli
    v-if="theme === ThemeEnum.deli"
    class="absolute h-full w-full pointer-events-none"
  />
</template>

<script lang="ts" setup>
import { ThemeEnum, useUser } from '@/store/modules/user';
import LoginBgDark from '@/views/Login/Backgrounds/login-bg-dark.vue';
import LoginBgDeli from '@/views/Login/Backgrounds/login-bg-deli.vue';
import LoginBg from '@/views/Login/Backgrounds/login-bg.vue';
import { computed } from 'vue';

const theme = computed((): ThemeEnum => {
  return useUser().theme;
});
</script>
