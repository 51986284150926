<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7846_11829)">
      <rect
        width="40"
        height="40"
        fill="#2A2928"
      />
      <path
        d="M15.3825 28.1409V9.60986H9.01953V24.5509C9.01953 29.0939 11.5965 33.0039 18.4775 33.4619C16.4135 32.8529 15.3825 31.1289 15.3825 28.1409ZM25.5515 9.61086V28.1409C25.5515 30.8129 24.7175 32.4739 23.0505 33.2309C28.2555 32.2479 30.3195 28.7349 30.3195 24.3549V9.60986H25.5525L25.5515 9.61086Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7846_11829">
        <rect
          width="40"
          height="40"
          rx="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
