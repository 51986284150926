<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 30 30"
  >
    <path
      d="M2.7 3.7C1.4 4.9 2 6.1 5.8 9.5c6.4 6 8.8 3.7 2.8-2.8C5.3 3 4 2.4 2.7 3.7z"
      fill="currentColor"
    />
    <path
      d="M17.7 4.8c-1 1-1.7 2.7-1.7 3.8 0 1.1-2.7 4.6-6 7.9-6.3 6.2-7.5 9.7-3.5 10.3 1.3.2 4-1.7 8-5.8 3.8-3.8 6.8-6 8.2-6 1.3 0 2.9-1 3.9-2.5 2.1-3.2 1.4-5.4-.9-3-3.4 3.3-7.4-1-4.2-4.5 1.7-1.9 1.7-2-.2-2-1.1 0-2.7.8-3.6 1.8zM8 24c0 .5-.4 1-1 1-.5 0-1-.5-1-1 0-.6.5-1 1-1 .6 0 1 .4 1 1zM18.7 19.8c-1.6 1.7-1.5 2 1.2 4.7 1.8 1.7 3.6 2.5 4.5 2.2 2.4-.9 1.9-3-1.2-6-2.8-2.6-2.9-2.7-4.5-.9z"
      fill="currentColor"
    />
  </svg>
</template>
