import BaseOrder from '@/models/orders/BaseOrder';
import Product from '@/models/Product';
import { useProducts } from '@/store/modules/products';
import { useSuggests } from '@/store/modules/suggests';

/**
 * Ф-я получает массив продуктов и пробует отфильтровать его до 1 элемента используя саджесты и стоки склада
 *
 * @async
 * @param {Product[]} products
 * @param {BaseOrder['order_id']} order_id
 * @returns {Promise<Product>}
 */
export const tryFilterProducts = async (products: Product[], order_id: BaseOrder['order_id']): Promise<Product> => {
  if (products.length === 1) return products[0];
  products = filterProductsBySuggests(products, order_id);
  if (products.length === 1) return products[0];
  products = await tryFilterProductsByStocks(products);
  if (products.length === 1) return products[0];
  //   Никак не понять, что хотел пользователь - берем просто первый по очереди продукт
  return products[0];
};

/**
 * Фильтруем список продуктов по саджестам: если есть саджест с продуктом - пускаем дальше
 *
 * @param {Product[]} products
 * @param {BaseOrder['order_id']} order_id
 * @returns {Product[]}
 */
const filterProductsBySuggests = (products: Product[], order_id: BaseOrder['order_id']): Product[] => {
  const suggests = useSuggests().suggestsByOrderId(order_id);
  if (!suggests) return products;

  const productsSet = new Set();
  for (const s of suggests.values()) {
    productsSet.add(s.product_id);
  }

  return products.filter(p => productsSet.has(p.product_id));
};

/**
 * Ищем активные остатки по складу или товары, что были на складе недавно и фильтруем список по ним.
 *
 * @async
 * @param {Product[]} products
 * @returns {Promise<Product[]>}
 */
const tryFilterProductsByStocks = async (products: Product[]): Promise<Product[]> => {
  for (const p of products) {
    const available = await useProducts().fetchAvailable(p.product_id);
    const hasAvailables = available && available.length > 0;
    const hasStocks = useProducts().hasStocks(p.product_id);
    if (hasAvailables || hasStocks) {
      return [p];
    }
  }
  return products;
};
