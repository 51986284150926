<template>
  <div class="flex flex-col gap-1">
    <div
      class="rounded py-0.5 px-1 flex justify-center items-center w-max h-min"
      :class="containerClass"
    >
      <Caption2
        class="font-bold"
        color="white"
      >
        {{ REPORT_ITEM_STATUS_TEXT[status] }}
      </Caption2>
    </div>
    <Caption1
      v-if="status === REPORT_ITEM_STATUS.ready"
      class="font-medium"
      color="semantic-text-minor"
    >
      {{
        $gettext('Ошибка записана')
      }}
    </Caption1>
    <Caption1
      v-if="status === REPORT_ITEM_STATUS.error"
      class="font-medium"
      color="semantic-text-minor"
    >
      {{
        $gettext('Нет знака или товара')
      }}
    </Caption1>
  </div>
</template>

<script setup lang="ts">
import { $gettext } from '@/temp/plugins/gettext';
import Caption1 from '@/ui/common/typo/caption-1.vue';
import Caption2 from '@/ui/common/typo/caption-2.vue';
import { computed, defineProps } from 'vue';
import { REPORT_ITEM_STATUS } from './constants';
import { getTrueMarkType } from '@/fsd/entities/true-mark/utils';

interface ReportItemProps {
  status: REPORT_ITEM_STATUS;
}

const REPORT_ITEM_STATUS_TEXT: Record<REPORT_ITEM_STATUS, string> = {
  [REPORT_ITEM_STATUS.ready]: $gettext('ОТСКАНИРОВАН'),
  [REPORT_ITEM_STATUS.error]: $gettext('НЕЛЬЗЯ ОТСКАНИРОВАТЬ'),
  [REPORT_ITEM_STATUS.waiting]: $gettext('Отсканируйте «%{markType}»', { markType: getTrueMarkType() }).toUpperCase(),
};

const props = defineProps<ReportItemProps>();

const containerClass = computed(() => ({
  'bg-green-normal': props.status === REPORT_ITEM_STATUS.ready,
  'bg-semantic-bg-invert opacity-50': props.status === REPORT_ITEM_STATUS.waiting,
  'bg-orange-normal': props.status === REPORT_ITEM_STATUS.error,
}));
</script>
