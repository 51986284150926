<template>
  <CardBlock
    :num="numCollected"
    :data-index="suggest.product_id"
    data-test="suggest-card"
  >
    <div class="flex flex-row px-2 py-4 justify-between">
      <SuggestCardInfo
        :collected="numCollected"
        :suggest="suggest"
      />
      <slot name="right-button" />
    </div>
    <slot name="childrenSuggests" />
    <slot name="buttons" />
  </CardBlock>
</template>

<script setup lang="ts">
import { SuggestCardInfo } from '@/fsd/entities/suggest';
import { CardBlock } from '@/fsd/shared/ui/cardBlock';
import Product from '@/models/Product';
import Suggest from '@/models/Suggest';
import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';
import { computed, defineProps } from 'vue';

interface SuggestCardProps {
  suggest: Suggest | TrueMarkSuggestWrapper;
  collected: string[] | number;
}

const props = defineProps<SuggestCardProps>();

const numCollected = computed<number>(() => {
  if (!props.collected) return 0;
  if (typeof props.collected === 'number') return props.collected;

  if (props.suggest.product?.isTrueWeight) {
    return Product.weightFromBarcode(props.collected);
  } else {
    return props.collected.length;
  }
});
</script>
