<template>
  <div
    v-if="activeStep === 'shelf'"
    class="p-4"
  >
    <Hint class="mb-2">
      {{ hintText }}
    </Hint>
    <UiButton :disabled="true">
      {{ $gettext('Завершить') }}
    </UiButton>
  </div>
</template>
<script setup lang="ts">
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Item from '@/models/Item';
import Product from '@/models/Product';
import Shelf, { ShelfTypeEnum } from '@/models/Shelf';
import Suggest, { SuggestTypeEnum } from '@/models/Suggest';
import BaseOrder, { OrderTypeEnum, OrderWorkStatusEnum } from '@/models/orders/BaseOrder';
import { useShelves } from '@/store/modules/shelves';
import { experiments } from '@/temp/constants';
import { $gettext } from '@/temp/plugins/gettext';
import Hint from '@/ui/common/hint/hint.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import {
  computed, defineEmits, defineProps, ref,
} from 'vue';
import UiButton from '../../ui-button.vue';
import { useUser } from '@/store/modules/user';

const { showLoader } = useLoader();
const shelvesStore = useShelves();
const userStore = useUser();

interface ShelfFieldProps {
  activeStep: string;
  order: BaseOrder;
  product?: Product;
  item?: Item;
  suggest: Suggest;
}

const props = defineProps<ShelfFieldProps>();

const emits = defineEmits<{ (e: 'confirmShelf', shelf?: Shelf): void }>();

const shelfRef = ref<Shelf | undefined>(undefined);

const shelf = computed<Shelf>(() => shelvesStore.shelfById(props.suggest.shelf_id)!);

const hintText = computed<string>(() => {
  const hintTexts = {
    default: $gettext('Отсканируйте полку на которую кладете товар'),
    refund: $gettext('Отсканируйте указанную полку'),
    part_refund: $gettext('Отсканируйте указанную полку'),
    stowage: $gettext('Отсканируйте указанную полку'),
    sale_stowage: $gettext('Отсканируйте указанную полку'),
    stowage_market: $gettext('Отсканируйте подходящую полку'),
    hand_move: $gettext('Отсканируйте полку %{shelf}', { shelf: shelf.value?.title }),
    kitchen_provision: $gettext('Отсканируйте полку %{shelf}', { shelf: shelf.value?.title }),
    robot_provision: $gettext('Отсканируйте полку %{shelf}', { shelf: shelf.value?.title }),
  };

  if (
    props.order.type === OrderTypeEnum.robot_provision
    && !props.suggest.isTargetCanceledOrFailed
    && props.suggest.type === SuggestTypeEnum.box2shelf
  ) {
    return hintTexts.default;
  }

  if (
    [
      OrderTypeEnum.writeoff_prepare_day,
      OrderTypeEnum.check_valid_regular,
      OrderTypeEnum.check_valid_short,
    ].includes(props.order.type)
  ) {
    return hintTexts['control'];
  }
  return hintTexts[props.order.type] || hintTexts['default'];
});

const onCancelDisabledShelfConfirm = (): void => {
  shelfRef.value = undefined;
  needBarcodeRequest.value = true;
};
const onInputShelfConfirm = (shelf?: Shelf): void => {
  needBarcodeRequest.value = false;
  emits('confirmShelf', shelf);
};
const onOkDisabledShelfConfirm = (): void => {
  onInputShelfConfirm(shelfRef.value as Shelf);
  shelfRef.value = undefined;
};

const checkKitchenComponentsShelfConditions = (shelf: Shelf): boolean => {
  const baseShelfsForKitchenComponents = shelf.type === ShelfTypeEnum.kitchen_components || shelf.type === ShelfTypeEnum.kitchen_trash || shelf.type === ShelfTypeEnum.review;

  // Проверка только для размещения
  if (props.order.type === OrderTypeEnum.sale_stowage && props.suggest.isKitchenComponents) {
    // Для ордера в статусе processing доступные полки - kitchen_components, trash, kitchen_trash и review
    if (props.order.status === OrderWorkStatusEnum.processing) {
      return baseShelfsForKitchenComponents || shelf.type === ShelfTypeEnum.trash;
    }
    // Для других статусов доступны полки kitchen_components, kitchen_trash и review
    return baseShelfsForKitchenComponents;
  }
  return true;
};

const checkValidShelf = (shelf: Shelf): boolean => {
  if (userStore.experimentByName(experiments.exp_components_for_kitchen) && !checkKitchenComponentsShelfConditions(shelf)) {
    Modal.show({
      title: $gettext('Вам нужна полка «КФ» или «ПФ»'),
      text: $gettext('Отсканируйте одну из них и затем разместите там товар'),
    });
    return false;
  }

  if (shelf.shelf_id === props.suggest.shelf_id) {
    return true;
  }
  if (!props.suggest.conditions.error) {
    Alerts.error($gettext('Отсканирована неверная полка'));
    return false;
  }
  if (
    [OrderTypeEnum.sale_stowage].includes(props.order.type)
    && props.order.attr.returnable_supplier
    && shelf.type === ShelfTypeEnum.trash
  ) {
    Modal.show({
      title: $gettext('Нельзя размещать товар от возвратного поставщика на полку Списание'),
    });
    return false;
  }
  if (
    [
      OrderTypeEnum.stowage_market,
      OrderTypeEnum.sale_stowage,
      OrderTypeEnum.weight_stowage,
      OrderTypeEnum.kitchen_provision,
      OrderTypeEnum.robot_provision,
    ].includes(props.order.type)
    && props.suggest.conditions.error
  ) {
    if (props.item) {
      if ([ShelfTypeEnum.parcel, ShelfTypeEnum.parcel_returned].includes(shelf.type)) {
        return true;
      }
      Alerts.error($gettext(`Полка не соответствует зоне хранения`));
      return false;
    }
    const tags = props.product?.tags || [];
    switch (true) {
      case tags.length === 0 && shelf.tags.length === 0:
      case tags[0] && shelf.tags.includes(tags[0]):
        if (shelf.status === 'disabled') {
          shelfRef.value = shelf;
          needBarcodeRequest.value = false;
          Modal.show({
            title: $gettext('Полка отключена.'),
            text: $gettext('Размещение товара - включит ее на складе.'),
            btnPosition: ButtonPositionsEnum.vertical,
            confirmBtnTitle: $gettext('Да, разместить'),
            closeBtnTitle: $gettext('Нет, выбрать другую'),
            onClose: () => {
              onCancelDisabledShelfConfirm();
            },
            onConfirm: () => {
              onOkDisabledShelfConfirm();
            },
          });
          return false;
        }
        return true;
      case shelf.type === ShelfTypeEnum.trash:
        return true;
      default:
        Alerts.error($gettext(`Полка не соответствует зоне хранения`));
        return false;
    }
  }
  Alerts.error($gettext('Отсканирована неверная полка'));
  return false;
};

const { needBarcodeRequest } = useRequestBarcode(async (barcode) => {
  const { closeLoader } = showLoader();
  try {
    const shelf = await shelvesStore.getShelfByBarcode(barcode); // getShelfByBarcode(barcode);
    if (!shelf) {
      Alerts.error($gettext('Произошла ошибка при загрузке полки'));
      return true;
    }
    if (checkValidShelf(shelf)) {
      onInputShelfConfirm(shelf);
      return false;
    }
    return true;
  } catch {
    Alerts.error($gettext('Не найден штрихкод'));
    return true;
  } finally {
    closeLoader();
  }
});
</script>
