export const enum CardTypeEnum {
  pause = 'pause',
  rover = 'rover',
  default = 'default',
}
export const enum IconTypeEnum {
  fragile = 'fragile',
  heavy = 'heavy',
  robot = 'robot',
}
