import {
  errorHandler, eventHandler, logHandler,
} from '@/temp/plugins/logs/handlers';

export type EventName = string;

export type EventValue = any;

export type ILogger = typeof logHandler & {
  error: typeof errorHandler;
  event: typeof eventHandler;
};

export enum LogSourcesEnum {
  logger = 'logger',
  sendEvent = 'sendEvent',
  sendError = 'sendError',
}
