import Suggest from '@/models/Suggest';
import { shelf2BoxRetail } from './shelf2boxRetail';
import type { Shelf2BoxRetail } from '@/views/OrderRetail/types';

/**
 * Обнуляет массив саджестов
 * @param suggests
 */
export const removeSuggestsList = async (suggests: Suggest[], reason?: Shelf2BoxRetail['reason']): Promise<boolean> => {
  const result = await Promise.allSettled(
    suggests.map((suggest) => {
      return shelf2BoxRetail({
        suggest,
        reason,
      });
    }),
  );

  return result.every(r => r);
};
