<template>
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.10615 1.96277C5.9484 0.5059 8.05149 0.505902 8.89374 1.96277L13.1961 9.40466C14.0392 10.863 12.9868 12.687 11.3023 12.687L2.6976 12.687C1.0131 12.687 -0.0392899 10.863 0.803809 9.40466L5.10615 1.96277ZM7.00005 4.37451C7.36249 4.37451 7.6563 4.66832 7.6563 5.03076V6.78076C7.6563 7.1432 7.36249 7.43701 7.00005 7.43701C6.63762 7.43701 6.3438 7.1432 6.3438 6.78076V5.03076C6.3438 4.66832 6.63762 4.37451 7.00005 4.37451ZM7.87505 9.40576C7.87505 9.88901 7.4833 10.2808 7.00005 10.2808C6.5168 10.2808 6.12505 9.88901 6.12505 9.40576C6.12505 8.92251 6.5168 8.53076 7.00005 8.53076C7.4833 8.53076 7.87505 8.92251 7.87505 9.40576Z"
      :fill="colorValue"
    />
  </svg>
</template>

<script setup lang="ts">
import { useColor } from '@/fsd/shared/hooks';

interface Props {
  color?: string;
}

const { color = 'white' } = defineProps<Props>();
const params = {
  color: () => color,
};
const { colorValue } = useColor(params);
</script>
