<template>
  <Layout>
    <template #header>
      <slot
        v-if="!loading && order"
        name="header"
      />
    </template>
    <template #default>
      <slot
        v-if="!loading && order"
        name="default"
      />
      <div
        v-else-if="loading"
        class="h-full flex flex-col items-center justify-center"
      >
        <LoaderIndicator class="mb-4" />
        <body1 class="mb-2">
          {{ $gettext('Загрузка') }}
        </body1>
        <caption1 class="mb-2">
          {{ $gettext('Пожалуйста, подождите') }}
        </caption1>
      </div>
      <div
        v-else
        class="h-full flex flex-col items-center justify-center"
      >
        <LoaderIndicator class="mb-4" />
        <body1 class="mb-2 text-wrap text-center">
          {{ $gettext('Документ недоступен, попробуйте ещё раз') }}
        </body1>
        <caption1 class="mb-2">
          {{ $gettext('Покинуть экран?') }}
        </caption1>
        <div class="w-28">
          <UiButton

            type="small"
            @click="router.push({ name: 'home' })"
          >
            {{ $gettext('На главную') }}
          </UiButton>
        </div>
      </div>
    </template>
    <template #footer>
      <slot
        v-if="!loading && order"
        name="footer"
      />
    </template>
  </Layout>
</template>
<script lang="ts" setup>
import { useCheckOrderResources } from '@/fsd/data/utils/checkResourse';
import { rumSpaManager } from '@/fsd/shared/tools/RumSpaManager';
import BaseOrder from '@/models/orders/BaseOrder';
import { useOrders } from '@/store/modules/orders';
import Layout from '@/ui/common/layout.vue';
import LoaderIndicator from '@/ui/common/loader-indicator.vue';
import { computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import UiButton from '@/ui/common/ui-button.vue';
import { $gettext } from '@/temp/plugins/gettext';

const props = defineProps<{ order_id: BaseOrder['order_id'] }>();

const { loading } = useCheckOrderResources(props.order_id);
const order = computed(() => {
  return useOrders().orderById(props.order_id);
});
const router = useRouter();

// #region RUM
const route = useRoute();
const pageName = route.name as string;
rumSpaManager.startDataLoading(pageName);
if (!loading.value) {
  // Если данные есть, то сразу ставим индикатор, что мы их отрисовываем
  rumSpaManager.finishDataLoading(pageName, true);
  rumSpaManager.startDataRendering(pageName);
} else {
  // Если данных не хватает, то ждем их загрузку
  const unWatch = watch(loading, (val) => {
    if (!val) {
      unWatch();
      rumSpaManager.finishDataLoading(pageName);
      rumSpaManager.startDataRendering(pageName);
    }
  });
}
// #endregion
</script>
