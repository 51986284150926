<template>
  <div class="main-container">
    <Typography class="chart-name">
      {{ name }}
    </Typography>
    <div class="chart-container">
      <div
        v-for="(item, number) in parsedChartData"
        :key="number"
        :class="item.color"
        class="chart-item"
      />
    </div>
    <Typography
      :class="lastRequestColor"
      class="last-request-time"
    >
      {{ lastRequestTime }}
    </Typography>
  </div>
</template>
<script lang="ts">
import Typography from '@/ui/common/typography.vue';
import { defineComponent, PropType } from 'vue';

interface ChartDataItem {
  duration: number;
  success: boolean;
  hasResponse?: boolean;
}

interface Data {
  uiState: {
    count: number;
  };
}

export default defineComponent({
  components: { Typography },
  props: {
    name: {
      type: String,
      required: true,
    },
    chartData: {
      type: Array as PropType<ChartDataItem[]>,
      required: true,
    },
  },
  data(): Data {
    return {
      uiState: {
        count: 20,
      },
    };
  },
  computed: {
    parsedChartData(): { color: string }[] {
      const data = this.chartData.slice(-this.uiState.count);
      const parsedData = data.map((item) => {
        switch (true) {
          case !item.success || item.duration > 5000:
            return {
              color: 'red',
            };
          case item.duration <= 1000:
            return {
              color: 'green',
            };
          case item.duration <= 5000:
            return {
              color: 'orange',
            };
          default:
            return {
              color: 'gray',
            };
        }
      });
      if (parsedData.length < this.uiState.count) {
        const emptyData = new Array(this.uiState.count - parsedData.length).fill({ color: 'gray' });
        return [...parsedData, ...emptyData];
      }
      return parsedData;
    },
    lastRequestTime(): string {
      const lastItem = this.chartData[this.chartData.length - 1];
      if (lastItem?.hasResponse === false) {
        return 'n/a';
      }
      return (lastItem?.duration || 0) + ' ms';
    },
    lastRequestColor(): string {
      const lastItem = this.chartData[this.chartData.length - 1] || undefined;
      switch (true) {
        case this.chartData.length === 0:
          return 'gray-text';
        case !lastItem.success:
          return 'red-text';
        case lastItem.duration <= 1000:
          return 'green-text';
        case lastItem.duration <= 5000:
          return 'orange-text';
        default:
          return 'red-text';
      }
    },
  },
});
</script>
<style scoped>
.chart-name {
  width: 45%;
}
.main-container {
  display: flex;
  height: 60px;
  margin: 0px 16px;
  align-items: center;
}
.chart-container {
  display: flex;
  height: 16px;
}
.chart-item {
  width: 2px;
  margin: 0 1px;
}
.gray {
  background: #f5f4f2;
}
.green {
  background: #48c600;
}
.orange {
  background: #fc9000;
}
.red {
  background: #f5523a;
}
.gray-text {
  color: #f5f4f2;
}
.green-text {
  color: #48c600;
}
.orange-text {
  color: #fc9000;
}
.red-text {
  color: #f5523a;
}

.last-request-time {
  flex: 1 1 0;
  text-align: right;
}
</style>
