<template>
  <PageLayout :order_id="order_id">
    <template #header>
      <Bar
        :order="order"
        :text="controlBarText"
        :caption="controlBarCaption"
        need-progress
        :progress-config="progressConfig"
        :total="filteredSuggests.length"
        :icons="{ left: filter === FiltersEnum.all ? 'close' : 'back', right: 'menu' }"
        :menu-config="menuConfig"
        @close-click="onBarLeftBtnClick"
      />
    </template>
    <template #default>
      <div class="flex flex-col h-full">
        <Hint
          v-if="filteredSuggests.length > 0 && filter === FiltersEnum.all"
          class="mx-4 my-2"
        >
          {{ hintText }}
        </Hint>
        <SuggestCardContainer
          :doc-type="order.type"
          :order="order"
          :suggest-menu-config="getSuggestMenuConfig"
          :suggests="filteredSuggests"
          :need-menu-for-suggests="
            filteredSuggests.map(s => {
              return s.status === SuggestStatusEnum.request && s.type === SuggestTypeEnum.shelf2box;
            })
          "
          @open-suggest-menu="needBarcodeRequest = false"
          @close-suggest-menu="needBarcodeRequest = true"
        />
      </div>
      <SuggestDetails
        v-if="suggestDetails.visible.value && suggestDetails.props.value"
        :order-id="order.order_id"
        :suggest-id="suggestDetails.props.value.suggest_id"
        @finish="props => finishActiveSuggest(props, suggestDetails.props.value)"
        @cancel-suggest="onCancelSuggest(suggestDetails.props.value)"
        @cancel="suggestDetails.hide"
      />
      <ShareOrder
        v-if="order && shareOrder.visible.value"
        :order="order"
        @close="shareOrder.hide"
      />
    </template>
    <template #footer>
      <LayoutFooter>
        <FilterMenu
          v-if="filter === FiltersEnum.all && stage === StageEnum.control"
          :menu-config="filterMenuConfig"
        />
        <template v-if="filter === FiltersEnum.all">
          <UiButton
            v-if="stage === StageEnum.control"
            data-test="control footer next-stage-btn"
            :disabled="trashStageBtnDisabled"
            @click="nextStage"
          >
            {{ $gettext('Перейти к списанию') }}
          </UiButton>
          <UiButton
            v-if="stage === StageEnum.trash"
            data-test="control footer finish-btn"
            :disabled="!canCloseOrder || order.estatus !== OrderEstatusEnum.waiting"
            @click="finishOrder"
          >
            {{ $gettext('Завершить') }}
          </UiButton>
        </template>
        <UiButton
          v-if="filter !== FiltersEnum.all"
          background-color="secondary"
          @click="setFilter(FiltersEnum.all)"
        >
          {{ $gettext('Назад') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </PageLayout>
</template>

<script lang="ts" setup>
import { useSubscribeOnOrder, useSubscribeOnOrderSignal } from '@/fsd/data/utils/subscribeOnOrder';
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import { tryFilterProducts } from '@/fsd/entities/filters/productsFilters';
import { getBox2ShelfSuggests, getDoneSuggests, getSuggestsByProduct, getSuggestsByStatus, getRequestSuggests, getPartDoneSuggests, getEmptyDoneSuggests, getSuggestsByShelf } from '@/fsd/entities/filters/suggestsFilters';
import { useBox2Shelf } from '@/fsd/entities/suggest/tools/useBox2Shelf';
import { FiltersEnum, useFilter } from '@/fsd/entities/suggest/tools/useFilter';
import { useShelf2Box } from '@/fsd/entities/suggest/tools/useShelf2Box';
import { useDetachFromOrder } from '@/fsd/features/order/utils/useDetachFromOrder';
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { useComponent } from '@/hooks/useComponent';
import { useHandleOrderStatus } from '@/hooks/useHandleOrderStatus';
import { BarcodeHandler, useRequestBarcode } from '@/hooks/useRequestBarcode';
import { ShelfTypeEnum } from '@/models/Shelf';
import Suggest, { SuggestStatusEnum, SuggestTypeEnum, SuggestVarsStageEnum } from '@/models/Suggest';
import { OrderEstatusEnum, OrderTargetEnum, SignalTypeEnum } from '@/models/orders/BaseOrder';
import ControlOrder, { ControlModeEnum } from '@/models/orders/ControlOrder';
import { OrderDoneShelf2BoxSuccessReasonCodeEnum, SignalEnum } from '@/services/requests';
import { useOrders } from '@/store/modules/orders';
import { useShelves } from '@/store/modules/shelves';
import { useSuggests } from '@/store/modules/suggests';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { $gettext, $ngettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import Bar from '@/ui/common/bar/bar.vue';
import FilterMenu from '@/ui/common/filter-menu/filter-menu.vue';
import Hint from '@/ui/common/hint/hint.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import ShareOrder from '@/ui/common/share-order/share-order.vue';
import { default as SuggestDetails } from '@/ui/common/suggest-details/suggest-details.vue';
import { Model } from '@/ui/common/suggest-details/types';
import UiButton from '@/ui/common/ui-button.vue';
import { checkConditions, OperationEnum } from '@/utils/checkConditions';
import SuggestCardContainer from '@/views/common/suggest-card-container.vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { getProductsByBarcode } from '@/fsd/entities/barcode-processor/modelGetters';

const { order_id } = defineProps<{
  order_id: string;
}>();
const { showLoader } = useLoader();
const shareOrder = useComponent();
const suggestDetails = useComponent<Suggest>();
const router = useRouter();
const { detachBtn } = useDetachFromOrder(order_id);
const { filter, setFilter, suggests } = useFilter(order_id);

useHandleOrderStatus(order_id);

const order = computed(() => useOrders().orderById(order_id) as ControlOrder);

enum StageEnum {
  control = 'control',
  trash = 'trash',
}

const menuConfig = computed(() => {
  const menuConfig: MenuItemConfig[] = [];
  menuConfig.push(detachBtn.value);

  const shareOrderBtn: MenuItemConfig = {
    buttonText: $gettext('Разделить задание'),
    onClick: () => showShareOrder(),
    condition: () => useUser().experimentByName(experiments.exp_tsd_companion),
  };
  menuConfig.push(shareOrderBtn);

  return menuConfig;
});

/**
 * В КСГ ест несколько этапов: проверка и списание, в каждом можем работать только с ограниченным набором саджестов.
 */
const stageSuggests = computed(() => {
  if (order.value.target === OrderTargetEnum.canceled) {
    return getBox2ShelfSuggests(suggests.value);
  }
  if (stage.value === StageEnum.control) {
    return suggests.value.filter(
      suggest =>
        suggest.type === SuggestTypeEnum.shelf2box
        || (suggest.type === SuggestTypeEnum.box2shelf
          && suggest.vars.stage === SuggestVarsStageEnum.store),
    );
  }
  return suggests.value.filter(
    suggest =>
      suggest.type === SuggestTypeEnum.box2shelf
      && suggest.vars.stage !== SuggestVarsStageEnum.store,
  );
});
/**
 * Саджесты, что мы показываем прямо сейчас, управляются фильтром по конфигу нижу
 */
const filteredSuggests = computed(() => {
  switch (filter.value) {
    case FiltersEnum.all:
      return stageSuggests.value;
    case FiltersEnum.done:
      return getDoneSuggests(stageSuggests.value);
    case FiltersEnum.request:
      return getRequestSuggests(stageSuggests.value);
    case FiltersEnum.part:
      return getPartDoneSuggests(stageSuggests.value);
    case FiltersEnum.noProduct:
      return getEmptyDoneSuggests(stageSuggests.value);
  }
  return suggests.value;
});

const filterMenuConfig = computed(() => {
  return [
    {
      buttonText: $gettext('Готово'),
      color: 'green',
      count: getDoneSuggests(stageSuggests.value).length,
      onClick: () => {
        setFilter(FiltersEnum.done);
      },
    },
    {
      buttonText: $gettext('Почти'),
      color: 'orange',
      count: getPartDoneSuggests(stageSuggests.value).length,
      onClick: () => {
        setFilter(FiltersEnum.part);
      },
    },
    {
      buttonText: $gettext('Нет'),
      color: 'warning',
      count: getEmptyDoneSuggests(stageSuggests.value).length,
      onClick: () => setFilter(FiltersEnum.noProduct),
    },
    {
      buttonText: $gettext('Не отсканированные'),
      color: 'secondary',
      count: getRequestSuggests(stageSuggests.value).length,
      onClick: () => setFilter(FiltersEnum.request),
    },
  ];
});

const canCloseOrder = computed(() => {
  return getRequestSuggests(stageSuggests.value).length === 0;
});

/**
 * Полка на которую нужно списать/уценить товар
 */
const destShelfForGoods = computed(() => {
  const suggest = suggests.value.find(
    suggest =>
      suggest.type === SuggestTypeEnum.box2shelf
      && suggest.vars.stage !== SuggestVarsStageEnum.store,
  );
  if (!suggest) {
    return null;
  }
  return useShelves().shelfById(suggest.shelf_id);
});

const hintText = computed(() => {
  if (!order.value || !order.value.suggests) return '';

  if (order.value.estatus === OrderEstatusEnum.waiting && order.value.suggests.length === 0) {
    return $gettext('Заданий нет, можете завершить КСГ');
  }

  if (stage.value === StageEnum.trash) {
    if (canCloseOrder.value) {
      return $gettext('Завершите задание');
    }
    switch (mode.value) {
      case ControlModeEnum.store2trash:
      case ControlModeEnum.markdown2trash:
      case ControlModeEnum.review2trash:
        return $gettext('Сканируйте полку списания');
      case ControlModeEnum.store2review:
        return $gettext('Сканируйте полку разбора');
      case ControlModeEnum.eatToday2markdown:
      case ControlModeEnum.store2markdown:
        return $gettext('Сканируйте полку распродажи');
    }
  }
  return $gettext('Отсканируйте товар');
});

const controlBarText = computed(() => {
  switch (filter.value) {
    case FiltersEnum.done:
    case FiltersEnum.request:
    case FiltersEnum.part:
    case FiltersEnum.noProduct:
      if (filteredSuggests.value.length) {
        return $ngettext('%{filtered} товар', '%{filtered} товаров', filteredSuggests.value.length, {
          filtered: String(filteredSuggests.value.length),
        });
      } else {
        return '';
      }
  }
  switch (mode.value) {
    case ControlModeEnum.store2trash:
    case ControlModeEnum.markdown2trash:
    case ControlModeEnum.review2trash:
      return $gettext('Контроль срока годности');
    case ControlModeEnum.store2review:
      return $gettext('Контроль товаров для возврата');
    case ControlModeEnum.store2markdown:
      return $gettext('Контроль товаров для распродажи');
    case ControlModeEnum.eatToday2markdown:
      return $gettext('КСГ "Годен сегодня"');
    default:
      return $gettext('Контроль срока годности');
  }
});

const controlBarCaption = computed(() => {
  switch (filter.value) {
    case FiltersEnum.done:
      return $gettext('Товары со статусом “Готово”');
    case FiltersEnum.request:
      return $gettext('Не отсканированные товары');
    case FiltersEnum.part:
      return $gettext('Товары со статусом “Почти”');
    case FiltersEnum.noProduct:
      return $gettext('Товары со статусом “Нет”');
  }
  if (stage.value === StageEnum.control) return $gettext('Отбор');
  // stage === trash
  switch (mode.value) {
    case ControlModeEnum.store2trash:
    case ControlModeEnum.markdown2trash:
    case ControlModeEnum.review2trash:
      return $gettext('Списание');
    case ControlModeEnum.store2review:
      return $gettext('Возврат');
    case ControlModeEnum.eatToday2markdown:
    case ControlModeEnum.store2markdown:
      return $gettext('Уценка');
    default:
      return '';
  }
});

const progressConfig = computed(() => {
  if (!order.value) return;
  return [
    {
      count: getDoneSuggests(stageSuggests.value).length,
      color: 'green',
    },
  ];
});

/**
 * Этап документа: контроль или списание
 */
const stage = computed<StageEnum>(() => {
  if (order.value.vars.suggests_write_off) {
    return StageEnum.trash;
  }
  return StageEnum.control;
});

const mode = computed(() => {
  return order.value.vars.mode;
});

const trashStageBtnDisabled = computed(() => {
  const permit = useUser().permitByName('order_signals');
  const hasPermit = Array.isArray(permit) && permit.includes('next_stage');
  return getRequestSuggests(stageSuggests.value).length > 0 || !hasPermit;
});

const getSuggestMenuConfig = (suggest) => {
  const menuConfig: MenuItemConfig[] = [];
  const noProductBtn: MenuItemConfig = {
    buttonText: $gettext('Отметить как отсутствующий'),
    onClick: () => {
      finishActiveSuggest({ count: 0 }, suggest);
    },
    dataTest: 'no-product btn',
    condition: () => {
      return (
        checkConditions(suggest, OperationEnum.all, true)
        && suggest.status === SuggestStatusEnum.request
        && suggest.type === SuggestTypeEnum.shelf2box
      );
    },
  };
  menuConfig.push(noProductBtn);

  return menuConfig;
};

const onBarLeftBtnClick = (): void => {
  if (filter.value === FiltersEnum.all) {
    router.push({ name: 'home' });
  } else {
    setFilter(FiltersEnum.all);
  }
};

const finishActiveSuggest = async ({ count = 0, date }: Pick<Model, 'count' | 'date'>, suggest?: Suggest): Promise<void> => {
  if (!order.value || !suggest) return;
  if (stage.value === StageEnum.trash) {
    suggestDetails.hide();
    Alerts.error($gettext('Невозможно отредактировать задание, документ находится на этапе списания'));
    return;
  }

  if (suggest.type === SuggestTypeEnum.shelf2box) {
    const result = await useShelf2Box(order_id, {
      suggest_id: suggest.suggest_id,
      count,
      valid: date,
    });
    if (!result) return;
  } else {
    const result = await useBox2Shelf(order_id, {
      suggest_id: suggest.suggest_id,
      count,
    });
    if (!result) return;
  }
  suggestDetails.hide();
};

const onCancelSuggest = async (suggest: Suggest): Promise<void> => {
  if (stage.value === StageEnum.trash) {
    suggestDetails.hide();
    Alerts.error($gettext('Невозможно отредактировать задание, документ находится на этапе списания'));
    return;
  }

  await useShelf2Box(order_id, {
    suggest_id: suggest.suggest_id,
    status: 'cancel',
    reason: { code: OrderDoneShelf2BoxSuccessReasonCodeEnum.CHANGE_COUNT_VALID },
  });
};

const finishOrder = async (): Promise<void> => {
  const confirmed = await Notifications.confirmBottom({
    title: $gettext('Вы уверены, что разместили все товары?'),
  });
  if (!confirmed) return;
  needBarcodeRequest.value = false;
  const result = await useEndOrder(order_id);
  if (result) {
    router.push({ name: 'home' });
  } else {
    needBarcodeRequest.value = true;
  }
};

const showShareOrder = async (): Promise<void> => {
  needBarcodeRequest.value = false;
  await shareOrder.asyncShow();
  needBarcodeRequest.value = true;
};

const nextStage = async (): Promise<void> => {
  const confirmed = await Notifications.confirmBottom({
    title: $gettext('Вы уверены, что проверили все товары?'),
  });
  if (!confirmed) return;
  const { closeLoader, updateLoader } = showLoader(undefined, order_id);
  try {
    await useOrders().signal({
      order_id: order_id,
      signal: SignalEnum.next_stage,
    });
    switch (mode.value) {
      case ControlModeEnum.store2markdown:
      case ControlModeEnum.eatToday2markdown:
        updateLoader($gettext('Ожидаем перехода задания на этап уценки'), order_id);
        break;
      case ControlModeEnum.store2review:
        updateLoader($gettext('Ожидаем перехода задания на этап разбора'), order_id);
        break;
      case ControlModeEnum.store2trash:
      case ControlModeEnum.markdown2trash:
      case ControlModeEnum.review2trash:
        updateLoader($gettext('Ожидаем перехода задания на этап списания'), order_id);
        break;
    }
    await waitNextStage();
  } catch (error) {
    console.error(error);
  } finally {
    closeLoader();
  }
};

const completeFinalStage = async (): Promise<void> => {
  let message = '';
  switch (mode.value) {
    case ControlModeEnum.store2markdown:
    case ControlModeEnum.eatToday2markdown:
      message = $gettext('Уцениваем товары');
      break;
    case ControlModeEnum.store2review:
      message = $gettext('Перемещаем товары');
      break;
    case ControlModeEnum.store2trash:
    case ControlModeEnum.markdown2trash:
    case ControlModeEnum.review2trash:
      message = $gettext('Списываем товары');
      break;
  }
  const { closeLoader } = showLoader(message);
  try {
    await useOrders().signal({
      order_id: order.value.order_id,
      signal: SignalEnum.complete_final_stage,
    });
    // ждем когда сигнал перейдет в done
    await useSubscribeOnOrderSignal(order_id)((s) => {
      return Boolean(s?.done);
    }, SignalTypeEnum.complete_final_stage);
    // снимаем лоадер
  } catch (error) {
    console.error(error);
  } finally {
    closeLoader();
  }
};

const waitNextStage = async (): Promise<void> => {
  if (order.value.vars.suggests_write_off) {
    return;
  }
  await useSubscribeOnOrder(order_id)((o) => {
    if (!o) return true;
    return !!(o as ControlOrder).vars.suggests_write_off;
  });
};

const trashStageBarcodeHandler = async (barcode: string): Promise<boolean> => {
  if (!destShelfForGoods.value) return true;

  const shelf = await useShelves().getShelfByBarcode(barcode).catch(console.error);
  if (!shelf) {
    Alerts.error($gettext('Не удалось отсканировать баркод, попробуйте еще раз'));
    return true;
  }
  let valid = false;
  switch (mode.value) {
    case ControlModeEnum.store2trash:
    case ControlModeEnum.markdown2trash:
    case ControlModeEnum.review2trash:
      valid = barcode === destShelfForGoods.value.barcode;
      break;
    case ControlModeEnum.store2review:
      valid = shelf.type === ShelfTypeEnum.review;
      break;
    case ControlModeEnum.store2markdown:
    case ControlModeEnum.eatToday2markdown: {
      valid = shelf.type === ShelfTypeEnum.markdown;
      break;
    }
  }
  if (!valid) {
    Alerts.error($gettext('Отсканирована неверная полка'));
    return true;
  }
  await completeFinalStage();
  return false;
};

const barcodeHandler: BarcodeHandler = async (barcode, requester) => {
  if (!order.value) return true;
  if (stage.value === StageEnum.trash) {
    return trashStageBarcodeHandler(barcode);
  }
  const products = await getProductsByBarcode(barcode);
  if (!products) {
    // ОШИБКА, нет такого продукта
    Alerts.error($gettext('Отсканирован неверный штрихкод %{barcode}', { barcode }));
    return true;
  }
  // Выфильтровываем продукты, определяем, что пользователь имел в виду
  const product = await tryFilterProducts(products, order_id);

  const getSuggest = async (product) => {
    // возвращает саджесты с этапа контроля. обычно это shelf2box . но в случае, если товар нужен для заказа это будет box2shelf.
    // для отмененного документа это всегда только box2shelf
    const suggests = Array.from(useSuggests().suggestsByOrderId(order_id).values()).filter((s) => {
      if (order.value.target === OrderTargetEnum.canceled) return s.type === SuggestTypeEnum.box2shelf;
      return s.type === SuggestTypeEnum.shelf2box || s.vars.stage === SuggestVarsStageEnum.store;
    });

    const suggestsWithProduct = getSuggestsByProduct(suggests, product);
    if (suggestsWithProduct.length === 0) return null;
    if (suggestsWithProduct.length === 1) return suggestsWithProduct[0];

    const suggestsWithShelf = await getSuggestsByShelf(suggestsWithProduct, requester);
    if (!suggestsWithShelf || suggestsWithShelf.length === 0) return null;
    if (suggestsWithShelf.length === 1) return suggestsWithShelf[0];

    const suggestsWithStatusRequest = getSuggestsByStatus(suggestsWithProduct, SuggestStatusEnum.request);
    if (suggestsWithStatusRequest.length === 0) return null;
    return suggestsWithStatusRequest[0];
  };

  const suggest = await getSuggest(product);
  if (!suggest) {
    // ОШИБКА, нет такого саджеста
    Alerts.error($gettext('Продукта нет в задании'));
    return true;
  }
  await suggestDetails.asyncShow(suggest);
  return true;
};

const { needBarcodeRequest } = useRequestBarcode(barcodeHandler);

</script>
