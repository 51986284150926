<template>
  <Caption1
    color="white"
    class="z-[51000] font-medium bg-black"
  >
    <div class="px-2 flex justify-between h-full">
      <DateTimeBlock>
        <template #extra>
          <button
            v-if="devtools.devtoolsEnabled"
            data-test="devtools-activator"
            @click="$emit('toggle-devtools')"
          >
            <DevtoolsIcon class="text-yellow-normal" />
          </button>
        </template>
      </DateTimeBlock>

      <IndicatorsBlock />
    </div>
  </Caption1>
</template>

<script lang="ts" setup>
import DevtoolsIcon from '@/devtools/sections/DevtoolsIcon.vue';
import { useDevtoolsStore } from '@/devtools/store/devtoolsModule';
import Caption1 from '@/ui/common/typo/caption-1.vue';
import DateTimeBlock from './ui/DateTimeBlock.vue';
import IndicatorsBlock from './ui/IndicatorsBlock.vue';

defineEmits(['toggle-devtools']);

const devtools = useDevtoolsStore();
</script>
