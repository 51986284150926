import { useScanner } from '@/store/modules/scanner';
import { logger } from '@/temp/plugins/logs';

declare global {
  interface Window {
    pro: {
      qrHandler?: (barcodeRaw: string) => void;
      closeCamera?: () => void;
      closePage?: () => void;
    };
  }
}

window.pro ??= {
  qrHandler: () => logger.error('Not implement qrHandler'),
  closeCamera: () => logger.error('Not implement closeCamera'),
  closePage: () => logger.error('Not implement closePage'),
};

// scanner.service ожидает везде промисы, поэтому сейчас все методы являются промисами
// даже те, которые фактически выполняются синхронно
export class CameraProScanner {
  private readonly mode = 'cameraPro';
  private readonly loggerInstance = console.log;
  private reject?: () => void;
  private resolve?: (value: string) => void;

  constructor() {
    this.loggerInstance = logger ?? console.log;
  }

  closeCamera() {
    useScanner().setCameraProActive(false);
  }

  qrHandler(barcodeRaw: string) {
    if (!this.resolve) {
      this.loggerInstance('Нет обработчика для barcode');
    }

    this.closeCamera();
    this.resolve?.(barcodeRaw);
  }

  async init() {
    this.loggerInstance(`Запуск режима ${this.mode}`);
    window.pro.qrHandler = this.qrHandler.bind(this);
    window.pro.closeCamera = this.closeCamera.bind(this);
  }

  async destroy() {
    this.loggerInstance(`Выключение режима ${this.mode}`);
    this.reject?.();
  }

  scan() {
    return new Promise((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
  }
}
