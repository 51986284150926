<template>
  <div
    ref="wrapperRef"
    class="rounded-2xl transition-colors duration-300 bg-cardBackground-day"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import {
  defineProps, ref, watch,
} from 'vue';

interface CardBlockProps {
  num?: number;
}

const props = defineProps<CardBlockProps>();

const wrapperRef = ref<HTMLDivElement>();

watch(
  () => props.num,
  () => {
    wrapperRef.value?.classList.replace('bg-cardBackground-day', 'bg-green-lite');
    setTimeout(() => {
      wrapperRef.value?.classList.replace('bg-green-lite', 'bg-cardBackground-day');
    }, 3000);
  },
);
</script>
