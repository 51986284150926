<template>
  <div
    id="root"
    class="flex flex-col min-h-full h-full"
    :class="{
      'is-rtl-main': userStore.isRTL,
      'deli-theme': userStore.theme === 'deli',
      'dark-theme': userStore.theme === 'dark',
      'bg-black': isShowContent,
      'bg-transparent': !isShowContent,
    }"
  >
    <SimpleLoader v-if="isLoading" />
    <template v-else>
      <StatusBar
        v-show="isShowContent"
        class="h-5"
        @toggle-devtools="isVisibleDevtools = !isVisibleDevtools"
      />
      <div
        v-show="isShowContent"
        class="relative rounded-2xl h-[calc(100vh-20px)] flex overflow-hidden"
      >
        <RouterView />
        <Devtools v-if="isVisibleDevtools" />
      </div>
      <CameraPro v-if="PlatformService.isPro()" />
      <QrAim v-if="scannerStore.isActiveCamera" />
    </template>

    <Updater />
    <NotificationsGroup />
    <AlertsGroup />
    <ModalGroup />
    <ActionGroup />
    <Loader />
  </div>
</template>

<script lang="ts" setup>
import Updater from '@/components/updater/updater.vue';
import Devtools from '@/devtools/devtools.vue';
import { PlatformService } from '@/fsd/data/services/platform.service';
import { checkClientTime } from '@/fsd/data/utils/checkClientTime';
import { useCheckAssignCourier } from '@/fsd/features/order/remainders/useCheckAssignCourier';
import { useCheckReadyMeals } from '@/fsd/features/order/remainders/useCheckReadyMeals';
import { useCheckReadyRobozone } from '@/fsd/features/order/remainders/useCheckReadyRobozone';
import { StatusBar } from '@/fsd/features/status-bar';
import { useCheckNetwork } from '@/fsd/features/tools/useCheckNetwork';
import { useWebViewUpdate, usePickerAppUpdate } from '@/fsd/features/tools/useUpdate';
import QrAim from '@/fsd/shared/ui/QrAim/QrAim.vue';
import ActionGroup from '@/fsd/shared/ui/action/ActionGroup.vue';
import AlertsGroup from '@/fsd/shared/ui/notifications/AlertsGroup.vue';
import ModalGroup from '@/fsd/shared/ui/notifications/ModalGroup.vue';
import { SimpleLoader } from '@/fsd/shared/ui/simpleLoader';
import { useUpdates } from '@/hooks/useUpdates';
import { useOrders } from '@/store/modules/orders';
import { useScanner } from '@/store/modules/scanner';
import { useUser } from '@/store/modules/user';
import NotificationsGroup from '@/temp/components/notifications-group.vue';
import Loader from '@/ui/common/loader/loader.vue';
import { initParams } from '@/utils/query-configurator';
import { storeToRefs } from 'pinia';
import {
  computed, onMounted, ref, watch,
} from 'vue';
import { useRouter } from 'vue-router';
import { useBranding } from './fsd/features/tools/useBranding';
import { CameraPro } from './fsd/widgets/CameraPro';

const isLoading = ref(true);
const isVisibleDevtools = ref(false);
const router = useRouter();
const scannerStore = useScanner();
const ordersStore = useOrders();
const userStore = useUser();
const { getBranding } = useBranding();

// запускаем отслеживание готовой еды. оповещаем сборщика о возможности продолжить сборку
useCheckReadyMeals();
// Запускаем отслеживание назначений курьера для документов с maybe_rover
useCheckAssignCourier();
// Запускаем отслеживание приезда стеллажей в робозоне
useCheckReadyRobozone();
// Проверяем время на клиенте, как только оно будет получено
checkClientTime();

useWebViewUpdate();
usePickerAppUpdate();

const isShowContent = computed(() => {
  return !scannerStore.isActiveCamera && !scannerStore.cameraProActive;
});

// если пользователь разлогинился, то пушим на страницу логина
const { isAuthenticated } = storeToRefs(userStore);
useCheckNetwork();
watch(isAuthenticated, (val) => {
  if (!val) router.push('/login');
});

watch(
  () => userStore.storeId,
  (value, oldValue) => {
    if (value && !oldValue) {
      ordersStore.startPolling();
    }
    if (!value && oldValue) {
      ordersStore.stopPolling();
    }
  },
  { immediate: true },
);

onMounted(() => {
  // Запрашиваем брендинг при монтировании
  Promise.allSettled([initParams(), getBranding()]).then(() => {
    isLoading.value = false;
    useUpdates();
  });
  userStore.getApkVersion();
});
</script>
