<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.1705 11.2045C18.6098 11.6438 18.6098 12.3562 18.1705 12.7955L14.4205 16.5455C13.9812 16.9848 13.2688 16.9848 12.8295 16.5455C12.3902 16.1062 12.3902 15.3938 12.8295 14.9545L14.659 13.125L3.125 13.125C2.50368 13.125 2 12.6213 2 12C2 11.3787 2.50368 10.875 3.125 10.875L14.659 10.875L12.8295 9.0455C12.3902 8.60616 12.3902 7.89385 12.8295 7.45451C13.2688 7.01517 13.9812 7.01517 14.4205 7.45451L18.1705 11.2045ZM6.5 17.625C6.5 17.0037 7.00368 16.5 7.625 16.5C8.24632 16.5 8.75 17.0037 8.75 17.625L8.75 18C8.75 19.2426 9.75736 20.25 11 20.25L18.5 20.25C19.7426 20.25 20.75 19.2426 20.75 18L20.75 6C20.75 4.75736 19.7426 3.75 18.5 3.75L11 3.75C9.75736 3.75 8.75 4.75736 8.75 6L8.75 6.375C8.75 6.99632 8.24632 7.5 7.625 7.5C7.00368 7.5 6.5 6.99632 6.5 6.375L6.5 6C6.5 3.51472 8.51472 1.5 11 1.5L18.5 1.5C20.9853 1.5 23 3.51472 23 6L23 18C23 20.4853 20.9853 22.5 18.5 22.5L11 22.5C8.51472 22.5 6.5 20.4853 6.5 18L6.5 17.625Z"
      :fill="colorValue"
    />
  </svg>
</template>
<script setup lang="ts">
import { useColor } from '@/fsd/shared/hooks';

interface Props {
  bgColor?: string;
  color?: string;
}

const { bgColor = '#FFA60D', color = '#000' } = defineProps<Props>();
const params = {
  color: () => color,
  bgColor: () => bgColor,
};
const { colorValue } = useColor(params);
</script>
