<template>
  <div class="h-10 w-10 rounded-full bg-semantic-text-custom flex items-center justify-center">
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.809 2.45073L16.4801 2.17933C17.1382 1.91318 17.5323 1.2348 17.4374 0.53125L14.3758 1.07174C14.0413 0.929623 13.6733 0.851003 13.2869 0.851003C11.7479 0.851003 10.5002 2.09867 10.5002 3.63775C10.5002 5.17684 11.7479 6.42451 13.2869 6.42451C14.826 6.42451 16.0737 5.17684 16.0737 3.63775C16.0737 3.21322 15.9788 2.81085 15.809 2.45073ZM1.12972 8.74363C1.65136 7.74758 2.69955 7.14054 3.8231 7.18381C4.29909 7.20215 4.86356 7.21147 5.48596 7.22176C6.73961 7.24248 8.22826 7.26708 9.70223 7.37697C9.99492 11.2614 8.8463 13.7039 7.15019 15.3003L6.8502 17.4002L1.6502 17.1002L2.5002 10.7002L0.548828 9.85282L1.12972 8.74363ZM6.85019 17.4003L6.60019 19.0002C7.04381 19.0002 8.01647 19.0112 9.18674 19.0246L9.18783 19.0246L9.18812 19.0246H9.18833C10.9557 19.0448 13.1734 19.07 14.7002 19.07C15.0658 17.8813 15.3653 16.6563 15.615 15.3094L17.3748 19.0733C17.9882 18.5887 18.2949 18.3464 18.4903 18.0491C18.7426 17.6654 18.8684 17.2124 18.8503 16.7535C18.8362 16.398 18.6984 16.0323 18.4228 15.3007L16.3611 9.82728C16.3246 9.73039 16.2999 9.66474 16.2733 9.61755C16.2456 9.52666 16.2024 9.44115 16.1411 9.36582C15.2853 8.31383 13.3604 7.7915 11.2091 7.52411C11.5771 11.9039 10.0837 15.3479 6.85019 17.4003Z"
        fill="white"
      />
    </svg>
  </div>
</template>
