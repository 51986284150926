<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.00195312"
      width="40"
      height="40"
      rx="20"
      :fill="bgColorValue"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.002 19.5789L20.3037 31L30.002 19.5789L24.5709 20.5789L23.5076 15H16.7871L15.433 20.5789L10.002 19.5789ZM17.0298 14H23.317L22.9358 12H17.5152L17.0298 14ZM17.7579 11L18.2433 9H22.364L22.7452 11H17.7579Z"
      :fill="colorValue"
    />
  </svg>
</template>
<script setup lang="ts">
import { useColor } from '@/fsd/shared/hooks';

interface Props {
  bgColor?: string;
  color?: string;
}

const { bgColor = 'red-normal', color = 'white' } = defineProps<Props>();
const params = {
  color: () => color,
  bgColor: () => bgColor,
};
const { colorValue, bgColorValue } = useColor(params);
</script>
