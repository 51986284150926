import { Log } from '@/devtools/log';
import Event from '@/models/events/Event';
import { AxiosResponse } from 'axios';

export class NetworkLog extends Log {
  public statusCode: AxiosResponse['status'];
  public responseBody: AxiosResponse['data'];
  public requestBody: AxiosResponse['config']['data'];
  constructor(axiosResponse: AxiosResponse) {
    super();
    this.statusCode = axiosResponse.status;
    this.responseBody = axiosResponse.data;
    this.requestBody = axiosResponse.config.data;
  }
}

export interface SseLogParams {
  event: EventSourceEventMap['message'];
  keys: (string | number)[][];
  data: Event[];
}

export class NetworkLogSse extends Log {
  eventType: EventSourceEventMap['message']['type'];
  responseBody: EventSourceEventMap['message']['data'];
  keys: (string | number)[][];
  lastEventId: string = '';
  type: string;

  constructor({
    event, keys, data,
  }: SseLogParams) {
    super();
    this.type = event.type;
    this.lastEventId = event.lastEventId;
    this.eventType = event.type;
    this.responseBody = data;
    this.keys = keys;
  }
}
