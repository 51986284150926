import Suggest from '@/models/Suggest';
import { BaseModel } from 'sjs-base-model';

export default class SuggestRemoveEvent extends BaseModel {
  public type = 'suggest_remove' as const;
  public suggest_id: Suggest['suggest_id'] = '';
  public order_id: Suggest['order_id'] = '';

  constructor(data: any) {
    super({ expand: true });
    this.update(data);
  }

  static isSuggestRemoveEvent(event: { type: string }): event is SuggestRemoveEvent {
    return event.type === 'suggest_remove';
  }
}
