import { api } from '@/fsd/data/api/api.service';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { isTrueMark } from '@/fsd/shared/tools/unpackTrueMark';
import Product, { TypeAccountingEnum } from '@/models/Product';
import ProductByBarcode from '@/models/ProductByBarcode';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import BaseOrder, { AcceptanceModeAttrEnum } from '@/models/orders/BaseOrder';
import { isString } from '@/models/typeGuards';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { $gettext } from '@/temp/plugins/gettext';
import { computed } from 'vue';

interface FindedProduct {
  product?: Product;
  findSuggest?: Suggest;
  childProduct?: Product;
}

interface FindedParent {
  suggest: Suggest;
  parent: Product;
  child: Product;
}

type ProductsCount = Record<string, number>;

interface ProductsWithCounts {
  products: Product[];
  counts: ProductsCount;
}

const isSimpleBarcode = (barcode: string) => {
  const len = barcode.length;
  const [firstChar] = barcode;
  return (len !== 13 && len !== 18) || (firstChar !== '2' && firstChar !== '6');
};

export const useHandleProductCode = (order_id: BaseOrder['order_id']) => {
  const ordersStore = useOrders();
  const productStore = useProducts();

  const order = computed(() => ordersStore.orderById(order_id));
  const suggests = computed(() => order.value?.suggests ?? []);

  const productBySuggest = (products: Product[], suggestWithProduct: Suggest) => {
    return products.find(p => p.product_id === suggestWithProduct.product_id)!;
  };

  const getSuggestsWithProducts = (products: Product[]): Suggest[] => {
    const productIdsSet = new Set(products.map(({ product_id }) => product_id));
    return suggests.value.filter(suggest => productIdsSet.has(suggest.product_id));
  };

  const getParent = async (products: Product[]): Promise<FindedParent | null> => {
    const parentIds = products.map(({ parent_id }) => parent_id).filter(isString);
    if (!parentIds.length) return null;

    const parents = await productStore.getProductsByIds(parentIds);
    if (!parents?.length) return null;

    const parentIdsSet = new Set(parents.map(({ product_id }) => product_id));

    const suggestsWithProduct = suggests.value.filter(suggest => parentIdsSet.has(suggest.product_id));
    if (!suggestsWithProduct.length) return null;

    let [suggest] = suggestsWithProduct;

    // Если саджестов несколько, найдем первый в статусе request
    if (suggestsWithProduct.length > 1) {
      const requestSuggest = suggestsWithProduct.find(s => s.status === SuggestStatusEnum.request);
      if (requestSuggest) {
        suggest = requestSuggest;
      }
    }

    const parent = productBySuggest(parents, suggest);
    const child = products.find(({ parent_id }) => parent_id === parent.product_id);

    if (!child) return null;

    return {
      suggest,
      parent,
      child,
    };
  };

  const getProductsByBarcode = async (barcode: string, orderId: string): Promise<ProductsWithCounts> => {
    try {
      const isSimple = isSimpleBarcode(barcode);
      const isMark = isTrueMark(barcode);

      const counts = {};
      // Простой случай, можно попробовать найти по баркоду в сторе
      // не работает с ЧЗ - мы не знаем что именно пикнули - КИГ (упаковка) или КИЗ (шт)
      if (!isMark && isSimple) {
        const products = productStore.getProductsByBarcodeLocal(barcode);
        if (products.length) return {
          products,
          counts,
        };
      }
      //  если ничего не нашли локально, то делаем запрос по ручке баркод
      const { data } = await api.barcode({
        barcode,
        order_id: orderId,
      });
      const productIds = data.found
        .filter(ProductByBarcode.isProductByBarcode)
        .map(({ product_id, count, true_mark }) => {
          // если пришел count - это КИГ ЧЗ и для него нам нужно знать количество, чтобы найти нужный дочерний саджест
          if (count && true_mark) {
            counts[product_id] = count;
          }
          return product_id;
        });

      if (!productIds.length) {
        Alerts.error($gettext('Не найден штрихкод %{barcode}', { barcode }));
        throw new Error('check barcode');
      }

      const results: Product[] = [];
      const products = (await productStore.getProductsByIds(productIds)) ?? [];

      for (const product of products) {
        const isProductNotWeight = product.type_accounting !== TypeAccountingEnum.weight;

        if (isProductNotWeight || product.parent_id) {
          results.push(product);
        } else {
          // нужно проверить детей (достаточно одного). если они НЕ весовые то разрешаем идти дальше
          if (product.children_id?.length) {
            // Если есть дети
            const [firstChildId] = product.children_id;
            const child = await productStore.getProductById(firstChildId);
            // И он штучный
            if (child?.type_accounting === TypeAccountingEnum.unit) {
              results.push(product);
            }
          }
        }
      }

      return {
        products: results,
        counts,
      };
    } catch (error) {
      console.error(error);
      Alerts.error($gettext('Не найден штрихкод'));
      throw error;
    }
  };

  const findNeededProduct = async (products: Product[], barcode: string): Promise<FindedProduct> => {
    const result: FindedProduct = {};
    const suggestsWithProduct = getSuggestsWithProducts(products);

    if (!suggestsWithProduct.length) {
      if (!isSimpleBarcode(barcode)) {
        const parentData = await getParent(products);

        if (parentData) {
          result.product = parentData.parent;
          result.childProduct = parentData.child;
          result.findSuggest = parentData.suggest;
          return result;
        }
      }
      if (order.value?.attr.acceptance_mode === AcceptanceModeAttrEnum.pre_check) {
        result.product = products.find(Product.isProduct);
        return result;
      }

      Alerts.error($gettext('Продукта нет в задании'));
      throw new Error('this product is not in order');
    }

    let [suggest] = suggestsWithProduct;

    if (suggestsWithProduct.length > 1) {
      const requestSuggest = suggestsWithProduct.find(s => s.status === SuggestStatusEnum.request);
      if (requestSuggest) {
        suggest = requestSuggest;
      }
    }

    const product = productBySuggest(products, suggest);

    result.findSuggest = suggest;
    result.product = product;

    return result;
  };

  return {
    getProductsByBarcode,
    findNeededProduct,
  };
};
