<template>
  <div
    class="flex justify-center items-center gap-1 font-bold py-0.5"
    :class="[{ ...containerClassDefault }, containerClass]"
    :data-test="dataTest"
    @click.stop="() => !disabled && $emit('click')"
  >
    <div
      v-if="isShowIcons && $slots.iconLeft"
      :class="[{ ...iconClassDefault }, iconLeftClass]"
    >
      <slot name="iconLeft" />
    </div>

    <component
      :is="titleComponents[size]"
      :class="titleClass"
      :is-color="false"
    >
      <slot>
        {{ title }}
      </slot>
    </component>

    <div
      v-if="isShowIcons && $slots.iconRight"
      :class="[{ ...iconClassDefault }, iconRightClass]"
    >
      <slot name="iconRight" />
    </div>
  </div>
</template>

<script setup lang="ts">
import Body1 from '@/ui/common/typo/body-1.vue';
import Caption1 from '@/ui/common/typo/caption-1.vue';
import Caption2 from '@/ui/common/typo/caption-2.vue';
import { computed } from 'vue';
import { BadgeBase } from './types';

const {
  size = 'medium',
  title = '',
  titleClass = '',
  disabled = false,
  uppercase = false,
  containerClass = '',
  iconLeftClass = '',
  iconRightClass = '',
  dataTest,
  onClick,
} = defineProps<BadgeBase>();

defineEmits(['click']);

const containerClassDefault = computed(() => ({
  'px-[3px] rounded-[5px] h-4': size === 'small',
  'px-0.5 rounded-md h-5': size === 'medium',
  'px-0.5 rounded-xl h-10 w-[220px]': size === 'large',
  'cursor-pointer': onClick && !disabled,
  'opacity-40': disabled,
  uppercase,
}));

const iconClassDefault = computed(() => ({
  'w-[14px] h-[14px]': size === 'medium',
  'w-[24px] h-[24px]': size === 'large',
}));

const isShowIcons = computed(() => size !== 'small');

const titleComponents = computed(() => ({
  small: Caption2,
  medium: Caption1,
  large: Body1,
}));
</script>
