import { api } from '@/fsd/data/api/api.service';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import OrderEvent from '@/models/events/OrderEvent';
import OrdersQueueEvent from '@/models/events/OrdersQueueEvent';
import SuggestEvent from '@/models/events/SuggestEvent';
import SuggestRemoveEvent from '@/models/events/SuggestRemoveEvent';
import BaseOrder, {
  OrderEstatusEnum,
  OrderTargetEnum,
  OrderTypeEnum,
  OrderWorkStatusEnum,
} from '@/models/orders/BaseOrder';
import OrderOrder from '@/models/orders/OrderOrder';
import Product from '@/models/Product';
import Shelf from '@/models/Shelf';
import Suggest from '@/models/Suggest';
import { AudioService } from '@/services/audio.service';
import EventService, { CB } from '@/services/event.service';
import itemQueue from '@/services/queue/item-queue';
import orderQueue from '@/services/queue/order-queue';
import productQueue from '@/services/queue/product-queue';
import shelfQueue from '@/services/queue/shelf-queue';
import {
  OrderCheckRequest,
  OrderDoneBox2ShelfRequest,
  OrderDoneCheckMoreRequest,
  OrderDoneCheckRequest,
  OrderDoneShelf2BoxRequest,
  OrderSignalRequest,
} from '@/services/requests';
import { useSuggests } from '@/store/modules/suggests';
import { useUser } from '@/store/modules/user';
import {
  createdDateSorter, isWaitingForCompleteOrder, mergeCreatedSort, queueSorter,
} from '@/store/utils/sorters';
import { errorMessages } from '@/temp/constants/translations';
import { $gettext } from '@/temp/plugins/gettext';
import { useLoader } from '@/ui/common/loader/useLoader';
import { getErrorText, isHomePage } from '@/utils';
import { AxiosRequestConfig } from 'axios';
import dayjs from 'dayjs';
import { difference } from 'lodash';
import { defineStore } from 'pinia';

export const useOrders = defineStore('orders', {
  state() {
    return {
      initialized: false,
      orders: new Map<BaseOrder['order_id'], BaseOrder>(),
      ordersQueue: new Array<BaseOrder['order_id']>(),
      orderUnSub: () => {},
      queueUnSub: () => {},
      recountBorders: new Map<BaseOrder['order_id'], [number, number | undefined]>(),
    };
  },
  getters: {
    isInitialized(state): boolean {
      return state.initialized;
    },
    ordersGetter(state): BaseOrder[] {
      return Array.from(state.orders.values() as any as BaseOrder[]).filter(o => this.isAvailableOrderType(o.type));
    },
    availableOrderTypes(): OrderTypeEnum[] {
      return (useUser().permitByName('tsd_order_types') as OrderTypeEnum[]) || [];
    },
    isAvailableOrderType(): (orderType: OrderTypeEnum) => boolean {
      return (orderType: OrderTypeEnum) => this.availableOrderTypes.includes(orderType);
    },
    orderById(state) {
      return (order_id: BaseOrder['order_id']): BaseOrder | undefined => {
        return state.orders.get(order_id) as BaseOrder;
      };
    },
    orderByExternalId(state) {
      return (external_id: string) => {
        const orders = state.orders.values();
        for (const o of orders) {
          if (o.external_id === external_id) return o;
        }
        return undefined;
      };
    },
    requestedOrders(): BaseOrder[] {
      const isJunior = useUser().isJunior;
      return this.ordersGetter.filter(
        (order: BaseOrder) =>
          (order.status === OrderWorkStatusEnum.request || order.status === OrderWorkStatusEnum.processing)
          && !order.data?.isPaused
          && (!isJunior || order.isAvailableForJunior),
      );
    },
    checkOrderByProductAndShelf(): (product_id: string, shelf_id: string) => BaseOrder | undefined {
      return (product_id: string, shelf_id: string) => {
        return this.ordersGetter.find((order) => {
          if (
            !order.suggests
            || order.suggests.length === 0
            || order.type !== OrderTypeEnum.check_product_on_shelf
            || order.status === 'complete'
          ) {
            return false;
          }
          return order.suggests.some(s => s.product_id === product_id && s.shelf_id === shelf_id);
        });
      };
    },
    hasRequestOrderOrder(): boolean {
      return this.orderOrders.some((order: BaseOrder) => order.status === OrderWorkStatusEnum.request);
    },
    acceptanceOrders(): (all: boolean) => BaseOrder[] {
      const user_id = useUser().userId;
      return (all = false) => {
        const acceptanceOrders = this.requestedOrders.filter((order: BaseOrder) =>
          [OrderTypeEnum.acceptance, OrderTypeEnum.acceptance_market].includes(order.type),
        );
        return all
          ? acceptanceOrders
          : acceptanceOrders.filter(
              (order: BaseOrder) =>
                order.users.includes(user_id) || !dayjs(order.attr.doc_date).isAfter(dayjs(), 'day'),
            );
      };
    },
    orderOrders(): BaseOrder[] {
      return this.requestedOrders.filter((order: BaseOrder) => {
        return (
          [OrderTypeEnum.order, OrderTypeEnum.order_retail].includes(order.type)
          && !((order as OrderOrder).isOrderPaused && order.status === OrderWorkStatusEnum.request)
        );
      });
    },
    checkOrders(): BaseOrder[] {
      return this.requestedOrders.filter(
        (order: BaseOrder) =>
          order.type === OrderTypeEnum.inventory_check_product_on_shelf
          || order.type === OrderTypeEnum.check
          || (order.type === OrderTypeEnum.check_final && this.isAvailableOrderType(OrderTypeEnum.check_final)),
      );
    },
    checkProductOnShelfOrders(): BaseOrder[] {
      return this.requestedOrders.filter((order: BaseOrder) => order.type === OrderTypeEnum.check_product_on_shelf);
    },
    blindCheckOrders(): BaseOrder[] {
      return this.requestedOrders.filter(
        (order: BaseOrder) =>
          order.type === OrderTypeEnum.check_more || order.type === OrderTypeEnum.inventory_check_more,
      );
    },
    controlOrders(): BaseOrder[] {
      return this.requestedOrders.filter((order: BaseOrder) =>
        [
          OrderTypeEnum.writeoff_prepare_day,
          OrderTypeEnum.check_valid_short,
          OrderTypeEnum.check_valid_regular,
          OrderTypeEnum.visual_control,
          OrderTypeEnum.repacking,
        ].includes(order.type),
      );
    },
    stowageOrders(): BaseOrder[] {
      return this.requestedOrders.filter((order: BaseOrder) =>
        [
          OrderTypeEnum.sale_stowage,
          OrderTypeEnum.weight_stowage,
          OrderTypeEnum.stowage_market,
        ].includes(order.type),
      );
    },
    writeoffOrders(): BaseOrder[] {
      return this.requestedOrders.filter((order: BaseOrder) => order.type === OrderTypeEnum.writeoff);
    },
    refundOrders(): BaseOrder[] {
      return this.requestedOrders.filter(
        (order: BaseOrder) => order.type === OrderTypeEnum.refund || order.type === OrderTypeEnum.part_refund,
      );
    },
    shipmentOrders(): BaseOrder[] {
      return this.requestedOrders.filter(
        (order: BaseOrder) => order.type === OrderTypeEnum.shipment || order.type === OrderTypeEnum.shipment_rollback,
      );
    },
    handMoveOrders(): BaseOrder[] {
      const filter = this.requestedOrders.filter(
        (order: BaseOrder) =>
          order.type === OrderTypeEnum.hand_move
          || order.type === OrderTypeEnum.kitchen_provision
          || order.type === OrderTypeEnum.robot_provision,
      );
      return filter;
    },
    controlCheckOrders(): BaseOrder[] {
      return this.ordersGetter.filter((order: BaseOrder) => order.type === OrderTypeEnum.control_check);
    },
    failedOrders(): BaseOrder[] {
      return this.ordersGetter.filter(order => order.data && order.data.isPaused);
    },
    sortedOrderOrders(): BaseOrder[] {
      const processingOrders = this.orderOrders
        .filter(order => order.status === OrderWorkStatusEnum.processing)
        .filter(order => !isWaitingForCompleteOrder(order));

      const processingQueueSortedOrders = processingOrders
        .filter(order => useOrders().ordersQueue.indexOf(order.external_id) !== -1)
        .sort(queueSorter);

      const processingNotInQueueSortedOrders = processingOrders
        .filter(order => useOrders().ordersQueue.indexOf(order.external_id) === -1)
        .sort(queueSorter);

      const processingSortedOrders = mergeCreatedSort(processingQueueSortedOrders, processingNotInQueueSortedOrders);

      const requestOrders = this.orderOrders.filter(order => order.status === OrderWorkStatusEnum.request);

      const requestedQueueSortedOrders = requestOrders
        .filter(order => useOrders().ordersQueue.indexOf(order.external_id) !== -1)
        .sort(queueSorter);

      const requestedNotInQueueSortedOrders = requestOrders.filter(
        order => useOrders().ordersQueue.indexOf(order.external_id) === -1,
      );

      const requestSortedOrders = mergeCreatedSort(requestedQueueSortedOrders, requestedNotInQueueSortedOrders);

      const waitingForCompleteOrders = this.ordersGetter
        .filter(order => isWaitingForCompleteOrder(order))
        .sort(createdDateSorter);
      return [...processingSortedOrders, ...requestSortedOrders, ...waitingForCompleteOrders];
    },
  },
  actions: {
    setOrders(orders: BaseOrder[]) {
      this.alert(orders);
      orders.forEach((o) => {
        const orderInStore = this.orders.get(o.order_id);
        if (!orderInStore || orderInStore.revision < o.revision) {
          this.orders.set(o.order_id, o);
        }
      });
    },
    alert(orders: BaseOrder[]) {
      const needAlert = orders.some((newOrder) => {
        const isOrderInStore = this.orders.get(newOrder.order_id);
        const isAlertStatusRequired
          = newOrder.status === OrderWorkStatusEnum.request
            || (newOrder.status === OrderWorkStatusEnum.processing
              && newOrder.users.includes(useUser().userId));
        const unnecessaryAlertOrderType = [OrderTypeEnum.check_product_on_shelf].includes(newOrder.type);
        const isNewOrder = !isOrderInStore && newOrder.type === OrderTypeEnum.order;
        const isOrderPage = Boolean(~window.location.pathname.indexOf('order'));
        return (
          (isNewOrder && !isOrderPage && !isHomePage())
          || (isHomePage() && !isOrderInStore && isAlertStatusRequired && !unnecessaryAlertOrderType)
          || (isOrderInStore
            && isOrderInStore.target === OrderTargetEnum.complete
            && newOrder.type === OrderTypeEnum.order
            && newOrder.target === OrderTargetEnum.canceled)
        );
      });
      if (needAlert) AudioService.playAlert();
    },
    removeOrder(order_id: string | string[]) {
      if (Array.isArray(order_id)) {
        order_id.forEach((id) => {
          this.orders.delete(id);
          useSuggests().removeOrderSuggests(id);
        });
      } else {
        this.orders.delete(order_id);
        useSuggests().removeOrderSuggests(order_id);
      }
    },
    updateOrderFromEvent({ event }: { event: OrderEvent }) {
      const orderInStore = this.orders.get(event.order_id);
      if (!orderInStore) return;

      switch (true) {
        case orderInStore.revision > event.revision:
          return;
        case orderInStore.revision === event.revision:
          if ('paused_until' in orderInStore && orderInStore.paused_until != event.paused_until) {
            orderInStore.updateFromEvent(event);
          }
          return;
        case orderInStore.revision < event.revision:
          orderInStore.updateFromEvent(event);
      }
    },
    setStatus({ order_id, status = 'isPaused' }) {
      const order = this.orderById(order_id);
      if (!order) return;
      order.data[status] = true;
    },
    setOrdersQueue(queue) {
      this.ordersQueue = queue;
    },
    async shelf2box(option: OrderDoneShelf2BoxRequest) {
      const response = await api.order.done.shelf2box(option);
      if (response?.data?.suggests) {
        await this.updateSuggestsFromResponse(response.data.suggests);
      }
      return response;
    },
    async box2shelf(payload: OrderDoneBox2ShelfRequest) {
      const response = await api.order.done.box2shelf(payload);
      if (response?.data?.suggests?.length) {
        await this.updateSuggestsFromResponse(response.data.suggests);
      }
      return response;
    },
    async check(option: {
      silent?: boolean;
      payload: OrderDoneCheckRequest;
    }) {
      const { payload, silent } = option;
      try {
        const response = await api.order.done.check(payload);
        if (response.data.suggests) {
          await this.updateSuggestsFromResponse(response.data.suggests);
        }
        return response;
      } catch (error: any) {
        if (!silent) {
          const title = errorMessages.ER_CHECK;
          const text = getErrorText(error);
          Modal.show({
            title,
            text,
          });
          AudioService.playError();
        }
        throw error;
      }
    },
    // TODO pinia поставить нормальные типы
    async loadOrders(ids?: BaseOrder['order_id'][], config?: AxiosRequestConfig) {
      // получили документы
      const response = await api.orders({ order_ids: ids }, config);
      const orders = response.data.orders;
      // флаг, что говорит, что мы грузим все документы, это бывает в 2 случаях: при инициализации и при обрыве соединения.
      // в этом случае нужно загрузить саджесты (даже если они были загружены)
      const need_force_reload_suggests = !ids;
      if (ids) {
        // проверяем, что все запрашиваемые ордера были загружены
        const loadedIds = orders.map(o => o.order_id);
        const notLoadedIds = difference(ids, loadedIds);
        this.removeOrder(notLoadedIds);
      }

      // загружаем саджесты для документов
      const promises: Promise<any>[] = [];
      orders.forEach((o) => {
        if (o.status === OrderWorkStatusEnum.processing && o.estatus === OrderEstatusEnum.waiting) {
          // мы грузим все док-ты только в 2 случаях: при инициализации и при разрыве связи с ивент сервисом. в обоих случаях мы хотим попробовать загрузить саджесты.
          promises.push(useSuggests().loadSuggests(o, need_force_reload_suggests));
        }
        // сейчас клиенский заказ во время отмены имеет базовый естатус waiting_subs_cancel,
        // обязательно нужно загрузить для него саджесты https://st.yandex-team.ru/LAVKADUTY-7021
        if (
          o.type === OrderTypeEnum.order
          && o.status === OrderWorkStatusEnum.processing
          && o.estatus === OrderEstatusEnum.waiting_subs_cancel
        ) {
          promises.push(useSuggests().loadSuggests(o, need_force_reload_suggests));
        }
      });
      await Promise.allSettled(promises);
      const relatedDataPromises: Promise<any>[] = [];
      // загружаем продукты, посылки и полки для документов
      orders.forEach((o) => {
        // в control_check у нас нет саджестов, поэтому грузим ресурсы из order[required]
        if (o.type === OrderTypeEnum.control_check) {
          relatedDataPromises.push(o.loadProducts());
          relatedDataPromises.push(o.loadShelves());
        }
        if (
          o.status === OrderWorkStatusEnum.request
          && [
            OrderTypeEnum.check_more,
            OrderTypeEnum.inventory_check_more,
            OrderTypeEnum.inventory_check_product_on_shelf,
          ].includes(o.type)
        ) {
          relatedDataPromises.push(o.loadShelves());
        }
        if (
          o.status === OrderWorkStatusEnum.request
          && useUser().isJunior
          && [
            OrderTypeEnum.order,
            OrderTypeEnum.sale_stowage,
            OrderTypeEnum.weight_stowage,
            OrderTypeEnum.hand_move,
            OrderTypeEnum.acceptance,
          ].includes(o.type)
        ) {
          relatedDataPromises.push(o.loadProducts());
        }
      });
      await Promise.allSettled(relatedDataPromises);
      // только после загрузки саджестов добавляем их в стор. это гарантия того, что с саджестом можно начинать работать
      this.setOrders(orders);
      this.initialized = true;
      return response;
    },
    async checkMore(option: OrderDoneCheckMoreRequest) {
      const response = await api.order.done.check_more(option);
      if (response.data?.suggests) {
        await this.updateSuggestsFromResponse(response.data.suggests);
      }
      return response;
    },
    async createCheck(option: OrderCheckRequest) {
      try {
        return await api.order.check(option);
      } catch (error: any) {
        console.error(error);
        const title = errorMessages.ER_CHECK_CREATE;
        let text = getErrorText(error);
        switch (error.response.data.code) {
          case 'ER_CONFLICT': {
            text = $gettext('Требуется завершить все дочерние документы Полной инвентаризации');
          }
        }
        Modal.show({
          title,
          text,
        });
        AudioService.playError();
        throw error;
      }
    },
    async cancelOrder(orderId) {
      try {
        await api.order.change_status({
          order_id: orderId,
          status: 'failed',
          reason: 'incomplete',
        });
      } catch (error: any) {
        console.error(error);
        let title, text;
        if (!error.response) title = errorMessages.ER_ORDER_COMPLETE_NETWORK;
        else {
          title = errorMessages.ER_ORDER_COMPLETE;
          text = getErrorText(error);
        }
        Modal.show({
          title,
          text,
        });
        AudioService.playError();
        throw error;
      }
    },
    async loadOrderQueue() {
      try {
        const { data } = await api.orders_queue();
        this.setOrdersQueue(data.queue);
      } catch (e) {
        console.error(e);
      }
    },
    async updateSuggestsFromResponse(suggests: Suggest[]) {
      const promises: Promise<any>[] = [];
      suggests.forEach((s) => {
        promises.push(s.loadProduct());
        promises.push(s.loadShelf());
      });
      await Promise.allSettled(promises);
      if (!suggests) return;

      useSuggests().addSuggests(suggests);
    },
    async updateSuggestFromEvent({ event }: { event: SuggestEvent }) {
      if (!useUser().isAuthenticated) return;
      if (!event.order_id || !event.suggest_id) return;
      const order = useOrders().orderById(event.order_id);

      // документ не наш, игнорируем ивент с саджестом
      if (!order || order.status !== OrderWorkStatusEnum.processing || !order.users.includes(useUser().userId))
        return;

      let suggest = order.suggestById(event.suggest_id);
      //  если саджеста нет. то пробуем загрузить его.
      if (!suggest) {
        suggest = await useSuggests().loadSuggest(order.order_id, event.suggest_id);
      }
      // если успешно, то грузим ресурсы
      if (suggest) {
        const promises: Promise<any>[] = [];
        // может быть не только продукт, но и посылка
        if (event.product_id) {
          if (suggest.vars.mode === 'item') {
            promises.push(itemQueue.load(event.product_id));
          } else {
            promises.push(productQueue.load(event.product_id));
          }
        }
        if (event.shelf_id) {
          promises.push(shelfQueue.load(event.shelf_id));
        }

        await Promise.allSettled(promises);

        if (suggest.revision < event.revision) {
          suggest.updateFromEvent(event);
        }
      } else {
        // Дожидаемся завершения промиса и выполняем обработку еще раз, чтобы не терять ивент, обработка которого происходит раньше,
        // чем заканчивается загрузка всех саджестов
        const requestSuggestsPromise = useSuggests().getRequestSuggestsPromise(event.order_id);
        if (requestSuggestsPromise) {
          requestSuggestsPromise.then(() => {
            this.updateSuggestFromEvent({ event });
          });
        }
      }
    },
    pause({ order_id }) {
      this.setStatus({
        order_id,
        status: 'isPaused',
      });
    },
    startPolling() {
      const storeId = useUser().storeId;

      EventService.init();
      const storeCB: CB = (code, events = []) => {
        switch (code) {
          case 'INIT':
            // останавливаем очередь, загружаем все.
            orderQueue.stop();
            this.loadOrders().catch(e => console.error(e));
            return;
          case 'MAYBE_DATA_LOST':
            orderQueue.stop();
            this.loadOrders().catch(e => console.error(e));
            return;
          case 'OK': {
            // отправляем ивент в очередь
            const orderEvents = events.filter(OrderEvent.isOrderEvent);
            // В одной пачке могут прилететь ивенты, что будут требовать загрузки и удаления ордера,
            // тк все ивенты обрабатываются индивидуально это приведет к лишнему запросу в бек
            const mustBeRemovedOrderIds = new Set(
              orderEvents
                .filter((e) => {
                  return (
                    e.status in OrderTargetEnum
                    || (e.status === OrderWorkStatusEnum.processing && !e.users.includes(useUser().userId))
                  );
                })
                .map(o => o.order_id),
            );

            orderEvents.forEach((event) => {
              const mustBeRemoved = mustBeRemovedOrderIds.has(event.order_id);
              if (!mustBeRemoved) {
                useSuggests().loadSuggests(event);
              }
              const orderInStore = useOrders().orderById(event.order_id);
              if (
                event.status in OrderWorkStatusEnum
                && (!orderInStore || orderInStore.version < event.version)
                && !mustBeRemoved
              ) {
                orderQueue.load(event.order_id);
                return;
              }
              this.updateOrderFromEvent({ event });
              if (mustBeRemoved) {
                // документ перешел в неинтересующее пользователя состояние.
                // обновляем стор, чтобы компоненты получили уведомление об этом, а затем удаляем его из хранилища
                setTimeout(() => this.removeOrder(event.order_id), 0);
                return;
              }
            });

            const suggestEvents = events.filter(SuggestEvent.isSuggestEvent);
            const suggestRemoveEvents = events.filter(SuggestRemoveEvent.isSuggestRemoveEvent);

            suggestEvents.forEach((event) => {
              if (suggestRemoveEvents.some(removeEvent => removeEvent.suggest_id === event.suggest_id)) return;
              this.updateSuggestFromEvent({ event });
            });

            suggestRemoveEvents.forEach(e => useSuggests().removeSuggest(e.order_id, e.suggest_id));
            break;
          }
          default:
            break;
        }
      };
      this.orderUnSub = EventService.subscribe(['order', 'store', storeId], storeCB);
      this.queueUnSub = EventService.subscribe(['order_queue', 'store', storeId], (code, events = []) => {
        switch (code) {
          case 'INIT':
            this.loadOrderQueue();
            return;
          case 'MAYBE_DATA_LOST':
            this.loadOrderQueue();
            return;
          case 'OK': {
            const ordersQueueEvents = events.filter(OrdersQueueEvent.isOrdersQueueEvent);
            const lastEvent = ordersQueueEvents.pop();
            if (lastEvent) {
              this.setOrdersQueue(lastEvent.queue);
            }
            break;
          }
          default:
            break;
        }
      });

      const userStore = useUser();
      EventService.subscribe(['device', 'store', storeId], (code, events) => {
        if (code === 'OK' && events?.length) {
          events.find((e) => {
            if (e.type === 'unlink' && userStore.userId === e.user_id) {
              if (!userStore.device || e.device === userStore.device) {
                userStore.logout();
              }
              return true;
            }
          });
        }
      });
    },
    stopPolling() {
      this.orderUnSub?.();
      this.queueUnSub?.();
      orderQueue.stop();
    },
    clear() {
      this.orders.clear();
    },
    async signal(requestData: OrderSignalRequest) {
      try {
        return await api.order.signal(requestData);
      } catch (error) {
        Alerts.error($gettext('Произошла ошибка при отправке сигнала'));
        throw error;
      }
    },
    // ищет существующий пересчет по продукту, полке и режиму
    async checkExistRecount(data: {
      product_id: Product['product_id'];
      shelf_id: Shelf['shelf_id'];
      mode: 'recount' | 'update_valids';
    }): Promise<BaseOrder['order_id'] | false> {
      const { showLoader } = useLoader();
      const order = this.checkOrderByProductAndShelf(data.product_id, data.shelf_id);
      if (!order) return false;
      if (order.required[0].update_valids && data.mode == 'recount') return false;
      if (!order.required[0].update_valids && data.mode === 'update_valids') return false;
      const { closeLoader } = showLoader();
      const loadedOrder = await orderQueue.load(order.order_id);
      closeLoader();
      if (!loadedOrder) {
        return false;
      }
      return loadedOrder.order_id;
    },
    setBorders(order_id: BaseOrder['order_id'], borders: [number, number | undefined]) {
      this.recountBorders.set(order_id, borders);
    },
    clearBorder(order_id: BaseOrder['order_id']) {
      this.recountBorders.delete(order_id);
    },
  },
});
