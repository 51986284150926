import box2shelf, { Box2ShelfOptions } from '@/fsd/data/utils/box2shelf';
import retryBox2ShelfModal from '@/fsd/entities/modals/retryBox2ShelfModal';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { SuggestStatusEnum } from '@/models/Suggest';
import { OrderDoneBox2ShelfRequest } from '@/services/requests';
import { useSuggests } from '@/store/modules/suggests';
import { $gettext } from '@/temp/plugins/gettext';
import { useLoader } from '@/ui/common/loader/useLoader';
import { merge } from 'lodash';

export const useBox2Shelf = async (
  order_id: string,
  payload: OrderDoneBox2ShelfRequest,
  options: Box2ShelfOptions = {},
) => {
  const { showLoader } = useLoader();

  let loader;

  const { suggest_id } = payload;

  const suggest = useSuggests().getSuggest(order_id, suggest_id)!;

  if (suggest.status === SuggestStatusEnum.done && !payload.reason) {
    if (suggest.isEditable) {
      payload.reason = {
        code: 'CHANGE_COUNT',
        count: (payload as any).count,
      };
    }
  }

  const defaultOptions: Box2ShelfOptions = {
    onValidateError: e => Modal.show({
      title: $gettext('Не удалось переместить товар на полку'),
      text: e,
    }),
    beforeRequest: () => (loader = showLoader($gettext('Отправляем запрос на выкладку товаров'))),
    afterRequest: () => loader?.updateLoader($gettext('Ожидаем ответ сервера')),
    onRequestError: async (e, retry) => {
      const confirmed = await retryBox2ShelfModal(e);
      if (!confirmed) return false;
      return retry();
    },
    beforeEventWaiting: () => {},
    afterEventWaiting: () => {},
    onEventWaitingError: (e) => {
      const errorsMap = {
        'Change shelf error': $gettext('Ошибка изменения полки размещения'),
      };
      Modal.show({
        title: $gettext('Не удалось переместить товар на полку'),
        text: errorsMap[e] || e,
      });
    },
    closeLoader: () => loader?.closeLoader(),
  };
  return box2shelf(order_id, payload, merge({}, defaultOptions, options));
};
