import { ComponentOptionsMixin } from 'vue';

type OnOption = {
  resolve: (args?: any) => void;
  reject: (reason?: any) => void;
  value: any;
};
export const EventFeedback: ComponentOptionsMixin = {
  created() {
    this.$ef = {
      on: ({
        resolve, reject, value,
      }, cb, props = []) => {
        cb(...props, value)
          .then(resolve)
          .catch(reject);
      },
      emit: (eventName, value) => {
        return new Promise((resolve, reject) => {
          this.$emit(eventName, {
            resolve,
            reject,
            value,
          });
        });
      },
    };
  },
};

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $ef: {
      on: (option: OnOption, cb: (...args: any[]) => any, props?: any[]) => void;
      emit: (eventName: string, value?: any) => Promise<any>;
    };
  }
}
