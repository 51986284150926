import emitter from 'tiny-emitter/instance';
import { v1 as uuid } from 'uuid';
import { onUnmounted } from 'vue';

// есть только один лоадер.
// его вызывают компоненты. при создании генерируется ид компонента.
// при уничтожении компонента удалять все причины

// у компонента могут быть несколько причин для отображения лоадера
// регистрирую лоадер в сетапе
// отдаю ф-и показать и закрыть

export const enum LoaderEventsEnum {
  SHOW_LOADER = 'SHOW_LOADER',
  CLOSE_LOADER = 'CLOSE_LOADER',
  UPDATE_LOADER = 'UPDATE_LOADER',
}
//  чтобы снятие и постановка лоадера не вызывала "дрожания" снимаем  лоадер через 200 мс
const DEBOUNCE_LOADER_TIMEOUT = 200;

export const useLoader = () => {
  const component_id = uuid();

  const emit = (event: LoaderEventsEnum, props: any) => {
    emitter.emit(event, props);
  };

  // метод, что позволяет закрыть лоадер по id
  const closeLoader = (loader_id: string) => {
    setTimeout(() => {
      emit(LoaderEventsEnum.CLOSE_LOADER, {
        component_id,
        loader_id,
      });
    }, DEBOUNCE_LOADER_TIMEOUT);
  };

  // метод, что позволяет обновить текст лоадера по id
  const updateLoader = (loader_id: string, message: string, order_id?: string) => {
    emit(LoaderEventsEnum.UPDATE_LOADER, {
      component_id,
      loader_id,
      message,
      order_id,
    });
  };

  // метод, показывает лоадер, может принимать текст для лоадера и order_id для попыток перезагрузить документ, если лоадер долго висит
  // возвращает методы для закрытия/обновления этого лоадера
  const showLoader = (message?: string, orderIdShow?: string, loaderId = uuid()) => {
    emit(LoaderEventsEnum.SHOW_LOADER, {
      component_id,
      loader_id: loaderId,
      message,
      order_id: orderIdShow,
    });
    return {
      closeLoader: () => closeLoader(loaderId),
      updateLoader: (message: string, orderIdUpdate = orderIdShow) => updateLoader(loaderId, message, orderIdUpdate),
    };
  };

  // метод, что позволяет закрыть все лоадеры, что были выставлены компонентом
  const closeComponentLoaders = () => {
    emit(LoaderEventsEnum.CLOSE_LOADER, { component_id });
  };

  onUnmounted(closeComponentLoaders);

  return {
    closeLoader,
    showLoader,
    closeComponentLoaders,
  };
};
