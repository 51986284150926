import { QuantUnitEnum } from '@/models/Product';
import {
  $gettext, $ngettext, gettextWrapper,
} from '@/temp/plugins/gettext';

export const quantUnits = gettextWrapper({
  milligram: $gettext('Мг'),
  gram: $gettext('г'),
  kilogram: $gettext('Кг'),
  milliliter: $gettext('Мл'),
  centiliter: $gettext('Сл'),
  liter: $gettext('Л'),
  unit: $gettext('Шт'),
  default: $gettext('порций'),
});

export const getQuantUnit = (quant_unit: QuantUnitEnum | undefined, count?: number) => {
  return quant_unit
    ? quantUnits[quant_unit]
    : typeof count === 'number'
      ? $ngettext('порций', 'порций', count)
      : quantUnits.default;
};
