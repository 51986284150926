<template>
  <Layout class="bg-background-card">
    <Swiper
      :space-between="0"
      :modules="[Virtual]"
      virtual
      @swiper="setSwiper"
      @slide-change="({ activeIndex }) => updateActiveSuggestIndexAll(activeIndex)"
    >
      <SwiperSlide
        v-for="(suggest, index) in suggests"
        :key="suggest.suggest_id"
        :virtual-index="index"
        :data-index="index"
      >
        <ClientOrderSuggestCardWrapper
          :is-visible="activeSuggestIndex === index"
          :order_id="order_id"
          :index="index"
          :suggest="suggest"
          :problem="getProblemByProduct(suggest.product_id)"
          :collected="collected[suggest.suggest_id]"
          :sherlock="sherlockStatuses[suggest.suggest_id]"
          @finish="completeActiveSuggest"
          @input-sherlock-count="onSherlock"
        />
      </SwiperSlide>
    </Swiper>
    <template #footer>
      <LayoutFooter
        v-if="exp_susanin && suggests.length > 1"
        class="grid grid-cols-2 gap-x-4 items-center"
      >
        <ProductSwipeButton
          v-if="prevSuggest"
          :suggest="prevSuggest"
          side="left"
          class="px-3 py-1 bg-warmGray-75 rounded-2xl h-14"
          @click="prev"
        />
        <Caption1
          v-else
          class="text-center"
          color="day-textMinor"
        >
          {{ $gettext('Открыт первый товар из списка') }}
        </Caption1>
        <ProductSwipeButton
          v-if="nextSuggest"
          :suggest="nextSuggest"
          side="right"
          class="px-3 py-1 bg-warmGray-75 rounded-2xl h-14"
          @click="next"
        />
        <Caption1
          v-else
          class="text-center"
          color="day-textMinor"
        >
          {{ $gettext('Открыт последний товар из списка') }}
        </Caption1>
      </LayoutFooter>
    </template>
  </Layout>
</template>

<script lang="ts" setup>
import ClientOrderSuggestCardWrapper from '@/fsd/widgets/order/RequiredCard/ClientOrderSuggestCardWrapper.vue';
import ProductSwipeButton from '@/fsd/widgets/order/RequiredCard/product-swipe-button.vue';
import { SherlockStatusEnum } from '@/fsd/widgets/order/RequiredCard/sherlock/types';
import { useSherlock } from '@/fsd/widgets/order/RequiredCard/sherlock/useSherlock';
import Product from '@/models/Product';
import Suggest from '@/models/Suggest';
import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';
import { OrderTargetEnum } from '@/models/orders/BaseOrder';
import OrderOrder from '@/models/orders/OrderOrder';
import { useOrders } from '@/store/modules/orders';
import { useUser } from '@/store/modules/user';
import { $gettext } from '@/temp/plugins/gettext';
import { logger } from '@/temp/plugins/logs';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { Collected } from '@/views/Order/types';
import { useCollectOrder } from '@/views/Order/useCollectOrder';
import { Virtual } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import {
  computed, nextTick, reactive, ref, toRef, watch,
} from 'vue';

interface RequiredCardWrapperProps {
  order_id: OrderOrder['order_id'];
  suggests: (Suggest | TrueMarkSuggestWrapper)[];
  activeSuggestIndex: number;
  activeSuggest?: Suggest;
  collected: Record<Suggest['suggest_id'], Collected>;
}

const props = defineProps<RequiredCardWrapperProps>();

const emits = defineEmits<{
  (e: 'finish'): void;
  (e: 'input-sherlock-count', count: number): void;
  (e: 'update:activeSuggestIndex', newVal: number): void;
  (e: 'update:collected', suggest_id: Suggest['suggest_id'], newVal: Collected): void;
}>();

const isManualSwiped = ref<boolean>(true);

const order = computed(() => {
  return useOrders().orderById(props.order_id) as OrderOrder | undefined;
});

const updateActiveSuggestIndexAll = (newVal: number) => {
  if (isManualSwiped.value) {
    logger.event('REQUIRED_CARD_SWIPED_MANUALLY');
  }
  emits('update:activeSuggestIndex', newVal);
  isManualSwiped.value = true;
};

const updateActiveSuggestIndexAuto = (newVal: number) => {
  isManualSwiped.value = false;
  emits('update:activeSuggestIndex', newVal);
};

const updateCollected = (suggest_id: Suggest['suggest_id'], newVal: Collected) => {
  emits('update:collected', suggest_id, newVal);
};
const { completeActiveSuggest, startBarcodeRequest } = useCollectOrder({
  order_id: toRef(props, 'order_id'),
  order: order,
  suggestsForCollect: toRef(props, 'suggests'),
  activeSuggest: toRef(props, 'activeSuggest'),
  activeSuggestIndex: toRef(props, 'activeSuggestIndex'),
  collected: toRef(props, 'collected'),
  updateActiveSuggestIndex: updateActiveSuggestIndexAuto,
  updateCollected,
});

const swiper = ref();
const exp_susanin = computed(() => {
  return useUser().experimentByName('exp_susanin');
});
const prevSuggest = computed(() => {
  if (props.activeSuggestIndex > 0) {
    return props.suggests[props.activeSuggestIndex - 1];
  } else {
    return undefined;
  }
});
const nextSuggest = computed(() => {
  return props.suggests[props.activeSuggestIndex + 1];
});
const sherlockStatuses = reactive<Record<Suggest['suggest_id'], SherlockStatusEnum>>({});

const setSwiper = (s) => {
  swiper.value = s;
  slideTo(props.activeSuggestIndex);
};
const slideTo = (index: number) => {
  swiper.value?.slideTo(index);
};
const next = () => {
  updateActiveSuggestIndexAll(props.activeSuggestIndex + 1);
};
const prev = () => {
  updateActiveSuggestIndexAll(props.activeSuggestIndex - 1);
};

const getProblemByProduct = (product_id: Product['product_id']) => {
  if (!order.value) return undefined;
  if (order.value.target === OrderTargetEnum.canceled) return undefined;
  return order.value?.problems.find(p => p.product_id === product_id);
};
const checkSherlock = async () => {
  if (!props.activeSuggest) return;
  const suggest_id = props.activeSuggest.suggest_id;
  if (sherlockStatuses[suggest_id] === SherlockStatusEnum.skip) return;
  if (sherlockStatuses[suggest_id] === SherlockStatusEnum.need) return;
  const { checkNeedSherlock, sherlockStatus } = useSherlock(props.order_id, props.activeSuggest);
  sherlockStatuses[suggest_id] = sherlockStatus.value;
  if (sherlockStatuses[suggest_id] !== SherlockStatusEnum.maybe) return;
  const needSherlock = await checkNeedSherlock();
  sherlockStatuses[suggest_id] = needSherlock ? SherlockStatusEnum.need : SherlockStatusEnum.skip;
};
const onSherlock = (count: number) => {
  if (props.activeSuggest?.suggest_id) {
    sherlockStatuses[props.activeSuggest.suggest_id] = SherlockStatusEnum.skip;
  }

  emits('input-sherlock-count', count);
  startBarcodeRequest();
};

watch(
  () => props.activeSuggestIndex,
  (idx) => {
    slideTo(idx);
    // проверяем нужен ли шерлок когда показываем карточку
    checkSherlock();
  },
  { immediate: true },
);

watch(
  () => props.suggests,
  async () => {
    if (!swiper.value) return;

    await nextTick();
    swiper.value.update();
    swiper.value.virtual.update();
  },
);
</script>

<style scoped>
/* НИКОГДА И НЕ ПРИ КАКИХ ОБСТОЯТЕЛЬСТВАХ НЕ ТРОГАТЬ ЭТОТ КЛАСС */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slider img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
