<template>
  <Blur>
    <AbsoluteCenter data-test="modal about-order">
      <template v-if="!APKtoInstall">
        <template v-if="!loading">
          <NeedUpdateWindow
            v-if="!hasError"
            @close="emits('close')"
            @load="loadUpdate"
          />
          <HasErrorUpdateWindow
            v-else
            @close="emits('close')"
            @load="loadUpdate"
          />
        </template>
        <LoadUpdateWindow
          v-else
          :progress="progress"
        />
      </template>
      <InstallUpdateWindow
        v-else
        @install="installAPK"
      />
    </AbsoluteCenter>
  </Blur>
</template>
<script setup lang="ts">
import HasErrorUpdateWindow from '@/fsd/features/tools/useUpdate/common/HasErrorUpdateWindow.vue';
import InstallUpdateWindow from '@/fsd/features/tools/useUpdate/common/InstallUpdateWindow.vue';
import LoadUpdateWindow from '@/fsd/features/tools/useUpdate/common/LoadUpdateWindow.vue';
import NeedUpdateWindow from '@/fsd/features/tools/useUpdate/updatePickerApp/NeedUpdateWindow.vue';
import AbsoluteCenter from '@/fsd/shared/ui/positions/AbsoluteCenter.vue';
import { logger } from '@/temp/plugins/logs';
import Blur from '@/temp/ui/blur/blur.vue';
import { App } from '@capacitor/app';
import { Directory, Filesystem, ProgressListener } from '@capacitor/filesystem';
import { Installer } from '@lavka/capacitor-installer-plugin';
import { defineEmits, ref } from 'vue';

const emits = defineEmits<{ (e: 'close'): void }>();
const loading = ref(false);
const hasError = ref(false);
const progress = ref(0);
const APKtoInstall = ref('');

const idToNameMap = {
  'net.yandex.lavka.wms.cap.polka.eu': 'eu.apk',
  'net.yandex.lavka.wms.cap.polka.eu.tst': 'euTst.apk',
  'net.yandex.lavka.wms.cap.polka.nana': 'nana.apk',
  'net.yandex.lavka.wms.cap.packman': 'packman.apk',
  'net.yandex.lavka.wms.cap.polka.retailtech': 'retail.apk',
  'net.yandex.lavka.wms.cap.polka.retailtech.tst': 'retailTst.apk',
  'net.yandex.lavka.wms.cap.polka.retailtech.yango': 'retailY.apk',
  'net.yandex.lavka.wms.cap.polka.retailtech.yango.tst': 'retailYTst.apk',
  'net.yandex.lavka.wms.cap.polka': 'ru.apk',
  'net.yandex.lavka.wms.cap.polka.tst': 'ruTst.apk',
};
const getApkName = async (): Promise<string> => {
  const { id } = await App.getInfo();
  if (idToNameMap[id]) {
    return idToNameMap[id];
  } else {
    throw new Error('Unknown app id');
  }
};
const loadUpdate = async () => {
  loading.value = true;
  hasError.value = false;
  const updateProgress: ProgressListener = ({ bytes, contentLength }) => {
    progress.value = ~~((bytes / contentLength) * 100);
  };
  const { remove } = await Filesystem.addListener('progress', updateProgress);
  try {
    const apkName = await getApkName();
    const result = await Filesystem.downloadFile({
      url: 'https://lavkaitinfrastorage.s3.yandex.net/picker-app/' + apkName,
      path: apkName,
      progress: true,
      directory: Directory.Documents,
    });
    APKtoInstall.value = result.path || '';
    await remove();
    loading.value = false;
    progress.value = 0;
  } catch (e) {
    logger.error(e);
    await remove();
    loading.value = false;
    progress.value = 0;
    hasError.value = true;
  }
};

const installAPK = async () => {
  Installer.install({ filePath: APKtoInstall.value });
};
</script>
