<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 3C11.7239 3 11.5 3.22386 11.5 3.5V11H4C3.72386 11 3.5 11.2239 3.5 11.5V12.5C3.5 12.7761 3.72386 13 4 13H11.5V20.5C11.5 20.7761 11.7239 21 12 21H13C13.2761 21 13.5 20.7761 13.5 20.5V13H21C21.2761 13 21.5 12.7761 21.5 12.5V11.5C21.5 11.2239 21.2761 11 21 11H13.5V3.5C13.5 3.22386 13.2761 3 13 3H12Z"
      fill="#21201F"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
