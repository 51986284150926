import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';
import type Suggest from '@/models/Suggest';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';

/**
 * Возвращает true, если для продукта нужно вывести меню
 */
export const checkSuggestNeedMenu = (suggest: Suggest | TrueMarkSuggestWrapper, collected: number) => {
  const canChangeDeleteReason = useUser().experimentByName(experiments.exp_delete_reason_required)
    && suggest.reason?.code;

  const canChangeManufactureDate = suggest.conditions.need_manufacture_date && suggest.result_valid;

  if (TrueMarkSuggestWrapper.isTrueMarkSuggestWrapper(suggest)) {
    return (suggest.isRequest && suggest.isPartial) || canChangeDeleteReason || canChangeManufactureDate;
  }

  return (collected || suggest.isDone)
    && (
      suggest.result_count
      || collected
      || canChangeDeleteReason
      || canChangeManufactureDate
    );
};
