<template>
  <PageLayout :order_id="order_id">
    <template #header>
      <Bar
        :caption="controlBarCaption"
        :text="controlBarText"
        need-progress
        :total="suggests.length"
        :progress-config="progressConfig"
        :order="order"
        :menu-config="menuConfig"
        @close-click="toHomePage"
      />
    </template>
    <template #default>
      <Hint
        v-if="filteredSuggests.length > 0"
        class="my-2 mx-4"
      >
        {{ hintText }}
      </Hint>

      <SuggestCard
        v-for="(suggest, index) in filteredSuggests"
        :key="suggest.suggest_id"
        :suggest="suggest"
        :order="order"
        :suggest-menu-config="suggestMenuConfig(suggest)"
        :doc-type="OrderTypeEnum.weight_stowage"
        :data-index="index"
        :need-menu="checkNeedMenu(suggest)"
        @open-suggest-menu="() => (uiStateNeedBarcodeRequest = false)"
        @close-suggest-menu="() => (uiStateNeedBarcodeRequest = true)"
      />

      <EmptyList v-if="uiStateFilter !== 'all' && filteredSuggests.length === 0" />

      <SuggestDetails
        v-if="uiState.activeProduct && suggestDetails.visible.value && order && suggestDetails.props.value"
        :key="uiState.suggestDetailsKey"
        :order-id="order.order_id"
        :suggest-id="suggestDetails.props.value.suggest_id"
        :product-id="uiState.activeProduct.product_id"
        :barcode="uiState.barcode"
        @finish="props => finishActiveSuggest(props, suggestDetails.props.value)"
        @cancel="closeSuggestDetails"
      />

      <ShareOrder
        v-if="order && shareOrder.visible.value"
        :order="order"
        @close="shareOrder.hide"
      />
      <OverlayScan
        v-if="overlayScan.visible.value"
        :text="$gettext('Сканируй дочерний продукт')"
      />
    </template>
    <template #footer>
      <LayoutFooter>
        <FilterMenu
          v-if="uiStateFilter === 'all'"
          :menu-config="filterMenuConfig"
        />
        <template v-if="uiStateFilter === 'all'">
          <UiButton
            data-test="weight_stowage finish btn"
            :is-disabled="!finishBtnEnabled"
            @click="finishOrder"
          >
            {{ $gettext('Завершить') }}
          </UiButton>
        </template>
        <UiButton
          v-else
          @click="setFilter('all')"
        >
          {{ $gettext('Назад') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </PageLayout>
</template>

<script lang="ts">
import { api } from '@/fsd/data/api/api.service';
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import {
  getDoneSuggests, getPartDoneSuggests, getRequestSuggests,
} from '@/fsd/entities/filters/suggestsFilters';
import { useBox2Shelf } from '@/fsd/entities/suggest/tools/useBox2Shelf';
import { useShelf2Box } from '@/fsd/entities/suggest/tools/useShelf2Box';
import { useDetachFromOrder } from '@/fsd/features/order/utils/useDetachFromOrder';
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import { useComponent } from '@/hooks/useComponent';
import { useHandleOrderStatus } from '@/hooks/useHandleOrderStatus';
import requestBarcode from '@/mixins/requestBarcode';
import Product, { TagsEnum } from '@/models/Product';
import ProductByBarcode from '@/models/ProductByBarcode';
import Suggest, { SuggestStatusEnum, SuggestTypeEnum, SuggestVarsStageEnum } from '@/models/Suggest';
import { OrderStageEnum, OrderTypeEnum } from '@/models/orders/BaseOrder';
import WeightStowageOrder from '@/models/orders/WeightStowageOrder';
import { ScannerService } from '@/services/scanner/scanner.service';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { shelfTagsInStowage } from '@/temp/constants/translations';
import { logger } from '@/temp/plugins/logs';
import Bar from '@/ui/common/bar/bar.vue';
import { ProgressConfig } from '@/ui/common/bar/types';
import EmptyList from '@/ui/common/empty-list.vue';
import FilterMenu from '@/ui/common/filter-menu/filter-menu.vue';
import { FilterMenuItemConfig } from '@/ui/common/filter-menu/types';
import Hint from '@/ui/common/hint/hint.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import OverlayScan from '@/ui/common/overlay-scan/overlay-scan.vue';
import ShareOrder from '@/ui/common/share-order/share-order.vue';
import SuggestCard from '@/ui/common/suggest-card/suggest-card.vue';
import SuggestDetails from '@/ui/common/suggest-details/suggest-details.vue';
import { Model } from '@/ui/common/suggest-details/types';
import UiButton from '@/ui/common/ui-button.vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

interface Data {
  uiState: {
    activeProduct?: Product;
    barcode?: string;
    suggestDetailsKey: number;
  };
  uiStateNeedBarcodeRequest: boolean;
  uiStateFilter: 'all' | 'done' | 'request' | 'part';
}

export default defineComponent({
  name: 'WeightStowage',
  components: {
    PageLayout,
    LayoutFooter,
    Hint,
    Bar,
    SuggestCard,
    UiButton,
    FilterMenu,
    EmptyList,
    SuggestDetails,
    ShareOrder,
    OverlayScan,
  },
  mixins: [requestBarcode],
  props: {
    order_id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { showLoader } = useLoader();
    const productsStore = useProducts();
    const ordersStore = useOrders();
    const shareOrder = useComponent();
    const suggestDetails = useComponent<Suggest>();
    const overlayScan = useComponent();
    const router = useRouter();
    const { detachBtn } = useDetachFromOrder(props.order_id);

    useHandleOrderStatus(props.order_id);

    return {
      showLoader,
      productsStore,
      ordersStore,
      shareOrder,
      suggestDetails,
      overlayScan,
      router,
      detachBtn,
      OrderTypeEnum,
    };
  },
  data(): Data {
    return {
      uiState: {
        activeProduct: undefined,
        barcode: undefined,
        suggestDetailsKey: 1,
      },
      uiStateNeedBarcodeRequest: true,
      uiStateFilter: 'all',
    };
  },
  computed: {
    order(): WeightStowageOrder | undefined {
      return this.ordersStore.orderById(this.order_id) as never as WeightStowageOrder;
    },
    parentSuggest(): Suggest | undefined {
      return this.suggests.find(s => s.type === SuggestTypeEnum.shelf2box && s.status === SuggestStatusEnum.request);
    },
    tag(): TagsEnum | 'empty' | 'mixed' | 'parcel' {
      if (!this.order || !this.order.vars?.tag) {
        return 'empty';
      }
      return this.order.vars.tag!;
    },
    progressConfig(): ProgressConfig[] {
      if (!this.order) return [];
      return [
        {
          count: this.fullCompletedSuggests.length,
          color: 'green',
        },
      ];
    },
    filterMenuConfig(): FilterMenuItemConfig[] {
      return [
        {
          buttonText: this.$gettext('Не отсканированные'),
          color: 'gray',
          count: this.requestSuggests.length,
          onClick: () => this.setFilter('request'),
        },
        {
          buttonText: this.$gettext('Готово'),
          color: 'green',
          count: this.fullCompletedSuggests.length,
          onClick: () => {
            this.setFilter('done');
          },
        },
      ];
    },
    fullCompletedSuggests(): Suggest[] {
      return getDoneSuggests(this.suggests);
    },
    confirmFinishTitle(): string {
      return this.$gettext('Вы уверены, что разместили все товары в %{tag}?', {
        tag: shelfTagsInStowage[this.tag],
      });
    },
    hintText(): string {
      return this.$gettext('Отсканируйте товар');
    },
    suggests(): Suggest[] {
      if (!this.order) return [];

      if (this.order.vars.stage === OrderStageEnum.trash) {
        return this.order.suggests.filter(s => s.vars.stage === SuggestVarsStageEnum.trash);
      }
      return this.order.suggests;
    },
    partiallyCompletedSuggests(): Suggest[] {
      return getPartDoneSuggests(this.suggests);
    },
    requestSuggests(): Suggest[] {
      return getRequestSuggests(this.suggests);
    },
    filteredSuggests(): Suggest[] {
      switch (this.uiStateFilter) {
        case 'all':
          return this.suggests;
        case 'done':
          return this.fullCompletedSuggests;
        case 'request':
          return this.requestSuggests;
        case 'part':
          return this.partiallyCompletedSuggests;
      }
      return this.suggests;
    },
    controlBarCaption(): string {
      if (this.order && this.order.vars?.stage === OrderStageEnum.trash) {
        return this.$gettext('Списание');
      }
      switch (this.uiStateFilter) {
        case 'all':
          return this.$gettext('Размещение в %{tag}', {
            tag: shelfTagsInStowage[this.tag!],
          });
        case 'done':
          return this.$gettext('Товары со статусом “Готово”');
        case 'request':
          return this.$gettext('Товары со статусом “Нет”');
        case 'part':
          return this.$gettext('Товары со статусом “Почти”');
      }
      return this.$gettext('Размещение в %{tag}', { tag: shelfTagsInStowage[this.tag!] });
    },
    controlBarText(): string {
      if (this.order && this.order.suggests) {
        return this.$ngettext(
          'Осталось %{request} из %{all} наименование',
          'Осталось %{request} из %{all} наименований',
          this.suggests.length,

          {
            request: String(this.requestSuggests.length),
            all: String(this.suggests.length),
          },
        );
      }
      return '';
    },
    suggestMenuConfig(): (suggest: Suggest) => MenuItemConfig[] {
      return (suggest) => {
        const menuConfig: MenuItemConfig[] = [];
        const closeParentSuggestBtn: MenuItemConfig = {
          buttonText: this.$gettext('Все размещено'),
          onClick: () => this.closeParentSuggest(),
          // обратная совместимость
          condition: () =>
            suggest.status === SuggestStatusEnum.request
            && suggest.type === SuggestTypeEnum.shelf2box
            && (this.parentSuggest?.count === 0
              || this.parentSuggest?.count === null),
        };
        menuConfig.push(closeParentSuggestBtn);

        return menuConfig;
      };
    },
    checkNeedMenu(): (suggest: Suggest) => boolean {
      return (suggest) => {
        const menu = this.suggestMenuConfig(suggest);
        return menu.filter(item => !item.condition || item.condition()).length > 0;
      };
    },
    menuConfig(): MenuItemConfig[] {
      const menuConfig: MenuItemConfig[] = [];

      const finishBtn: MenuItemConfig = {
        buttonText: this.$gettext('Все размещено'),
        dataTest: 'bar menu-item weight-stowage-close-parent',
        onClick: () => this.closeParentSuggest(),
        // обратная совместимость
        condition: () =>
          Boolean(this.parentSuggest) && (this.parentSuggest?.count === 0 || this.parentSuggest?.count === null),
      };
      menuConfig.push(finishBtn);

      menuConfig.push(this.detachBtn);

      const shareOrderBtn: MenuItemConfig = {
        buttonText: this.$gettext('Разделить задание'),
        onClick: () => this.showShareOrder(),
        condition: () => false,
      };
      menuConfig.push(shareOrderBtn);

      return menuConfig;
    },
    finishBtnEnabled(): boolean {
      return this.requestSuggests.length === 0 || this.order?.vars.suggest_mode === 'done';
    },
    isBanana(): boolean {
      return Boolean(this.order?.vars.scan_unit_child_product);
    },
  },
  methods: {
    async requestBarcode(): Promise<boolean> {
      const barcode = await ScannerService.requestCode(this.$options.name + this._uuid);
      const { closeLoader } = this.showLoader(undefined, this.order_id);
      try {
        const { data } = await api.barcode({ barcode });
        const foundProduct_ids = data.found.filter(ProductByBarcode.isProductByBarcode).map(p => p.product_id);
        const products = await this.productsStore.getProductsByIds(foundProduct_ids);
        closeLoader();
        if (!products || products.length === 0) {
          this.$alert.error(this.$gettext('Не найден штрихкод %{barcode}', { barcode }));
          return true;
        }
        // развилка в логике, если речь о штучных бананах, то нам можно сканить родителя, иначе нельзя
        const suggestWithProduct = this.isBanana ? this.findWeightParent(products) : this.findWeightChild(products);

        if (!suggestWithProduct) {
          this.$alert.error(this.$gettext('Не найден штрихкод %{barcode}', { barcode }));
          return true;
        }
        const [suggest, product] = suggestWithProduct;

        if (suggest.type === SuggestTypeEnum.shelf2box) {
          if (!Product.weightFromBarcode(barcode)) {
            this.$modal.show({
              title: this.$gettext('Сканируйте ШК с упаковки продукта'),
              text: this.$gettext('В ШК упаковки содержится вес товара, необходимый для учета товара в системе'),
            });
            return true;
          }
          this.uiState.barcode = barcode;
        }

        if (this.isBanana && suggest.type === SuggestTypeEnum.box2shelf) {
          const child = await this.waitForChildProduct(product);
          if (child) {
            this.uiState.activeProduct = child;
          } else {
            return true;
          }
        } else {
          this.uiState.activeProduct = product;
        }

        await this.suggestDetails.asyncShow(suggest);
        return true;
      } catch (error) {
        closeLoader();
        this.overlayScan.hide();
        console.error(error);
        return true;
      }
    },
    async waitForChildProduct(parent: Product): Promise<Product | undefined> {
      //   нужно спросить, какой именно банан размещается
      this.overlayScan.show();
      const barcode = await ScannerService.requestCode(this.$options.name + this._uuid + 2);
      this.overlayScan.hide();
      const { closeLoader } = this.showLoader(undefined, this.order_id);
      try {
        const { data } = await api.barcode({ barcode });
        const foundProduct_ids = data.found.filter(ProductByBarcode.isProductByBarcode).map(p => p.product_id);
        const childs = await this.productsStore.getProductsByIds(foundProduct_ids);
        // @ts-expect-error pinia. childs был  any
        return childs.find(child => parent.product_id === child.parent_id);
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        closeLoader();
      }
    },
    findWeightChild(products: Product[]): [Suggest, Product] | undefined {
      // сканим только детей! в саджесте может быть родитель(shelf2box) или ребенок(box2shelf)
      products = products.filter(p => p.parent_id);

      if (!products.length) {
        return undefined;
      }
      // сначала ищем среди саджестов box2shelf
      for (const p of products) {
        const suggest = this.requestSuggests.find((s) => {
          return (s.type === SuggestTypeEnum.box2shelf && s.product_id === p.product_id);
        });
        if (suggest) {
          return [suggest, p];
        }
      }
      // затем среди shelf2box
      for (const p of products) {
        const suggest = this.requestSuggests.find((s) => {
          return (s.type === SuggestTypeEnum.shelf2box && s.product_id === p.parent_id);
        });
        if (suggest) {
          return [suggest, p];
        }
      }
      return undefined;
    },
    findWeightParent(products: Product[]): [Suggest, Product] | undefined {
      //   при скане бананов у нас в саджесте лежит род продукт и сканим мы род продукт
      products = products.filter(p => p.children_id?.length && !p.parent_id);
      if (!products.length) {
        return undefined;
      }

      for (const p of products) {
        const suggest = this.requestSuggests.find(s => s.product_id === p.product_id);
        if (suggest) {
          return [suggest, p];
        }
      }
      return undefined;
    },
    async finishActiveSuggest(
      option: Pick<Model, 'count' | 'weight'> & { shelf_id: string },
      suggest?: Suggest,
    ): Promise<void> {
      if (!suggest) return;
      if (suggest.type === SuggestTypeEnum.box2shelf) {
        // если нужно выбрать другую полку
        if (suggest.shelf_id !== option.shelf_id) {
          const result = await useBox2Shelf(this.order_id, {
            suggest_id: suggest.suggest_id,
            status: 'error',
            reason: {
              code: 'LIKE_SHELF',
              shelf_id: option.shelf_id,
            },
          });
          if (!result) return;
        }
        const result = await useBox2Shelf(this.order_id, {
          suggest_id: suggest.suggest_id,
          weight: option.weight,
          count: suggest.count!,
          product_id: this.uiState.activeProduct?.product_id,
        });
        if (!result) return;
      }
      if (suggest.type === SuggestTypeEnum.shelf2box) {
        const result = await useShelf2Box(this.order_id, {
          suggest_id: suggest.suggest_id,
          weight: option.weight,
          count: option.count,
          product_id: this.uiState.activeProduct?.product_id,
        });
        if (!result) return;
      }
      await this.closeSuggestDetails();
    },
    async finishOrder(): Promise<void> {
      const confirm = await this.$notification.confirmBottom({
        title: this.confirmFinishTitle,
      });
      if (!confirm) return;
      this.uiStateNeedBarcodeRequest = false;
      const result = await useEndOrder(this.order_id);
      if (result) {
        this.toHomePage();
      } else {
        this.uiStateNeedBarcodeRequest = true;
      }
    },
    toHomePage(): void {
      this.router.push({ name: 'home' });
    },
    setFilter(filter): void {
      this.uiStateFilter = filter;
    },
    async closeSuggestDetails(): Promise<void> {
      this.uiState.activeProduct = undefined;
      this.uiState.barcode = undefined;
      this.suggestDetails.hide();
      this.uiStateNeedBarcodeRequest = true;
    },
    async closeParentSuggest(): Promise<void> {
      try {
        await useShelf2Box(this.order_id, {
          suggest_id: this.parentSuggest!.suggest_id,
          weight: 0,
          count: 0,
        });
        this.uiState.activeProduct = undefined;
      } catch (error) {
        logger.error(error);
      }
    },
    async showShareOrder(): Promise<void> {
      this.uiStateNeedBarcodeRequest = false;
      await this.shareOrder.asyncShow();
      this.uiStateNeedBarcodeRequest = true;
    },
  },
});
</script>
