<template>
  <div class="flex flex-col items-center justify-stretch flex-1 bg-background-main">
    <BgLogin />
    <div
      v-if="isShowExitBtn"
      class="absolute flex justify-end w-full"
    >
      <button
        data-test="login exit btn"
        class="flex justify-center items-center h-[60px] w-[64px]"
        @click="closeApp"
      >
        <IconExit />
      </button>
    </div>
    <div
      class="flex items-center flex-[2_1_auto]"
      data-test="login-logo"
    >
      <LogoB2b
        v-if="isB2B"
        color="text-primary"
      />
      <LogoDeli v-else-if="theme === 'deli'" />
      <Logo v-else />
    </div>

    <div
      v-if="!deviceId"
      class="flex flex-col items-center flex-[1_1_auto]"
    >
      <InlineLoader />
      <div class="text-center mt-6 mx-10">
        <Caption1>
          {{ $gettext('Устаналиваем соединение с устройством') }}
        </Caption1>
        <br>
        <Caption1>
          {{ $gettext('Пожалуйста, подождите') }}
        </Caption1>
      </div>
    </div>
    <div
      v-else-if="tryLogin"
      class="flex flex-col items-center flex-[1_1_auto]"
    >
      <InlineLoader />
      <div class="text-center mt-6 mx-10">
        <Caption1>
          {{ $gettext('Загружаем задания.') }}
        </Caption1>
        <br>
        <Caption1>
          {{ $gettext('Пожалуйста, подождите') }}
        </Caption1>
      </div>
    </div>
    <div
      v-else
      class="flex flex-col items-center flex-[1_1_auto]"
    >
      <BarcodeLogin />
      <div class="text-center mt-6 mx-10">
        <Caption1>
          {{ $gettext('Отсканируйте личный штрих-код чтобы войти') }}
        </Caption1>
      </div>
    </div>
    <Version @click="update" />
  </div>
</template>

<script lang="ts" setup>
import { PlatformService } from '@/fsd/data/services/platform.service';
import { loadingFinish, logoutPro } from '@/fsd/data/services/pro.service';
import { useBranding } from '@/fsd/features/tools/useBranding';
import { showUpdateWindow } from '@/fsd/features/tools/useUpdate/updatePickerApp/usePickerAppUpdate';
import { rumSpaManager } from '@/fsd/shared/tools/RumSpaManager';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import IconExit from '@/temp/icons/icon-exit.vue';
import { $gettext } from '@/temp/plugins/gettext';
import { Checkin } from '@/temp/services/checkin';
import InlineLoader from '@/ui/common/inline-loader.vue';
import Caption1 from '@/ui/common/typo/caption-1.vue';
import Version from '@/ui/home/version/version.vue';
import BarcodeLogin from '@/views/Login/BarcodeLogin.vue';
import BgLogin from '@/views/Login/BgLogin.vue';
import LogoB2b from '@/views/Login/Logos/logo-b2b.vue';
import LogoDeli from '@/views/Login/Logos/logo-deli.vue';
import Logo from '@/views/Login/Logos/logo.vue';
import { App as CapacitorApp } from '@capacitor/app';
import {
  computed, ref, watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

const userStore = useUser();
const router = useRouter();
const route = useRoute();
const tryLogin = ref<boolean>(false);

const pageName = useRoute().name as string;
rumSpaManager.finishDataLoading(pageName);
rumSpaManager.startDataRendering(pageName);

const theme = computed((): string => {
  return userStore.theme;
});
const deviceId = computed((): string => {
  return userStore.device;
});

const update = () => {
  showUpdateWindow();
};

const isShowExitBtn = computed(() => PlatformService.isPro() || PlatformService.isCapacitor());

const closeApp = () => {
  if (PlatformService.isCapacitor()) {
    CapacitorApp.exitApp();
  } else if (PlatformService.isPro()) {
    logoutPro();
  }
};
const { isB2B } = useBranding();

const login = async (barcode: string) => {
  if (!deviceId.value) return true;
  try {
    tryLogin.value = true;
    await userStore.login({
      barcode,
      device: deviceId.value,
    });
    if (Checkin.checkinRequired()) {
      await router.push({ name: 'checkin' });
      return false;
    }
    if (Checkin.checkinStoreConfirmRequired()) {
      const storeTitle = userStore.options?.constants.store.title;
      const userOnTheSameStore = await Modal.show({
        title: storeTitle
          ? $gettext('Вы всё ещё на складе «%{storeTitle}»?', { storeTitle })
          : $gettext('Вы находитесь на том же складе?'),
        btnPosition: ButtonPositionsEnum.horizontal,
      });
      if (!userOnTheSameStore) {
        await router.push({ name: 'checkin' });
        return false;
      }
    }
    await router.push({ name: 'home' });
    return false;
  } catch (error: any) {
    if (error?.isAxiosError && error?.response) {
      const errCode = error.response.data.code;
      const errStatus = error.response.status;
      if (
        userStore.experimentByName(experiments.exp_dynamic_qr_only)
        && errStatus === 401
        && errCode === 'ER_DYNAMIC_QR_CODE_REQUIRED'
      ) {
        Modal.show({
          title: $gettext('Нужно отсканировать QR-код из Личного кабинета Яндекс Про'),
        });
      }
    }
    console.error(error);
    tryLogin.value = false;
    // Сообщить PRO, что попытка логина провалилась
    if (PlatformService.isPro()) {
      loadingFinish();
    }
    return true;
  }
};

// Сейчас deviceId может быть установлен позже, чем отрисуется компонент
// мы хотим попытаться войти по barcode только 1 раз, при этом нам нужен deviceId
watch(
  deviceId,
  (deviceId: string) => {
    const barcode = route.query.barcode;
    if (deviceId && barcode && typeof barcode === 'string') {
      login(barcode);
    }
  },
  { immediate: true },
);

useRequestBarcode(login);
</script>
