<template>
  <LayoutFooter
    class="flex-row"
  >
    <UiButton
      class="mr-2"
      data-test="delete-reasons back"
      background-color="secondary"
      @click="$emit('close')"
    >
      {{ $gettext('Назад') }}
    </UiButton>
    <UiButton
      :disabled="!selectedDeleteReason"
      data-test="delete-reasons done"
      background-color="primary"
      @click="$emit('done')"
    >
      {{ isEditMode ? $gettext('Сохранить') : $gettext('Продолжить') }}
    </UiButton>
  </LayoutFooter>
</template>

<script setup lang="ts">
import { $gettext } from '@/temp/plugins/gettext';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { SuggestReason } from '@/models/Constants';
import { computed } from 'vue';

interface DeleteReasonFooterProps {
  savedDeleteReason?: SuggestReason['value'];
  selectedDeleteReason?: SuggestReason['value'];
}

const props = defineProps<DeleteReasonFooterProps>();

defineEmits<{
  (e: 'close'): void;
  (e: 'done'): void;
}>();

/**
 * True, если редактируем уже сохраненные причины
 */
const isEditMode = computed(() => {
  return !!props.savedDeleteReason;
});
</script>
