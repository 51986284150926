<template>
  <!-- @todo: заменить на новый тег common/tag -->
  <span
    :data-test="`tag ${type}`"
    class="rounded bg-opacity-40 leading-none"
    :class="`bg-${bgColor}`"
    style="padding: 2px 6px"
  >
    <caption-2
      class="uppercase font-medium"
      :color="textColor"
    >
      <slot />
    </caption-2>
  </span>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

const mapTypeToColors = {
  success: {
    bg: 'green-high',
    text: 'green-normal',
  },
  warning: {
    bg: 'red-high',
    text: 'red-normal',
  },
  info: {
    bg: 'orange-high',
    text: 'orange-normal',
  },
  disable: {
    bg: 'gray-300',
    text: 'gray-500',
  },
};

export enum TagTypesEnum {
  success = 'success',
  warning = 'warning',
  info = 'info',
  disable = 'disable',
}

export default defineComponent({
  props: {
    type: {
      type: String as PropType<TagTypesEnum>,
      default: TagTypesEnum.success,
    },
  },
  computed: {
    textColor(): string {
      return mapTypeToColors[this.type]?.text || mapTypeToColors.success.text;
    },
    bgColor(): string {
      return mapTypeToColors[this.type]?.bg || mapTypeToColors.success.bg;
    },
  },
});
</script>
