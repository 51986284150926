<template>
  <div class="flex flex-col w-full">
    <Checkbox
      v-for="(option, index) in options"
      :key="option.name + option.id"
      :checked="value.includes(option.id)"
      :field-id="option.id"
      :label="option.name"
      :class="{ 'border-b': index !== options.length - 1 }"
      class="border-day-line py-5"
      @update:checked="check(option.id, $event)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Checkbox from './checkbox.vue';

export default defineComponent({
  name: 'MultiCheckbox',
  components: {
    Checkbox,
  },
  props: {
    value: {
      type: Array as PropType<string[]>,
      required: true,
    },
    options: {
      type: Array as PropType<{
        name: string;
        id: string;
      }[]>,
      required: true,
      validator: (value) => {
        if (!Array.isArray(value)) return false;
        const hasNameKey = value.every(option => Object.keys(option).includes('name'));
        const hasIdKey = value.every(option => Object.keys(option).includes('id'));
        return hasNameKey && hasIdKey;
      },
    },
  },
  emits: ['update:value'],

  setup(props, context) {
    const check = (optionId, checked) => {
      let updatedValue = [...props.value];
      if (checked) {
        updatedValue.push(optionId);
      } else {
        updatedValue.splice(updatedValue.indexOf(optionId), 1);
      }
      context.emit('update:value', updatedValue);
    };

    return {
      check,
    };
  },
});
</script>
