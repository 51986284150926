import { useRumStore } from '@/store/modules/rum';
import { v4 } from 'uuid';
import {
  clearMarks, getDuration, markEnd, markStart,
} from '../utils/timeMarkHelper';

type GetDurationMark = (markName: string) => number | undefined;
type CreateMark = () => {
  uniqueMarkName: string;
  getDuration: () => ReturnType<GetDurationMark>;
};

export type RestartMultiMark = (multiMarkName: string) => boolean;
type GetDurationMultiMark = (multiMarkName: string) => number | undefined;
type ClearMultiMark = (multiMarkName: string) => void;
type CreateMultiMark = () => {
  uniqueMarkName: string;
  restart: () => ReturnType<RestartMultiMark>;
  getDuration: () => ReturnType<GetDurationMultiMark>;
  clear: () => ReturnType<ClearMultiMark>;
};

export const useMark = () => {
  const {
    addMark,
    removeMark,

    addMultiMark,
    checkMultiMarkExist,
    removeMultiMark,
  } = useRumStore();

  const createMark: CreateMark = () => {
    const uniqueMarkName = v4();
    addMark(uniqueMarkName);
    markStart(uniqueMarkName);

    return {
      uniqueMarkName,
      getDuration: () => getDurationMark(uniqueMarkName),
    };
  };

  const getDurationMark: GetDurationMark = (markName) => {
    markEnd(markName);
    removeMark(markName);
    return getDuration(markName);
  };

  const createMultiMark: CreateMultiMark = () => {
    const uniqueMarkName = v4();
    addMultiMark(uniqueMarkName);
    markStart(uniqueMarkName);

    return {
      uniqueMarkName,
      restart: () => restartMultiMark(uniqueMarkName),
      getDuration: () => getDurationMultiMark(uniqueMarkName),
      clear: () => clearMultiMark(uniqueMarkName),
    };
  };

  const restartMultiMark: RestartMultiMark = (multiMarkName) => {
    if (checkMultiMarkExist(multiMarkName)) {
      markStart(multiMarkName);
      return true;
    }
    return false;
  };

  // марка будет очищена в performance api
  const getDurationMultiMark: GetDurationMultiMark = (multiMarkName) => {
    markEnd(multiMarkName);
    return getDuration(multiMarkName);
  };

  const clearMultiMark: ClearMultiMark = (multiMarkName) => {
    clearMarks(multiMarkName);
    removeMultiMark(multiMarkName);
  };

  return {
    createMark,
    getDurationMark,

    createMultiMark,
    restartMultiMark,
    getDurationMultiMark,
    clearMultiMark,
  };
};
