<template>
  <Bar
    :caption="caption"
    :text="text"
    :icons="{ left: 'back', right: type }"
    :custom-menu="type !== 'none'"
    @close-click="emits('close')"
    @menu-click="menuClick"
  />
</template>
<script setup lang="ts">
import Suggest from '@/models/Suggest';
import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';
import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import { $gettext } from '@/temp/plugins/gettext';
import { checkSuggestNeedMenu } from '@/views/OrderRetail/tools/checkSuggestNeedMenu';
import { checkSuggestCanBeRemoved } from '@/views/OrderRetail/tools/checkSuggestCanBeRemoved';
import Bar from '@/ui/common/bar/bar.vue';
import { computed } from 'vue';

interface RetailHeaderDetails {
  order: OrderOrderRetail;
  suggest: Suggest | TrueMarkSuggestWrapper;
  collected: number;
}

const caption = computed<string>(() => {
  if (!props.suggest || !props.suggest.product) {
    return '';
  }
  return props.suggest.product.title;
});

const text = computed<string>(() => {
  if (!props.order) {
    return '';
  }
  return `${$gettext('Заказ')} ${props.order.orderNumberForView}`;
});

const type = computed<'remove' | 'menu' | 'none'>(() => {
  if (checkSuggestNeedMenu(props.suggest, props.collected)) {
    return 'menu';
  }

  return checkSuggestCanBeRemoved(props.suggest) ? 'remove' : 'none';
});

const props = defineProps<RetailHeaderDetails>();

const emits = defineEmits<{
  (e: 'close'): void;
  (e: 'openSuggestMenu'): void;
  (e: 'removeSuggest'): void;
}>();

const menuClick = () => {
  if (type.value === 'remove') emits('removeSuggest');
  if (type.value === 'menu') emits('openSuggestMenu');
};
</script>
