<template>
  <div>
    <notifications
      class="universal-modal"
      classes="notifications"
      width="100%"
      group="universal-modal"
      position="bottom center"
    >
      <template #body="props">
        <UniversalModal
          :position="props.item.data.position"
          :backdrop-click="props.item.data.backdropClick"
          :class="props.item.data.class"
          @backdrop-click="executeAndClose(() => props.item.data.resolve(false), props.close)"
        >
          <ModalContent
            :data-test="props.item.data.dataTest"
            :title="props.item.data.title"
            :title-align="props.item.data.titleAlign"
            :text="props.item.data.text"
            :text-align="props.item.data.textAlign"
            :icon-top="props.item.data.iconTop"
            :icon-center="props.item.data.iconCenter"
            :icon-bottom="props.item.data.iconBottom"
            :is-wrap="props.item.data.isWrap"
            :buttons="props.item.data.buttons"
            @btn-click="cb => executeAndClose(() => props.item.data.resolve(cb()), props.close)"
          />
        </UniversalModal>
      </template>
    </notifications>

    <notifications
      classes="notifications"
      width="100%"
      group="major-error"
      position="bottom center"
    >
      <template #body="props">
        <MajorError
          :details="props.item.data.details"
          :title="props.item.data.title"
          @close="executeAndClose(props.item.data.onClose, props.close)"
          @repeat="executeAndClose(props.item.data.onRepeat, props.close)"
        />
      </template>
    </notifications>
  </div>
</template>

<script lang="ts">
import MajorError from '@/temp/ui/notifications/major-error/major-error.vue';
import ModalContent from '@/ui/common/notifications/modal-content.vue';
import UniversalModal from '@/ui/common/notifications/universalModal.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NotificationsGroup',
  components: {
    UniversalModal,
    ModalContent,
    MajorError,
  },
  methods: {
    showDetails(title, text): void {
      this.$modal.show({
        title,
        text,
      });
    },
    executeAndClose(cb, close): void {
      cb();
      close();
    },
  },
});
</script>

<style lang="scss">
.vue-notification-group {
  z-index: 1000;

  &.universal-modal {
    z-index: 1001;
  }
}
</style>
