import { api } from '@/fsd/data/api/api.service';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import Product from '@/models/Product';
import BaseOrder, { OrderTypeEnum } from '@/models/orders/BaseOrder';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useUser } from '@/store/modules/user';
import { experiments, permits } from '@/temp/constants';
import { $gettext } from '@/temp/plugins/gettext';
import { isProductInRequired } from '@/types/product';
import { isAxiosError } from 'axios';

// true- можно добавить продукт, false - нельзя, undefined - хз, пусть еще раз стукнется в ручку
export const checkContractor = async (order_id: BaseOrder['order_id'], product_id: Product['product_id']) => {
  const order = useOrders().orderById(order_id);
  const product = useProducts().productById(product_id);
  if (!product) return false;
  // ищем добавляемый продукт в required/suggests документа, если есть - то разрешаем добавлять без запросов
  // смотрим включен ли эксп на складе, если нет, то разрешаем добавлять все, что угодно
  // ф-я дергается при создании пересчета, когда ордера еще нет
  if (
    order
    && order.type === OrderTypeEnum.sale_stowage
    && (order.required.find(r => isProductInRequired(r) && r.product_id === product_id)
      || order.suggests?.find(s => s.product_id === product_id)
      || !useUser().experimentByName(experiments.exp_schrodinger)
      || !useUser().permitByName(permits.tsd_check_contractor))
  )
    return true;
  try {
    await api.check_contractor({
      order_id,
      product_id,
    });
    // если 200 код, то разрешаем добавлять
    return true;
  } catch (e: unknown) {
    if (isAxiosError(e)) {
      if (e.response?.data.code === 'ER_NOT_FOUND') {
        // если ответ ER_NOT_FOUND, то это значит, что добавить в размещение этот товар нельзя - чистим продукт, закрываем попап
        Alerts.error(
          $gettext('Неверный поставщик для товара %{product}', {
            product: product.title,
          }),
        );
        return false;
      }
    }
    // если это рандомная ошибка, то даем возможность отправить запрос еще раз
    Alerts.error($gettext('Произошла ошибка при проверке товара в ассортименте поставщика'));
    return undefined;
  }
};
