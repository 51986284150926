import BaseOrder, { OrderWorkStatusEnum } from '@/models/orders/BaseOrder';
import { ScheduleTypeEnum } from '@/models/Product';
import Suggest from '@/models/Suggest';
import { useOrders } from '@/store/modules/orders';

export const statusSorter = (a: BaseOrder, b: BaseOrder) => {
  switch (true) {
    case a.status === OrderWorkStatusEnum.processing && b.status === OrderWorkStatusEnum.processing:
      return 0;
    case a.status === OrderWorkStatusEnum.processing:
      return -1;
    case b.status === OrderWorkStatusEnum.processing:
      return 1;
    default:
      return 0;
  }
};

export const createdDateSorter = (a: BaseOrder, b: BaseOrder) => {
  return a.created - b.created;
};

export const queueSorter = (a, b) => {
  const placeA = useOrders().ordersQueue.indexOf(a.external_id);
  const placeB = useOrders().ordersQueue.indexOf(b.external_id);
  if (placeA !== -1 && placeB !== -1) {
    return placeA - placeB;
  }
  return statusSorter(a, b) || createdDateSorter(a, b);
};

const isWaitingForCourierOrder = (order: BaseOrder): boolean => {
  const isDone = order.suggests.length && order.suggests.every(s => s.isDone || s.isPackaging);

  return !!order.attr.maybe_rover && !order.courier && !!isDone;
};

const isWaitingForKitchenOrder = (order: BaseOrder): boolean => {
  const productSuggests: Suggest[] = [];
  const kitchenSuggests: Suggest[] = [];

  order.suggests.filter((s) => {
    if (
      s.product?.schedule_type === ScheduleTypeEnum.coffee
      || s.product?.schedule_type === ScheduleTypeEnum.kitchens
    ) {
      kitchenSuggests.push(s);
    } else {
      productSuggests.push(s);
    }
  });

  const isProductDone = productSuggests.every(s => s.isDone || s.isPackaging);
  const isKitchenWaiting = kitchenSuggests.some(s => s.isBlocked);

  return isProductDone && isKitchenWaiting;
};

export const isWaitingForCompleteOrder = (order: BaseOrder): boolean => {
  return isWaitingForCourierOrder(order) || isWaitingForKitchenOrder(order);
};

export const mergeCreatedSort = (a: BaseOrder[], b: BaseOrder[]) => {
  const sorted: BaseOrder[] = [];
  let indexA = 0;
  let indexB = 0;

  while (indexA < a.length && indexB < b.length) {
    if (a[indexA].created < b[indexB].created) {
      sorted.push(a[indexA]);
      indexA++;
    } else {
      sorted.push(b[indexB]);
      indexB++;
    }
  }

  while (indexA < a.length) {
    sorted.push(a[indexA]);
    indexA++;
  }

  while (indexB < b.length) {
    sorted.push(b[indexB]);
    indexB++;
  }
  return sorted;
};
