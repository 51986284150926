<template>
  <div class="flex items-center pl-[3px] w-[32px]">
    <p class="w-[13px] text-center">
      {{ eventErrorCount }}
    </p>
    <div class="w-[16px] min-w-4 h-4 flex justify-center items-center">
      <EventIcon :class="eventIndicatorColorClass" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useIndicators } from '@/store/indicators';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import EventIcon from './icons/EventIcon.vue';

const {
  eventErrorCount, hasEventsPoll, hasEventError, eventPullCount,
} = storeToRefs(useIndicators());

const eventIndicatorColorClass = computed<string>(() => {
  if (!hasEventsPoll.value) {
    return 'text-semantic-text-minor';
  }
  if (hasEventError.value) {
    return 'text-red-normal';
  }
  if (eventPullCount.value % 2 === 0) {
    return 'text-blue-normal';
  }
  return 'text-green-normal';
});
</script>
