import { $gettext, gettextWrapper } from '@/temp/plugins/gettext';

export const defaultSuggestWriteoffReasons = gettextWrapper({
  sale_stowage_review: {
    TRASH_DAMAGE: $gettext('Повреждена упаковка'),
    TRASH_TTL: $gettext('Истек срок годности'),
    GOOD_EXTRAS: $gettext('Излишек'),
  },
  sale_stowage_trash: {
    TRASH_DAMAGE: $gettext('Повреждена упаковка'),
    TRASH_TTL: $gettext('Истек срок годности'),
    TRASH_DECAYED: $gettext('Потерял товарный вид'),
    TRASH_ACCIDENT: $gettext('Поломка оборудования'),
    TRASH_ORDINANCE: $gettext('По приказу/распоряжению'),
    TRASH_MOL: $gettext('Списание на мол'),
  },
  sale_stowage_stowage: {
    TRASH_DAMAGE: $gettext('Повреждена упаковка'),
    TRASH_TTL: $gettext('Истек срок годности'),
    TRASH_DECAYED: $gettext('Потерял товарный вид'),
    TRASH_ACCIDENT: $gettext('Поломка оборудования'),
    TRASH_ORDINANCE: $gettext('По приказу/распоряжению'),
    TRASH_MOL: $gettext('Списание на мол'),
  },
  refund_trash: {
    TRASH_DAMAGE: $gettext('Повреждена упаковка'),
    TRASH_TTL: $gettext('Истек срок годности'),
    TRASH_DECAYED: $gettext('Потерял товарный вид'),
  },
  part_refund_trash: {
    TRASH_DAMAGE: $gettext('Повреждена упаковка'),
    TRASH_TTL: $gettext('Истек срок годности'),
    TRASH_DECAYED: $gettext('Потерял товарный вид'),
  },
  shipment_rollback_trash: {
    TRASH_DAMAGE: $gettext('Повреждена упаковка'),
    TRASH_TTL: $gettext('Истек срок годности'),
    TRASH_DECAYED: $gettext('Потерял товарный вид'),
  },
});
