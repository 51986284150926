import { getTrueMarkType } from '@/fsd/entities/true-mark/utils';
import IconCancelForModal from '@/fsd/shared/icons/IconCancelForModal.vue';
import IconWarnForModal from '@/fsd/shared/icons/IconWarnForModal.vue';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { ButtonPositionsEnum, InfoItem } from '@/fsd/shared/universalModal';
import { ERR, ERR_STATUS_BAD_TRUE_MARK } from '@/temp/constants/errors';
import { $gettext } from '@/temp/plugins/gettext';
import { markRaw } from 'vue';
import { OrderTypeEnum } from '@/models/orders/BaseOrder';
import {
  getInfoBlockDescriptionByCodeAcceptance,
  getInfoBlockDescriptionByCodeDefault,
  getInfoBlockDescriptionByStatusDefault,
  getTitleAndTextByCodeAcceptance,
  getTitleAndTextByCodeDefault,
  getTrueMarkErrorTitle,
} from '@/fsd/entities/modals/TrueMarkErrorsTexts';
import type { TitleAndText } from '@/fsd/entities/modals/TrueMarkErrorsTexts/types';

const WARN_ICON_LIST = [
  ERR.ER_INVALID_TRUE_MARK,
  ERR.ER_SUGGEST_TRUE_MARK_REQUIRED,
  ERR.ER_EXTERNAL_SERVICE,
  ERR.ER_SUGGEST_TRUE_MARK_ALREADY_TAKEN,
  ERR.ER_SUGGEST_TRUE_MARK_DUPLICATED,
  ERR.ER_SUGGEST_WRONG_TRUE_MARK,
  ERR.ER_SUGGEST_TRUE_MARK_NOT_IN_CURRENT_ORDER,
];

export const checkNeedTrueMarkModal = (code?: string) => !!code && !!ERR[code];

export type ErrorTrueMarkParams = {
  code: ERR;
  status?: ERR_STATUS_BAD_TRUE_MARK;
  orderType?: OrderTypeEnum;
};

const getTitleAndDescriptionDefault = (code: ERR, status?: ERR_STATUS_BAD_TRUE_MARK) => {
  const titleText = getTitleAndTextByCodeDefault(code);
  const description = getInfoBlockDescriptionByCodeDefault(code);
  let descriptionStatus: InfoItem | null = null;

  if (status) {
    descriptionStatus = getInfoBlockDescriptionByStatusDefault(status);
  }

  return {
    titleText,
    description,
    descriptionStatus,
  };
};

export const errorTrueMarkModal = async (params: ErrorTrueMarkParams) => {
  const { TITLE_CODE_ERROR } = getTrueMarkErrorTitle();
  const { code, status, orderType } = params;

  let title = $gettext('Отсканируйте «%{markType}» ещё раз',
    { markType: getTrueMarkType() },
  );
  let text = '';
  const infoBlock: InfoItem[] = [];
  let errorTitleText: TitleAndText | null = null;
  let descriptionCode: InfoItem | null = null;
  let descriptionStatus: InfoItem | null = null;

  // * Ветвление для разных типов ордеров
  // * Если для этого типа ордера есть уникальные тексты ошибки
  if (orderType === OrderTypeEnum.acceptance) {
    errorTitleText = getTitleAndTextByCodeAcceptance(code);
    descriptionCode = getInfoBlockDescriptionByCodeAcceptance(code);
  }

  // * Если мы не нашли текст для типа ордера, то идем в default
  // * По типам ордеров не должно быть случая, когда есть descriptions или text, но нет title
  if (!errorTitleText) {
    const textDefault = getTitleAndDescriptionDefault(code, status);

    errorTitleText = textDefault.titleText;
    descriptionCode = textDefault.description;
    descriptionStatus = textDefault.descriptionStatus;
  }

  if (errorTitleText) {
    title = errorTitleText.title;
    text = errorTitleText.text;
  }

  if (descriptionCode) {
    infoBlock.push(descriptionCode);
  }

  if (descriptionStatus) {
    infoBlock.push(descriptionStatus);
  }

  infoBlock.push({
    title: code,
    subtitle: TITLE_CODE_ERROR,
  });

  const component = WARN_ICON_LIST.includes(code)
    ? markRaw(IconWarnForModal)
    : markRaw(IconCancelForModal);

  await Modal.show({
    title,
    text,
    infoBlock,
    component,
    btnPosition: ButtonPositionsEnum.one_button,
    confirmBtnTitle: $gettext('Хорошо'),
  });
};
