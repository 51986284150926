<template>
  <svg
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.899 2.96003C8.57907 -0.320011 3.42093 -0.320011 0.100954 2.96003C-0.0303446 3.10089 -0.0303446 3.30212 0.0821968 3.42286L0.719932 4.10704C0.85123 4.22778 1.0388 4.2479 1.15134 4.12717C3.88985 1.43069 8.11015 1.43069 10.8487 4.12717C10.98 4.2479 11.1488 4.22778 11.2801 4.10704L11.9178 3.42286C12.0303 3.30212 12.0303 3.10089 11.899 2.96003ZM6.00938 6.92426C5.33413 6.92426 4.80894 7.50783 4.80894 8.21213C4.80894 8.93656 5.33413 9.5 6.00938 9.5C6.66587 9.5 7.20982 8.93656 7.20982 8.21213C7.20982 7.50783 6.66587 6.92426 6.00938 6.92426ZM9.79827 5.25405C7.64123 3.20151 4.35877 3.20151 2.20173 5.25405C2.07043 5.37479 2.07043 5.59614 2.18297 5.71688L2.83946 6.40106C2.952 6.5218 3.12082 6.5218 3.25211 6.42118C4.82769 4.95221 7.17231 4.95221 8.74789 6.42118C8.87918 6.5218 9.048 6.5218 9.16054 6.40106L9.81703 5.71688C9.92957 5.59614 9.92957 5.37479 9.79827 5.25405Z"
      fill="currentColor"
    />
  </svg>
</template>
