import { Alerts } from '@/fsd/shared/tools/alertNotification';
import Product from '@/models/Product';
import Suggest from '@/models/Suggest';
import { $gettext } from '@/temp/plugins/gettext';
import { needLessWeightModal } from '@/utils/modals';
import { ReturnWeightEnum } from '../types';

/**
 * пПроверяет итоговый вес отсканированных марок, в случае ошибки показывает модалку
 * @param suggest
 * @param barcodes
 */
export const checkWeight = async (suggest: Suggest, barcodes: string[]): Promise<ReturnWeightEnum> => {
  if (!suggest.product?.isTrueWeight) return ReturnWeightEnum.ERROR;

  const weight = Product.weightFromBarcode(barcodes);

  if (!weight) {
    Alerts.error($gettext('Отсканирован неверный штрихкод %{barcode}', { barcode: barcodes[barcodes.length - 1] }));
    return ReturnWeightEnum.ERROR;
  }

  if (suggest.max_count && weight > suggest.max_count) {
    const delta = weight - suggest.max_count;
    await needLessWeightModal(delta.toString());
    return ReturnWeightEnum.OVER;
  }
  if (suggest.min_count && weight < suggest.min_count) {
    return ReturnWeightEnum.MORE;
  }
  return ReturnWeightEnum.FULL;
};
