<template>
  <div class="empty-container">
    <div class="title-container">
      <Typography
        type="h2"
        align="center"
      >
        {{ $gettext('Товаров с таким статусом нет') }}
      </Typography>
    </div>
    <div>
      <img
        src="@/assets/img/empty-list.jpg"
        alt=""
      >
    </div>
  </div>
</template>
<script>
import Typography from '@/ui/common/typography.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { Typography },
});
</script>
<style lang="scss" scoped>
.empty-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-grow: 1;

  & div {
    flex-grow: 1;
  }
}

.title-container {
  display: flex;
  align-items: center;
  padding: 0 16px;
}
</style>
