<template>
  <svg
    width="104"
    height="48"
    viewBox="0 0 104 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M57.6638 47.0952L0.449837 36.9769C-0.458619 36.8172 0.0519964 35.9463 1.59324 35.0347L41.9005 11.1956C43.4417 10.284 45.4278 9.67628 46.3362 9.83604L103.55 19.9543C104.459 20.1141 103.948 20.985 102.407 21.8966L62.0995 45.7357C60.5614 46.6504 58.5722 47.2581 57.6638 47.0952Z"
      fill="#E8E7E7"
    />
    <path
      d="M91.1606 20.7793L98.8606 22.095L59.2456 45.5614L51.5488 44.2488L91.1606 20.7793Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M91.1606 19.0898L98.8606 20.4024L59.2456 43.8719L51.5488 42.5562L91.1606 19.0898Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M94.918 16.0863V22.8434L98.8588 20.3999V13.834L94.918 16.0863Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M51.5488 42.5547V44.2463L59.2456 45.5589L59.4336 43.8704L51.5488 42.5547Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M98.861 22.0951L98.8579 20.4004L59.434 43.873L59.2461 45.5615L98.861 22.0951Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M94.918 22.8423L87.0332 21.5297V14.7695L94.918 16.0852V22.8423Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M77.6445 26.223V32.9832L81.5854 30.5367V23.9707L77.6445 26.223Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M77.6445 32.9829L69.7598 31.6672V24.9102L77.6445 26.2227V32.9829Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M59.4336 37.1117V43.8719L63.3713 41.4253V34.8594L59.4336 37.1117Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M59.4336 43.8716L51.5488 42.5559V35.7988L59.4336 37.1114V43.8716Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M64.8786 16.4629L72.5754 17.7755L32.9605 41.2449L25.2637 39.9292L64.8786 16.4629Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M64.8786 14.7695L72.5754 16.0852L32.9605 39.5516L25.2637 38.239L64.8786 14.7695Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M68.6309 11.766V18.5262L72.5717 16.0796V9.51367L68.6309 11.766Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25.2637 38.2383V39.9267L32.9605 41.2424L33.1484 39.5508L25.2637 38.2383Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M72.5759 17.7748L72.5727 16.0801L33.1489 39.5527L32.9609 41.2443L72.5759 17.7748Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M68.6328 18.5258L60.748 17.2102V10.4531L68.6328 11.7657V18.5258Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M51.3633 21.9066V28.6637L55.301 26.2202V19.6543L51.3633 21.9066Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M51.3625 28.6626L43.4746 27.35V20.5898L51.3625 21.9055V28.6626Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33.1484 32.7953V39.5523L37.0893 37.1089V30.543L33.1484 32.7953Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33.1484 39.5532L25.2637 38.2406V31.4805L33.1484 32.7962V39.5532Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M41.0337 12.1426L48.7336 13.4583L9.11869 36.9246L1.42188 35.6121L41.0337 12.1426Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M41.0337 10.4531L48.7336 11.7657L9.11869 35.2352L1.42188 33.9195L41.0337 10.4531Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M44.791 7.44961V14.2066L48.7318 11.7632V5.19727L44.791 7.44961Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.42188 33.9199V35.6115L9.11869 36.9241L9.30664 35.2356L1.42188 33.9199Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M48.7341 13.4584L48.7309 11.7637L9.3071 35.2363L9.11914 36.9248L48.7341 13.4584Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M44.7891 14.2055L36.9043 12.893V6.13281L44.7891 7.44851V14.2055Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.5156 17.5863V24.3465L31.4564 21.8999V15.334L27.5156 17.5863Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.5176 24.3462L19.6328 23.0305V16.2734L27.5176 17.586V24.3462Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.30664 28.475V35.2352L13.2443 32.7886V26.2227L9.30664 28.475Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.30664 35.2348L1.42188 33.9191V27.1621L9.30664 28.4747V35.2348Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M59.4346 37.113L1.42188 27.1638V25.6602L59.4346 35.6125V37.113Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.0977 28.6612L30.4279 31.4774L59.8118 11.0121L56.9298 9.69643C56.9298 9.69643 46.304 8.40266 45.9061 8.75665C45.5052 9.1169 14.0977 28.6612 14.0977 28.6612Z"
      fill="#F9F9F9"
    />
    <path
      d="M63.2689 33.371L5.25618 23.4219L1.42188 25.6586L59.4346 35.6484L63.2689 33.371Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M81.4908 22.505L23.4781 12.5527L19.6406 14.7925L77.6534 24.7793L81.4908 22.505Z"
      fill="#F9F9F9"
    />
    <path
      d="M81.4926 22.5043L80.6719 22.3633"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M98.7545 12.2734L40.7386 2.32422L36.9043 4.5609L94.917 14.5508L98.7545 12.2734Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M63.3713 34.8599L59.4336 37.1122V35.6117L63.3713 33.3594V34.8599Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M77.6455 26.2223L19.6328 16.2732V14.7695L77.6455 24.7218V26.2223Z"
      fill="#F9F9F9"
    />
    <path
      d="M77.6455 24.7218V26.2223L19.6328 16.2732V14.7695"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M81.5854 23.9712L77.6445 26.2236V24.723L81.5854 22.4707V23.9712Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M94.917 16.0856L36.9043 6.13332V4.63281L94.917 14.582V16.0856Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M98.8588 13.8337L94.918 16.0861V14.5824L98.8588 12.3301V13.8337Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.42188 24.1585L40.8457 0.876953L49.0218 2.08613L9.4946 25.471L9.47267 27.0405L1.42188 25.659V24.1585L9.48207 25.54L1.42188 24.1585Z"
      fill="#F9F9F9"
    />
    <path
      d="M1.42188 24.1585L40.8457 0.876953L49.0218 2.08613L9.4946 25.471L9.47267 27.0405L1.42188 25.659V24.1585ZM1.42188 24.1585L9.48207 25.54"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.49414 25.4713V27.0125L48.73 3.69342V2.38086L9.49414 25.4713Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.3613 26.2237L52.7883 2.94531L60.861 4.44584L21.4341 27.5394L21.4153 29.1088L13.3613 27.7274V26.2237L21.4215 27.6083L13.3613 26.2237Z"
      fill="#F9F9F9"
    />
    <path
      d="M13.3613 26.2237L52.7883 2.94531L60.861 4.44584L21.4341 27.5394L21.4153 29.1088L13.3613 27.7274V26.2237ZM13.3613 26.2237L21.4215 27.6083"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.4336 27.5389V29.077L60.6695 5.76099V4.44531L21.4336 27.5389Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M38.5176 30.5432L77.9446 7.26172L86.0173 8.76223L46.5903 31.8558L46.5715 33.4252L38.5176 32.0438V30.5432L46.5778 31.9247L38.5176 30.5432Z"
      fill="#F9F9F9"
    />
    <path
      d="M38.5176 30.5432L77.9446 7.26172L86.0173 8.76223L46.5903 31.8558L46.5715 33.4252L38.5176 32.0438V30.5432ZM38.5176 30.5432L46.5778 31.9247"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M46.5918 31.8553V33.3965L85.8277 10.0774V8.76172L46.5918 31.8553Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25.4531 28.2882L64.8801 5.00977L72.9528 6.51028L33.5259 29.6039L33.5071 31.1733L25.4531 29.7918V28.2882L33.5133 29.6728L25.4531 28.2882Z"
      fill="#F9F9F9"
    />
    <path
      d="M25.4531 28.2882L64.8801 5.00977L72.9528 6.51028L33.5259 29.6039L33.5071 31.1733L25.4531 29.7918V28.2882ZM25.4531 28.2882L33.5133 29.6728"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33.5254 29.6033V31.1446L72.7613 7.82546V6.50977L33.5254 29.6033Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M51.3633 32.7952L90.7903 9.51367L98.863 11.0173L59.436 34.1078L59.4141 35.6772L51.3633 34.2957V32.7952L59.4235 34.1767L51.3633 32.7952Z"
      fill="#F9F9F9"
    />
    <path
      d="M51.3633 32.7952L90.7903 9.51367L98.863 11.0173L59.436 34.1078L59.4141 35.6772L51.3633 34.2957V32.7952ZM51.3633 32.7952L59.4235 34.1767"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M59.4336 34.108V35.6493L98.6695 12.3301V11.0176L59.4336 34.108Z"
      fill="#F9F9F9"
      stroke="#B5B5B5"
      stroke-width="0.626521"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
