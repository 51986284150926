import { v4 } from 'uuid';
import { ComponentOptions } from 'vue';

export const withUuid: ComponentOptions = {
  beforeCreate() {
    this._uuid = v4();
  },
};

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    _uuid: string;
  }
}
