// organize-imports-ignore
// rum
import '@yandex-int/rum-counter/dist/inline/interface.js';
import '@yandex-int/rum-counter/dist/inline/longtask.js';
import '@yandex-int/rum-counter/dist/bundle/scroll.js';
import '@yandex-int/rum-counter/dist/bundle/send.js';
import '@yandex-int/rum-counter/dist/bundle/spa-metric.js';
import '@yandex-int/rum-counter/dist/bundle/onload.js';

// error-booster
import '@yandex-int/error-counter/dist/interfaceOverRum.js';
import '@yandex-int/error-counter/dist/implementation.js';
import '@yandex-int/error-counter/dist/filters.js';
import '@yandex-int/error-counter/dist/logError.js';
import '@yandex-int/error-counter/dist/logEvent.js';

import { v1 as uuidv1 } from 'uuid';
import { errorShouldNotBeIgnored } from '@/utils';

const reqid = uuidv1();

window.Ya.Rum.initErrors({
  project: 'lavka-picker',
  reqid,
  // @ts-expect-error
  env: process.env.VUE_APP_MODE,
  version: process.env.VUE_APP_VERSION, // Версия [статики] вашего приложения
  referrer: true,
  unhandledRejection: true,
  uncaughtException: true,
  transform: (error) => {
    if (!errorShouldNotBeIgnored(error as any)) {
      return false;
    }
    return error;
  },
});

export const rumConfig = {
  settings: {
    beacon: !!navigator.sendBeacon,
    clck: 'https://yandex.ru/clck/click',
    reqid,
    crossOrigin: '',
    sendUrlInResTiming: true,
  },
  vars: {
    'rum_id': 'ru.lavka-picker',
    '-project': 'lavka-picker',
    '-env': process.env.VUE_APP_MODE!,
    '-version': process.env.VUE_APP_VERSION!,
    '-page': 'entry',
  },
};

window.Ya.Rum.init(rumConfig.settings, rumConfig.vars);
