<template>
  <p
    v-if="showRamIndicator"
    class="px-[3px] w-[64px] text-right"
    :class="ramIndicatorColorClass"
  >
    {{ `${usedMemory} Mb` }}
  </p>
</template>

<script setup lang="ts">
import { useMemory } from '@vueuse/core/index';
import { computed } from 'vue';

const { isSupported: showRamIndicator, memory } = useMemory();

const usedMemory = computed(() => {
  if (!memory.value || !showRamIndicator) return 0;
  return ~~(memory.value.usedJSHeapSize / 1024 / 1024);
});

const ramIndicatorColorClass = computed(() => {
  if (usedMemory.value <= 50) {
    return 'text-green-normal';
  }
  if (usedMemory.value <= 80) {
    return 'text-yellow-dark';
  }
  return 'text-red-normal';
});
</script>
