<template>
  <div class="flex flex-col gap-6">
    <InfoRow
      :title="$gettext('Полка')"
      :value="shelf?.title ?? ''"
    />
    <template v-if="shelf && product && shelf.isKitchenShelf && product.quants > 1">
      <InfoRow
        :title="$gettext('Закрытых упаковок на полке')"
        :value="String(closedPackage)"
      />
      <InfoRow
        v-if="tail"
        :title="$gettext('В открытой упаковке')"
        :value="tailTitle"
      />
    </template>
    <InfoRow
      v-else
      :title="$gettext('На полке')"
      :value="countToView"
    />

    <InfoRow
      v-if="availableOnShelf"
      :title="$gettext('Резерв')"
      :value="String(availableOnShelf?.reserved)"
    />
    <InfoRow
      v-if="count"
      :title="$gettext('Новое кол - во')"
      :value="String(count)"
    />
    <InfoRow
      v-if="date"
      :title="$gettext('Новая дата производства')"
      :value="date"
    />
  </div>
</template>

<script lang="ts" setup>
import InfoRow from '@/fsd/widgets/productCard/InfoRow.vue';
import Product from '@/models/Product';
import Shelf from '@/models/Shelf';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { getQuantUnit } from '@/temp/constants/translations/quantUnits';
import { $gettext } from '@/temp/plugins/gettext';
import { getCountToView } from '@/ui/common/suggest-card/formatters/count-formatter';
import { computed, toRefs } from 'vue';

const props = defineProps<{
  shelf_id: Shelf['shelf_id'];
  product_id: Product['product_id'];
  count?: number;
  date?: string;
}>();
const { product_id } = toRefs(props);
const product = computed(() => {
  return useProducts().productById(product_id.value);
});
const shelf = computed(() => {
  return useShelves().shelfById(props.shelf_id);
});

const availableOnShelf = computed(() => {
  const available = useProducts().availableByProductId(product_id.value);
  if (!available) return undefined;
  return available.find(a => a.shelf_id === shelf.value?.shelf_id);
});

const closedPackage = computed(() => {
  if (!availableOnShelf.value || !product.value) return 0;
  return ~~(availableOnShelf.value.count / product.value.quants);
});

const tail = computed(() => {
  if (!availableOnShelf.value || !product.value) return 0;
  return ~~(availableOnShelf.value.count % product.value.quants);
});
const tailTitle = computed(() => {
  if (!tail.value || !product.value) return '-';
  if (!product.value.quant_unit) return String(tail.value);
  return `${tail.value} ${getQuantUnit(product.value.quant_unit)}`;
});
const countToView = computed(() => {
  if (!availableOnShelf.value || !product.value) {
    return '-';
  }
  return getCountToView({
    type_accounting: product.value.type_accounting,
    count: availableOnShelf.value.count,
  });
});
</script>
