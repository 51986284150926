<template>
  <Overlay>
    <div class="role-upgrade-container">
      <Layout>
        <template #header>
          <Bar
            :caption="$gettext('Изменение роли')"
            :icons="{ left: 'close' }"
            @close-click="$emit('close')"
          />
        </template>
        <div class="content-container">
          <Typography
            type="h3"
            align="center"
          >
            {{ $gettext('Введите личный код, чтобы повысить роль:') }}
          </Typography>
        </div>
        <template #footer>
          <Keyboard
            :placeholder="$gettext('Личный код...')"
            type="pin"
            @input="onInputCode"
          />
        </template>
      </Layout>
    </div>
  </Overlay>
</template>
<script lang="ts">
import { useUser } from '@/store/modules/user';
import Bar from '@/ui/common/bar/bar.vue';
import Keyboard from '@/ui/common/keyboard/keyboard.vue';
import Layout from '@/ui/common/layout.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import Overlay from '@/ui/common/overlay/overlay.vue';
import Typography from '@/ui/common/typography.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RoleUpgrade',
  components: {
    Typography,
    Overlay,
    Bar,
    Layout,
    Keyboard,
  },
  emits: ['close'],
  setup() {
    const { showLoader } = useLoader();
    const userStore = useUser();

    return {
      showLoader,
      userStore,
    };
  },
  methods: {
    async onInputCode(value: string | number | null): Promise<void> {
      const { closeLoader } = this.showLoader();
      if (value) {
        try {
          await this.userStore.upgrade({ pin: value.toString() });
          this.$alert.success(this.$gettext('Ваша роль была успешно изменена'));
          this.$emit('close');
        } catch {
          this.$alert.error(this.$gettext('Введен неправильный личный код'));
        } finally {
          closeLoader();
        }
      }
    },
    inputValidator(value): boolean {
      return value.length <= 4 && value.match(/^\d+$/);
    },
    validator(value): boolean {
      return value.length === 4 && value.match(/^\d+$/);
    },
  },
});
</script>
<style lang="scss" scoped>
.role-upgrade-container {
  background: var(--main-bg);
  height: 100vh;
  width: 100vw;
  pointer-events: auto;
}
.content-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px 30px;
}
</style>
