type ActionPro = 'openCamera' | 'closeCamera' | 'pop' | 'addFlashlight' | 'deleteFlashlight' | 'loadingFinish';

interface CallHandler {
  (action: ActionPro): void;
  (action: 'vibration', amount: number): void;
}

declare global {
  interface Window {
    flutter_inappwebview?: {
      callHandler: CallHandler;
    };
  }
}

export const openCameraPro = () => {
  window.flutter_inappwebview?.callHandler('openCamera');
};

export const closeCameraPro = () => {
  window.flutter_inappwebview?.callHandler('closeCamera');
};

export const logoutPro = () => {
  window.flutter_inappwebview?.callHandler('pop');
};

export const enableFlashLightPro = () => {
  window.flutter_inappwebview?.callHandler('addFlashlight');
};

export const disableFlashLightPro = () => {
  window.flutter_inappwebview?.callHandler('deleteFlashlight');
};

export const vibrationPro = (n: number) => {
  window.flutter_inappwebview?.callHandler('vibration', n);
};

export const loadingFinish = () => {
  window.flutter_inappwebview?.callHandler('loadingFinish');
};
