<template>
  <Row
    v-if="needShow"
    :label="label"
    :value="value"
    data-test="suggest-details product-on-shelf row"
  />
</template>

<script setup lang="ts">
import BaseOrder, { OrderStageEnum, OrderTypeEnum } from '@/models/orders/BaseOrder';
import Product from '@/models/Product';
import Suggest, { SuggestTypeEnum, SuggestVarsModeEnum } from '@/models/Suggest';
import { useProducts } from '@/store/modules/products';
import { AvailableProduct } from '@/types/product';
import { getCountToView } from '@/ui/common/suggest-card/formatters/count-formatter';
import Row from '@/ui/common/suggest-details/row.vue';
import { Model } from '@/ui/common/suggest-details/types';
import { computed, onMounted } from 'vue';
import { $gettext } from '@/temp/plugins/gettext';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';

interface ProductOnShelfRowProps {
  model: Model;
  order: BaseOrder;
  suggest: Suggest;
  product?: Product;
}
const props = defineProps<ProductOnShelfRowProps>();
const productsStore = useProducts();
const usersStore = useUser();

const expHiddenCount = computed<boolean>(() => usersStore.experimentByName(experiments.exp_hidden_count));

const isControl = computed<boolean>(() =>
  [OrderTypeEnum.writeoff_prepare_day, OrderTypeEnum.check_valid_regular, OrderTypeEnum.check_valid_short].includes(props.order.type),
);

const isStowage = computed<boolean>(() => props.order.type === OrderTypeEnum.stowage || props.order.type === OrderTypeEnum.sale_stowage);

const available = computed<AvailableProduct[]>(() =>
  productsStore.availableByProductId(props.suggest.product_id) || [],
);

const needShow = computed<boolean>(() => {
  if (
    props.order.type === OrderTypeEnum.stowage
    && props.suggest.vars.mode === SuggestVarsModeEnum.product
    && props.suggest.type === SuggestTypeEnum.box2shelf
  ) {
    return true;
  }

  if (
    props.order.type === OrderTypeEnum.sale_stowage
    && props.suggest.vars.mode === SuggestVarsModeEnum.product
    && props.suggest.type === SuggestTypeEnum.box2shelf
    && props.order.vars.stage === OrderStageEnum.stowage
  ) {
    return true;
  }

  return isControl.value && props.suggest.type === SuggestTypeEnum.shelf2box;
});

const label = computed<string>(() => {
  if (expHiddenCount.value && isStowage.value) return $gettext('Уже есть на этой полке');
  return $gettext('На полке');
});

const value = computed<string>(() => {
  let productOnShelf;
  if (props.model.shelf) {
    productOnShelf = available.value.find(({ shelf_id }) => shelf_id === props.model.shelf!.shelf_id);
  } else if (available.value) {
    productOnShelf = available.value.find(({ shelf_id }) => shelf_id === props.suggest.shelf_id);
  }

  if (expHiddenCount.value && isStowage.value) {
    return productOnShelf ? $gettext('Да') : $gettext('Нет');
  }

  if (productOnShelf) {
    let count = productOnShelf.count;
    if (isControl.value) {
      count = count - props.model.count!;
    }
    return getCountToView({
      count,
      type_accounting: props.product?.type_accounting,
    });
  }
  return '—';
});

onMounted(async () => {
  if (needShow.value && props.product) {
    await productsStore.fetchAvailable(props.product?.product_id);
  }
});

</script>
