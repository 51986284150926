import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import BaseOrder from '@/models/orders/BaseOrder';
import { AudioService } from '@/services/audio.service';
import { useOrders } from '@/store/modules/orders';
import { $gettext } from '@/temp/plugins/gettext';
import {
  computed, onMounted, ref, watch,
} from 'vue';
import { useRouter } from 'vue-router';

type CancelHandler<O> = (order: O | undefined) => void;

interface Handlers<O> {
  cancel?: CancelHandler<O>;
}

export const useHandleOrderStatus = <T extends BaseOrder>(order_id: T['order_id'], handlers?: Handlers<T>) => {
  const router = useRouter();
  const cancelHandler = ref<CancelHandler<T> | undefined>(handlers?.cancel);

  const order = computed<T | undefined>(() => {
    return useOrders().orderById(order_id) as T | undefined;
  });

  const target = computed<string | undefined>(() => {
    return order.value?.target;
  });

  const status = computed<string | undefined>(() => {
    return order.value?.status;
  });

  const cancelTitle = computed<string>(() => {
    const titles = {
      default: $gettext('Задание было отменено'),
      order: $gettext('Клиент отменил заказ'),
      order_retail: $gettext('Заказ %{number} отменён', {
        number: order.value ? order.value.orderNumberForView : '',
      }),
      refund: $gettext('Задание было отменено'),
      part_refund: $gettext('Задание было отменено'),
      hand_move: $gettext('Задание было отменено'),
      kitchen_provision: $gettext('Задание было отменено'),
      robot_provision: $gettext('Задание было отменено'),
      acceptance: $gettext('Задание было отменено'),
      weight_stowage: $gettext('Задание было отменено'),
      sale_stowage: $gettext('Задание было отменено'),
      repacking: $gettext('Задание было отменено'),
      check_valid_short: $gettext('Задание было отменено'),
      writeoff_prepare_day: $gettext('Задание было отменено'),
      check_valid_regular: $gettext('Задание было отменено'),
      check_more: $gettext('Задание было отменено'),
      inventory_check_more: $gettext('Задание было отменено'),
      writeoff: $gettext('Задание было отменено'),
      shipment: $gettext('Задание было отменено'),
      shipment_rollback: $gettext('Задание было отменено'),
      visual_control: $gettext('Задание было отменено'),
      check: $gettext('Задание было отменено'),
      check_final: $gettext('Задание было отменено'),
      inventory_check_product_on_shelf: $gettext('Задание было отменено'),
      control_check: $gettext('Задание было отменено'),
    };
    if (order.value) {
      return titles[order.value.type];
    }
    return titles.default;
  });

  const cancelText = computed<string>(() => {
    const titles = {
      default: $gettext('Пожалуйста, выполните все незавершенные действия и дождитесь завершения задания'),
      order: $gettext('Пожалуйста, разложите все собранные товары обратно'),
      order_retail: $gettext('Верните все собранные товары обратно на полки'),
      refund: $gettext('Пожалуйста, соберите все разложенные товары обратно'),
      part_refund: $gettext('Пожалуйста, соберите все разложенные товары обратно'),
      hand_move: $gettext('Пожалуйста, соберите все разложенные товары обратно'),
      kitchen_provision: $gettext('Пожалуйста, соберите все разложенные товары обратно'),
      robot_provision: $gettext('Пожалуйста, соберите все разложенные товары обратно'),
      repacking: $gettext('Пожалуйста, соберите все разложенные товары обратно'),
      writeoff: $gettext('Пожалуйста, соберите все разложенные товары обратно'),
      shipment: $gettext('Пожалуйста, соберите все отгруженные товары обратно'),
      shipment_rollback: $gettext('Пожалуйста, верните все товары обратно'),
    };
    if (order.value) {
      return titles[order.value.type];
    }
    return titles.default;
  });

  const handleOrderStatusCancel = async (newValue, oldValue) => {
    //  если ранее не было экземпляра ордера, то нет смысла обрабатывать изменения, так как их нет)
    if (!oldValue) {
      return;
    }
    //  документ пропал у пользователя, то есть он достиг целевого статуса
    if (!newValue) {
      //  документ был отменен
      if (oldValue === 'canceled') {
        Alerts.success($gettext('Задание успешно отменено'));
      }

      await router.push({ name: 'home' });
      return;
    }
    // документ перешел из обычного в флоу отмены
    if (newValue === 'canceled' && oldValue === 'complete') {
      AudioService.playAlert();
      await Modal.show({
        title: cancelTitle.value,
        text: cancelText.value,
      });

      if (cancelHandler.value) {
        cancelHandler.value(order.value);
      }
    }
  };

  const handleOrderStatusComplete = async (newValue, oldValue) => {
    //  если ранее не было экземпляра ордера, то нет смысла обрабатывать изменения, так как их нет)
    if (!oldValue) {
      return;
    }
    // документ шел к завершению, а сейчас пропал у пользователя. это может означать, что
    if (!newValue) {
      if (oldValue === 'complete') {
        Alerts.success($gettext('Задание завершено'));
      }

      await router.push({ name: 'home' });
      return;
    }
  };

  onMounted(() => {
    watch(target, handleOrderStatusCancel);
    watch(status, handleOrderStatusComplete);
  });
};
