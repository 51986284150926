<template>
  <RoundedCard24 class="flex flex-col">
    <IconError class="mx-2 mt-2" />
    <Title4 class="font-medium mx-4 my-3">
      {{ $gettext('Не удалось загрузить обновление') }}
    </Title4>
    <Body2 class="mx-4">
      {{ $gettext('Попробуйте ещё раз') }}
    </Body2>
    <RoundedCard16
      bg="bg-semantic-bg-minor"
      class="mx-3 px-4 py-2 mb-2 mt-4"
    >
      <Body2 class="font-medium">
        {{ $gettext('Что могло случиться?') }}
      </Body2>
      <InfoWithLabelVertical
        :label="$gettext('Нет соединения')"
        :value="$gettext('Проверьте, что вы рядом с точкой доступа Wi-Fi')"
      />
    </RoundedCard16>
    <div class="m-2 flex gap-2">
      <UiButton
        background-color="secondary"
        @click="emits('close')"
      >
        {{ $gettext('Позже') }}
      </UiButton>
      <UiButton
        background-color="primary"
        @click="emits('load')"
      >
        {{ $gettext('Загрузить заново') }}
      </UiButton>
    </div>
  </RoundedCard24>
</template>
<script setup lang="ts">
import IconError from '@/fsd/shared/icons/error/icon-error.vue';
import RoundedCard16 from '@/fsd/shared/ui/cards/RoundedCard16.vue';
import RoundedCard24 from '@/fsd/shared/ui/cards/RoundedCard24.vue';
import InfoWithLabelVertical from '@/fsd/shared/ui/output/InfoWithLabelVertical.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { defineEmits } from 'vue';

const emits = defineEmits<{
  (e: 'close'): void;
  (e: 'load'): void;
}>();
</script>
