import IconCourierHuman from '@/fsd/shared/icons/courier/icon-courier-human.vue';
import IconCourierRover from '@/fsd/shared/icons/courier/icon-courier-rover.vue';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import { SuggestStatusEnum } from '@/models/Suggest';
import { OrderWorkStatusEnum } from '@/models/orders/BaseOrder';
import OrderOrder, { isOrderOrder } from '@/models/orders/OrderOrder';
import { useOrders } from '@/store/modules/orders';
import { confirmNotifyId, modalNotifyId } from '@/temp/constants/common';
import { $gettext } from '@/temp/plugins/gettext';
import { notify } from '@kyvg/vue3-notification';
import { storeToRefs } from 'pinia';
import {
  computed, markRaw, watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

export const useCheckAssignCourier = () => {
  const ordersStore = useOrders();
  const router = useRouter();
  const route = useRoute();

  // нас интересуют только обычные клиентские заказы
  const { orderOrders } = storeToRefs(ordersStore);
  const orders = computed(() => {
    return orderOrders.value.filter<OrderOrder>(isOrderOrder).filter(o => o.status === OrderWorkStatusEnum.processing);
  });
  // смотрим, есть ли документ для которого назначли курьера
  const hasAssignedCouriers = computed(() => {
    return orders.value.some(o => o.attr.maybe_rover && o.courier);
  });
  // на страничке ордеров не показываем никаких модалок.
  const isOrderPage = computed(() => {
    return route.name === 'order';
  });
  // на главной показываем без возможности отказаться.
  const isHomePage = computed(() => {
    return route.name === 'home';
  });

  const openOrder = (o: OrderOrder) => {
    notify.close(modalNotifyId);
    notify.close(confirmNotifyId);
    router.push({
      name: 'order',
      params: { order_id: o.order_id },
    });
  };
  const showAssignCourierConfirm = async (o: OrderOrder) => {
    if (!o.courier) return;
    // полка точно есть, проверили выше

    // если мы на главной, то у пользователя нет выбора )
    const title = o.isRover
      ? $gettext('Заказ %{doc_number} доставит ровер', { doc_number: o.orderNumberForView })
      : $gettext('Заказ %{doc_number} доставит курьер', { doc_number: o.orderNumberForView });
    const text = $gettext('Продолжите работу с заказом');
    const icon = o.isRover ? markRaw(IconCourierRover) : markRaw(IconCourierHuman);

    if (isHomePage.value && !o.isRover) {
      await Modal.show({
        title,
        text,
        confirmBtnTitle: $gettext('Хорошо'),
        component: icon,
      });
      openOrder(o);
      return;
    }

    const confirmed = await Modal.show({
      title,
      text,
      btnPosition: ButtonPositionsEnum.horizontal,
      confirmBtnTitle: $gettext('К заказу'),
      closeBtnTitle: $gettext('Позже'),
      component: icon,
    });
    if (!confirmed) return;
    openOrder(o);
  };

  const checkAssignCourier = () => {
    // ищем нужный ордер. это должен быть ордер со всеми выполненными саджестами и назначенным курьером.
    const order = orders.value.find((o) => {
      const isDone = o.suggests.length && o.suggests.every(s => s.status === SuggestStatusEnum.done || s.isPackaging);
      return o.attr.maybe_rover && o.courier && isDone;
    });
    if (!order) return;
    if (order.status !== OrderWorkStatusEnum.processing) return;
    // если мы уже в ордере, то модалка не нужна
    if (isOrderPage.value) return;
    showAssignCourierConfirm(order);
  };

  // в реалтайме мониторим статусы готовой еды.
  watch(hasAssignedCouriers, (value) => {
    if (!value) return;
    checkAssignCourier();
  });

  // при переходе на главную чекаем, есть ли отложенные документы.
  watch(isHomePage, (value) => {
    if (!value) return;
    checkAssignCourier();
  });
};
