import { Found } from '@/services/response';
import { BaseModel } from 'sjs-base-model';

export default class ShelfByBarcode extends BaseModel {
  public type: string = 'shelf';
  public shelf_id: string = '';
  public title: string = '';

  constructor(data: any) {
    super({ expand: true });
    this.update(data);
  }

  static isShelfByBarcode(found: Found): found is ShelfByBarcode {
    return found.type === 'shelf';
  }
}
