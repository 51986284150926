<template>
  <Layout>
    <template #header>
      <ConfirmListHeader :order="order" />
    </template>
    <template #default>
      <Tabs :default-active-tab-key="defaultTabKey">
        <Tab
          tab_key="no"
          :title="`${$gettext('Не собрано')} · 0`"
        >
          <EmptyScreen :height="96" />
        </Tab>
        <Tab
          tab_key="q"
          :title="`${$gettext('Согласовать')} · ${needConfirmSuggests.length}`"
        >
          <EmptyScreen
            v-if="!needConfirmSuggests.length"
            :height="96"
          />
          <SuggestsByGroup
            :suggests="needConfirmSuggests"
            :confirm-suggests="confirmSuggests"
            :need-confirm="true"
            @open-details="openDetails"
            @remove-suggest="sug => $emit('removeSuggest', sug)"
            @open-suggest-menu="sug => $emit('openSuggestMenu', sug)"
            @change-product="sug => $emit('changeProduct', sug.suggest_id)"
            @confirm-suggest="confirmSuggest"
          />
        </Tab>
        <Tab
          tab_key="yes"
          :title="`${$gettext('Собрано')} · ${packedSuggests.length}`"
        >
          <EmptyScreen
            v-if="!packedSuggests.length"
            :height="96"
          />
          <SuggestsByGroup
            :suggests="packedSuggests"
            @open-details="openDetails"
            @remove-suggest="sug => $emit('removeSuggest', sug)"
            @open-suggest-menu="sug => $emit('openSuggestMenu', sug)"
            @change-product="sug => $emit('changeProduct', sug.suggest_id)"
          />
        </Tab>
      </Tabs>
    </template>
    <template #footer>
      <ConfirmListFooter
        :order="order"
        :confirmed="allConfirmed"
        @finish="checkPayment"
      />
    </template>
  </Layout>
</template>

<script setup lang="ts">
import { useConfirmAssembledProducts } from '@/fsd/data/order/useConfirmAssembledProducts';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { EmptyScreen } from '@/fsd/shared/ui/emptyScreen';
import { Tab, Tabs } from '@/fsd/shared/ui/tabs';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Product from '@/models/Product';
import Suggest from '@/models/Suggest';
import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';
import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import { useProducts } from '@/store/modules/products';
import { $gettext } from '@/temp/plugins/gettext';
import Layout from '@/ui/common/layout.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import {
  computed, onMounted, ref, watch,
} from 'vue';
import { MoreProductSuggestProps } from '../../types';
import SuggestsByGroup from '../SuggestCard/SuggestsByGroup.vue';
import ConfirmListFooter from './ConfirmListFooter.vue';
import ConfirmListHeader from './ConfirmListHeader.vue';

const productsStore = useProducts();
const { showLoader } = useLoader();
const { confirmAssembledProducts } = useConfirmAssembledProducts();

interface OrderRetailConfirmProps {
  order: OrderOrderRetail;
}

const props = defineProps<OrderRetailConfirmProps>();

const emits = defineEmits<{
  (e: 'openDetails', suggest_id: Suggest['suggest_id']): void;
  (e: 'removeSuggest', suggest: Suggest | TrueMarkSuggestWrapper): void;
  (e: 'changeSuggest', suggest: Suggest | TrueMarkSuggestWrapper): void;
  (e: 'openSuggestMenu', suggest: Suggest | TrueMarkSuggestWrapper): void;
  (e: 'openMoreProduct', data: MoreProductSuggestProps): void;
  (e: 'changeProduct', suggest_id: Suggest['suggest_id']): void;
  (e: 'toFinish'): void;
}>();

const confirmSuggests = ref<Record<Suggest['suggest_id'], boolean>>({});

const suggests = computed<Suggest[]>(() => {
  return props.order.suggests;
});

const needConfirmSuggests = computed<Suggest[]>(() => props.order.needConfirmSuggests);

const packedSuggests = computed<Suggest[]>(() => props.order.packedSuggests);

const defaultTabKey = computed<string>(() => {
  if (needConfirmSuggests.value.length) return 'q';
  return 'yes';
});

const confirmSuggest = (suggest: Suggest) => {
  confirmSuggests.value[suggest.suggest_id] = true;
};

onMounted(() => {
  needConfirmSuggests.value.forEach((suggest) => {
    if (!confirmSuggests.value[suggest.suggest_id]) {
      confirmSuggests.value[suggest.suggest_id] = !!suggest.vars.more_product;
    }
  });
});

watch(needConfirmSuggests, (newSuggest) => {
  newSuggest.forEach((suggest) => {
    if (!confirmSuggests.value[suggest.suggest_id]) {
      confirmSuggests.value[suggest.suggest_id] = !!suggest.vars.more_product;
    }
  });
});

const allConfirmed = computed<boolean>(() => {
  let result: boolean = true;
  Object.values(confirmSuggests.value).forEach((value) => {
    if (!value) result = false;
  });

  return result;
});

const { needBarcodeRequest } = useRequestBarcode(async (barcode) => {
  const { closeLoader } = showLoader();
  try {
    const product = await productsStore.getProductByBarcode(barcode);
    const suggest = suggests.value.find(s => s.product_id === product.product_id);
    if (!suggest || suggest.conditions.need_true_mark) {
      await openMoreProduct(product, barcode);
      return false;
    }
    if (suggest.isDone) {
      Alerts.error($gettext('Данный продукт уже собран'));
      return true;
    }
    return true;
  } catch {
    Alerts.error($gettext('Отсканирован неверный баркод'));
    return true;
  } finally {
    closeLoader();
  }
});

const openMoreProduct = async (product: Product, barcode: string) => {
  needBarcodeRequest.value = false;
  emits('openMoreProduct', {
    product_id: product.product_id,
    barcode,
  });
  needBarcodeRequest.value = true;
};

const openDetails = async (suggest: Suggest | TrueMarkSuggestWrapper) => {
  needBarcodeRequest.value = false;
  emits('openDetails', suggest.suggest_id);
  needBarcodeRequest.value = true;
};

const checkPayment = () => {
  if (props.order.forFinishPacking.length) {
    confirmAssembledProducts(props.order);
  } else {
    emits('toFinish');
  }
};
</script>
