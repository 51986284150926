export type ChipContent = {
  text: string;
  icon?: IconNameEnum;
  appendIcon?: AppendIconNameEnum;
};
export const enum IconNameEnum {
  completed = 'completed',
  warning = 'warning',
}
export const enum AppendIconNameEnum {
  robot = 'robot',
}
