<template>
  <Bar
    :menu-config="suggestMenuConfig"
    :icons="{ left: 'close', right: 'menu' }"
    @close-click="toHomePage"
  />
</template>

<script lang="ts" setup>
import { $gettext } from '@/temp/plugins/gettext';
import Bar from '@/ui/common/bar/bar.vue';
import { MenuItemConfig } from '@/ui/common/menu/types';
import { StepEnum } from '@/views/ProductWriteoff/types';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const props = defineProps<{
  currentStep: StepEnum;
  hasTail: boolean;
}>();
const emits = defineEmits<{
  (e: 'writeoff-all'): void;
  (e: 'writeoff-tail'): void;
}>();

const suggestMenuConfig = computed(() => {
  const menuConfig: MenuItemConfig[] = [];

  const writeoffAllBtn: MenuItemConfig = {
    buttonText: $gettext('Списать все'),
    dataTest: 'writeoff menu writeoff-all',
    onClick: () => emits('writeoff-all'),
    condition: () => props.currentStep === StepEnum.count,
  };
  menuConfig.push(writeoffAllBtn);

  const writeoffTailBtn: MenuItemConfig = {
    buttonText: $gettext('Списать открытую упаковку'),
    dataTest: 'writeoff menu writeoff-tail',
    onClick: () => emits('writeoff-tail'),
    condition: () => Boolean(props.currentStep === StepEnum.count && props.hasTail),
  };
  menuConfig.push(writeoffTailBtn);

  return menuConfig;
});

const toHomePage = () => {
  router.push({ name: 'home' });
};
</script>
