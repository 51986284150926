// предполагаю следующую логику:
// описываем ф-ю, что по переданному саджесту умеет генерировать набор шагов.
// часть сценариев можно описать универсально, но должна быть возможность для кастомизации
// шаги с типом type:'required' обязательны для выполнения,
// шаги с типом type:'additional' не обязательны и доступны через 3 точки

// Нет и не будет кондишена для ввода полки, можно добавить только кастомизацией

import Suggest, { SuggestStatusEnum, SuggestTypeEnum } from '@/models/Suggest';
import { OrderTypeEnum } from '@/models/orders/BaseOrder';
import SaleStowageOrder from '@/models/orders/SaleStowageOrder';
import { useOrders } from '@/store/modules/orders';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { Model } from '@/ui/common/suggest-details/types';
import { ShelfTypeEnum } from '../Shelf';

export interface Step {
  name:
    | 'count'
    | 'date'
    | 'reason'
    | 'shelf'
    | 'finish'
    | 'weight'
    | 'edit-confirm'
    | 'multi-product-counter'
    | 'select-package-class';
  type: 'required' | 'additional';
  condition?: (suggest: Suggest, models: Model) => boolean;
}

export const getSteps = (suggest: Suggest): Step[] => {
  const result: Step[] = [];

  const order = useOrders().orderById(suggest.order_id);

  if (!order) {
    return [];
  }

  switch (order.type) {
    case OrderTypeEnum.sale_stowage:
      result.push(...getSaleStowageSteps(suggest));
      break;
    case OrderTypeEnum.weight_stowage:
      result.push(...getWeightStowageSteps(suggest));
      break;
    case OrderTypeEnum.writeoff_prepare_day:
    case OrderTypeEnum.check_valid_short:
    case OrderTypeEnum.check_valid_regular:
      result.push(...getControlSteps(suggest));
      break;
    case OrderTypeEnum.visual_control:
      result.push(...getVisualControlSteps(suggest));
      break;
    case OrderTypeEnum.hand_move:
    case OrderTypeEnum.kitchen_provision:
    case OrderTypeEnum.robot_provision:
      result.push(...getHandMoveSteps(suggest));
      break;
    case OrderTypeEnum.shipment_rollback:
      result.push(...getShipmentRollbackSteps(suggest));
      break;
    case OrderTypeEnum.repacking:
      result.push(...getRepackingSteps(suggest));
      break;
    case OrderTypeEnum.refund:
    case OrderTypeEnum.part_refund:
      result.push(...getRefundSteps(suggest));
      break;
    case OrderTypeEnum.stowage_market:
      result.push(...getStowageMarketSteps());
      break;
    default:
      result.push(...getDefaultSteps(suggest));
      break;
  }
  // если шагов нет, то просто показываем кнопку завершить
  if (result.length === 0) {
    result.push({
      name: 'finish',
      type: 'required',
    });
  }
  return result;
};

const getSaleStowageSteps = (suggest: Suggest): Step[] => {
  const result: Step[] = [];

  // Ввод кол-ва или веса.
  if (suggest.conditions.need_weight) {
    result.push({
      name: 'weight',
      type: 'required',
    });
  } else {
    const order = useOrders().orderById(suggest.order_id) as SaleStowageOrder;

    result.push({
      name: 'count',
      type: 'required',
      // поле обязательно если это дов размещение или этап списания или мы размещаем дочерний продукт(в саджесте родительский продукт, например, просто арбуз,
      // а размещаем мы конкретную группу, например арбуз от 6 до 9 кг)
      // @ts-expect-error pinia
      condition: suggest =>
        (suggest.conditions.all && order.conditions.is_agree)
        || suggest.vars.stage === 'trash'
        || suggest.product?.children_id?.length,
    });
  }
  // ввод СГ
  if (suggest.conditions.need_valid) {
    result.push({
      name: 'date',
      type: 'required',
      condition: (suggest) => {
        return useUser().experimentByName('exp_baden_baden') || !suggest.valid;
      },
    });
  }

  // Полка обычно последний шаг,  но если сканим полку списания, то нужно спросить причину
  if (suggest.vars.stage === 'trash') {
    result.push({
      name: 'reason',
      type: 'required',
    });
    result.push({
      name: 'shelf',
      type: 'required',
    });
  } else {
    result.push({
      name: 'shelf',
      type: 'required',
    });
    result.push({
      name: 'reason',
      type: 'required',
      condition: (s, models) => models.shelf?.type === ShelfTypeEnum.trash || models.shelf?.type === ShelfTypeEnum.review,
    });
  }
  result.push({
    name: 'finish',
    type: 'required',
  });
  return result;
};
const getStowageMarketSteps = (): Step[] => {
  const result: Step[] = [];

  result.push({
    name: 'shelf',
    type: 'required',
  });
  result.push({
    name: 'reason',
    type: 'required',
    condition: (s, models) => models.shelf?.type === ShelfTypeEnum.trash,
  });

  result.push({
    name: 'select-package-class',
    type: 'required',
    condition: (s, models) =>
      useUser().experimentByName(experiments.exp_package_class)
      && models.shelf?.type !== ShelfTypeEnum.parcel_returned
      && !s.item?.hasImg,
  });

  result.push({
    name: 'finish',
    type: 'required',
    condition: (s, models) =>
      !useUser().experimentByName(experiments.exp_package_class)
      || models.shelf?.type === ShelfTypeEnum.parcel_returned
      || Boolean(s.item?.hasImg),
  });
  return result;
};

const getWeightStowageSteps = (suggest: Suggest): Step[] => {
  const result: Step[] = [];

  if (suggest.type === SuggestTypeEnum.box2shelf) {
    if (suggest.conditions.need_valid) {
      result.push({
        name: 'date',
        type: 'required',
      });
    }
    result.push({
      name: 'shelf',
      type: 'required',
    }, {
      name: 'finish',
      type: 'required',
    });
    return result;
  }

  return getDefaultSteps(suggest);
};

const getRefundSteps = (suggest: Suggest): Step[] => {
  const result: Step[] = [];

  result.push(...getDefaultSteps(suggest));
  // если это возврат, то можно не сканить полку
  if (suggest.vars.stage === 'canceling') {
    return result;
  }
  result.push({
    name: 'shelf',
    type: 'required',
  });

  return result;
};

const getControlSteps = (suggest: Suggest): Step[] => {
  const result: Step[] = [];
  if (suggest.status === SuggestStatusEnum.done) {
    result.push({
      name: 'edit-confirm',
      type: 'required',
    });
  }

  result.push({
    name: 'count',
    type: 'required',
  });
  // бек присылает кривые кондишены, поэтому захардкожено https://st.yandex-team.ru/LAVKADEV-7296

  result.push({
    name: 'date',
    type: 'required',
  });

  return result;
};

const getVisualControlSteps = (suggest: Suggest): Step[] => {
  const result: Step[] = [];

  if (suggest.type === SuggestTypeEnum.shelf2box) {
    result.push({
      name: 'multi-product-counter',
      type: 'required',
    });
  } else {
    result.push({
      name: 'finish',
      type: 'required',
    });
  }
  return result;
};

const getHandMoveSteps = (suggest: Suggest): Step[] => {
  const result: Step[] = [];

  if (suggest.isProductSuggest && suggest.conditions.all) {
    result.push({
      name: 'count',
      type: 'required',
    });
  }

  result.push({
    name: 'shelf',
    type: 'required',
  });
  result.push({
    name: 'finish',
    type: 'required',
  });

  return result;
};

const getRepackingSteps = (suggest: Suggest): Step[] => {
  const result: Step[] = [];

  if (suggest.type === SuggestTypeEnum.shelf2box) {
    result.push({
      name: 'count',
      type: 'required',
    });
  }
  if (suggest.type === SuggestTypeEnum.box2shelf) {
    result.push({
      name: 'shelf',
      type: 'required',
    });
  }

  return result;
};

const getShipmentRollbackSteps = (suggest: Suggest): Step[] => {
  if (suggest.isItemSuggest) {
    return [{
      name: 'shelf',
      type: 'required',
    }];
  }

  if (suggest.type === SuggestTypeEnum.shelf2box) {
    return [{
      name: 'count',
      type: 'required',
    }];
  }

  if (suggest.vars.stage === 'trash') {
    return [
      {
        name: 'reason',
        type: 'required',
      },
      {
        name: 'count',
        type: 'required',
      },
      {
        name: 'shelf',
        type: 'required',
      },
    ];
  }
  return [
    {
      name: 'count',
      type: 'required',
    },
    {
      name: 'shelf',
      type: 'required',
    },
  ];
};

const getDefaultSteps = (suggest: Suggest): Step[] => {
  // в идеале это единственно необходимый метод, нов кондишенах творится разброд и анархия, поэтому приходится кастомизировать
  const result: Step[] = [];
  if (suggest.conditions.need_weight) {
    result.push({
      name: 'weight',
      type: 'required',
    });
  }
  if (suggest.conditions.all && !suggest.conditions.need_weight && !suggest.isItemSuggest) {
    result.push({
      name: 'count',
      type: 'required',
    });
  }
  if (suggest.conditions.need_valid) {
    result.push({
      name: 'date',
      type: 'required',
    });
  }
  if (suggest.conditions.trash_reason) {
    result.push({
      name: 'reason',
      type: 'required',
    });
  }
  return result;
};

export class StepManager {
  public steps: Step[] = [];
  public activeStepNumber: number;
  public stepsStatuses: {
    [key: string]: boolean;
  };

  public order_id: string;
  public suggest_id: string;
  // в случае с виртуальным саджестом его нет в хранилище
  public _suggest: Suggest | undefined = undefined;

  constructor(order_id: string, suggest_id: string, suggest?: Suggest) {
    this.order_id = order_id;
    this._suggest = suggest;
    this.suggest_id = suggest_id;
    this.steps = getSteps(this.suggest);
    this.stepsStatuses = this.steps.reduce((acc, cur) => {
      acc[cur.name] = false;
      return acc;
    }, {});
    this.activeStepNumber = this.findNextStep({});
  }

  findNextStep(models): number {
    return this.steps.findIndex((s, index) => {
      return (
        index !== this.activeStepNumber
        && this.stepsStatuses[s.name] === false
        && s.type === 'required'
        && (!s.condition || s.condition(this.suggest, models))
      );
    });
  }

  hasNextStep(models) {
    return this.findNextStep(models) !== -1;
  }

  setActiveStep(step: string) {
    const idx = this.steps.findIndex((item) => {
      return item.name === step;
    });
    if (~idx) {
      this.activeStepNumber = idx;
    }
  }

  nextStep(models): void {
    this.stepsStatuses[this.activeStep.name] = true;

    const nextStepNumber = this.findNextStep(models);
    if (nextStepNumber !== -1) {
      this.changeStep(this.activeStepNumber, nextStepNumber);
    }
  }

  changeStep(from, to): void {
    // у нас может быть очень сложная логика переключения шагов, что невозможно описать в общем случае (кондишены, тип шага)
    // данная ф-я отлично подходит для случаев:
    // "это раскладка со специфичным флагом, сегодня четверг и полнолуние то переключаем шаги особым образом"
    this.activeStepNumber = to;
  }

  get activeStep() {
    return this.steps[this.activeStepNumber];
  }

  get suggest(): Suggest {
    if (this._suggest) return this._suggest;
    const order = useOrders().orderById(this.order_id);
    return order?.suggestById(this.suggest_id)!;
  }
}
