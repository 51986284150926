<template>
  <PageLayout :order_id="order_id">
    <template #header>
      <Bar
        :order="order"
        :icons="{ left: 'back' }"
        :caption="$gettext('Отмена приёмки')"
        :text="barText"
        data-test="suggest true-mark bar"
        @close-click="toHomePage"
      />
    </template>
    <div class="relative bg-element">
      <div class="bg-white rounded-b-3xl px-4 pb-4">
        <Title2 class="font-bold inline-block py-[10.5px]">
          {{ $gettext('Верните все товары из поставки обратно поставщику') }}
        </Title2>
        <Body2 class="inline-block">
          {{ $gettext('Отсканируйте Честный знак, чтобы проверить, был ли товар в этой приёмке или нет') }}
        </Body2>
      </div>
      <div class="bg-white py-4 mt-1">
        <RequiredCard
          v-for="product in required"
          :key="product.product_id + product.shelf_id"
          :required-item="product"
          :order="order"
        />
      </div>
    </div>

    <template #footer>
      <LayoutFooter class="flex gap-2 !p-2">
        <UiButton
          background-color="secondary"
          data-test="btn rollback"
          @click="goToAcceptance"
        >
          {{ $gettext('К приёмке') }}
        </UiButton>
        <UiButton
          data-test="btn continue"
          @click="finishOrder"
        >
          {{ $gettext('Готово') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </PageLayout>
</template>

<script setup lang="ts">
import { api } from '@/fsd/data/api/api.service';
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { isTrueMark } from '@/fsd/shared/tools/unpackTrueMark';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Product from '@/models/Product';
import ProductByBarcode from '@/models/ProductByBarcode';
import { OrderTargetEnum } from '@/models/orders/BaseOrder';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useSuggests } from '@/store/modules/suggests';
import { orderTypeUrls } from '@/temp/constants';
import { $gettext } from '@/temp/plugins/gettext';
import { logger } from '@/temp/plugins/logs';
import { Notifications } from '@/temp/plugins/notification';
import { isProductInRequired, ProductInRequired } from '@/types/product';
import Bar from '@/ui/common/bar/bar.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import Body2 from '@/ui/common/typo/body-2.vue';
import Title2 from '@/ui/common/typo/title-2.vue';
import UiButton from '@/ui/common/ui-button.vue';
import RequiredCard from '@/ui/view-order/required-card.vue';
import { computed, onBeforeUnmount, watch, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import { SignalEnum } from '@/services/requests';

const props = defineProps<{ order_id: string }>();

const router = useRouter();
const ordersStore = useOrders();
const suggestsStore = useSuggests();
const productsStore = useProducts();

const { showLoader } = useLoader();

// todo обработка, что ордер удалили
const order = computed(() => ordersStore.orderById(props.order_id));

const suggestsTrueMarks = new Set();
const productsIds = new Set();

watchEffect(() => {
  const suggests = suggestsStore.suggestsByOrderId(props.order_id)?.values() ?? [];
  suggestsTrueMarks.clear();
  productsIds.clear();

  for (const suggest of suggests) {
    if (suggest.vars.true_mark) {
      suggestsTrueMarks.add(suggest.vars.true_mark);
    }
    productsIds.add(suggest.product_id);
  }
});

const required = computed<ProductInRequired[]>(() => {
  if (order.value) {
    return order.value.required.filter(require => isProductInRequired(require)) as ProductInRequired[];
  }

  return [];
});

const barText = computed(() => {
  const number = order.value?.attr.doc_number ?? '-';
  const date = order.value?.date ?? '-';

  return $gettext('%{number} от %{date}', {
    number,
    date,
  });
});

const toHomePage = () => router.push({ name: 'home' });

const processFoundProduct = (product: Product | ProductByBarcode) => {
  if (product.true_mark) {
    Alerts.error($gettext('Сканируйте марку'));
    return true;
  }

  if (productsIds.has(product.product_id)) {
    Alerts.success($gettext('Этот товар был в приёмке'));
    return true;
  }

  return false;
};

const handlerBarcode = async (barcode: string) => {
  const isMark = isTrueMark(barcode);

  // Если это марка
  if (isMark) {
    // Если мы ее сканировали
    if (suggestsTrueMarks.has(barcode)) {
      Alerts.success($gettext('Этот товар был в приёмке'));
    } else {
      // TODO возможно, здесь придется ходить в check_true_mark
      Alerts.error($gettext('Этого товара не было в приёмке'));
    }
    return true;
  }

  const products = productsStore.getProductsByBarcodeLocal(barcode);
  // Если продукт есть в сторе, то проверим true_mark или был ли он в саджестах
  if (products.length) {
    processFoundProduct(products[0]);
    return true;
  }
  // Если в сторе не нашли, идем к бэку
  const { data } = await api.barcode({
    barcode,
    order_id: props.order_id,
  });
  const [product] = data.found.filter(ProductByBarcode.isProductByBarcode);

  const isFound = product && processFoundProduct(product);
  if (!isFound) {
    Alerts.error($gettext('Этого товара не было в приёмке'));
  }

  return true;
};

const { needBarcodeRequest } = useRequestBarcode(handlerBarcode);

const finishOrder = async (): Promise<void> => {
  const isFinishedConfirm = await Notifications.confirmBottom({
    title: $gettext('Вы уверены, что вернули все товары?'),
  });
  if (!isFinishedConfirm) return;
  needBarcodeRequest.value = false;
  const result = await useEndOrder(props.order_id);
  if (result) {
    await toHomePage();
  } else {
    needBarcodeRequest.value = true;
  }
};

const goToAcceptance = async () => {
  const { closeLoader } = showLoader($gettext('Отправляем запрос'), props.order_id);
  try {
    await ordersStore.signal({
      order_id: props.order_id,
      signal: SignalEnum.rollback_complete_acceptance,
    });
  } catch (e) {
    closeLoader();
    logger.error(e, {
      method: 'signal',
      type: 'api',
      source: 'AcceptanceRollback.vue',
    });
  }
};

watch(() => order.value?.target, (target) => {
  if (target && target === OrderTargetEnum.complete) {
    router.push({
      name: orderTypeUrls.acceptance,
      params: { order_id: props.order_id },
    });
  }
});

onBeforeUnmount(() => {
  needBarcodeRequest.value = false;
});
</script>
