<template>
  <svg
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m10 7.5 4.5 4.5-4.5 4.5"
      stroke="#21201F"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
