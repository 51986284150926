<template>
  <Layout>
    <template #header>
      <ResetSuggestsHeader
        :order="order"
        @close="$emit('back')"
      />
    </template>
    <template #default>
      <div class="bg-element flex flex-col gap-2">
        <div class="bg-background-main p-4 rounded-b-3xl flex flex-col gap-2">
          <Title2
            v-if="confirmingAssembled"
            class="font-bold"
          >
            {{ $gettext('Проверка возможности оплаты') }}
          </Title2>
          <template v-else>
            <Title2 class="font-bold">
              {{ $gettext('Выложите эти товары из заказа') }}
            </Title2>
            <Body2>{{ $gettext('Эти товары не надо собирать в заказ.') }}</Body2>
          </template>
        </div>
        <div class="bg-background-main p-4 rounded-t-3xl flex flex-col gap-4 h-full">
          <SuggestCard
            v-for="s in needResetSuggests"
            :key="s.suggest_id"
            :collected="s.result_count || 0"
            :suggest="s"
            :need-buttons="false"
            :need-right-buttons="false"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <ResetSuggestsFooter
        :suggest="suggest"
        :confirming="confirmingAssembled"
        @back="$emit('back')"
        @ready="toPackage"
      />
    </template>
  </Layout>
</template>

<script setup lang="ts">
import { useConfirmAssembledProducts } from '@/fsd/data/order/useConfirmAssembledProducts';
import { unMergeTrueMarkSuggests } from '@/fsd/entities/suggest/tools/mergeTrueMarkSuggests';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import Suggest from '@/models/Suggest';
import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import { $gettext } from '@/temp/plugins/gettext';
import Layout from '@/ui/common/layout.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import Title2 from '@/ui/common/typo/title-2.vue';
import { removeSuggestsList } from '@/views/OrderRetail/tools/removeSuggestsList';
import { computed } from 'vue';
import { getFullChildSuggests } from '../../tools/getChildSuggests';
import SuggestCard from '../SuggestCard/SuggestCard.vue';
import ResetSuggestsFooter from './ResetSuggestsFooter.vue';
import ResetSuggestsHeader from './ResetSuggestsHeader.vue';

const { confirmAssembledProducts } = useConfirmAssembledProducts();

interface ClearConfirmProps {
  order: OrderOrderRetail;
  suggest_id?: Suggest['suggest_id'];
}

const props = defineProps<ClearConfirmProps>();

const emits = defineEmits<{
  (e: 'back'): void;
  (e: 'toFinish'): void;
}>();

const suggest = computed(() => props.order.suggests.find(s => s.suggest_id === props.suggest_id));

const confirmingAssembled = computed<boolean>(
  () =>
    !!props.order.vars.confirming_assembled_products
    && typeof props.order.vars.fulfilled_conditions?.confirm_assembled_products !== 'boolean',
);

const needResetSuggests = computed<Suggest[]>(() => {
  if (suggest.value) {
    return getFullChildSuggests(suggest.value);
  }
  return props.order.needResetSuggests;
});

const hide = () => {
  if (suggest.value) {
    emits('back');
  } else if (props.order.forFinishPacking.length) {
    confirmAssembledProducts(props.order);
  } else {
    emits('toFinish');
  }
};

const toPackage = async () => {
  if (needResetSuggests.value.length) {
    let success = await Modal.show({
      title: $gettext('Все товары из списка выложены из заказа?'),
      btnPosition: ButtonPositionsEnum.horizontal,
      closeBtnTitle: $gettext('Отмена'),
      confirmBtnTitle: $gettext('Выложены'),
    });
    if (success) {
      success = await removeSuggestsList(unMergeTrueMarkSuggests(needResetSuggests.value));
      if (!success) return;
    }
    if (success) hide();
  } else {
    hide();
  }
};
</script>
