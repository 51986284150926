import { PlatformService } from '@/fsd/data/services/platform.service';
import { logger } from '@/temp/plugins/logs';
import { scanner } from '@lavka/capacitor-scanner-plagin';

export class NativeScanner {
  static promisify(action) {
    return new Promise((resolve, reject) => {
      // @ts-expect-error
      window.pickerscanner[action](
        0,
        (data) => {
          logger(`Успешный ${action}`);
          resolve(data);
        },
        () => {
          reject(`Ошибка в ${action}`);
        },
      );
    });
  }

  static getDeviceId(): Promise<any> {
    if (PlatformService.isCapacitor()) {
      return scanner.device_id();
    }
    return this.promisify('deviceId');
  }

  static start() {
    if (PlatformService.isCapacitor()) {
      return scanner.start();
    }
    return this.promisify('start');
  }

  static stop() {
    if (PlatformService.isCapacitor()) {
      return scanner.stop();
    }
    return this.promisify('stop');
  }

  static barcode() {
    if (PlatformService.isCapacitor()) {
      return scanner.barcode();
    }
    return this.promisify('barcode');
  }
}
