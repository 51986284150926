<template>
  <div
    class="item h-[100px]"
    :class="{ item_light: count === 0 }"
    :data-test="dataTest"
    @click="onClick"
  >
    <div
      class="image"
      :class="{ image_light: count === 0, 'is-rtl': isRTL }"
    >
      <img
        v-if="image === 'order'"
        src="@/ui/home/group-item/img/basket.svg"
        alt=""
      >
      <img
        v-if="image === 'acceptance'"
        src="@/ui/home/group-item/img/car.svg"
        alt=""
      >
      <img
        v-if="image === 'check_product_on_shelf'"
        src="@/ui/home/group-item/img/scan.svg"
        alt=""
      >
      <img
        v-if="image === 'stowage'"
        src="@/ui/home/group-item/img/shelves.svg"
        alt=""
      >
      <img
        v-if="image === 'writeoff'"
        src="@/ui/home/group-item/img/bin.svg"
        alt=""
      >
      <img
        v-if="image === 'control'"
        src="@/ui/home/group-item/img/calendar.svg"
        alt=""
      >
      <img
        v-if="image === 'inventarization'"
        src="@/ui/home/group-item/img/inventarization.svg"
        alt=""
      >
      <img
        v-if="image === 'refund'"
        src="@/ui/home/group-item/img/box.svg"
        alt=""
      >
      <img
        v-if="image === 'check'"
        src="@/ui/home/group-item/img/check.svg"
        alt=""
      >
    </div>
    <Typography
      class="name"
      type="text3"
      is-bold
      color="primary-text"
    >
      <slot />
    </Typography>
    <Typography
      type="h2"
      color="primary-text"
      class="count"
    >
      {{ count }}
    </Typography>
  </div>
</template>

<script lang="ts">
import { useUser } from '@/store/modules/user';
import Typography from '@/ui/common/typography.vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
export default defineComponent({
  components: { Typography },
  props: {
    count: {
      type: Number,
      default: 0,
    },
    image: {
      type: String,
      default: '',
    },
    dataTest: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      required: true,
    },
    isMonoOrder: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const userStore = useUser();
    const router = useRouter();

    return {
      userStore,
      router,
    };
  },
  computed: {
    isRTL() {
      return this.userStore.isRTL;
    },
  },
  methods: {
    onClick() {
      if (this.isMonoOrder) {
        this.router.push({ name: this.url });
      } else {
        this.router.push({
          name: 'list',
          params: { type: this.url },
        });
      }
    },
  },
});
</script>

<style scoped lang="scss">
.item {
  align-items: stretch;
  background: var(--element-bg);
  border-radius: 1rem;
  display: flex;
  flex-basis: calc(50% - 0.5rem);
  flex-direction: column;
  justify-content: stretch;
  margin: 0.25rem;
  min-height: 6.25rem;
  overflow: hidden;
  position: relative;
}

.name {
  flex: 1 1 auto;
  padding: 1rem 1rem 0.5rem;
  z-index: 1;
}

.count {
  padding: 0 1rem 1rem;
  z-index: 1;
}

.item_light .name {
  color: var(--text-secondary-color);
}

.item_light .count {
  color: var(--text-secondary-color);
}

.image {
  bottom: -1rem;
  position: absolute;
  right: -1rem;
  z-index: 0;
  &.is-rtl {
    left: -1rem;
    right: auto;
  }
  path {
    fill: var(--home-order_type-img);
  }
}

.image_light {
  opacity: 0.4;
}
</style>
