<template>
  <RoundedCard24 class="flex flex-col">
    <IconWarning
      class="mx-2 mt-2"
      bg-color="orange-normal"
    />
    <Title4 class="font-medium mx-4 my-3">
      {{ $gettext('Необходимо обновить приложение') }}
    </Title4>
    <Body2 class="mx-4">
      {{ $gettext('Это может занять несколько минут. В это время приложение будет недоступно') }}
    </Body2>
    <RoundedCard16
      bg="bg-semantic-bg-minor"
      class="mx-3 px-4 mb-2 mt-4"
    >
      <InfoWithLabelVertical
        :label="$gettext('Текущая версия приложения')"
        :value="appVersion"
      />
      <Divider />
      <InfoWithLabelVertical
        :label="$gettext('Новая версия приложения')"
        :value="LAST_APP_VERSION"
      />
    </RoundedCard16>
    <div class="m-2 flex gap-2">
      <UiButton
        background-color="secondary"
        @click="emits('close')"
      >
        Позже
      </UiButton>
      <UiButton
        background-color="primary"
        @click="emits('load')"
      >
        Обновить
      </UiButton>
    </div>
  </RoundedCard24>
</template>
<script setup lang="ts">
import IconWarning from '@/fsd/shared/icons/warning/icon-warning.vue';
import RoundedCard16 from '@/fsd/shared/ui/cards/RoundedCard16.vue';
import RoundedCard24 from '@/fsd/shared/ui/cards/RoundedCard24.vue';
import InfoWithLabelVertical from '@/fsd/shared/ui/output/InfoWithLabelVertical.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import UiButton from '@/ui/common/ui-button.vue';
import Divider from '@/fsd/shared/ui/divider/divider.vue';
import { LAST_APP_VERSION } from '@/fsd/features/tools/useUpdate/updatePickerApp/pickerAppVersion';
import { defineEmits, ref } from 'vue';
import { App } from '@capacitor/app';

const emits = defineEmits<{
  (e: 'close'): void;
  (e: 'load'): void;
}>();
const appVersion = ref('1.0.0');

App.getInfo()
  .then((info) => {
    appVersion.value = info.version;
  })
  .catch(console.error);

</script>
