import { productCacheLifeTime } from '@/temp/constants/common';

export const createCacheValidator = (cacheLT: number = productCacheLifeTime) => {
  const tsMap = new Map<string, number>();
  const set = (id: string) => {
    const ts = Date.now() / 1000;
    tsMap.set(id, ts);
  };

  const check = (id: string) => {
    const ts = tsMap.get(id);
    if (!ts) return undefined;
    return Date.now() / 1000 - ts < cacheLT;
  };

  const clear = () => {
    tsMap.clear();
  };
  return {
    set,
    check,
    clear,
  };
};
