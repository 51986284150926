<template>
  <div class="flex py-3 px-4 shadow-[0px_-4px_20px_rgba(0,0,0,0.12)] rounded-t-3xl">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
