<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 13.125C10.3827 13.125 13.125 10.3827 13.125 7C13.125 3.61726 10.3827 0.875 7 0.875C3.61726 0.875 0.875 3.61726 0.875 7C0.875 10.3827 3.61726 13.125 7 13.125ZM9.7125 5.64375C9.92996 5.3538 9.8712 4.94246 9.58125 4.725C9.2913 4.50754 8.87996 4.5663 8.6625 4.85625L6.4915 7.75092L5.27654 6.53596C5.02026 6.27968 4.60474 6.27968 4.34846 6.53596C4.09218 6.79224 4.09218 7.20776 4.34846 7.46404L6.09846 9.21404C6.23298 9.34856 6.41926 9.41809 6.60902 9.4046C6.79878 9.39111 6.97336 9.29594 7.0875 9.14375L9.7125 5.64375Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
