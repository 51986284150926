import CameraTrigger from '@/fsd/entities/cameraTrigger/CameraTrigger.vue';
import { callAction } from '@/fsd/shared/ui/action';
import { BarcodeHandler } from '@/services/scanner/handlers/BarcodeHandler';
import { cameraBarcodeActionId } from '@/temp/constants/common';
import { notify } from '@kyvg/vue3-notification';
import { markRaw } from 'vue';

export class CameraScanner extends BarcodeHandler {
  mode: string = 'camera';
  reject: () => any = () => {};

  constructor() {
    super();
  }

  async scan(): Promise<any> {
    await super.scan();
    return new Promise((resolve, reject) => {
      this.reject = reject;
      notify.close(cameraBarcodeActionId);
      callAction(markRaw(CameraTrigger), { resolve }, { id: cameraBarcodeActionId });
    });
  }

  async destroy() {
    await super.destroy();
    if (this.reject) {
      this.reject();
    }
    notify.close(cameraBarcodeActionId);
  }
}
