<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.9607 6.75H18V4.5C18 2.84315 16.6569 1.5 15 1.5H9C7.34315 1.5 6 2.84315 6 4.5L6 6.75H4.03931C2.22268 6.75 0.75 8.22268 0.75 10.0393C0.75 10.3449 0.792596 10.6491 0.87656 10.943L2.8175 17.7362C3.36946 19.6681 5.13519 21 7.14436 21H10.9288C10.3206 20.352 9.83229 19.5902 9.49913 18.75H7.14436C6.13978 18.75 5.25691 18.0841 4.98093 17.1181L3.03999 10.3248C3.01346 10.232 3 10.1359 3 10.0393C3 9.46532 3.46532 9 4.03931 9H19.9607C20.5347 9 21 9.46532 21 10.0393C21 10.1359 20.9865 10.232 20.96 10.3248L20.8313 10.7754C21.5363 11.3962 22.1177 12.1541 22.5336 13.0075L23.1234 10.943C23.2074 10.6491 23.25 10.3449 23.25 10.0393C23.25 8.22268 21.7773 6.75 19.9607 6.75ZM8.25 4.5V6.75H15.75V4.5C15.75 4.08579 15.4142 3.75 15 3.75L9 3.75C8.58579 3.75 8.25 4.08579 8.25 4.5Z"
      :fill="colorValue"
    />
    <path
      d="M15.1304 13.691C14.733 13.2935 14.0885 13.2935 13.691 13.691C13.2935 14.0885 13.2935 14.733 13.691 15.1304L14.6855 16.125L13.691 17.1196C13.2935 17.5171 13.2935 18.1615 13.691 18.559C14.0885 18.9565 14.733 18.9565 15.1304 18.559L16.125 17.5645L17.1196 18.559C17.5171 18.9565 18.1615 18.9565 18.559 18.559C18.9565 18.1615 18.9565 17.5171 18.559 17.1196L17.5645 16.125L18.559 15.1304C18.9565 14.733 18.9565 14.0885 18.559 13.691C18.1615 13.2935 17.5171 13.2935 17.1196 13.691L16.125 14.6855L15.1304 13.691Z"
      :fill="colorValue"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.125 9.75C12.6042 9.75 9.75 12.6042 9.75 16.125C9.75 19.6458 12.6042 22.5 16.125 22.5C19.6458 22.5 22.5 19.6458 22.5 16.125C22.5 12.6042 19.6458 9.75 16.125 9.75ZM11.7857 16.125C11.7857 13.7285 13.7285 11.7857 16.125 11.7857C18.5215 11.7857 20.4643 13.7285 20.4643 16.125C20.4643 18.5215 18.5215 20.4643 16.125 20.4643C13.7285 20.4643 11.7857 18.5215 11.7857 16.125Z"
      :fill="colorValue"
    />
  </svg>
</template>
<script setup lang="ts">
import { useColor } from '@/fsd/shared/hooks';

interface Props {
  bgColor?: string;
  color?: string;
}

const { bgColor = '#FFA60D', color = '#1C1D20' } = defineProps<Props>();
const params = {
  color: () => color,
  bgColor: () => bgColor,
};
const { colorValue } = useColor(params);
</script>
