import BaseOrder, { OrderBaseVars } from '@/models/orders/BaseOrder';
import { TransferAct } from './AcceptanceMarketOrder';

export interface ShipmentOrderVars extends OrderBaseVars {
  market_courier?: string;
  transfer_act?: TransferAct;
  transfer_act_diff?: {
    barcode: string;
    item_id: string | null;
    status: 'transferred_and_not_received' | 'not_transferred_and_received';
  }[];
}

export default class ShipmentOrder extends BaseOrder {
  public vars: ShipmentOrderVars = {};

  constructor(data: any) {
    super(data);
    this.vars = data.vars;
  }

  get isMarketShipment() {
    return Boolean(this.vars.market_courier);
  }
}
