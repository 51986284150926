<template>
  <div>
    <div class="picture">
      <div class="img-container">
        <ImageWrapper :img-src="imgSrc" />
      </div>
    </div>
    <div
      data-test="about product"
      class="info"
    >
      <Typography
        type="h3"
        align="center"
        margin="12px 16px"
      >
        {{ item.title }}
      </Typography>
    </div>
    <Typography
      color="sub-title-italic"
      align="center"
      margin="0 0 12px 0"
    >
      · {{ storageTypeTitle }} ·
    </Typography>
    <InfoRow
      v-if="item.external_id"
      :title="$gettext('Артикул')"
      :value="item.external_id"
    />
    <InfoRow
      v-if="available"
      :title="$gettext('Полка')"
      :value="shelf?.title ?? ''"
    />
  </div>
</template>

<script lang="ts">
import Item, { AvailableItem } from '@/models/Item';
import { useItems } from '@/store/modules/items';
import { useShelves } from '@/store/modules/shelves';
import { storageTypesInfo } from '@/temp/constants/translations';
import ImageWrapper from '@/ui/common/image-wrapper.vue';
import Typography from '@/ui/common/typography.vue';
import InfoRow from '@/ui/home/product-card/common/info-row.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    Typography,
    ImageWrapper,
    InfoRow,
  },
  props: {
    item: {
      type: Object as PropType<Item>,
      required: true,
    },
    needShowInfo: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  setup() {
    const itemsStore = useItems();
    const shelvesStore = useShelves();

    return {
      itemsStore,
      shelvesStore,
    };
  },
  computed: {
    imgSrc(): string {
      if (this.item) {
        return this.item.imgSrc;
      }
      return 'parcel';
    },
    available(): AvailableItem {
      return this.itemsStore.availableById(this.item.item_id)[0];
    },
    shelf() {
      return this.shelvesStore.shelfById(this.available?.shelf_id);
    },
    storageTypeTitle(): string {
      const tag = this.item.tags[0] || 'empty';
      return storageTypesInfo[tag];
    },
  },
});
</script>

<style lang="scss" scoped>
.picture {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.img-container {
  height: 174px;
  width: 174px;
  display: flex;
  position: relative;
}
</style>
