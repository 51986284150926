import { TagsEnum } from '@/models/Product';
import { BaseModel } from 'sjs-base-model';

export interface ItemInRequired {
  item_id: string;
  // Идентификатор продукта
  count?: number;
  // Количество
  result_count?: number;
  // Итоговое количество
  shelf_id?: string;
  // Идентификатор полки
  update_valids?: boolean;
  // флаг, который показывает, что изменение СГ будет применено ко всем остаткам
  // tags: Tags[];
}

export interface AvailableItem {
  shelf_id: string;
  product_id: string;
  shelf_type: string;
  count: number;
  reserved: number;
  quants: number;
  valid: string;
  is_components: boolean;
}

export const enum TypePackageTypeEnum {
  box = 'box',
  packet = 'packet',
  without_package = 'without_package',
}

export const enum TypePackageSizeEnum {
  l = 'l',
  m = 'm',
  s = 's',
}

export const enum TypePackageClassEnum {
  box_l = 'box_l',
  box_m = 'box_m',
  box_s = 'box_s',
  packet_l = 'packet_l',
  packet_m = 'packet_m',
  packet_s = 'packet_s',
  without_package = 'without_package',
}

export enum ParcelTagsEnum {
  // Лакшери упаковка
  ultima_package = 'ultima_package',
}

export default class Item extends BaseModel {
  // Идентификатор экземпляра
  public item_id: string = '';
  // Идентификатор склада
  public store_id: string = '';
  // Идентификатор идемпотентности в складе
  public external_id: string = '';
  // Тип экземпляра
  public type: 'parcel' | 'lot' = 'parcel';
  // Статус
  public status: 'active' | 'inactive' = 'active';
  // Название
  public title: string = '';
  // Штрихкоды
  public barcode: string[] = [];
  // Длинное название
  public description?: string = undefined;
  // Метки сопоставления с полками
  public tags: TagsEnum[] = [];
  // Данные, зависящие от типа
  public data: {
    // ссылка на фото в аватарнице
    image: string;
    //  Планируемая дата доставки
    delivery: string;
    // Тип и размер посылки
    package_class?: TypePackageClassEnum;
    weight?: number;
    // Массив характеристик посылки
    parcel_tags: ParcelTagsEnum[];
  } = {
      image: '',
      delivery: '',
      package_class: undefined,
      weight: 0,
      parcel_tags: [],
    };

  public created: string | number = '';
  public updated: string | number = '';
  public lsn: number = 0;
  public serial: number = 0;
  // ревизия
  public revision: number = 0;
  // Кто создавал посылку
  public source: 'wms' | 'beru' = 'wms';

  constructor(data: any) {
    super({ expand: true });
    this.update(data);
  }

  static isItem = (item): item is Item => item?.item_id;

  static isItemInRequired = (item): item is ItemInRequired => item.item_id;

  get packageClass(): TypePackageClassEnum | undefined {
    return this.data.package_class;
  }

  get isUltimaParcel(): boolean {
    return this.data?.parcel_tags?.includes(ParcelTagsEnum.ultima_package);
  }

  get hasImg(): boolean {
    return !!this.data.image;
  }

  get imgSrc(): string {
    if (this.data.image) {
      return this.data.image;
    }
    if (this.type === 'lot') return this.type;

    if (this.packageClass?.includes('box')) return 'parcel';
    if (this.packageClass?.includes('packet')) return 'packet';

    return 'empty';
  }

  get sizePackageItem(): TypePackageSizeEnum | undefined {
    if (this.packageClass === TypePackageClassEnum.without_package || !this.packageClass) return undefined;

    const [, size] = this.packageClass.split('_');

    return size as TypePackageSizeEnum;
  }

  get typePackageItem(): TypePackageTypeEnum | undefined {
    if (this.packageClass === TypePackageClassEnum.without_package)
      return TypePackageTypeEnum.without_package;

    if (!this.packageClass) return undefined;

    const [type] = this.packageClass.split('_');

    return type as TypePackageTypeEnum;
  }

  get weight(): number {
    return this.data.weight ?? 0;
  }

  // https://st.yandex-team.ru/LAVKADEV-14271
  checkBarcode(barcode: string) {
    // Некоторые камеры после скана подставляют вначало строки символ GS
    return this.barcode.some((b) => {
      return b === barcode || (marketItemsRe.test(barcode) && barcode.toLowerCase() === b.toLowerCase());
    });
  }
}

const marketItemsRe = /^(YP|YA)\d+$/i;
