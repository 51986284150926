<template>
  <Sector v-if="fragileSuggests.length">
    <template #title>
      <div class="flex justify-between">
        {{ $gettext('Хрупкое') }}
        <div>
          <Tag
            data-test="tag fragile-tag"
            class="inline"
            bg-color="purple-normal"
            color="white"
          >
            {{ $gettext('Класть сверху') }}
          </Tag>
        </div>
      </div>
    </template>

    <div class="flex flex-col gap-2">
      <ProductGroupItem
        v-for="s in fragileSuggests"
        :key="s.product_id"
        :suggest="s"
        data-test="product-group-item fragile"
      />
    </div>
  </Sector>

  <Sector v-if="domesticSuggests.length">
    <template #title>
      <div class="flex justify-between">
        {{ $gettext('Бытовая химия') }}
        <div>
          <Tag
            data-test="tag domestic-tag"
            class="inline"
            bg-color="orange-normal"
            color="white"
          >
            {{ $gettext('Отдельный пакет') }}
          </Tag>
        </div>
      </div>
    </template>

    <div class="flex flex-col gap-2">
      <ProductGroupItem
        v-for="s in domesticSuggests"
        :key="s.product_id"
        :suggest="s"
        data-test="product-group-item domestic"
      />
    </div>
  </Sector>

  <Sector v-if="specialPackageSuggests.length">
    <template #title>
      <div class="flex justify-between">
        {{ $gettext('Товары 18+') }}
        <div>
          <Tag
            data-test="tag special-package-tag"
            class="inline"
            bg-color="red-dark"
            color="white"
          >
            {{ $gettext('Непрозрачный пакет') }}
          </Tag>
        </div>
      </div>
    </template>

    <div class="flex flex-col gap-2">
      <ProductGroupItem
        v-for="s in specialPackageSuggests"
        :key="s.product_id"
        :suggest="s"
        data-test="product-group-item special-package"
      />
    </div>
  </Sector>

  <Sector
    v-if="commonSuggests.length"
    class="rounded-b-none"
  >
    <template #title>
      {{ $gettext('Обычные товары') }}
    </template>

    <div class="flex flex-col gap-2">
      <ProductGroupItem
        v-for="s in commonSuggests"
        :key="s.product_id"
        :suggest="s"
      />
    </div>
  </Sector>
</template>

<script lang="ts">
import Sector from '@/fsd/shared/ui/sector/sector.vue';
import Tag from '@/fsd/shared/ui/tag/tag.vue';
import ProductGroupItem from '@/fsd/widgets/order/finish-order/SortingStage/ProductGroupItem.vue';
import Suggest from '@/models/Suggest';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'ProductGroupsByType',
  components: {
    ProductGroupItem,
    Tag,
    Sector,
  },
  props: {
    suggests: {
      type: Array as PropType<Suggest[]>,
      required: true,
    },
  },
  computed: {
    specialPackageSuggests() {
      return this.suggests.filter(s => s.product?.special_package);
    },
    fragileSuggests() {
      return this.suggests.filter(s => s.product?.fragile && !s.product.special_package);
    },
    domesticSuggests() {
      return this.suggests.filter(s => s.product?.domestic && !s.product.special_package);
    },
    commonSuggests() {
      return this.suggests.filter(s => !s.product?.fragile && !s.product?.domestic && !s.product?.special_package);
    },
  },
});
</script>
