<template>
  <div class="w-screen h-[calc(100vh-20px)] z-10 fixed overflow-hidden rounded-2xl top-5 left-0 bg-white">
    <Layout>
      <template #header>
        <Bar
          :caption="$gettext('Ингредиент отсутствует')"
          :text="$gettext('Выберите ингредиент')"
          :icons="{ left: 'back', right: 'menu' }"
          :menu-config="[]"
          @close-click="$emit('close')"
        />
      </template>
      <template #default>
        <div class="w-full px-4">
          <MultiCheckbox
            v-model:value="missing"
            :options="checkboxOptions"
          />
        </div>
      </template>

      <template #footer>
        <LayoutFooter>
          <UiButton
            :is-disabled="missing.length === 0"
            data-test="missing-ingradient confirm-btn"
            @click="finish"
          >
            {{ $gettext('Подтвердить') }}
          </UiButton>
        </LayoutFooter>
      </template>
    </Layout>
  </div>
</template>

<script lang="ts">
import Product from '@/models/Product';
import productQueue from '@/services/queue/product-queue';
import { useProducts } from '@/store/modules/products';
import Bar from '@/ui/common/bar/bar.vue';
import MultiCheckbox from '@/ui/common/fields/multi-checkbox.vue';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { flatten } from 'lodash';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'OrderMissingIngradient',
  components: {
    MultiCheckbox,
    UiButton,
    LayoutFooter,
    Bar,
    Layout,
  },
  props: {
    product_id: {
      type: String,
      required: true,
    },
  },
  emits: ['close', 'done'],
  setup() {
    const productsStore = useProducts();

    return { productsStore };
  },
  data() {
    return {
      missing: [],
    };
  },
  computed: {
    product() {
      return this.productsStore.productById(this.product_id)!;
    },
    components(): Product[] {
      return this.componentsId.map(id => this.productsStore.productById(id)!).filter(Boolean);
    },
    componentsId(): string[] {
      return flatten(this.product?.components || []).map((c: any) => c.product_id);
    },
    checkboxOptions() {
      return this.components.map((c) => {
        return {
          name: c.title,
          id: c.product_id,
        };
      });
    },
  },
  mounted() {
    productQueue.loadMany(this.componentsId);
  },
  methods: {
    async finish() {
      const confirmed = await this.$notification.confirmBottom({
        title: this.$gettext('Вы выбрали все ингредиенты, которых не хватает?'),
      });
      if (!confirmed) return;
      this.$emit('done', this.missing);
    },
  },
});
</script>
