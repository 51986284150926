<template>
  <img
    :style="{ margin: '0 auto' }"
    width="116"
    src="./rover.png"
  >
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
