<template>
  <svg
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.72222 7.5H1.11111C0.767361 7.5 0.5 7.79297 0.5 8.125V9.375C0.5 9.72656 0.767361 10 1.11111 10H1.72222C2.04688 10 2.33333 9.72656 2.33333 9.375V8.125C2.33333 7.79297 2.04688 7.5 1.72222 7.5ZM4.77778 5H4.16667C3.82292 5 3.55556 5.29297 3.55556 5.625V9.375C3.55556 9.72656 3.82292 10 4.16667 10H4.77778C5.10243 10 5.38889 9.72656 5.38889 9.375V5.625C5.38889 5.29297 5.10243 5 4.77778 5ZM7.83333 2.5H7.22222C6.87847 2.5 6.61111 2.79297 6.61111 3.125V9.375C6.61111 9.72656 6.87847 10 7.22222 10H7.83333C8.15799 10 8.44444 9.72656 8.44444 9.375V3.125C8.44444 2.79297 8.15799 2.5 7.83333 2.5ZM10.8889 0H10.2778C9.93403 0 9.66667 0.292969 9.66667 0.625V9.375C9.66667 9.72656 9.93403 10 10.2778 10H10.8889C11.2135 10 11.5 9.72656 11.5 9.375V0.625C11.5 0.292969 11.2135 0 10.8889 0Z"
      fill="currentColor"
    />
  </svg>
</template>
