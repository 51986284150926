import { api } from '@/fsd/data/api/api.service';
import Item, { AvailableItem, TypePackageClassEnum } from '@/models/Item';
import ItemByBarcode from '@/models/ItemByBarcode';
import itemQueue from '@/services/queue/item-queue';
import { ItemsResponse } from '@/services/response';
import { createCacheValidator } from '@/store/utils/common';
import { logger } from '@/temp/plugins/logs';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { defineStore } from 'pinia';

const cv = createCacheValidator();
export const useItems = defineStore('items', {
  state: () => ({
    items: new Map<Item['item_id'], Item>(),
    availableByKey: {} as {
      [key: string]: AvailableItem[];
    },
  }),
  getters: {
    availableById: state => id => state.availableByKey[id],
    itemByBarcode:
      state =>
        (barcode: string): Item | undefined => {
          for (const [id, item] of state.items) {
            if (item.checkBarcode(barcode)) {
              useItems().checkNeedReload(id);
              return item as Item;
            }
          }
          return undefined;
        },
    itemById:
      state =>
        (id: string): Item | undefined => {
          useItems().checkNeedReload(id);
          return state.items.get(id) as Item;
        },
  },
  actions: {
    set(items: Item[]) {
      items.forEach((item) => {
        this.items.set(item.item_id, item);
        cv.set(item.item_id);
      });
    },
    addAvailable({ found, item_id }) {
      if (Array.isArray(item_id)) {
        item_id.forEach((item) => {
          const foundItem = found.filter((itemAvailable) => {
            return itemAvailable.item_id === item;
          });
          this.availableByKey = {
            ...this.availableByKey,
            [item]: foundItem,
          };
        });
      } else {
        this.availableByKey = {
          ...this.availableByKey,
          [item_id]: found,
        };
      }
    },
    clear() {
      this.items.clear();
      this.availableByKey = {};
      cv.clear();
    },
    async getItemById(id: string): Promise<Item | undefined> {
      const item = this.itemById(id);
      if (item) return item;
      try {
        return await itemQueue.load(id);
      } catch {
        return undefined;
      }
    },
    async getItemByBarcode(barcode: string) {
      const item = this.itemByBarcode(barcode);
      if (item) return item;
      // https://st.yandex-team.ru/LAVKADEV-11938 Отправляем order_id в запросе для логирования "бедпиков" при работе с посылками
      const url = window.location.pathname;
      const parts = url.split('/');
      let order_id: string | undefined = parts[parts.length - 1];
      if (order_id.length !== 44) order_id = undefined;
      const { data } = await api.barcode({
        barcode,
        order_id,
      });
      if (data.found.length > 1) {
        logger.event('MULTI_ITEM_ON_BARCODE', barcode);
      }
      const itemByBarcode = data.found[0];
      if (!itemByBarcode || !ItemByBarcode.isItemByBarcode(itemByBarcode)) throw new Error('wrong barcode');
      try {
        return await itemQueue.load(itemByBarcode.item_id);
      } catch (e) {
        console.error(e);
      }
    },
    async getItemsByIds(ids: string[]) {
      ids = ids.filter(id => !!id);
      ids = [...new Set(ids)];
      if (ids.length === 0) return;
      const findItems: Item[] = [];
      const itemsToFetchIds: string[] = [];
      ids.forEach((id) => {
        const item = this.itemById(id);
        if (item) findItems.push(item);
        else itemsToFetchIds.push(id);
      });
      if (itemsToFetchIds.length > 0) {
        try {
          const items = (await itemQueue.loadMany(itemsToFetchIds)).filter(Item.isItem);
          if (items) {
            findItems.push(...items);
          }
        } catch (e) {
          logger.error(e, {
            method: 'addToQueue',
            type: 'api',
            source: 'items.ts',
          });
        }
      }
      return findItems;
    },
    async loadItems({
      ids,
      config,
    }: {
      ids: string[];
      config?: AxiosRequestConfig;
    }): Promise<AxiosResponse<ItemsResponse>> {
      try {
        const response = await api.items.load({ ids }, config);
        this.set(response.data.items);
        return response;
      } catch (error) {
        logger.error(error, {
          method: 'items',
          type: 'api',
          source: 'items.ts',
        });
        throw error;
      }
    },
    async savePackageClassItem(item_id: string, package_class: TypePackageClassEnum) {
      try {
        const { data } = await api.items.save({
          item_id,
          data: {
            package_class,
          },
        });
        this.set([data.result]);
      } catch (error) {
        logger.error(error, {
          method: 'items/save',
          type: 'api',
          source: 'items.ts',
        });
        throw error;
      }
    },
    async fetchAvailable(item_id: string | string[]) {
      try {
        const { data } = await api.stock.available({ item_id });
        this.addAvailable({
          found: data.found,
          item_id,
        });
      } catch (error) {
        logger.error(error, {
          method: 'available',
          type: 'api',
          source: 'items.ts',
        });
      }
    },
    checkNeedReload(id: string) {
      if (cv.check(id) === false) {
        itemQueue.load(id);
      }
    },
    checkValidCache(id: string) {
      return cv.check(id);
    },
  },
});
