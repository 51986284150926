<template>
  <div class="bg-purple-normal rounded-full w-10 h-10 flex items-center justify-center">
    <IconFragile
      color="white"
      class="m-2.5 w-full h-full"
    />
  </div>
</template>

<script lang="ts">
import IconFragile from '@/temp/icons/icon-fragile.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IconFragileForModal',
  components: { IconFragile },
});
</script>
