import { StatusBadgeConfig, StatusTypeEnum } from '@/fsd/entities/badges';
import Suggest from '@/models/Suggest';
import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';
import { $gettext } from '@/temp/plugins/gettext';

export const getImgBadge = (suggest: Suggest | TrueMarkSuggestWrapper): StatusBadgeConfig[] => {
  const result: StatusBadgeConfig[] = [];
  if (suggest.isDone) {
    if (suggest.isFull || suggest.isOver) {
      result.push({
        type: StatusTypeEnum.complete,
        text: $gettext('Собран'),
      });
    } else if (suggest.isPartial) {
      result.push({
        type: StatusTypeEnum.waiting,
        text: $gettext('Мало'),
      });
    } else {
      result.push({
        type: StatusTypeEnum.error,
        text: $gettext('Нет'),
      });
    }
  }
  return result;
};
