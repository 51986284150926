import createRetryOnErrorFn from '@/fsd/shared/utils/retryRequest';
import Shelf from '@/models/Shelf';
import { createQueue, QueueLoadFn } from '@/services/queue/queue';
import { useShelves } from '@/store/modules/shelves';
import axios, { AxiosError } from 'axios';

const loadFn: QueueLoadFn<Shelf> = async (ids, setCancel) => {
  const uniqIds = [...new Set(ids)];
  const { data } = await useShelves().loadShelves({
    ids: uniqIds,
    config: {
      cancelToken: new axios.CancelToken(setCancel),
    },
  });
  const idToItem = data.shelves.reduce((acc, s) => {
    acc[s.shelf_id] = s;
    return acc;
  }, {});
  return ids.map(id => idToItem[id] || null);
};
const shelvesCheckError = (e: AxiosError) => {
  // нет ответа, сетевая ошибка
  if (!e.response) return true;
  // Отвалилась авторизация, пользователя разлогинило, не хотим ретраить
  if (e.response.status === 401) return false;
  return true;
};
const withRetry = createRetryOnErrorFn(loadFn, {
  maxRetry: 3,
  maxDelay: 8,
  chechError: shelvesCheckError,
});

const cacheMap = {
  get(id: string) {
    const shelf = useShelves().shelfById(id);
    if (!shelf) return;
    return Promise.resolve(shelf);
  },
  set() {},
  clear() {},
  delete() {},
};
// @ts-expect-error
export default createQueue<Shelf>(withRetry, cacheMap);
