<template>
  <div class="image-wrapper">
    <div class="empty-img-container">
      <img
        v-if="imgSrc === 'empty'"
        class="error-icon"
        src="@/assets/img/no-picture.svg"
        alt="Error Icon"
      >
      <IconItemBox
        v-else-if="imgSrc === 'parcel'"
        class="icon"
      />
      <img
        v-else-if="imgSrc === 'packet'"
        class="icon"
        src="@/temp/icons/icon-item-packet.png"
      >
      <img
        v-else-if="imgSrc === 'lot'"
        class="icon"
        src="@/temp/icons/icon-item-lot.png"
      >
      <template v-else>
        <img
          v-if="showImg"
          class="icon"
          :class="{ luminosity, grayscale, 'dark-theme': theme === 'dark' }"
          :src="imgSrc"
          alt="Icon"
          draggable="false"
          @error="() => (imgLoadError = true)"
        >
        <img
          v-else
          class="error-icon"
          src="@/assets/img/no-picture.svg"
          alt="Error Icon"
          draggable="false"
        >
      </template>
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { useUser } from '@/store/modules/user';
import IconItemBox from '@/temp/icons/icon-item-box.vue';
import { defineComponent } from 'vue';

interface Data {
  imgLoadError: boolean;
}

export default defineComponent({
  components: { IconItemBox },
  props: {
    imgSrc: {
      type: String,
      default: undefined,
    },
    luminosity: {
      type: Boolean,
      default: false,
    },
    grayscale: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const userStore = useUser();

    return { userStore };
  },
  data(): Data {
    return {
      imgLoadError: false,
    };
  },
  computed: {
    theme(): string {
      return this.userStore.theme;
    },
    showImg(): boolean {
      return Boolean(this.imgSrc) && !this.imgLoadError;
    },
  },
});
</script>

<style lang="scss" scoped>
.image-wrapper {
  width: inherit;
  height: inherit;
}

.icon {
  width: inherit;
  height: inherit;
  position: absolute;
  object-fit: contain;

  &.dark-theme {
    opacity: 0.72;
  }

  &.luminosity {
    mix-blend-mode: luminosity;
  }

  &.grayscale {
    filter: grayscale(100%);
  }
}

.error-icon {
  position: absolute;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.empty-img-container {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
}
</style>
