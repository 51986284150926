<template>
  <Row
    v-if="needShow"
    :label="label"
    :value="value"
    :is-bold="true"
    data-test="suggest-details count row"
  />
</template>

<script lang="ts">
import { getValueForView } from '@/fsd/entities/product';
import BaseOrder, { OrderStageEnum, OrderTypeEnum } from '@/models/orders/BaseOrder';
import Product from '@/models/Product';
import Shelf, { ShelfTypeEnum } from '@/models/Shelf';
import Suggest, { SuggestTypeEnum } from '@/models/Suggest';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { getCountTitle } from '@/temp/constants/translations';
import Row from '@/ui/common/suggest-details/row.vue';
import { Model } from '@/ui/common/suggest-details/types';
import { defineComponent, PropType } from 'vue';

// Компонент для отображения кол-ва в задании(если есть)

export default defineComponent({
  components: {
    Row,
  },
  props: {
    model: {
      type: Object as PropType<Model>,
      required: true,
    },
    order: {
      type: Object as PropType<BaseOrder>,
      required: true,
    },
    suggest: {
      type: Object as PropType<Suggest>,
      required: true,
    },
    product: {
      type: Object as PropType<Product>,
      default: undefined,
    },
    step: {
      type: String,
      required: true,
    },
  },
  setup() {
    const shelvesStore = useShelves();
    const productsStore = useProducts();

    return {
      shelvesStore,
      productsStore,
    };
  },
  computed: {
    shelf(): Shelf {
      return this.shelvesStore.shelfById(this.suggest.shelf_id)!;
    },
    isControl(): boolean {
      return [
        OrderTypeEnum.writeoff_prepare_day,
        OrderTypeEnum.check_valid_regular,
        OrderTypeEnum.check_valid_short,
      ].includes(this.order.type);
    },
    needShow(): boolean {
      if ([OrderTypeEnum.check_final, OrderTypeEnum.stowage_market].includes(this.order.type)) return false;
      if (this.order.type === OrderTypeEnum.acceptance) return true;
      if (
        this.order.type === OrderTypeEnum.sale_stowage
        && this.order.vars.stage === OrderStageEnum.trash
        && this.model.count === 0
      ) {
        return false;
      }
      if ([OrderTypeEnum.check_more, OrderTypeEnum.inventory_check_more, OrderTypeEnum.weight_stowage].includes(this.order.type)
        && this.model.count
      ) {
        return true;
      }
      return !!(this.suggest.count && (this.suggest.vars.mode === 'product' || !this.suggest.vars.mode));
    },
    label(): string {
      if (this.isControl && this.step !== 'count' && this.suggest.type === SuggestTypeEnum.shelf2box) {
        return this.$gettext('Списываем');
      }
      return getCountTitle({
        docType: this.order.type,
        suggest: this.suggest,
      });
    },
    value(): string {
      let count = this.model.count || this.suggest.count || 0;
      if (this.order.type === OrderTypeEnum.acceptance && this.step === 'count') {
        count = this.suggest.count || 0;
      }
      if (this.isControl && this.step !== 'count' && this.suggest.type === SuggestTypeEnum.shelf2box) {
        count = this.model.count || 0;
      }
      if (
        [OrderTypeEnum.inventory_check_product_on_shelf, OrderTypeEnum.check, OrderTypeEnum.check_final].includes(
          this.order.type,
        )
      ) {
        let productOnShelf;
        if ([ShelfTypeEnum.trash, ShelfTypeEnum.kitchen_trash].includes(this.shelf.type)) {
          const available = this.shelvesStore.availableById(this.suggest?.shelf_id);
          productOnShelf = available?.find(item => item.product_id === this.suggest?.product_id)!;
        } else {
          const available = this.productsStore.availableByProductId(this.suggest.product_id);
          productOnShelf = available?.find(item => item.shelf_id === this.suggest.shelf_id)!;
        }
        count = productOnShelf?.count || 0;
      }
      return getValueForView({
        product: this.product!,
        shelf: this.shelf,
        count,
        order: this.order,
      });
    },
  },
});
</script>
