<template>
  <ClientOrderSuggestCardSherlockInfo
    :suggest="suggest"
    img-size="small"
  >
    <div
      data-test="sherlock-counter container"
      class="bg-day-backgroundInvert rounded-t-3xl px-3 py-4 flex flex-col items-center"
      @touchstart.stop
    >
      <body2
        data-test="sherlock-counter title"
        color="white"
        class="mx-3 mb-4 font-medium"
      >
        <template v-if="needScanProduct">
          {{ $gettext('Отсканируйте продукт') }}
        </template>
        <template v-else>
          {{ $gettext('Какое количество этого товара на полке?') }}
        </template>
      </body2>
      <Counter
        :min="0"
        :disabled="needScanProduct"
        :product_id="suggest.product_id"
        :btn-text="$gettext('Готово')"
        :btn-timeout="5"
        @confirm="value => onInputSherlock({ value })"
      />
    </div>
  </ClientOrderSuggestCardSherlockInfo>
</template>

<script lang="ts" setup>
import Counter from '@/fsd/entities/counter';
import ClientOrderSuggestCardSherlockInfo from '@/fsd/widgets/order/RequiredCard/sherlock/ClientOrderSuggestCardSherlockInfo.vue';
import { useSherlock } from '@/fsd/widgets/order/RequiredCard/sherlock/useSherlock';
import { checkBarcodeBySuggest } from '@/fsd/widgets/order/RequiredCard/useCollectOrder';
import { finishSherlock, startSherlock } from '@/fsd/widgets/order/rum/useMeasureAssemblingPosition';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Suggest from '@/models/Suggest';
import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';
import OrderOrder from '@/models/orders/OrderOrder';
import { useProducts } from '@/store/modules/products';
import { $gettext } from '@/temp/plugins/gettext';
import { ref, watch } from 'vue';

const props = defineProps<{
  suggest: Suggest | TrueMarkSuggestWrapper;
  order_id: OrderOrder['order_id'];
  isVisible: boolean;
}>();

const emits = defineEmits(['input-sherlock-count']);

const { sendSherlockSignal } = useSherlock(props.order_id, props.suggest);

const needScanProduct = ref(true);

const ackSherlockCount = async () => {
  startSherlock();
  if (!needScanProduct.value) return;
  useRequestBarcode(async (barcode) => {
    const valid = await checkBarcodeBySuggest(barcode, props.suggest);
    if (!valid) {
      return true;
    }
    needScanProduct.value = false;
    return false;
  });
};
const onInputSherlock = async ({ value }: { value: number }): Promise<void> => {
  const available = await useProducts().fetchAvailable(props.suggest.product_id);
  const availableOnShelf = available?.find(item => item.shelf_id === props.suggest.shelf_id);
  finishSherlock();
  if (availableOnShelf?.count !== value) {
    sendSherlockSignal();
  }
  emits('input-sherlock-count', value);
};

watch(
  () => props.isVisible,
  (val) => {
    if (!val) return;
    // запускаем на след тик, чтобы перехватить запрос у основного потока.
    setTimeout(ackSherlockCount);
  },
  { immediate: true },
);
</script>
