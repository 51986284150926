type Sender<T> = (logs: T[]) => void;

type Filter<T> = (log: T) => boolean;
export default class Logger<T> {
  private logs: T[] = [];
  private autoSendTimeout: number = 600;
  private autoSendCount: number = 10;
  private timerId?: number;
  private sender: Sender<T>;
  private filter?: Filter<T>;
  constructor(sender: Sender<T>, filter?: Filter<T>) {
    this.sender = sender;
    this.filter = filter;
  }

  push(item: T) {
    const valid = this.filter ? this.filter(item) : true;
    if (!valid) return;
    this.logs.push(item);
    if (!this.timerId) {
      this.createTimer();
    }
    if (this.logs.length >= this.autoSendCount) {
      this.send();
    }
  }

  private createTimer() {
    this.timerId = setTimeout(() => this.send, this.autoSendTimeout);
  }

  private send() {
    const logs = this.logs;
    this.clear();
    this.sender(logs);
  }

  clear() {
    this.logs = [];
    clearTimeout(this.timerId);
    this.timerId = undefined;
  }
}
