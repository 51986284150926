import createRetryOnErrorFn from '@/fsd/shared/utils/retryRequest';
import Product from '@/models/Product';
import { createQueue, QueueLoadFn } from '@/services/queue/queue';
import { useProducts } from '@/store/modules/products';
import axios, { AxiosError } from 'axios';

const loadFn: QueueLoadFn<Product> = async (ids, setCancel) => {
  const uniqIds = [...new Set(ids)];
  const { data } = await useProducts().loadProducts({
    ids: uniqIds,
    config: {
      cancelToken: new axios.CancelToken(setCancel),
    },
  });
  const idToItem = data.products.reduce((acc, p) => {
    acc[p.product_id] = p;
    return acc;
  }, {});
  return ids.map(id => idToItem[id] || null);
};
const productCheckError = (e: AxiosError) => {
  // нет ответа, сетевая ошибка
  if (!e.response) return true;
  // Отвалилась авторизация, пользователя разлогинило, не хотим ретраить
  if (e.response.status === 401) return false;
  return true;
};
const withRetry = createRetryOnErrorFn(loadFn, {
  maxRetry: 3,
  maxDelay: 8,
  chechError: productCheckError,
});

const cacheMap = {
  get(id: string) {
    const product = useProducts().products.get(id) as Product;
    if (!product) return;
    const cacheValid = useProducts().checkValidCache(id);
    if (cacheValid === false) return;
    return Promise.resolve(product);
  },
  set() {},
  clear() {},
  delete() {},
};
// @ts-expect-error
export default createQueue<Product>(withRetry, cacheMap);
