import BaseOrder, { OrderTypeEnum } from '@/models/orders/BaseOrder';

export default class RobotProvision extends BaseOrder {}

/**
 * True, если ордер на снабжения робозоны
 * @param o
 */
export const isRobotProvisionOrder = (o: BaseOrder): o is RobotProvision => {
  return o.type === OrderTypeEnum.robot_provision;
};
