<template>
  <div
    class="flex items-center"
    data-test="history-item"
  >
    <ImageWrapper
      style="width: 52px; height: 52px"
      :img-src="product && product.images[0]"
    />
    <div
      class="flex flex-auto mx-3 py-4 justify-between border-b-2 border-day-line last:border-b-0"
      :class="{ 'opacity-40': isDeleted }"
    >
      <div class="flex flex-col">
        <body-2 class="font-medium mb-px">
          {{ title }}
          <template v-if="isDeleted">
            {{ $gettext('Удалено') }}
          </template>
        </body-2>
        <caption-1 color="day-textMinor">
          {{ info }}
        </caption-1>
      </div>
      <div
        v-if="!isDeleted"
        class="cursor-pointer"
        @click="deleteItem"
      >
        <Trash />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Product, { TypeAccountingEnum } from '@/models/Product';
import { useProducts } from '@/store/modules/products';
import Trash from '@/temp/icons/trash.vue';
import ImageWrapper from '@/ui/common/image-wrapper.vue';
import { getCountToView } from '@/ui/common/suggest-card/formatters/count-formatter';
import {
  HistoryItem, HistoryStatusEnum, HistoryTypeEnum,
} from '@/views/control_check/types';
import dayjs from 'dayjs';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  components: {
    Trash,
    ImageWrapper,
  },
  props: {
    historyItem: {
      type: Object as PropType<HistoryItem>,
      required: true,
    },
  },
  emits: ['delete'],
  setup() {
    const productsStore = useProducts();

    return { productsStore };
  },
  computed: {
    product(): Product | undefined {
      return this.productsStore.productById(this.historyItem.product_id);
    },
    time(): string {
      return dayjs(this.historyItem.time).format('HH:mm:ss');
    },
    title(): string {
      return this.product?.title || '';
    },
    isDeleted(): boolean {
      return this.historyItem.status === HistoryStatusEnum.deleted;
    },
    info(): string {
      const mapType = {
        [HistoryTypeEnum.input]: this.$gettext('Ручной ввод'),
        [HistoryTypeEnum.scan]: this.$gettext('Сканирование'),
      };
      if (this.historyItem.type === HistoryTypeEnum.scan) {
        return `${mapType[HistoryTypeEnum.scan]} · ${this.time}`;
      }
      return `${mapType[HistoryTypeEnum.input]} · ${getCountToView({
        [this.product?.type_accounting === TypeAccountingEnum.weight ? 'weight' : 'count']: this.historyItem.count,
        type_accounting: this.product?.type_accounting,
      })} · ${this.time}`;
    },
  },
  methods: {
    deleteItem(): void {
      this.$emit('delete');
    },
  },
});
</script>
