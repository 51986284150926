// Название страниц для страниц
export const enum PageMetric {
  PRODUCT_CARD = 'productCard',
  PRODUCT_CHECK_CREATE = 'productCheckCreate',
  PRODUCT_CHANGE_VALID_CREATE = 'productChangeValidCreate',
  PRODUCT_CHECK = 'productCheck',
  PRODUCT_MOVE = 'productMove',
  PRODUCT_WRITEOFF = 'productWriteoff',
  SHELF_CARD = 'shelfCard',
  SUPPORT_CHAT = 'supportChat',
  ITEM_CARD = 'itemCard',
  ITEM_CARD_MOVE = 'itemCardMove',
}
// Название страниц для блоков
export const enum BlockMetric {
  EXAMPLE_BLOCK = 'EXAMPLE_BLOCK',
}

interface OptionsWithDelta<M extends string> {
  deltaLifecycleHooks: true; // считать дельту в хуках
  deltaName: M;
}

interface OptionsWithoutDelta {
  deltaLifecycleHooks?: false; // не считать дельту в хуках
  deltaName?: never;
}

interface OptionsBase {
  pageName: string; // имя для страницы, в зависимости от isBlock будет префикс page. || block.
  isBlock?: boolean; // создавать страницу как блок
  pageLifecycleHooks?: boolean; // создавать и финализировать страницу в хуках
}

export type RumOptions<M extends string> = OptionsBase & (OptionsWithDelta<M> | OptionsWithoutDelta);
