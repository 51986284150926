<template>
  <ScaleContainer
    need-scale
    class="item-container"
    data-test="list-card-item container"
    @long-press="$emit('long-press')"
  >
    <button class="item">
      <div class="item-wrap">
        <Typography
          type="text2"
          class="text-secondary"
        >
          {{ $gettext('Полка') }}
        </Typography>
        <Typography
          type="h3"
          class="text-primary"
        >
          <slot />
        </Typography>
      </div>
      <div class="code">
        <img
          v-if="isDone"
          src="@/assets/img/complete.svg"
          alt=""
        >
        <img
          v-if="isInProgress"
          src="@/assets/img/in-progress.svg"
          alt=""
        >
        <img
          v-if="!isDone && !isInProgress"
          src="@/assets/img/waiting.svg"
          alt=""
        >
      </div>
    </button>
  </ScaleContainer>
</template>

<script lang="ts">
import ScaleContainer from '@/ui/common/scale-container/scale-container.vue';
import Typography from '@/ui/common/typography.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    ScaleContainer,
    Typography,
  },
  props: {
    count: {
      type: Number,
      required: false,
      default: undefined,
    },
    done: {
      type: Number,
      default: 0,
    },
    isDone: {
      type: Boolean,
      default: false,
    },
    isInProgress: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['long-press'],
  data() {
    return {
      uiState: {
        longTapStart: false,
      },
    };
  },
  computed: {
    isCompleted(): boolean {
      if (isNaN(this.done)) {
        return false;
      }

      return this.done === this.count;
    },
  },
});
</script>

<style lang="scss" scoped>
.item-container {
  width: 50%;
  height: 100px;
  display: flex;
}

.item {
  align-items: center;
  display: flex;
  padding: 0;
  text-align: left;
  flex-grow: 1;
  position: relative;
  border-radius: 12px;
  background: var(--card-bg);
  margin: 4px;
  overflow: hidden;
}

.item-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  padding: 12px 0 12px 16px;
  z-index: 1;
}

.code {
  position: absolute;
  bottom: 0;
  right: 1px;
  z-index: 0;
}
</style>
