import { AudioService } from '@/services/audio.service';
import {
  confirmNotifyId, majorErrorNotifyId, modalNotifyId,
} from '@/temp/constants/common';
import { $gettext } from '@/temp/plugins/gettext';
import { ButtonConfig, IconConfig } from '@/temp/plugins/types';
import { IErrorRow } from '@/temp/ui/notifications/major-error/error-row/types';
import Vue3Notification, { notify } from '@kyvg/vue3-notification';
import { flatten } from 'lodash';

export const Notifications = {
  confirmBottom(options: IConfirm): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      notify({
        group: 'universal-modal',
        duration: -Infinity,
        id: confirmNotifyId,
        data: {
          title: options.title,
          titleAlign: options.titleAlign,
          text: options.text,
          textAlign: options.textAlign,
          iconTop: options.iconTop,
          iconCenter: options.iconCenter,
          iconBottom: options.iconBottom,
          backdropClick: options.backdropClick,
          dataTest: options.dataTest,
          resolve,
          buttons: [
            {
              title: options.decline ?? $gettext('Нет'),
              color: 'secondary',
              dataTest: 'confirm decline-btn',
              onClick: () => false,
            },
            {
              title: options.ok ?? $gettext('Да'),
              color: 'primary',
              dataTest: 'confirm ok-btn',
              timeout: options.timeout,
              onClick: () => true,
            },
          ],
        },
      });
    });
  },
  universalModal(options: IUniversalModal) {
    return new Promise((resolve) => {
      notify({
        // id нужно для того, чтобы принудительно закрыть модалку
        id: modalNotifyId,
        group: 'universal-modal',
        duration: -1,
        data: {
          resolve,
          position: options.position || 'center',
          title: options.title,
          titleAlign: options.titleAlign,
          text: options.text,
          textAlign: options.textAlign,
          iconTop: options.iconTop,
          iconCenter: options.iconCenter,
          iconBottom: options.iconBottom,
          backdropClick: options.backdropClick,
          isWrap: options.isWrap,
          buttons: options.buttons,
        },
      });
    });
  },
  error: {
    major: {
      suggest(options: ISuggestError) {
        AudioService.playError();
        notify({
          duration: -Infinity,
          group: 'major-error',
          id: majorErrorNotifyId,
          data: {
            title: options.title,
            details: [
              {
                title: $gettext('Операция'),
                label: options.operation,
              },
              ...flatten(
                options.products.map(product => [
                  {
                    title: $gettext('Товар'),
                    label: product.label,
                  },
                  {
                    title: $gettext('Количество'),
                    label: product.count,
                  },
                ]),
              ),
              {
                title: $gettext('Причина ошибки'),
                label: options.reason,
              },
              {
                title: $gettext('Ошибка'),
                label: options.body,
              },
            ] as Array<IErrorRow>,
            onClose: options.onClose,
            onRepeat: options.onRepeat,
          },
        });
      },
      order(options: IOrderError) {
        AudioService.playError();
        notify({
          duration: -Infinity,
          group: 'major-error',
          id: majorErrorNotifyId,
          data: {
            title: options.title,
            details: [
              {
                title: $gettext('Операция'),
                label: $gettext('Закрытие документа'),
              },
              {
                title: $gettext('Причина ошибки'),
                label: options.reason,
              },
              {
                title: $gettext('Ошибка'),
                label: options.body,
              },
            ] as Array<IErrorRow>,
            onClose: options.onClose,
            onRepeat: options.onRepeat,
          },
        });
      },
    },
  },
};

export const notification = {
  install(app) {
    app.use(Vue3Notification);
    app.config.globalProperties.$notification = Notifications;
  },
};

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $notification: INotification;
  }
}

export interface INotification {
  confirmBottom: TConfirm;
  universalModal: TUniversalModal;
  error: {
    major: {
      suggest: (config: ISuggestError) => void;
      order: (config: IOrderError) => void;
    };
  };
}

type TConfirm = (options: IConfirm) => Promise<boolean>;

type TUniversalModal = (options: IUniversalModal) => Promise<void>;

interface ICommon {
  title: string;
  titleAlign?: string;
  text?: string;
  textAlign?: string;
  iconTop?: IconConfig;
  iconCenter?: IconConfig;
  iconBottom?: IconConfig;
  backdropClick?: boolean;
  class?: string;
  dataTest?: string;
}

export interface IUniversalModal extends ICommon {
  buttons?: ButtonConfig[];
  position?: 'bottom' | 'center';
  isWrap?: boolean;
}

export interface IModal extends ICommon {
  position?: 'bottom' | 'center';
  buttonText?: string;
  timeout?: number;
}

export interface IConfirm extends ICommon {
  ok?: string;
  decline?: string;
  timeout?: number;
}

interface IProductRow {
  label: string;
  count: string | number;
}

export interface ISuggestError extends IMajorErrorBase {
  operation: string;
  products: Array<IProductRow>;
}

export type IOrderError = IMajorErrorBase;

interface IMajorErrorBase {
  title: string;
  reason: string;
  body: string;
  onClose: () => void;
  onRepeat: () => void;
}
