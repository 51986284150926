<template>
  <div>
    <div
      class="border-l-4 border-blue-400 flex cursor-pointer"
      @click="toggleVisibility"
    >
      <Body1 class="pl-1 font-bold">
        <slot name="title" />
      </Body1>
      <img
        src="./arrow-navigate.png"
        :class="!contentVisible && '-rotate-90'"
        class="ml-1 w-3 h-3 mt-1.5"
      >
    </div>
    <div
      v-if="contentVisible"
      class="mt-1"
    >
      <slot />
    </div>
  </div>
</template>

<script setup>
import Body1 from '@/ui/common/typo/body-1.vue';
import { ref } from 'vue';

const contentVisible = ref(false);
const toggleVisibility = () => (contentVisible.value = !contentVisible.value);
</script>
