<template>
  <div class="flex flex-col px-2 bg-background-main rounded-b-3xl">
    <RouteBar
      :active-index="index"
      :chips="chipsContent"
      @choose-chip="idx => $emit('choose-suggest', idx)"
    />
  </div>
</template>

<script lang="ts">
import RouteBar from '@/fsd/shared/ui/routeBar';
import { AppendIconNameEnum, ChipContent, IconNameEnum } from '@/fsd/shared/ui/routeBar/types';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  name: 'ShelvesChain',
  components: {
    RouteBar,
  },
  props: {
    suggests: {
      type: Array as PropType<Suggest[]>,
      default: () => {
        return [] as Suggest[];
      },
    },
    index: {
      type: Number,
      required: true,
    },
  },
  emits: ['choose-suggest'],
  computed: {
    chipsContent(): ChipContent[] {
      return this.suggests?.map(suggest => ({
        text: this.getShelfTitle(suggest),
        icon: suggest.status === SuggestStatusEnum.done ? IconNameEnum.completed : undefined,
        appendIcon: suggest.isRobozone ? AppendIconNameEnum.robot : undefined,
      }));
    },
  },
  methods: {
    getShelfTitle(suggest): string {
      return suggest.shelf?.title || '-';
    },
  },
});
</script>
