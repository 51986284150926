<template>
  <svg
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path
      fill="#1C1D20"
      d="m4.125,5.4375c0,-0.72487 0.58763,-1.3125 1.3125,-1.3125l3.28125,0l0,-2.625l-3.28125,0c-2.17462,0 -3.9375,1.76288 -3.9375,3.9375l0,3.28125l2.625,0l0,-3.28125z"
    />
    <path
      fill="#1C1D20"
      d="m13.691,13.691c0.3975,-0.3975 1.042,-0.3975 1.4394,0l0.9946,0.9945l0.9946,-0.9945c0.3975,-0.3975 1.0419,-0.3975 1.4394,0c0.3975,0.3975 0.3975,1.042 0,1.4394l-0.9945,0.9946l0.9945,0.9946c0.3975,0.3975 0.3975,1.0419 0,1.4394c-0.3975,0.3975 -1.0419,0.3975 -1.4394,0l-0.9946,-0.9945l-0.9946,0.9945c-0.3975,0.3975 -1.0419,0.3975 -1.4394,0c-0.3975,-0.3975 -0.3975,-1.0419 0,-1.4394l0.9945,-0.9946l-0.9945,-0.9946c-0.3975,-0.3974 -0.3975,-1.0419 0,-1.4394z"
    />
    <path
      fill="#1C1D20"
      d="m9.75,16.125c0,-3.5208 2.8542,-6.375 6.375,-6.375c3.5208,0 6.375,2.8542 6.375,6.375c0,3.5208 -2.8542,6.375 -6.375,6.375c-3.5208,0 -6.375,-2.8542 -6.375,-6.375zm6.375,-4.3393c-2.3965,0 -4.3393,1.9428 -4.3393,4.3393c0,2.3965 1.9428,4.3393 4.3393,4.3393c2.3965,0 4.3393,-1.9428 4.3393,-4.3393c0,-2.3965 -1.9428,-4.3393 -4.3393,-4.3393z"
      clip-rule="evenodd"
      fill-rule="evenodd"
    />
    <path
      fill="#1C1D20"
      d="m5.4375,19.875c-0.72487,0 -1.3125,-0.5876 -1.3125,-1.3125l0,-3.2812l-2.625,0l0,3.2812c0,2.1747 1.76288,3.9375 3.9375,3.9375l3.28125,0l0,-2.625l-3.28125,0z"
    />
    <path
      fill="#1C1D20"
      d="m18.5625,4.125c0.7249,0 1.3125,0.58763 1.3125,1.3125l0,3.28125l2.625,0l0,-3.28125c0,-2.17462 -1.7628,-3.9375 -3.9375,-3.9375l-3.2813,0l0,2.625l3.2813,0z"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
