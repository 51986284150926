<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="circle-xmark">
      <path
        id="icon"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 19.25C14.8325 19.25 18.75 15.3325 18.75 10.5C18.75 5.66751 14.8325 1.75 10 1.75C5.16751 1.75 1.25 5.66751 1.25 10.5C1.25 15.3325 5.16751 19.25 10 19.25ZM8.16291 7.33709C7.79679 6.97097 7.2032 6.97097 6.83709 7.33709C6.47097 7.7032 6.47097 8.29679 6.83709 8.66291L8.67417 10.5L6.83709 12.3371C6.47097 12.7032 6.47097 13.2968 6.83709 13.6629C7.2032 14.029 7.79679 14.029 8.16291 13.6629L10 11.8258L11.8371 13.6629C12.2032 14.029 12.7968 14.029 13.1629 13.6629C13.529 13.2968 13.529 12.7032 13.1629 12.3371L11.3258 10.5L13.1629 8.66291C13.529 8.29679 13.529 7.7032 13.1629 7.33709C12.7968 6.97097 12.2032 6.97097 11.8371 7.33709L10 9.17417L8.16291 7.33709Z"
        fill="white"
      />
    </g>
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
