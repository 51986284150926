<template>
  <Layout>
    <div
      class="flex flex-col h-full"
      data-test="empty truck step"
    >
      <Title2 class="font-bold px-4 pt-2 my-[18px]">
        {{ $gettext('Разгрузите машину') }}
      </Title2>
      <Body2 class="px-4 pb-4">
        {{ $gettext('Вы сможете продолжить приёмку, когда водитель уедет') }}
      </Body2>
      <div class="flex justify-between items-center mx-4 py-4 border-b">
        <Body2>{{ $gettext('Машина разгружена') }}</Body2>
        <Checkbox
          :checked="truckEmpty"
          :field-id="'empty truck'"
          @update:checked="truckEmpty = !truckEmpty"
        />
      </div>
    </div>
    <template #footer>
      <div class="p-2">
        <UiButton
          background-color="primary"
          :is-disabled="!truckEmpty"
          data-test="empty truck continue"
          @click="$emit('done')"
        >
          {{ $gettext('Далее') }}
        </UiButton>
      </div>
    </template>
  </Layout>
</template>
<script lang="ts">
import Checkbox from '@/ui/common/fields/checkbox.vue';
import Layout from '@/ui/common/layout.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import Title2 from '@/ui/common/typo/title-2.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { defineComponent } from 'vue';

interface Data {
  truckEmpty: boolean;
}

export default defineComponent({
  name: 'EmptyTruck',
  components: {
    Checkbox,
    Layout,
    UiButton,
    Body2,
    Title2,
  },
  emits: ['done'],
  data(): Data {
    return {
      truckEmpty: false,
    };
  },
});
</script>
