<template>
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="0.5"
      width="40"
      height="40"
      rx="20"
      fill="#707170"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.625 25.5C25.625 26.5355 24.7855 27.375 23.75 27.375H16.25C15.2145 27.375 14.375 26.5355 14.375 25.5L14.375 15.5C14.375 14.4645 15.2145 13.625 16.25 13.625L19.6967 13.625C20.194 13.625 20.6709 13.8225 21.0225 14.1742L25.0758 18.2275C25.4275 18.5791 25.625 19.056 25.625 19.5533V25.5ZM26.4016 16.9016C27.1049 17.6049 27.5 18.5587 27.5 19.5533L27.5 25.5C27.5 27.5711 25.8211 29.25 23.75 29.25H16.25C14.1789 29.25 12.5 27.5711 12.5 25.5V15.5C12.5 13.4289 14.1789 11.75 16.25 11.75H19.6967C20.6913 11.75 21.6451 12.1451 22.3483 12.8483L26.4016 16.9016ZM16.8371 22.7254C16.471 22.3593 16.471 21.7657 16.8371 21.3996C17.2032 21.0335 17.7968 21.0335 18.1629 21.3996L19.0625 22.2992V19.5625C19.0625 19.0447 19.4822 18.625 20 18.625C20.5178 18.625 20.9375 19.0447 20.9375 19.5625V22.2992L21.8371 21.3996C22.2032 21.0335 22.7968 21.0335 23.1629 21.3996C23.529 21.7657 23.529 22.3593 23.1629 22.7254L20.6629 25.2254C20.2968 25.5915 19.7032 25.5915 19.3371 25.2254L16.8371 22.7254Z"
      fill="white"
    />
  </svg>
</template>
