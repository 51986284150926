import Suggest from '@/models/Suggest';
import { DocTypes } from '@/temp/constants/translations/types';
import { $gettext, gettextWrapper } from '@/temp/plugins/gettext';

export const inputCountTitles = gettextWrapper({
  default: $gettext('На складе'),
  check_valid_regular: {
    box2shelf: $gettext('Вернуть'),
    shelf2box: $gettext('Проверить'),
    write_off: $gettext('Списать'),
  },
  writeoff_prepare_day: {
    box2shelf: $gettext('Вернуть'),
    shelf2box: $gettext('Проверить'),
    write_off: $gettext('Списать'),
  },
  check_valid_short: {
    box2shelf: $gettext('Вернуть'),
    shelf2box: $gettext('Проверить'),
    write_off: $gettext('Списать'),
  },
  sale_stowage: {
    box2shelf: $gettext('Размещается'),
    shelf2box: $gettext('Вернуть в корзину'),
    trash: $gettext('Количество'),
  },
  acceptance: $gettext('Принять'),
  writeoff: $gettext('Списать'),
  check_more: $gettext('На полке'),
  refund: {
    box2shelf: $gettext('Разместить'),
    shelf2box: $gettext('Вернуть в корзину'),
    trash: $gettext('Списать'),
  },
  part_refund: $gettext('Количество'),
  shipment: {
    shelf2box: $gettext('Отгрузить'),
    box2shelf: $gettext('Разместить'),
  },
  shipment_rollback: {
    box2shelf: $gettext('Разместить'),
    shelf2box: $gettext('Вернуть в корзину'),
    trash: $gettext('Списать'),
  },
  hand_move: $gettext('Кол-во'),
  kitchen_provision: $gettext('Кол-во'),
  robot_provision: $gettext('Кол-во'),
  visual_control: {
    box2shelf: $gettext('Списать'),
    visual_control: $gettext('Вернуть'),
    shelf2box: $gettext('Проверить'),
  },
  weight_stowage: {
    box2shelf: $gettext('Разместить'),
    shelf2box: $gettext('Собрать'),
    trash: $gettext('Списать'),
  },
  inventory_check_more: $gettext('Количество'),
  inventory_check_product_on_shelf: $gettext('Учет. кол-во'),
  check: $gettext('Учет. кол-во'),
  check_final: $gettext('Учет. кол-во'),
  // виртуальные типы документа
  view: $gettext('Количество'),
  shelfCollector: $gettext('Разместить'),
  shelf: $gettext('На полке'),
  pack: $gettext('Количество'),
  repacking: {
    trash2shelf: $gettext('Списать'),
    repacking2box: $gettext('Собрать'),
    repacking2shelf: $gettext('Разместить'),
  },
});

export const inputCountTitlesDone = gettextWrapper({
  default: $gettext('Количество'),
  check_valid_regular: {
    box2shelf: $gettext('Возвращено'),
    shelf2box: $gettext('Проверено'),
    write_off: $gettext('Списано'),
  },
  writeoff_prepare_day: {
    box2shelf: $gettext('Возвращено'),
    shelf2box: $gettext('Проверено'),
    write_off: $gettext('Списано'),
  },
  check_valid_short: {
    box2shelf: $gettext('Возвращено'),
    shelf2box: $gettext('Проверено'),
    write_off: $gettext('Списано'),
  },
  sale_stowage: {
    box2shelf: $gettext('Размещено'),
    shelf2box: $gettext('Возвращено'),
    trash: $gettext('Количество'),
  },
  acceptance: $gettext('Принято'),
  writeoff: $gettext('Списываем'),
  check_more: $gettext('Количество'),
  refund: {
    box2shelf: $gettext('Размещено'),
    shelf2box: $gettext('Возвращено'),
    trash: $gettext('Списано'),
  },
  part_refund: $gettext('Размещено'),
  shipment: {
    shelf2box: $gettext('Отгружено'),
    box2shelf: $gettext('Размещено'),
  },
  shipment_rollback: {
    box2shelf: $gettext('Размещено'),
    shelf2box: $gettext('Возвращено'),
    trash: $gettext('Списано'),
  },
  hand_move: $gettext('Кол-во'),
  kitchen_provision: $gettext('Кол-во'),
  robot_provision: $gettext('Кол-во'),
  visual_control: {
    box2shelf: $gettext('Списано'),
    visual_control: $gettext('Вернуть'),
    shelf2box: $gettext('Проверено'),
  },
  weight_stowage: {
    box2shelf: $gettext('Размещено'),
    shelf2box: $gettext('Собрано'),
    trash: $gettext('Списано'),
  },
  inventory_check_more: $gettext('Количество'),
  inventory_check_product_on_shelf: $gettext('Факт./Учет. кол-во'),
  check: $gettext('Факт. кол-во'),
  check_final: $gettext('Факт. кол-во'),
  // виртуальные типы документа
  moving: $gettext('Собрано'),
  shelfCollector: $gettext('Размещено'),
  shelf: $gettext('На полке'),
  pack: $gettext('Количество'),
  repacking: {
    trash2box: $gettext('Списано'),
    trash2shelf: $gettext('Списано'),
    repacking2box: $gettext('Собрано'),
    repacking2shelf: $gettext('Размещено'),
  },
});

interface Option {
  suggest?: Suggest;
  docType: DocTypes;
}
export const getCountTitle = ({ docType, suggest }: Option) => {
  if (!suggest) {
    return inputCountTitles[docType];
  }
  const result = inputCountTitles[docType];
  if (typeof result === 'object') {
    return result[suggest?.vars.stage!] || result[suggest.type] || inputCountTitles.default;
  }
  return result || inputCountTitles.default;
};

export const getCountTitleDone = ({ docType, suggest }: Option) => {
  if (!suggest) {
    return inputCountTitles[docType];
  }
  const result = inputCountTitlesDone[docType] || inputCountTitlesDone.default;
  if (typeof result === 'object') {
    return result[suggest?.vars.stage!] || result[suggest.type] || inputCountTitlesDone.default;
  }
  return result || inputCountTitlesDone.default;
};
