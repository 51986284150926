import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import { logger } from '@/temp/plugins/logs';
import { TrueMark } from '@/temp/services/true-mark';
import { useLoader } from '@/ui/common/loader/useLoader';

/**
 * Проверяет, что марка ЧЗ валидна
 * @param mark
 * @param order_id
 * @return Promise<boolean>
 */
export const checkTrueMark = async (mark: string, order_id?: OrderOrderRetail['order_id']): Promise<boolean> => {
  const { showLoader } = useLoader();
  const { closeLoader } = showLoader();
  try {
    await TrueMark.check({
      true_mark: mark,
      order_id: order_id,
    });
    return true;
  } catch (error) {
    logger.error(error, {
      method: 'checkTrueMark',
      type: 'order_retail',
    });
    return false;
  } finally {
    closeLoader();
  }
};
