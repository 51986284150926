import Alert from '@/assets/sound/alert.m4a';
import Error from '@/assets/sound/error.m4a';
import MultiItem from '@/assets/sound/multi-item.m4a';
import NewOrderAlert from '@/assets/sound/new_order_alert.m4a';
import { ModeService } from '@/services/mode.service';

const alert = new Audio(Alert);
const error = new Audio(Error);
const newOrderAlert = new Audio(NewOrderAlert);
// звук для информирования о том, что текущая посылка является составной при проведении приемке маркета
const multiItem = new Audio(MultiItem);

const play = async (audio) => {
  if (ModeService.isDevelopment()) return;
  try {
    //  при разработке локально вытекают уши от звука новых заказов
    await audio.play();
  } catch (e) {
    console.error(e);
  }
};

export const AudioService = {
  // если параллельно что то воспроизводится, до мы получим непойманную ошибку.
  playAlert() {
    play(alert);
  },
  playError() {
    play(error);
  },
  playNewOrderAlert() {
    play(newOrderAlert);
  },
  playMultiItem() {
    play(multiItem);
  },
};
