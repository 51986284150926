import { PlatformService } from '@/fsd/data/services/platform.service';
import PickerAppUpdater from '@/fsd/features/tools/useUpdate/updatePickerApp/PickerAppUpdater.vue';
import { callAction } from '@/fsd/shared/ui/action';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { logger } from '@/temp/plugins/logs';
import { App } from '@capacitor/app';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';
import { LAST_APP_VERSION } from '@/fsd/features/tools/useUpdate/updatePickerApp/pickerAppVersion';

// Вероятно нужно править это место руками, чтобы вызвать обновление.

const checkNeedUpdate = async () => {
  if (!PlatformService.isCapacitor() || !useUser().experimentByName(experiments.exp_update_cap_picker_app))
    return false;
  try {
    const { version } = await App.getInfo();
    const [lastMajor, lastMinor] = LAST_APP_VERSION.split('.');
    const [curMajor, curMinor] = version.split('.');
    return +curMajor < +lastMajor || +curMinor < +lastMinor;
  } catch (e) {
    logger.error(e);
    return false;
  }
};

export const showUpdateWindow = () => {
  callAction(PickerAppUpdater);
};

export const usePickerAppUpdate = () => {
  const { isAuthenticated } = storeToRefs(useUser());

  watch(isAuthenticated, (val) => {
    if (!val) return;
    checkNeedUpdate().then((r) => {
      if (r) showUpdateWindow();
    });
  });
};
