<template>
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="0.5"
      width="40"
      height="40"
      rx="20"
      fill="#FA6A3C"
    />
    <path
      d="M11.4725 22.8543C11.0873 22.7053 10.829 22.3338 10.8536 21.9215C11.1923 16.2437 15.7099 11.6983 21.3546 11.3544C21.7683 11.3292 22.1407 11.5892 22.2887 11.9763L28.2664 27.6069C28.5676 28.3952 27.7983 29.1689 27.0144 28.866L22.7619 27.2212C22.2842 27.0364 21.7709 27.3906 21.7709 27.9054V28.2002C21.7709 28.605 21.4442 28.9335 21.0417 28.9335C20.6392 28.9335 20.3126 28.605 20.3126 28.2002V27.029C20.3126 26.5736 20.034 26.1659 19.6111 26.0024C18.8943 25.7252 18.125 26.2568 18.125 27.029V28.9335C18.125 29.3383 17.7983 29.6668 17.3958 29.6668C16.9933 29.6668 16.6667 29.3383 16.6667 28.9335V26.3742C16.6667 25.4641 16.1096 24.6479 15.2645 24.3208L11.4725 22.8543ZM22.7619 24.4599C23.5676 24.4599 24.2202 23.8035 24.2202 22.9932C24.2202 22.1829 23.5676 21.5265 22.7619 21.5265C21.9561 21.5265 21.3035 22.1829 21.3035 22.9932C21.3035 23.8035 21.9561 24.4599 22.7619 24.4599ZM20.5313 16.9208C21.1351 16.9208 21.6251 16.428 21.6251 15.8208C21.6251 15.2129 21.1351 14.7208 20.5313 14.7208C19.9276 14.7208 19.4376 15.2129 19.4376 15.8208C19.4376 16.428 19.9276 16.9208 20.5313 16.9208ZM18.8542 20.1335C18.8542 18.9184 17.8749 17.9335 16.6667 17.9335C15.4584 17.9335 14.4791 18.9184 14.4791 20.1335C14.4791 21.3486 15.4584 22.3335 16.6667 22.3335C17.8749 22.3335 18.8542 21.3486 18.8542 20.1335Z"
      fill="white"
    />
  </svg>
</template>
