import EventService from '@/services/event.service';
import { useUser } from '@/store/modules/user';
import { computed, watch } from 'vue';

export const useUpdates = () => {
  const userStore = useUser();
  let unSub = () => {};

  const _store_id = computed(() => userStore.storeId);

  const subscribeByStore = () => {
    if (!useUser().user) return false;
    if (userStore.storeId) {
      unSub = EventService.subscribe(['config', 'store', userStore.storeId], (code, events) => {
        if (code === 'OK' && events?.length) {
          events.find((e) => {
            if (e.type === 'config' && e.group === 'frontend_version' && e.name === 'tsd_frontend_version') {
              useUser().loadOptions();
              return true;
            }
          });
        }
      });
    }
  };

  watch(
    _store_id,
    (value) => {
      {
        if (value) {
          subscribeByStore();
        } else {
          unSub();
        }
      }
    },
    { immediate: true },
  );
};
