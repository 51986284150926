<template>
  <div
    data-test="suggest-details rows container"
    class="flex flex-col gap-4 w-full"
  >
    <ShelfRow
      :order="order"
      :suggest="suggest"
      :model="model"
      :step="step"
    />
    <CountRow
      :order="order"
      :product="product"
      :suggest="suggest"
      :model="model"
      :step="step"
    />
    <ResultCountRow
      :order="order"
      :product="product"
      :suggest="suggest"
      :model="model"
      :step="step"
    />
    <QuantsRow
      :order="order"
      :product="product"
    />
    <ReasonRow
      :reasons="reasons"
      :model="model"
      :step="step"
    />
    <ValidRow
      :order="order"
      :product="product"
      :suggest="suggest"
      :model="model"
      :step="step"
      :mode="mode"
    />
    <ExpirationDateRow
      :order="order"
      :product="product"
      :suggest="suggest"
      :step="step"
    />
    <WriteoffForRow
      :order="order"
      :product="product"
      :suggest="suggest"
      :step="step"
    />
    <ProductOnShelfRow
      :order="order"
      :product="product"
      :suggest="suggest"
      :model="model"
    />
    <WeightsRow :suggest="suggest" />
    <ResultWeightsRow
      :order="order"
      :suggest="suggest"
      :model="model"
      :step="step"
    />
  </div>
</template>

<script lang="ts">
import BaseOrder from '@/models/orders/BaseOrder';
import Product from '@/models/Product';
import Suggest from '@/models/Suggest';
import ReasonRow from '@/ui/common/suggest-details/data-rows/reason-row.vue';
import { Model } from '@/ui/common/suggest-details/types';
import { ValidTypeEnum } from '@/views/ProductCheck/types';
import { defineComponent, PropType } from 'vue';
import CountRow from './count-row.vue';
import ExpirationDateRow from './expiration-date-row.vue';
import ProductOnShelfRow from './ProductOnShelfRow.vue';
import QuantsRow from './quants-row.vue';
import ResultCountRow from './result-count-row.vue';
import ResultWeightsRow from './result-weights-row.vue';
import ShelfRow from './shelf-row.vue';
import ValidRow from './valid-row.vue';
import WeightsRow from './weights-row.vue';
import WriteoffForRow from './writeoff-for-row.vue';

interface Reasons {
  [key: string]: string;
}

export default defineComponent({
  components: {
    ResultCountRow,
    CountRow,
    ValidRow,
    ShelfRow,
    ExpirationDateRow,
    WriteoffForRow,
    ProductOnShelfRow,
    ReasonRow,
    QuantsRow,
    WeightsRow,
    ResultWeightsRow,
  },
  props: {
    order: {
      type: Object as PropType<BaseOrder>,
      required: true,
    },
    suggest: {
      type: Object as PropType<Suggest>,
      required: true,
    },
    product: {
      type: Object as PropType<Product>,
      default: undefined,
    },
    model: {
      type: Object as PropType<Model>,
      default: undefined,
    },
    reasons: {
      type: Object as PropType<Reasons>,
      required: true,
    },
    step: {
      type: String,
      required: true,
    },
    mode: {
      type: String as PropType<ValidTypeEnum>,
      default: ValidTypeEnum.expirationDate,
    },
  },
});
</script>
