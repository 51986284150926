export enum ShelfStatusEnum {
  'missing' = 'missing',
  'in-stock' = 'in-stock',
  'not-processed' = 'not-processed',
  'waiting-products-scan' = 'waiting-products-scan',
  'stock-scanned' = 'stock-scanned',
  'empty-shelf' = 'empty-shelf',
}

export enum HistoryTypeEnum {
  input = 'input',
  scan = 'scan',
}

export enum HistoryStatusEnum {
  active = 'active',
  deleted = 'deleted',
}

export interface HistoryItem {
  time: number;
  count: number;
  type: HistoryTypeEnum;
  status: HistoryStatusEnum;
  product_id: string;
}

export interface ProductStock {
  product_id: string;
  count: number;
  accounting_quantity?: number;
  history: HistoryItem[];
}

export interface ShelfStock {
  [key: string]: ProductStock;
}
