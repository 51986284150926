<template>
  <Layout>
    <template #header>
      <SuggestDetailsHeader
        :suggest="suggest"
        :order="order"
        :collected="collected.length"
        @close="$emit('close')"
        @remove-suggest="$emit('removeSuggest', suggest)"
        @open-suggest-menu="$emit('openSuggestMenu', suggest)"
      />
    </template>
    <template #default>
      <SuggestDetails
        :collected="collected"
        :product="suggest.product"
        :suggest="suggest"
      />
    </template>
    <template #footer>
      <SuggestDetailsFooter
        :suggest="suggest"
        :collected="collected"
        @suggest-done="$emit('suggestDone', suggest)"
        @remove-products="$emit('removeProducts', suggest)"
        @change-products="$emit('changeProducts', suggest.suggest_id)"
        @open-manual-input="$emit('openManualInput', suggest.suggest_id)"
      />
    </template>
  </Layout>
</template>

<script setup lang="ts">
import { checkNeedTrueMarkModal, errorTrueMarkModal } from '@/fsd/entities/modals/errorTrueMarkModal';
import { SuggestDetails } from '@/fsd/entities/suggest';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Product from '@/models/Product';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';
import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import { SignalEnum } from '@/services/requests';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { $gettext } from '@/temp/plugins/gettext';
import Layout from '@/ui/common/layout.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import { checkTrueMark } from '@/views/OrderRetail/tools/checkTrueMark';
import { computed, watch } from 'vue';
import SuggestDetailsFooter from './SuggestDetailsFooter.vue';
import SuggestDetailsHeader from './SuggestDetailsHeader.vue';

const productsStore = useProducts();
const ordersStore = useOrders();
const { showLoader } = useLoader();

interface SuggestDetailsProps {
  order: OrderOrderRetail;
  suggest_id: Suggest['suggest_id'];
  collected: string[];
}

const props = defineProps<SuggestDetailsProps>();

const suggest = computed(
  () => props.order.suggests.find(s => s.suggest_id === props.suggest_id) as Suggest | TrueMarkSuggestWrapper,
);

const checkTrueMarkSuggests = computed(() => {
  if (TrueMarkSuggestWrapper.isTrueMarkSuggestWrapper(suggest.value)) return suggest.value.suggests;

  return [];
});

const emits = defineEmits<{
  (e: 'close'): void;
  (e: 'removeSuggest', suggest: Suggest | TrueMarkSuggestWrapper): void;
  (e: 'openSuggestMenu', suggest: Suggest | TrueMarkSuggestWrapper): void;
  (e: 'updateCollected', suggest: Suggest | TrueMarkSuggestWrapper, barcodes: string[]): void;
  (e: 'removeProducts', suggest: Suggest | TrueMarkSuggestWrapper): void;
  (e: 'suggestDone', suggest: Suggest | TrueMarkSuggestWrapper): void;
  (e: 'changeProducts', suggest_id: Suggest['suggest_id']): void;
  (e: 'openManualInput', suggest_id: Suggest['suggest_id']): void;
}>();

const addProduct = async (suggest: Suggest, barcode: string): Promise<void> => {
  const confirm = await Modal.show({
    title: $gettext('Добавить еще этого товара?'),
    text: $gettext('Этот товар уже полностью собран. Вы уверены, что хотите добавить ещё этого товара?'),
    btnPosition: ButtonPositionsEnum.horizontal,
    closeBtnTitle: $gettext('Отмена'),
    confirmBtnTitle: $gettext('Добавить'),
  });

  if (confirm && suggest.result_count) {
    if (TrueMarkSuggestWrapper.isTrueMarkSuggestWrapper(suggest)) {
      await addTrueMarkProduct(suggest.product_id, barcode);
    } else {
      emits('updateCollected', suggest, [barcode]);
    }
  }
};

const addTrueMarkProduct = async (product_id: Product['product_id'], true_mark: string): Promise<void> => {
  const { closeLoader } = showLoader();

  const isSuccess = await checkTrueMark(true_mark, props.order.order_id);
  if (!isSuccess) return;

  try {
    await ordersStore.signal({
      order_id: props.order.order_id,
      signal: SignalEnum.more_product,
      data: {
        product_id,
        count: 1,
        true_mark,
        status: SuggestStatusEnum.done,
      },
    });
    await new Promise<void>((resolve) => {
      const unWatch = watch(checkTrueMarkSuggests, (suggests) => {
        for (const s of suggests) {
          if (s.status === SuggestStatusEnum.done && s.vars.true_mark === true_mark) {
            resolve();
            unWatch();
            return;
          }
        }
      });
    });
  } catch (e: any) {
    const response = e?.response;

    if (checkNeedTrueMarkModal(response?.data?.code)) {
      errorTrueMarkModal({
        code: response?.data?.code,
        status: response?.data?.true_mark_status,
      });
    }
  } finally {
    closeLoader();
  }
};

useRequestBarcode(async (barcode) => {
  const { closeLoader } = showLoader();
  try {
    const product = await productsStore.getProductByBarcode(barcode);
    if (suggest.value.product_id !== product.product_id) {
      Alerts.error($gettext('Отсканирован другой товар'));
      return true;
    }

    if (
      (suggest.value.isFull || suggest.value.isOver)
      && !suggest.value.product?.isTrueWeight
      && props.order.isReplaceItemsMethod
    ) {
      closeLoader();
      await addProduct(suggest.value, barcode);
      return true;
    }

    if (suggest.value.isDone && suggest.value.isFull) {
      Alerts.error($gettext('Данный продукт уже собран'));
      return true;
    }
    emits('updateCollected', suggest.value, [barcode]);

    return true;
  } catch {
    Alerts.error($gettext('Отсканирован неверный баркод'));
    return true;
  } finally {
    closeLoader();
  }
});
</script>
