<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7432_8733)">
      <path
        d="M6.3335 4.25L10.0835 8L6.3335 11.75"
        :stroke="isColor ? colorValue : 'currentColor'"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7432_8733">
        <rect
          width="16"
          height="16"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script setup lang="ts">
import { useColor } from '@/fsd/shared/hooks';

const { color, isColor = true } = defineProps<{
  color?: string;
  isColor?: boolean;
}>();
const { colorValue } = useColor({ color: () => color });
</script>
