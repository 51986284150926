<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="40"
      height="40"
      rx="20"
      :fill="bgColorValue"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5002 15.8334C12.0399 15.8334 11.6668 16.2065 11.6668 16.6667V17.5H28.3335V16.6667C28.3335 16.2065 27.9604 15.8334 27.5002 15.8334H12.5002ZM10.8335 20C10.8335 19.0796 11.5797 18.3334 12.5002 18.3334H20.0002H21.6668H26.6668C28.0475 18.3334 29.1668 19.4527 29.1668 20.8334V23.9941C29.1668 24.1046 29.1229 24.2106 29.0448 24.2887L28.5776 24.756C28.4213 24.9122 28.2093 25 27.9883 25H22.0603C21.8015 25 21.5463 24.9398 21.3149 24.8241L20.3521 24.3427C20.1206 24.2269 19.8655 24.1667 19.6067 24.1667H18.7209C19.0045 23.6764 19.1668 23.1072 19.1668 22.5C19.1668 20.6591 17.6744 19.1667 15.8335 19.1667C13.9925 19.1667 12.5002 20.6591 12.5002 22.5C12.5002 23.1072 12.6625 23.6764 12.9461 24.1667H12.5002C11.5797 24.1667 10.8335 23.4205 10.8335 22.5V20ZM24.1668 21.6667C24.1668 22.1269 23.7937 22.5 23.3335 22.5C22.8733 22.5 22.5002 22.1269 22.5002 21.6667C22.5002 21.2065 22.8733 20.8334 23.3335 20.8334C23.7937 20.8334 24.1668 21.2065 24.1668 21.6667ZM27.9168 21.6667C27.9168 22.1269 27.5437 22.5 27.0835 22.5C26.6233 22.5 26.2502 22.1269 26.2502 21.6667C26.2502 21.2065 26.6233 20.8334 27.0835 20.8334C27.5437 20.8334 27.9168 21.2065 27.9168 21.6667ZM18.3335 22.5C18.3335 23.8808 17.2142 25 15.8335 25C14.4528 25 13.3335 23.8808 13.3335 22.5C13.3335 21.1193 14.4528 20 15.8335 20C17.2142 20 18.3335 21.1193 18.3335 22.5Z"
      :fill="colorValue"
    />
  </svg>
</template>
<script setup lang="ts">
import { useColor } from '@/fsd/shared/hooks';

interface Props {
  bgColor?: string;
  color?: string;
}

const { bgColor = 'blue-normal', color = 'white' } = defineProps<Props>();
const params = {
  color: () => color,
  bgColor: () => bgColor,
};
const { colorValue, bgColorValue } = useColor(params);
</script>
