<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.99446 1.08283C3.27616 1.41804 2.81152 1.95666 2.81152 2.67587L4.02478 7.52884C4.24925 8.42676 5.48541 9.03265 6.29859 9.19572V10.6267C6.29859 10.6414 6.29908 10.656 6.29991 10.6705C5.09599 10.8251 4.20621 11.3714 4.20621 12.0216C4.20621 12.7921 5.45527 13.4166 6.99606 13.4166C8.53683 13.4166 9.78593 12.7921 9.78593 12.0216C9.78593 11.3714 8.89617 10.8251 7.6922 10.6705C7.69304 10.656 7.69352 10.6414 7.69352 10.6267V9.19579C8.50677 9.03272 9.74303 8.42676 9.96748 7.52884L11.1809 2.67539C11.1807 1.95629 10.716 1.418 9.99775 1.08283C9.29045 0.752737 8.27863 0.583252 6.99613 0.583252C5.71355 0.583252 4.70181 0.752737 3.99446 1.08283ZM4.34834 1.84141C4.89641 1.58564 5.76775 1.42035 6.99606 1.42035C8.2243 1.42035 9.09571 1.58564 9.64378 1.84141C10.1809 2.09206 10.3438 2.39065 10.3438 2.67576C10.3438 3.87434 7.34786 3.85688 6.65095 3.82732L6.99599 5.04698L5.94979 6.44191L6.29991 7.83685L4.90359 6.44191L5.60105 5.04698L4.83164 3.54159C3.84812 3.27016 3.11059 2.41901 4.34834 1.84141Z"
      :fill="isColor ? colorValue : 'currentColor'"
    />
  </svg>
</template>
<script setup lang="ts">
import { useColor } from '@/fsd/shared/hooks';

const { color, isColor = true } = defineProps<{
  color?: string;
  isColor?: boolean;
}>();
const { colorValue } = useColor({ color: () => color });
</script>
