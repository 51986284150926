import { mergeTrueMarkSuggests, unMergeTrueMarkSuggests } from '@/fsd/entities/suggest/tools/mergeTrueMarkSuggests';
import Suggest from '@/models/Suggest';
import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';
import { Signal } from '@/models/orders/BaseOrder';
import { useOrders } from '@/store/modules/orders';

/**
 * Находит все сигналы, с помощью которых были созданы продукты
 * @param suggest
 */
export const getParentSignals = (suggest: Suggest | TrueMarkSuggestWrapper): Signal[] => {
  const signals: Signal[] = [];

  const order = useOrders().orderById(suggest.order_id);

  if (!order) return signals;

  const suggests = unMergeTrueMarkSuggests([suggest]);

  order.signals.forEach((signal) => {
    suggests.forEach((suggest) => {
      if (suggest.vars.sigid === signal.sigid && signal.data.parent_suggest_id) signals.push(signal);
    });
  });

  return signals;
};

/**
 * Находит родительский саджест
 * @param suggest
 */
export const getParentSuggest = (
  suggest: Suggest | TrueMarkSuggestWrapper,
): Suggest | TrueMarkSuggestWrapper | undefined => {
  const signal = getParentSignals(suggest);
  const suggests = unMergeTrueMarkSuggests([suggest]);

  const parentSuggests: Suggest[] = [];

  signal.forEach((signal) => {
    suggests.forEach((suggest) => {
      if (signal.data.parent_suggest_id === suggest.suggest_id) parentSuggests.push(suggest);
    });
  });

  return mergeTrueMarkSuggests(parentSuggests)[0];
};

/**
 * Возвращает true, если саджест был создан с помощью сигнала more_product
 * @param suggest
 */
export const isChildSuggest = (suggest: Suggest | TrueMarkSuggestWrapper): boolean => {
  return !!getParentSignals(suggest).length;
};
