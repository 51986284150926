<template>
  <DevtoolsSection>
    <template #title>
      Запросы
    </template>
    <Log
      v-for="(request, index) in requests"
      :key="index"
      :date="request.date"
      :status="request.statusCode"
      :duration="request.duration"
      :content="request.path"
      more-info
      @more-info="visibleLog = request"
    />
  </DevtoolsSection>

  <LogDetails
    v-if="visibleLog"
    class="fixed top-0 left-0 w-screen h-screen z-10"
    :log="visibleLog"
    @close="visibleLog = undefined"
  />
</template>

<script setup lang="ts">
import LogDetails from '@/devtools/sections/log-details.vue';
import Log from '@/devtools/sections/log.vue';
import { RequestLog } from '@/devtools/sections/requests/requestLog';
import DevtoolsSection from '@/devtools/sections/section/devtools-section.vue';
import { useDevtoolsStore } from '@/devtools/store/devtoolsModule';
import { ref } from 'vue';

const { requests } = useDevtoolsStore();
const visibleLog = ref<RequestLog>();
</script>
