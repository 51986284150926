<template>
  <div
    class="backdrop-blur-md blur-container fixed top-5 left-0 w-screen h-[calc(100vh-20px)] overflow-hidden rounded-2xl z-10"
    @click.self="$emit('click')"
  >
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'Blur',
  emits: ['click'],
});
</script>

<style scoped>
.blur-container {
  background: rgba(0, 0, 0, 0.5);
}
</style>
