export enum BadgeSizeEnum {
  small = 'small',
  medium = 'meduim',
  large = 'large',
}

export interface BadgeBase {
  size?: BadgeSizeEnum;
  title?: string;
  titleClass?: string;
  disabled?: boolean;
  uppercase?: boolean;
  containerClass?: string;
  iconLeftClass?: string;
  iconRightClass?: string;
  dataTest?: string;
  onClick?: () => any;
}
