import { api } from '@/fsd/data/api/api.service';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import ClientOrder from '@/models/orders/ClientOrder';
import { SignalEnum } from '@/services/requests';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import { useLoader } from '@/ui/common/loader/useLoader';

/**
 * Хук, который позволяет открывать крышку ровера по ордеру
 */
export function useOpenRoverHatch() {
  const { showLoader } = useLoader();

  const sendOpenHatchSignal = async (order: ClientOrder) => {
    const { closeLoader } = showLoader();
    try {
      await api.order.signal({
        order_id: order.order_id,
        signal: SignalEnum.rover_open_hatch,
      });
    } finally {
      closeLoader();
    }
  };

  const openRoverHatch = async (order: ClientOrder) => {
    const confirmed = await Notifications.confirmBottom({
      title: $gettext('Вы уверены, что собрали заказ и хотите открыть ровер?'),
    });
    if (!confirmed) return;
    try {
      await sendOpenHatchSignal(order);
    } catch {
      Alerts.error($gettext('Произошла ошибка при открытии ровера'));
      return;
    }
  };

  return { openRoverHatch };
}
