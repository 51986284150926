<template>
  <DevtoolsSection>
    <template #title>
      Баркоды
    </template>
    <Log
      v-for="(barcode, index) in devtools.barcodes"
      :key="index"
      data-test="barcode-row"
      :date="barcode.date"
      :content="barcode.barcode"
    />
  </DevtoolsSection>
</template>

<script setup lang="ts">
import Log from '@/devtools/sections/log.vue';
import DevtoolsSection from '@/devtools/sections/section/devtools-section.vue';
import { useDevtoolsStore } from '@/devtools/store/devtoolsModule';

const devtools = useDevtoolsStore();
</script>
