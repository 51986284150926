import { checkNeedTrueMarkModal, errorTrueMarkModal } from '@/fsd/entities/modals/errorTrueMarkModal';
import retryBox2ShelfModal from '@/fsd/entities/modals/retryBox2ShelfModal';
import { prepareBarcodes, useShelf2Box } from '@/fsd/entities/suggest/tools/useShelf2Box';
import Product from '@/models/Product';
import { checkTrueMark } from '@/views/OrderRetail/tools/checkTrueMark';
import { Shelf2BoxRetail } from '@/views/OrderRetail/types';

/**
 * Закрывает саджест в 0 или в определенный count, в зависимости от переданных свойств
 * @param payload
 */
export const shelf2BoxRetail = async ({ suggest, barcodes = [], true_mark, reason, valid }: Shelf2BoxRetail): Promise<boolean> => {
  let count: number;

  if (suggest.conditions.need_true_mark) {
    count = true_mark ? 1 : 0;
  } else if (suggest.product?.isTrueWeight) {
    count = Product.weightFromBarcode(barcodes);
  } else {
    count = barcodes.length;
  }

  if (suggest.conditions.need_true_mark && true_mark) {
    const isSuccess = await checkTrueMark(true_mark, suggest.order_id);
    if (!isSuccess) return false;
  }

  return await useShelf2Box(
    suggest.order_id,
    {
      suggest_id: suggest.suggest_id,
      true_mark: suggest.conditions.need_true_mark && suggest.result_count ? suggest.vars?.true_mark : true_mark,
      barcodes: !suggest.conditions.need_true_mark && barcodes.length ? prepareBarcodes(barcodes) : undefined,
      count,
      reason,
      valid,
    },
    {
      onRequestError: async (e, retry) => {
        const response = e.response;

        if (checkNeedTrueMarkModal(response?.data?.code)) {
          await errorTrueMarkModal({
            code: response?.data?.code,
            status: response?.data?.true_mark_status,
          });
          return false;
        }

        const confirmed = await retryBox2ShelfModal(e);
        if (!confirmed) {
          return false;
        }
        return retry();
      },
    },
  );
};
