import { axiosForApi, setImgUrl } from '@/fsd/data/api/api.service';
import { UserLanguageEnum } from '@/models/User';
import EventService from '@/services/event.service';
import { ModeService } from '@/services/mode.service';
import { useUser } from '@/store/modules/user';

declare global {
  interface Window {
    ym: any;
  }
}

export interface QueryParams {
  lang?: UserLanguageEnum;
  event?: string;
  img?: string;
  api?: string;
  token?: string;
  company?: string;
  // признак того, что мы запускаемся на капаситоре
  cap?: boolean;
  // признак того, что мы запускаемся в PRO
  pro?: boolean;
}

// единая точка сохранения и примениния параметров из квери, нужны для конфигурирования приложения.
// Нужна для кастомизации приложения
// предполагаемый вариант использования:
// создается новая апк, где все отличия передаются в приложение через квери.
// например: английский язык по умолчанию и нейтральная страница логина в б2б

const parseUrl = (): QueryParams => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const params: QueryParams = {};
  for (const [key, value] of urlParams.entries()) {
    params[key] = value;
  }
  return params;
};

export const initParams = async () => {
  const params = parseUrl();
  const userStore = useUser();
  userStore.setQueryParams(params);

  // язык
  if (params.lang) {
    userStore.setLanguageMutation(params.lang);
  } else if (params.company && params.company !== 'yandex') {
    userStore.setLanguageMutation(UserLanguageEnum.en_EN);
  }
  // адрес ивент сервера
  if (params.event) {
    localStorage.setItem('event', params.event);
    EventService.url = params.event;
  }
  // заглушка для загрузки картинок
  if (params.img) {
    localStorage.setItem('img', params.img);
    setImgUrl(params.img);
  }

  if (params.cap) {
    localStorage.setItem('isCapacitor', 'true');
  } else {
    localStorage.removeItem('isCapacitor');
  }

  if (params.pro) {
    localStorage.setItem('isPro', 'true');
  } else {
    localStorage.removeItem('isPro');
  }

  // адрес апи
  if (params.api) {
    localStorage.setItem('api', params.api);
    axiosForApi.defaults.baseURL = params.api;
  }
  // токен пользователя
  if (params.token) {
    await userStore.setTokenAction(params.token);
  } else if (userStore.user && ModeService.isDevelopment()) {
    await userStore.setTokenAction(userStore.user.token);
  }

  // подключаем метрику
  if (ModeService.isProduction() && (!params.company || params.company === 'yandex')) {
    const head = document.head || document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://mc.yandex.ru/metrika/tag.js';
    head.appendChild(script);
    window.ym = function (...args) {
      window.ym.a = window.ym.a || [];
      window.ym.a.push(args);
    };
    window.ym.l = Date.now();
    // window.ym(90465422, 'init', {
    //   clickmap: true,
    //   trackLinks: true,
    //   accurateTrackBounce: true,
    //   webvisor: true,
    // });
  }
};
