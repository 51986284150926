<template>
  <RoundedCard24 class="flex flex-col">
    <IconWarning
      class="mx-2 mt-2"
      bg-color="orange-normal"
    />
    <Title4 class="font-medium mx-4 my-3">
      {{ $gettext('Ваш браузер устарел, его нужно обновить') }}
    </Title4>
    <Body2 class="mx-4">
      {{ $gettext('Это может занять до 20 минут. В это время приложение будет недоступно') }}
    </Body2>
    <RoundedCard16
      bg="bg-semantic-bg-minor"
      class="mx-3 px-4 mb-2 mt-4"
    >
      <InfoWithLabelVertical
        :label="$gettext('Текущая версия WebView')"
        :value="webViewVersion"
      />
      <Divider />
      <InfoWithLabelVertical
        :label="$gettext('Версия ОС')"
        :value="`Android ${osVersion}`"
      />
      <Divider />
      <InfoWithLabelVertical
        :label="$gettext('Архитектура процессора')"
        :value="cpu"
      />
      <Divider />
      <InfoWithLabelVertical
        label="WebView"
        :value="webViewName"
      />
    </RoundedCard16>
    <div class="m-2 flex gap-2">
      <UiButton
        background-color="secondary"
        @click="emits('close')"
      >
        Позже
      </UiButton>
      <UiButton
        background-color="primary"
        @click="emits('load')"
      >
        Обновить
      </UiButton>
    </div>
  </RoundedCard24>
</template>
<script setup lang="ts">
import IconWarning from '@/fsd/shared/icons/warning/icon-warning.vue';
import RoundedCard16 from '@/fsd/shared/ui/cards/RoundedCard16.vue';
import RoundedCard24 from '@/fsd/shared/ui/cards/RoundedCard24.vue';
import Divider from '@/fsd/shared/ui/divider/divider.vue';
import InfoWithLabelVertical from '@/fsd/shared/ui/output/InfoWithLabelVertical.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { Device } from '@capacitor/device';
import { scanner } from '@lavka/capacitor-scanner-plagin';
import { defineEmits, ref } from 'vue';

const emits = defineEmits<{
  (e: 'close'): void;
  (e: 'load'): void;
}>();
const webViewVersion = ref('');
const webViewName = ref('');
const osVersion = ref('');
const cpu = ref('');

Device.getInfo()
  .then((info) => {
    webViewVersion.value = info.webViewVersion;
    osVersion.value = info.osVersion;
  })
  .catch(console.error);
scanner
  .info()
  .then((info) => {
    cpu.value = info.cpu || 'default';
    webViewName.value = info.webViewName || '-';
  })
  .catch(console.error);
</script>
