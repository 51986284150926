<template>
  <Overlay @backdrop-click="$emit('close')">
    <div class="h-screen flex items-center justify-center">
      <div class="bg-background-main p-4 rounded-2xl my-auto mx-3 pointer-events-auto">
        <div class="flex items-center mb-3">
          <Typography type="h3">
            {{ $gettext('Перезапустите приложение') }}
          </Typography>
        </div>
        <Typography type="text1">
          {{ $gettext('Для приложения доступно обновление. Пожалуйста, полностью закройте его и запустите заново') }}
          <br>
          {{ currentVersion }}->{{ actualVersion }}
        </Typography>
        <div class="-m-2 mt-5">
          <UiButton
            class="mb-1"
            background-color="secondary"
            @click="onClose"
          >
            {{ $gettext('Позже') }}
          </UiButton>
          <UiButton
            background-color="primary"
            data-test="restart btn"
            @click="onRestart"
          >
            {{ $gettext('Перезапустить приложение') }}
          </UiButton>
        </div>
      </div>
    </div>
  </Overlay>
</template>

<script lang="ts">
import { PlatformService } from '@/fsd/data/services/platform.service';
import { useUser } from '@/store/modules/user';
import { useLoader } from '@/ui/common/loader/useLoader';
import Overlay from '@/ui/common/overlay/overlay.vue';
import Typography from '@/ui/common/typography.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StaticVersionUpdater',
  components: {
    Overlay,
    Typography,
    UiButton,
  },
  props: {
    actualVersion: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  setup() {
    const { showLoader } = useLoader();
    const userStore = useUser();

    return {
      showLoader,
      userStore,
    };
  },
  computed: {
    currentVersion(): string {
      return this.userStore.version;
    },
  },
  methods: {
    onClose(): void {
      this.$emit('close');
    },
    onRestart(): void {
      const ts = Date.now();
      this.showLoader();
      localStorage.setItem('user', JSON.stringify(this.userStore.user));
      const token = this.userStore.user?.token;
      let reloadURl = window.location.origin + `?${ts}`;
      if (token) {
        reloadURl += `&token=${token}`;
      }
      if (PlatformService.isCapacitor()) reloadURl += '&cap=true';
      if (PlatformService.isPro()) reloadURl += '&pro=true';
      window.location.assign(reloadURl);
    },
  },
});
</script>
