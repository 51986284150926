<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="32"
      height="32"
      rx="16"
      fill="#FC9000"
    />
    <path
      d="M9.63749 23.9163H22.3708C23.8215 23.9163 24.75 22.8509 24.75 21.5376C24.75 21.1412 24.6505 20.7365 24.435 20.3648L18.0518 9.28063C17.6041 8.48772 16.8083 8.08301 16.0041 8.08301C15.2 8.08301 14.3959 8.49598 13.9482 9.28063L7.56502 20.373C7.34948 20.7447 7.25 21.1412 7.25 21.5376C7.25 22.8509 8.18676 23.9163 9.63749 23.9163ZM9.795 22.3305C9.28103 22.3305 8.94114 21.9093 8.94114 21.455C8.94114 21.3229 8.95772 21.1577 9.04062 21.009L15.2581 10.1644C15.4239 9.88356 15.714 9.75141 16.0041 9.75141C16.2943 9.75141 16.5761 9.88356 16.7337 10.1644L22.9594 21.0255C23.034 21.1659 23.0671 21.3229 23.0671 21.455C23.0671 21.9093 22.719 22.3305 22.205 22.3305H9.795ZM16.0041 18.1843C16.4601 18.1843 16.7254 17.9283 16.7337 17.4492L16.858 13.4764C16.8746 12.9891 16.5098 12.6422 15.9959 12.6422C15.4819 12.6422 15.1254 12.9809 15.142 13.4682L15.2581 17.4575C15.2746 17.9283 15.5399 18.1843 16.0041 18.1843ZM16.0041 20.9264C16.543 20.9264 16.9824 20.5382 16.9824 20.0179C16.9824 19.4893 16.5513 19.1094 16.0041 19.1094C15.4653 19.1094 15.0259 19.4975 15.0259 20.0179C15.0259 20.5382 15.4736 20.9264 16.0041 20.9264Z"
      fill="white"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
