<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_6635_9909)">
      <rect
        x="0"
        y="0"
        width="40"
        height="40"
        rx="20"
        fill="#575858"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="m20,26.875c3.797,0 6.875,-3.078 6.875,-6.875c0,-3.797 -3.078,-6.875 -6.875,-6.875c-3.797,0 -6.875,3.078 -6.875,6.875c0,3.797 3.078,6.875 6.875,6.875zm0,1.875c4.8325,0 8.75,-3.9175 8.75,-8.75c0,-4.8325 -3.9175,-8.75 -8.75,-8.75c-4.8325,0 -8.75,3.9175 -8.75,8.75c0,4.8325 3.9175,8.75 8.75,8.75zm1.25,-11.875c0,0.6904 -0.5596,1.25 -1.25,1.25c-0.6904,0 -1.25,-0.5596 -1.25,-1.25c0,-0.6904 0.5596,-1.25 1.25,-1.25c0.6904,0 1.25,0.5596 1.25,1.25zm-0.3125,3.75c0,-0.5178 -0.4197,-0.9375 -0.9375,-0.9375c-0.5178,0 -0.9375,0.4197 -0.9375,0.9375l0,3.125c0,0.5178 0.4197,0.9375 0.9375,0.9375c0.5178,0 0.9375,-0.4197 0.9375,-0.9375l0,-3.125z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_6635_9909"
        x="0"
        y="0"
        width="40"
        height="40"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.341176 0 0 0 0 0.345098 0 0 0 0 0.345098 0 0 0 0.35 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6635_9909"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6635_9909"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
