<template>
  <Bar
    :order="order"
    :caption="caption"
    :text="$gettext('Сборка заказа')"
    :custom-menu="true"
    :icons="{ left: 'close', right: 'info' }"
    @close-click="toHomePage"
    @menu-click="orderMenu"
  />
</template>

<script setup lang="ts">
import { OrderModal } from '@/fsd/features/order';
import { callAction } from '@/fsd/shared/ui/action';
import type OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import { $gettext } from '@/temp/plugins/gettext';
import Bar from '@/ui/common/bar/bar.vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

interface HeaderRetailProps {
  order: OrderOrderRetail;
}

const router = useRouter();

const props = defineProps<HeaderRetailProps>();

const caption = computed<string>(() => {
  if (!props.order) {
    return '';
  }
  if (props.order.isCanceled) {
    return $gettext('Возврат товаров на полки');
  }
  return `${$gettext('Заказ')} ${props.order.orderNumberForView}`;
});

defineEmits<{
  (e: 'back'): void;
  (e: 'finish'): void;
}>();

const orderMenu = async () => {
  await callAction(OrderModal, { order: props.order });
};

const toHomePage = (): Promise<any> => {
  return router.push({ name: 'home' });
};
</script>
