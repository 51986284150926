<template>
  <LayoutFooter
    v-if="!hasActiveConfirmSignal"
    class="flex-row"
  >
    <UiButton
      v-if="canBeReplaced"
      type="small"
      data-test="return-btn"
      class="mr-2"
      background-color="secondary"
      @click="emits('changeProducts')"
    >
      <template #icon>
        <IconPlus />
      </template>
      {{ $gettext('Замена') }}
    </UiButton>
    <UiButton
      v-if="canSuggestDone"
      type="small"
      data-test="approve-btn"
      class="mr-2"
      background-color="secondary"
      @click="emits('suggestDone')"
    >
      <template #icon>
        <Check />
      </template>
      {{ $gettext('Собрано') }}
    </UiButton>
    <template v-if="suggest.isDone">
      <UiButton
        v-if="isManualInputAllowed && suggest.isFull"
        type="small"
        class="mr-2"
        data-test="manual-edit-btn"
        background-color="secondary"
        @click="emits('openManualInput')"
      >
        <template #icon>
          <IconEditPen />
        </template>
        {{ $gettext('Изменить количество товара') }}
      </UiButton>
      <UiButton
        v-else-if="!suggest.product?.isTrueWeight && (suggest.isFull || suggest.isOver)"
        class="mr-2"
        type="small"
        data-test="delete-product-btn"
        background-color="secondary"
        @click="emits('removeProducts')"
      >
        <template #icon>
          <IconReturn />
        </template>
        {{ $gettext('Уменьшить количество товара') }}
      </UiButton>
    </template>
    <UiButton
      v-if="isManualInputButtonVisible"
      type="small"
      data-test="manual-input-btn"
      background-color="secondary"
      @click="$emit('openManualInput')"
    >
      <template #icon>
        <Edit v-if="suggest.product?.isTrueWeight" />
        <IconExit v-else />
      </template>
      {{ suggest.product?.isTrueWeight ? $gettext('Ввести вес') : $gettext('Указать кол-во') }}
    </UiButton>
  </LayoutFooter>
</template>

<script setup lang="ts">
import IconEditPen from '@/fsd/shared/icons/IconEditPen.vue';
import IconExit from '@/fsd/shared/icons/IconExit.vue';
import IconReturn from '@/fsd/shared/icons/icon-return.vue';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';
import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import { checkManualInputAllowed } from '@/views/OrderRetail/tools/checkManualInputAllowed';
import { useOrders } from '@/store/modules/orders';
import Check from '@/temp/icons/check.vue';
import Edit from '@/temp/icons/edit.vue';
import IconPlus from '@/temp/icons/icon-plus.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { computed, toRefs } from 'vue';

interface RetailFooterDetailsProps {
  suggest: Suggest | TrueMarkSuggestWrapper;
  collected: string[];
}

const props = defineProps<RetailFooterDetailsProps>();
const { suggest, collected } = toRefs(props);
const order = computed<OrderOrderRetail>(() => {
  return useOrders().orderById(suggest.value.order_id) as OrderOrderRetail;
});
const emits = defineEmits<{
  (e: 'suggestDone'): void;
  (e: 'changeProducts'): void;
  (e: 'removeProducts'): void;
  (e: 'openManualInput'): void;
}>();

const hasActiveConfirmSignal = computed((): boolean | undefined => {
  return (
    order.value.vars.confirming_assembled_products || order.value.vars.fulfilled_conditions?.confirm_assembled_products
  );
});

/**
 * True, если продукт может быть заменен
 */
const canBeReplaced = computed(() => {
  return !(suggest.value.isFull || suggest.value.isOver) && order.value.isReplaceItemsMethod;
});

const canSuggestDone = computed(() => {
  if (TrueMarkSuggestWrapper.isTrueMarkSuggestWrapper(suggest.value)) {
    return suggest.value.isRequest && suggest.value.isPartial;
  }
  return suggest.value.isRequest && collected.value.length;
});

/**
 * True, если для товара доступен ручной ввод количества
 */
const isManualInputAllowed = computed(() => checkManualInputAllowed(suggest.value));

/**
 * True, если отображается кнопка ручного ввода количества
 */
const isManualInputButtonVisible = computed(() => {
  return (
    isManualInputAllowed.value
    && (suggest.value.status !== SuggestStatusEnum.done || !suggest.value.result_count)
    && !props.collected.length
  );
});
</script>
