import Suggest from '@/models/Suggest';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import { useLoader } from '@/ui/common/loader/useLoader';
import { AxiosResponse } from 'axios';
import { reactive } from 'vue';

// null означает, что саджест никто не трогал
export type CollectedPackingSuggests = Map<Suggest['suggest_id'], number | null>;
const DEFAULT_COLLECTED_COUNT = null;

/*
TODO
  Потенциально, этот код можно будет использовать и в обычной сборке заказа
  Но сейчас я не рискну это делать, так как возможно, что старый флоу сборки мы вообще удалим
 */
export function useClosePackingSuggests(packingSuggests: Suggest[]) {
  // Тут мы храним информацию о том, сколько тех или иных саджестов удалось собрать
  const collected: CollectedPackingSuggests = reactive(new Map());

  // Проставляем дефолтные значения для саджетов
  const closePackingSuggests = async (): Promise<boolean | undefined> => {
    // создаем запросы shelf2box на саджесты с пакетами
    const promises = usePushShelf2BoxOnCollected(collected);
    // ждем запросы
    const responses = await settlePromises(promises);

    const allSuccess = responses.every(response => response.status === 'fulfilled' && response.value.status === 200);
    // если все саджесты завершились успешно - мы молодцы
    if (allSuccess) return true;
    // если нет - показываем ошибку
    await showModalError({
      onRepeat: closePackingSuggests,
      collected,
      packingSuggests,
      responses,
    });
  };

  packingSuggests.forEach(s => collected.set(s.suggest_id, DEFAULT_COLLECTED_COUNT));

  return {
    collected,
    closePackingSuggests,
  };
}

function usePushShelf2BoxOnCollected(collected: CollectedPackingSuggests) {
  const ordersStore = useOrders();
  const promises: Promise<AxiosResponse>[] = [];

  for (const [suggest_id, count] of collected.entries()) {
    const promise = ordersStore.shelf2box({
      suggest_id,
      count: count === null ? 0 : count,
    });
    promises.push(promise);
  }

  return promises;
}

async function settlePromises(promises: ReturnType<typeof usePushShelf2BoxOnCollected>) {
  const { closeLoader } = useLoader().showLoader(undefined);
  const responses = await Promise.allSettled<Promise<AxiosResponse>[]>(promises);
  closeLoader();
  return responses;
}

async function showModalError({
  onRepeat, collected, packingSuggests, responses,
}) {
  const products = Array.from(collected.keys())
    .filter((item, idx) => {
      return responses[idx].status === 'rejected' || responses[idx].value.status !== 200;
    })
    .map((suggest_id) => {
      return {
        label: useProducts().productById(packingSuggests.find(ps => ps.suggest_id === suggest_id).product_id)?.title!,
        count: collected.get(suggest_id),
      };
    });
  await new Promise(resolve =>
    Notifications.error.major.suggest({
      title: $gettext('Не удалось отправить информацию на сервер'),
      reason: 'Network error',
      body: '',
      onClose: () => {
        resolve(undefined);
      },
      onRepeat: async () => {
        await onRepeat();
        resolve(undefined);
      },
      operation: $gettext('Взять с полки'),
      products,
    }),
  );
}
