<template>
  <div
    data-test="count-field-view container"
    class="flex-col w-full"
  >
    <Hint
      v-if="hintText"
      class="my-2"
    >
      {{ hintText }}
    </Hint>

    <div class="flex">
      <div
        class="w-full h-14 flex justify-between p-4 items-center bg-semantic-bg-control-minor rounded-2xl"
        data-test="count-field-view value"
      >
        <Title4 class="text-day-text inline-block">
          {{ count }}
        </Title4>
        <div
          v-if="unit"
          class="text-day-textMinor"
        >
          {{ unit }}
        </div>
      </div>
      <UiButton
        data-test="count-field-view continue"
        is-icon
        class="!h-14 !w-14 ml-2 disabled:bg-secondary disabled:fill-text-secondary fill-text-primary"
        @click="$emit('cancel')"
      >
        <IconCheck class="fill-inherit" />
      </UiButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { QuantUnitEnum } from '@/models/Product';
import { getQuantUnit } from '@/temp/constants/translations/quantUnits';
import IconCheck from '@/temp/icons/icon-check.vue';
import Hint from '@/ui/common/hint/hint.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { computed } from 'vue';

interface CountFieldViewProps {
  hintText?: string;
  count: number;
  quantUnit?: QuantUnitEnum;
}
defineEmits<{ (e: 'cancel'): void }>();
const props = defineProps<CountFieldViewProps>();

const unit = computed(() => (props.quantUnit ? getQuantUnit(props.quantUnit, props.count) : ''));
</script>
