<template>
  <div class="grow-1 overflow-hidden h-full max-h-screen">
    <DynamicScroller
      :items="suggests"
      key-field="suggest_id"
      :min-item-size="150"
      class="h-full"
      list-class="h-full"
    >
      <template #default="{ item, index, active }">
        <DynamicScrollerItem
          :item="item"
          :active="active"
          :data-index="index"
        >
          <SuggestCard
            :class="index >= 0 ? 'scroller-active-item' : 'scroller-hidden-item'"
            :suggest="item"
            :order="order"
            :doc-type="docType"
            :need-menu="needMenuForSuggests[index]"
            :suggest-menu-config="suggestMenuConfig(item)"
            @open-suggest-menu="() => $emit('open-suggest-menu')"
            @close-suggest-menu="() => $emit('close-suggest-menu')"
          />
        </DynamicScrollerItem>
      </template>
      <template #empty>
        <EmptyList v-if="suggests.length === 0" />
      </template>
    </DynamicScroller>
  </div>
</template>

<script lang="ts">
import BaseOrder from '@/models/orders/BaseOrder';
import Suggest from '@/models/Suggest';
import { DocTypes } from '@/temp/constants/translations/types';
import EmptyList from '@/ui/common/empty-list.vue';
import SuggestCard from '@/ui/common/suggest-card/suggest-card.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'SuggestCardContainer',
  components: {
    EmptyList,
    SuggestCard,
  },
  props: {
    suggests: {
      type: Array as PropType<Suggest[]>,
      required: true,
    },
    needMenuForSuggests: {
      type: Array as PropType<boolean[]>,
      default: () => [],
    },
    order: {
      type: Object as PropType<BaseOrder>,
      required: true,
    },
    docType: {
      type: String as PropType<DocTypes>,
      required: true,
    },
    suggestMenuConfig: {
      type: Function,
      default: () => {},
    },
  },
  emits: ['open-suggest-menu', 'close-suggest-menu'],
});
</script>
