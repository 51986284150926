<template>
  <div class="overlay">
    <div
      class="overlay-backdrop"
      data-test="overlay backdrop"
      @click="$emit('backdrop-click')"
    />
    <div class="overlay-container">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['backdrop-click'],
});
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  left: 0;
  top: 20px;

  width: 100vw;
  height: calc(100vh - 20px);
  z-index: 1000;
}

.overlay-backdrop {
  width: 100%;
  height: calc(100vh - 20px);
  transition: opacity 150ms ease 0ms;
  position: fixed;
  overflow: hidden;
  border-radius: 16px;
  top: 20px;
  left: 0;
  background: rgba(33, 32, 31, 0.8);
  backdrop-filter: blur(12px);
  z-index: -1;
}

.overlay-container {
  display: flex;
  height: calc(100vh - 20px);
  width: 100%;
  flex-direction: column;
  pointer-events: none;
}
</style>
