<template>
  <Row
    v-if="needShow"
    :label="label"
    :value="value"
    data-test="suggest card reserved row"
  />
</template>

<script lang="ts">
// поле нужно для отрисовки зарезервированного количества на странице полки
import { getValueForView } from '@/fsd/entities/product';
import { AvailableItem } from '@/models/Item';
import Product from '@/models/Product';
import Shelf from '@/models/Shelf';
import { useItems } from '@/store/modules/items';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { DocTypes } from '@/temp/constants/translations/types';
import { AvailableProduct } from '@/types/product';
import Row from '@/ui/common/suggest-card/row.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    Row,
  },
  props: {
    docType: {
      type: String as PropType<DocTypes>,
      required: true,
    },
    availableProduct: {
      type: Object as PropType<AvailableProduct | AvailableItem>,
      default: undefined,
    },
  },
  setup() {
    const itemsStore = useItems();
    const shelvesStore = useShelves();
    const productsStore = useProducts();

    return {
      itemsStore,
      shelvesStore,
      productsStore,
    };
  },
  computed: {
    shelf(): Shelf {
      return this.shelvesStore.shelfById(this.availableProduct?.shelf_id)!;
    },
    product(): Product {
      return this.productsStore.productById(this.availableProduct?.product_id)!;
    },
    item() {
      return this.itemsStore.itemById(this.availableProduct?.product_id ?? '')!;
    },
    needShow(): boolean {
      return this.docType === 'shelf';
    },
    label(): string {
      return this.$gettext('Резерв');
    },
    value(): string {
      if (this.availableProduct) {
        if (this.item) {
          if (this.availableProduct.reserved) return this.$gettext('Да');
          return this.$gettext('Нет');
        }
        let count: number = this.availableProduct.reserved || 0;

        return getValueForView({
          product: this.product,
          shelf: this.shelf,
          count,
        });
      }
      return '-';
    },
  },
});
</script>
