<template>
  <CourierQr :title="$gettext('Возврат Маркета')" />
</template>

<script setup lang="ts">
import { api } from '@/fsd/data/api/api.service';
import { useSubscribeOnOrderStatus } from '@/fsd/data/utils/subscribeOnOrder';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import { OrderWorkStatusEnum } from '@/models/orders/BaseOrder';
import { $gettext } from '@/temp/plugins/gettext';
import { BaseError } from '@/types/baseErrorResponse';
import { useLoader } from '@/ui/common/loader/useLoader';
import CourierQr from '@/views/create/market/courier-qr.vue';
import { AxiosError } from 'axios';
import { v1 as uuidv1 } from 'uuid';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const { showLoader } = useLoader();
const router = useRouter();

const orderId = ref<string | undefined>(undefined);

const createShipment = async (courier_qr_code: string): Promise<void> => {
  const { closeLoader } = showLoader();
  try {
    const { data } = await api.order.shipment_items({
      courier_qr_code,
      external_id: uuidv1(),
    });
    orderId.value = data.order.order_id;
    // ждем создания ордера
    await useSubscribeOnOrderStatus(data.order.order_id)((s) => {
      return s === OrderWorkStatusEnum.processing;
    });
  } catch (e) {
    console.error(e);
    throw e;
  } finally {
    closeLoader();
  }
};

useRequestBarcode(async (barcode) => {
  try {
    await createShipment(barcode);
    await router.push({
      name: 'market_shipment',
      params: { order_id: orderId.value },
    });
    return false;
  } catch (e: any) {
    if (e.isAxiosError) {
      const response = (e as AxiosError<BaseError>)['response'];
      if (response?.data.code === 'ER_NO_ITEMS_EXPIRED') {
        await Modal.show({
          title: $gettext('Нет посылок для передачи курьеру'),
          text: 'ER_NO_ITEMS_EXPIRED',
        });
      } else {
        await Modal.show({
          title: response?.data.code!,
          text: response?.data.message,
        });
      }
    } else {
      Alerts.error($gettext('Отсканирован неверный баркод'));
    }

    return true;
  }
});
</script>
