<template>
  <Overlay @backdrop-click="$emit('close')">
    <CommonMenu
      :menu-config="menuConfig"
      :title="title"
      :subtitle="subtitle"
      @close="$emit('close')"
    />
  </Overlay>
</template>

<script lang="ts">
import CommonMenu from '@/ui/common/menu/common-menu.vue';
import { MenuItemConfig } from './types';
import Overlay from '@/ui/common/overlay/overlay.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    Overlay,
    CommonMenu,
  },
  props: {
    menuConfig: {
      type: Array as PropType<MenuItemConfig[]>,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
});
</script>
