<template>
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.37439 3.6217C6.27664 3.52395 6.10069 3.36755 5.98339 3.28935C5.84653 3.19159 5.69013 3.21114 5.57283 3.3089L5.16227 3.71945C5.00587 3.89541 4.92767 4.11046 4.92767 4.30596C4.92767 4.40371 5.00587 4.50147 5.06452 4.56012C5.12317 4.59922 5.20137 4.65787 5.27957 4.71652C5.82698 5.28348 5.82698 6.16325 5.27957 6.71065L3.95015 8.02053C3.40274 8.58749 2.50343 8.58749 1.95602 8.02053C1.40861 7.47312 1.42816 6.5738 1.97557 6.02639L2.22972 5.77224C2.32747 5.69404 2.34702 5.55718 2.30792 5.43988C2.19062 5.10753 2.13197 4.75562 2.11242 4.42326C2.11242 4.14956 1.78006 4.01271 1.58456 4.20821L0.861196 4.93157C-0.292274 6.08504 -0.292274 7.98143 0.861196 9.1349C2.01467 10.2884 3.91105 10.2884 5.06452 9.1349L6.37439 7.82502C6.37439 7.82502 6.37439 7.82502 6.37439 7.80547C7.52786 6.67155 7.54741 4.79472 6.37439 3.6217ZM9.13099 0.865103C7.97752 -0.288368 6.08114 -0.288368 4.92767 0.865103L3.61779 2.17498C3.61779 2.17498 3.61779 2.17498 3.61779 2.19453C2.46432 3.32845 2.44477 5.20528 3.61779 6.3783C3.71555 6.47605 3.8915 6.63245 4.0088 6.71065C4.14565 6.80841 4.30206 6.78886 4.41936 6.6911L4.82992 6.28055C4.98632 6.10459 5.06452 5.88954 5.06452 5.69404C5.06452 5.59629 4.98632 5.49853 4.92767 5.43988C4.86902 5.40078 4.79082 5.34213 4.71261 5.28348C4.1652 4.71652 4.1652 3.83675 4.71261 3.28935L6.04204 1.97947C6.58945 1.41251 7.48876 1.41251 8.03617 1.97947C8.58358 2.52688 8.56403 3.4262 8.01662 3.97361L7.76247 4.22776C7.66472 4.30596 7.64516 4.44282 7.68427 4.56012C7.80157 4.89247 7.86022 5.24438 7.87977 5.57674C7.87977 5.85044 8.21212 5.98729 8.40763 5.79179L9.13099 5.06843C10.2845 3.91496 10.2845 2.01857 9.13099 0.865103Z"
      fill="currentColor"
    />
  </svg>
</template>
