import shelf2box, { Shelf2BoxOptions } from '@/fsd/data/utils/shelf2box';
import retryShelf2BoxModal from '@/fsd/entities/modals/retryShelf2BoxModal';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { SuggestStatusEnum } from '@/models/Suggest';
import {
  OrderDoneShelf2BoxRequest,
  OrderDoneShelf2BoxSuccessReasonCodeEnum,
  OrderDoneShelf2BoxSuccessRequest,
} from '@/services/requests';
import { useSuggests } from '@/store/modules/suggests';
import { $gettext } from '@/temp/plugins/gettext';
import { useLoader } from '@/ui/common/loader/useLoader';
import { merge } from 'lodash';

export const useShelf2Box = async (
  order_id: string,
  payload: OrderDoneShelf2BoxRequest,
  options: Shelf2BoxOptions = {},
) => {
  const { showLoader } = useLoader();

  let loader;

  const { suggest_id } = payload;

  const suggest = useSuggests().getSuggest(order_id, suggest_id)!;

  if (suggest.status === SuggestStatusEnum.done && suggest.isEditable && !payload.reason) {
    payload.reason = {
      code: OrderDoneShelf2BoxSuccessReasonCodeEnum.CHANGE_COUNT,
      count: (payload as OrderDoneShelf2BoxSuccessRequest).count,
    };
  }

  const defaultOptions: Shelf2BoxOptions = {
    onValidateError: e => Modal.show({
      title: $gettext('Не удалось переместить товар в корзину'),
      text: e,
    }),
    beforeRequest: () => (loader = showLoader($gettext('Отправляем запрос на перемещение товаров в корзину'))),
    afterRequest: () => loader?.updateLoader($gettext('Ожидаем ответ сервера')),
    onRequestError: async (e, retry) => {
      const confirmed = await retryShelf2BoxModal(e);
      if (!confirmed) return false;
      return retry();
    },
    beforeEventWaiting: () => {},
    afterEventWaiting: () => {},
    onEventWaitingError: (e) => {
      Modal.show({
        title: $gettext('Не удалось переместить товар в корзину'),
        text: e,
      });
    },
    closeLoader: () => loader?.closeLoader(),
  };
  return shelf2box(order_id, payload, merge({}, defaultOptions, options));
};

export const prepareBarcodes = (barcodes: string[]) => {
  return barcodes.reduce<{
    barcode: string;
    count: number;
  }[]>((acc, b) => {
    const bIdx = acc.findIndex(fB => fB.barcode === b);
    if (bIdx !== -1) {
      acc[bIdx].count++;
    } else {
      acc.push({
        barcode: b,
        count: 1,
      });
    }
    return acc;
  }, []);
};
