<template>
  <Bar
    :menu-config="suggestMenuConfig"
    :icons="{ left: 'close', right: 'menu' }"
    :menu-title="productTitle"
    @close-click="backClick"
  />
</template>

<script lang="ts" setup>
import { callAction } from '@/fsd/shared/ui/action';
import { ActionReportProps } from '@/fsd/shared/ui/action/types';
import Product from '@/models/Product';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { $gettext } from '@/temp/plugins/gettext';
import Bar from '@/ui/common/bar/bar.vue';
import { MenuItemConfig } from '@/ui/common/menu/types';
import Report from '@/ui/common/notifications/report.vue';
import { computed, toRefs } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const props = defineProps<{
  product_id: Product['product_id'];
  productTitle: string | undefined;
}>();
const { product_id, productTitle } = toRefs(props);

const suggestMenuConfig = computed(() => {
  const menuConfig: MenuItemConfig[] = [];
  if (product_id.value) {
    const errorInCardBtn: MenuItemConfig = {
      buttonText: $gettext('Ошибка в карточке товара'),
      buttonSecondText: $gettext('Изображение, описание, другое'),
      dataTest: 'product-card error-card',
      onClick: async () => {
        await callAction<ActionReportProps, void>(Report, {
          title: $gettext('Ошибка в карточке товара'),
          productId: product_id.value,
        });
      },
      condition: () => useUser().experimentByName(experiments.exp_product_bug_ticket),
    };
    menuConfig.push(errorInCardBtn);
  }
  return menuConfig;
});

const backClick = () => {
  if ((router.options.history.state.back as string).includes('/shelf')) {
    router.back();
  } else {
    router.push({ name: 'home' });
  }
};
</script>
