<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.25303 9.49697C8.60018 10.1498 8.16393 10.9877 8.00348 11.8969L7.49739 14.7647C7.31643 15.7902 8.20978 16.6836 9.23525 16.5026L12.1031 15.9965C13.0123 15.8361 13.8502 15.3998 14.503 14.747L19.1071 10.1428L22.1071 7.14285C22.3587 6.89131 22.5 6.55015 22.5 6.19443C22.5 3.60177 20.3982 1.5 17.8056 1.5C17.4498 1.5 17.1087 1.64131 16.8571 1.89285L13.8571 4.89285L9.25303 9.49697ZM15.1569 6.77506L10.844 11.088C10.5176 11.4144 10.2995 11.8333 10.2192 12.2879L9.89936 14.1006L11.7121 13.7807C11.7405 13.7757 11.7688 13.7702 11.7969 13.7641C12.2187 13.6728 12.606 13.462 12.912 13.156L17.2249 8.84308C17.0711 7.77426 16.2257 6.92891 15.1569 6.77506ZM18.9946 7.07338C18.5432 6.1835 17.8165 5.45682 16.9266 5.00536L18.1569 3.77506C19.2257 3.92891 20.0711 4.77426 20.2249 5.84308L18.9946 7.07338ZM12 3.375C12 2.75368 11.4963 2.25 10.875 2.25H6.75C4.26472 2.25 2.25 4.26472 2.25 6.75V17.25C2.25 19.7353 4.26472 21.75 6.75 21.75H17.25C19.7353 21.75 21.75 19.7353 21.75 17.25V13.125C21.75 12.5037 21.2463 12 20.625 12C20.0037 12 19.5 12.5037 19.5 13.125V17.25C19.5 18.4926 18.4926 19.5 17.25 19.5H6.75C5.50736 19.5 4.5 18.4926 4.5 17.25V6.75C4.5 5.50736 5.50736 4.5 6.75 4.5H10.875C11.4963 4.5 12 3.99632 12 3.375Z"
      :fill="colorValue"
    />
  </svg>
</template>
<script setup lang="ts">
import { useColor } from '@/fsd/shared/hooks';

const { color } = defineProps<{ color?: string }>();
const { colorValue } = useColor({ color: () => color });
</script>
