import { AxiosRequestConfig } from 'axios';
import { defineStore } from 'pinia';

export const useIndicators = defineStore('indicators', {
  state: () => ({
    requests: [] as AxiosRequestConfig[],
    requestsTime: [] as number[],
    eventPullCount: 0 as number,
    errorCount: 0 as number,
    eventErrorCount: 0 as number,
    hasApiError: false as boolean,
    hasEventError: false as boolean,
    lastRequestTime: undefined as undefined | number,
    hasEventsPoll: false as boolean,
  }),
  getters: {
    hasRequest: (state) => {
      return state.requests.length > 0;
    },
    requestTime: (state) => {
      if (state.lastRequestTime !== undefined) {
        return state.lastRequestTime;
      }
      return state.requestsTime.sort((a, b) => b - a)[0];
    },
    hasRequestInState: state => (payload) => {
      return !!state.requests.find(r => r === payload);
    },
  },
  actions: {
    startRequest(requestConfig: AxiosRequestConfig): Promise<void> {
      // нужно начать считать время запроса
      this.addRequest(requestConfig);
      return new Promise((resolve) => {
        const id = setInterval(() => {
          if (this.hasRequestInState(requestConfig)) {
            this.addRequestsTime(requestConfig);
            this.clearLastRequestTime();
          } else {
            clearInterval(id);
            resolve();
          }
        }, 1000);
      });
    },
    addRequest(payload: AxiosRequestConfig) {
      this.requests = [...this.requests, payload];
      this.requestsTime = [...this.requestsTime, 0];
    },
    addRequestsTime(payload) {
      const idx = this.requests.findIndex(r => r === payload);
      this.requestsTime[idx] = this.requestsTime[idx] + 1;
    },
    removeRequest(payload: AxiosRequestConfig) {
      const idx = this.requests.findIndex(r => r === payload);
      this.requests.splice(idx, 1);
      this.lastRequestTime = this.requestsTime[idx];
      this.requestsTime.splice(idx, 1);
      this.hasApiError = false;
    },
    receivedError() {
      this.requests = [];
      this.requestsTime = [];
      this.errorCount = this.errorCount + 1;
      this.hasApiError = true;
      this.lastRequestTime = 20;
    },
    addEventPoll() {
      this.eventPullCount = this.eventPullCount + 1;
      this.hasEventError = false;
    },
    addEventError() {
      this.hasEventError = true;
      this.eventErrorCount = this.eventErrorCount + 1;
    },
    clearEventErrors() {
      this.hasEventError = false;
    },
    clearLastRequestTime() {
      this.lastRequestTime = undefined;
    },
    clearApiErrors() {
      this.errorCount = 0;
    },
    clearIndicators() {
      this.errorCount = 0;
      this.eventErrorCount = 0;
    },
    setHasEventsPoll(hasEventsPoll) {
      this.hasEventsPoll = hasEventsPoll;
    },
  },
});
