<template>
  <div
    :id="id"
    class="h-14 flex shrink-0 grow-0 justify-center items-center px-4 rounded-2xl relative"
  >
    <HintBg
      :custom-bg-color="customBgColor"
      :custom-ears-color="customEarsColor"
      class="absolute h-full w-full"
    />
    <Caption1
      data-test="hint text"
      class="text-primary font-medium whitespace-pre-wrap relative text-center"
    >
      <slot />
    </Caption1>
  </div>
</template>
<script>
import HintBg from '@/ui/common/hint/img/hint-bg.vue';
import Caption1 from '@/ui/common/typo/caption-1.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Caption1,
    HintBg,
  },
  props: {
    id: {
      type: String,
      default: undefined,
    },
    customBgColor: {
      type: String,
      default: '',
    },
    customEarsColor: {
      type: String,
      default: '',
    },
  },
});
</script>
