<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.42354 0.447424C6.7928 0.293567 7.20821 0.293567 7.57747 0.447424L11.9451 2.26725C12.5041 2.50017 12.8682 3.04637 12.8682 3.65196V10.3504C12.8682 10.956 12.5041 11.5022 11.9451 11.7351L7.57747 13.5549C7.20821 13.7088 6.7928 13.7088 6.42354 13.5549L2.05595 11.7351C1.49694 11.5022 1.13281 10.956 1.13281 10.3504L1.13281 3.65196C1.13281 3.04637 1.49694 2.50017 2.05595 2.26725L6.42354 0.447424ZM3.3565 3.06369L6.38281 1.80272V4.32465L3.3565 3.06369ZM10.6445 3.06369L7.61819 4.32465V1.80272L10.6445 3.06369ZM2.36819 10.3504L2.36819 3.99022L6.20709 5.58976L4.0282 11.2186L2.5311 10.5948C2.43245 10.5537 2.36819 10.4573 2.36819 10.3504ZM6.38296 12.1997L6.38295 8.55762L5.16892 11.6939L6.38296 12.1997ZM7.61834 12.1996L7.61831 5.66293L11.6328 3.99022V10.3504C11.6328 10.4573 11.5686 10.5537 11.4699 10.5948L7.61834 12.1996Z"
      :fill="colorValue"
    />
  </svg>
</template>
<script setup lang="ts">
import { useColor } from '@/fsd/shared/hooks';

interface Props {
  bgColor?: string;
  color?: string;
}

const { bgColor = '#FFA60D', color = 'white' } = defineProps<Props>();
const params = {
  color: () => color,
  bgColor: () => bgColor,
};
const { colorValue } = useColor(params);
</script>
