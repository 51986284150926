<template>
  <Typography
    color="secondary-title"
    type="text3"
  >
    {{ versionText }}
  </Typography>
</template>

<script>
import { useUser } from '@/store/modules/user';
import Typography from '@/ui/common/typography.vue';

export default {
  name: 'VersionText',
  components: { Typography },
  setup() {
    const userStore = useUser();

    return { userStore };
  },
  computed: {
    version() {
      if (this.userStore.options?.constants.frontend_version === this.userStore.version) {
        return this.userStore.version + 'f';
      }
      return this.userStore.version;
    },
    apkVersion() {
      return this.userStore.apkVersion;
    },
    versionText() {
      return this.apkVersion ? `ver: ${this.version} (${this.apkVersion})` : this.version;
    },
  },
};
</script>
