<template>
  <ButtonFloat
    v-show="!scannerStore.isActiveCamera"
    storage-key="camera-scanner-trigger"
  >
    <CameraIcon @click="getBarcodeFromCamera" />
  </ButtonFloat>
</template>

<script setup lang="ts">
import { startScan, stopScan } from '@/fsd/data/services/camera.scanner';
import ButtonFloat from '@/fsd/shared/ui/ButtonFloat/ButtonFloat.vue';
import { useScanner } from '@/store/modules/scanner';
import CameraIcon from '@/temp/icons/camera-icon.vue';
import {
  defineEmits, defineProps, onUnmounted,
} from 'vue';

const props = defineProps<{ resolve }>();
const emits = defineEmits<{ (e: 'close'): void }>();
const scannerStore = useScanner();

const getBarcodeFromCamera = async () => {
  useScanner().setCameraActive(true);
  try {
    const result = await startScan();
    if (result) props.resolve(result);
    emits('close');
    scannerStore.setCameraActive(false);
  } catch {
    scannerStore.setCameraActive(false);
  }
};

onUnmounted(() => {
  stopScan();
});
</script>
